import axios from "axios";
import axiosInstance from "./axiosInstance";
import apiService from "./apiService";

interface finalDataProps {
	main_drug_uid: string;
	ids: string[];
}
// 設定附加醫令的排序
export const postDrugAttachDrugsSortService = async (token: string, finalData: finalDataProps) => {
	return apiService<finalDataProps, any>({
		method: "POST",
		path: "/api/drug/attach_drugs/sort",
		token,
		data: finalData
	});
};

// export const postDrugAttachDrugsSortService = async (
//     token: string,
//     finalData: finalDataProps
// ) => {
//     try {
//         const requestBody = JSON.stringify(finalData);
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };
//         console.log(requestBody, 'requestBody 設定附加醫令的排序');

//         const response = await axiosInstance.post(
//             '/api/drug/attach_drugs/sort',
//             requestBody,
//             {
//                 headers,
//             }
//         );

//         const result = response.data;
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
