import axios from "axios";

// 請求依照用戶資料註冊
export interface RegisterCovidPatientInfoReqProps {
	account: string; // 雲元件帳號
	pw: string; // 雲元件密碼
	patient: {
		name: string; // 病患姓名
		localNumber: string; // 病患唯一代碼
		cellPhone: string; // 病患手機號碼
		user_id: string; // 身分證字號
		user_birthday: string; // 病患生日 (DateTime format)
		country: "HC" | "FC"; // 國家 (HC: 本國, FC: 外國)
		gender: "M" | "F"; // 病患性別 (M: 男生, F: 女)
		veterans: boolean; // 是否榮民
		disability: boolean; // 身心障礙
		injury: boolean; // 重大傷病

		email?: string; // 病患電子信箱
		address?: string; // 病患地址
		zip_code?: string; // 病患郵遞區號
		bloodtype?: "A" | "B" | "AB" | "O" | "N/A"; // 血型
		bloodrhtype?: "RhPositive" | "RhNegative"; // 血型 Rh
		social?: "N/A" | "LIH" | "MLIH"; // 中低收入戶
		profession?: "AG" | "FS" | "SV" | "FN" | "ED" | "EL" | "IT" | "SE" | "OTH"; // 職業
		aboriginal?:
			| "AM"
			| "BN"
			| "BN1"
			| "DU"
			| "KML"
			| "KNKN"
			| "LARU"
			| "LK"
			| "PU"
			| "S"
			| "SCRY"
			| "SDK"
			| "SS"
			| "T"
			| "TLG"
			| "TY"
			| "OTH"; // 原住民族別
		education?: "EL" | "JR" | "SR" | "BS" | "MS" | "PHD"; // 教育程度
		age?: string; // 年齡
		marriage?: "S" | "M" | "W"; // 婚姻狀態
		allergy?: string; // 過敏史
		homeNumber?: string; // 家裡電話
		companyNumber?: string; // 公司電話
		counties?: string; // 居住縣市
	};
	consultation: {
		doctorID: string; // 醫生帳號
		consultationStartTime: string; // 門診時段開始時間 (DateTime format)
		consultationEndTime: string; // 門診時段結束時間 (DateTime format)
		hisCaseID: string; // HIS 就診唯一編號
	};
	option?: {
		bloodtype?: "A" | "B" | "AB" | "O" | "N/A";
		bloodrhtype?: "RhPositive" | "RhNegative";
		social?: "N/A" | "LIH" | "MLIH";
		profession?: "AG" | "FS" | "SV" | "FN" | "ED" | "EL" | "IT" | "SE" | "OTH";
		aboriginal?:
			| "AM"
			| "BN"
			| "BN1"
			| "DU"
			| "KML"
			| "KNKN"
			| "LARU"
			| "LK"
			| "PU"
			| "S"
			| "SCRY"
			| "SDK"
			| "SS"
			| "T"
			| "TLG"
			| "TY"
			| "OTH";
		education?: "EL" | "JR" | "SR" | "BS" | "MS" | "PHD";
		age?: string;
		marriage?: "S" | "M" | "W";
		allergy?: string;
		homeNumber?: string;
		companyNumber?: string;
		counties?: string;
	};
}

export interface RegisterCovidPatientInfoResProps {
	ExectResult: boolean; // 執行結果，成功為 true
	ExectureState: number; // 執行狀態碼，成功為 1
	ErrorMessage: string; // 錯誤狀況說明
	PatientMessage: string; // 用戶之簡訊內容
}

export const postRegisterCovidPatientInfoService = async (
	RegisterCovidPatientInfoReqData: RegisterCovidPatientInfoReqProps
): Promise<any> => {
	console.log(RegisterCovidPatientInfoReqData, "service這邊看看");
	try {
		console.log(RegisterCovidPatientInfoReqData, "service看一下");
		const finalData = JSON.stringify(RegisterCovidPatientInfoReqData);
		const response = await axios.post(" http://localhost:23752/VDAT/RsRegisterCovidPatient", finalData, {
			headers: {
				// 'Accept': '*/*',
				// 'Access-Control-Allow-Origin': '*',
				"Content-Type": "application/json"
			}
		});

		console.log(response.data, "再次要求授權");
		return response.data;
	} catch (error: unknown) {
		if (error instanceof Error) {
			console.error("Error:", error.message);
		} else {
			console.error("An unknown error occurred");
		}
	}
};
