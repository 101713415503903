import React, { createContext, useContext, useState, ReactNode } from "react";

interface showAbnormalButtonStatusContextValue {
	showAbnormalButtonStatus: boolean;
	setShowAbnormalButtonStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const showAbnormalButtonStatusContext = createContext<showAbnormalButtonStatusContextValue | undefined>(undefined);

interface showAbnormalButtonStatusProviderProps {
	children: ReactNode;
}

export const ShowAbnormalButtonStatusProvider: React.FC<showAbnormalButtonStatusProviderProps> = ({ children }) => {
	const [showAbnormalButtonStatus, setShowAbnormalButtonStatus] = useState(false);
	return (
		<showAbnormalButtonStatusContext.Provider
			value={{
				showAbnormalButtonStatus,
				setShowAbnormalButtonStatus
			}}>
			{children}
		</showAbnormalButtonStatusContext.Provider>
	);
};

export const useShowAbnormalButtonStatus = (): showAbnormalButtonStatusContextValue => {
	const context = useContext(showAbnormalButtonStatusContext);

	if (!context) {
		throw new Error("useshowAbnormalButtonStatus must be used within a showAbnormalButtonStatusProvider");
	}

	return context;
};
