import { useEffect, useState } from "react";
import { Box, Center, Text, Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator } from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import { Header } from "./Header";
import { Body } from "./Body";
import { useICCardUpdateListStore } from "store/ICCardUpdate/useICCardUpdateListStore";
import { useICCardUpdateListTabIndexStore } from "store/ICCardUpdate/useICCardUpdateListTabIndexStore";

import { useIcDataListsAPI } from "service/icDataListsServiceHelper";
import { useIcDataVoidAPI } from "service/icDataVoidServiceHelper";

export const Lists = () => {
	const { tabIndex, setTabIndex } = useICCardUpdateListTabIndexStore();
	const { icCardUpdateList, updateICCardUpdateList, clearICCardUpdateList } = useICCardUpdateListStore();
	const { callIcDataVoidAPI } = useIcDataVoidAPI();
	const { callIcDataListsAPI } = useIcDataListsAPI();

	useEffect(() => {
		callIcDataListsAPI();
	}, []);

	useEffect(() => {
		if (tabIndex === 0) {
			callIcDataListsAPI();
		} else if (tabIndex === 1) {
			callIcDataVoidAPI();
		}
	}, [tabIndex]);

	return (
		<>
			<Tabs position='relative' variant='unstyled' onChange={index => setTabIndex(index)}>
				<TabList>
					<Tab opacity={0.8} _selected={{ opacity: 1 }}>
						<Center textColor={"rsPrimary.500"} gap={"8px"}>
							<Box>
								<HeroIcons name='upload-list' />
							</Box>
							<Text>上傳清單</Text>
						</Center>
					</Tab>
					<Tab opacity={0.8} _selected={{ opacity: 1 }}>
						<Center textColor={"rsPrimary.500"} gap={"8px"}>
							<Box>
								<HeroIcons name='delete' />
							</Box>
							<Text>作廢清單</Text>
						</Center>
					</Tab>
				</TabList>
				<TabIndicator mt='-1.5px' height='2px' bg='rsPrimary.500' borderRadius='1px' />

				<TabPanels mt={"12px"}>
					<TabPanel p={0} pos={"relative"} overflowY={"scroll"} h={`calc(${window.innerHeight}px - 301px)`}>
						<Header tabIndex={tabIndex} />
						{icCardUpdateList && Array.isArray(icCardUpdateList) && <Body tabIndex={tabIndex} listData={icCardUpdateList} />}
					</TabPanel>
					<TabPanel p={0} pos={"relative"} overflowY={"scroll"} h={`calc(${window.innerHeight}px - 301px)`}>
						<Header tabIndex={tabIndex} />
						{icCardUpdateList && Array.isArray(icCardUpdateList) && <Body tabIndex={tabIndex} listData={icCardUpdateList} />}
					</TabPanel>
				</TabPanels>
			</Tabs>
		</>
	);
};
