import { Box } from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import { useIsFormMaximizeStatus } from "contexts/TreatmentManagement/Prescription";

export const FormMaximizeButton = () => {
	const { isFormMaximizeStatus, setIsFormMaximizeStatus } = useIsFormMaximizeStatus();

	return (
		<Box
			pos={"absolute"}
			top={"8px"}
			right={"12px"}
			onClick={() => {
				setIsFormMaximizeStatus(pre => !pre);
			}}
			opacity={"0.5"}
			_hover={{ cursor: "pointer", opacity: "1" }}
			zIndex={20}>
			<HeroIcons name={isFormMaximizeStatus ? "arrow-pointing-in" : "arrow-pointing-out"}></HeroIcons>
		</Box>
	);
};
