import React from "react";
import { Text } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer } from "@chakra-ui/react";

type ListProps = {
	code?: string;
	name?: string;
	time?: number;
	day?: number;
	freq?: string;
	part?: string;
	total?: number;
	point?: number;
	percent?: number;
	member?: string;
};

import { useDeclareListHistoryDataStore } from "store/DeclarationAudit/useDeclareListHistoryDataStore";
import { useDeclareHistoryLoadingStatusStore } from "store/DeclarationAudit/useDeclareHistoryLoadingStatusStore";
import Loading from "components/CustomComponents/Loading";
export const RxList = () => {
	const { historyData } = useDeclareListHistoryDataStore();
	const { isHistoryLoading } = useDeclareHistoryLoadingStatusStore();

	return (
		<TableContainer my={"12px"} h={`calc(${window.innerHeight}px - 674px + 71px)`} overflowY={"scroll"}>
			<Table variant={"striped"} size={"sm"}>
				<Thead bg={"#D9D9D9"} pos={"sticky"} top={0} left={0} zIndex={"10"}>
					<Tr>
						<Th p={"4px 8px"}>
							<Text fontSize={"md"}>健保碼</Text>
						</Th>
						<Th p={"4px 8px"} w={"130px"}>
							<Text fontSize={"md"}>藥品名稱</Text>
						</Th>
						<Th p={"4px 8px"}>
							<Text fontSize={"md"}>次</Text>
						</Th>
						<Th p={"4px 8px"}>
							<Text fontSize={"md"}>天</Text>
						</Th>
						<Th p={"4px 8px"}>
							<Text fontSize={"md"}>用法</Text>
						</Th>
						<Th p={"4px 8px"}>
							<Text fontSize={"md"}>部位</Text>
						</Th>
						<Th p={"4px 8px"}>
							<Text fontSize={"md"}>總量</Text>
						</Th>
						<Th p={"4px 8px"}>
							<Text fontSize={"md"}>點數</Text>
						</Th>
						<Th p={"4px 8px"}>
							<Text fontSize={"md"}>%</Text>
						</Th>
						<Th p={"4px 8px"}>
							<Text fontSize={"md"}>執行人員</Text>
						</Th>
					</Tr>
				</Thead>
				<Tbody>
					{isHistoryLoading ? (
						<Td pos={"relative"} w={"100%"} h={"120px"} colSpan={10}>
							<Loading />
						</Td>
					) : (
						<>
							{historyData.drugs &&
								historyData.drugs.map((item, index) => (
									<Tr key={index}>
										<Td p={"4px 8px"}>{item.drug_no}</Td>
										<Td p={"4px 8px"}>
											<Text w={"130px"} textOverflow={"ellipsis"} overflow={"hidden"} whiteSpace={"nowrap"}>
												{item.name_tw}
											</Text>
										</Td>
										<Td p={"4px 8px"}>{item.qty}</Td>
										<Td p={"4px 8px"}>{item.dose_days}</Td>
										<Td p={"4px 8px"}>{item.freq_code}</Td>
										<Td p={"4px 8px"}>{item.site}</Td>
										<Td p={"4px 8px"}>{item.total_qty}</Td>
										<Td p={"4px 8px"}>{item.drug_price}</Td>
										<Td p={"4px 8px"}></Td>
										<Td p={"4px 8px"}></Td>
									</Tr>
								))}

							{historyData.drugs && historyData.drugs.length === 0 && (
								<Tr>
									<Td p={"4px 8px"} colSpan={10}>
										<Text fontSize={"md"}>無資料</Text>
									</Td>
								</Tr>
							)}
						</>
					)}
				</Tbody>
			</Table>
		</TableContainer>
	);
};
