import apiService from "./apiService";

export type HospNameReqDataProps = {
	nhid: string;
};

export type HospNameResProps = {
	result: string;
	msg: string;
	info: HospNameInfoProps;
};

export type HospNameInfoProps = {
	hosp_code: string;
	hosp_name: string;
	stop_date: string;
};

export const callHospNameService = async (token: string, data: HospNameReqDataProps): Promise<HospNameResProps> => {
	const queryParams = data ? { nhid: data.nhid } : undefined;
	return apiService<string, any>({
		method: "GET",
		path: "/api/hosp/name",
		token,
		data: queryParams
	});
};
