import { create } from "zustand";

export type VirtualCardAuthorizeTokenProps = {
	token: string;
};

const initialVirtualCardAuthorizeToken: VirtualCardAuthorizeTokenProps = {
	token: ""
};

export type VirtualCardAuthorizeTokenState = {
	virtualCardAuthorizeToken: VirtualCardAuthorizeTokenProps;
	updateVirtualCardAuthorizeToken: (token: string) => void;
};

export const useVirtualCardAuthorizeTokenStore = create<VirtualCardAuthorizeTokenState>(set => ({
	virtualCardAuthorizeToken: initialVirtualCardAuthorizeToken,
	updateVirtualCardAuthorizeToken: token => set({ virtualCardAuthorizeToken: { token } })
}));
