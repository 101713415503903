import React from "react";
import { Button } from "@chakra-ui/react";
import { useICCardUpdateScopeStore } from "store/ICCardUpdate/useICCardUpdateScopeStore";
import { IcDataDlXMLReqDataProps } from "service/icDataDlXMLService";
import { useIcDataDlXMLAPI } from "service/icDataDlXMLServiceHelper";

export const DlXMLButton = () => {
	const { icCardUpdateScopeData } = useICCardUpdateScopeStore();
	const { callIcDataDlXMLAPI } = useIcDataDlXMLAPI();
	const handleDlXML = () => {
		const { start_date, end_date } = icCardUpdateScopeData;
		const dlXMLReqData: IcDataDlXMLReqDataProps = { start_date, end_date };
		console.log("要傳的資料 dlXMLReqData:", dlXMLReqData);
		callIcDataDlXMLAPI(dlXMLReqData);
	};
	return (
		<Button colorScheme='rsPrimary' boxShadow={"2px -2px 2px 0px rgba(0, 0, 0, 0.50) inset;"} onClick={handleDlXML}>
			產生預檢檔案 XML
		</Button>
	);
};
