// import axios from 'axios';
import axiosInstance from "./axiosInstance";
import { BasicInfoProps } from "components/DataSettings/DrugDataMaintenanceComponents/DrugDataMaintenanceTable";

import apiService from "./apiService";
// 取得藥品基本資料設定

export const callDrugBasicService = async (token: string, data: string) => {
	const queryParams = data ? { data } : undefined;
	return apiService<any, any>({
		method: "GET",
		path: "/api/drug/basic",
		token,
		data: queryParams
	});
};

// export const callDrugBasicService = async (token: string, data: string) => {
//     try {
//         const queryParams = { data };
//         const headers = {
//             'Api-Token': `${token}`,
//         };
//         console.log(queryParams, headers, '取得藥品基本資料設定service')
//         const response = await axiosInstance.get('/api/drug/basic', {
//             headers,
//             params: queryParams,
//         });
//         const result = response.data;
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };

export const postDrugBasicService = async (token: string, updatedData: BasicInfoProps) => {
	return apiService<BasicInfoProps, any>({
		method: "POST",
		path: "/api/drug/basic",
		token,
		data: updatedData
	});
};

// export const postDrugBasicService = async (
//     token: string,
//     updatedData: BasicInfoProps
// ) => {
//     try {
//         const requestBody = JSON.stringify(updatedData);
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         const response = await axiosInstance.post('/api/drug/basic', requestBody, {
//             headers,
//         });
//         const result = response.data;

//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
