import { useState, useEffect } from "react";
import { Button, Flex, Box, Text } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import "../../../style/components/datePickerCustomForMainStyle.css";
import iconICReader from "../../../../src/images/icon/icon-IC_Reader.png";

import Swal from "sweetalert2";

import { useVirtualCardState } from "contexts/VirtualCardContext";
import { useRegisteredFlowStatus } from "contexts/RegisteredFlowStatusContext";
import { checkInsureTypeToNhiType } from "utils/checkInsureTypeToNhiType";
import { postPatientInfoService } from "service/patientInfoService";

import { usePatientInfoStore, useCurrentVisitInfoStore, useSearchPatientStore } from "store/Registration";
import { callRshisGetRegisterBasicService } from "service/RsService/RshisGetRegisterBasicService";
import { callPatientSearchService } from "service/patientSearchService";
import { hisGetRegisterBasic } from "functions/hisGetRegisterBasic";
import { RegisterBasicProps } from "functions/hisGetRegisterBasic";
import { checkCardStatus } from "components/TreatmentManagement/ConsultingRoomComponents/BillingProcess/CompleteOptions/utils/checkCardStatus";
import { updatePatientInfoHelper } from "./RegistrationButton/hooks/updatePatientInfoHelper";

import { useVitalSignsDataStore } from "store/Registration";
import { useUserStore } from "store";
import { useIsChronicStatus } from "contexts/Registration/IsChronicContext";
const RegistrationButtons = () => {
	// const token = localStorage.getItem('token');
	const { userInfo } = useUserStore();
	const { updateSearchPatientData, resetSearchPatientData } = useSearchPatientStore();
	const { currentVisitInfoData, resetCurrentVisitInfoData } = useCurrentVisitInfoStore();
	const { resetVitalSignsData } = useVitalSignsDataStore();
	const { patientInfo, updatePatientInfo, resetPatientInfo } = usePatientInfoStore();
	const { registeredFlowStatus, setRegisteredFlowStatus, setShowPatientManagementStructureStatus } = useRegisteredFlowStatus();
	const { setShowReadVHICStatus } = useVirtualCardState();
	const { setIsChronicStatus } = useIsChronicStatus();

	const { showSearchPatientMedicalRecord, setShowSearchPatientMedicalRecord } = useVirtualCardState();

	const parsePBuffer = (pBuffer: string) => {
		return {
			cardNumber: pBuffer.substring(0, 12).trim(),
			name: pBuffer.substring(12, 28).trim(),
			idOrDocumentNumber: pBuffer.substring(28, 39).trim(),
			birthDate: pBuffer.substring(39, 46).trim(),
			gender: pBuffer.substring(46, 47).trim(),
			issueDate: pBuffer.substring(47, 54).trim(),
			cancellationMark: pBuffer.substring(54, 55).trim(),
			insurerCode: pBuffer.substring(55, 57).trim(),
			insureIdentity: pBuffer.substring(57, 58).trim(),
			cardValidityPeriod: pBuffer.substring(58, 65).trim(),
			medicalTreatmentNumber: pBuffer.substring(65, 67).trim(),
			newBornAttachmentDate: pBuffer.substring(67, 74).trim(),
			newBornTwinsNote: pBuffer.substring(74, 81).trim()
		};
	};

	const handleClickReadVHIC = () => {
		resetAllRegistrationStores();
		setShowReadVHICStatus(true);
	};

	const handleClickReadICCard = async () => {
		resetAllRegistrationStores();

		const cardStatus = await checkCardStatus("2");
		if (cardStatus.result !== "success") {
			Swal.fire({
				icon: "error",
				title: "讀取卡片異常",
				text: cardStatus.msg,
				showConfirmButton: true
			});
			return;
		}
		callRshisGetRegisterBasicAPI();
	};
	const [isUpdateNhiTypeStatus, setIsUpdateNhiTypeStatus] = useState(false);

	const [nhiTypeForUpdate, setNhiTypeForUpdate] = useState("");
	const callRshisGetRegisterBasicAPI = async () => {
		try {
			const responseParse = await hisGetRegisterBasic();

			if (responseParse) {
				// u000 u000
				// console.log('讀卡成功', responseParse);

				const patientData = responseParse.PatCardNumber
					? {
							name: responseParse.PatName || "",
							sex: responseParse.PatGender || "",
							id_no: responseParse.PatIDNo || "",
							insurerType: responseParse.PatInsurerType || "",
							insurerCode: responseParse.PatInsurerCode || "",
							birth_date: parseBirthDate(responseParse.PatBirth || "")
						}
					: {
							name: responseParse.name || "",
							sex: responseParse.gender || "",
							id_no: responseParse.idOrDocumentNumber || "",
							insurerType: responseParse.insureIdentity || "",
							insurerCode: responseParse.insurerCode || "",
							birth_date: parseBirthDate(responseParse.birthDate || "")
						};

				if (patientData.id_no) {
					// 這邊
					try {
						const dbResult = await callPatientSearchService(userInfo.token!, "id_no", patientData.id_no);
						console.log("資料庫搜尋結果:", dbResult);

						if (dbResult && dbResult.result === "success") {
							if (dbResult.lists && dbResult.lists.length > 0) {
								updateSearchPatientData({
									keywords: patientData.id_no,
									searchLists: dbResult.lists
								});
								setShowSearchPatientMedicalRecord(true);
							} else if (dbResult.info) {
								if (dbResult.alert && dbResult.alert[0] === "可能為慢性病連續處方箋") {
									setIsChronicStatus(true);
									Swal.fire({
										icon: "warning",
										title: "有慢性病連續處方箋 \n 是否前往連箋領藥？",

										showConfirmButton: true,
										showCancelButton: true,
										confirmButtonText: "是",
										cancelButtonText: "否"
									}).then(result => {
										if (result.isConfirmed) {
											console.log("使用者選擇前往領藥");
											setRegisteredFlowStatus("SequentialPrescription");
										}
									});
								}
								updatePatientInfo({
									...patientInfo,
									info: dbResult.info,
									email: dbResult.email,
									emer: dbResult.emer_contactor,
									address: dbResult.address,
									phone: dbResult.phone
								});

								console.log(patientData, "看一次patientData吧");
								const nhiType = checkInsureTypeToNhiType(patientData.insurerType);
								console.log(nhiType, "看一下nhiType");
								if (nhiType && nhiType !== dbResult.info.nhi_identity) {
									console.log(dbResult.info.nhi_identity, nhiType, "身份不一樣看一下 前面是資料庫 後面是讀卡");
									const { isConfirmed } = await Swal.fire({
										title: "身份不一致",
										text: "讀卡資料與資料庫中的身份不同，是否要更新身份？",
										icon: "warning",
										showCancelButton: true,
										confirmButtonColor: "#3085d6",
										cancelButtonColor: "#d33",
										confirmButtonText: "是，更新身份",
										cancelButtonText: "否，保持原身份"
									});

									if (isConfirmed) {
										console.log("有進try", patientInfo, nhiType);
										const updatedInfo = {
											...patientInfo,
											info: {
												...dbResult.info,
												nhi_identity: nhiType,
												if_drug_allergy: dbResult.info.if_drug_allergy.toString()
											}
										};
										console.log(updatedInfo, "看一下updatedInfo", patientInfo);
										updatePatientInfo({
											...patientInfo,
											info: {
												...dbResult.info,
												nhi_identity: nhiType
											},
											email: dbResult.email,
											emer: dbResult.emer_contactor,
											address: dbResult.address,
											phone: dbResult.phone
										});
										try {
											// const updateResult = await updatePatientInfoHelper(updatedInfo);
											const result = await postPatientInfoService(userInfo.token!, updatedInfo);
											console.log(result, "看一下result");
											if (result.result === "success") {
												console.log("Patient identity updated successfully");
												Swal.fire({
													icon: "success",
													title: "更新成功",
													text: "病患身份已成功更新",
													timer: 1500
												});
												// You might want to show a success message to the user
											} else {
												console.error("Failed to update patient identity:", result.error);
												// Show an error message to the user
											}
										} catch (error) {
											console.error("Error updating patient identity:", error);
											// Show an error message to the user
											Swal.fire({
												icon: "error",
												title: "更新失敗",
												text: "更新病患身份時發生錯誤，請稍後再試",
												timer: 1500
											});
										}
									} else {
										// Keep the original identity
										updatePatientInfo({
											...patientInfo,
											info: dbResult.info,
											email: dbResult.email,
											emer: dbResult.emer_contactor,
											address: dbResult.address,
											phone: dbResult.phone
										});
									}
								} else {
									updatePatientInfo({
										...patientInfo,
										info: dbResult.info,
										email: dbResult.email,
										emer: dbResult.emer_contactor,
										address: dbResult.address,
										phone: dbResult.phone
									});
								}
								setShowSearchPatientMedicalRecord(false);
								setShowPatientManagementStructureStatus(true);
								setRegisteredFlowStatus("second");
							} else {
								handleNewPatient(patientData);
							}
						} else if (dbResult.msg === "查無符合資料") {
							handleNewPatient(patientData);
						} else {
							console.log("資料庫查詢結果異常:", dbResult);
							Swal.fire({
								icon: "error",
								title: "卡片狀態異常",
								text: "請置入卡片或檢查卡片是否置入正確",
								showConfirmButton: true
							});
						}
					} catch (error) {
						console.error("資料庫查詢錯誤:", error);
					}
				} else {
					Swal.fire({
						icon: "error",
						title: "讀卡資料不完整",
						text: "無法獲取病患身分證號",
						timer: 1500
					});
				}
			} else {
				throw new Error("讀卡失敗");
			}
		} catch (error) {
			console.error("讀卡或解析錯誤:", error);
			Swal.fire({
				icon: "error",
				title: "讀卡失敗",
				text: "請確認讀卡機及代理人是否正確",
				timer: 1500
			});
		}
	};
	// useEffect(()=>{
	//     if(nhiTypeForUpdate !== '' && nhiTypeForUpdate){
	//         const result = updatePatientInfoHelper(nhiTypeForUpdate)
	//         console.log(result, '看一下result')
	//         console.log(nhiTypeForUpdate, '看一下nhiTypeForUpdate')
	//         setIsUpdateNhiTypeStatus(false)
	//     }
	// }, [isUpdateNhiTypeStatus])
	const parseBirthDate = (birthDateString: string): Date => {
		let ROCyyy = parseInt(birthDateString.slice(0, 3));
		let ROCmm = parseInt(birthDateString.slice(3, 5));
		let ROCdd = birthDateString.slice(5, 7);
		let ADYear = ROCyyy + 1911;
		let ADMonth = ROCmm - 1;
		return new Date(ADYear, ADMonth, parseInt(ROCdd));
	};

	const handleNewPatient = (patientData: any) => {
		// console.log('初診：新增病患資料');
		updatePatientInfo({
			...patientInfo,
			info: {
				...patientInfo.info,
				...patientData
			}
		});
		setShowPatientManagementStructureStatus(true);
		setRegisteredFlowStatus("first");
	};

	const resetAllRegistrationStores = () => {
		resetCurrentVisitInfoData();
		resetPatientInfo();
		resetVitalSignsData();
		resetSearchPatientData();
	};

	return (
		<>
			<Flex gap={"8px"}>
				<Flex direction={"column"} gap={"8px"}>
					<Button colorScheme='rsPrimary' px={"24px"} py={"10px"} height={"auto"} onClick={handleClickReadICCard}>
						<Box as='span' w={"60px"} mr={"5px"}>
							<Image src={iconICReader} w={"100%"}></Image>
						</Box>
						<Text fontSize={"48px"} fontWeight={"normal"}>
							IC 讀卡
						</Text>
					</Button>
					<Flex gap={"8px"} justify={"space-between"}>
						<Button
							flex={1}
							colorScheme='rsWarning'
							onClick={() => {
								resetAllRegistrationStores();
								setShowSearchPatientMedicalRecord(true);
							}}>
							查詢病患
						</Button>
						<Button
							flex={1}
							colorScheme={"rsSteel"}
							onClick={() => {
								resetAllRegistrationStores();
								setRegisteredFlowStatus("first");
								setShowPatientManagementStructureStatus(true);
								// resetCurrentVisitInfoData();
								// resetPatientInfo();
							}}>
							初診新增
						</Button>
					</Flex>
				</Flex>
				<Button colorScheme='rsSecondary' h={"auto"} onClick={handleClickReadVHIC}>
					虛 <br />
					擬 <br />
					IC <br />
					卡 <br />
					讀 <br />卡
				</Button>
			</Flex>
		</>
	);
};

export default RegistrationButtons;
