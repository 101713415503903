import { create } from "zustand";

// 定義 OpdModelProps 接口
export interface OpdModelProps {
	//IPatientBase
	Pat_Code: string; // 病歷號
	Pat_Name: string; // 病患姓名
	Pat_IdnoType: number; // 身份證件種類 0.身分證 1.護照 2.居留證號
	Pat_IDNo?: string; // 身分證字號(建議)
	Pat_Gender?: string; // 病患性別
	Pat_Birth?: string; // 病患生日(建議)
	Pat_BirthTime?: string; // 出生時間 Ex : 13:00
	Pat_Tel?: string; // 家用電話
	Pat_Mobile?: string; // 行動電話
	Pat_Addr?: string; // 病患地址
	Pat_Memo?: string; // 病患備註
	Pat_Job?: string; // 病患職業
	Pat_Mark?: string; // 病患註記
	//IDoctorBase
	Dr_Code?: string; // 醫師代碼
	Dr_Name: string; // 醫師姓名
	E_Dr_Name?: string; // 醫師英文姓名
	Dr_IDNo?: string; // 醫師身分證
	Dr_Certificate?: string; // 醫師證號
	Dr_Mobile?: string; // 醫師電話
	//IShiftBase
	Shift_Code?: string; // 時段代碼
	Shift_Name: string; // 時段名稱
	//IRoomBase
	RoomSeq?: number; // 第N診
	RoomName?: string; // 診間名稱(建議)
	//IDepartmentBase
	Dept_Code?: string; // 科別代碼
	Dept_Name?: string; // 科別名稱
	//Opd
	OpdDateTime: string; // 掛號日期時間
	OpdStatus: string; // 看診狀態
	Req?: number; // 就診序
	RegNumber?: string; // 掛號序號
	Nhi_Type: string; // 身分
	Nhi_Card?: string; // 卡號
	TreatNumber?: string; // 就醫識別碼
	Fee_HG?: number; // 掛號費
	Fee_CO?: number; // 自付額
	Fee_LPay?: number; // 押單費
	Handler?: string; // 經手人
}

// 定義 OpdModelStore 接口
interface OpdModelStore {
	opdModel: OpdModelProps;
	updateOpdModel: (opdModel: OpdModelProps) => void;
	resetOpdModel: () => void;
}

// 初始化 opdModel 的值
export const initialOpdModel: OpdModelProps = {
	Pat_Code: "",
	Pat_Name: "",
	Pat_IdnoType: 0,
	Pat_IDNo: "",
	Pat_Gender: "",
	Pat_Birth: "",
	Pat_BirthTime: "",
	Pat_Tel: "",
	Pat_Mobile: "",
	Pat_Addr: "",
	Pat_Memo: "",
	Pat_Job: "",
	Pat_Mark: "",
	Dr_Code: "",
	Dr_Name: "",
	E_Dr_Name: "",
	Dr_IDNo: "",
	Dr_Certificate: "",
	Dr_Mobile: "",
	Shift_Code: "",
	Shift_Name: "",
	RoomSeq: 0,
	RoomName: "",
	Dept_Code: "",
	Dept_Name: "",
	OpdDateTime: "",
	OpdStatus: "",
	Req: 0,
	RegNumber: "",
	Nhi_Type: "",
	Nhi_Card: "",
	TreatNumber: "",
	Fee_HG: 0,
	Fee_CO: 0,
	Fee_LPay: 0,
	Handler: ""
};

// 創建 useOpdModelStore
export const useOpdModelStore = create<OpdModelStore>(set => ({
	opdModel: initialOpdModel,
	updateOpdModel: opdModel =>
		set(() => ({
			opdModel: opdModel
		})),
	resetOpdModel: () =>
		set(() => ({
			opdModel: initialOpdModel
		}))
}));

export default useOpdModelStore;
