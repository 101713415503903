import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab, TabList, TabPanel, TabPanels, TabIndicator } from "@chakra-ui/react";
import { Input, Button } from "@chakra-ui/react";
import { Table, Thead, Tbody, Td, Tr } from "@chakra-ui/react";
import { Box, Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import Swal from "sweetalert2";

import { useUnsavedChanges } from "contexts/UnsavedChangesContext";
import { callRegFeeSettingService, callRegFeeSettingChosenService } from "service/regFeeSettingService";
import { postRegFeeSettingService } from "service/regFeeSettingService";
import { deleteRegFeeSettingService } from "service/regFeeSettingService";

import ROCDatePicker from "components/CustomComponents/ROCDatePicker";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import { useUserStore } from "store";
interface feeSettingProps {
	seReg: string;
	seNewReg: string;
	seEmgReg: string;
	seDeposit: string;
	seMeFee: string;
	hiReg: string;
	hiNewReg: string;
	hiEmgReg: string;
	hiDeposit: string;
	hiMeFee: string;
	hcReg: string;
	hcNewReg: string;
	hcEmgReg: string;
	hcDeposit: string;
	hcMeFee: string;
	liReg: string;
	liNewReg: string;
	liEmgReg: string;
	liDeposit: string;
	liMeFee: string;
}
const FeeSettingTable = () => {
	const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();
	// const token = localStorage.getItem('token');
	const { userInfo } = useUserStore();

	const [isActiveDate, setIsActiveDate] = useState(false);
	const [activeDate, setActiveDate] = useState("");
	const [chosenDate, setChosenDate] = useState("");
	// 資料傳輸西元年
	const [dateLists, setDateLists] = useState<string[]>([]);
	// 顯示ROC的日期
	const [ROCDateLists, setROCDateLists] = useState<string[]>([]);
	const rowHeader = ["", "掛號費", "初診掛號費", "急診掛號費", "押單費", "診察費"];

	const colHeader = ["自費", "健保", "榮民", "福保"];

	const initialFeeSetting = {
		seReg: "",
		seNewReg: "",
		seEmgReg: "",
		seDeposit: "",
		seMeFee: "",
		hiReg: "",
		hiNewReg: "",
		hiEmgReg: "",
		hiDeposit: "",
		hiMeFee: "",
		hcReg: "",
		hcNewReg: "",
		hcEmgReg: "",
		hcDeposit: "",
		hcMeFee: "",
		liReg: "",
		liNewReg: "",
		liEmgReg: "",
		liDeposit: "",
		liMeFee: ""
	};
	const [feeSetting, setFeeSetting] = useState<feeSettingProps>(initialFeeSetting); // 给 feeSetting 一个初始值，类型为数组

	//first row se 自費
	const [seReg, setSeReg] = useState("");
	const [seNewReg, setSeNewReg] = useState("");
	const [seEmgReg, setSeEmgReg] = useState("");
	const [seDeposit, setSeDeposit] = useState("");
	const [seMeFee, setSeMeFee] = useState("");
	//second row hi 健保

	const [hiReg, setHiReg] = useState("");
	const [hiNewReg, setHiNewReg] = useState("");
	const [hiEmgReg, setHiEmgReg] = useState("");
	const [hiDeposit, setHiDeposit] = useState("");
	const [hiMeFee, setHiMeFee] = useState("");

	//third row hc 榮民
	const [hcReg, setHcReg] = useState("");
	const [hcNewReg, setHcNewReg] = useState("");
	const [hcEmgReg, setHcEmgReg] = useState("");
	const [hcDeposit, setHcDeposit] = useState("");
	const [hcMeFee, setHcMeFee] = useState("");

	//fourth row li 福保
	const [liReg, setLiReg] = useState("");
	const [liNewReg, setLiNewReg] = useState("");
	const [liEmgReg, setLiEmgReg] = useState("");
	const [liDeposit, setLiDeposit] = useState("");
	const [liMeFee, setLiMeFee] = useState("");

	//first  id row se 自費
	const [seRegId, setSeRegId] = useState("");
	const [seNewRegId, setSeNewRegId] = useState("");
	const [seEmgRegId, setSeEmgRegId] = useState("");
	const [seDepositId, setSeDepositId] = useState("");
	const [seMeFeeId, setSeMeFeeId] = useState("");
	//second id  row hi 健保

	const [hiRegId, setHiRegId] = useState("");
	const [hiNewRegId, setHiNewRegId] = useState("");
	const [hiEmgRegId, setHiEmgRegId] = useState("");
	const [hiDepositId, setHiDepositId] = useState("");
	const [hiMeFeeId, setHiMeFeeId] = useState("");

	//third id row hc 榮民
	const [hcRegId, setHcRegId] = useState("");
	const [hcNewRegId, setHcNewRegId] = useState("");
	const [hcEmgRegId, setHcEmgRegId] = useState("");
	const [hcDepositId, setHcDepositId] = useState("");
	const [hcMeFeeId, setHcMeFeeId] = useState("");

	//fourth id row li 福保
	const [liRegId, setLiRegId] = useState("");
	const [liNewRegId, setLiNewRegId] = useState("");
	const [liEmgRegId, setLiEmgRegId] = useState("");
	const [liDepositId, setLiDepositId] = useState("");
	const [liMeFeeId, setLiMeFeeId] = useState("");

	// check data
	const [checkSelectedDate, setCheckSelectedDate] = useState<Date | null>(null);
	//first row se 自費
	const [checkSeReg, setCheckSeReg] = useState("");
	const [checkSeNewReg, setCheckSeNewReg] = useState("");
	const [checkSeEmgReg, setCheckSeEmgReg] = useState("");
	const [checkSeDeposit, setCheckSeDeposit] = useState("");
	const [checkSeMeFee, setCheckSeMeFee] = useState("");
	//second row hi 健保

	const [checkHiReg, setCheckHiReg] = useState("");
	const [checkHiNewReg, setCheckHiNewReg] = useState("");
	const [checkHiEmgReg, setCheckHiEmgReg] = useState("");
	const [checkHiDeposit, setCheckHiDeposit] = useState("");
	const [checkHiMeFee, setCheckHiMeFee] = useState("");

	//third row hc 榮民
	const [checkHcReg, setCheckHcReg] = useState("");
	const [checkHcNewReg, setCheckHcNewReg] = useState("");
	const [checkHcEmgReg, setCheckHcEmgReg] = useState("");
	const [checkHcDeposit, setCheckHcDeposit] = useState("");
	const [checkHcMeFee, setCheckHcMeFee] = useState("");

	//fourth row li 福保
	const [checkLiReg, setCheckLiReg] = useState("");
	const [checkLiNewReg, setCheckLiNewReg] = useState("");
	const [checkLiEmgReg, setCheckLiEmgReg] = useState("");
	const [checkLiDeposit, setCheckLiDeposit] = useState("");
	const [checkLiMeFee, setCheckLiMeFee] = useState("");

	const [patientManagementFormTableHeight, setPatientManagementFormTableHeight] = useState(0);
	const windowHeight = window.innerHeight;
	const [tableHeight, setTableHeight] = useState(0);
	const outerDivRef = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		function updateHeight() {
			if (outerDivRef.current) {
				const height = outerDivRef.current.clientHeight;
				setPatientManagementFormTableHeight(height);
			}
		}
		updateHeight();
		window.addEventListener("resize", updateHeight);

		return () => {
			window.removeEventListener("resize", updateHeight);
		};
	}, [setPatientManagementFormTableHeight]);
	useEffect(() => {
		const finalHeight = windowHeight - patientManagementFormTableHeight - 140;
		setTableHeight(finalHeight);
	}, [windowHeight, patientManagementFormTableHeight]);
	// 取得
	const fetchData = async () => {
		let today = new Date();
		let formatDate = moment(today).format("YYYY-MM-DD");
		if (userInfo.token) {
			try {
				const fetchedRegFeeSettingLists = await callRegFeeSettingService(userInfo.token);
				if (fetchedRegFeeSettingLists) {
					setActiveDate(fetchedRegFeeSettingLists.active_date);
					setChosenDate(fetchedRegFeeSettingLists.chosen_date);
					setDateLists(fetchedRegFeeSettingLists.date_lists);
					const ROCDateArr: string[] = [];
					fetchedRegFeeSettingLists.date_lists.forEach((item: string) => {
						const yearPart = parseInt(item.substring(0, 4)) - 1911;
						const formattedDate = `${yearPart}${moment(item).format(".MM.DD")}`;
						ROCDateArr.push(formattedDate);
					});
					setROCDateLists(ROCDateArr);

					if (fetchedRegFeeSettingLists.fee_setting) {
						let lists = fetchedRegFeeSettingLists.fee_setting;

						setSeReg(lists.se.reg.fee);
						setSeNewReg(lists.se.new_reg.fee);
						setSeEmgReg(lists.se.emg_reg.fee);
						setSeDeposit(lists.se.deposit.fee);
						setSeMeFee(lists.se.medical_exam_fee.fee);

						setHiReg(lists.hi.reg.fee);
						setHiNewReg(lists.hi.new_reg.fee);
						setHiEmgReg(lists.hi.emg_reg.fee);
						setHiDeposit(lists.hi.deposit.fee);
						setHiMeFee(lists.hi.medical_exam_fee.fee);

						setHcReg(lists.hc.reg.fee);
						setHcNewReg(lists.hc.new_reg.fee);
						setHcEmgReg(lists.hc.emg_reg.fee);
						setHcDeposit(lists.hc.deposit.fee);
						setHcMeFee(lists.hc.medical_exam_fee.fee);

						setLiReg(lists.li.reg.fee);
						setLiNewReg(lists.li.new_reg.fee);
						setLiEmgReg(lists.li.emg_reg.fee);
						setLiDeposit(lists.li.deposit.fee);
						setLiMeFee(lists.li.medical_exam_fee.fee);
						//id
						setSeRegId(lists.se.reg.id);
						setSeNewRegId(lists.se.new_reg.id);
						setSeEmgRegId(lists.se.emg_reg.id);
						setSeDepositId(lists.se.deposit.id);
						setSeMeFeeId(lists.se.medical_exam_fee.id);

						setHiRegId(lists.hi.reg.id);
						setHiNewRegId(lists.hi.new_reg.id);
						setHiEmgRegId(lists.hi.emg_reg.id);
						setHiDepositId(lists.hi.deposit.id);
						setHiMeFeeId(lists.hi.medical_exam_fee.id);

						setHcRegId(lists.hc.reg.id);
						setHcNewRegId(lists.hc.new_reg.id);
						setHcEmgRegId(lists.hc.emg_reg.id);
						setHcDepositId(lists.hc.deposit.id);
						setHcMeFeeId(lists.hc.medical_exam_fee.id);

						setLiRegId(lists.li.reg.id);
						setLiNewRegId(lists.li.new_reg.id);
						setLiEmgRegId(lists.li.emg_reg.id);
						setLiDepositId(lists.li.deposit.id);
						setLiMeFeeId(lists.li.medical_exam_fee.id);
					}
					handleChangeChosenDate(0);
					setSelectedTabIndex(0);
				} else {
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500,
					showConfirmButton: false
				});
			}
		}
	};
	useEffect(() => {
		fetchData();
	}, []);

	//新增 或 編輯
	const postData = async (finalData: object) => {
		if (userInfo.token) {
			try {
				const fetchedRegFeeSettingLists = await postRegFeeSettingService(userInfo.token, finalData);
				if (fetchedRegFeeSettingLists.result === "success") {
					Swal.fire({
						icon: "success",
						title: "新增成功",
						showConfirmButton: false,
						timer: 1500
					});
					newCancelValue();
					fetchData();
					setHasUnsavedChanges(false);
				} else {
					Swal.fire({
						icon: "error",
						title: " 新增失敗",
						showConfirmButton: false, // 隱藏確認按鈕
						timer: 1500 // 設定自動關閉的時間 (1.5 秒)
					});
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500,
					showConfirmButton: false
				});
			}
		}
	};
	const postChangeData = async (finalData: object) => {
		if (userInfo.token) {
			try {
				const fetchedRegFeeSettingLists = await postRegFeeSettingService(userInfo.token, finalData);
				if (fetchedRegFeeSettingLists.result === "success") {
					Swal.fire({
						icon: "success",
						title: "修改成功",
						showConfirmButton: false, // 隱藏確認按鈕
						timer: 1500 // 設定自動關閉的時間 (1.5 秒)
					});
					setHasUnsavedChanges(false);
				} else {
					Swal.fire({
						icon: "error",
						title: " 修改失敗",
						showConfirmButton: false, // 隱藏確認按鈕
						timer: 1500 // 設定自動關閉的時間 (1.5 秒)
					});
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500,
					showConfirmButton: false
				});
			}
		}
	};

	// 新增
	const handleNewFeeSetting = () => {
		let formatDate = moment(selectedDate).format("YYYY-MM-DD");
		if (selectedDate) {
			if (dateLists.includes(formatDate)) {
				Swal.fire({
					icon: "error",
					title: "日期重複",
					showConfirmButton: false, // 隱藏確認按鈕
					timer: 1500 // 設定自動關閉的時間 (1.5 秒)
				});
				return;
			} else {
				let allFieldsFilled = true;
				if (
					!newSeReg ||
					!newSeNewReg ||
					!newSeEmgReg ||
					!newSeDeposit ||
					!newSeMeFee ||
					!newHiReg ||
					!newHiNewReg ||
					!newHiEmgReg ||
					!newHiDeposit ||
					!newHiMeFee ||
					!newHcReg ||
					!newHcNewReg ||
					!newHcEmgReg ||
					!newHcDeposit ||
					!newHcMeFee ||
					!newLiReg ||
					!newLiNewReg ||
					!newLiEmgReg ||
					!newLiDeposit ||
					!newLiMeFee
				) {
					allFieldsFilled = false;
				}
				if (allFieldsFilled) {
					let lists = [
						{
							identity: "se",
							reg_type: "reg",
							fee: newSeReg
						},
						{
							identity: "se",
							reg_type: "new_reg",
							fee: newSeNewReg
						},
						{
							identity: "se",
							reg_type: "emg_reg",
							fee: newSeEmgReg
						},
						{
							identity: "se",
							reg_type: "deposit",
							fee: newSeDeposit
						},
						{
							identity: "se",
							reg_type: "medical_exam_fee",
							fee: newSeMeFee
						},
						// second
						{
							identity: "hi",
							reg_type: "reg",
							fee: newHiReg
						},
						{
							identity: "hi",
							reg_type: "new_reg",
							fee: newHiNewReg
						},
						{
							identity: "hi",
							reg_type: "emg_reg",
							fee: newHiEmgReg
						},
						{
							identity: "hi",
							reg_type: "deposit",
							fee: newHiDeposit
						},
						{
							identity: "hi",
							reg_type: "medical_exam_fee",
							fee: newHiMeFee
						},
						// third hc
						{
							identity: "hc",
							reg_type: "reg",
							fee: newHcReg
						},
						{
							identity: "hc",
							reg_type: "new_reg",
							fee: newHcNewReg
						},
						{
							identity: "hc",
							reg_type: "emg_reg",
							fee: newHcEmgReg
						},
						{
							identity: "hc",
							reg_type: "deposit",
							fee: newHcDeposit
						},
						{
							identity: "hc",
							reg_type: "medical_exam_fee",
							fee: newHcMeFee
						},

						// fourth li
						{
							identity: "li",
							reg_type: "reg",
							fee: newLiReg
						},
						{
							identity: "li",
							reg_type: "new_reg",
							fee: newLiNewReg
						},
						{
							identity: "li",
							reg_type: "emg_reg",
							fee: newLiEmgReg
						},
						{
							identity: "li",
							reg_type: "deposit",
							fee: newLiDeposit
						},
						{
							identity: "li",
							reg_type: "medical_exam_fee",
							fee: newLiMeFee
						}
					];
					let finalData = {
						start_date: formatDate,
						lists: lists
					};

					postData(finalData);
				} else {
					Swal.fire({
						icon: "info",
						title: "儲存失敗，請填妥每個項目金額",
						showConfirmButton: true,
						confirmButtonText: "好"
					});
					return;
				}
			}
		} else {
			Swal.fire({
				icon: "error",
				title: "不能選擇今天或以前的日期",
				showConfirmButton: true,
				confirmButtonText: "好"
			});
			return;
		}
	};
	//編輯
	const handleEdit = () => {
		//更新日期
		setSelectedDate(new Date(dateLists[selectedTabIndex]));
		setCheckSelectedDate(new Date(dateLists[selectedTabIndex]));
		setCheckSeReg(seReg);
		setCheckSeNewReg(seNewReg);
		setCheckSeEmgReg(seEmgReg);
		setCheckSeDeposit(seDeposit);
		setCheckSeMeFee(seMeFee);

		setCheckHiReg(hiReg);
		setCheckHiNewReg(hiNewReg);
		setCheckHiEmgReg(hiEmgReg);
		setCheckHiDeposit(hiDeposit);
		setCheckHiMeFee(hiMeFee);

		setCheckHcReg(hcReg);
		setCheckHcNewReg(hcNewReg);
		setCheckHcEmgReg(hcEmgReg);
		setCheckHcDeposit(hcDeposit);
		setCheckHcMeFee(hcMeFee);

		setCheckLiReg(liReg);
		setCheckLiNewReg(liNewReg);
		setCheckLiEmgReg(liEmgReg);
		setCheckLiDeposit(liDeposit);
		setCheckLiMeFee(liMeFee);

		setEditStatus(true);
	};

	//修改儲存
	const handleSave = () => {
		let formatDate = moment(selectedDate).format("YYYY-MM-DD");
		if (selectedDate) {
			let filteredDateList = dateLists.filter(date => date !== formatDate);
			// 修改這個日期的資料
			if (formatDate === dateLists[selectedTabIndex]) {
				let allFieldsFilled = true;
				const fieldsToCheck = [
					seReg,
					seNewReg,
					seEmgReg,
					seDeposit,
					seMeFee,
					hiReg,
					hiNewReg,
					hiEmgReg,
					hiDeposit,
					hiMeFee,
					hcReg,
					hcNewReg,
					hcEmgReg,
					hcDeposit,
					hcMeFee,
					liReg,
					liNewReg,
					liEmgReg,
					liDeposit,
					liMeFee
				];

				// 使用 for-of 循环检查每个字段
				for (const field of fieldsToCheck) {
					// 检查字段是否未定义、null 或空字符串，而允许 0 作为有效值
					if (field === undefined || field === null || field === "") {
						allFieldsFilled = false;
						break;
					}
				}

				let allFieldsMatch = true;
				let mismatchedFields = [];
				// 如果所有字段都已填写，则检查字段值是否与对应的检查值匹配
				if (allFieldsFilled) {
					if (seReg !== checkSeReg) mismatchedFields.push("seReg");
					if (seNewReg !== checkSeNewReg) mismatchedFields.push("seNewReg");
					if (seEmgReg !== checkSeEmgReg) mismatchedFields.push("seEmgReg");
					if (seDeposit !== checkSeDeposit) mismatchedFields.push("seDeposit");
					if (seMeFee !== checkSeMeFee) mismatchedFields.push("seMeFee");
					if (hiReg !== checkHiReg) mismatchedFields.push("hiReg");
					if (hiNewReg !== checkHiNewReg) mismatchedFields.push("hiNewReg");
					if (hiEmgReg !== checkHiEmgReg) mismatchedFields.push("hiEmgReg");
					if (hiDeposit !== checkHiDeposit) mismatchedFields.push("hiDeposit");
					if (hiMeFee !== checkHiMeFee) mismatchedFields.push("hiMeFee");
					if (hcReg !== checkHcReg) mismatchedFields.push("hcReg");
					if (hcNewReg !== checkHcNewReg) mismatchedFields.push("hcNewReg");
					if (hcEmgReg !== checkHcEmgReg) mismatchedFields.push("hcEmgReg");
					if (hcDeposit !== checkHcDeposit) mismatchedFields.push("hcDeposit");
					if (hcMeFee !== checkHcMeFee) mismatchedFields.push("hcMeFee");
					if (liReg !== checkLiReg) mismatchedFields.push("liReg");
					if (liNewReg !== checkLiNewReg) mismatchedFields.push("liNewReg");
					if (liEmgReg !== checkLiEmgReg) mismatchedFields.push("liEmgReg");
					if (liDeposit !== checkLiDeposit) mismatchedFields.push("liDeposit");
					if (liMeFee !== checkLiMeFee) mismatchedFields.push("liMeFee");

					allFieldsMatch = mismatchedFields.length === 0;
				}

				if (!allFieldsMatch) {
					let lists = [
						{
							data_id: seRegId,
							identity: "se",
							reg_type: "reg",
							fee: seReg
						},
						{
							data_id: seNewRegId,
							identity: "se",
							reg_type: "new_reg",
							fee: seNewReg
						},
						{
							data_id: seEmgRegId,
							identity: "se",
							reg_type: "emg_reg",
							fee: seEmgReg
						},
						{
							data_id: seDepositId,
							identity: "se",
							reg_type: "deposit",
							fee: seDeposit
						},
						{
							data_id: seMeFeeId,
							identity: "se",
							reg_type: "medical_exam_fee",
							fee: seMeFee
						},
						// second
						{
							data_id: hiRegId,
							identity: "hi",
							reg_type: "reg",
							fee: hiReg
						},
						{
							data_id: hiNewRegId,
							identity: "hi",
							reg_type: "new_reg",
							fee: hiNewReg
						},
						{
							data_id: hiEmgRegId,
							identity: "hi",
							reg_type: "emg_reg",
							fee: hiEmgReg
						},
						{
							data_id: hiDepositId,
							identity: "hi",
							reg_type: "deposit",
							fee: hiDeposit
						},
						{
							data_id: hiMeFeeId,
							identity: "hi",
							reg_type: "medical_exam_fee",
							fee: hiMeFee
						},
						// third hc
						{
							data_id: hcRegId,
							identity: "hc",
							reg_type: "reg",
							fee: hcReg
						},
						{
							data_id: hcNewRegId,
							identity: "hc",
							reg_type: "_reg",
							fee: hcReg
						},
						{
							data_id: hcEmgRegId,
							identity: "hc",
							reg_type: "emg_reg",
							fee: hcEmgReg
						},
						{
							data_id: hcDepositId,
							identity: "hc",
							reg_type: "deposit",
							fee: hcDeposit
						},
						{
							data_id: hcMeFeeId,
							identity: "hc",
							reg_type: "medical_exam_fee",
							fee: hcMeFee
						},

						// fourth li
						{
							data_id: liRegId,
							identity: "li",
							reg_type: "reg",
							fee: liReg
						},
						{
							data_id: liNewRegId,
							identity: "li",
							reg_type: "_reg",
							fee: liReg
						},
						{
							data_id: liEmgRegId,
							identity: "li",
							reg_type: "emg_reg",
							fee: liEmgReg
						},
						{
							data_id: liDepositId,
							identity: "li",
							reg_type: "deposit",
							fee: liDeposit
						},
						{
							data_id: liMeFeeId,
							identity: "li",
							reg_type: "medical_exam_fee",
							fee: liMeFee
						}
					];
					const finalData = {
						start_date: formatDate,
						lists: lists
					};
					postChangeData(finalData);
				}
			} else if (filteredDateList.includes(formatDate)) {
				Swal.fire({
					icon: "error",
					title: "日期重複",
					showConfirmButton: false, // 隱藏確認按鈕
					timer: 1500 // 設定自動關閉的時間 (1.5 秒)
				});
				return;
			} else {
				let lists = [
					{
						data_id: seRegId,
						identity: "se",
						reg_type: "reg",
						fee: seReg
					},
					{
						data_id: seNewRegId,
						identity: "se",
						reg_type: "new_reg",
						fee: seNewReg
					},
					{
						data_id: seEmgRegId,
						identity: "se",
						reg_type: "emg_reg",
						fee: seEmgReg
					},
					{
						data_id: seDepositId,
						identity: "se",
						reg_type: "deposit",
						fee: seDeposit
					},
					{
						data_id: seMeFeeId,
						identity: "se",
						reg_type: "medical_exam_fee",
						fee: seMeFee
					},
					// second
					{
						data_id: hiRegId,
						identity: "hi",
						reg_type: "reg",
						fee: hiReg
					},
					{
						data_id: hiNewRegId,
						identity: "hi",
						reg_type: "new_reg",
						fee: hiNewReg
					},
					{
						data_id: hiEmgRegId,
						identity: "hi",
						reg_type: "emg_reg",
						fee: hiEmgReg
					},
					{
						data_id: hiDepositId,
						identity: "hi",
						reg_type: "deposit",
						fee: hiDeposit
					},
					{
						data_id: hiMeFeeId,
						identity: "hi",
						reg_type: "medical_exam_fee",
						fee: hiMeFee
					},
					// third hc
					{
						data_id: hcRegId,
						identity: "hc",
						reg_type: "reg",
						fee: hcReg
					},
					{
						data_id: hcNewRegId,
						identity: "hc",
						reg_type: "_reg",
						fee: hcReg
					},
					{
						data_id: hcEmgRegId,
						identity: "hc",
						reg_type: "emg_reg",
						fee: hcEmgReg
					},
					{
						data_id: hcDepositId,
						identity: "hc",
						reg_type: "deposit",
						fee: hcDeposit
					},
					{
						data_id: hcMeFeeId,
						identity: "hc",
						reg_type: "medical_exam_fee",
						fee: hcMeFee
					},

					// fourth li
					{
						data_id: liRegId,
						identity: "li",
						reg_type: "reg",
						fee: liReg
					},
					{
						data_id: liNewRegId,
						identity: "li",
						reg_type: "_reg",
						fee: liReg
					},
					{
						data_id: liEmgRegId,
						identity: "li",
						reg_type: "emg_reg",
						fee: liEmgReg
					},
					{
						data_id: liDepositId,
						identity: "li",
						reg_type: "deposit",
						fee: liDeposit
					},
					{
						data_id: liMeFeeId,
						identity: "li",
						reg_type: "medical_exam_fee",
						fee: liMeFee
					}
				];
				const finalData = {
					start_date: formatDate,
					lists: lists
				};
				postData(finalData);
			}
		}
		setEditStatus(false);
	};
	// 刪除
	const handleDelete = () => {
		const selectedDate = dateLists[selectedTabIndex]; // 获取当前选中的日期
		const rocYear = parseInt(selectedDate.substring(0, 4)) - 1911;
		const rocDate = `${rocYear}${moment(selectedDate).format(".MM.DD")}`;
		if (userInfo.token) {
			Swal.fire({
				title: `是否要將「${rocDate}」\n的資料刪除？`,
				icon: "question",
				showCancelButton: true,
				confirmButtonText: "是",
				cancelButtonText: "否"
			}).then(result => {
				if (result.isConfirmed) {
					const deleteData = async () => {
						try {
							const fetchedFeeSettingLists = await deleteRegFeeSettingService(userInfo.token, selectedDate);
							if (fetchedFeeSettingLists.result === "success") {
								Swal.fire({
									icon: "success",
									title: "刪除成功",
									showConfirmButton: false,
									timer: 1500
								});
								fetchData();
							} else {
								Swal.fire({
									icon: "error",
									title: "刪除失败",
									showConfirmButton: false,
									timer: 1500
								});
							}
						} catch {
							Swal.fire({
								icon: "error",
								title: "後端服務錯誤",
								timer: 1500,
								showConfirmButton: false
							});
						}
					};
					deleteData();
					setHasUnsavedChanges(false);
				} else {
					return;
				}
			});
		}
	};
	// 切分頁
	//有可能這裡讓event兩次
	const handleChangeChosenDate = (index: number) => {
		setEditStatus(false);
		const fetchDataChosen = async () => {
			// let today = new Date();
			// let formatDate = moment(today).format('YYYY-MM-DD');
			if (userInfo.token) {
				try {
					const fetchedRegFeeSettingLists = await callRegFeeSettingChosenService(userInfo.token, dateLists[index]);

					setActiveDate(fetchedRegFeeSettingLists.active_date);
					setChosenDate(fetchedRegFeeSettingLists.chosen_date);
					setDateLists(fetchedRegFeeSettingLists.date_lists);
					const ROCDateArr: string[] = [];
					fetchedRegFeeSettingLists.date_lists.forEach((item: string) => {
						const yearPart = parseInt(item.substring(0, 4)) - 1911;
						const formattedDate = `${yearPart}${moment(item).format(".MM.DD")}`;
						ROCDateArr.push(formattedDate);
					});
					setROCDateLists(ROCDateArr);
					if (fetchedRegFeeSettingLists.fee_setting) {
						let lists = fetchedRegFeeSettingLists.fee_setting;
						setSeReg(lists.se.reg.fee);
						setSeNewReg(lists.se.new_reg.fee);
						setSeEmgReg(lists.se.emg_reg.fee);
						setSeDeposit(lists.se.deposit.fee);
						setSeMeFee(lists.se.medical_exam_fee.fee);

						setHiReg(lists.hi.reg.fee);
						setHiNewReg(lists.hi.new_reg.fee);
						setHiEmgReg(lists.hi.emg_reg.fee);
						setHiDeposit(lists.hi.deposit.fee);
						setHiMeFee(lists.hi.medical_exam_fee.fee);

						setHcReg(lists.hc.reg.fee);
						setHcNewReg(lists.hc.new_reg.fee);
						setHcEmgReg(lists.hc.emg_reg.fee);
						setHcDeposit(lists.hc.deposit.fee);
						setHcMeFee(lists.hc.medical_exam_fee.fee);

						setLiReg(lists.li.reg.fee);
						setLiNewReg(lists.li.new_reg.fee);
						setLiEmgReg(lists.li.emg_reg.fee);
						setLiDeposit(lists.li.deposit.fee);
						setLiMeFee(lists.li.medical_exam_fee.fee);
						setSelectedDate(lists.chosen_date);
					}
				} catch {
					Swal.fire({
						icon: "error",
						title: "後端服務錯誤",
						timer: 1500,
						showConfirmButton: false
					});
				}
			}
		};
		fetchDataChosen();
	};
	// declare

	const handleCancelNew = () => {
		if (hasUnsavedChanges) {
			Swal.fire({
				title: "資料尚未儲存，是否要離開？",
				icon: "question",
				showCancelButton: true,
				confirmButtonText: "是",
				cancelButtonText: "否"
			}).then(result => {
				if (result.isConfirmed) {
					setSelectedTabIndex(0);
					setHasUnsavedChanges(false);
					setSelectedDate(null);
					newCancelValue();
				}
				return;
			});
		} else {
			setSelectedTabIndex(selectedTabIndex - 1);
		}
	};
	// if editStatus is true equal dont show new tab
	const [showNewTab, setShowNewTab] = useState(true);

	const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
	const [preSelectedDate, setPreSelectedDate] = useState<Date | null>(null);
	const handleChangeDate = (selectedDate: Date | null) => {
		setSelectedDate(selectedDate);
	};
	const handleFocusDate = (_: React.FocusEvent<HTMLInputElement>) => {
		setPreSelectedDate(selectedDate);
		setSelectedDate(null);
	};

	// const handleBlur = (event: any) => {
	//     if (event.currentTarget.value.length < 6) {
	//         setSelectedDate(preSelectedDate);
	//         return;
	//     }

	//     if (event.currentTarget.value.length === 7) {
	//         const enteredValue = event.currentTarget.value;
	//         const year = parseInt(enteredValue.substring(0, 3)) + 1911;
	//         const month = enteredValue.substring(3, 5);
	//         const day = enteredValue.substring(5, 7);

	//         const enteredDate = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');
	//         //若輸入時間為之前 則顯示今天
	//         if (enteredDate.isBefore(moment())) {
	//             setSelectedDate(preSelectedDate);
	//             return;
	//         }
	//         const parsedDate = moment(`${year}.${month}.${day}`, 'YYYY.MM.DD');
	//         if (parsedDate.isValid()) {
	//             handleChangeDate(parsedDate.toDate());
	//         }
	//     } else if (event.currentTarget.value.length === 6) {
	//         const enteredValue = event.currentTarget.value;
	//         const year = parseInt(`0${enteredValue.substring(0, 2)}`) + 1911;
	//         const month = enteredValue.substring(2, 4);
	//         const day = enteredValue.substring(4, 6);
	//         const enteredDate = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');
	//         //若輸入時間為之前 則顯示今天
	//         if (enteredDate.isBefore(moment())) {
	//             setSelectedDate(preSelectedDate);
	//             return;
	//         }
	//         const parsedDate = moment(`${year}.${month}.${day}`, 'YYYY.MM.DD');
	//         if (parsedDate.isValid()) {
	//             handleChangeDate(parsedDate.toDate());
	//         }
	//     }
	// };
	const handleBlur = (event: any) => {
		const enteredValue = event.currentTarget.value.replace(/\./g, ""); // 移除所有點，方便處理

		if (enteredValue.length < 6) {
			setSelectedDate(preSelectedDate); // 輸入日期有問題恢復到先前的日期
			return;
		}

		const yearOffset = enteredValue.length === 7 ? 0 : 1; // 根據輸入長度決定年
		const year = parseInt(enteredValue.substring(0, 3 - yearOffset)) + 1911;
		const month = enteredValue.substring(3 - yearOffset, 5 - yearOffset);
		const day = enteredValue.substring(5 - yearOffset);

		const formattedDate = `${year}-${month}-${day}`;
		const enteredDate = moment(formattedDate, "YYYY-MM-DD");

		// 檢查日期
		if (!enteredDate.isValid() || enteredDate.isBefore(moment())) {
			setSelectedDate(preSelectedDate); // 如果日期有誤或在今天前回到上次成功的值
		} else {
			handleChangeDate(enteredDate.toDate()); // 更新日期
		}
	};

	const handleFeeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		const { name, value } = event.target;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setSeReg(inputElement);
			setFeeSetting((prev: feeSettingProps) => ({
				...prev,
				[name]: value
			}));
		}
	};

	// 修改的input
	const handleChangeSeReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setSeReg(inputElement);
		}
	};
	const handleChangeSeNewReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setSeNewReg(inputElement);
		}
	};
	const handleChangeSeEmgReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setSeEmgReg(inputElement);
		}
	};
	const handleChangeSeDeposit = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setSeDeposit(inputElement);
		}
	};
	const handleChangeSeMeFee = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setSeMeFee(inputElement);
		}
	};

	const handleChangeHiReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setHiReg(inputElement);
		}
	};
	const handleChangeHiNewReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setHiNewReg(inputElement);
		}
	};
	const handleChangeHiEmgReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setHiEmgReg(inputElement);
		}
	};
	const handleChangeHiDeposit = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setHiDeposit(inputElement);
		}
	};
	const handleChangeHiMeFee = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setHiMeFee(inputElement);
		}
	};

	const handleChangeHcReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setHcReg(inputElement);
		}
	};
	const handleChangeHcNewReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setHcNewReg(inputElement);
		}
	};
	const handleChangeHcEmgReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setHcEmgReg(inputElement);
		}
	};
	const handleChangeHcDeposit = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setHcDeposit(inputElement);
		}
	};
	const handleChangeHcMeFee = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setHcMeFee(inputElement);
		}
	};

	const handleChangeLiReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setLiReg(inputElement);
		}
	};
	const handleChangeLiNewReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setLiNewReg(inputElement);
		}
	};
	const handleChangeLiEmgReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setLiEmgReg(inputElement);
		}
	};
	const handleChangeLiDeposit = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setLiDeposit(inputElement);
		}
	};
	const handleChangeLiMeFee = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setLiMeFee(inputElement);
		}
	};

	const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
	const handleChangeTabIndex = (event: number) => {
		if (hasUnsavedChanges && event !== selectedTabIndex) {
			Swal.fire({
				title: "資料尚未儲存，是否要離開？",
				icon: "question",
				showCancelButton: true,
				confirmButtonText: "是",
				cancelButtonText: "否"
			}).then(result => {
				if (result.isConfirmed) {
					setHasUnsavedChanges(false);

					newCancelValue();

					handleCancel();
					setEditStatus(false);
					setSelectedTabIndex(event);
				}
			});
		} else {
			setSelectedTabIndex(event);
		}
	};
	const newCancelValue = () => {
		setNewSeReg("");
		setNewSeNewReg("");
		setNewSeEmgReg("");
		setNewSeDeposit("");
		setNewSeMeFee("");

		setNewHiReg("");
		setNewHiNewReg("");
		setNewHiEmgReg("");
		setNewHiDeposit("");
		setNewHiMeFee("");

		setNewHcReg("");
		setNewHcNewReg("");
		setNewHcEmgReg("");
		setNewHcDeposit("");
		setNewHcMeFee("");

		setNewLiReg("");
		setNewLiNewReg("");
		setNewLiEmgReg("");
		setNewLiDeposit("");
		setNewLiMeFee("");
	};

	const [editStatus, setEditStatus] = useState(false);
	const cancelValue = () => {
		setSeReg(checkSeReg);
		setSeNewReg(checkSeNewReg);
		setSeEmgReg(checkSeEmgReg);
		setSeDeposit(checkSeDeposit);
		setSeMeFee(checkSeMeFee);

		setHiReg(checkHiReg);
		setHiNewReg(checkHiNewReg);
		setHiEmgReg(checkHiEmgReg);
		setHiDeposit(checkHiDeposit);
		setHiMeFee(checkHiMeFee);

		setHcReg(checkHcReg);
		setHcNewReg(checkHcNewReg);
		setHcEmgReg(checkHcEmgReg);
		setHcDeposit(checkHcDeposit);
		setHcMeFee(checkHcMeFee);

		setLiReg(checkLiReg);
		setLiNewReg(checkLiNewReg);
		setLiEmgReg(checkLiEmgReg);
		setLiDeposit(checkLiDeposit);
		setLiMeFee(checkLiMeFee);
	};
	const handleCancel = () => {
		//當有變動 要問是否需要

		// 恢復原本
		cancelValue();
		setEditStatus(false);
		setHasUnsavedChanges(false);
	};

	useEffect(() => {
		setIsActiveDate(chosenDate === activeDate);
	}, [chosenDate]);

	/* new input */
	const handleChangeNewSeReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setNewSeReg(inputElement);
			setHasUnsavedChanges(true);
		}
	};

	const handleChangeNewSeNewReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setNewSeNewReg(inputElement);
		}
	};
	const handleChangeNewSeEmgReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setNewSeEmgReg(inputElement);
		}
	};
	const handleChangeNewSeDeposit = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setNewSeDeposit(inputElement);
		}
	};
	const handleChangeNewSeMeFee = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setNewSeMeFee(inputElement);
		}
	};

	const handleChangeNewHiReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setNewHiReg(inputElement);
		}
	};
	const handleChangeNewHiNewReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setNewHiNewReg(inputElement);
		}
	};
	const handleChangeNewHiEmgReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setNewHiEmgReg(inputElement);
		}
	};
	const handleChangeNewHiDeposit = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setNewHiDeposit(inputElement);
		}
	};
	const handleChangeNewHiMeFee = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setNewHiMeFee(inputElement);
		}
	};

	const handleChangeNewHcReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setNewHcReg(inputElement);
		}
	};
	const handleChangeNewHcNewReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setNewHcNewReg(inputElement);
		}
	};
	const handleChangeNewHcEmgReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setNewHcEmgReg(inputElement);
		}
	};
	const handleChangeNewHcDeposit = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setNewHcDeposit(inputElement);
		}
	};
	const handleChangeNewHcMeFee = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setNewHcMeFee(inputElement);
		}
	};

	const handleChangeNewLiReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setNewLiReg(inputElement);
		}
	};
	const handleChangeNewLiNewReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setNewLiNewReg(inputElement);
		}
	};
	const handleChangeNewLiEmgReg = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setNewLiEmgReg(inputElement);
		}
	};
	const handleChangeNewLiDeposit = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setNewLiDeposit(inputElement);
		}
	};
	const handleChangeNewLiMeFee = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		if (/^[0-9]*$/.test(inputElement)) {
			setHasUnsavedChanges(true);
			setNewLiMeFee(inputElement);
		}
	};

	//first row se 自費
	const [newSeReg, setNewSeReg] = useState("");
	const [newSeNewReg, setNewSeNewReg] = useState("");
	const [newSeEmgReg, setNewSeEmgReg] = useState("");
	const [newSeDeposit, setNewSeDeposit] = useState("");
	const [newSeMeFee, setNewSeMeFee] = useState("");
	//second row hi 健保

	const [newHiReg, setNewHiReg] = useState("");
	const [newHiNewReg, setNewHiNewReg] = useState("");
	const [newHiEmgReg, setNewHiEmgReg] = useState("");
	const [newHiDeposit, setNewHiDeposit] = useState("");
	const [newHiMeFee, setNewHiMeFee] = useState("");

	//third row hc 榮民
	const [newHcReg, setNewHcReg] = useState("");
	const [newHcNewReg, setNewHcNewReg] = useState("");
	const [newHcEmgReg, setNewHcEmgReg] = useState("");
	const [newHcDeposit, setNewHcDeposit] = useState("");
	const [newHcMeFee, setNewHcMeFee] = useState("");

	//fourth row li 福保
	const [newLiReg, setNewLiReg] = useState("");
	const [newLiNewReg, setNewLiNewReg] = useState("");
	const [newLiEmgReg, setNewLiEmgReg] = useState("");
	const [newLiDeposit, setNewLiDeposit] = useState("");
	const [newLiMeFee, setNewLiMeFee] = useState("");

	return (
		<>
			<Tabs variant='none' onChange={handleChangeTabIndex} index={selectedTabIndex}>
				<TabList bg={"gray.50"} p={"8px 16px"} rounded={"15px"} borderBottom={"2px solid #ccc"}>
					{dateLists &&
						ROCDateLists &&
						ROCDateLists.map((item, index) => (
							<Tab
								key={index}
								opacity={selectedTabIndex === index ? "1" : "0.5"}
								// borderBottom={'2px solid'}
								borderBottomColor={selectedTabIndex === index ? "rsPrimary.500" : "transparent"}
								onClick={() => handleChangeChosenDate(index)}>
								<Flex align={"center"} gap={"4px"}>
									<Box textColor={"rsPrimary.500"}>
										<HeroIcons name='dollar-fill'></HeroIcons>
									</Box>
									<Box>{item}</Box>
								</Flex>
							</Tab>
						))}

					{showNewTab && (
						<>
							<Tab key={ROCDateLists.length} opacity={selectedTabIndex === ROCDateLists.length ? "1" : "0.5"} border={0}>
								<Flex align={"center"} gap={"4px"}>
									<Box textColor={"rsPrimary.500"}>
										<HeroIcons name='add-fill'></HeroIcons>
									</Box>
									<Box>新增費用設定</Box>
								</Flex>
							</Tab>
						</>
					)}
				</TabList>
				<TabIndicator mt='-16px' height='2px' bg='#9B9B7A' borderRadius='1px' />

				<TabPanels mt={"8px"} bg={"gray.50"} p={"16px"} rounded={"15px"} borderBottom={"2px solid #ccc"}>
					{/* 修改費用設定 */}
					{dateLists &&
						ROCDateLists &&
						ROCDateLists.map((item, index) => (
							<>
								<TabPanel key={index} p={0}>
									<Flex gap={"5px"} align={"center"}>
										<Box textStyle={"semiTitle"}>啟動日期：</Box>
										{editStatus ? (
											<>
												<Box bg={"#fff"}>
													<ROCDatePicker
														onChange={handleChangeDate}
														selectedDate={selectedDate}
														handleChangeDate={handleChangeDate}
														className='border-2 rounded-base box-border w-auto h-full '
														onFocus={handleFocusDate}
														onBlur={handleBlur}
														minDate={new Date()}
													/>
												</Box>
											</>
										) : (
											<>
												<Box>
													<Text p={"8px 16px"}>{item}</Text>
												</Box>
												<Text
													bgColor={"#88ffcd"}
													textColor={"#006a3e"}
													fontSize={"14px"}
													fontWeight={"bold"}
													p={"4px 16px"}
													hidden={index !== 0}
													boxShadow={"0 3px 5px rgba(0,0,0,0.16)"}>
													啟動中
												</Text>
											</>
										)}
									</Flex>

									<Box mb={"16px"}>
										<Flex justify={"space-between"} borderBottom={"1px solid #868C6E"}>
											{rowHeader &&
												rowHeader.map((item, index) => (
													<Box key={index} flex={1} px={"10px"}>
														<Text py={"8px"} textStyle={"semiTitle"} textAlign={"right"}>
															{item}
														</Text>
													</Box>
												))}
										</Flex>
										<Flex>
											<Box flex={1}>
												<Flex direction={"column"}>
													{colHeader &&
														colHeader.map((item, index) => (
															<Box key={index} p={"10px"} borderBottom={"1px solid #e1e1e1"}>
																<Text padding={"8px 16px"} textAlign={"center"} textStyle={"semibold"}>
																	{item}
																</Text>
															</Box>
														))}
												</Flex>
											</Box>
											<Box flex={5}>
												{/* 自費 Se 掛號 初診 急診 押單 診察 */}
												<Flex w={"100%"} justify={"space-between"} borderBottom={"1px solid #e1e1e1"}>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeSeReg}
																name='seReg'
																value={seReg}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{seReg}
															</Text>
														)}
													</Box>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeSeNewReg}
																name='seNewReg'
																value={seNewReg}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{seNewReg}
															</Text>
														)}
													</Box>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeSeEmgReg}
																name='seEmgReg'
																value={seEmgReg}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{seEmgReg}
															</Text>
														)}
													</Box>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeSeDeposit}
																name='seDeposit'
																value={seDeposit}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{seDeposit}
															</Text>
														)}
													</Box>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeSeMeFee}
																name='seMeFee'
																value={seMeFee}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{seMeFee}
															</Text>
														)}
													</Box>
												</Flex>

												{/* 健保 Hi 掛號 初診 急診 押單 診察 */}
												<Flex w={"100%"} justify={"space-between"} borderBottom={"1px solid #e1e1e1"}>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeHiReg}
																name='hiReg'
																value={hiReg}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{hiReg}
															</Text>
														)}
													</Box>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeHiNewReg}
																name='hiNewReg'
																value={hiNewReg}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{hiNewReg}
															</Text>
														)}
													</Box>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeHiEmgReg}
																name='hiEmgReg'
																value={hiEmgReg}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{hiEmgReg}
															</Text>
														)}
													</Box>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeHiDeposit}
																name='hiDeposit'
																value={hiDeposit}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{hiDeposit}
															</Text>
														)}
													</Box>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeHiMeFee}
																name='hiMeFee'
																value={hiMeFee}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{hiMeFee}
															</Text>
														)}
													</Box>
												</Flex>

												{/* 榮民 Hc 掛號 初診 急診 押單 診察 */}
												<Flex w={"100%"} justify={"space-between"} borderBottom={"1px solid #e1e1e1"}>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeHcReg}
																name='hcReg'
																value={hcReg}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{hcReg}
															</Text>
														)}
													</Box>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeHcNewReg}
																name='hcNewReg'
																value={hcNewReg}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{hcNewReg}
															</Text>
														)}
													</Box>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeHcEmgReg}
																name='hcEmgReg'
																value={hcEmgReg}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{hcEmgReg}
															</Text>
														)}
													</Box>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeHcDeposit}
																name='hcDeposit'
																value={hcDeposit}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{hcDeposit}
															</Text>
														)}
													</Box>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeHcMeFee}
																name='hcMeFee'
																value={hcMeFee}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{hcMeFee}
															</Text>
														)}
													</Box>
												</Flex>

												{/* 福保 Li 掛號 初診 急診 押單 診察 */}
												<Flex w={"100%"} justify={"space-between"} borderBottom={"1px solid #e1e1e1"}>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeLiReg}
																name='liReg'
																value={liReg}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{liReg}
															</Text>
														)}
													</Box>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeLiNewReg}
																name='liNewReg'
																value={liNewReg}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{liNewReg}
															</Text>
														)}
													</Box>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeLiEmgReg}
																name='liEmgReg'
																value={liEmgReg}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{liEmgReg}
															</Text>
														)}
													</Box>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeLiDeposit}
																name='liDeposit'
																value={liDeposit}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{liDeposit}
															</Text>
														)}
													</Box>
													<Box flex={1} p={"10px"}>
														{editStatus ? (
															<Input
																bg={"#fff"}
																maxLength={7}
																onChange={handleChangeLiMeFee}
																name='liMeFee'
																value={liMeFee}
																textAlign={"right"}></Input>
														) : (
															<Text bg={"#fff"} rounded={"4px"} textAlign={"right"} p={"8px 16px"}>
																{liMeFee}
															</Text>
														)}
													</Box>
												</Flex>
											</Box>
										</Flex>
									</Box>
									{editStatus ? (
										<Flex justify={"flex-end"} py={"8px"} gap={"8px"}>
											<Button colorScheme='rsSteel' onClick={handleCancel}>
												取消
											</Button>
											<Button colorScheme='rsSecondary' onClick={handleSave}>
												儲存
											</Button>
										</Flex>
									) : (
										<>
											<Flex justify={"flex-end"} py={"8px"} gap={"8px"}>
												<Button colorScheme='rsDanger' onClick={handleDelete} isDisabled={isActiveDate}>
													刪除
												</Button>
												<Button colorScheme='rsWarning' onClick={handleEdit} isDisabled={isActiveDate}>
													編輯
												</Button>
											</Flex>

											<Text textColor={"rsWarning.500"} py={"8px"} textAlign={"right"} hidden={!isActiveDate}>
												*<b className='underline'>無法修改</b>
												當日正在啟用的費用設定
											</Text>
										</>
									)}
								</TabPanel>
							</>
						))}

					{/* 新增費用設定 */}
					{showNewTab && (
						<>
							<TabPanel width={"full"} p={0}>
								<Flex gap={"5px"} align={"center"}>
									<Box textStyle={"semiTitle"}>啟動日期：</Box>

									<Box bg={"#fff"}>
										<ROCDatePicker
											onChange={handleChangeDate}
											selectedDate={selectedDate}
											handleChangeDate={handleChangeDate}
											className='border-2 rounded-base box-border w-auto h-full '
											onFocus={handleFocusDate}
											onBlur={handleBlur}
											minDate={new Date()}
										/>
									</Box>
								</Flex>

								<Box mb={"16px"}>
									{/* 表頭 Header 掛號、初診、急診、押單、診察 */}
									<Flex justify={"space-between"} borderBottom={"1px solid #868C6E"}>
										{rowHeader &&
											rowHeader.map((item, index) => (
												<Box key={index} flex={1} px={"10px"}>
													<Text py={"8px"} textStyle={"semiTitle"} textAlign={"right"}>
														{item}
													</Text>
												</Box>
											))}
									</Flex>

									{/* 表體 body */}
									<Flex>
										{/* 自費、健保、榮民、福保 */}
										<Box flex={1}>
											<Flex direction={"column"}>
												{colHeader &&
													colHeader.map((item, index) => (
														<Box key={index} p={"10px"} borderBottom={"1px solid #e1e1e1"}>
															<Text p={"8px 16px"} textAlign={"center"} textStyle={"semibold"}>
																{item}
															</Text>
														</Box>
													))}
											</Flex>
										</Box>
										<Box flex={5}>
											{/* 自費 Se 掛號 初診 急診 押單 診察 */}
											<Flex w={"100%"} justify={"space-between"} borderBottom={"1px solid #e1e1e1"}>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewSeReg}
														name='newSeReg'
														value={newSeReg}
														textAlign={"right"}></Input>
												</Box>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewSeNewReg}
														name='newSeNewReg'
														value={newSeNewReg}
														textAlign={"right"}></Input>
												</Box>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewSeEmgReg}
														name='newSeEmgReg'
														value={newSeEmgReg}
														textAlign={"right"}></Input>
												</Box>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewSeDeposit}
														name='newSeDeposit'
														value={newSeDeposit}
														textAlign={"right"}></Input>
												</Box>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewSeMeFee}
														name='newSeMeFee'
														value={newSeMeFee}
														textAlign={"right"}></Input>
												</Box>
											</Flex>

											{/* 健保 Hi 掛號 初診 急診 押單 診察 */}
											<Flex w={"100%"} justify={"space-between"} borderBottom={"1px solid #e1e1e1"}>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewHiReg}
														name='newHiReg'
														value={newHiReg}
														textAlign={"right"}></Input>
												</Box>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewHiNewReg}
														name='newHiNewReg'
														value={newHiNewReg}
														textAlign={"right"}></Input>
												</Box>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewHiEmgReg}
														name='newHiEmgReg'
														value={newHiEmgReg}
														textAlign={"right"}></Input>
												</Box>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewHiDeposit}
														name='newHiDeposit'
														value={newHiDeposit}
														textAlign={"right"}></Input>
												</Box>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewHiMeFee}
														name='newHiMeFee'
														value={newHiMeFee}
														textAlign={"right"}></Input>
												</Box>
											</Flex>

											{/* 榮民 Hc 掛號 初診 急診 押單 診察 */}
											<Flex w={"100%"} justify={"space-between"} borderBottom={"1px solid #e1e1e1"}>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewHcReg}
														name='newHcReg'
														value={newHcReg}
														textAlign={"right"}></Input>
												</Box>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewHcNewReg}
														name='newHcNewReg'
														value={newHcNewReg}
														textAlign={"right"}></Input>
												</Box>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewHcEmgReg}
														name='newHcEmgReg'
														value={newHcEmgReg}
														textAlign={"right"}></Input>
												</Box>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewHcDeposit}
														name='newHcDeposit'
														value={newHcDeposit}
														textAlign={"right"}></Input>
												</Box>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewHcMeFee}
														name='newHcMeFee'
														value={newHcMeFee}
														textAlign={"right"}></Input>
												</Box>
											</Flex>

											{/* 福保 Li 掛號 初診 急診 押單 診察 */}
											<Flex w={"100%"} justify={"space-between"} borderBottom={"1px solid #e1e1e1"}>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewLiReg}
														name='newLiReg'
														value={newLiReg}
														textAlign={"right"}></Input>
												</Box>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewLiNewReg}
														name='newLiNewReg'
														value={newLiNewReg}
														textAlign={"right"}></Input>
												</Box>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewLiEmgReg}
														name='newLiEmgReg'
														value={newLiEmgReg}
														textAlign={"right"}></Input>
												</Box>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewLiDeposit}
														name='newLiDeposit'
														value={newLiDeposit}
														textAlign={"right"}></Input>
												</Box>
												<Box flex={1} p={"10px"}>
													<Input
														bg={"#fff"}
														maxLength={7}
														onChange={handleChangeNewLiMeFee}
														name='newLiMeFee'
														value={newLiMeFee}
														textAlign={"right"}></Input>
												</Box>
											</Flex>
										</Box>
									</Flex>
								</Box>
								<Flex justify={"flex-end"} py={"8px"} gap={"8px"}>
									<Button colorScheme='rsSteel' onClick={handleCancelNew}>
										取消
									</Button>
									<Button colorScheme='rsSecondary' onClick={handleNewFeeSetting}>
										儲存
									</Button>
								</Flex>
							</TabPanel>
						</>
					)}
				</TabPanels>
			</Tabs>
		</>
	);
};
export default FeeSettingTable;
