import React from "react";
import { Box, Text, Flex, Center } from "@chakra-ui/react";
import { textEllipsisOverflow } from "style/chakraStyles/textStyles";

export const Points = () => {
	return (
		<Box w={"50%"}>
			<Text fontWeight={"semibold"} textColor={"rsPrimary.500"} mb={"8px"}>
				申報點數明細
			</Text>

			<Flex pos={"relative"} justify={"space-between"}>
				<Box flexBasis={"calc(50% - 1px)"}>
					<Flex pos={"relative"} p={"2px"} gap={"0px"}>
						<Text flexGrow={"1"} fontWeight={"semibold"}>
							診察費
						</Text>
						<Text flexShrink={0} textAlign={"right"} px={"4px"} sx={textEllipsisOverflow} w={"54px"}>
							0
						</Text>
					</Flex>
					<Flex pos={"relative"} p={"2px"} gap={"0px"}>
						<Text flexGrow={"1"} fontWeight={"semibold"}>
							診療費
						</Text>
						<Text flexShrink={0} textAlign={"right"} px={"4px"} sx={textEllipsisOverflow} w={"54px"}>
							0
						</Text>
					</Flex>
					<Flex pos={"relative"} p={"2px"} gap={"0px"}>
						<Text flexGrow={"1"} fontWeight={"semibold"}>
							藥費
						</Text>
						<Text flexShrink={0} textAlign={"right"} px={"4px"} sx={textEllipsisOverflow} w={"54px"}>
							0
						</Text>
					</Flex>
					<Flex pos={"relative"} p={"2px"} gap={"0px"}>
						<Text flexGrow={"1"} fontWeight={"semibold"}>
							藥事服務費
						</Text>
						<Text flexShrink={0} textAlign={"right"} px={"4px"} sx={textEllipsisOverflow} w={"54px"}>
							0
						</Text>
					</Flex>
					<Flex pos={"relative"} p={"2px"} gap={"0px"}>
						<Text flexGrow={"1"} fontWeight={"semibold"}>
							材料費
						</Text>
						<Text flexShrink={0} textAlign={"right"} px={"4px"} sx={textEllipsisOverflow} w={"54px"}>
							0
						</Text>
					</Flex>
				</Box>
				<Box pos={"absolute"} top={0} left={"50%"} bg={"#646B48"} w={"1px"} h={"100%"}></Box>
				<Box flexBasis={"calc(50% - 1px)"}>
					<Flex pos={"relative"} p={"2px"} gap={"0px"}>
						<Text fontWeight={"semibold"} px={"4px"}>
							基本負擔
						</Text>
						<Text flexShrink={0} textAlign={"right"} sx={textEllipsisOverflow} w={"54px"} pr={"8px"}>
							0
						</Text>
					</Flex>
					<Flex pos={"relative"} p={"2px"} gap={"0px"}>
						<Text fontWeight={"semibold"} px={"4px"}>
							高檢負擔
						</Text>
						<Text flexShrink={0} textAlign={"right"} sx={textEllipsisOverflow} w={"54px"} pr={"8px"}>
							0
						</Text>
					</Flex>
					<Flex pos={"relative"} p={"2px"} gap={"0px"}>
						<Text fontWeight={"semibold"} px={"4px"}>
							高藥負擔
						</Text>
						<Text flexShrink={0} textAlign={"right"} sx={textEllipsisOverflow} w={"54px"} pr={"8px"}>
							0
						</Text>
					</Flex>
					<Flex pos={"relative"} p={"2px"} gap={"0px"}>
						<Text fontWeight={"semibold"} px={"4px"}>
							負擔小計
						</Text>
						<Text flexShrink={0} textAlign={"right"} sx={textEllipsisOverflow} w={"54px"} pr={"8px"}>
							0
						</Text>
					</Flex>
				</Box>
			</Flex>

			<Flex
				rounded={"md"}
				p={"8px 16px"}
				background={"#646B48"}
				boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset"}
				textColor={"#fff"}
				fontWeight={"semibold"}
				justify={"space-between"}
				mt={"12px"}>
				<Text>申報點數合計</Text>
				<Text> 點</Text>
			</Flex>
		</Box>
	);
};
