import apiService from "./apiService";

type Props = {
	data_id: string;
	date: string;
};

export type basicInputDataProps = {
	customer_uid: string | null;
	hosp_id: string | null;
	nhid: string | null;
	branch_uid: string | null;
	hosp_name: string | null;
	institution_type: string | null;
	business_license: string | null;
	uniform_numbers: string | null;
	tel_area_code: string | null;
	tel_number: string | null;
	address: string | null;
	zip: string | null;
	prescription_style: string | null;
	responsible_doctor: string | null;
	doctor_idno: string | null;
	pharmacist: string | null;
	pharmacist_idno: string | null;
};
// 診療畫面取得藥品詳細資訊
export const callDrugsForTreatmentService = async (token: string, data: Props) => {
	const queryParams = data ? { data_id: data.data_id, date: data.date } : undefined;
	console.log(queryParams, "apiService有啥");
	return apiService<string, any>({
		method: "GET",
		path: "/api/drugs/for_treatment",
		token,
		data: queryParams
	});
};

// 診療畫面查詢院內用藥
interface dataProps {
	keyword: string;
	// type?: string;
	date: string;
}
export const postDrugsForTreatmentService = async (token: string, data: dataProps) => {
	return apiService<string, any>({
		method: "POST",
		path: "/api/drugs/for_treatment",
		token,
		data: data
	});
};
