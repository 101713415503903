import React, { useState, useEffect } from "react";
import { Flex, Button } from "@chakra-ui/react";
import { useDeclareSearchRangeStore } from "store/DeclarationAudit/useDeclareSearchRangeStore";
import { DatePick } from "./DatePick";
import { useDeclareListsAPI } from "service/declareListsServiceHelper";
import { useDeclareErrorAPI } from "service/declareErrorServiceHelper";
import { useDeclareStaticAPI } from "service/declareStaticServiceHelper";
import { useDeclareListSelectedItemStore } from "store/DeclarationAudit/useDeclareListSelectedItemStore";
import { useDeclareListTabIndexStore } from "store/DeclarationAudit/useDeclareListTabIndexStore";
import { useDeclareProgressAPI } from "service/declareProgressServiceHelper";

export const Selections = () => {
	const { declareSearchRangeData, updateDeclareSearchRangeData } = useDeclareSearchRangeStore();
	const { callDeclareListsAPI } = useDeclareListsAPI();
	const { callDeclareStaticAPI } = useDeclareStaticAPI();
	const { callDeclareErrorAPI } = useDeclareErrorAPI();
	const { resetSelectedItem } = useDeclareListSelectedItemStore();
	const { tabIndex } = useDeclareListTabIndexStore();
	const { callDeclareProgressAPI } = useDeclareProgressAPI();

	const handleSearch = () => {
		resetSelectedItem();
		updateDeclareSearchRangeData({
			page: 1
		});

		if (tabIndex === 0) {
			callDeclareListsAPI({ ...declareSearchRangeData, page: 1 });
			console.log("搜尋 declareLists");
		} else if (tabIndex === 1) {
			callDeclareProgressAPI({ ym: declareSearchRangeData.ym });
			console.log("搜尋 declareProgress");
		} else if (tabIndex === 2) {
			callDeclareErrorAPI({ ...declareSearchRangeData, page: 1 });
			console.log("搜尋 declareStatic");
		}
		callDeclareStaticAPI(declareSearchRangeData.ym);
	};

	useEffect(() => {
		console.log("Selections 監控時間區間: ", declareSearchRangeData);
	}, [declareSearchRangeData]);

	return (
		<Flex gap={"8px"} align={"center"}>
			<DatePick />
			<Button colorScheme={"rsPrimary"} onClick={handleSearch}>
				搜尋
			</Button>
		</Flex>
	);
};
