import { useState } from "react";
import { Box, Text, Flex, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";

import { useFeeStore } from "store/TreatmentManagement/Payment";
import { textEllipsisOverflow } from "style/chakraStyles/textStyles";

export const SelfPayList = () => {
	const feeState = useFeeStore(state => state.feeData);
	const [tabIndex, setTabIndex] = useState(0);

	const ListItem = {
		align: "center",
		gap: "8px",
		pos: "relative",
		p: "2px"
	};

	const TabListItem = (isActive: boolean) => ({
		...ListItem,
		bg: isActive ? "#BFC6A2" : "",
		rounded: "sm"
	});

	const IconSize = {
		w: "20px",
		h: "20px"
	};

	return (
		<>
			<Box>
				<Flex pos={"relative"} justify={"space-between"}>
					<Box flexBasis={"calc(50% - 1px)"} px={"8px"}>
						<Flex sx={ListItem}>
							<Text flexGrow={"1"} fontWeight={"semibold"} pl={"26px"}>
								診察費
							</Text>
							<Text flexShrink={0} sx={textEllipsisOverflow} w={"54px"}>
								{feeState.xFeePH}
							</Text>
						</Flex>
						<Flex
							sx={TabListItem(tabIndex === 0)}
							_hover={{ opacity: 1, cursor: "pointer" }}
							onClick={() => {
								setTabIndex(0);
							}}>
							<Box sx={IconSize} pos={"absolute"} top={"4px"} left={"4px"}>
								<Box textColor={"#646B48"} sx={IconSize} opacity={0.8}>
									<HeroIcons name='openWindow'></HeroIcons>
								</Box>
							</Box>
							<Text flexGrow={"1"} fontWeight={"semibold"} pl={"26px"}>
								診療費
							</Text>
							<Text flexShrink={0} sx={textEllipsisOverflow} w={"54px"}>
								{feeState.xDiagnosisFee}
							</Text>
						</Flex>
						<Flex
							sx={TabListItem(tabIndex === 1)}
							_hover={{ opacity: 1, cursor: "pointer" }}
							onClick={() => {
								setTabIndex(1);
							}}>
							<Box sx={IconSize} pos={"absolute"} top={"4px"} left={"4px"}>
								<Box textColor={"#646B48"} sx={IconSize} opacity={0.8}>
									<HeroIcons name='openWindow'></HeroIcons>
								</Box>
							</Box>
							<Text flexGrow={"1"} fontWeight={"semibold"} pl={"26px"}>
								藥費
							</Text>
							<Text flexShrink={0} sx={textEllipsisOverflow} w={"54px"}>
								{feeState.xDrugFee}
							</Text>
						</Flex>

						{/*  <Flex sx={ListItem}>
                            <Text
                                flexGrow={'1'}
                                fontWeight={'semibold'}
                                pl={'26px'}
                            >
                                藥事服務費
                            </Text>
                            <Text flexShrink={0}
                                sx={textEllipsisOverflow}
                                w={'54px'}>{feeState.xFeeS}</Text>
                        </Flex> */}

						<Flex
							sx={TabListItem(tabIndex === 2)}
							_hover={{ opacity: 1, cursor: "pointer" }}
							onClick={() => {
								setTabIndex(2);
							}}>
							<Box sx={IconSize} pos={"absolute"} top={"4px"} left={"4px"}>
								<Box textColor={"#646B48"} sx={IconSize} opacity={0.8}>
									<HeroIcons name='openWindow'></HeroIcons>
								</Box>
							</Box>
							<Text flexGrow={"1"} fontWeight={"semibold"} pl={"26px"}>
								檢查檢驗費
							</Text>
							<Text flexShrink={0} sx={textEllipsisOverflow} w={"54px"}>
								{feeState.xFeeEQ}
							</Text>
						</Flex>

						<Flex sx={ListItem}>
							<Text flexGrow={"1"} fontWeight={"semibold"} pl={"26px"}>
								材料費
							</Text>
							<Text flexShrink={0} sx={textEllipsisOverflow} w={"54px"}>
								{feeState.xFeeM}
							</Text>
						</Flex>
						<Flex sx={ListItem}>
							<Text flexGrow={"1"} fontWeight={"semibold"} pl={"26px"}>
								證明費
							</Text>
							<Text flexShrink={0} sx={textEllipsisOverflow} w={"54px"}>
								0
							</Text>
						</Flex>
					</Box>
					<Box pos={"absolute"} top={0} left={"50%"} bg={"#646B48"} w={"1px"} h={"100%"}></Box>
					<Box flexBasis={"calc(50% - 1px)"} px={"8px"}>
						<Tabs index={tabIndex} onChange={index => setTabIndex(index)}>
							<TabList hidden>
								<Tab>診療費</Tab>
								<Tab>藥費</Tab>
								<Tab>檢查檢驗費</Tab>
							</TabList>
							<TabPanels>
								<TabPanel p={0}>
									<Flex direction={"column"} w={"100%"}>
										<Flex sx={ListItem}>
											<Text flexGrow={"1"}>注射技術費</Text>
											<Text flexShrink={0} sx={textEllipsisOverflow} w={"54px"}>
												{feeState.xFeeT}
											</Text>
										</Flex>
										<Flex sx={ListItem}>
											<Text flexGrow={"1"}>復健費</Text>
											<Text flexShrink={0} sx={textEllipsisOverflow} w={"54px"}>
												{feeState.xFeeR}
											</Text>
										</Flex>
										<Flex sx={ListItem}>
											<Text flexGrow={"1"}>處置費</Text>
											<Text flexShrink={0} sx={textEllipsisOverflow} w={"54px"}>
												{feeState.xFeeF}
											</Text>
										</Flex>
										<Flex sx={ListItem}>
											<Text flexGrow={"1"}>手術費</Text>
											<Text flexShrink={0} sx={textEllipsisOverflow} w={"54px"}>
												{feeState.xFeeP}
											</Text>
										</Flex>
										<Flex sx={ListItem}>
											<Text flexGrow={"1"}>麻醉費</Text>
											<Text flexShrink={0} sx={textEllipsisOverflow} w={"54px"}>
												{feeState.xFeeA}
											</Text>
										</Flex>
									</Flex>
								</TabPanel>
								<TabPanel p={0}>
									<Flex direction={"column"} w={"100%"}>
										<Flex sx={ListItem}>
											<Text flexGrow={"1"}>口服藥費</Text>
											<Text flexShrink={0} sx={textEllipsisOverflow} w={"54px"}>
												{feeState.xFeeO}
											</Text>
										</Flex>
										<Flex sx={ListItem}>
											<Text flexGrow={"1"}>外用藥費</Text>
											<Text flexShrink={0} sx={textEllipsisOverflow} w={"54px"}>
												{feeState.xFeeL}
											</Text>
										</Flex>
										<Flex sx={ListItem}>
											<Text flexGrow={"1"}>藥水費</Text>
											<Text flexShrink={0} sx={textEllipsisOverflow} w={"54px"}>
												{feeState.xFeeW}
											</Text>
										</Flex>
										<Flex sx={ListItem}>
											<Text flexGrow={"1"}>注射藥費</Text>
											<Text flexShrink={0} sx={textEllipsisOverflow} w={"54px"}>
												{feeState.xFeeI}
											</Text>
										</Flex>
										<Flex sx={ListItem}>
											<Text flexGrow={"1"}>胰島素費</Text>
											<Text flexShrink={0} sx={textEllipsisOverflow} w={"54px"}>
												{feeState.xFeeG}
											</Text>
										</Flex>
										<Flex sx={ListItem}>
											<Text flexGrow={"1"}>中藥費</Text>
											<Text flexShrink={0} sx={textEllipsisOverflow} w={"54px"}>
												0
											</Text>
										</Flex>
									</Flex>
								</TabPanel>
								<TabPanel p={0}>
									<Flex direction={"column"} w={"100%"}>
										<Flex sx={ListItem}>
											<Text flexGrow={"1"}>檢查費</Text>
											<Text flexShrink={0} sx={textEllipsisOverflow} w={"54px"}>
												{feeState.xFeeE}
											</Text>
										</Flex>
										<Flex sx={ListItem}>
											<Text flexGrow={"1"}>檢驗費</Text>
											<Text flexShrink={feeState.xFeeQ} sx={textEllipsisOverflow} w={"54px"}>
												0
											</Text>
										</Flex>
										<Flex sx={ListItem}>
											<Text flexGrow={"1"}>Ｘ光費</Text>
											<Text flexShrink={0} sx={textEllipsisOverflow} w={"54px"}>
												{feeState.xFeeX}
											</Text>
										</Flex>
									</Flex>
								</TabPanel>
							</TabPanels>
						</Tabs>
					</Box>
				</Flex>
			</Box>
		</>
	);
};
