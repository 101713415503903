import { accItemsLevelProps } from "service/accItemsLevelService";
import { cashDataStoreProps, FeeState } from "store/TreatmentManagement/Payment";
import { TreatmentInfoProps } from "store/TreatmentManagement/useTreatmentInfoStore";
import { CashierProps } from "service/medicalRecordService";

export const finalFeeDataLogic = (
	accItemsLevelList: accItemsLevelProps[],
	treatmentInfo: TreatmentInfoProps,
	feeData: FeeState,
	cashDataStore: cashDataStoreProps
): CashierProps[] => {
	if (accItemsLevelList && accItemsLevelList.length > 0 && treatmentInfo) {
		const cashierType = treatmentInfo.prescription_style;
		const nhiType = treatmentInfo.nhi_type_name;
		let cashier: CashierProps[] = [
			// HG 掛號費
			{
				acc_item_uid: accItemsLevelList[0].id,
				cashier_type: 3, // 1：點數、2：交付、3：金額
				price: cashDataStore.registrationFee,
				//"discount": 20,
				final: cashDataStore.registrationFee
			},
			// O 口服
			{
				acc_item_uid: accItemsLevelList[1].id,
				cashier_type: nhiType === "自費" ? 3 : cashierType === 1 ? 2 : 1,
				price: nhiType === "自費" ? feeData.xFeeO : feeData.mFeeO,
				//"discount": 20,
				final: nhiType === "自費" ? feeData.xFeeO : feeData.mFeeO
			},
			// L 外用
			{
				acc_item_uid: accItemsLevelList[2].id,
				cashier_type: nhiType === "自費" ? 3 : cashierType === 1 ? 2 : 1,
				price: nhiType === "自費" ? feeData.xFeeL : feeData.mFeeL,
				//"discount": 20,
				final: nhiType === "自費" ? feeData.xFeeL : feeData.mFeeL
			},
			// I 注射 (正常都是院內) 通常不交付
			{
				acc_item_uid: accItemsLevelList[3].id,
				// "cashier_type": nhiType === '自費' ? 3 : cashierType === 1 ? 2 : 1 ,
				cashier_type: nhiType === "自費" ? 3 : 1,
				price: nhiType === "自費" ? feeData.xFeeI : feeData.mFeeI,
				//"discount": 20,
				final: nhiType === "自費" ? feeData.xFeeI : feeData.mFeeI
			},
			// G 胰島
			{
				acc_item_uid: accItemsLevelList[4].id,
				cashier_type: nhiType === "自費" ? 3 : cashierType === 1 ? 2 : 1,
				price: nhiType === "自費" ? feeData.xFeeG : feeData.mFeeG,
				//"discount": 20,
				final: nhiType === "自費" ? feeData.xFeeG : feeData.mFeeG
			},
			// W 藥水
			{
				acc_item_uid: accItemsLevelList[5].id,
				cashier_type: nhiType === "自費" ? 3 : cashierType === 1 ? 2 : 1,
				price: nhiType === "自費" ? feeData.xFeeW : feeData.mFeeW,
				//"discount": 20,
				final: nhiType === "自費" ? feeData.xFeeW : feeData.mFeeW
			},
			// PH 診察費
			{
				acc_item_uid: accItemsLevelList[6].id,
				cashier_type: nhiType === "自費" ? 3 : cashierType === 1 ? 2 : 1,
				price: nhiType === "自費" ? feeData.xFeePH : feeData.mFeePH,
				//"discount": 20,
				final: nhiType === "自費" ? feeData.xFeePH : feeData.mFeePH
			},
			// T 注技 應該沒有交付
			{
				acc_item_uid: accItemsLevelList[7].id,
				cashier_type: nhiType === "自費" ? 3 : 1,
				price: nhiType === "自費" ? feeData.xFeeT : feeData.mFeeT,
				//"discount": 20,
				final: nhiType === "自費" ? feeData.xFeeT : feeData.mFeeT
			},
			// R 復健
			{
				acc_item_uid: accItemsLevelList[8].id,
				cashier_type: nhiType === "自費" ? 3 : cashierType === 1 ? 2 : 1,
				price: nhiType === "自費" ? feeData.xFeeR : cashierType === 1 ? feeData.DeliverRFee : feeData.mFeeR,
				//"discount": 20,
				final: nhiType === "自費" ? feeData.xFeeR : cashierType === 1 ? feeData.DeliverRFee : feeData.mFeeR
			},
			// F 處置
			{
				acc_item_uid: accItemsLevelList[9].id,
				cashier_type: nhiType === "自費" ? 3 : cashierType === 1 ? 2 : 1,
				price: nhiType === "自費" ? feeData.xFeeF : feeData.mFeeF,
				//"discount": 20,
				final: nhiType === "自費" ? feeData.xFeeF : feeData.mFeeF
			},
			// P 手術
			{
				acc_item_uid: accItemsLevelList[10].id,
				cashier_type: nhiType === "自費" ? 3 : cashierType === 1 ? 2 : 1,
				price: nhiType === "自費" ? feeData.xFeeP : feeData.mFeeP,
				//"discount": 20,
				final: nhiType === "自費" ? feeData.xFeeP : feeData.mFeeP
			},
			// A 麻醉
			{
				acc_item_uid: accItemsLevelList[11].id,
				cashier_type: nhiType === "自費" ? 3 : cashierType === 1 ? 2 : 1,
				price: nhiType === "自費" ? feeData.xFeeA : feeData.mFeeA,
				//"discount": 20,
				final: nhiType === "自費" ? feeData.xFeeA : feeData.mFeeA
			},
			// E 檢查
			{
				acc_item_uid: accItemsLevelList[12].id,
				cashier_type: nhiType === "自費" ? 3 : cashierType === 1 ? 2 : 1,
				price: nhiType === "自費" ? feeData.xFeeE : feeData.mFeeE,
				//"discount": 20,
				final: nhiType === "自費" ? feeData.xFeeE : feeData.mFeeE
			},
			// Q 檢驗
			{
				acc_item_uid: accItemsLevelList[13].id,
				cashier_type: nhiType === "自費" ? 3 : cashierType === 1 ? 2 : 1,
				price: nhiType === "自費" ? feeData.xFeeQ : feeData.mFeeQ,
				//"discount": 20,
				final: nhiType === "自費" ? feeData.xFeeQ : feeData.mFeeQ
			},
			// X 放射
			{
				acc_item_uid: accItemsLevelList[14].id,
				cashier_type: nhiType === "自費" ? 3 : cashierType === 1 ? 2 : 1,
				price: nhiType === "自費" ? feeData.xFeeX : feeData.mFeeX,
				//"discount": 20,
				final: nhiType === "自費" ? feeData.xFeeX : feeData.mFeeX
			},
			// D 病床 目前費用沒有這個
			// {
			//     "acc_item_uid": accItemsLevelList[15].id,
			//     "cashier_type": nhiType === '自費' ? 3 : cashierType === 1 ? 2 : 1 ,
			//     "price": nhiType ==='自費' ? feeData.xFeeD : feeData.mFeeD,
			//     //"discount": 20,
			//     "final":  nhiType ==='自費' ? feeData.xFeeD : feeData.mFeeD,
			// },
			// H 論病 目前沒做
			//    {
			//     "acc_item_uid": accItemsLevelList[16].id,
			//     "cashier_type": nhiType === '自費' ? 3 : cashierType === 1 ? 2 : 1 ,
			//     "price": nhiType ==='自費' ? feeData.xFeeX : feeData.mFeeX,
			//     //"discount": 20,
			//     "final":  nhiType ==='自費' ? feeData.xFeeX : feeData.mFeeX,
			// },
			// N 管目前沒做灌
			//    {
			//     "acc_item_uid": accItemsLevelList[17].id,
			//     "cashier_type": nhiType === '自費' ? 3 : cashierType === 1 ? 2 : 1 ,
			//     "price": nhiType ==='自費' ? feeData.xFeeX : feeData.mFeeX,
			//     //"discount": 20,
			//     "final":  nhiType ==='自費' ? feeData.xFeeX : feeData.mFeeX,
			// },
			// U 精神
			{
				acc_item_uid: accItemsLevelList[18].id,
				cashier_type: nhiType === "自費" ? 3 : cashierType === 1 ? 2 : 1,
				price: nhiType === "自費" ? feeData.xFeeU : feeData.mFeeU,
				//"discount": 20,
				final: nhiType === "自費" ? feeData.xFeeU : feeData.mFeeU
			},
			// B 血液
			{
				acc_item_uid: accItemsLevelList[19].id,
				cashier_type: nhiType === "自費" ? 3 : cashierType === 1 ? 2 : 1,
				price: nhiType === "自費" ? feeData.xFeeB : feeData.mFeeB,
				//"discount": 20,
				final: nhiType === "自費" ? feeData.xFeeB : feeData.mFeeB
			},
			// Y 托嬰 目前沒
			// {
			//     "acc_item_uid": accItemsLevelList[20].id,
			//     "cashier_type": nhiType === '自費' ? 3 : cashierType === 1 ? 2 : 1 ,
			//     "price": nhiType ==='自費' ? feeData.xFeeB: feeData.mFeeB,
			//     //"discount": 20,
			//     "final":  nhiType ==='自費' ? feeData.xFeeB : feeData.mFeeB,
			// },
			// CO 自付額
			{
				acc_item_uid: accItemsLevelList[21].id,
				cashier_type: 3,
				price: cashDataStore.deductibleFee,
				//"discount": 20,
				final: cashDataStore.deductibleFee
			},
			// HD 高藥
			{
				acc_item_uid: accItemsLevelList[22].id,
				cashier_type: 1,
				price: feeData.mFeeHD,
				//"discount": 20,
				final: feeData.mFeeHD
			},
			// EQ 高檢
			{
				acc_item_uid: accItemsLevelList[23].id,
				cashier_type: 1,
				price: feeData.mFeeEQ,
				//"discount": 20,
				final: feeData.mFeeHD
			},
			// J 代辦 AJ說先不用
			// {
			//     "acc_item_uid": accItemsLevelList[24].id,
			//     "cashier_type": 3,
			//     "price": feeData.mFeeEQ,
			//     //"discount": 20,
			//     "final": feeData.mFeeHD,
			// },
			// M 材料
			{
				acc_item_uid: accItemsLevelList[25].id,
				cashier_type: nhiType === "自費" ? 3 : cashierType === 1 ? 2 : 1,
				price: nhiType === "自費" ? feeData.xFeeM : cashierType === 1 ? feeData.DeliverMFee : feeData.mFeeM,
				//"discount": 20,
				final: nhiType === "自費" ? feeData.xFeeM : cashierType === 1 ? feeData.DeliverMFee : feeData.mFeeM
			},
			// Proff 證明 目前沒做
			// {
			//     "acc_item_uid": accItemsLevelList[26].id,
			//     "cashier_type": nhiType === '自費' ? 3 : cashierType === 1 ? 2 : 1 ,
			//     "price": nhiType ==='自費' ? feeData.xFeeM: cashierType === 1 ? feeData.DeliverMFee : feeData.mFeeM,
			//     //"discount": 20,
			//     "final": nhiType ==='自費' ? feeData.xFeeM: cashierType === 1 ? feeData.DeliverMFee : feeData.mFeeM,
			// },
			// S 藥事
			{
				acc_item_uid: accItemsLevelList[27].id,
				cashier_type: 1,
				price: feeData.mFeeS,
				//"discount": 20,
				final: feeData.mFeeS
			}
			// LPAY 押單 還沒
			// {
			//     "acc_item_uid": accItemsLevelList[28].id,
			//     "cashier_type": 1,
			//     "price": feeData.mFeeS,
			//     //"discount": 20,
			//     "final": feeData.mFeeS,
			// },
			// PAY 還單 還沒
			// {
			//     "acc_item_uid": accItemsLevelList[29].id,
			//     "cashier_type": 1,
			//     "price": feeData.mFeeS,
			//     //"discount": 20,
			//     "final": feeData.mFeeS,
			// },
			// Debt 欠款 還沒
			// {
			//     "acc_item_uid": accItemsLevelList[30].id,
			//     "cashier_type": 1,
			//     "price": feeData.mFeeS,
			//     //"discount": 20,
			//     "final": feeData.mFeeS,
			// },
			// Repay 還款 還沒
			// {
			//     "acc_item_uid": accItemsLevelList[31].id,
			//     "cashier_type": 1,
			//     "price": feeData.mFeeS,
			//     //"discount": 20,
			//     "final": feeData.mFeeS,
			// },
			// K 其他 還沒
			// {
			//     "acc_item_uid": accItemsLevelList[32].id,
			//     "cashier_type": 1,
			//     "price": feeData.mFeeS,
			//     //"discount": 20,
			//     "final": feeData.mFeeS,
			// },
		];
		return cashier;
	}
	return [];
};
