import apiService from "./apiService";

export interface shiftProps {
	data_id: string;
	name: string;
	start_time: string;
	end_time: string;
}
//取得院所看診時段
export const callShiftService = async (token: string) => {
	return apiService<undefined, any>({
		method: "GET",
		path: "/api/shift",
		token
	});
};

interface updatedDataProps {
	data_id?: string;
	name: string;
	start_time: string;
	end_time: string;
	seq: string;
}
export const postShiftService = async (token: string, updatedData: updatedDataProps) => {
	return apiService<updatedDataProps, any>({
		method: "POST",
		path: "/api/shift",
		token,
		data: updatedData
	});
};

// 增修看診時段
// export const postShiftService = async (
//     token: string,
//     updatedData: updatedDataProps
// ) => {
//     try {
//         const requestBody = JSON.stringify(updatedData);

//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         const response = await axiosInstance.post('/api/shift', requestBody, {
//             headers,
//         });

//         const result = response.data;

//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };

// 目前已停用
export const deleteShiftService = async (token: string, dataId: string) => {
	// 使用apiService调用DELETE方法
	return apiService<undefined, any>({
		method: "DELETE",
		path: `/api/shift?id=${dataId}`,
		token
	});
};

// 刪除看診時段
// export const deleteShiftService = async (token: string, dataId: string) => {
//     try {
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };
//         const url = `/api/shift?id=${dataId}`;

//         const response = await axiosInstance.delete(url, {
//             headers,
//         });

//         const result = response.data;

//         console.log(result, 'API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
