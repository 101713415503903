

// 目前沒做災民
/*
{
    "code": "hi",
    "name": "健保"
    "insureType": "3"
},
{
    "code": "hc",
    "name": "榮民"
    "insureType": "2"
},
{
    "code": "li",
    "name": "福保"
    "insureType": "1"
},
{
    "code": "se",
    "name": "自費"
    "insureType": ""
},
{
    "code": "dp",
    "name": "押單"
    "insureType": ""
}
*/
export const checkInsureTypeToNhiType = (insureType: string) => {
    switch (insureType) {
        case '1':
            return 'li';
        case '2':
            return 'hc';
        case '3':
            return 'hi';
        case '8':
            return 'hi';
    }
            
}