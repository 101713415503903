import { create } from "zustand";
import { useOpdModelStore, OpdModelProps } from "./useOpdModelStore";
import { useMedicalRecordModelStore, MedicalRecordModelProps } from "./useMedicalRecordModelStore";
import { useMedicalRecordDiagModelStore, MedicalRecordDiagModelProps } from "./useMedicalRecordDiagModelStore";
import { useMedicalRecordDrugModelStore, MedicalRecordDrugModelProps } from "./useMedicalRecordDrugModelStore";
import { useChargeModelStore, ChargeModelProps } from "./useChargeModelStore";
import { useHospitalModelStore, HospitalModelProps } from "./useHospitalModelStore";
import { usePatientModelStore, PatientModelProps } from "./usePatientModelStore";
import { useSlowRecModelStore, SlowRecModelProps } from "./useSlowRecModelStore";

// 定義 PrintChronicPrescriptionProps 接口
export interface PrintChronicPrescriptionProps {
	FormName: string;
	Opd: OpdModelProps; //
	MedicalRecord: MedicalRecordModelProps; //病歷模型
	MedicalRecordDiag: MedicalRecordDiagModelProps[];
	MedicalRecordDrug: MedicalRecordDrugModelProps[];
	Charge: ChargeModelProps;
	Hospital: HospitalModelProps;
	Patient: PatientModelProps;
	SlowRec: SlowRecModelProps;
}

// 初始化 PrintChronicPrescriptionProps
export const initialPrintChronicPrescriptionData: PrintChronicPrescriptionProps = {
	FormName: "",
	Opd: useOpdModelStore.getState().opdModel, // 從 useOpdModelStore 獲取初始 patient
	MedicalRecord: useMedicalRecordModelStore.getState().medicalRecord,
	MedicalRecordDiag: useMedicalRecordDiagModelStore.getState().diagModels,
	MedicalRecordDrug: useMedicalRecordDrugModelStore.getState().drugModels,
	Charge: useChargeModelStore.getState().chargeModel,
	Hospital: useHospitalModelStore.getState().hospitalModel,
	Patient: usePatientModelStore.getState().patientModel,
	SlowRec: useSlowRecModelStore.getState().slowRecModel
};

// 定義 PrintChronicPrescriptionState 接口
interface PrintChronicPrescriptionState {
	PrintChronicPrescriptionData: PrintChronicPrescriptionProps;
	updatePrintChronicPrescriptionData: (newData: Partial<PrintChronicPrescriptionProps>) => void;
	resetPrintChronicPrescriptionData: () => void;
}

// 創建 usePrintChronicPrescriptionStore
export const usePrintChronicPrescriptionStore = create<PrintChronicPrescriptionState>(set => ({
	PrintChronicPrescriptionData: initialPrintChronicPrescriptionData,
	updatePrintChronicPrescriptionData: newData =>
		set(state => ({
			PrintChronicPrescriptionData: {
				...state.PrintChronicPrescriptionData,
				...newData
			}
		})),
	resetPrintChronicPrescriptionData: () =>
		set(() => ({
			PrintChronicPrescriptionData: initialPrintChronicPrescriptionData
		}))
}));

export default usePrintChronicPrescriptionStore;
