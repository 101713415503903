import { postTreatNumV1000EncodeService } from 'service/RsService/TreatNumV1000EncodeService';
import { postIcDataService } from 'service/icDataServices';

import { SummaryNoICCardUpdateData } from '../../../SummaryNoICCardUpdateData';
import Swal from 'sweetalert2';
import { TreatmentInfoProps } from 'store/TreatmentManagement/useTreatmentInfoStore';
import { CCPEProps } from 'store/TreatmentManagement';
import { DxProps } from 'store/TreatmentManagement';
import { PrescriptionProps } from 'store/TreatmentManagement';
import { cashDataStoreProps } from 'store/TreatmentManagement/Payment';
import { accItemsLevelProps } from 'service/accItemsLevelService';
import { FeeState } from 'store/TreatmentManagement/Payment';
import { drugTypeListProps } from 'service/drugTypeListService';
import { InstitutionInfoProps } from 'store/DataSetting/useInstitutionInfoStore';
import { DrugFreqListsProps } from 'store/DataSetting/useDrugFreqListStore';
import { UserInfoProps } from 'store/useUserStore';
import { ADStringToROCStringyyyMMddHHmmssNoDot } from 'functions/ADStringToROCStringyyyMMddHHmmssNoDot';
export async function handleMissingCardNumberForCompletedTreatment(
    treatmentInfo: TreatmentInfoProps,
    CCPEData : CCPEProps,
    dxList: DxProps[],
    prescriptionList: PrescriptionProps[],
    cashDataStore: cashDataStoreProps,
    accItemsLevelList: accItemsLevelProps[],
    feeData: FeeState,
    if_formal_data: string,
    caseUid: string,
    drugTypeLists: drugTypeListProps[],
    institutionInfoData: InstitutionInfoProps,
    drugFreqLists: DrugFreqListsProps[],
    userInfo: UserInfoProps,
    //   hisGetTreatNumNoICCardResult?: pBufferProps,
    //   mb1?: mb1Props,
    //   treatNumV1000EncodeData?: TreatNumV1000EncodeResultProps,
) {
    const treated_at = treatmentInfo.medical_record?.info?.treated_at ? ADStringToROCStringyyyMMddHHmmssNoDot(treatmentInfo.medical_record?.info?.treated_at) : '' 
    let TreatNumV1000EncodeReqData = {
        sHospid: institutionInfoData.nhid,
        sPatientId: treatmentInfo.id_no,
        sTreatDT: treated_at ,
    };
    
    let TreatNumV1000EncodeResult = await postTreatNumV1000EncodeService(TreatNumV1000EncodeReqData);
    console.log('TreatNumV1000EncodeResult', TreatNumV1000EncodeResult);
    
    if (TreatNumV1000EncodeResult?.result !== 'success') {
        Swal.fire({
        icon: 'error',
        title: '取得就醫識別碼失敗',
        text: '請檢查健保卡或讀卡機狀態，並重試。',
        showConfirmButton: true,
        });
        return null;
    }

    const TreatNumV1000EncodeData = TreatNumV1000EncodeResult.data;
    let ICCardUpdateData = SummaryNoICCardUpdateData(
        treatmentInfo,
        CCPEData,
        dxList,
        prescriptionList,
        cashDataStore,
        accItemsLevelList,
        feeData,
        if_formal_data,
        caseUid,
        drugTypeLists,
        institutionInfoData,
        drugFreqLists,
        undefined,
        undefined,
        undefined,
        TreatNumV1000EncodeData,
    );
    console.log('ICCardUpdateData', ICCardUpdateData);

    const ICDataResult = await postIcDataService(userInfo.token, ICCardUpdateData);
    
    if (ICDataResult.result !== 'success') {
        Swal.fire({
        icon: 'error',
        title: '寫IC_DATA資料庫發生問題',
        showConfirmButton: true,
        });
        return null;
    }

    return ICCardUpdateData;
}