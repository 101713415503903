import { NavigateFunction } from "react-router-dom";

export const handleNavigateAfterComplete = (
	medicalRecordStatus: string,
	navigate: NavigateFunction,
	setCalculationComplete: (value: boolean) => void,
	setIsCompleteButtonStatus: (value: boolean) => void,
	setLoadingStatus: (value: boolean) => void,
	delay?: number
) => {
	setCalculationComplete(false);
	setIsCompleteButtonStatus(false);
	setLoadingStatus(false);
	const effectiveDelay = delay ?? 200;

	setTimeout(() => {
		switch (medicalRecordStatus) {
			case "0":
			case "1":
				navigate("/OPDPage");
				break;
			case "2":
				navigate("/ICCardUpdatePage");
				break;
			case "3":
				navigate("/DeclarationAuditPage");
				break;
			default:
				console.warn("Unknown medical record status:", medicalRecordStatus);
		}
	}, effectiveDelay);
};
