import { Flex, Box, Select, Input, Button } from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import {
    medicalStaffListsProps,
    callMedicalStaffListService,
} from 'service/medicalStaffListService';
import { useWaitingListsStatus } from 'contexts/Registration/WaitingListsStatusContext';
import { useDropDownMenuQuery } from 'query/useDropDownMenuQuery';
import { SelectedSign } from './SelectedSign';
import { useUserStore } from 'store';
import { shiftProps } from 'service/shiftService';
import Clock from 'components/CustomComponents/Clock';
import { useLocation } from 'react-router-dom';

export const BaseFilterBar = () => {
    const { userInfo } = useUserStore();

    const {
        filterByDoctorStatus,
        setFilterByDoctorStatus,
        filterByPeriodStatus,
        setFilterByPeriodStatus,
        filterByPatientDataStatus,
        setFilterByPatientDataStatus,
    } = useWaitingListsStatus();

    // 取得醫師名單
    const [medicalStaffLists, setMedicalStaffLists] = useState<
        medicalStaffListsProps[]
    >([]);
    const { data: medicalStaffListData, isSuccess: isMedicalStaffListSuccuss } =
        useQuery(
            'medicalStaffList',
            () => callMedicalStaffListService(userInfo.token!, 'iden_doctor'),
            { enabled: !!userInfo.token, staleTime: Infinity }
        );

    // 取得時段
    const [shiftLists, setShiftLists] = useState<shiftProps[]>([]);

    const { data: shiftListData, isSuccess: isShiftListSuccess } =
        useDropDownMenuQuery('shift', userInfo.token!);

    useEffect(() => {
        if (isMedicalStaffListSuccuss) {
            setMedicalStaffLists(medicalStaffListData.lists);
        }
        if (isShiftListSuccess) {
            setShiftLists(shiftListData.lists);
        }
    }, [isMedicalStaffListSuccuss, isShiftListSuccess]);

    let location = useLocation();
    const pathName = location.pathname;
    const pathSegments = pathName.split('/');
    const lastSegment = pathSegments.pop();

    return (
        <>
            <Flex align={'center'} justify={'space-between'}>
                <Flex align={'center'} mb={'8px'} columnGap={'16px'}>
                    <Flex gap={'8px'} align={'center'}>
                        <Box textColor={'rsPrimary.500'}>
                            <HeroIcons name="clock"></HeroIcons>
                        </Box>
                        <Select
                            bg={'#fff'}
                            w={'200px'}
                            value={filterByPeriodStatus || ''}
                            onChange={e => {
                                setFilterByPeriodStatus(e.target.value);
                            }}
                        >
                            <option value="">全部</option>
                            {shiftLists &&
                                shiftLists.map((item, index) => (
                                    <option key={index} value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                        </Select>
                    </Flex>
                    <Flex gap={'8px'} align={'center'}>
                        <Box textColor={'rsPrimary.500'}>
                            <HeroIcons name="user"></HeroIcons>
                        </Box>
                        <Select
                            bg={'#fff'}
                            w={'200px'}
                            value={filterByDoctorStatus || ''}
                            onChange={e => {
                                setFilterByDoctorStatus(e.target.value);
                            }}
                        >
                            <option value="">全部醫師</option>
                            {medicalStaffLists &&
                                medicalStaffLists.map(
                                    (item: any, index: number) => (
                                        <option key={index} value={item.name}>
                                            {item.name}
                                        </option>
                                    )
                                )}
                        </Select>
                    </Flex>

                    <Flex columnGap={'8px'} align={'center'}>
                        <Box textColor={'rsPrimary.500'}>
                            <HeroIcons name="search"></HeroIcons>
                        </Box>
                        <Input
                            bg={'#fff'}
                            w={'200px'}
                            placeholder="姓名 / 病歷號 "
                            value={filterByPatientDataStatus || ''}
                            onChange={e => {
                                setFilterByPatientDataStatus(e.target.value);
                            }}
                        ></Input>
                    </Flex>
                    <Button
                        colorScheme="rsSecondary"
                        variant={'link'}
                        onClick={() => {
                            setFilterByDoctorStatus('');
                            setFilterByPeriodStatus('');
                            setFilterByPatientDataStatus('');
                        }}
                    >
                        <HeroIcons name="recycle-sm"></HeroIcons>{' '}
                        <Box as="span" ml={'4px'}>
                            清除篩選條件
                        </Box>
                    </Button>
                    <SelectedSign />
                </Flex>
                <Box
                    hidden={lastSegment === 'RegisteredPage'}
                    p={'8px 16px'}
                    textColor={'rsPrimary.500'}
                >
                    <Clock />
                </Box>
            </Flex>
        </>
    );
};
