import { PrescriptionProps } from "store/TreatmentManagement";
import { InstitutionInfoProps } from "store/DataSetting/useInstitutionInfoStore";

export const D05Logic = (drug: PrescriptionProps, orderType: string, institutionInfo: InstitutionInfoProps) => {
	const validOrderTypes = ["1", "2", "P", "M", "Q", "R"];
	const give = institutionInfo.deliver_exam_type;
	let D05 = "";
	if (validOrderTypes.includes(orderType)) {
		switch (orderType) {
			case "1":
			case "2":
				D05 = "0";
				break;
			case "M":
			case "Q":
				D05 = "1";
				break;
			case "R":
				// 這邊要補委自委交
				D05 = give === "4" ? "2" : give === "3" ? "4" : "1";
				break;
			case "P":
				// 這邊要補委自委交
				D05 = give === "4" ? "2" : give === "3" ? "4" : "0";
				break;
			default:
				D05 = "";
				break;
		}
	}
	return D05;
};
