import React, { useEffect, useState } from "react";
import DrugDataMaintenanceTable from "../../components/DataSettings/DrugDataMaintenanceComponents/DrugDataMaintenanceTable";
const DrugDataMaintenancePage: React.FC = () => {
	const [bgOpenStatus, setBgOpenStatue] = useState(false);
	const handleOpen = () => {
		setBgOpenStatue(true);
	};
	const handleClose = () => {
		setBgOpenStatue(false);
	};

	return (
		<>
			<DrugDataMaintenanceTable />
		</>
	);
};

export default DrugDataMaintenancePage;
