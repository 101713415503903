import { create } from "zustand";
import { DeclareListsItemProps } from "./useDeclareListsStore";

interface DeclareListSelectedItemState {
	selectedItem: DeclareListsItemProps | null;
	setSelectedItem: (item: DeclareListsItemProps | null) => void;
	resetSelectedItem: () => void;
}

export const useDeclareListSelectedItemStore = create<DeclareListSelectedItemState>(set => ({
	selectedItem: null,
	setSelectedItem: item => set({ selectedItem: item }),
	resetSelectedItem: () => set({ selectedItem: null })
}));
