import { RshisGetSeqNumber256N1Service } from "service/RsService/RshisGetSeqNumber256N1Service";
import Swal from "sweetalert2";
type finalDataProps = {
	cTreatItem: string;
	cBabyTreat: string;
	cTreatAfterCheck: string;
	iButterLen: string;
};

export type SeqNumber256N1DataProps = {
	visitDateTime: string; //就診日期時間
	visitSerialNumber: string; //就醫序號
	medicalInstitutionCode: string; //醫療院所代碼
	securitySignature: string; //安全簽章
	samId: string; //SAM ID
	sameDayVisit: string; //是否同日就診
	visitIdentificationCode: string; //就醫識別碼
};

type ResponseProps = {
	ERRORCODE: number;
	pBuffer: string;
};

export type SeqNumber256N1Props = {
	ERRORCODE: number;
	pBuffer: SeqNumber256N1DataProps;
};

const parseSeqNumber256N1 = (pBuffer: string): SeqNumber256N1DataProps => {
	return {
		visitDateTime: pBuffer.substring(0, 13).trim(), // 就診日期時間
		visitSerialNumber: pBuffer.substring(13, 17).trim(), // 就醫序號
		medicalInstitutionCode: pBuffer.substring(17, 27).trim(), // 醫療院所代碼
		securitySignature: pBuffer.substring(27, 283).trim(), // 安全簽章
		samId: pBuffer.substring(283, 295).trim(), // SAM ID
		sameDayVisit: pBuffer.substring(295, 296).trim(), // 是否同日就診
		visitIdentificationCode: pBuffer.substring(296, 316).trim() // 就醫識別碼
	};
};

const parseResponse = (response: string): ResponseProps => {
	const parsedResponse = JSON.parse(response);
	return {
		ERRORCODE: parsedResponse.ERRORCODE,
		pBuffer: parsedResponse.pBuffer
	};
};

export const hisGetSeqNumber256N1 = async (finalData: finalDataProps): Promise<SeqNumber256N1Props | null> => {
	try {
		const response = await RshisGetSeqNumber256N1Service(finalData);
		console.log("新的256N1", response);

		const { ERRORCODE, pBuffer } = parseResponse(response);

		if (!pBuffer) {
			console.error("錯誤回應或 pBuffer 未定義或為空");

			return null;
		}

		const parsedResult = parseSeqNumber256N1(pBuffer);

		let result = {
			ERRORCODE: ERRORCODE,
			pBuffer: parsedResult
		};
		return result;
	} catch (error) {
		console.error("在 hisGetSeqNumber256N1 函數中發生錯誤:", error);
		return null;
	}
};
