import { create } from "zustand";

export interface DrugFreqListsProps {
	data_id: string;
	code: string;
	freq_code: string;
	ori_freq_code: string;
	directions: string;
	days: number;
	times: number;
	type: number | null;
	taking_time: string;
}

const initialDrugFreqLists: DrugFreqListsProps[] = [];

interface DrugFreqListsState {
	drugFreqListsData: DrugFreqListsProps[];
	updateDrugFreqListsData: (newData: DrugFreqListsProps[]) => void;
	resetDrugFreqListsData: () => void;
}

export const useDrugFreqListsStore = create<DrugFreqListsState>(set => ({
	drugFreqListsData: initialDrugFreqLists,
	updateDrugFreqListsData: newData =>
		set(() => ({
			drugFreqListsData: newData
		})),
	resetDrugFreqListsData: () =>
		set(() => ({
			drugFreqListsData: initialDrugFreqLists
		}))
}));
