import axios from "axios";

type finalDataProps = {
	pDateTime: string;
	pPatientID: string;
	pPatientBirthDate: string;
	pDataWrite: string;
	iWriteCount: number;
};
// Rs多筆處方箋寫入作業
export const RshisWriteMultiPrescriptSignService = async (finalData: finalDataProps) => {
	console.log("多筆處方", finalData);
	try {
		const response = await axios.post("http://localhost:23752/cshis/RshisWriteMultiPrescriptSign", finalData, {
			headers: {
				Accept: "*/*",
				"Content-Type": "application/json"
			}
		});

		console.log(response.data, "Rs多筆處方箋寫入作業");
		return response.data;
	} catch (error: unknown) {
		if (error instanceof Error) {
			console.error("Error:", error.message);
		} else {
			console.error("An unknown error occurred");
		}
	}
};
