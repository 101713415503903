import { callDeclareStaticService, DeclareStaticReqProps } from "service/declareStaticService";
import { useDeclareStaticDataStore } from "store/DeclarationAudit/useDeclareStaticDataStore";
import { useUserStore } from "store";
import Swal from "sweetalert2";

export const useDeclareStaticAPI = () => {
	const { userInfo } = useUserStore();
	const { updateDeclareStaticData, resetDeclareStaticData } = useDeclareStaticDataStore();

	const callDeclareStaticAPI = async (data?: string) => {
		try {
			const response = await callDeclareStaticService(userInfo.token, {
				ym: data
			} as DeclareStaticReqProps);

			if (response.result === "success") {
				console.log("案件數 API: ", response);
				updateDeclareStaticData(response.lists);
			} else if (response.result === "failure") {
				console.log("案件數 API: ", response);
				resetDeclareStaticData();
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			Swal.fire({
				icon: "error",
				title: "查詢過程發生錯誤",
				text: "請稍後再試",
				confirmButtonText: "確定"
			});
		}
	};
	return { callDeclareStaticAPI };
};
