import { ConsultingRoom } from "components/TreatmentManagement/ConsultingRoomComponents";
import { CombinedTreatmentManagementProviders } from "contexts/TreatmentManagement";

export const ConsultingRoomPage = () => {
	return (
		<CombinedTreatmentManagementProviders>
			<ConsultingRoom />
		</CombinedTreatmentManagementProviders>
	);
};
