import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";

import { Flex, Text, Select } from "@chakra-ui/react";
import { medicalTypeProps, callGTypeMedicalTypeService, caseTypeProps, callGTypeCaseTypeService } from "service/gTypeService";
import { callHospSubjectService } from "service/hospSubjectService";
import { useUserStore } from "store";
import { useTreatmentInfoStore } from "store/TreatmentManagement";

type hospSubjectProps = {
	id: string;
	name: string;
};

export const Category = () => {
	const { userInfo } = useUserStore();
	const { treatmentInfo, updateTreatmentInfo } = useTreatmentInfoStore();

	// 就醫類別
	const [medicalTypeLists, setMedicalTypeLists] = useState<medicalTypeProps[]>([]);

	const { data: medicalTypeData, isSuccess: isMedicalTypeSuccess } = useQuery(
		"medicalType",
		() => callGTypeMedicalTypeService(userInfo.token!),
		{
			enabled: !!userInfo.token,
			staleTime: Infinity
		}
	);

	useEffect(() => {
		if (isMedicalTypeSuccess) {
			setMedicalTypeLists(medicalTypeData.lists);
		}
	}, [isMedicalTypeSuccess]);

	const handleChangeMedicalType = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const selected_mt_type = event.target.value || "";
		if (selected_mt_type) {
			updateTreatmentInfo({
				selected_mt_type: selected_mt_type
			});
		}
	};

	// 科別
	const [hospSubjectLists, setHospSubjectLists] = useState<hospSubjectProps[]>([]);

	const { data: hospSubjectData, isSuccess: isHospSubjectSuccess } = useQuery(
		"hospSubject",
		() => callHospSubjectService(userInfo.token!),
		{
			enabled: !!userInfo.token,
			staleTime: Infinity
		}
	);

	useEffect(() => {
		if (isHospSubjectSuccess) {
			setHospSubjectLists(hospSubjectData.lists);
		}
	}, [isHospSubjectSuccess]);

	const handleChangeHospSubject = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const selected_subject_code = event.target.value || "";

		const subjectItem = hospSubjectLists.find(item => item.id === selected_subject_code);

		if (subjectItem) {
			updateTreatmentInfo({
				subject_code: subjectItem.id,
				subject_name: subjectItem.name
			});
		}
	};

	// 案件分類
	const [caseTypeLists, setCaseTypeLists] = useState<caseTypeProps[]>([]);

	const { data: caseTypeData, isSuccess: isCaseTypeSuccess } = useQuery("caseType", () => callGTypeCaseTypeService(userInfo.token!), {
		enabled: !!userInfo.token,
		staleTime: Infinity
	});

	useEffect(() => {
		if (isCaseTypeSuccess) {
			setCaseTypeLists(caseTypeData.lists);
		}
	}, [isCaseTypeSuccess]);
	const handleChangeCaseType = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const case_type_code = event.target.value || "";
		const selectedCaseType = caseTypeLists.find(item => item.code === case_type_code);

		if (selectedCaseType) {
			updateTreatmentInfo({
				case_type: case_type_code
			});

			console.error("確定有改", case_type_code);
			// Check if the selected case type is '09' (自費)
		}
	};

	return (
		<>
			<Flex w={"100%"} h={"100%"} p={"8px 16px"} direction={"column"} gap={"4px"}>
				<Flex>
					<Text flex={"0 0 100px"} p={"8px 0px"} fontWeight={"semibold"}>
						科別
					</Text>

					<Select bg={"#fff"} onChange={handleChangeHospSubject} value={treatmentInfo.subject_code || ""}>
						{hospSubjectLists &&
							hospSubjectLists.map((item, index) => (
								<option value={item.id} key={index}>
									{item.id}
									{item.name}
								</option>
							))}
					</Select>
				</Flex>
				<Flex>
					<Text flex={"0 0 100px"} p={"8px 0px"} fontWeight={"semibold"}>
						就醫類別
					</Text>

					<Select
						bg={"#fff"}
						value={treatmentInfo.selected_mt_type ? treatmentInfo.selected_mt_type : treatmentInfo.mt_type || ""} // 確保不為 null
						onChange={handleChangeMedicalType}>
						{medicalTypeLists &&
							medicalTypeLists.map((item, index) => (
								<option value={item.code} key={index}>
									{item.code}
									{item.name}
								</option>
							))}
					</Select>
				</Flex>
				<Flex>
					<Text flex={"0 0 100px"} p={"8px 0px"} fontWeight={"semibold"}>
						案件分類
					</Text>

					<Select
						bg={"#fff"}
						onChange={handleChangeCaseType}
						value={treatmentInfo.case_type || ""}
						textColor={treatmentInfo.case_type === "09" ? "rsDanger.800" : "black"}
						borderColor={treatmentInfo.case_type === "09" ? "rsDanger.800" : "#e2e8f0"}>
						{caseTypeLists &&
							caseTypeLists.map((item, index) => (
								<option value={item.code} key={index}>
									{item.code}
									{item.name}
								</option>
							))}
					</Select>
				</Flex>
			</Flex>
		</>
	);
};
