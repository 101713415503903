import React, { useState, useEffect } from "react";
import { TableContainer, Table, Thead, Tr, Th, Tbody, Td, Button, Text, Tooltip } from "@chakra-ui/react";
import Swal from "sweetalert2";

import { useRegistrationRefillableStore } from "store/Registration/useRegistrationRefillableStore";
import { useCurrentVisitInfoStore } from "store/Registration/useCurrentVisitInfoStore";
import { ADDateTransToROCStr } from "functions/ADDateTransToROCStr";
import { useRegisteredFlowStatus } from "contexts/RegisteredFlowStatusContext";

export const GetMedTable = ({ refillable_uid }: { refillable_uid: string }) => {
	const { refillableDetailInfo } = useRegistrationRefillableStore();
	const { currentVisitInfoData, updateCurrentVisitInfoData } = useCurrentVisitInfoStore();

	const { setRegisteredFlowStatus } = useRegisteredFlowStatus();

	// 1118 不限制於日期區間
	const isDateInRange = (startDateStr: string, endDateStr: string) => {
		const today = new Date();
		const startDate = new Date(new Date(startDateStr).setDate(new Date(startDateStr).getDate() - 10));
		const endDate = new Date(endDateStr);

		return today >= startDate && today <= endDate;
	};

	const handleGetMed = (startDateStr: string, endDateStr: string, refillable_detail_uid: string, seq: number) => {
		const getMedOk = isDateInRange(startDateStr, endDateStr);
		if (getMedOk) {
			console.log("慢性病 ID:", refillable_uid);
			console.log("領藥序號 ID:", refillable_detail_uid);
			console.log("領藥序號:", seq);
			confirmGetMed(refillable_detail_uid, seq);
		} else {
			Swal.fire({
				title: "非建議領藥日期內 \n 是否確定領藥？",
				icon: "question",
				confirmButtonText: "確定",
				cancelButtonText: "取消"
			}).then(result => {
				if (result.isConfirmed) {
					confirmGetMed(refillable_detail_uid, seq);
				} else {
					return;
				}
			});
		}
	};

	const confirmGetMed = (refillable_detail_uid: string, seq: number) => {
		updateCurrentVisitInfoData({
			refillable_uid: refillable_uid,
			refillable_detail_uid: refillable_detail_uid,
			card_no: `IC0${seq}`,
			mt_type: "AE"
		});
		Swal.fire({
			title: `連箋領藥序 第 ${seq} 次`,
			icon: "success",
			timer: 1500
		});
		setRegisteredFlowStatus("second");
	};

	const handleCancelGetMed = () => {
		updateCurrentVisitInfoData({
			refillable_uid: "",
			refillable_detail_uid: "",
			card_no: "",
			mt_type: ""
		});
	};

	useEffect(() => {
		console.log("日式綠茶 store", refillableDetailInfo);
	}, [refillableDetailInfo]);

	return (
		<>
			<TableContainer h={"289px"} overflowY={"auto"}>
				<Table variant='striped'>
					<Thead bg={"#D9D9D9"} pos={"sticky"} top={0} left={0} zIndex={10}>
						<Tr>
							<Th>
								<Text textStyle={"semiTitle"}> 序</Text>
							</Th>
							<Th>
								<Text textStyle={"semiTitle"}> 建議領藥日</Text>
							</Th>
							<Th>
								<Text textStyle={"semiTitle"}> 領藥日</Text>
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						{refillableDetailInfo.lists.map((item, index) => (
							<Tr key={index}>
								<Td>{item.seq}</Td>
								<Td>
									{ADDateTransToROCStr(
										new Date(new Date(item.start_date).setDate(new Date(item.start_date).getDate() - 10))
									)}
									{" ~ "}
									{ADDateTransToROCStr(item.end_date)}
								</Td>
								<Td>
									{item.prescription_date ? (
										ADDateTransToROCStr(item.prescription_date)
									) : currentVisitInfoData.refillable_detail_uid &&
									  currentVisitInfoData.refillable_detail_uid === item.data_id ? (
										<Tooltip label='點擊取消本次領藥' hasArrow bg={"rsDanger.500"} placement='right'>
											<Button
												size={"sm"}
												colorScheme='rsSecondary'
												boxShadow={"inset 2px -2px 4px rgba(0,0,0,0.2)"}
												onClick={handleCancelGetMed}>
												本次領藥
											</Button>
										</Tooltip>
									) : (
										<Button
											size={"sm"}
											colorScheme='rsDanger'
											boxShadow={"inset 2px -2px 4px rgba(0,0,0,0.2)"}
											onClick={() => handleGetMed(item.start_date, item.end_date, item.data_id ?? "", item.seq)}
											// isDisabled={
											//     !isDateInRange(
											//         item.start_date,
											//         item.end_date
											//     )
											// }
										>
											領藥
										</Button>
									)}
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
		</>
	);
};
