import React, { createContext, useContext, useState, ReactNode } from "react";

interface IsPartCopyContextValue {
	isPartCopyStatus: boolean;
	setIsPartCopyStatus: React.Dispatch<React.SetStateAction<boolean>>;
	copyWayStatus: string;
	setCopyWayStatus: React.Dispatch<React.SetStateAction<string>>;
}

const IsPartCopyContext = createContext<IsPartCopyContextValue | undefined>(undefined);

interface IsPartCopyProviderProps {
	children: ReactNode;
}

export const IsPartCopyProvider: React.FC<IsPartCopyProviderProps> = ({ children }) => {
	const [isPartCopyStatus, setIsPartCopyStatus] = useState(false);
	const [copyWayStatus, setCopyWayStatus] = useState("1"); // 新增的状态

	return (
		<IsPartCopyContext.Provider
			value={{
				isPartCopyStatus,
				setIsPartCopyStatus,
				copyWayStatus,
				setCopyWayStatus
			}}>
			{children}
		</IsPartCopyContext.Provider>
	);
};

export const useIsPartCopyStatus = (): IsPartCopyContextValue => {
	const context = useContext(IsPartCopyContext);

	if (!context) {
		throw new Error("useIsPartCopyStatus must be used within a IsPartCopyProvider");
	}

	return context;
};
