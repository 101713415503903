import React, { useEffect, useState } from "react";
import {
	Box,
	Text,
	Flex,
	List,
	ListItem,
	Button,
	useDisclosure,
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	Select
} from "@chakra-ui/react";
import { SwalToast } from "components/CustomComponents/SwalComponents";
import { useMedicalRecordStatus } from "contexts/TreatmentManagement/MedicalRecordStatusContext";

import { usePatientInfoStore } from "store/Registration";
import { useCurrentVisitInfoStore } from "store/Registration";
import { useTreatmentInfoStore } from "store/TreatmentManagement";

import { useDropDownMenuQuery } from "query/useDropDownMenuQuery";
import { membershipProps } from "service/memberShipService";

import { useUserStore } from "store";

export const PatientInfo = () => {
	const { userInfo } = useUserStore();

	const { currentVisitInfoData, updateCurrentVisitInfoData } = useCurrentVisitInfoStore();
	const { medicalRecordStatus } = useMedicalRecordStatus();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const openExceptionListBtnRef = React.useRef<HTMLButtonElement>(null);
	const { treatmentInfo, updateTreatmentInfo } = useTreatmentInfoStore();
	const [checkCardNo, setCheckCardNo] = useState<string | undefined>();

	const [displayCardNo, setDisplayCardNo] = useState<string | undefined>("");

	const { data: membershipData, isSuccess: isMembershipSuccess } = useDropDownMenuQuery("membership", userInfo.token!);

	const exceptionStateListBtnAPI = [
		{ code: "----", desc: "待取卡號" },
		{ code: "A000", desc: "讀卡設備故障" },
		{ code: "A010", desc: "讀卡機故障" },
		{ code: "A020", desc: "網路故障造成讀卡機無法使用" },
		{ code: "A030", desc: "安全模組故障造成讀卡機無法使用" },
		{ code: "B000", desc: "卡片不良(表面正常，晶片異常)" },
		{ code: "C000", desc: "停電" },
		{ code: "C001", desc: "例外就醫" },
		{ code: "D000", desc: "醫療資訊系統(HIS)當機" },
		{ code: "D010", desc: "醫療院所電腦故障" },
		{ code: "E000", desc: "健保署資訊系統當機" },
		{ code: "E001", desc: "急診無法更新者" },
		{ code: "F000", desc: "院所赴偏遠地區無法上網" },
		{ code: "Z000", desc: "其他" },
		{ code: "Z00A", desc: "異常就醫序號" },
		{ code: "G000", desc: "網路未建置" },
		{ code: "MSPT", desc: "代謝症候群防治計畫" },
		{ code: "ICND", desc: "未具健保身份生產案件" },
		{
			code: "ICC4",
			desc: "癌症治療品質計畫之追蹤及診斷品質管理"
		}
	];

	const handleChangeException = (event: React.MouseEvent<HTMLButtonElement>) => {
		const dataCode = event.currentTarget.getAttribute("data-code");
		const dataDesc = event.currentTarget.getAttribute("data-desc");
		if (dataCode && dataCode === "----") {
			updateTreatmentInfo({ card_no: "" });
			setDisplayCardNo("待取卡號");
		} else {
			updateTreatmentInfo({
				card_no: dataCode ?? ""
			});

			setDisplayCardNo(`${dataCode}${dataDesc}`);
		}
		onClose();
		SwalToast.fire({
			icon: "success",
			title: "更新成功",
			timer: 1500
		});
	};

	const [membershipLists, setMembershipLists] = useState<membershipProps[]>([]);

	useEffect(() => {
		setCheckCardNo(treatmentInfo.card_no);
		setDisplayCardNo(treatmentInfo.card_no);
	}, []);

	useEffect(() => {
		if (isMembershipSuccess) {
			setMembershipLists(membershipData.lists);
		}
	}, [isMembershipSuccess]);

	const handleChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const value = event.target.value;
		updateCurrentVisitInfoData({ member_uid: value });
	};

	return (
		<>
			<Box w={"100%"} h={"100%"} p={"8px 12px"}>
				<List spacing={"4px"}>
					<ListItem>
						<Flex align={"center"}>
							<Text textColor={"#000"} fontWeight={"semibold"} flex={"0 0 120px"}>
								姓名
							</Text>
							<Box
								bg={treatmentInfo.sex === "M" ? "#79A09F" : treatmentInfo.sex === "F" ? "#D28989" : "red.500"}
								h={"40px"}
								px={"8px"}
								rounded={"4px"}>
								<Text fontSize={"30px"} textColor={"#fff"} lineHeight={"40px"}>
									{treatmentInfo.patient_name}
								</Text>
							</Box>
						</Flex>
					</ListItem>
					<ListItem>
						<Flex align={"center"}>
							<Text textColor={"#000"} fontWeight={"semibold"} flex={"0 0 120px"}>
								掛號身份
							</Text>
							<Box py={"8px"}>
								<Text textColor={"#000"}>{treatmentInfo.nhi_type_name}</Text>
							</Box>
						</Flex>
					</ListItem>
					<ListItem>
						<Flex align={"center"}>
							<Text textColor={"#000"} fontWeight={"semibold"} flex={"0 0 120px"}>
								卡號
							</Text>

							<Flex flex={1} justify={"space-between"} align={"center"} w={"100%"} overflow={"hidden"} gap={"4px"}>
								<Text flex={"1"} whiteSpace={"nowrap"} textOverflow={"ellipsis"} overflowX={"hidden"}>
									{displayCardNo}
								</Text>
								{medicalRecordStatus === "0" && (
									<Button
										flex={"0 0 30px"}
										ref={openExceptionListBtnRef}
										onClick={onOpen}
										colorScheme='rsDanger'
										size={"sm"}>
										異
									</Button>
								)}
							</Flex>
						</Flex>
					</ListItem>
					<ListItem>
						<Flex align={"center"}>
							<Text textColor={"#000"} fontWeight={"semibold"} flex={"0 0 120px"}>
								當次優免身份
							</Text>

							<Select bg={"#fff"} onChange={handleChangeSelect} value={treatmentInfo.member_uid ?? ""} name='member_uid'>
								<option value=''>請選擇</option>
								{membershipLists &&
									membershipLists.map((item, index) => (
										<option key={index} value={item.data_id}>
											{item.name}
										</option>
									))}
							</Select>
						</Flex>
					</ListItem>
				</List>
			</Box>

			<Drawer isOpen={isOpen} placement='right' onClose={onClose} finalFocusRef={openExceptionListBtnRef} size={"md"}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader textColor={"rsPrimary.500"}>異常卡號</DrawerHeader>
					<DrawerBody>
						<Flex rowGap={"8px"} flexWrap={"wrap"} gap={"8px"} direction={"column"}>
							{exceptionStateListBtnAPI &&
								exceptionStateListBtnAPI.map((item, index) => (
									<Button
										p={0}
										border={0}
										outline={0}
										boxShadow={0}
										bg={"transparent"}
										h={"auto"}
										display={"flex"}
										key={index}
										data-code={item.code}
										data-desc={item.desc}
										name='exception_card_number'
										onClick={handleChangeException}
										_hover={{
											filter: "hue-rotate(45deg)"
										}}
										overflow={"hidden"}>
										<Box
											px={"8px"}
											py={"12px"}
											bg={"rsPrimary.500"}
											textColor={"#fff"}
											flex={"0 0 80px"}
											borderRadius={"4px 0 0 4px"}>
											{item.code}
										</Box>
										<Box px={"16px"} py={"12px"} bg={"gray.250"} flex={"1 0 auto"} textAlign={"left"}>
											{item.desc}
										</Box>
									</Button>
								))}
						</Flex>
					</DrawerBody>

					<DrawerFooter>
						<Button colorScheme='rsSteel' mr={3} onClick={onClose}>
							Cancel
						</Button>
						{/* <Button colorScheme="blue">Save</Button> */}
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</>
	);
};
