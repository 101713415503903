import axios from "axios";
import Swal from "sweetalert2";

// RsPea 保險對象特定查詢
export const callRsGetPeaCareNameService = async (data: object) => {
	try {
		const response = await axios.post("http://localhost:23752/PEA/CARENAME", data, {
			headers: {
				Accept: "*/*",
				"Content-Type": "application/json"
			}
		});
		let result;
		if (typeof response.data === "string") {
			result = JSON.parse(response.data);
		} else {
			result = response.data;
		}
		return result;
	} catch (error: unknown) {
		Swal.fire({
			icon: "error",
			title: "調用醫療人員規格API時出錯",
			text: "請稍後再試",
			confirmButtonText: "確定"
		});
	}
};
