import { create } from "zustand";

export interface HistoryDxListProps {
	data_id: string;
	short_code: string;
	icd_code: string;
	code: string;
	name_en: string;
	name_tw: string;
	original_name: string;
	attach_ccpe: string;
	if_chronic?: string;
}

interface HistoryDxListStore {
	histroyDxList: HistoryDxListProps[];
	updateHistoryDxList: (index: number, diagnosis: HistoryDxListProps) => void;
	appendHistoryDxList: (diagnosis: HistoryDxListProps) => void;
	resetHistoryDxList: () => void;
}

export const initialHistoryDxListList: HistoryDxListProps = {
	data_id: "",
	short_code: "",
	icd_code: "",
	code: "",
	name_en: "",
	name_tw: "",
	original_name: "",
	attach_ccpe: "",
	if_chronic: ""
};

export const useHistoryDxListStore = create<HistoryDxListStore>(set => ({
	histroyDxList: Array(6).fill(initialHistoryDxListList),
	updateHistoryDxList: (index, diagnosis) =>
		set(state => {
			const newHistoryDxListList = [...state.histroyDxList];
			newHistoryDxListList[index] = diagnosis;
			return { histroyDxList: newHistoryDxListList };
		}),
	appendHistoryDxList: diagnosis =>
		set(state => ({
			histroyDxList: [...state.histroyDxList, diagnosis] // 追加模式
		})),

	resetHistoryDxList: () => set({ histroyDxList: Array(6).fill(initialHistoryDxListList) })
}));
