import apiService from './apiService';

export type DeclareStaticResProps = {
    result: string;
    msg: string;
    lists: DeclareStaticDataProps;
};

export type DeclareStaticReqProps = {
    ym: string;
};

export type DeclareStaticDataProps = {
    total: number; // 總件數
    case_01: number; // 一般案件數
    case_other: number; // 其他案件數
    deposit: number; // 押單數
};

export const callDeclareStaticService = async (
    token: string,
    data: DeclareStaticReqProps
): Promise<DeclareStaticResProps> => {
    return apiService<string, any>({
        method: 'GET',
        path: '/api/declare/static/',
        token,
        data: data,
    });
};
