import { Box, Input } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { postVitalSignSetValueService } from "service/vitalSignSetValueService";
import { VitalSignRules, getBackgroundColor } from "functions/VitalSignRules";
import React from "react";
import Swal from "sweetalert2";
import { SwalToast } from "components/CustomComponents/SwalComponents";
import { useLocation } from "react-router-dom";
import { useUserStore } from "store";

type VitalSignProps = {
	data_id?: string;
	setting_uid?: string;
	name?: string;
	value?: string;
	unit?: string;
};
interface SignProps {
	vital_signs?: VitalSignProps[];
	index: number;
	vital_sign_uid: string;
	opd_id: string;
	data_id?: string;
}
interface vitalSignSetValueProps {
	data_id?: string;
	opd_uid: string;
	vital_sign_uid: string;
	value?: string | null;
}

export const Sign: React.FC<SignProps> = ({ index, vital_sign_uid, vital_signs = [], opd_id, data_id }) => {
	// const token = localStorage.getItem('token');
	const { userInfo } = useUserStore();
	const [editStatus, setEditStatus] = useState(false);
	const [value, setValue] = useState("");
	const [name, setName] = useState("");
	const [originalValue, setOriginalValue] = useState("");

	let location = useLocation();

	const pathName = location.pathname;
	const pathSegments = pathName.split("/");
	const lastSegment = pathSegments.pop();

	useEffect(() => {
		const initialItem = vital_signs.find(item => item.setting_uid === vital_sign_uid) || {};
		const initialVal = initialItem.value || "";
		const initialName = initialItem.name || "";
		setName(initialName);
		setValue(initialVal);
		setOriginalValue(initialVal);
	}, [vital_sign_uid, vital_signs]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value);
	};

	const handleSave = async () => {
		if (value !== originalValue) {
			// 检查值是否更改
			const finalData: vitalSignSetValueProps = {
				data_id,
				opd_uid: opd_id,
				vital_sign_uid: vital_sign_uid,
				value: value
			};
			await postVitalSignSetValueAPI(finalData);
			setOriginalValue(value); // 更新 originalValue
		}
	};

	const handleEdit = () => {
		setEditStatus(true);
	};

	const handleBlur = () => {
		setEditStatus(false);
		handleSave();
	};

	const postVitalSignSetValueAPI = async (finalData: vitalSignSetValueProps) => {
		try {
			const result = await postVitalSignSetValueService(userInfo.token!, finalData);
			SwalToast.fire({
				icon: "success",
				title: "已更新"
			});
		} catch (error) {
			console.error("更新失败", error);
			SwalToast.fire({
				icon: "error",
				title: "更新失败"
			});
		}
	};

	const comparisonResult = VitalSignRules(value, name);

	// if (!name.includes('體重') && !name.includes('體溫')) {
	//     return <Box minHeight="20px"></Box>;
	// }

	return (
		<React.Fragment key={index}>
			{editStatus ? (
				<Input
					textColor={"rsPrimary.600"}
					textAlign={"right"}
					onClick={handleEdit}
					onChange={handleChange}
					onKeyDown={event => {
						if (event.key === "Enter") {
							handleSave();
						}
					}}
					onBlur={handleBlur}
					autoFocus
					value={value}
					bg={getBackgroundColor(comparisonResult)}
				/>
			) : (
				<Box
					rounded={"lg"}
					p={"8px 16px"}
					textColor={"rsPrimary.600"}
					textAlign={"right"}
					onClick={handleEdit}
					minHeight='20px'
					bg={getBackgroundColor(comparisonResult)}
					boxShadow={lastSegment === "RegisteredPage" ? "inset 2px 2px 3px rgba(0,0,0,0.2)" : ""}>
					{value || "-"}
				</Box>
			)}
		</React.Fragment>
	);
};
