import { create } from "zustand";

export type DeclareListsItemProps = {
	medical_record_uid: string; // 病歷資料 id
	case_type: string; // 案件分類
	main_diagnosis: string; // 主診斷 icd 10 內碼
	dose_days: number; // 開藥天數
	nhi_type: string; // 掛號身份
	card_no: string; // 就醫序號
	name: string; // 病患姓名
	medical_record_number: string; // 病歷號
	treated_at: string; // 看診日期
	opd_uid: string; // 門診 id
	id_no: string; // 身分證字號
	birth_date: string; // 生日
	error_msg?: string | ""; // 錯誤訊息
	ic_data_uid?: string | null; // 醫療院所 id
};

interface DeclareListsState {
	declareLists: DeclareListsItemProps[];
	updateDeclareLists: (list: DeclareListsItemProps[]) => void;
	clearDeclareLists: () => void;
}

export const useDeclareListsStore = create<DeclareListsState>(set => ({
	declareLists: [],
	updateDeclareLists: list => set({ declareLists: list }),

	clearDeclareLists: () => set({ declareLists: [] })
}));
