import { create } from "zustand";

interface RefillableListsProps {
	lists: {
		data_id: string;
		diagnosis_name: string;
	}[];
}

interface RefillableDetailInfoProps {
	refillable_uid: string;
	refillable_detail_uid: string | null;
	card_no: string | null;
	first_date: string;
	refill_times: number;
	get_times: number;
	lists: {
		data_id?: string;
		seq: number;
		prescription_date: string | null;
		start_date: string;
		end_date: string;
	}[];
}

interface RegistrationRefillableState {
	refillableLists: RefillableListsProps;
	updateRefillableLists: (newData: Partial<RefillableListsProps>) => void;
	resetRefillableLists: () => void;

	refillableDetailInfo: RefillableDetailInfoProps;
	updateRefillableDetailInfo: (newData: Partial<RefillableDetailInfoProps>) => void;
	resetRefillableDetailInfo: () => void;
}

const initialRefillableListsData: RefillableListsProps = {
	lists: []
};

const initialRefillableDetailInfoData: RefillableDetailInfoProps = {
	refillable_uid: "",
	refillable_detail_uid: "",
	card_no: "",
	first_date: "",
	refill_times: 0,
	get_times: 0,
	lists: []
};

export const useRegistrationRefillableStore = create<RegistrationRefillableState>(set => ({
	refillableLists: initialRefillableListsData,
	updateRefillableLists: newData =>
		set(state => ({
			refillableLists: { ...state.refillableLists, ...newData }
		})),
	resetRefillableLists: () =>
		set(() => ({
			refillableLists: initialRefillableListsData
		})),

	refillableDetailInfo: initialRefillableDetailInfoData,
	updateRefillableDetailInfo: newData =>
		set(state => ({
			refillableDetailInfo: {
				...state.refillableDetailInfo,
				...newData
			}
		})),
	resetRefillableDetailInfo: () =>
		set(() => ({
			refillableDetailInfo: initialRefillableDetailInfoData
		}))
}));
