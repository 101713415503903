import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useVitalSignQuery, vitalSignSettingListsProps } from "./utils/useVitalSignQuery";
import { useSignContext } from "contexts";

export const VitalSignHeader = () => {
	const { lists, isLoading, error } = useVitalSignQuery();
	const { state } = useSignContext();
	const [displayedLists, setDisplayedLists] = useState<vitalSignSettingListsProps[]>([]);

	useEffect(() => {
		if (lists) {
			const filteredLists = lists.filter((item: vitalSignSettingListsProps, index: number) => state.selectedSigns.includes(item.id));
			setDisplayedLists(filteredLists);
		}
	}, [lists, state.selectedSigns]);

	if (isLoading) return <span>Loading...</span>;

	return (
		<>
			{displayedLists &&
				displayedLists.map((item, index) => (
					<Box key={index} textColor={"rsPrimary.600"} pr={"8px"} flex={"0 0 10%"} textAlign={"right"}>
						{item.name} {item.unit}
					</Box>
				))}
		</>
	);
};
