import { Flex, Text, Input, Button } from "@chakra-ui/react";
import { usePricingStore } from "store/TreatmentManagement";

export const FeeDisplay = () => {
	const { costPrice, insurancePrice, selfFundedPrice } = usePricingStore();

	return (
		<>
			<Flex gap={"16px"}>
				<Flex gap={"4px"} align={"center"}>
					<Text colorScheme='semiTitle' w={"20px"}>
						C
					</Text>
					<Input
						bg={"#fff"}
						placeholder='成本價'
						value={Number(costPrice).toFixed(1)}
						rounded={"4px"}
						boxShadow={"4px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;"}
						readOnly></Input>
				</Flex>
				<Flex gap={"4px"} align={"center"}>
					<Text colorScheme='semiTitle' w={"20px"}>
						P
					</Text>
					<Input
						bg={"#fff"}
						placeholder='健保價/自費價'
						value={Math.floor(Number(insurancePrice)) + "/" + Math.floor(Number(selfFundedPrice))}
						rounded={"4px"}
						boxShadow={"4px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;"}
						readOnly></Input>
				</Flex>
				<Flex gap={"4px"} align={"center"}>
					<Text colorScheme='semiTitle' w={"20px"}>
						專
					</Text>
					<Input
						bg={"#fff"}
						placeholder='專案比率'
						rounded={"4px"}
						boxShadow={"4px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;"}
						readOnly></Input>
				</Flex>
			</Flex>
		</>
	);
};
