import { ListItem, Flex, Box, Text, Input } from "@chakra-ui/react";
import { VitalSignRules, VitalSignRulesType, getBackgroundColor } from "functions/VitalSignRules";
import { vitalSignSettingListsProps } from "service/vitalSignSettingListsService"; // 确保这里导入正确

export type SignProps = {
	item: vitalSignSettingListsProps;
	index: number;
	value: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Sign = ({ item, index, value, onChange }: SignProps) => {
	const comparisonResult = VitalSignRules(value, item.name);

	return (
		<ListItem mb={"8px"} key={index}>
			<Flex gap={"16px"} align={"center"} key={index}>
				<Text textStyle={"semiTitle"} flex={"0 0 auto"}>
					{item.name}
				</Text>
				<Input bg={getBackgroundColor(comparisonResult)} name={item.name} onChange={onChange} value={value}></Input>
				<Text textStyle={"semiTitle"} flex={"0 0 auto"}>
					{item.unit}
				</Text>
			</Flex>
		</ListItem>
	);
};
