import apiService from "./apiService";

export const callDrugFreqBaseService = async (token: string, data: number) => {
	const queryParams = data ? { data } : undefined;
	try {
		const path = `/api/drug/freq/base`;
		const response = await apiService<undefined, any>({
			method: "GET",
			path,
			token,
			data: queryParams
		});
		return response;
	} catch (error) {
		console.error("Error calling DrugFreqBaseService:", error);
		if (error instanceof Error) {
			return { result: "error", message: error.message };
		} else {
			return { result: "error", message: "An unknown error occurred" };
		}
	}
};
