import { DeclarationAudit } from "components/DeclarationAudit";

const DeclarationAuditPage = () => {
	return (
		<>
			<DeclarationAudit />
		</>
	);
};

export default DeclarationAuditPage;
