import React, { createContext, useContext, useState, ReactNode } from "react";

interface IsCopyDrugFromHistoryForChronicContextValue {
	isCopyDrugFromHistoryForChronicStatus: boolean;
	setIsCopyDrugFromHistoryForChronicStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsCopyDrugFromHistoryForChronicContext = createContext<IsCopyDrugFromHistoryForChronicContextValue | undefined>(undefined);

interface IsCopyDrugFromHistoryForChronicProviderProps {
	children: ReactNode;
}

export const IsCopyDrugFromHistoryForChronicProvider: React.FC<IsCopyDrugFromHistoryForChronicProviderProps> = ({ children }) => {
	const [isCopyDrugFromHistoryForChronicStatus, setIsCopyDrugFromHistoryForChronicStatus] = useState(false);

	return (
		<IsCopyDrugFromHistoryForChronicContext.Provider
			value={{
				isCopyDrugFromHistoryForChronicStatus,
				setIsCopyDrugFromHistoryForChronicStatus
			}}>
			{children}
		</IsCopyDrugFromHistoryForChronicContext.Provider>
	);
};

export const useIsCopyDrugFromHistoryForChronicStatus = (): IsCopyDrugFromHistoryForChronicContextValue => {
	const context = useContext(IsCopyDrugFromHistoryForChronicContext);

	if (!context) {
		throw new Error("useIsCopyDrugFromHistoryForChronicStatus must be used within a IsCopyDrugFromHistoryForChronicProvider");
	}

	return context;
};
