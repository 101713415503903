import { RshisGetCardStatusService } from "service/RsService/RshisGetCardStatusService";

type cardType = {
	cardType: string;
};

export const hisGetCardStatus = async (data: string): Promise<string> => {
	const finalData: cardType = {
		cardType: data
	};
	try {
		const result = await RshisGetCardStatusService(finalData);
		console.log("RshisGetCardStatusService API 返回結果", result);
		return result;
	} catch (error) {
		console.error("調用 RshisGetCardStatusService 時發生錯誤:", error);
		throw error;
	}
};
