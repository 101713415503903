import { create } from "zustand";

export interface FeeState {
	//1 營業收入
	// 1掛號
	// HG掛號

	// 2藥費
	// O口服 L外用 I注射 G胰島素 W藥水

	mFeeO: number;
	xFeeO: number;
	mFeeL: number;
	xFeeL: number;
	mFeeI: number;
	xFeeI: number;
	mFeeG: number;
	xFeeG: number;
	mFeeW: number;
	xFeeW: number;
	// 5檢查 檢驗
	// E檢查 Q檢驗 X放射
	mFeeE: number;
	xFeeE: number;
	mFeeQ: number;
	xFeeQ: number;
	mFeeX: number;
	xFeeX: number;
	// 6住院
	// D病床 H論病 N管灌 U精神 B血液 Y托嬰
	//沒D
	mFeeH: number;
	xFeeH: number;
	//沒N
	mFeeB: number;
	xFeeB: number;
	mFeeU: number;
	xFeeU: number;
	// 沒Y
	// 7代辦費
	// CO自付 HD高藥 EQ高檢 J代辦
	xFeeCO: number;

	mFeeEQ: number;
	xFeeEQ: number;
	mFeeHD: number;
	// xFeeHD: number;

	// 8材料
	// Proff證明 M材料
	mFeeM: number;
	xFeeM: number;
	// 10藥事服務費
	// S藥服
	mFeeS: number;
	xFeeS: number;
	// 11其他
	// LPAY押單 PAY還單費 Debt欠款 Repay還款 K其他

	//2 申報點數
	// 3診察費
	// PH診察
	mFeePH: number;
	xFeePH: number;
	// 4診療費
	// T注技 R復健 F處置 P手術 Ａ麻醉
	mFeeT: number;
	xFeeT: number;
	mFeeR: number;
	xFeeR: number;
	mFeeF: number;
	xFeeF: number;
	mFeeP: number;
	xFeeP: number;
	mFeeA: number;
	xFeeA: number;

	// 總和 前端頁面
	// 健保總藥費
	mDrugFee: number;
	xDrugFee: number;

	freeBurden: string;

	// Shd_CO: number;  六代應收自付額

	mScrap: number;
	mFeeV: number;
	mFee7: number;
	mFee8: number;
	mFee99: number;
	xFeeHG: number;
	xFeeLPay: number;
	xFeeV: number;
	xFee7: number;
	xFee8: number;
	xFee99: number;
	mCostO: number;
	mCostL: number;
	mCostI: number;
	mCostF: number;
	mCostM: number;
	mCost99: number;
	FeeMoney: number;
	FeeAll: number;
	// FeeAllH: number;
	FeeAllP: number;
	CostAll: number;
	FeeAdd: number;
	FeeHDH: number;
	FeeHDP: number;

	FeeDH: number;

	// 檢驗檢查費
	mEQFee: number;
	xEQFee: number;

	// 診療費
	mDiagnosisFee: number;
	xDiagnosisFee: number;
	//交付
	DeliverDrugFee: number;
	DeliverRFee: number;
	DeliverEQFee: number;
	DeliverMFee: number;

	// 總和
	mAllFee: number; //健保總和
	xAllFee: number; //自費總和

	//這邊應該沒有要用了
	dFeeE: number;
	dFeeM: number;
	dFeeR: number;
}

export const initialFeeState: FeeState = {
	//1 營業收入
	// 1掛號
	// HG掛號

	// 2藥費
	// O口服 L外用 I注射 G胰島素 W藥水
	mFeeO: 0,
	xFeeO: 0,
	mFeeL: 0,
	xFeeL: 0,
	mFeeI: 0,
	xFeeI: 0,
	mFeeG: 0,
	xFeeG: 0,
	mFeeW: 0,
	xFeeW: 0,
	// 5檢查 檢驗
	// E檢查 Q檢驗 X放射
	mFeeE: 0,
	xFeeE: 0,
	mFeeQ: 0,
	xFeeQ: 0,
	mFeeX: 0,
	xFeeX: 0,
	// 6住院
	// D病床 H論病 N管灌 U精神 B血液 Y托嬰
	//沒D
	mFeeH: 0,
	xFeeH: 0,
	//沒N
	mFeeB: 0,
	xFeeB: 0,
	mFeeU: 0,
	xFeeU: 0,
	// 沒Y
	// 7代辦費
	// CO自付 HD高藥 EQ高檢 J代辦
	xFeeCO: 0,
	mFeeEQ: 0,
	xFeeEQ: 0,
	mFeeHD: 0,
	// xFeeHD: 0,
	// 8材料
	// Proff證明 M材料
	mFeeM: 0,
	xFeeM: 0,
	// 10藥事服務費
	// S藥服
	mFeeS: 0,
	xFeeS: 0,
	// 11其他
	// LPAY押單 PAY還單費 Debt欠款 Repay還款 K其他

	//2 申報點數
	// 3診察費
	// PH診察
	mFeePH: 0,
	xFeePH: 0,
	// 4診療費
	// T注技 R復健 F處置 P手術 Ａ麻醉
	mFeeT: 0,
	xFeeT: 0,
	mFeeR: 0,
	xFeeR: 0,
	mFeeF: 0,
	xFeeF: 0,
	mFeeP: 0,
	xFeeP: 0,
	mFeeA: 0,
	xFeeA: 0,

	// 總和 前端頁面
	// 健保總藥費
	mDrugFee: 0,
	xDrugFee: 0,
	mAllFee: 0, // 健保總和
	xAllFee: 0, // 自費總和

	freeBurden: "",

	xFeeHG: 0,
	xFeeLPay: 0,
	// Shd_CO: 0,
	mScrap: 0,

	mFeeV: 0,

	mFee7: 0,
	mFee8: 0,
	mFee99: 0,
	dFeeE: 0,
	dFeeM: 0,
	dFeeR: 0,

	xFeeV: 0,

	xFee7: 0,
	xFee8: 0,
	xFee99: 0,
	mCostO: 0,
	mCostL: 0,
	mCostI: 0,
	mCostF: 0,
	mCostM: 0,
	mCost99: 0,
	FeeMoney: 0,
	FeeAll: 0,
	// FeeAllH: 0,
	FeeAllP: 0,
	CostAll: 0,
	FeeAdd: 0,
	FeeHDH: 0,
	FeeHDP: 0,

	FeeDH: 0,
	mEQFee: 0,
	xEQFee: 0,
	mDiagnosisFee: 0,
	xDiagnosisFee: 0,
	DeliverDrugFee: 0,
	DeliverRFee: 0,
	DeliverEQFee: 0,
	DeliverMFee: 0
};
export interface feeState {
	initialFeeState: FeeState;
	feeData: FeeState;
	setFeeData: (feeValues: Partial<FeeState>) => void;
	resetFeeData: () => void;
}
export const useFeeStore = create<feeState>(set => ({
	initialFeeState: { ...initialFeeState },
	feeData: { ...initialFeeState },
	setFeeData: (feeValues: Partial<FeeState>) => set(state => ({ feeData: { ...state.feeData, ...feeValues } })),
	resetFeeData: () => set({ feeData: { ...initialFeeState } })
}));

export default useFeeStore;
