import axios from "axios";
import apiService from "./apiService";

// 刪除人員執照
export const deleteMedicalStaffLicenseService = async (token: string, data_id: string) => {
	return apiService<undefined, any>({
		method: "DELETE",
		path: `/api/medical_staff/license?id=${data_id}`,
		token
	});
};
// export const deleteMedicalStaffLicenseService = async (
//     token: string,
//     data_id: string
// ) => {
//     try {
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };
//         const url = `/api/medical_staff/license?id=${data_id}`;

//         const response = await axios.delete(url, {
//             headers,
//         });

//         const result = response.data;
//         console.log(result, 'API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
