import React from "react";

import { IsChronicProvider } from "./IsChronicContext";
import { IsPrintProofOpenProvider } from "./IsPrintProofOpenContext";
import { RoomStatusProvider } from "./RoomStatusContext";

interface CombinedRegistrationProvidersProps {
	children: React.ReactNode;
}

export const CombinedRegistrationProviders: React.FC<CombinedRegistrationProvidersProps> = ({ children }) => {
	return (
		<>
			<RoomStatusProvider>
				<IsChronicProvider>
					<IsPrintProofOpenProvider>{children}</IsPrintProofOpenProvider>
				</IsChronicProvider>
			</RoomStatusProvider>
		</>
	);
};

// Re-export individual contexts and hooks if needed
