// store/userStore.ts
import { create } from "zustand";

export type HospInfoProps = {
	customer_uid: string;
	hosp_id: string;
	nhid: string;
	news_online_url: string;
	hosp_name: string;
};

export type UserInfoProps = {
	result: string;
	user_name: string;
	user_id: string;
	hosp_info: HospInfoProps;
	token: string;
};
const initialUserInfo: UserInfoProps = {
	result: "",
	user_name: "",
	user_id: "",
	hosp_info: {
		customer_uid: "",
		hosp_id: "",
		nhid: "",
		news_online_url: "",
		hosp_name: ""
	},
	token: ""
};
interface UserState {
	userInfo: UserInfoProps;
	updateUserInfo: (newData: Partial<UserInfoProps>) => void;
	resetUserInfo: () => void;
}
export const useUserStore = create<UserState>(set => ({
	userInfo: initialUserInfo,
	updateUserInfo: newData =>
		set(state => ({
			...state,
			userInfo: { ...state.userInfo, ...newData }
		})),
	resetUserInfo: () =>
		set(state => ({
			...state,
			userInfo: initialUserInfo
		}))
}));
