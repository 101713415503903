import React, { useState, useEffect } from "react";
import { Box, Flex, Center, Text, Table, TableContainer, Thead, Tbody, Tr, Th, Td, Checkbox } from "@chakra-ui/react";
import { usePrescriptionStore } from "store/TreatmentManagement";
import { useTreatmentInfoStore } from "store/TreatmentManagement";
import { ChronicPrescriptionItem } from "./index";

export const RightContent = ({
	isModalOpen,
	chronicPrescriptionList,
	setChronicPrescriptionList
}: {
	isModalOpen: boolean;
	chronicPrescriptionList: ChronicPrescriptionItem[];
	setChronicPrescriptionList: (list: ChronicPrescriptionItem[]) => void;
}) => {
	const { prescriptionList } = usePrescriptionStore();
	const { treatmentInfo } = useTreatmentInfoStore();

	const handleChangeCheckbox = (data_id: string) => {
		setChronicPrescriptionList(
			chronicPrescriptionList.map(item =>
				item.data_id === data_id ? { ...item, if_chronic: item.if_chronic === "1" ? "0" : "1" } : item
			)
		);
	};

	useEffect(() => {
		if (isModalOpen) {
			setChronicPrescriptionList(
				prescriptionList && prescriptionList.length > 0 && prescriptionList.some(item => item.data_id)
					? prescriptionList
							.filter(
								prescription =>
									parseInt(prescription.dose_day || "0") >= 14 &&
									prescription.controlled_level.toString() !== "1" &&
									prescription.controlled_level.toString() !== "2"
							)
							.map(item => ({
								data_id: item.data_id,
								if_chronic: item.if_chronic || "1",
								name_tw: item.name_tw,
								qty_in_day: item.qty_in_day ?? "",
								qty: item.qty ?? "",
								dose_day: item.dose_day ?? "",
								freq_custom_code: item.freq_custom_code ?? "",
								total_qty: item.total_qty ?? "",
								given_qty: item.given_qty ?? "",
								special_mark: item.special_mark ?? ""
							}))
					: []
			);
		} else {
			setChronicPrescriptionList([]);
		}
	}, [isModalOpen]);

	const allChecked = chronicPrescriptionList.every(item => item.if_chronic === "1");
	const isIndeterminate = chronicPrescriptionList.some(item => item.if_chronic === "1") && !allChecked;

	const handleSelectAll = () => {
		const newIfChronicValue = allChecked ? "0" : "1"; // 如果全勾選，則設為0，否則設為1
		setChronicPrescriptionList(
			chronicPrescriptionList.map(item => ({
				...item,
				if_chronic: newIfChronicValue
			}))
		);
	};

	return (
		<Box bg={"#F5F5F5"} rounded={"lg"} h={`calc(100vh - 200px)`} w={"100%"} overflow={"auto"}>
			<Table sx={{ "th, td": { padding: "12px 16px" } }}>
				<TableContainer>
					<Thead bg={"#D9D9D9"} width='100%'>
						<Tr width='100%'>
							<Th fontSize='16px' width='6%'>
								<Checkbox
									isChecked={allChecked}
									isIndeterminate={isIndeterminate}
									onChange={handleSelectAll}
									colorScheme='rsPrimary'>
									勾選
								</Checkbox>
							</Th>
							<Th fontSize='16px' width='17%'>
								Rx.處方名稱
							</Th>
							<Th fontSize='16px' width='9%'>
								日量
							</Th>
							<Th fontSize='16px' width='9%'>
								次量
							</Th>
							<Th fontSize='16px' width='9%'>
								天數
							</Th>
							<Th fontSize='16px' width='9%'>
								用法
							</Th>
							<Th fontSize='16px' width='9%'>
								總量
							</Th>
							<Th fontSize='16px' width='9%'>
								發藥量
							</Th>
							<Th fontSize='16px' width='9%'>
								M欄
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						{chronicPrescriptionList.map(prescription => (
							<Tr
								_hover={{
									bg: "#E0E0E0",
									cursor: "pointer"
								}}
								onClick={(e: React.MouseEvent<HTMLDivElement>) => {
									const target = e.target as HTMLInputElement; // 將 target 轉型為 HTMLInputElement
									if (target.type !== "checkbox") {
										handleChangeCheckbox(prescription.data_id);
									}
								}}>
								<Td onClick={e => e.stopPropagation()}>
									<Box px={2}>
										<Checkbox
											isChecked={prescription.if_chronic === "1"}
											bg={"#FFFFFF"}
											colorScheme='rsPrimary'
											onChange={() => handleChangeCheckbox(prescription.data_id)}
										/>
									</Box>
								</Td>
								<Td>{prescription.name_tw}</Td>
								<Td>{prescription.qty_in_day}</Td>
								<Td>{prescription.qty}</Td>
								<Td>{prescription.dose_day}</Td>
								<Td>{prescription.freq_custom_code}</Td>
								<Td>{prescription.total_qty}</Td>
								<Td>{prescription.given_qty}</Td>
								<Td>{prescription.special_mark}</Td>
							</Tr>
						))}
					</Tbody>
				</TableContainer>
			</Table>
		</Box>
	);
};
