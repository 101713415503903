import { Flex, Text, Box } from "@chakra-ui/react";

type PatientInfoProps = {
	sex?: string;
	patient_name?: string;
	date?: string;
	uid?: string;
	birth?: string;
};

export const PatientInfo = () => {
	const patientData: PatientInfoProps = {};
	// const patientData: PatientInfoProps = {
	//     sex: 'M',
	//     patient_name: '高金素麵',
	//     date: '113.06.26',
	//     uid: 'H223456789',
	//     birth: '100.12.25',
	// };
	return (
		<>
			<Flex align={"center"} my={"8px"}>
				<Text textColor={"#000"} fontWeight={"semibold"} flex={"0 0 112px"}>
					姓名
				</Text>
				<Box
					bg={patientData.sex === "M" ? "#79A09F" : patientData.sex === "F" ? "#D28989" : "#fff"}
					h={"40px"}
					px={"8px"}
					rounded={"4px"}>
					<Text fontSize={"30px"} textColor={"#fff"} lineHeight={"40px"} textAlign={"center"}>
						{patientData.patient_name}
					</Text>
				</Box>
			</Flex>
			<Flex align={"center"} my={"8px"}>
				<Text textColor={"#000"} fontWeight={"semibold"} flex={"0 0 112px"}>
					看診日期
				</Text>
				<Text>{patientData.date}</Text>
			</Flex>
			<Flex align={"center"} my={"8px"}>
				<Text textColor={"#000"} fontWeight={"semibold"} flex={"0 0 112px"}>
					身分證號
				</Text>
				<Text>{patientData.uid}</Text>
			</Flex>
			<Flex align={"center"} my={"8px"}>
				<Text textColor={"#000"} fontWeight={"semibold"} flex={"0 0 112px"}>
					生日
				</Text>
				<Text>{patientData.birth}</Text>
			</Flex>
		</>
	);
};
