import { TreatmentInfoProps } from "store/TreatmentManagement/useTreatmentInfoStore";

import { PatientInfoProps } from "store/Registration";

export const patientLogic = (treatmentInfo: TreatmentInfoProps, patientInfo: PatientInfoProps) => {
	let finalData = {
		Pat_Code: treatmentInfo.medical_record_number,
		Pat_Name: treatmentInfo.patient_name,
		Pat_IdnoType: (() => {
			switch (patientInfo.info.id_no_type) {
				case "1": // 身分證
					return 0;
				case "2": // 居留證號
					return 2;
				case "3": // 護照
					return 1;
				default:
					return 0;
			}
		})(), //雲端邏輯 1 身分證 2 居留證號 3 護照 ； 列印邏輯 0 身分證 1 護照 2 居留證號
		Pat_IDNo: treatmentInfo.id_no,
		Pat_Gender: treatmentInfo.sex === "F" ? "女" : treatmentInfo.sex === "M" ? "男" : "",
		Pat_Birth: treatmentInfo.birth_date?.toISOString(),
		Pat_BirthTime: "", // 目前應該沒
		Pat_Tel: patientInfo.phone?.find(phone => phone.type === "2")?.number || "",
		Pat_Mobile: patientInfo.phone?.find(phone => phone.type === "1")?.number || "",
		Pat_Addr: patientInfo.address?.[0]?.area_name
			? `${patientInfo.address[0].area_name}` + `${patientInfo.address[0].city_name}` + `${patientInfo.address[0].content}`
			: "",
		Pat_Memo: "", // 病患備註 #20 押單收200塊, % 都不收, $20 掛號收200 等...規則
		Pat_Job: patientInfo.info.job,
		Pat_Mark: patientInfo.info.note, // 病患註記
		//Patient
		Pat_OriCode: "",
		Pat_ShortCode: "",
		Pat_Allergy: "",
		Req: 0,
		FirstDate: "",
		LastDate: ""
	};
	return finalData;
};
