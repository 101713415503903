import { Flex, Text, Box } from "@chakra-ui/react";

type dxListProps = {
	name?: string;
	icd_code?: string;
};
export const DxInfo = () => {
	const dxList: dxListProps[] = [];
	// const dxList = [
	//     {
	//         name: 'URI急性上呼吸道感染',
	//         icd_code: 'J069',
	//     },
	//     {
	//         name: '急性扁桃腺炎',
	//         icd_code: 'J0390',
	//     },
	//     {
	//         name: '急性胃炎未伴有出血',
	//         icd_code: 'K2900',
	//     },
	//     {
	//         name: '激躁性腸症候群未伴有腹瀉',
	//         icd_code: 'K581',
	//     },
	//     {
	//         name: '功能性消化不良',
	//         icd_code: 'K30',
	//     },
	//     {
	//         name: '花粉所致過敏性鼻炎',
	//         icd_code: 'J301',
	//     },
	// ];
	return (
		<>
			<Text textStyle={"semiTitle"}>診斷（ICD10）</Text>
			<Flex my={"8px"} flexWrap={"wrap"} gap={"8px"}>
				{dxList &&
					dxList.map((item, index) => (
						<Text
							key={index}
							p={"4px 16px"}
							bg={index === 0 ? "#D08C60" : "#BFC6A2"}
							textColor={index === 0 ? "#fff" : "#000"}
							rounded={"md"}
							h={"30px"}
							textOverflow={"ellipsis"}
							overflow={"hidden"}
							whiteSpace={"nowrap"}>
							{item.name}
							{item.icd_code}
						</Text>
					))}
			</Flex>
		</>
	);
};
