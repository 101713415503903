import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Input, Select } from "@chakra-ui/react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import zhTW from 'date-fns/locale/zh-TW';
import moment from 'moment';

interface IconDatePickerProps {
  selectedDate: Date | null;
  onChange: (date: Date | null) => void;
}

export const IconDatePicker: React.FC<IconDatePickerProps> = ({ selectedDate, onChange }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const [currentMonth, setCurrentMonth] = useState<number>(
    parseInt(moment(selectedDate)?.format('MM')) ||
    parseInt(moment(new Date()).format('MM'))
  );
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const thisYear = new Date().getFullYear();
  const startYear = thisYear - 100;
  const endYear = thisYear + 20;
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const formatDate = (date: Date | null): string => {
    if (!date) return '';
    const rocYear = date.getFullYear() - 1911;
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `民國 ${rocYear} 年 ${month.toString().padStart(2, '0')} 月 ${day.toString().padStart(2, '0')} 日`;
  };

  const handleChange = (date: Date | null) => {
    onChange(date);
    setIsOpen(false);
    setInputValue(formatDate(date));
  };

  const toggleCalendar = () => {
    setIsOpen(!isOpen);
  };

  const handleChangeDateByInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);

    const match = value.match(/民國\s*(\d+)\s*年\s*(\d+)\s*月\s*(\d+)\s*日/);
    if (match) {
      const [_, rocYear, month, day] = match;
      const year = parseInt(rocYear) + 1911;
      const parsedDate = new Date(year, parseInt(month) - 1, parseInt(day));
      if (!isNaN(parsedDate.getTime())) {
        onChange(parsedDate);
      }
    } else {
      onChange(null);
    }
  };

  const handleBlur = () => {
    if (selectedDate) {
      setInputValue(formatDate(selectedDate));
    } else {
      setInputValue('');
    }
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    setInputValue(formatDate(selectedDate));
    if (selectedDate) {
      const selectedYear = moment(selectedDate).year();
      setCurrentYear(selectedYear);
      const month = parseInt(moment(selectedDate).format('MM'), 10);
      setCurrentMonth(month);
    }
  }, [selectedDate]);

  return (
    <div ref={wrapperRef} style={{ position: 'relative' }}>
      <Input
        ref={inputRef}
        value={inputValue}
        onChange={handleChangeDateByInput}
        onBlur={handleBlur}
        onClick={toggleCalendar}
        borderColor="inherit" 
        bg={'#fff'}
        readOnly={true}
      />
      {/* <FaCalendarAlt
        style={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
        }}
        onClick={toggleCalendar}
      /> */}
      {isOpen && (
        <div style={{ position: 'absolute', zIndex: 1000 }}>
          <DatePicker
            maxDate={new Date()}
            selected={selectedDate}
            onChange={handleChange}
            inline
            showYearDropdown
            showMonthDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={100}
            locale={zhTW}
            todayButton="回到今天"
            yearItemNumber={4}
            dateFormat="yyy.MM.dd"
            renderCustomHeader={({ changeYear, changeMonth }) => (
              <div className="flex justify-center items-center gap-2">
                <div>
                  <Select
                    size={'sm'}
                    onChange={e => {
                      const selectedYear = parseInt(e.target.value);
                      setCurrentYear(selectedYear);
                      changeYear(selectedYear);
                      e.stopPropagation();
                    }}
                    className="text-base bg-gray-100 cursor-pointer z-50 w-full"
                    value={currentYear}
                  >
                    {Array.from(
                      { length: endYear - startYear + 1 },
                      (_, index) => {
                        const year = startYear + index;
                        return (
                          <option key={index} value={year}>
                            民國
                            {year > 1911 ? year - 1911 : year}{' '}
                            年
                          </option>
                        );
                      }
                    )}
                  </Select>
                </div>
                <div>
                  <Select
                    className="text-base bg-gray-100 cursor-pointer z-50"
                    size={'sm'}
                    onChange={e => {
                      const selectedMonth = parseInt(e.target.value);
                      setCurrentMonth(selectedMonth);
                      changeMonth(selectedMonth - 1);
                      e.stopPropagation();
                    }}
                    value={currentMonth}
                  >
                    {Array.from({ length: 12 }, (_, index) => {
                      const month = index + 1;
                      return (
                        <option key={index} value={month}>
                          {month}月
                        </option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default IconDatePicker;