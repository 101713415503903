import React, { useState, useEffect, forwardRef } from "react";
import { Center, Text, Input, InputGroup, InputLeftElement, Flex, Box, Button } from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import moment from "moment";
import { zhTW } from "date-fns/locale";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { useDeclareSearchRangeStore } from "store/DeclarationAudit/useDeclareSearchRangeStore";
import { useDeclareProgressStore } from "store/DeclarationAudit/useDeclareProgressStore";
import Swal from "sweetalert2";

export const DatePick = () => {
	const { declareSearchRangeData, updateDeclareSearchRangeData } = useDeclareSearchRangeStore();

	const [selectedDate, setSelectedDate] = useState<Date>(() => {
		if (declareSearchRangeData.ym) {
			return moment(declareSearchRangeData.ym, "YYYY-MM").toDate();
		} else {
			return new Date();
		}
	});

	const { clearDeclareProgress } = useDeclareProgressStore();

	const ChakraInput = forwardRef((props, ref) => (
		<InputGroup>
			<InputLeftElement pointerEvents='none'>
				<Box textColor={"rsPrimary.500"}>
					<HeroIcons name={"calendar-days"} />
				</Box>
			</InputLeftElement>
			<Input
				{...props}
				ref={ref as React.Ref<HTMLInputElement>}
				readOnly
				cursor={"pointer"}
				value={formatROCDate(selectedDate ?? new Date())}
			/>
		</InputGroup>
	));

	const handleChangeDate = (date: Date | null) => {
		if (date && date !== selectedDate) {
			setSelectedDate(date);
		}
	};

	const formatROCDate = (date: Date) => {
		const rocYear = date.getFullYear() - 1911;
		const month = moment(date).format("MM");
		return `${rocYear}-${month}`;
	};

	const handleFocusDate = () => {
		console.log("DatePicker focus");
	};

	const handleBlurDate = (event: React.FocusEvent<HTMLInputElement>) => {
		console.log("DatePicker blur");
		if (selectedDate && formatROCDate(selectedDate) !== declareSearchRangeData.ym) {
			updateDeclareSearchRangeData({
				ym: moment(selectedDate).format("YYYY-MM")
			});
			console.log("選擇日期，更新時間區間", selectedDate);
		} else {
			console.log("selectedDate is null");
		}
		clearDeclareProgress();
	};

	return (
		<Center gap={2} zIndex={20}>
			<Text fontWeight={"semibold"} textColor={"rsPrimary.500"} flexShrink={0}>
				費用年月
			</Text>

			<DatePicker
				wrapperClassName='datePicker'
				selected={selectedDate}
				onChange={handleChangeDate}
				onFocus={handleFocusDate}
				onBlur={handleBlurDate}
				maxDate={new Date()}
				dateFormat='yyy-MM'
				showMonthYearPicker
				locale={zhTW}
				customInput={<ChakraInput />}
				renderCustomHeader={({ date, decreaseYear, increaseYear, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
					<Flex justify={"space-between"} align={"center"} px={2}>
						<Button size={"sm"} onClick={decreaseYear} disabled={prevMonthButtonDisabled}>
							{"<"}
						</Button>
						<Text fontSize={"lg"}>民國 {date.getFullYear() - 1911} 年</Text>
						<Button size={"sm"} onClick={increaseYear} disabled={nextMonthButtonDisabled}>
							{">"}
						</Button>
					</Flex>
				)}
			/>
		</Center>
	);
};
