import axios from "axios";
import Swal from "sweetalert2";

export type RsGetStokenResProps = {
	ERRORCODE: number;
	pBuffer: string;
};

export const RsGetStokenService = async (): Promise<RsGetStokenResProps | null> => {
	try {
		const response = await axios.post("http://localhost:23752/VDAT/RsGetStoken");
		console.log(response.data, "看一下response.data");
		return response.data as RsGetStokenResProps;
	} catch (error) {
		console.error("更新虛擬卡憑證失敗");

		return null;
	}
};
