import React, { createContext, useContext, useState, ReactNode } from "react";

interface DraiaiLoadingStatusContextValue {
	isDraiaiLoading: boolean;
	setIsDraiaiLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const DraiaiLoadingStatusContext = createContext<DraiaiLoadingStatusContextValue | undefined>(undefined);

interface DraiaiLoadingStatusProviderProps {
	children: ReactNode;
}

export const DraiaiLoadingStatusProvider: React.FC<DraiaiLoadingStatusProviderProps> = ({ children }) => {
	const [isDraiaiLoading, setIsDraiaiLoading] = useState(false);

	return (
		<DraiaiLoadingStatusContext.Provider
			value={{
				isDraiaiLoading,
				setIsDraiaiLoading
			}}>
			{children}
		</DraiaiLoadingStatusContext.Provider>
	);
};

export const useDraiaiLoadingStatus = (): DraiaiLoadingStatusContextValue => {
	const context = useContext(DraiaiLoadingStatusContext);

	if (!context) {
		throw new Error("useDraiaiLoadingStatus 必須在 DraiaiLoadingStatusProvider 內使用");
	}

	return context;
};
