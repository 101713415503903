// ... existing imports ...
import { VHChisGetSeqNumber256N1ReqProps, postVHChisGetSeqNumber256N1Service } from 'service/VHIC/VHChisGetSeqNumber256N1Service';

export type VHChisGetSeqNumber256N1ResultProps = {
    visitDateTime: string;            // 就醫日期時間
    medicalSerialNumber: string;      // 就醫序號
    medicalInstitutionCode: string;   // 醫療機構代碼
    securitySignature: string;        // 安全簽章
    samId: string;                    // SAM卡號
    isSameDayVisit: boolean;          // 是否為同日就醫
    medicalIdentificationCode: string; // 就醫識別碼
}
// Add this new function
export const VHChisGetSeqNumber256N1Format = (pBuffer: string) => {
    return {
        visitDateTime: pBuffer.slice(0, 13),
        medicalSerialNumber: pBuffer.slice(13, 17),
        medicalInstitutionCode: pBuffer.slice(17, 27),
        securitySignature: pBuffer.slice(27, 283),
        samId: pBuffer.slice(283, 295),
        isSameDayVisit: pBuffer[295] === 'Y',
        medicalIdentificationCode: pBuffer.slice(296)
    };
}
