import apiService from "./apiService";

interface dataProps {
	keyword: string;
	page?: string | number;
	type?: string;
}

// 12. 診療畫面查詢院內 ICD-10 資訊
export const callICD10ForTreatmentService = async (token: string, searchData: dataProps) => {
	return apiService({
		method: "GET",
		path: "/api/icd10/for_treatment",
		token,
		data: searchData
	});
};
