import axios from "axios";
import apiService from "./apiService";
interface finalDataProps {
	data_id: string;
	ids: string[];
}
// 設定藥品附加診斷排序
export const postDrugAttachICDSortService = async (token: string, updatedData: finalDataProps) => {
	return apiService<finalDataProps, any>({
		method: "POST",
		path: "/api/drug/attach_icd/sort",
		token,
		data: updatedData
	});
};
// export const postDrugAttachIcdSortService = async (
//     token: string,
//     finalData: finalDataProps
// ) => {
//     try {
//         const requestBody = JSON.stringify(finalData);
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };
//         console.log(requestBody, 'requestBody設定藥品附加診斷排序');

//         const response = await axios.post(
//             '/api/drug/attach_icd/sort',
//             requestBody,
//             {
//                 headers,
//             }
//         );

//         const result = response.data;
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
