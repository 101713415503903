// 批價模型（ChargeModel）
import { currentVisitInfoDataProps } from "store/Registration";
import { cashDataStoreProps } from "store/TreatmentManagement/Payment";
import { FeeState } from "store/TreatmentManagement/Payment/useFeeStore";
import { TreatmentInfoProps } from "store/TreatmentManagement/useTreatmentInfoStore";
import { getDoseDay } from "functions/getDoseDay";
import { drugTypeListProps } from "service/drugTypeListService";
import { DrugFreqListsProps } from "store/DataSetting/useDrugFreqListStore";
import { PrescriptionProps } from "store/TreatmentManagement";
import { PatientInfoProps } from "store/Registration";

export const slowRecLogic = (
	prescriptionList: PrescriptionProps[],
	treatmentInfo: TreatmentInfoProps,
	drugTypeList: drugTypeListProps[],
	drugFreqLists: DrugFreqListsProps[]
) => {
	let finalData = {
		OpenDate: new Date().toISOString(),
		Freq: treatmentInfo.refillable?.refill_times ?? 0,
		Days: getDoseDay(prescriptionList, drugTypeList)
	};
	return finalData;
};
