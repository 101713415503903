import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import Swal from "sweetalert2";

interface UnsavedChangesContextValue {
	hasUnsavedChanges: boolean;
	setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const UnsavedChangesContext = createContext<UnsavedChangesContextValue | undefined>(undefined);

interface UnsavedChangesProviderProps {
	children: ReactNode;
}

export const UnsavedChangesProvider: React.FC<UnsavedChangesProviderProps> = ({ children }) => {
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

	useEffect(() => {
		const handleBeforeUnload = async (event: BeforeUnloadEvent) => {
			if (hasUnsavedChanges) {
				event.preventDefault();
				event.returnValue = "";
			}
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [hasUnsavedChanges]);

	return <UnsavedChangesContext.Provider value={{ hasUnsavedChanges, setHasUnsavedChanges }}>{children}</UnsavedChangesContext.Provider>;
};

export const useUnsavedChanges = (): UnsavedChangesContextValue => {
	const context = useContext(UnsavedChangesContext);
	if (context === undefined) {
		throw new Error("useUnsavedChanges must be used within a UnsavedChangesProvider");
	}
	return context;
};
