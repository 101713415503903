import { postTreatNumV1000EncodeService } from "service/RsService/TreatNumV1000EncodeService";
import { SummaryNoICCardUpdateData } from "../../../SummaryNoICCardUpdateData";
import { postIcDataService } from "service/icDataServices";

import Swal from "sweetalert2";
import { TreatmentInfoProps } from "store/TreatmentManagement/useTreatmentInfoStore";
import { CCPEProps } from "store/TreatmentManagement";
import { DxProps } from "store/TreatmentManagement";
import { PrescriptionProps } from "store/TreatmentManagement";
import { cashDataStoreProps } from "store/TreatmentManagement/Payment";
import { accItemsLevelProps } from "service/accItemsLevelService";
import { FeeState } from "store/TreatmentManagement/Payment";
import { drugTypeListProps } from "service/drugTypeListService";
import { InstitutionInfoProps } from "store/DataSetting/useInstitutionInfoStore";
import { DrugFreqListsProps } from "store/DataSetting/useDrugFreqListStore";
import { UserInfoProps } from "store/useUserStore";
import moment from "moment";
import { ADDateToROCStringyyyMMddHHmmssNoDot } from "functions/ADDateToROCStringyyyMMddHHmmssNoDot";
// 補掛日期異常卡號 流程
export const handleHistoricalDataUploadComplete = async (
	treatmentInfo: TreatmentInfoProps,
	CCPEData: CCPEProps,
	dxList: DxProps[],
	prescriptionList: PrescriptionProps[],
	cashDataStore: cashDataStoreProps,
	accItemsLevelList: accItemsLevelProps[],
	feeData: FeeState,
	if_formal_data: string,
	caseUid: string,
	drugTypeLists: drugTypeListProps[],
	institutionInfoData: InstitutionInfoProps,
	drugFreqLists: DrugFreqListsProps[],
	userInfo: UserInfoProps
) => {
	const treated_at = treatmentInfo.opd_at ? ADDateToROCStringyyyMMddHHmmssNoDot(treatmentInfo.opd_at) : "";
	console.log("treated_at", treated_at);
	const treatNumV1000EncodeResResult = await postTreatNumV1000EncodeService({
		sHospid: institutionInfoData.nhid,
		sPatientId: treatmentInfo.id_no,
		sTreatDT: treated_at
	});
	if (treatNumV1000EncodeResResult?.result !== "success") {
		Swal.fire({
			icon: "error",
			title: "取得就醫識別碼失敗",
			text: "請檢查健保卡或讀卡機狀態，並重試。",
			showConfirmButton: true
		});
		return null;
	}
	const treatNumV1000EncodeResData = treatNumV1000EncodeResResult.data;
	let ICCardUpdateData = SummaryNoICCardUpdateData(
		treatmentInfo,
		CCPEData,
		dxList,
		prescriptionList,
		cashDataStore,
		accItemsLevelList,
		feeData,
		if_formal_data,
		caseUid,
		drugTypeLists,
		institutionInfoData,
		drugFreqLists,
		undefined,
		undefined,
		undefined,
		treatNumV1000EncodeResData
	);

	const ICDataResult = await postIcDataService(userInfo.token, ICCardUpdateData);

	if (ICDataResult.result !== "success") {
		Swal.fire({
			icon: "error",
			title: "寫IC_DATA資料庫發生問題",
			showConfirmButton: true
		});
		return null;
	}

	return ICCardUpdateData;
};
