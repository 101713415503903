import React from "react";
import { Box, Flex, Tag } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIsVisitedWaitingListFeeDetailOpenStatus } from "contexts/IsVisitedWaitingListFeeDetailOpenContext";
import { ADStringToROCString } from "functions/ADStringToROCString";
import { useFilterRegistrationOpdLists } from "./useFilterRegistrationOpdLists";
import { useVisitedListsQuery } from "./useVisitedListQuery";
import { useCurrentVisitInfoStore, usePatientInfoStore, useSearchPatientStore, useVitalSignsDataStore } from "store/Registration";
import { usePrescriptionStore } from "store/TreatmentManagement";
import { useMedicalRecordStatus } from "contexts/TreatmentManagement/MedicalRecordStatusContext";

import { useHistoryStore } from "store/TreatmentManagement";
import { useTreatmentInfoStore, useCCPEStore, useDxStore, usePricingStore } from "store/TreatmentManagement";

export interface RegistrationListProps {
	birth_date: string;
	card_no: string;
	clinic_room_name: string;
	doctor_name: string;
	id: string;
	medical_record_number: string;
	nhi_type: string;
	nhi_type_name: string;
	note: string | null;
	opd_at: string;
	patient_name: string;
	reg_number: number;
	shift_name: string;
	status: string;
	treated_at: string;
	vital_sign?: any;
	fee?: {
		1: string;
		CO: string;
		HD: string;
		LPAY: string;
		SE: string;
	};
}

export const VisitedListBody = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const pathName = location.pathname;
	const pathSegments = pathName.split("/");
	const lastSegment = pathSegments[pathSegments.length - 1];
	const { isVisitedWaitingListFeeDetailOpenStatus } = useIsVisitedWaitingListFeeDetailOpenStatus();
	const { data: registrationData } = useVisitedListsQuery();
	const { updatePrescription, resetPrescriptionList } = usePrescriptionStore();
	const { updateCCPEData, resetCCPEData } = useCCPEStore();
	const { updateDxList, resetDx } = useDxStore();
	const { resetHistoryData } = useHistoryStore();
	const { resetPricing } = usePricingStore();
	const { resetSearchPatientData } = useSearchPatientStore();
	const { setMedicalRecordStatus } = useMedicalRecordStatus();

	const { updateTreatmentInfo, resetTreatmentInfo } = useTreatmentInfoStore();
	const { currentVisitInfoData, updateCurrentVisitInfoData, resetCurrentVisitInfoData } = useCurrentVisitInfoStore();
	const { resetVitalSignsData } = useVitalSignsDataStore();
	const { patientInfo, updatePatientInfo, resetPatientInfo } = usePatientInfoStore();

	const handleGoToConsultingRoom = (id: string) => {
		console.log("Go to consulting room", id);
		resetTreatmentInfo();
		resetHistoryData();
		resetPrescriptionList();
		resetDx();
		resetCCPEData();
		resetPricing();
		navigate("/OPDPage/ConsultingRoomPage");
		setMedicalRecordStatus("1");
		updateTreatmentInfo({
			id: id
		});
	};

	const filteredData = useFilterRegistrationOpdLists(registrationData?.lists || {});

	const bodyElementStyle = {
		textColor: "rsPrimary.600",
		p: "13px 9px",
		borderBottom: "1px solid #868C6E",
		_groupHover: { bg: "#DDDBBA33", cursor: "pointer" },
		whiteSpace: "nowrap"
	};

	return (
		<>
			{filteredData &&
				Object.entries(filteredData).map(([key, item], index) => (
					<Box key={index}>
						<Flex
							align={"center"}
							onClick={lastSegment === "RegisteredPage" ? undefined : () => handleGoToConsultingRoom(item.data_id)}
							role='group'>
							<Box sx={bodyElementStyle} flex={"0 0 5%"}>
								{item.reg_number || "-"}
							</Box>
							<Box sx={bodyElementStyle} flex={"0 0 5%"}>
								{item.shift_name || "-"}
							</Box>
							<Box sx={bodyElementStyle} flex={"0 0 5%"}>
								{item.clinic_room_name || "-"}
							</Box>
							<Box sx={bodyElementStyle} flex={"0 0 10%"}>
								{item.doctor_name || "-"}
							</Box>
							<Box sx={bodyElementStyle} flex={"0 0 10%"}>
								{item.patient_name || "-"}
							</Box>
							<Box sx={bodyElementStyle} flex={"0 0 5%"}>
								{item.nhi_type_name || "-"}
							</Box>
							<Box sx={bodyElementStyle} flex={"0 0 10%"}>
								{ADStringToROCString(item.birth_date) || "-"}
							</Box>
							<Box sx={bodyElementStyle} flex={"0 0 10%"}>
								{item.card_no || "-"}
							</Box>
							<Box sx={bodyElementStyle} flex={"0 0 15%"}>
								{item.medical_record_number || "-"}
							</Box>
							<Box sx={bodyElementStyle} flex={"0 0 15%"}>
								{item.treated_at ? item.treated_at.slice(11, 16) : "-"}
							</Box>
							<Box
								sx={{
									...bodyElementStyle,
									textAlign: "center"
								}}
								flex={"1 0 6%"}
								hidden={lastSegment !== "RegisteredPage"}>
								-
							</Box>

							{lastSegment === "RegisteredPage" ? (
								<>
									{isVisitedWaitingListFeeDetailOpenStatus && item.fee && (
										<>
											<Box
												sx={{
													...bodyElementStyle,
													textAlign: "center"
												}}
												flex={"1 0 6%"}>
												{item.fee[1] || "0"}
											</Box>
											<Box
												sx={{
													...bodyElementStyle,
													textAlign: "center"
												}}
												flex={"1 0 6%"}>
												{item.fee.CO || "0"}
											</Box>
											<Box
												sx={{
													...bodyElementStyle,
													textAlign: "center"
												}}
												flex={"1 0 6%"}>
												{item.fee.HD || "0"}
											</Box>
											<Box
												sx={{
													...bodyElementStyle,
													textAlign: "center"
												}}
												flex={"1 0 6%"}>
												{item.fee.LPAY || "0"}
											</Box>
											<Box
												sx={{
													...bodyElementStyle,
													textAlign: "center"
												}}
												flex={"1 0 6%"}>
												{item.fee.SE || "0"}
											</Box>
										</>
									)}
								</>
							) : (
								<Box sx={bodyElementStyle} flex={"0 0 10%"}>
									{item.status}
								</Box>
							)}
						</Flex>
					</Box>
				))}
		</>
	);
};
