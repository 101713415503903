import React, { createContext, useContext, useState, ReactNode } from 'react';

interface IsChooseAbnormalButtonStatusContextValue {
    isChooseAbnormalButtonStatus: boolean;
    setIsChooseAbnormalButtonStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsChooseAbnormalButtonStatusContext = createContext<
    IsChooseAbnormalButtonStatusContextValue | undefined
>(undefined);

interface IsChooseAbnormalButtonStatusProviderProps {
    children: ReactNode;
}

export const IsChooseAbnormalButtonStatusProvider: React.FC<IsChooseAbnormalButtonStatusProviderProps> = ({
    children,
}) => {
    const [isChooseAbnormalButtonStatus, setIsChooseAbnormalButtonStatus] = useState(false);
    return (
        <IsChooseAbnormalButtonStatusContext.Provider
            value={{
                isChooseAbnormalButtonStatus,
                setIsChooseAbnormalButtonStatus,
            }}
        >
            {children}
        </IsChooseAbnormalButtonStatusContext.Provider>
    );
};

export const useIsChooseAbnormalButtonStatus= (): IsChooseAbnormalButtonStatusContextValue => {
    const context = useContext(IsChooseAbnormalButtonStatusContext);

    if (!context) {
        throw new Error(
            'useIsChooseAbnormalButtonStatusStatus must be used within a isChooseAbnormalButtonStatusStatusProvider'
        );
    }

    return context;
};
