import React from "react";
interface CombinedTreatmentManagementProvidersProps {
	children: React.ReactNode;
}
// 醫療記錄相關
import { IsHistoryLoadingProvider } from "contexts/TreatmentManagement/MedicalRecord/IsHistoryLoadingContext";
import { IsPartCopyProvider } from "contexts/TreatmentManagement/MedicalRecord";
import { IsCopyDrugFromHistoryProvider } from "contexts/TreatmentManagement/IsCopyDrugFromHistoryContext";
import { IsCopyDrugFromHistoryForChronicProvider } from "contexts/TreatmentManagement/IsCopyDrugFromHistoryForChronicContext";

// 處方相關
import {
	IsFormMaximizeProvider,
	RightSideOptionSelectProvider,
	PrescriptionInputRefsProvider,
	PrescriptionProvider,
	IsSearchListsProvider
} from "contexts/TreatmentManagement/Prescription";

// 計費相關
import { ComputeFeeProvider } from "contexts/TreatmentManagement/ComputeFeeContext";
import { CalculationCompleteProvider } from "contexts/TreatmentManagement/CalculationCompleteContext";

// 結帳流程相關
import { IsBillingOpenProvider, IsReferralOpenProvider } from "contexts/TreatmentManagement/BillingProcess";
import { ShowAbnormalButtonStatusProvider } from "contexts/TreatmentManagement/BillingProcess/ShowAbnormalButtonStatusContext";
import { IsChooseAbnormalButtonStatusProvider } from "contexts/TreatmentManagement/BillingProcess/IsChooseAbnormalButtonStatusContext";

// 其他
import { DraiaiLoadingStatusProvider } from "contexts/TreatmentManagement/DraiaiLoadingStatusContext";

export const CombinedTreatmentManagementProviders: React.FC<CombinedTreatmentManagementProvidersProps> = ({ children }) => {
	return (
		<>
			<PrescriptionInputRefsProvider>
				<RightSideOptionSelectProvider>
					<IsFormMaximizeProvider>
						<IsPartCopyProvider>
							<PrescriptionProvider>
								<IsSearchListsProvider>
									<IsHistoryLoadingProvider>
										<IsBillingOpenProvider>
											<IsReferralOpenProvider>
												<ComputeFeeProvider>
													<IsCopyDrugFromHistoryProvider>
														<CalculationCompleteProvider>
															<ShowAbnormalButtonStatusProvider>
																<IsChooseAbnormalButtonStatusProvider>
																	<DraiaiLoadingStatusProvider>
																		<IsCopyDrugFromHistoryForChronicProvider>
																			{children}
																		</IsCopyDrugFromHistoryForChronicProvider>
																	</DraiaiLoadingStatusProvider>
																</IsChooseAbnormalButtonStatusProvider>
															</ShowAbnormalButtonStatusProvider>
														</CalculationCompleteProvider>
													</IsCopyDrugFromHistoryProvider>
												</ComputeFeeProvider>
											</IsReferralOpenProvider>
										</IsBillingOpenProvider>
									</IsHistoryLoadingProvider>
								</IsSearchListsProvider>
							</PrescriptionProvider>
						</IsPartCopyProvider>
					</IsFormMaximizeProvider>
				</RightSideOptionSelectProvider>
			</PrescriptionInputRefsProvider>
		</>
	);
};
