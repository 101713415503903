import React, { useEffect, useState } from "react";

import { Box, Flex, Text, Input, InputGroup, InputLeftElement, InputRightElement, Button, Select, Tooltip } from "@chakra-ui/react";

import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";

// Store
import { useUserStore } from "store";
import { useTakingTimeListsStore } from "store/DataSetting/useTakingTimeListStore";
import { useDrugFreqListsStore } from "store/DataSetting/useDrugFreqListStore";

// Context
import { useIsAddDrugFreqOpenStatus } from "contexts/DataSetting/DrugFrequency";

// API
import { callDrugFreqListsService } from "service/drugFreqListsService";
import { callDrugFreqSpecService, postDrugFreqSpecService, deleteDrugFreqSpecService } from "service/drugFreqSpecService";

import Swal from "sweetalert2";

interface DrugFreqListsProps {
	data_id: string;
	code: string;
	freq_code: string;
	ori_freq_code: string;
	directions: string;
	days: number;
	times: number;
	type: number | null;
	taking_time: string;
}

interface DrugSpecProps {
	data_id?: string;
	code: string;
	freq_code: string;
	ori_freq_code: string;
	directions: string;
	days?: number;
	times?: number;
	type: number;
	taking_time?: string;
	transfer?: number;
	x: number;
}

export const InUseList = () => {
	const { userInfo } = useUserStore();
	const { takingTimeListsData, updateTakingTimeListsData } = useTakingTimeListsStore();

	const { drugFreqListsData, updateDrugFreqListsData } = useDrugFreqListsStore();

	const { setIsAddDrugFreqOpenStatus } = useIsAddDrugFreqOpenStatus();
	const handleOpenAddDrugFreq = () => {
		setIsAddDrugFreqOpenStatus(true);
	};

	const callDrugFreqListsAPI = async () => {
		try {
			const result = await callDrugFreqListsService(userInfo.token);
			if (result.result === "success") {
				updateDrugFreqListsData(result.lists);
			}
		} catch (error) {
			console.error("呼叫藥品頻率列表服務時發生錯誤:", error);
			Swal.fire({
				icon: "error",
				title: "發生錯誤",
				text: "無法獲取藥品頻率列表，請稍後再試",
				confirmButtonText: "確定"
			});
		}
	};

	useEffect(() => {
		callDrugFreqListsAPI();
	}, []);

	useEffect(() => {
		const takingTimeList = drugFreqListsData.filter(item => item.type === 4);
		console.log(takingTimeList, "takingTimeList");
		updateTakingTimeListsData(takingTimeList);
	}, [drugFreqListsData]);

	const [editIndex, setEditIndex] = useState(-1);

	const initialDrugSpecData = {
		data_id: "",
		code: "",
		freq_code: "",
		ori_freq_code: "",
		directions: "",
		days: 0,
		times: 0,
		type: 0,
		taking_time: "",
		transfer: 0,
		x: 0
	};

	const [drugSpecData, setDrugSpecData] = useState<DrugSpecProps>(initialDrugSpecData);

	const [IsACorPC, setIsACorPC] = useState(false);
	const containsACorPC = (str: string): boolean => {
		return /AC|PC/.test(str);
	};

	const handleEditDrugSpec = (index: number, data_id: string) => {
		setEditIndex(index);
		callDrugFreqSpecAPI(data_id);
	};

	const callDrugFreqSpecAPI = async (data_id: string) => {
		setDrugSpecData(initialDrugSpecData);
		const data = { id: data_id };
		try {
			const result = await callDrugFreqSpecService(userInfo.token, data);
			if (result.result === "success") {
				setDrugSpecData(result.info);
				// 這邊要做 check 存起來
				console.log("drugSpec result.info:", result.info);

				if (containsACorPC(result.info.code)) {
					setIsACorPC(true);
				} else {
					setIsACorPC(false);
				}
			} else {
				console.log("callDrugFreqSpecAPI 出現錯誤:", result.msg);
			}
		} catch (error) {
			console.error("調用 callDrugFreqSpecService 時發生錯誤:", error);
			// 可以在這裡添加錯誤處理邏輯，例如顯示錯誤消息給用戶
		}
	};

	useEffect(() => {
		console.log(takingTimeListsData, "takingTimeListsData");
	}, [takingTimeListsData]);

	const handleChangeDrugSpec = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		setDrugSpecData(prevData => ({
			...prevData,
			[name]: value
		}));
	};

	const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const { value: newTakingTime } = e.target;

		setDrugSpecData(prevData => {
			let { freq_code } = prevData;

			// 找出 AC 或 PC 的 index 位置
			const acIndex = freq_code.lastIndexOf("AC");
			const pcIndex = freq_code.lastIndexOf("PC");

			// 如果 index 不為 -1 代表字串中有 AC PC
			if (acIndex !== -1) {
				freq_code = freq_code.substring(0, acIndex); // 保留 index 0 ~ acIndex 的值，等於將 AC 之後的移除
			} else if (pcIndex !== -1) {
				freq_code = freq_code.substring(0, pcIndex);
			}

			// 添加新的 taking_time 到 freq_code
			freq_code = `${freq_code}${newTakingTime}`;

			return {
				...prevData,
				taking_time: newTakingTime,
				freq_code: freq_code
			};
		});
	};

	const handleSaveDrugSpec = () => {
		const postData = {
			data_id: drugSpecData.data_id,
			code: drugSpecData.code,
			type: drugSpecData.type,
			freq_code: drugSpecData.freq_code,
			ori_freq_code: drugSpecData.ori_freq_code,
			days: drugSpecData.days,
			times: drugSpecData.times,
			directions: drugSpecData.directions,
			taking_time: drugSpecData.taking_time,
			x: drugSpecData.x
		};
		postDrugFreqSpecAPI(postData);
	};

	const postDrugFreqSpecAPI = async (data: DrugSpecProps) => {
		try {
			const result = await postDrugFreqSpecService(userInfo.token, data);

			if (result.result === "success") {
				setEditIndex(-1);
				callDrugFreqListsAPI();
				Swal.fire({
					icon: "success",
					title: `${drugSpecData.code} 已更新`,
					timer: 1500,
					showConfirmButton: false
				});
			} else {
				console.error("API Error:", result);

				Swal.fire({
					icon: "error",
					title: `${result.msg}`,
					timer: 1500,
					showConfirmButton: false
				});
			}
		} catch (error) {
			console.error("Network or server error:", error);
			Swal.fire({
				icon: "error",
				title: `Network or server error`,
				timer: 1500,
				showConfirmButton: false
			});
		}
	};

	const handleDeleteDrugSpec = (data_id: string, code: string) => {
		if (data_id) {
			Swal.fire({
				icon: "question",
				title: `確定要刪除 ${code} 嗎？`,
				confirmButtonText: "是",
				cancelButtonText: "否",
				showCancelButton: true
			}).then(result => {
				if (result.isConfirmed) {
					deleteDrugFreqSpecAPI(data_id);
				} else {
					return;
				}
			});
		}
	};

	const deleteDrugFreqSpecAPI = async (data_id: string) => {
		const data = { id: data_id };

		try {
			const result = await deleteDrugFreqSpecService(userInfo.token, data);
			if (result.result === "success") {
				Swal.fire({
					icon: "info",
					title: "刪除成功",
					timer: 1500
				});
				callDrugFreqListsAPI();
			} else {
				throw new Error("刪除失敗");
			}
		} catch (error) {
			console.error("刪除藥品頻率規格時發生錯誤:", error);
			Swal.fire({
				icon: "error",
				title: "刪除失敗",
				text: "請稍後再試",
				timer: 1500
			});
		}
	};

	const handleCancelDrugSpec = () => {
		setEditIndex(-1);
		setDrugSpecData(initialDrugSpecData);
		console.log("取消並清空");
	};

	return (
		<>
			<Flex gap={"8px"} mb={"8px"} justify={"space-between"}>
				<Flex gap={"8px"} hidden>
					<Text py={"8px"} flex={"0 0 auto"} textStyle={"semiTitle"}>
						搜尋用法
					</Text>
					<InputGroup maxW={"300px"}>
						<InputLeftElement opacity={0.5}>
							<HeroIcons name='search'></HeroIcons>
						</InputLeftElement>
						<Input bg={"#fff"} placeholder='代碼 / 用法 / 健保碼' onChange={() => {}}></Input>
						<InputRightElement>
							<HeroIcons name='xmark-sm'></HeroIcons>
						</InputRightElement>
					</InputGroup>
				</Flex>

				<Button colorScheme='rsSecondary' onClick={handleOpenAddDrugFreq}>
					新增用法
				</Button>
			</Flex>
			<Box maxH={`calc(${window.innerHeight}px - 80px - 56px - 48px - 16px)`} overflow={"auto"} boxShadow={"0 1px 1px #00000033"}>
				<Flex bg={"#D9D9D9"} pos={"sticky"} top={0} left={0} zIndex={10}>
					<Box p={"4px 16px"} flex={"0 0 140px"}>
						<Text fontWeight={"semibold"} textColor={"#555B3E"}>
							代碼
						</Text>
					</Box>
					<Box p={"4px 16px"} flex={"1 0 0"}>
						<Text fontWeight={"semibold"} textColor={"#555B3E"}>
							用法
						</Text>
					</Box>
					<Box p={"4px 16px"} flex={"0 0 160px"}>
						<Text fontWeight={"semibold"} textColor={"#555B3E"}>
							服用時間
						</Text>
					</Box>
					<Box p={"4px 16px"} flex={"0 0 80px"}>
						<Text fontWeight={"semibold"} textColor={"#555B3E"}>
							天數
						</Text>
					</Box>
					<Box p={"4px 16px"} flex={"0 0 80px"}>
						<Text fontWeight={"semibold"} textColor={"#555B3E"}>
							次數
						</Text>
					</Box>
					<Box p={"4px 16px"} flex={"0 0 140px"}>
						<Text fontWeight={"semibold"} textColor={"#555B3E"}>
							健保碼
						</Text>
					</Box>

					<Box p={"4px 16px"} flex={"0 0 140px"}>
						<Text fontWeight={"semibold"} textColor={"#555B3E"} textAlign={"center"}>
							編輯
						</Text>
					</Box>
				</Flex>
				<Box>
					{drugFreqListsData &&
						drugFreqListsData.map((item: DrugFreqListsProps, index: number) => (
							<React.Fragment key={index}>
								<Flex
									bg={editIndex === index ? "#D2D2B3" : "#fff"}
									borderBottom={"1px solid #646B48"}
									align={"center"}
									_hover={{ bg: "#D2D2B3" }}>
									{editIndex === index ? (
										<>
											{drugSpecData && (
												<>
													<Box px='4px' flex={"0 0 140px"}>
														<Input
															size={"sm"}
															bg={"#fff"}
															textColor={"#000"}
															value={drugSpecData.code}
															name='code'
															onChange={handleChangeDrugSpec}></Input>
													</Box>
													<Box px='4px' flex={"1 0 0"}>
														<Input
															size={"sm"}
															bg={"#fff"}
															textColor={"#000"}
															value={drugSpecData.directions}
															name='directions'
															onChange={handleChangeDrugSpec}></Input>
													</Box>
													<Box px='4px' flex={"0 0 160px"}>
														<Select
															disabled={IsACorPC}
															placeholder='請選擇'
															bg={"#fff"}
															size={"sm"}
															onChange={handleSelectChange}>
															{takingTimeListsData &&
																takingTimeListsData.map(item => (
																	<option key={item.data_id} value={item.freq_code}>
																		{item.freq_code}
																	</option>
																))}
														</Select>
													</Box>
													<Box px='4px' flex={"0 0 80px"}>
														<Input
															disabled={IsACorPC}
															size={"sm"}
															bg={"#fff"}
															textColor={"#000"}
															value={drugSpecData.days || ""}
															name='days'
															onChange={handleChangeDrugSpec}></Input>
													</Box>
													<Box px='4px' flex={"0 0 80px"}>
														<Input
															disabled={IsACorPC}
															size={"sm"}
															bg={"#fff"}
															textColor={"#000"}
															value={drugSpecData.times || ""}
															name='times'
															onChange={handleChangeDrugSpec}></Input>
													</Box>
													<Box px='4px' flex={"0 0 140px"}>
														<Text p={"8px 16px"} textColor={"#000"}>
															{drugSpecData.freq_code}
														</Text>
													</Box>
													<Box px='4px' flex={"0 0 140px"}>
														<Flex gap={"8px"} p={"8px 16px"} align={"center"} justify={"center"}>
															<Tooltip label={"確定"} hasArrow>
																<Box
																	w={"24px"}
																	opacity={0.8}
																	_hover={{
																		opacity: 1,
																		cursor: "pointer"
																	}}
																	bg={"blue.50"}
																	rounded={"sm"}
																	onClick={() => {
																		handleSaveDrugSpec();
																	}}>
																	<HeroIcons name='check'></HeroIcons>
																</Box>
															</Tooltip>
															<Tooltip label={"取消"} hasArrow>
																<Box
																	w={"24px"}
																	opacity={0.8}
																	_hover={{
																		opacity: 1,
																		cursor: "pointer"
																	}}
																	bg={"gray.50"}
																	rounded={"sm"}
																	onClick={() => {
																		handleCancelDrugSpec();
																	}}>
																	<HeroIcons name='xmark-sm'></HeroIcons>
																</Box>
															</Tooltip>
														</Flex>
													</Box>
												</>
											)}
										</>
									) : (
										<>
											{" "}
											<Box flex={"0 0 140px"}>
												<Text p={"8px 16px"} textColor={"#000"}>
													{item.code}
												</Text>
											</Box>
											<Box flex={"1 0 0"}>
												<Text p={"8px 16px"} textColor={"#000"}>
													{item.directions}
												</Text>
											</Box>
											<Box flex={"0 0 160px"}>
												<Text p={"8px 16px"} textColor={"#000"}>
													{item.taking_time}
												</Text>
											</Box>
											<Box flex={"0 0 80px"}>
												<Text p={"8px 16px"} textColor={"#000"}>
													{item.days}
												</Text>
											</Box>
											<Box flex={"0 0 80px"}>
												<Text p={"8px 16px"} textColor={"#000"}>
													{item.times}
												</Text>
											</Box>
											<Box flex={"0 0 140px"}>
												<Text p={"8px 16px"} textColor={"#000"}>
													{item.freq_code}
												</Text>
											</Box>
											<Box flex={"0 0 140px"}>
												<Flex gap={"8px"} p={"8px 16px"} align={"center"} justify={"center"}>
													<Tooltip label={"編輯"} hasArrow>
														<Box
															w={"24px"}
															opacity={0.8}
															_hover={{
																opacity: 1,
																cursor: "pointer"
															}}
															onClick={() => {
																handleEditDrugSpec(index, item.data_id);
															}}
															textColor={"rsPrimary.800"}>
															<HeroIcons name='edit-fill'></HeroIcons>
														</Box>
													</Tooltip>

													<Tooltip label={"刪除"} hasArrow>
														<Box
															w={"24px"}
															opacity={0.8}
															_hover={{
																opacity: 1,
																cursor: "pointer"
															}}
															textColor={"rsDanger.500"}
															onClick={() => {
																handleDeleteDrugSpec(item.data_id, item.code);
															}}>
															<HeroIcons name='delete-fill'></HeroIcons>
														</Box>
													</Tooltip>
												</Flex>
											</Box>
										</>
									)}
								</Flex>
							</React.Fragment>
						))}
				</Box>
			</Box>
		</>
	);
};
