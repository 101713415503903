import apiService from "./apiService";

type callDrugSpecProps = {
	id: string;
};

interface postDrugSpecProps {
	id?: string;
	code: string;
	type: number;
	ori_freq_code: string;
	freq_code: string;
	days?: number | null;
	times?: number | null;
	directions: string;
}

// 71. 取得特定的院內藥品使用頻率詳細資料
export const callDrugFreqSpecService = async (token: string, data: callDrugSpecProps) => {
	const queryParams = data ? { data_id: data.id } : undefined;
	return apiService<string, any>({
		method: "GET",
		path: "/api/drug/freq/spec",
		token,
		data: queryParams
	});
};

// 72. 刪除特定的藥品使用頻率設定
export const deleteDrugFreqSpecService = async (token: string, data: callDrugSpecProps) => {
	const queryParams = data ? { id: data.id } : undefined;
	return apiService<string, any>({
		method: "DELETE",
		path: `/api/drug/freq/spec?id=${data}`,
		token,
		data: queryParams
	});
};

// 73. 儲存藥品使用頻率設定

export const postDrugFreqSpecService = async (token: string, data: postDrugSpecProps) => {
	const queryParams = data ? data : undefined;
	return apiService<string, any>({
		method: "POST",
		path: "/api/drug/freq/spec",
		token,
		data: queryParams
	});
};
