export type VitalSignRulesType = "normal" | "warning" | "abnormal";

export const VitalSignRules = (value: string, type: string): VitalSignRulesType => {
	const numValue = parseFloat(value);
	if (isNaN(numValue)) return "normal";
	switch (type) {
		case "體溫":
			if (numValue > 38) return "abnormal";
			if (numValue >= 37.5 && numValue <= 38) return "warning";
			return "normal";
		case "收縮壓":
			if (numValue < 90 || numValue > 160) return "abnormal";
			if (numValue > 140) return "warning";
			return "normal";
		case "舒張壓":
			if (numValue < 60 || numValue > 100) return "abnormal";
			if (numValue > 90) return "warning";
			return "normal";
		case "飯前AC":
			if (numValue < 70 || numValue > 250) return "abnormal";
			if (numValue > 180) return "warning";
			return "normal";
		case "飯後PC":
			if (numValue > 200) return "abnormal";
			if (numValue >= 180 && numValue <= 200) return "warning";
			return "normal";
		default:
			return "normal";
	}
};

export const getBackgroundColor = (result: VitalSignRulesType): string => {
	switch (result) {
		case "abnormal":
			return "red.200";
		case "warning":
			return "yellow.200";
		default:
			return "white";
	}
};
