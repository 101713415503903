import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useDeclareListSelectedItemStore } from 'store/DeclarationAudit/useDeclareListSelectedItemStore';
import { useDeclareListHistoryDataStore } from 'store/DeclarationAudit/useDeclareListHistoryDataStore';

export const BasicInfo = () => {
    const { selectedItem } = useDeclareListSelectedItemStore();
    const { historyData } = useDeclareListHistoryDataStore();
    const formatBirthDate = (birthDate: string) => {
        const year = parseInt(birthDate.slice(0, 4)) - 1911;
        const month = birthDate.slice(5, 7);
        const day = birthDate.slice(8, 10);
        return `${year}年${month}月${day}日`;
    };
    return (
        <Flex
            justify={'space-between'}
            align={'center'}
            gap={'12px'}
            mt={'8px'}
        >
            <Flex
                p={'4px 12px'}
                bg={'#BFC6A2'}
                rounded={'md'}
                fontWeight={'semibold'}
                fontSize={'md'}
                gap={5}
                h={'35px'}
                minW={'300px'}
            >
                <Text>{selectedItem?.card_no || '卡號'}</Text>
                <Text>{selectedItem?.name || '姓名'}</Text>
                <Text>
                    {selectedItem?.birth_date
                        ? formatBirthDate(selectedItem?.birth_date)
                        : '生日'}
                </Text>
                <Text>{selectedItem?.id_no || '身分證號'}</Text>
            </Flex>

            <Flex gap={'8px'}>
                <Flex gap={'4px'} minW={'64px'}>
                    <Text fontWeight={'semibold'}>案件</Text>
                    <Text>{selectedItem?.case_type || '-'}</Text>
                </Flex>
                <Flex gap={'4px'} minW={'84px'}>
                    <Text fontWeight={'semibold'}>醫師</Text>
                    <Text>{historyData.info?.doctor_name || '-'}</Text>
                </Flex>
                <Flex gap={'4px'} minW={'84px'}>
                    <Text fontWeight={'semibold'}>藥師</Text>
                    <Text>{historyData.info?.pharmacist_name || '-'}</Text>
                </Flex>
            </Flex>
        </Flex>
    );
};
