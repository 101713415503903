import apiService from "./apiService";

export type RefillableSpecReqProps = {
	patient_uid: string; // 病患資料 id
	refillable_uid: string; // 慢性病的資料 id
	medical_date: string; // 就醫日期 (西元年格式，YYYY-mm-dd)
	opd_uid?: string; // 病患資料 id
};

export type RefillableSpecResProps = {
	result: string;

	detail_info?: {
		refillable_uid: string; // 慢性病資料 id，對應 lists[0].data_id
		refillable_detail_uid: string | null;
		card_no: string | null;
		first_date: string;
		refill_times: number;
		get_times: number;
		lists: {
			data_id?: string; // 連許處方箋資料 id，當資料有領藥日期或是已過服藥結束日期時，不會回應資料 id
			seq: number; // 領藥順序
			prescription_date?: string; // 領藥日期
			start_date: string; // 服藥的開始日期
			end_date: string; // 服藥的結束日期
		}[];
	};
};

export const callRefillableSpecService = async (token: string, data: RefillableSpecReqProps): Promise<RefillableSpecResProps> => {
	return apiService<undefined, RefillableSpecResProps>({
		method: "GET",
		path: "/api/refillable/spec",
		token,
		data: data
	});
};
