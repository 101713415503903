import { Flex, Box, Text, Checkbox, Center } from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";

type PrescriptionsInfoProps = {
	sended?: boolean;
	sending?: boolean;
	code?: string;
	name?: string;
	drug_no?: string;
	parts?: string;
	freq?: string;
	days?: string;
	total?: string;
	note?: string;
	submit?: boolean;
	long?: boolean;
	significant?: boolean;
	sign?: boolean;
};

export const PrescriptionsInfo = () => {
	const prescriptions: PrescriptionsInfoProps[] = [];
	// const prescriptions = [
	//     {
	//         sended: true,
	//         sending: false,
	//         code: 'C145',
	//         name: 'Cypromin Solution',
	//         drug_no: 'A043588118',
	//         parts: '',
	//         freq: 'QID',
	//         days: 3,
	//         total: 36,
	//         note: '01',
	//         submit: false,
	//         long: true,
	//         significant: false,
	//         sign: true,
	//     },
	//     {
	//         sended: false,
	//         sending: true,
	//         code: 'SOD',
	//         name: 'Sodicon(mdeicon)TAB',
	//         drug_no: 'A023490100',
	//         parts: '',
	//         freq: 'QID',
	//         days: 3,
	//         total: 3,
	//         note: '01',
	//         submit: true,
	//         long: false,
	//         significant: true,
	//         sign: false,
	//     },
	//     {
	//         sended: true,
	//         sending: false,
	//         code: 'Inf',
	//         name: 'Inf ACT 80mg',
	//         drug_no: '08089B',
	//         parts: '',
	//         freq: 'QID',
	//         days: 3,
	//         total: 12,
	//         note: '01',
	//         submit: false,
	//         long: false,
	//         significant: false,
	//         sign: true,
	//     },
	//     {
	//         sended: false,
	//         sending: true,
	//         code: 'Orn',
	//         name: 'Ornadin',
	//         drug_no: 'A024857118',
	//         parts: '',
	//         freq: 'QID',
	//         days: 3,
	//         total: 30,
	//         note: '01',
	//         submit: true,
	//         long: false,
	//         significant: true,
	//         sign: false,
	//     },
	//     {
	//         sended: true,
	//         sending: false,
	//         code: 'Del',
	//         name: 'Delamin',
	//         drug_no: 'A026559100',
	//         parts: '',
	//         freq: 'QID',
	//         days: 3,
	//         total: 2.01,
	//         note: '01',
	//         submit: false,
	//         long: true,
	//         significant: false,
	//         sign: true,
	//     },
	//     {
	//         sended: false,
	//         sending: true,
	//         code: 'Amb',
	//         name: 'Ambroxol Tablets 30m',
	//         drug_no: 'A028453100',
	//         parts: '',
	//         freq: 'QID',
	//         days: 3,
	//         total: 3,
	//         note: '01',
	//         submit: true,
	//         long: false,
	//         significant: true,
	//         sign: false,
	//     },
	//     {
	//         sended: true,
	//         sending: false,
	//         code: 'Vor',
	//         name: 'Voren 25mg',
	//         drug_no: 'A031188329',
	//         parts: '',
	//         freq: 'QID',
	//         days: 3,
	//         total: 1.5,
	//         note: '01',
	//         submit: false,
	//         long: true,
	//         significant: false,
	//         sign: true,
	//     },

	//     {
	//         sended: true,
	//         sending: false,
	//         code: 'AMP',
	//         name: 'Ampicillin 500mg',
	//         drug_no: 'A045678123',
	//         parts: '',
	//         freq: 'BID',
	//         days: 5,
	//         total: 10,
	//         note: '01',
	//         submit: true,
	//         long: false,
	//         significant: true,
	//         sign: false,
	//     },
	//     {
	//         sended: false,
	//         sending: true,
	//         code: 'CEF',
	//         name: 'Cefuroxime 250mg',
	//         drug_no: 'A056789234',
	//         parts: '',
	//         freq: 'TID',
	//         days: 7,
	//         total: 21,
	//         note: '01',
	//         submit: false,
	//         long: true,
	//         significant: false,
	//         sign: true,
	//     },
	//     {
	//         sended: true,
	//         sending: false,
	//         code: 'DIC',
	//         name: 'Diclofenac 50mg',
	//         drug_no: 'A067890345',
	//         parts: '',
	//         freq: 'QID',
	//         days: 3,
	//         total: 12,
	//         note: '01',
	//         submit: true,
	//         long: false,
	//         significant: true,
	//         sign: false,
	//     },
	//     {
	//         sended: false,
	//         sending: true,
	//         code: 'MET',
	//         name: 'Metformin 500mg',
	//         drug_no: 'A078901456',
	//         parts: '',
	//         freq: 'BID',
	//         days: 10,
	//         total: 20,
	//         note: '01',
	//         submit: false,
	//         long: true,
	//         significant: false,
	//         sign: true,
	//     },
	//     {
	//         sended: true,
	//         sending: false,
	//         code: 'PAR',
	//         name: 'Paracetamol 500mg',
	//         drug_no: 'A089012567',
	//         parts: '',
	//         freq: 'TID',
	//         days: 4,
	//         total: 12,
	//         note: '01',
	//         submit: true,
	//         long: false,
	//         significant: true,
	//         sign: false,
	//     },
	//     {
	//         sended: false,
	//         sending: true,
	//         code: 'CLO',
	//         name: 'Clopidogrel 75mg',
	//         drug_no: 'A090123678',
	//         parts: '',
	//         freq: 'QD',
	//         days: 30,
	//         total: 30,
	//         note: '01',
	//         submit: false,
	//         long: true,
	//         significant: false,
	//         sign: true,
	//     },
	//     {
	//         sended: true,
	//         sending: false,
	//         code: 'SIM',
	//         name: 'Simvastatin 20mg',
	//         drug_no: 'A101234789',
	//         parts: '',
	//         freq: 'QD',
	//         days: 14,
	//         total: 14,
	//         note: '01',
	//         submit: true,
	//         long: false,
	//         significant: true,
	//         sign: false,
	//     },
	//     {
	//         sended: false,
	//         sending: true,
	//         code: 'AZI',
	//         name: 'Azithromycin 250mg',
	//         drug_no: 'A112345890',
	//         parts: '',
	//         freq: 'QD',
	//         days: 3,
	//         total: 3,
	//         note: '01',
	//         submit: false,
	//         long: true,
	//         significant: false,
	//         sign: true,
	//     },
	// ];

	return (
		<>
			<Box pos={"relative"} h={`calc(${window.innerHeight}px - 464px)`} overflowY={"scroll"}>
				<Flex bg={"#fff"} pos={"sticky"} top={0} left={0} borderBottom={"2px solid #646B48"} w={"100%"} zIndex={10}>
					<Box flex={"0 0 5%"}>
						<Text textStyle={"semiTitle"} p={"4px 8px"}>
							已傳
						</Text>
					</Box>
					<Box flex={"0 0 5%"}>
						<Text textStyle={"semiTitle"} p={"4px 8px"} textAlign={"center"}>
							傳
						</Text>
					</Box>
					<Box flex={"0 0 5%"}>
						<Text textStyle={"semiTitle"} p={"4px 8px"}>
							代碼
						</Text>
					</Box>
					<Box flex={"1 0 15%"}>
						<Text textStyle={"semiTitle"} p={"4px 8px"}>
							藥品名稱
						</Text>
					</Box>
					<Box flex={"1 0 10%"}>
						<Text textStyle={"semiTitle"} p={"4px 8px"}>
							健保碼
						</Text>
					</Box>
					<Box flex={"0 0 5%"}>
						<Text textStyle={"semiTitle"} p={"4px 8px"}>
							部位
						</Text>
					</Box>
					<Box flex={"0 0 5%"}>
						<Text textStyle={"semiTitle"} p={"4px 8px"}>
							用法
						</Text>
					</Box>
					<Box flex={"0 0 5%"}>
						<Text textStyle={"semiTitle"} p={"4px 8px"} textAlign={"center"}>
							天
						</Text>
					</Box>
					<Box flex={"0 0 5%"}>
						<Text textStyle={"semiTitle"} p={"4px 8px"} textAlign={"center"}>
							總量
						</Text>
					</Box>
					<Box flex={"0 0 5%"}>
						<Text textStyle={"semiTitle"} p={"4px 8px"}>
							註記
						</Text>
					</Box>
					<Box flex={"0 0 5%"}>
						<Text textStyle={"semiTitle"} p={"4px 8px"} textAlign={"center"}>
							交
						</Text>
					</Box>
					<Box flex={"0 0 5%"}>
						<Text textStyle={"semiTitle"} p={"4px 8px"} textAlign={"center"}>
							長期
						</Text>
					</Box>
					<Box flex={"0 0 5%"}>
						<Text textStyle={"semiTitle"} p={"4px 8px"} textAlign={"center"}>
							重要
						</Text>
					</Box>
					<Box flex={"0 0 5%"}>
						<Text textStyle={"semiTitle"} p={"4px 8px"} textAlign={"center"}>
							簽
						</Text>
					</Box>
				</Flex>

				{prescriptions &&
					prescriptions.map((item, index) => (
						<Flex borderBottom={"1px solid #646B4833"} w={"100%"}>
							<Center flex={"0 0 5%"}>
								{item.sended && (
									<>
										<Box
											textColor={"rsPrimary.500"}
											opacity={0.8}
											_hover={{
												opacity: 1,
												cursor: "pointer"
											}}>
											<HeroIcons name='check-circle-fill' />
										</Box>
									</>
								)}
							</Center>
							<Center flex={"0 0 5%"}>
								<Checkbox colorScheme='rsPrimary' isChecked={item.sending}></Checkbox>
							</Center>
							<Box flex={"0 0 5%"}>
								<Text textStyle={"semiTitle"} p={"4px 8px"}>
									{item.code}
								</Text>
							</Box>
							<Box flex={"1 0 15%"}>
								<Text textStyle={"semiTitle"} p={"4px 8px"}>
									{item.name}
								</Text>
							</Box>
							<Box flex={"1 0 10%"}>
								<Text textStyle={"semiTitle"} p={"4px 8px"}>
									{item.drug_no}
								</Text>
							</Box>
							<Box flex={"0 0 5%"}>
								<Text textStyle={"semiTitle"} p={"4px 8px"}>
									{item.parts}
								</Text>
							</Box>
							<Box flex={"0 0 5%"}>
								<Text textStyle={"semiTitle"} p={"4px 8px"}>
									{item.freq}
								</Text>
							</Box>
							<Box flex={"0 0 5%"}>
								<Text textStyle={"semiTitle"} p={"4px 8px"} textAlign={"right"}>
									{item.days}
								</Text>
							</Box>
							<Box flex={"0 0 5%"}>
								<Text textStyle={"semiTitle"} p={"4px 8px"} textAlign={"right"}>
									{item.total}
								</Text>
							</Box>
							<Box flex={"0 0 5%"}>
								<Text textStyle={"semiTitle"} p={"4px 8px"} textAlign={"center"}>
									{item.note}
								</Text>
							</Box>
							<Center flex={"0 0 5%"}>
								<Checkbox colorScheme='rsPrimary' isChecked={item.submit}></Checkbox>
							</Center>
							<Center flex={"0 0 5%"}>
								<Checkbox colorScheme='rsPrimary' isChecked={item.long}></Checkbox>
							</Center>
							<Center flex={"0 0 5%"}>
								<Checkbox colorScheme='rsPrimary' isChecked={item.significant}></Checkbox>
							</Center>
							<Center flex={"0 0 5%"}>
								<Checkbox colorScheme='rsPrimary' isChecked={item.sign}></Checkbox>
							</Center>
						</Flex>
					))}
			</Box>
		</>
	);
};
