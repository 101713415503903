import { Input, InputGroup, InputLeftElement, Button, Box, Flex, Text, Center } from "@chakra-ui/react";
import { MedicalOrderListsProps } from "./DrugDataMaintenanceTable";
import { useUnsavedChanges } from "contexts/UnsavedChangesContext";
import { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";

//API
import { postDrugsInUseService } from "service/drugsInUseService";
import { postDrugAttachDrugsSortService } from "service/drugAttachDrugsSortService";
import { deleteDrugAttachDrugsService } from "service/drugAttachDrugsService";
import { useUserStore } from "store";
//排序
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import Loading from "components/CustomComponents/Loading";
interface finalData {
	main_drug_uid: string;
	ids: string[];
}
interface DrugDataMaintenanceMedicalOrderProps {
	medicalOrderLists: MedicalOrderListsProps[];
	setMedicalOrderLists: React.Dispatch<React.SetStateAction<MedicalOrderListsProps[]>>;
	isEditing: boolean;
	setIsFromDelete: React.Dispatch<React.SetStateAction<boolean>>;
	dataIdFromTable: string;
	setOriginMedicalOrderLists: React.Dispatch<React.SetStateAction<MedicalOrderListsProps[]>>;
}

interface SearchListsProps {
	code: string;
	data_id: string;
	name_en: string;
	name_tw: string;
	short_code: string;
	drug_no: string;
}

const DrugDataMaintenanceMedicalOrder = ({
	medicalOrderLists,
	setMedicalOrderLists,
	isEditing,
	setIsFromDelete,
	dataIdFromTable,
	setOriginMedicalOrderLists
}: DrugDataMaintenanceMedicalOrderProps) => {
	// const token = localStorage.getItem('token');
	const { userInfo } = useUserStore();
	const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();
	const [searchLists, setSearchLists] = useState<SearchListsProps[]>([]);
	const boxRef = useRef<HTMLDivElement>(null);

	const [isCNtyping, setIsCNtyping] = useState<boolean>(false);
	const [openShowListsStatus, setOpenShowListsStatus] = useState<boolean>(false);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			const target = event.target as Node;

			if (boxRef.current && !boxRef.current.contains(target)) {
				setOpenShowListsStatus(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [boxRef]);

	const callDrugOfficialAPI = () => {
		let finalData = {
			keyword: keyword
		};
		const postDragsOfficialAPI = async () => {
			if (userInfo.token) {
				try {
					const result = await postDrugsInUseService(userInfo.token, finalData);
					if (result.result === "success") {
						setSearchLists(result.lists);
					} else if (result.result === "failure") {
						Swal.fire({
							icon: "warning",
							title: "查無資料",
							showConfirmButton: true,
							confirmButtonText: "重新嘗試"
						});
						setOpenShowListsStatus(false);
					}
				} catch {
					Swal.fire({
						icon: "error",
						title: "後端服務錯誤",
						timer: 1500
					});
				}
			}
		};
		postDragsOfficialAPI();
	};
	const handleSearch = () => {
		setSearchLists([]);
		callDrugOfficialAPI();
		setOpenShowListsStatus(true);
		setTimeout(() => {
			boxRef.current?.focus();
		}, 0);
	};
	const [keyword, setKeyword] = useState<string>("");
	const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		setKeyword(inputElement);
	};

	const handleClickInsert = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
		const selectedItem = searchLists[index];
		let finalData = {
			attach_drug_uid: selectedItem.data_id,
			name_tw: selectedItem.name_tw,
			name_en: selectedItem.name_en,
			short_code: selectedItem.short_code,
			drug_no: selectedItem.drug_no
		};
		setMedicalOrderLists(prevLists => [...prevLists, finalData]);
		setHasUnsavedChanges(true);
	};

	const deleteDrugAttachDrugsAPI = async (id: string, deleteIndex: number) => {
		// 修改 已經有的刪掉
		if (userInfo.token && medicalOrderLists && id) {
			try {
				const result = await deleteDrugAttachDrugsService(userInfo.token, id);
				let newArr = medicalOrderLists.filter((item, index) => index !== deleteIndex);
				if (result.result === "success") {
					setMedicalOrderLists(newArr);
					setIsFromDelete(true);
					Swal.fire({
						icon: "success",
						title: "刪除成功",
						showConfirmButton: false,
						timer: 1500
					});
				} else if (result.result === "failure") {
					Swal.fire({
						icon: "error",
						title: "刪除失敗",
						showConfirmButton: false,
						timer: 1500
					});
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500
				});
			}
		} else {
			return;
		}
	};
	const handleDelete = (deleteIndex: number) => {
		let newArr = medicalOrderLists.filter((item, index) => index !== deleteIndex);
		let data_id = medicalOrderLists[deleteIndex].data_id;

		if (data_id) {
			Swal.fire({
				title: "是否要刪除此醫令？",
				icon: "question",
				showCancelButton: true,
				confirmButtonText: "是",
				cancelButtonText: "否"
			}).then(result => {
				if (result.isConfirmed) {
					if (medicalOrderLists[deleteIndex].data_id && data_id) {
						deleteDrugAttachDrugsAPI(data_id, deleteIndex);
					}
				}
				return;
			});
		} else {
			setMedicalOrderLists(newArr);
			setIsFromDelete(true);
		}
	};
	const handleDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		} else {
			const newData = [...medicalOrderLists];

			const [movedItem] = newData.splice(result.source.index, 1);
			newData.splice(result.destination.index, 0, movedItem);

			setMedicalOrderLists(newData);
			let newArr: string[] = [];

			newData.forEach(item => {
				if (item.data_id) {
					newArr.push(item.data_id);
				}
			});

			if (newData && newArr) {
				const postDrugAttachDrugsSortAPI = async () => {
					const finalData: finalData = {
						main_drug_uid: dataIdFromTable,
						ids: newArr
					};
					if (userInfo.token && finalData && !hasUnsavedChanges) {
						try {
							const result = await postDrugAttachDrugsSortService(userInfo.token, finalData);
							console.log(finalData, "finalData");
							if (result.result === "success") {
								Swal.fire({
									icon: "success",
									title: " 更新成功",
									showConfirmButton: false, // 隱藏確認按鈕
									timer: 1500 // 設定自動關閉的時間 (1.5 秒)
								});
							} else {
								Swal.fire({
									icon: "error",
									title: " 更新失敗",
									showConfirmButton: false, // 隱藏確認按鈕
									timer: 1500 // 設定自動關閉的時間 (1.5 秒)
								});
							}
						} catch {
							Swal.fire({
								icon: "error",
								title: "後端服務錯誤",
								timer: 1500
							});
						}
					} else {
					}
				};
				postDrugAttachDrugsSortAPI();
			}
		}
	};

	const handleListNavigation = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === "ArrowDown") {
			// 處理向下箭頭鍵
			// 例如：選擇下一個列表項目
			console.log("down");
		} else if (event.key === "ArrowUp") {
			console.log("up");
			// 處理向上箭頭鍵
			// 例如：選擇上一個列表項目
		}
	};

	return (
		<>
			<Flex direction={"column"} gap={"16px"}>
				<Flex gap={"8px"} w={"100%"}>
					<Box pos={"relative"} zIndex={20} flex={"1 0 auto"}>
						<InputGroup>
							<InputLeftElement pointerEvents='none'>
								<HeroIcons name='search'></HeroIcons>
							</InputLeftElement>
							<Input
								bgColor={isEditing ? "#fff" : "transparent"}
								placeholder='藥碼、簡碼、藥品名稱、健保碼'
								onChange={handleChangeInput}
								isDisabled={!isEditing}
								onCompositionStart={() => {
									setIsCNtyping(true);
								}}
								onCompositionEnd={() => {
									setIsCNtyping(false);
								}}
								onKeyDown={e => {
									if (!isCNtyping && e.key === "Enter") {
										handleSearch();
									}
								}}
							/>
						</InputGroup>
						{openShowListsStatus && (
							<>
								<Box
									pos={"absolute"}
									top={"40px"}
									left={"0px"}
									w={"100%"}
									overflowY={"scroll"}
									rounded={"md"}
									zIndex={"60"}
									maxH={"230px"}
									boxShadow={"lg"}
									ref={boxRef}
									tabIndex={0}
									onKeyDown={event => handleListNavigation(event)}>
									<Flex
										bgColor='rsPrimary.500'
										textColor={"#fff"}
										p={"8px 20px"}
										justify={"space-between"}
										pos={"sticky"}
										top={0}>
										<Flex gap={2}>
											<Text>請點選</Text>
											<Text fontWeight={"normal"} textColor={"#fff"}>
												（已選擇 {medicalOrderLists.length} 筆）
											</Text>
										</Flex>
										<Box
											onClick={() => {
												setOpenShowListsStatus(false);
											}}
											opacity={0.8}
											_hover={{
												opacity: 1,
												cursor: "pointer"
											}}>
											DONE
										</Box>
									</Flex>

									{searchLists ? (
										<>
											{" "}
											{searchLists.map((item, index) => {
												const isInShowLists = medicalOrderLists.some(showItem => showItem.data_id === item.data_id);
												return (
													<>
														<Flex
															key={index}
															onClick={event => handleClickInsert(event, index)}
															bg={isInShowLists ? "#ddf2f0" : "#fff"}
															_hover={{
																bg: "#E9E5DA",
																cursor: "pointer"
															}}
															textColor={"#000"}
															// 等一下來看這邊

															borderBottom={"1px solid rgba(0, 0, 0, 0.05)"}>
															<Box flex={"0 0 150px"} p={"8px 16px"}>
																<Text>{item.short_code}</Text>
															</Box>
															<Box flex={"0 0 150px"} p={"8px 16px"}>
																<Text>{item.drug_no}</Text>
															</Box>
															<Box flex={"1 1 0"} p={"8px 16px"}>
																<Text>{item.name_tw ? item.name_tw : item.name_en}</Text>
															</Box>
															<Center w={"50px"} textColor={"rsPrimary.500"}>
																<HeroIcons name='plus-circle-fill'></HeroIcons>
															</Center>
														</Flex>
													</>
												);
											})}
										</>
									) : (
										<>
											<Box minH={"80px"} pos={"relative"} bg={"#fff"}>
												<Loading></Loading>
											</Box>
										</>
									)}
								</Box>
							</>
						)}
					</Box>

					<Box>
						<Button colorScheme='rsPrimary' onClick={handleSearch} isDisabled={!isEditing}>
							查詢
						</Button>
					</Box>
				</Flex>

				<Box>
					{/* table header */}
					<Flex borderBottom={"1px solid #708368"} pos={"sticky"} top={"40px"} zIndex={10} textColor={"#868C6E"}>
						<Box p={"8px 16px"} fontWeight={"semibold"} w={"80px"} textAlign={"center"}>
							排序
						</Box>
						<Box p={"8px 16px"} fontWeight={"semibold"} flex={"0 0 250px"}>
							藥品代碼
						</Box>
						<Box p={"8px 16px"} fontWeight={"semibold"} flex={"1 1 0"}>
							健保碼
						</Box>
						<Box p={"8px 16px"} fontWeight={"semibold"} flex={"1 1 0"}>
							藥品名稱
						</Box>
						<Center w={"50px"} padding={"8px 8px"} fontWeight={"semibold"}>
							刪除
						</Center>
					</Flex>
					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable droppableId='root'>
							{/* table body */}
							{provided => (
								<Flex
									h={"150px"}
									overflowY={"scroll"}
									mt={"8px"}
									direction={"column"}
									{...provided.droppableProps}
									ref={provided.innerRef}>
									{medicalOrderLists && (
										<>
											{medicalOrderLists.map((item, index) => (
												<Draggable
													key={index}
													draggableId={item.data_id || `item-${index}`} // 使用默認值
													index={index}>
													{provided => (
														// Tr
														<Flex
															textColor={"#8c8c8c"}
															borderBottom={"1px solid #0000000d"}
															key={item.data_id}
															{...provided.draggableProps}
															ref={provided.innerRef}>
															{isEditing ? (
																<>
																	<Flex
																		p={"8px 16px"}
																		w={"80px"}
																		justify={"center"}
																		align={"center"}
																		{...provided.dragHandleProps}>
																		&#x283F;
																	</Flex>
																</>
															) : (
																<>
																	<Flex
																		p={"8px 16px"}
																		w={"80px"}
																		justify={"center"}
																		align={"center"}
																		opacity={0.5}>
																		&#x283F;
																	</Flex>
																</>
															)}

															<Box p={"8px 16px"} fontWeight={"semibold"} flex={"0 0 250px"}>
																{item.short_code}
															</Box>
															<Box p={"8px 16px"} fontWeight={"semibold"} flex={"1 1 0"}>
																{item.drug_no}
															</Box>
															<Box p={"8px 16px"} fontWeight={"semibold"} flex={"1 1 0"}>
																{item.name_tw}
															</Box>

															{isEditing ? (
																<>
																	<Center
																		w={"50px"}
																		p={"8px 8px"}
																		onClick={() => handleDelete(index)}
																		opacity={0.8}
																		_hover={{
																			cursor: "pointer",
																			opacity: 1
																		}}>
																		<HeroIcons name='delete-fill'></HeroIcons>
																	</Center>
																</>
															) : (
																<>
																	<Center w={"50px"} p={"8px 8px"} opacity={0.5} pointerEvents={"none"}>
																		<HeroIcons name='delete-fill'></HeroIcons>
																	</Center>
																</>
															)}
														</Flex>
													)}
												</Draggable>
											))}
										</>
									)}
								</Flex>
							)}
						</Droppable>
					</DragDropContext>
				</Box>
			</Flex>
		</>
	);
};

export default DrugDataMaintenanceMedicalOrder;
