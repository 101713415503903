import apiService from "./apiService";
import axiosInstance from "./axiosInstance";

//取得序號規則設定清單
export const callRegSeqSettingService = async (token: string, data?: string) => {
	const queryParams = data ? { data } : undefined;
	return apiService<string, any>({
		method: "GET",
		path: "/api/reg_seq_setting",
		token,
		data: queryParams
	});
};
// export const callRegSeqSettingService = async (token: string, id?: string) => {
//     try {
//         const headers = {
//             'Api-Token': `${token}`,
//         };
//         const queryParams = id ? { id } : {};
//         const response = await axiosInstance.get('/api/reg_seq_setting', {
//             headers,
//             params: queryParams,
//         });
//         const result = response.data;
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };

interface ListsProps {
	rule_type: number;
	seq_list: string;
}
export interface RegSeqSettingProps {
	data_id?: string;
	rule_name: string;
	lists: ListsProps[];
}
// 設定並儲存序號規則內容

export const postRegSeqSettingService = async (token: string, updatedData?: RegSeqSettingProps) => {
	return apiService<RegSeqSettingProps, any>({
		method: "POST",
		path: "api/reg_seq_setting",
		token,
		data: updatedData
	});
};
// export const postRegSeqSettingService = async (token: string, data?: any) => {
//     try {
//         const requestBody = JSON.stringify(data);
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         const response = await axios.post('api/reg_seq_setting', requestBody, {
//             headers,
//         });
//         const result = response.data;

//         console.log(result, 'API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };

// 刪除序號規則
export const deleteRegSeqSettingService = async (token: string, data_id: string) => {
	// 使用apiService调用DELETE方法
	return apiService<undefined, any>({
		method: "DELETE",
		path: `/api/reg_seq_setting?id=${data_id}`,
		token
	});
};
// export const deleteRegSeqSettingService = async (
//     token: string,
//     data_id: string
// ) => {
//     try {
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };
//         console.log('service這邊有嗎', data_id);
//         const url = `/api/reg_seq_setting?id=${data_id}`;

//         const response = await axiosInstance.delete(url, {
//             headers,
//         });

//         const result = response.data;
//         console.log(result, 'API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
