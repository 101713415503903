// import axios from 'axios';
import axiosInstance from "./axiosInstance";

import apiService from "./apiService";
// 取得藥品附加S.O.

export const callDrugAttachSoService = async (token: string, data: string) => {
	const queryParams = data ? { data } : undefined;
	return apiService<string, any>({
		method: "GET",
		path: "/api/drug/attach_so",
		token,
		data: queryParams
	});
};

// 取得藥品附加S.O.
// export const callDrugAttachSoService = async (token: string, id: string) => {
//     try {
//         const queryParams = { id };
//         const headers = {
//             'Api-Token': `${token}`,
//         };
//         const response = await axiosInstance.get('/api/drug/attach_so', {
//             headers,
//             params: queryParams,
//         });
//         const result = response.data;
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };

// import axios from 'axios';

interface soDataProps {
	data_id: string;
	subjective: string;
	objective: string;
}

// 更新 附加 SO
export const postDrugAttachSoService = async (token: string, updatedData: soDataProps) => {
	return apiService({
		method: "POST",
		path: "/api/drug/attach_so",
		token,
		data: updatedData
	});
};

// export const postDrugAttachSoService = async (
//     token: string,
//     updatedData: soDataProps
// ) => {
//     try {
//         const requestBody = JSON.stringify(updatedData);
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         const response = await axiosInstance.post('/api/drug/attach_so', requestBody, {
//             headers,
//         });
//         const result = response.data;

//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
