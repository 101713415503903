import { create } from "zustand";

export type DeclareSearchRangeDataProps = {
	ym?: string;
	page?: number;
};

interface DeclareSearchRangeState {
	declareSearchRangeData: DeclareSearchRangeDataProps;
	updateDeclareSearchRangeData: (newData: Partial<DeclareSearchRangeState["declareSearchRangeData"]>) => void;
	resetDeclareSearchRangeData: () => void;
}

const initialDeclareSearchRangeData: DeclareSearchRangeDataProps = {
	ym: "",
	page: 1
};

export const useDeclareSearchRangeStore = create<DeclareSearchRangeState>(set => ({
	declareSearchRangeData: initialDeclareSearchRangeData,
	updateDeclareSearchRangeData: newData =>
		set(state => ({
			declareSearchRangeData: {
				...state.declareSearchRangeData,
				...newData
			}
		})),
	resetDeclareSearchRangeData: () =>
		set(() => ({
			declareSearchRangeData: initialDeclareSearchRangeData
		}))
}));
