import { PrescriptionProps } from "store/TreatmentManagement";
import { TreatmentInfoProps } from "store/TreatmentManagement/useTreatmentInfoStore";
import { DxProps } from "store/TreatmentManagement/useDxStore";
import { FeeState } from "store/TreatmentManagement/Payment/useFeeStore";
import { customerProps } from "service/customerService";
import { calculateAgeNumber } from "functions/calculateAgeNumber";
import { isChronicLogic } from "./chronicConditions";
import Swal from "sweetalert2";
import { calculateAgeMonthNumber } from "functions/calculateAgeMonthNumber";
import { getDoseDay } from "functions/getDoseDay";

interface drugTypeListProps {
	data_id: string;
	code: string;
	name: string;
}

type OriginDrugHandler = (drug: PrescriptionProps) => void;
let isConfirmed = false; //

export const resetConfirmation = () => {
	isConfirmed = false;
};

export const computeFeeAndClassifyCase = (
	drugTypeList: drugTypeListProps[],
	treatmentInfo: TreatmentInfoProps,
	prescriptionList: PrescriptionProps[],
	dxList: DxProps[],
	customData: customerProps,
	initialFeeState: FeeState,
	feeData: FeeState,
	setFeeData: (feeValues: Partial<FeeState>) => void,
	resetFeeData: () => void
) => {
	let localFeeData = { ...initialFeeState };
	let maxDay = 0;
	let caseType = treatmentInfo.case_type || "01";
	// let caseType = '01';
	let highFee = 0;
	let nowMFeePH = 358;
	let deliveryTransfer = treatmentInfo.prescription_style;
	let mOriginalBottle = 0;
	let DurgEQNumber = 0;
	let percentageX = 1;
	let percentageEQ = 1;
	let percentageR = 1;
	let percentageP = 1;
	let percentageA = 1;
	let XArr: string[] = [];
	let age: { ageYear: number; ageMonth: number } | null = null;

	if (treatmentInfo.birth_date !== null) {
		age = calculateAgeMonthNumber(new Date(), treatmentInfo.birth_date);
	} else {
	}

	const groupedPrescription: { [key: string]: PrescriptionProps[] } = {};
	const selfPayPrescription: { [key: string]: PrescriptionProps[] } = {};

	// console.log(groupedPrescription, selfPayPrescription, '有哪些', prescriptionList);
	prescriptionList.forEach(item => {
		// console.log(drugTypeList, '藥類下拉清單')
		const drugType = drugTypeList.find(drug => drug.data_id === item.drug_type);
		if (drugType && item.special_mark !== "O") {
			if (item.special_mark?.includes("X")) {
				if (!selfPayPrescription[drugType.code]) {
					selfPayPrescription[drugType.code] = [];
				}
				selfPayPrescription[drugType.code].push(item);
			} else if (!item.special_mark?.includes("贈")) {
				if (!groupedPrescription[drugType.code]) {
					groupedPrescription[drugType.code] = [];
				}
				groupedPrescription[drugType.code].push(item);
			}
		}
	});
	// console.log(groupedPrescription, selfPayPrescription, '有哪些啦')

	const originDrugHandlers: { [key: string]: OriginDrugHandler } = {
		O: (drug: PrescriptionProps) => {
			if (treatmentInfo.nhi_type_name === "自費" && treatmentInfo.prescription_style !== 1) {
				localFeeData.xFeeO += Number(drug.given_qty) * Number(drug.self_price);
				localFeeData.xFeeO = Math.round(localFeeData.xFeeO);
				// console.log('算一下自費O', localFeeData.xFeeO)
			} else {
				localFeeData.mFeeO += Number(drug.given_qty) * Number(drug.drug_price);
				localFeeData.mFeeO = Math.round(localFeeData.mFeeO);
				// console.log('算一下健保O', localFeeData.mFeeO)
			}
		},
		L: (drug: PrescriptionProps) => {
			if (treatmentInfo.nhi_type_name === "自費" && treatmentInfo.prescription_style !== 1) {
				localFeeData.xFeeL += Number(drug.given_qty) * Number(drug.self_price);
				localFeeData.xFeeL = Math.round(localFeeData.mFeeL);
			} else {
				localFeeData.mFeeL += Number(drug.given_qty) * Number(drug.drug_price);
				localFeeData.mFeeL = Math.round(localFeeData.mFeeL);
			}
		},
		W: (drug: PrescriptionProps) => {
			if (treatmentInfo.nhi_type_name === "自費" && treatmentInfo.prescription_style !== 1) {
				localFeeData.xFeeW += Number(drug.given_qty) * Number(drug.self_price);
				localFeeData.xFeeW = Math.round(localFeeData.xFeeW);
			} else {
				localFeeData.mFeeW += Number(drug.given_qty) * Number(drug.drug_price);
				localFeeData.mFeeW = Math.round(localFeeData.mFeeW);
				mOriginalBottle++;
			}
		},
		E: (drug: PrescriptionProps) => {
			if (treatmentInfo.nhi_type_name === "自費" && treatmentInfo.prescription_style !== 1) {
				localFeeData.xFeeE += Number(drug.given_qty) * Number(drug.self_price);
				localFeeData.xFeeE = Math.round(localFeeData.xFeeE);
			} else {
				if (drug.special_mark?.includes("R")) {
					localFeeData.DeliverEQFee = Number(drug.given_qty) * Number(drug.drug_price);
					localFeeData.DeliverEQFee = Math.round(localFeeData.DeliverEQFee);
				} else {
					localFeeData.mFeeE += Number(drug.given_qty) * Number(drug.drug_price);
					localFeeData.mFeeE = Math.round(localFeeData.mFeeE);
					DurgEQNumber++;
				}
			}
		},
		Q: (drug: PrescriptionProps) => {
			if (treatmentInfo.nhi_type_name === "自費" && treatmentInfo.prescription_style !== 1) {
				localFeeData.xFeeQ += Number(drug.given_qty) * Number(drug.self_price);
				localFeeData.xFeeQ = Math.round(localFeeData.xFeeQ);
			} else {
				if (drug.special_mark?.includes("R")) {
					localFeeData.DeliverEQFee = Number(drug.given_qty) * Number(drug.drug_price);
					localFeeData.DeliverEQFee = Math.round(localFeeData.DeliverEQFee);
				} else {
					localFeeData.mFeeQ += Number(drug.given_qty) * Number(drug.drug_price) * percentageEQ;
					localFeeData.mFeeQ = Math.round(localFeeData.mFeeQ);
					DurgEQNumber++;
					percentageEQ -= DurgEQNumber > 15 ? 0.2 : DurgEQNumber > 10 ? 0.1 : 0;
				}
			}
		},
		X: (drug: PrescriptionProps) => {
			if (treatmentInfo.nhi_type_name === "自費" && treatmentInfo.prescription_style !== 1) {
				localFeeData.xFeeX += Number(drug.given_qty) * Number(drug.self_price);
				localFeeData.xFeeX = Math.round(localFeeData.xFeeX);
			} else {
				if (drug.special_mark?.includes("R")) {
					localFeeData.DeliverEQFee = Number(drug.given_qty) * Number(drug.drug_price);
					localFeeData.DeliverEQFee = Math.round(localFeeData.DeliverEQFee);
				} else {
					localFeeData.mFeeX += Number(drug.given_qty) * Number(drug.drug_price) * percentageEQ;
					localFeeData.mFeeX = Math.round(localFeeData.mFeeX);
					DurgEQNumber++;
					if (treatmentInfo.mt_type === "04") {
						percentageX += 0.2;
					}
					if (XArr.includes(drug.drug_no)) {
						percentageX -= 0.2;
					} else {
						XArr.push(drug.drug_no);
					}
				}
			}
		},
		R: (drug: PrescriptionProps) => {
			if (treatmentInfo.nhi_type_name === "自費" && treatmentInfo.prescription_style !== 1) {
				localFeeData.xFeeR += Number(drug.given_qty) * Number(drug.self_price);
				localFeeData.xFeeR = Math.round(localFeeData.xFeeR);
			} else {
				if (age) {
					percentageR += age.ageYear < 2 ? 0.3 : age.ageYear < 6 ? 0.2 : 0;
				}
				localFeeData.mFeeR += Number(drug.given_qty) * Number(drug.drug_price) * percentageR;
				localFeeData.mFeeR = Math.round(localFeeData.mFeeR);
			}
		},
		G: (drug: PrescriptionProps) => {
			if (treatmentInfo.nhi_type_name === "自費" && treatmentInfo.prescription_style !== 1) {
				localFeeData.xFeeG += Number(drug.given_qty) * Number(drug.self_price);
				localFeeData.xFeeG = Math.round(localFeeData.xFeeG);
			} else {
				localFeeData.mFeeG += Number(drug.given_qty) * Number(drug.drug_price);
				localFeeData.mFeeG = Math.round(localFeeData.mFeeG);
			}
		},
		P: (drug: PrescriptionProps) => {
			if (treatmentInfo.nhi_type_name === "自費" && treatmentInfo.prescription_style !== 1) {
				localFeeData.xFeeP += Number(drug.given_qty) * Number(drug.self_price);
				localFeeData.xFeeP = Math.round(localFeeData.xFeeP);
			} else {
				percentageP += 0.53;
				if (treatmentInfo.mt_type === "04") percentageP += 0.3;
				localFeeData.mFeeP += Number(drug.given_qty) * Number(drug.drug_price) * percentageP;
				localFeeData.mFeeP = Math.round(localFeeData.mFeeP);
			}
		},
		A: (drug: PrescriptionProps) => {
			if (treatmentInfo.nhi_type_name === "自費" && treatmentInfo.prescription_style !== 1) {
				localFeeData.xFeeA += Number(drug.given_qty) * Number(drug.self_price);
				localFeeData.xFeeA = Math.round(localFeeData.xFeeA);
			} else {
				localFeeData.mFeeA += Number(drug.given_qty) * Number(drug.drug_price);
				localFeeData.mFeeA = Math.round(localFeeData.mFeeA);
				if (treatmentInfo.mt_type === "04") percentageA += 0.2;
			}
		},
		M: (drug: PrescriptionProps) => {
			if (treatmentInfo.nhi_type_name === "自費" && treatmentInfo.prescription_style !== 1) {
				localFeeData.xFeeM += Number(drug.given_qty) * Number(drug.self_price);
				localFeeData.xFeeM = Math.round(localFeeData.xFeeM);
			} else {
				if (drug.special_mark?.includes("Z")) {
					localFeeData.DeliverMFee += Number(drug.drug_price);
					localFeeData.DeliverMFee = Math.round(localFeeData.DeliverMFee);
				} else {
					localFeeData.mFeeM += Number(drug.given_qty) * Number(drug.drug_price);
					localFeeData.mFeeM = Math.round(localFeeData.mFeeM);
				}
			}
		}
	};

	const processDrugItem = (item: PrescriptionProps, treatmentInfo: TreatmentInfoProps, code: string, isSelfPay: boolean) => {
		if (originDrugHandlers[code as keyof typeof originDrugHandlers]) {
			originDrugHandlers[code as keyof typeof originDrugHandlers](item);
		}
	};

	const processPrescriptions = (
		prescriptions: { [key: string]: PrescriptionProps[] },
		treatmentInfo: TreatmentInfoProps,
		isSelfPay: boolean
	) => {
		Object.keys(prescriptions).forEach(code => {
			prescriptions[code].forEach(item => {
				processDrugItem(item, treatmentInfo, code, isSelfPay);
				if (!isSelfPay && Number(item.dose_day) > maxDay) {
					maxDay = Number(item.dose_day);
				}
			});
		});
	};

	processPrescriptions(groupedPrescription, treatmentInfo, false);
	processPrescriptions(selfPayPrescription, treatmentInfo, true);
	// if(treatmentInfo.nhi_type_name === '自費' ){
	//     localFeeData.xDrugFee = localFeeData.xFeeO + localFeeData.xFeeL + localFeeData.xFeeI + localFeeData.xFeeW + localFeeData.xFeeG;
	//     localFeeData.xDiagnosisFee = localFeeData.xFeeT + localFeeData.xFeeR + localFeeData.xFeeF + localFeeData.xFeeP + localFeeData.xFeeA;
	//     localFeeData.xEQFee = localFeeData.xFeeE + localFeeData.xFeeQ + localFeeData.xFeeX;
	//     localFeeData.xFeePH = 358
	// }else{

	// }

	let drugW = groupedPrescription["W"] ? groupedPrescription["W"].length : 0;
	if (age && age.ageYear < 13 && drugW) {
		if (drugW === 1) {
			if (maxDay === 1) {
				localFeeData.mDrugFee = 31;
			} else if (maxDay === 2) {
				localFeeData.mDrugFee = 62;
			} else if (maxDay === 3) {
				localFeeData.mDrugFee = 93;
			}
		} else if (drugW === 2) {
			if (maxDay === 1) {
				localFeeData.mDrugFee = 37;
			} else if (maxDay === 2) {
				localFeeData.mDrugFee = 74;
			} else if (maxDay === 3) {
				localFeeData.mDrugFee = 111;
			}
		} else if (drugW >= 3) {
			if (maxDay === 1) {
				localFeeData.mDrugFee = 41;
			} else if (maxDay === 2) {
				localFeeData.mDrugFee = 82;
			} else if (maxDay === 3) {
				localFeeData.mDrugFee = 123;
			}
		}
	}

	const checkHighDrugFee = (mDrugFee: number) => {
		// console.log(mDrugFee, '健保藥費多少');
		if (mDrugFee < 100) {
			localFeeData.mFeeHD = 0;
		} else if (mDrugFee < 200) {
			localFeeData.mFeeHD = 20;
		} else if (mDrugFee < 300) {
			localFeeData.mFeeHD = 40;
		} else if (mDrugFee < 400) {
			localFeeData.mFeeHD = 60;
		} else if (mDrugFee < 500) {
			localFeeData.mFeeHD = 80;
		} else if (mDrugFee < 600) {
			localFeeData.mFeeHD = 100;
		} else if (mDrugFee < 700) {
			localFeeData.mFeeHD = 120;
		} else if (mDrugFee < 800) {
			localFeeData.mFeeHD = 140;
		} else if (mDrugFee < 900) {
			localFeeData.mFeeHD = 160;
		} else if (mDrugFee < 1000) {
			localFeeData.mFeeHD = 180;
		} else {
			localFeeData.mFeeHD = 200;
		}
	};
	const checkHighEQFee = (mEQFee: number) => {
		// 預設基層院所
		let hospital_level = 1;
		if (hospital_level === 1) {
			// 基層
			// console.log('有沒有進基層院所', mEQFee);
			if (mEQFee > 1000) {
				localFeeData.mFeeEQ = 100;
			} else {
				localFeeData.mFeeEQ = 0;
			}
		} else if (hospital_level === 2) {
			// 地區
			if (mEQFee > 900) {
				localFeeData.mFeeEQ = 90;
			} else if (mEQFee > 800) {
				localFeeData.mFeeEQ = 80;
			} else if (mEQFee > 700) {
				localFeeData.mFeeEQ = 70;
			} else if (mEQFee > 600) {
				localFeeData.mFeeEQ = 60;
			} else if (mEQFee > 500) {
				localFeeData.mFeeEQ = 50;
			} else {
				localFeeData.mFeeEQ = 0;
			}
		}

		// FeeHE = (mFeeEq > 1000) ? 100 : (_hospital.HospLevel == EHospLevel.基層診所) ? 0 :
		// ((mFeeEq > 900) ? 90 : (mFeeEq > 800) ? 80 : (mFeeEq > 700) ? 70 : // 地區醫院
		// (mFeeEq > 600) ? 60 : (mFeeEq > 500) ? 50 : 0);
	};
	// if (maxDay > 14) {
	//     caseType = '04';
	// }
	// let isChronic = isChronicLogic(dxList);
	let isChronic = dxList[0].if_chronic === "1";
	//0：自調、1：交付、2：藥師調劑

	//
	if (treatmentInfo.nhi_type_name !== "自費") {
		// console.log('maxDay', maxDay, '會進到這嗎')
		if (maxDay < 8) {
			localFeeData.mFeeS = 39;
		} else if (maxDay < 14) {
			localFeeData.mFeeS = 39;
		} else if (maxDay < 28) {
			localFeeData.mFeeS = 41;
		} else {
			localFeeData.mFeeS = 51;
		}
	}
	if (age && age.ageYear === 0 && age.ageMonth < 6) {
		localFeeData.mFeeS = Math.round(localFeeData.mFeeS * 1.6);
	} else if (age && age.ageYear < 2) {
		localFeeData.mFeeS = Math.round(localFeeData.mFeeS * 1.3);
	} else if (age && age.ageYear < 7) {
		localFeeData.mFeeS = Math.round(localFeeData.mFeeS * 1.2);
	}

	if (treatmentInfo.prescription_style === 0) {
		console.log(caseType, "自條的caseType, func");
		// if (maxDay < 7) {
		//     localFeeData.mFeeS = 20;
		// } else if (maxDay < 13) {
		//     localFeeData.mFeeS = 20;
		// } else if (13 < maxDay && maxDay < 28) {
		//     localFeeData.mFeeS = 30;
		// } else {
		//     localFeeData.mFeeS = 41;
		// }
		if (treatmentInfo.nhi_type_name == "自費") {
			// localFeeData.xDrugFee = localFeeData.xDrugFee;
			localFeeData.xDrugFee = localFeeData.xFeeO + localFeeData.xFeeL + localFeeData.xFeeI + localFeeData.xFeeW + localFeeData.xFeeG;
			localFeeData.xDiagnosisFee =
				localFeeData.xFeeT + localFeeData.xFeeR + localFeeData.xFeeF + localFeeData.xFeeP + localFeeData.xFeeA;
			localFeeData.xEQFee = localFeeData.xFeeE + localFeeData.xFeeQ + localFeeData.xFeeX;
			localFeeData.xFeePH = 358;
			// FeeAll = FeeHG + FeeCO + FeeLPay + FeeMoney + FeeHD + FeeHE + FeeAdd;
			localFeeData.mFeeS = 0;
			localFeeData.xFeeM = localFeeData.xFeeM;
			localFeeData.xAllFee =
				localFeeData.xFeePH + localFeeData.mDiagnosisFee + localFeeData.xDrugFee + localFeeData.xEQFee + localFeeData.xFeeM;
		} else {
			localFeeData.mDrugFee = localFeeData.mFeeO + localFeeData.mFeeL + localFeeData.mFeeI + localFeeData.mFeeW + localFeeData.mFeeG;
			localFeeData.mDiagnosisFee =
				localFeeData.mFeeT + localFeeData.mFeeR + localFeeData.mFeeF + localFeeData.mFeeP + localFeeData.mFeeA;
			localFeeData.mEQFee = localFeeData.mFeeE + localFeeData.mFeeQ + localFeeData.mFeeX;
			localFeeData.mFeeM = localFeeData.mFeeM;
			localFeeData.mFeePH = 358;
			checkHighDrugFee(localFeeData.mDrugFee);
			checkHighEQFee(localFeeData.mEQFee);
			if (localFeeData.mDrugFee <= 66) {
				if (maxDay === 1) {
					localFeeData.mDrugFee = 22;
				} else if (maxDay === 2) {
					localFeeData.mDrugFee = 44;
				} else if (maxDay === 3) {
					localFeeData.mDrugFee = 66;
				}
			}

			// console.log(localFeeData.mDrugFee, caseType, '我要進到變66func');

			// console.log(isChronic, '是否慢性病 func');

			localFeeData.mAllFee =
				localFeeData.mFeePH +
				localFeeData.mDiagnosisFee +
				localFeeData.mDrugFee +
				localFeeData.mFeeS +
				localFeeData.mFeeEQ +
				localFeeData.mFeeM;
			// 診察費 診療費 藥費 藥事服務費 檢查檢驗費 材料費
		}
	} else if (treatmentInfo.prescription_style === 1) {
		// 明天這邊應該要調整
		caseType = "09";

		if (treatmentInfo.nhi_type_name == "自費") {
			localFeeData.DeliverDrugFee =
				localFeeData.mFeeO + localFeeData.mFeeL + localFeeData.mFeeI + localFeeData.mFeeW + localFeeData.mFeeG;
			localFeeData.xFeeO = 0;
			localFeeData.xFeePH = 358;
			localFeeData.xFeeM = localFeeData.xFeeM;
		} else {
			localFeeData.DeliverDrugFee =
				localFeeData.mFeeO + localFeeData.mFeeL + localFeeData.mFeeI + localFeeData.mFeeW + localFeeData.mFeeG;
			localFeeData.mDrugFee = localFeeData.mFeeI;
			localFeeData.DeliverEQFee = localFeeData.mFeeE + localFeeData.mFeeQ + localFeeData.mFeeX;
			localFeeData.mFeeO = 0;
			localFeeData.mFeePH = 358;
			localFeeData.mFeeF = localFeeData.mFeeM;
			checkHighDrugFee(localFeeData.mDrugFee);
			checkHighEQFee(localFeeData.mEQFee);

			localFeeData.mAllFee =
				localFeeData.mFeePH +
				localFeeData.mDiagnosisFee +
				localFeeData.mDrugFee +
				localFeeData.mFeeS +
				localFeeData.mFeeEQ +
				localFeeData.mFeeM;
			// 診察費 診療費 藥費 藥事服務費 檢查檢驗費 材料費
		}
	} else if (treatmentInfo.prescription_style === 2) {
		if (treatmentInfo.nhi_type_name == "自費") {
			localFeeData.xDrugFee = localFeeData.xFeeO + localFeeData.xFeeL + localFeeData.xFeeI + localFeeData.xFeeW + localFeeData.xFeeG;
			localFeeData.xDiagnosisFee =
				localFeeData.xFeeT + localFeeData.xFeeR + localFeeData.xFeeF + localFeeData.xFeeP + localFeeData.xFeeA;
			localFeeData.xEQFee = localFeeData.xFeeE + localFeeData.xFeeQ + localFeeData.xFeeX;
			localFeeData.xFeePH = 358;
			localFeeData.xFeeM = localFeeData.xFeeM;
		} else {
			localFeeData.mDrugFee = localFeeData.mFeeO + localFeeData.mFeeL + localFeeData.mFeeI + localFeeData.mFeeW + localFeeData.mFeeG;
			localFeeData.mDiagnosisFee =
				localFeeData.mFeeT + localFeeData.mFeeR + localFeeData.mFeeF + localFeeData.mFeeP + localFeeData.mFeeA;
			localFeeData.mEQFee = localFeeData.mFeeE + localFeeData.mFeeQ + localFeeData.mFeeX;
			localFeeData.mFeeM = localFeeData.mFeeM;
			localFeeData.mFeePH = 358;
			checkHighDrugFee(localFeeData.mDrugFee);
			checkHighEQFee(localFeeData.mEQFee);
			// if(localFeeData.mDrugFee < 66) caseType = '01'
			if (localFeeData.mDrugFee <= 66) {
				if (maxDay === 1) {
					localFeeData.mDrugFee = 22;
				} else if (maxDay === 2) {
					localFeeData.mDrugFee = 44;
				} else if (maxDay === 3) {
					localFeeData.mDrugFee = 66;
				}
			}
			// console.log(localFeeData.mDrugFee, caseType, '我要進到變66func');

			// console.log(isChronic, '是否慢性病 func');

			localFeeData.mAllFee =
				localFeeData.mFeePH +
				localFeeData.mDiagnosisFee +
				localFeeData.mDrugFee +
				localFeeData.mFeeS +
				localFeeData.mFeeEQ +
				localFeeData.mFeeM;
			// 診察費 診療費 藥費 藥事服務費 檢查檢驗費 材料費
		}
	}

	const doseDay = getDoseDay(prescriptionList, drugTypeList);
	if (
		treatmentInfo.case_type === "04" &&
		treatmentInfo.refillable?.refill_times &&
		treatmentInfo.refillable.refill_times > 0 &&
		Number(doseDay) >= 21
	) {
		localFeeData.mAllFee -= localFeeData.mFeeHD;
		localFeeData.mFeeHD = 0;
	}
	if (treatmentInfo.case_type === "08") {
		localFeeData.mAllFee -= localFeeData.mFeePH;
		localFeeData.mAllFee -= localFeeData.mFeeHD;
		localFeeData.mFeePH = 0;
		localFeeData.mFeeHD = 0;
	}
	if (treatmentInfo.prescription_style === 1) {
		localFeeData.mAllFee -= localFeeData.mDrugFee;
		localFeeData.mAllFee -= localFeeData.mFeeS;
		localFeeData.mDrugFee = 0;
		localFeeData.mFeeS = 0;
	}
	// console.log('最終案件類型', caseType, localFeeData.mDrugFee, '藥費 , func');
	setFeeData(localFeeData);
	// return caseType;
};
