export const calculateAge = (birthdate: Date): string => {
	const birthDate = new Date(birthdate);
	const today = new Date();

	let ageYears = today.getFullYear() - birthDate.getFullYear();
	let ageMonths = today.getMonth() - birthDate.getMonth();

	if (ageMonths < 0 || (ageMonths === 0 && today.getDate() < birthDate.getDate())) {
		ageYears--;
		ageMonths += 12;
	}

	if (today.getDate() < birthDate.getDate()) {
		ageMonths--;
	}

	ageMonths = ageMonths % 12;

	return `${ageYears}歲${ageMonths}月`;
};
