import Swal from "sweetalert2";
import { hisGetCardStatus2 } from "utils/hisGetCardStatus2";

export const checkCardStatus = async (CardType: string) => {
	let hisGetCardStatusResult;
	try {
		hisGetCardStatusResult = await hisGetCardStatus2(CardType);

		if (
			hisGetCardStatusResult === "2" ||
			hisGetCardStatusResult === "3" ||
			hisGetCardStatusResult === "4" ||
			hisGetCardStatusResult === "5"
		) {
			return { result: "success" };
		} else if (hisGetCardStatusResult === "0") {
			return { result: "fail", msg: "卡片未插入" };
		} else {
			return { result: "fail", msg: "請檢查代理人是否正確" };
		}
	} catch (error) {
		Swal.fire({
			icon: "error",
			title: "卡片狀態異常",
			text: "請檢查卡片是否正確插入",
			showConfirmButton: true
		});
		return { result: "fail" };
	}
};
