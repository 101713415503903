import { create } from "zustand";

interface DeclareTransferLoadingStatusState {
	isTransferLoading: boolean;
	setIsTransferLoading: (status: boolean) => void;
	resetIsTransferLoading: () => void;
}

export const useDeclareTransferLoadingStatusStore = create<DeclareTransferLoadingStatusState>(set => ({
	isTransferLoading: false,
	setIsTransferLoading: status => set({ isTransferLoading: status }),
	resetIsTransferLoading: () => set({ isTransferLoading: false })
}));
