import { Box, Flex, Text, Input, Select, List } from '@chakra-ui/react';
import { useEffect, useState, ChangeEvent } from 'react';
import { useUnsavedChanges } from 'contexts/UnsavedChangesContext';
import moment from 'moment';
import React from 'react';
import { useUserStore } from 'store';
import {
    useVitalSignsDataStore,
    VitalSignsDataProps,
} from 'store/Registration';
import {
    callVitalSignSettingListsService,
    vitalSignSettingListsProps,
} from 'service/vitalSignSettingListsService';
import { useQuery } from 'react-query';
import {
    useCurrentVisitInfoStore,
    vitalSignDataProps,
} from 'store/Registration';

import { Sign } from './VitalSignComponents/Sign';

export const VitalSigns = () => {
    const {userInfo} = useUserStore()
    const { vitalSignsData, updateVitalSignsData } = useVitalSignsDataStore();
    const { currentVisitInfoData, updateCurrentVisitInfoData } =
        useCurrentVisitInfoStore();
    const [vitalSignsSettingLists, setVitalSignSettingLists] = useState<
        vitalSignSettingListsProps[]
    >([]);
    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const vitalSignUid = vitalSignsSettingLists.find(
            item => item.name === name
        )?.id;

        if (vitalSignUid) {
            const existingIndex = currentVisitInfoData.vital_sign?.findIndex(
                vs => vs.vital_sign_uid === vitalSignUid
            );

            if (existingIndex !== -1 && existingIndex !== undefined) {
                // 修改陣列
                if (value) {
                    const updatedVitalSigns =
                        currentVisitInfoData.vital_sign?.map((item, index) =>
                            index === existingIndex
                                ? { ...item, value: value }
                                : item
                        );
                    updateCurrentVisitInfoData({
                        vital_sign: updatedVitalSigns,
                    });
                } else {
                    // 沒有 value 就是刪除
                    const updatedVitalSigns =
                        currentVisitInfoData.vital_sign?.filter(
                            (item, index) => index !== existingIndex
                        );
                    updateCurrentVisitInfoData({
                        vital_sign: updatedVitalSigns,
                    });
                }
            } else {
                if (value) {
                    // 新增陣列
                    const newVitalSign = {
                        vital_sign_uid: vitalSignUid,
                        value: value,
                    };
                    const updatedVitalSigns = currentVisitInfoData.vital_sign
                        ? [...currentVisitInfoData.vital_sign, newVitalSign]
                        : [newVitalSign];
                    updateCurrentVisitInfoData({
                        vital_sign: updatedVitalSigns,
                    });
                }
            }
        }
    };

    const {
        data: vitalSignSettingListsData,
        isSuccess: isVitalSignSettingListsSuccess,
    } = useQuery(
        'vitalSignSettingLists',
        () => callVitalSignSettingListsService(userInfo.token!),
        {
            enabled: !!userInfo.token,
            staleTime: Infinity,
        }
    );

    useEffect(() => {
        if (isVitalSignSettingListsSuccess) {
            setVitalSignSettingLists(vitalSignSettingListsData.lists);
        }
    }, [isVitalSignSettingListsSuccess]);

    return (
        <>
            <Flex
                minW={'250px'}
                p={'16px'}
                rounded={'15px'}
                bg={'gray.250'}
                borderBottom={'2px solid #ccc'}
                direction={'column'}
                gap={'4px'}
            >
                <Text textStyle={'semiTitle'} mb={'8px'}>
                    基礎體徵
                </Text>
                <List>
                    {vitalSignsSettingLists &&
                        vitalSignsSettingLists.map((item, index) => {
                            const currentVitalSign =
                                currentVisitInfoData.vital_sign?.find(
                                    vs => vs.vital_sign_uid === item.id
                                );
                            const value = currentVitalSign
                                ? currentVitalSign.value
                                : '';
                            return (
                                <Sign
                                    key={index}
                                    item={item}
                                    index={index}
                                    value={value ?? ''}
                                    onChange={handleChangeInput}
                                />
                            );
                        })}
                </List>
            </Flex>
        </>
    );
};
