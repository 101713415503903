import { Box, Flex, Text } from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import { FormMaximizeButton } from "./FormMaximizeButton";

export const FormHeader = () => {
	return (
		<>
			<Flex
				gap={"4px"}
				borderBottom={"1px solid rgba(100, 107, 72, 0.4)"}
				pos={"sticky"}
				top={0}
				left={0}
				bg={"#fff"}
				zIndex={10}
				p={"8px 12px"}
				boxShadow={"inset 4px 2px 2px rgba(0,0,0,0.25)"}>
				<Text textAlign={"center"} textColor={"#646B48"} fontWeight={"semibold"} flex={"0 0 32px"}>
					排序
				</Text>
				<Text textAlign={"center"} textColor={"#646B48"} fontWeight={"semibold"} flex={"1 1 30%"}>
					Rx.處方名稱
				</Text>
				<Text textAlign={"center"} textColor={"#646B48"} fontWeight={"semibold"} flex={"0 0 6%"}>
					次量
				</Text>

				<Text textAlign={"center"} textColor={"#646B48"} fontWeight={"semibold"} flex={"0 0 6%"}>
					用法
				</Text>
				<Text textAlign={"center"} textColor={"#646B48"} fontWeight={"semibold"} flex={"0 0 6%"}>
					天數
				</Text>
				<Text textAlign={"center"} textColor={"#646B48"} fontWeight={"semibold"} flex={"0 0 6%"}>
					日量
				</Text>
				<Text textAlign={"center"} textColor={"#646B48"} fontWeight={"semibold"} flex={"0 0 6%"}>
					總量
				</Text>
				<Text textAlign={"center"} textColor={"#646B48"} fontWeight={"semibold"} flex={"0 0 6%"}>
					發藥量
				</Text>
				<Text textAlign={"center"} textColor={"#646B48"} fontWeight={"semibold"} flex={"0 0 6%"}>
					自費價
				</Text>
				<Text textAlign={"center"} textColor={"#646B48"} fontWeight={"semibold"} flex={"0 0 6%"}>
					Ｍ
				</Text>
				<Text textAlign={"center"} textColor={"#646B48"} fontWeight={"semibold"} flex={"0 0 6%"}>
					備註
				</Text>
				<Text textAlign={"center"} textColor={"#646B48"} fontWeight={"semibold"} flex={"0 0 30px"}>
					{/* 特殊 */}
				</Text>
				<FormMaximizeButton />
			</Flex>
		</>
	);
};
