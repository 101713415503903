import axios from "axios";
import apiService from "./apiService";
import axiosInstance from "./axiosInstance";

// 取得藥品相關設定
export const callDrugSpecificationsService = async (token: string, data: string) => {
	const queryParams = data ? { data } : undefined;
	return apiService<string, any>({
		method: "GET",
		path: "/api/drug/specifications",
		token,
		data: queryParams
	});
};
// export const callDrugSpecificationsService = async (
//     token: string,
//     id: string
// ) => {
//     try {
//         const queryParams = { id };
//         const headers = {
//             'Api-Token': `${token}`,
//         };
//         const response = await axios.get('/api/drug/specifications', {
//             headers,
//             params: queryParams,
//         });
//         const result = response.data;
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };

interface relatedSettingDataProps {
	drug_in_use_uid: string | null;
	bottle_package: string | null;
	package_capacity: string | null;
	package_unit: string | null;
	package_qty: string | null;
	administrationRoute: string | null;
	check_insure_status: string | null;
	exam_declare: string | null;
	drg_code: string | null;
	ingredientCode: string | null;
	substitute: string | null;
	specific_part: string | null;
	treatment_code: string | null;
	deliver_exam_type: string | null;
	deliver_exam_lab: string | null;
	lab_name: string | null;
}
// 增修藥品相關設定
export const postDrugSpecificationsService = async (token: string, updatedData: relatedSettingDataProps) => {
	return apiService({
		method: "POST",
		path: "/api/drug/specifications",
		token,
		data: updatedData
	});
};
// export const postDrugSpecificationsService = async (
//     token: string,
//     updatedData?: relatedSettingDataProps
// ) => {
//     try {
//         const requestBody = JSON.stringify(updatedData);

//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         console.log(requestBody, 'requestBody service印的');

//         const response = await axiosInstance.post(
//             '/api/drug/specifications',
//             requestBody,
//             {
//                 headers,
//             }
//         );

//         const result = response.data;

//         console.log(result, 'API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
