import { create } from "zustand";

export type VirtualCardSearchWayProps = {
	searchWay: "qrCode" | "rocId";
};

const initialVirtualCardSearchWay: VirtualCardSearchWayProps = {
	searchWay: "rocId"
};

export type VirtualCardSearchWayState = {
	virtualCardSearchWay: VirtualCardSearchWayProps;
	updateVirtualCardSearchWay: (searchWay: "qrCode" | "rocId") => void;
};

export const useVirtualCardSearchWayStore = create<VirtualCardSearchWayState>(set => ({
	virtualCardSearchWay: initialVirtualCardSearchWay,
	updateVirtualCardSearchWay: searchWay => set({ virtualCardSearchWay: { searchWay } })
}));
