import apiService from "./apiService";

export type specificTreatmentListsProps = {
	code: string;
	name: string;
	explanation: string;
};

// 取得常用部分負擔列表
export const callSpecificTreatmentListsService = async (token: string) => {
	return apiService<specificTreatmentListsProps, any>({
		method: "GET",
		path: "/api/specific_treatment/lists",
		token
	});
};
