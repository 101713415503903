import { PrescriptionProps } from "store/TreatmentManagement";
import { TreatmentInfoProps } from "store/TreatmentManagement/useTreatmentInfoStore";

interface M24_M29Result {
	refill_a: string | null;
	refill_b: string | null;
	refill_c: string | null;
	refill_d: string | null;
	refill_e: string | null;
	refill_f: string | null;
	r_dose_days: number;
	c_dose_days: number;
}

export const M24_M29Logic = (prescriptionList: PrescriptionProps[], treatmentInfo: TreatmentInfoProps): M24_M29Result => {
	let refill_a = null;
	let refill_b = null;
	let refill_c = null;
	let refill_d = null;
	let refill_e = null;
	let refill_f = null;
	let r_dose_days = 0;
	let c_dose_days = 0;

	if (treatmentInfo.case_type === "08") {
		prescriptionList.forEach(item => {
			if (!item.controlled_level) {
				// 一般藥
				refill_c = treatmentInfo.card_no.split("").pop() || null;
				if (Number(item.dose_day) > r_dose_days) {
					r_dose_days = Number(item.dose_day) * Number(treatmentInfo.refillable?.refill_times);
				}
			} else {
				refill_f = treatmentInfo.card_no.split("").pop() || null;
				if (Number(item.dose_day) > c_dose_days) {
					c_dose_days = Number(item.dose_day) * Number(treatmentInfo.refillable?.refill_times);
				}
			}
		});
	} else {
		prescriptionList.forEach(item => {
			if (!item.controlled_level) {
				// 一般藥
				if (Number(item.dose_day) < 7) {
					refill_a = "1";
					return;
				} else if (Number(item.dose_day) >= 7 && item.if_chronic === "1") {
					refill_c = treatmentInfo.refillable?.refill_times?.toString();
					if (Number(item.dose_day) > r_dose_days) {
						r_dose_days = Number(item.dose_day) * Number(treatmentInfo.refillable?.refill_times);
					}
					return;
				} else if (Number(item.dose_day) > 7) {
					refill_b = "1";
					return;
				}
			} else {
				// 管制藥
				if (Number(item.dose_day) < 7) {
					refill_d = "1";
					return;
				} else if (
					Number(item.dose_day) >= 7 &&
					item.if_chronic === "1" &&
					item.controlled_level !== "1" &&
					item.controlled_level !== "2"
				) {
					refill_f = treatmentInfo.refillable?.refill_times?.toString();
					if (Number(item.dose_day) > c_dose_days) {
						c_dose_days = Number(item.dose_day) * Number(treatmentInfo.refillable?.refill_times);
					}
					return;
				} else if (Number(item.dose_day) > 7) {
					refill_e = "1";
					return;
				}
			}
		});
	}

	return {
		refill_a,
		refill_b,
		refill_c,
		refill_d,
		refill_e,
		refill_f,
		r_dose_days,
		c_dose_days
	};
};
