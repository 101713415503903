import { callIcDataDlXMLService, IcDataDlXMLReqDataProps } from "service/icDataDlXMLService";
import { useUserStore } from "store";
import Swal from "sweetalert2";

export const useIcDataDlXMLAPI = () => {
	const { userInfo } = useUserStore();

	const callIcDataDlXMLAPI = async (data?: IcDataDlXMLReqDataProps) => {
		try {
			const response = await callIcDataDlXMLService(userInfo.token, data);

			if (response) {
				const blob = new Blob([response], {
					type: "application/octet-stream"
				});

				if (blob instanceof Blob) {
					console.log("成功創建 Blob 對象");
					console.log("blob size:", blob.size);

					const urlObject = window.URL.createObjectURL(blob);
					const a = document.createElement("a");
					a.href = urlObject;
					a.download = "ic_data.xml";
					document.body.appendChild(a);
					a.click();
					a.remove();
					window.URL.revokeObjectURL(urlObject);
				} else {
					console.log("response is null");
					Swal.fire({
						icon: "info",
						title: "blob 檔案損毀",
						confirmButtonText: "確定"
					});
				}
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			Swal.fire({
				icon: "error",
				title: "下載過程發生錯誤",
				confirmButtonText: "確定"
			});
		}
	};
	return { callIcDataDlXMLAPI };
};
