import { create } from "zustand";

// 定義 MedicalRecordDiagModelProps 接口
export interface MedicalRecordDiagModelProps {
	// IDiagBase
	Diag_Code: string; // 診斷代碼
	Diag_Name?: string; // 診斷名稱
	E_Diag_Name?: string; // 英文診斷名稱
	ICD9?: string; // ICD-9
	ICD10?: string; // ICD-10
	Chronic?: string; // 慢性病碼
	// IMedicalRecordDiag
	Seq?: number; // 順序
	DS?: string; // 左右側
	DOC?: string; // 醫囑/衛教
}

// 定義 MedicalRecordDiagModelStore 接口
interface MedicalRecordDiagModelStore {
	diagModels: MedicalRecordDiagModelProps[];
	updateDiagModel: (index: number, diagModel: MedicalRecordDiagModelProps) => void;
	addDiagModel: (diagModel: MedicalRecordDiagModelProps) => void;
	resetDiagModels: () => void;
}

// 初始化 diagModel 的值
export const initialDiagModel: MedicalRecordDiagModelProps = {
	// IDiagBase
	Diag_Code: "",
	Diag_Name: "",
	E_Diag_Name: "",
	ICD9: "",
	ICD10: "",
	Chronic: "",
	// IMedicalRecordDiag
	Seq: 0,
	DS: "",
	DOC: ""
};

// 創建 useMedicalRecordDiagModelStore
export const useMedicalRecordDiagModelStore = create<MedicalRecordDiagModelStore>(set => ({
	diagModels: [],
	updateDiagModel: (index, diagModel) =>
		set(state => {
			const newDiagModels = [...state.diagModels];
			newDiagModels[index] = diagModel;
			return { diagModels: newDiagModels };
		}),
	addDiagModel: diagModel =>
		set(state => ({
			diagModels: [...state.diagModels, diagModel]
		})),
	resetDiagModels: () =>
		set(() => ({
			diagModels: []
		}))
}));

export default useMedicalRecordDiagModelStore;
