import { ConsultingRoom } from 'components/TreatmentManagement/ConsultingRoomComponents';
import { IsHistoryLoadingProvider } from 'contexts/TreatmentManagement/MedicalRecord/IsHistoryLoadingContext';

// Contexts
import { IsPartCopyProvider } from 'contexts/TreatmentManagement/MedicalRecord';
import {
    IsFormMaximizeProvider,
    RightSideOptionSelectProvider,
    PrescriptionInputRefsProvider,
    PrescriptionProvider,
    IsSearchListsProvider,
} from 'contexts/TreatmentManagement/Prescription';
import {
    IsBillingOpenProvider,
    IsReferralOpenProvider,
} from 'contexts/TreatmentManagement/BillingProcess';
import { ComputeFeeProvider } from 'contexts/TreatmentManagement/ComputeFeeContext';
import { IsCopyDrugFromHistoryProvider } from 'contexts/TreatmentManagement/IsCopyDrugFromHistoryContext'; 
import { CalculationCompleteProvider } from 'contexts/TreatmentManagement/CalculationCompleteContext';
import { ShowAbnormalButtonStatusProvider } from 'contexts/TreatmentManagement/BillingProcess/ShowAbnormalButtonStatusContext';
import { IsChooseAbnormalButtonStatusProvider } from 'contexts/TreatmentManagement/BillingProcess/IsChooseAbnormalButtonStatusContext';

export default function ConsultingRoomPage() {
    return (
        <>
            <PrescriptionInputRefsProvider>
                <RightSideOptionSelectProvider>
                    <IsFormMaximizeProvider>
                        <IsPartCopyProvider>
                            <PrescriptionProvider>
                                <IsSearchListsProvider>
                                    <IsHistoryLoadingProvider>
                                        <IsBillingOpenProvider>
                                            <IsReferralOpenProvider>
                                                <ComputeFeeProvider>
                                                    <IsCopyDrugFromHistoryProvider>
                                                        <CalculationCompleteProvider>
                                                            <ShowAbnormalButtonStatusProvider>
                                                                <IsChooseAbnormalButtonStatusProvider>
                                                                        <ConsultingRoom />
                                                                </IsChooseAbnormalButtonStatusProvider>
                                                            </ShowAbnormalButtonStatusProvider>
                                                        </CalculationCompleteProvider>
                                                    </IsCopyDrugFromHistoryProvider>
                                                </ComputeFeeProvider>
                                            </IsReferralOpenProvider>
                                        </IsBillingOpenProvider>
                                    </IsHistoryLoadingProvider>
                                </IsSearchListsProvider>
                            </PrescriptionProvider>
                        </IsPartCopyProvider>
                    </IsFormMaximizeProvider>
                </RightSideOptionSelectProvider>
            </PrescriptionInputRefsProvider>
        </>
    );
}
