import React, { createContext, useContext, useState, ReactNode } from "react";

interface PrescriptionIndexContextValue {
	rowIndexStatus: number;
	colIndexStatus: number;
	setRowIndexStatus: React.Dispatch<React.SetStateAction<number>>;
	setColIndexStatus: React.Dispatch<React.SetStateAction<number>>;
}

const PrescriptionIndexContext = createContext<PrescriptionIndexContextValue | undefined>(undefined);

interface PrescriptionProviderProps {
	children: ReactNode;
}

export const PrescriptionProvider: React.FC<PrescriptionProviderProps> = ({ children }) => {
	const [rowIndexStatus, setRowIndexStatus] = useState<number>(0);
	const [colIndexStatus, setColIndexStatus] = useState<number>(0);

	return (
		<PrescriptionIndexContext.Provider value={{ rowIndexStatus, colIndexStatus, setRowIndexStatus, setColIndexStatus }}>
			{children}
		</PrescriptionIndexContext.Provider>
	);
};

export const usePrescriptionIndexContext = (): PrescriptionIndexContextValue => {
	const context = useContext(PrescriptionIndexContext);
	if (!context) {
		throw new Error("usePrescriptionIndexStatusContext must be used within a PrescriptionProvider");
	}
	return context;
};
