import axios from "axios";
import apiService from "./apiService";
// 模糊搜尋公告藥檔

interface dataProps {
	keyword: string;
}
export const postDrugsOfficialService = async (token: string, data: dataProps) => {
	return apiService<string, any>({
		method: "POST",
		path: "api/drugs/official",
		token,
		data: data
	});
};

// interface dataProps {
//     keyword: string;
// }
// export const postDrugsOfficialService = async (
//     token: string,
//     finalData: dataProps
// ) => {
//     try {
//         const requestBody = JSON.stringify(finalData);
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         const response = await axios.post('/api/drugs/official', requestBody, {
//             headers,
//         });
//         const result = response.data;

//         console.log(result, 'API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
