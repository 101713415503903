import React, { useState, useEffect } from "react";
import { Box, Flex, Text, List, ListItem, Tooltip } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer } from "@chakra-ui/react";
import { InsetShadowBox } from "style/chakraStyles";
import { textEllipsisOverflow } from "style/chakraStyles/textStyles";
import { useUserStore } from "store";
import { useDropDownMenuQuery } from "query/useDropDownMenuQuery";
import { membershipProps } from "service/memberShipService";

type ListProps = {
	name?: string;
	code?: string;
};

import { usePatientInfoStore } from "store/Registration";

export const MedicalHistory = () => {
	const { patientInfo } = usePatientInfoStore();

	const Items = {
		w: "240px",
		p: "4px 8px",
		gap: "24px",
		align: "center"
	};

	// const list: ListProps[] = [
	//     {
	//         name: 'URI急性上呼吸道感染',
	//         code: 'J069',
	//     },
	//     {
	//         name: '急性扁桃腺炎',
	//         code: 'J0390',
	//     },
	//     {
	//         name: '激躁性腸症候群未伴有腹瀉',
	//         code: 'K581',
	//     },
	//     {
	//         name: '功能性消化不良',
	//         code: 'K30',
	//     },
	//     {
	//         name: '急性胃炎未伴有出血',
	//         code: 'K2900',
	//     },
	//     {
	//         name: '花粉所致過敏性鼻炎',
	//         code: 'J301',
	//     },
	// ];
	const list: ListProps[] = [];

	const { userInfo } = useUserStore();

	const [membershipLists, setMembershipLists] = useState<membershipProps[]>([]);
	const { data: membershipData, isSuccess: isMembershipSuccess } = useDropDownMenuQuery("membership", userInfo.token!);

	useEffect(() => {
		if (isMembershipSuccess) {
			setMembershipLists(membershipData.lists);
		}
	}, [isMembershipSuccess]);
	return (
		<>
			<Box w={"100%"} sx={InsetShadowBox}>
				<Flex w={"100%"} justify={"space-between"} flexWrap={"wrap"} py={"12px"} rowGap={"24px"}>
					<Flex gap={"12px"} flex={"0 1 50%"} overflow={"hidden"}>
						<Box>
							<Flex sx={Items}>
								<Text textStyle={"semiTitle"} w={"80px"}>
									病歷號
								</Text>
								<Text w={"120px"}> {patientInfo.info.medical_record_number} </Text>
							</Flex>
							<Flex sx={Items}>
								<Text textStyle={"semiTitle"} w={"80px"}>
									姓名
								</Text>
								<Text w={"120px"}> {patientInfo.info.name} </Text>
							</Flex>

							<Flex sx={Items}>
								<Text textStyle={"semiTitle"} w={"80px"}>
									身分證號
								</Text>
								<Text w={"120px"}> {patientInfo.info.id_no} </Text>
							</Flex>
							<Flex sx={Items}>
								<Text textStyle={"semiTitle"} w={"80px"}>
									體重
								</Text>
								<Text w={"120px"}> {} </Text>
							</Flex>
							<Flex sx={Items}>
								<Text textStyle={"semiTitle"} w={"80px"}>
									優免身份
								</Text>
								<Text w={"120px"}>
									{membershipLists &&
										(membershipLists?.find(item => item.data_id === patientInfo.info.membership)?.name ?? "")}
								</Text>
							</Flex>
						</Box>
						<Box>
							<Flex sx={Items}>
								<Text textStyle={"semiTitle"} w={"80px"}>
									診病日
								</Text>
								<Text w={"120px"}>{/* 113.07.08 */}</Text>
							</Flex>
							<Flex sx={Items}>
								<Text textStyle={"semiTitle"} w={"80px"}>
									科別／醫師
								</Text>
								<Text w={"120px"}>{/* 家醫科／李正信  */}</Text>
							</Flex>
							<Flex sx={Items}>
								<Text textStyle={"semiTitle"} w={"80px"}>
									班別
								</Text>
								<Text w={"120px"}>{/* 晚診  */}</Text>
							</Flex>
							<Flex sx={Items}>
								<Text textStyle={"semiTitle"} w={"80px"}>
									診間
								</Text>
								<Text w={"120px"}>{/* 3 診  */}</Text>
							</Flex>
							<Flex sx={Items}>
								<Text textStyle={"semiTitle"} w={"80px"}>
									就醫序號
								</Text>
								<Text w={"120px"}>{/* 0023 */}</Text>
							</Flex>
						</Box>
					</Flex>
					<Box flex={"0 1 50%"} overflow={"hidden"}>
						<TableContainer h={"160px"} boxShadow={"md"} overflowY={"scroll"}>
							<Table variant='striped' size={"sm"} pr={"20px"}>
								<Thead bg={"#D9D9D9"} pos={"sticky"} top={0} left={0} zIndex={10}>
									<Tr>
										<Th>
											<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
												日期
											</Text>
										</Th>
										<Th>
											<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
												科別
											</Text>
										</Th>
										<Th>
											<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
												醫師
											</Text>
										</Th>
										<Th>
											<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
												診斷
											</Text>
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									<Tr>
										<Td>
											<Text w={"72px"} sx={textEllipsisOverflow}>
												{/* 113.07.08 */}
											</Text>
										</Td>
										<Td>
											<Text w={"32px"} sx={textEllipsisOverflow}>
												{/* 01 */}
											</Text>
										</Td>
										<Td>
											<Text w={"64px"} sx={textEllipsisOverflow}>
												{/* 李阿信 */}
											</Text>
										</Td>
										<Td>
											<Text w={"220px"} sx={textEllipsisOverflow}>
												{/* 急性上呼吸道感染急性上呼吸道感染
                                                急性上呼吸道感染 */}
											</Text>
										</Td>
									</Tr>
								</Tbody>
							</Table>
						</TableContainer>
					</Box>
				</Flex>
				<Flex mt={"24px"}>
					<Box flex={"0 1 50%"} pr={"16px"}>
						<Box>
							<Text textStyle={"semiTitle"} py={"8px"}>
								CC / PE
							</Text>
							<Box sx={InsetShadowBox} h={"200px"} overflowY={"auto"}>
								{/* Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Obcaecati ipsum adipisci enim
                                necessitatibus aut rem itaque unde! Maxime fuga
                                eos beatae, sed, laudantium iusto neque unde ex */}
							</Box>
						</Box>

						<Box>
							<Text textStyle={"semiTitle"} py={"8px"}>
								Dx（ ICD10 ）
							</Text>
							<List h={"212px"} overflow={"hidden"}>
								{list &&
									list.map((item, index) => (
										<ListItem
											key={index}
											bg={index === 0 ? "rsDanger.500" : "#BFC6A2"}
											textColor={index === 0 ? "#fff" : "#000"}
											p={"4px 8px"}
											mb={"4px"}
											rounded={"md"}>
											{item.name} {item.code}
										</ListItem>
									))}
							</List>
						</Box>
					</Box>
					<Box flex={"0 1 50%"}>
						<TableContainer h={"492px"} overflowY={"scroll"} boxShadow={"md"}>
							<Table variant={"striped"} size={"sm"}>
								<Thead bg={"#D9D9D9"} position={"sticky"} top={0} left={0} zIndex={10}>
									<Tr>
										<Th>
											<Text textStyle={"semiTitle"}>代碼</Text>
										</Th>
										<Th>
											<Text textStyle={"semiTitle"} w={"100px"}>
												Rx.處方名稱
											</Text>
										</Th>
										<Th>
											<Text textStyle={"semiTitle"}>次量</Text>
										</Th>
										<Th>
											<Text textStyle={"semiTitle"}>頻率</Text>
										</Th>
										<Th>
											<Text textStyle={"semiTitle"}>天數</Text>
										</Th>
										<Th>
											<Text textStyle={"semiTitle"}>總量</Text>
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									<Tr>
										{/* <Td>VO25</Td>
                                        <Td>
                                            <Tooltip
                                                openDelay={500}
                                                hasArrow
                                                label={
                                                    'Voren 25mg Lorem ipsum dolorsit amet consecteturadipisicing elit. Saepefugit, dolore delectus quosadipisci maiores accusamusquo quas unde hic?'
                                                }
                                            >
                                                <Text
                                                    w={'100px'}
                                                    sx={textEllipsisOverflow}
                                                >
                                                    Voren 25mg Lorem ipsum dolor
                                                    sit amet consectetur
                                                    adipisicing elit. Saepe
                                                    fugit, dolore delectus quos
                                                    adipisci maiores accusamus
                                                    quo quas unde hic?
                                                </Text>
                                            </Tooltip>
                                        </Td>
                                        <Td>1</Td>
                                        <Td>QID</Td>
                                        <Td>3</Td>
                                        <Td>12</Td> */}
										<Td></Td>
										<Td></Td>
										<Td></Td>
										<Td></Td>
										<Td></Td>
									</Tr>
								</Tbody>
							</Table>
						</TableContainer>
					</Box>
				</Flex>
			</Box>
		</>
	);
};
