import { create } from "zustand";

export interface cashDataStoreProps {
	registrationFee: number; //掛號費
	deductibleFee: number; // 部分負擔 // 自付額
	depositFee: number; //押單費
	receivableFee: number; // 應付金額
	discount: number; // 折讓
	actualFee: number; // 實收金額
}

const initialCashDataStoreItem: cashDataStoreProps = {
	registrationFee: 0,
	deductibleFee: 0,
	depositFee: 0,
	receivableFee: 0,
	discount: 0,
	actualFee: 0
};

interface cashDataStoreState {
	cashDataStore: cashDataStoreProps;
	updateCashDataStore: (cashDataStore: Partial<cashDataStoreProps>) => void;
	resetCashDataStore: () => void;
}

export const useCashDataStore = create<cashDataStoreState>(set => ({
	cashDataStore: initialCashDataStoreItem,
	updateCashDataStore: updatedFields =>
		set(state => ({
			cashDataStore: { ...state.cashDataStore, ...updatedFields }
		})),
	resetCashDataStore: () => set({ cashDataStore: initialCashDataStoreItem })
}));
