import React, { FC, useState, useEffect } from "react";
import { Tbody, Tr, Td, Box, Text, Button, Tooltip, Flex } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import { HeroIcons } from "../../CustomComponents/CustomHeroIcons";
import { useDeclareListTabIndexStore } from "store/DeclarationAudit/useDeclareListTabIndexStore";
import { useDeclareListSelectedItemStore } from "store/DeclarationAudit/useDeclareListSelectedItemStore";
import { useDeclareListLoadingStatusStore } from "store/DeclarationAudit/useDeclareListLoadingStatusStore";
import { useGoToConsultingRoom } from "hooks/useGoToConsultingRoom";
import { DeclareListsItemProps } from "store/DeclarationAudit/useDeclareListsStore";

import Loading from "../../CustomComponents/Loading";

interface ListBodyProps {
	data: DeclareListsItemProps[];
}

export const ListBody: FC<ListBodyProps> = ({ data }) => {
	const { tabIndex } = useDeclareListTabIndexStore();
	const { selectedItem, setSelectedItem, resetSelectedItem } = useDeclareListSelectedItemStore();
	const { isLoading } = useDeclareListLoadingStatusStore();
	const [isMessageOpen, setIsMessageOpen] = useState(false);

	const { handleGoToConsultingRoom } = useGoToConsultingRoom();

	const handleRowClick = (item: DeclareListsItemProps) => {
		console.log(item.medical_record_uid);
		setSelectedItem(item);
	};

	const handleMessageToggle = (item: DeclareListsItemProps) => {
		console.log(item);
		setIsMessageOpen(!isMessageOpen);
	};

	useEffect(() => {
		resetSelectedItem();
	}, [tabIndex]);

	const formatTreatedAt = (treatedAt: string) => {
		const year = parseInt(treatedAt.slice(0, 4)) - 1911;
		const month = treatedAt.slice(5, 7);
		const day = treatedAt.slice(8, 10);
		return `${year}.${month}.${day}`;
	};

	const wrenchAnimation = keyframes`
    0% { transform: rotate(0deg); }
    12.5% { transform: rotate(10deg); }
    25% { transform: rotate(-10deg); }
    37.5% { transform: rotate(8deg); }
    50% { transform: rotate(-8deg); }
    62.5% { transform: rotate(5deg); }
    75% { transform: rotate(-5deg); }
    87.5% { transform: rotate(0deg); }
    100% { transform: rotate(0deg); }
`;

	return (
		<>
			{isLoading ? (
				<Tbody>
					<Tr>
						<Td colSpan={tabIndex === 0 ? 10 : tabIndex === 2 ? 11 : 1} textAlign={"center"} position={"relative"}>
							<Box minH={"300px"} bg={"gray.100"}>
								<Loading />
							</Box>
						</Td>
					</Tr>
				</Tbody>
			) : data.length === 0 ? (
				<Tbody>
					<Tr>
						<Td colSpan={tabIndex === 0 ? 10 : tabIndex === 2 ? 11 : 1} textAlign={"center"}>
							目前無資料
						</Td>
					</Tr>
				</Tbody>
			) : (
				<Tbody>
					{data.map((item, index) => (
						<Tooltip
							key={index}
							hasArrow
							label='此筆病歷尚無 IC 資料，請進入病歷並重新完診'
							bg='red.500'
							color='white'
							isDisabled={item.ic_data_uid !== null}>
							<Tr
								bg={(() => {
									const isSelected = selectedItem?.medical_record_uid === item.medical_record_uid;
									const hasNoIcData = item.ic_data_uid === null;

									if (isSelected && hasNoIcData) return "rgba(166, 0, 0, 0.5)";
									if (hasNoIcData) return "rgba(166,0,0,0.3)";
									if (isSelected) return "#BFC6A2";
									return "none";
								})()}
								onClick={() => handleRowClick(item)}
								_hover={{
									bg: item.ic_data_uid === null ? "rgba(166, 0, 0, 0.7)" : "#BFC6A299", // 調整背景色
									boxShadow: "inset 0 0 4px rgba(0, 0, 0, 0.2)", // 添加陰影
									textColor: item.ic_data_uid === null ? "white" : "black",
									cursor: "pointer"
								}}
								role='group'>
								<Td p={"4px 8px"}>
									<Box
										onClick={() => handleGoToConsultingRoom(item.opd_uid, "3")}
										_groupHover={{
											textColor: item.ic_data_uid === null ? "white" : "black"
										}}
										position={"relative"}>
										{item.ic_data_uid === null ? (
											<Box animation={`${wrenchAnimation} 2s ease-in-out infinite`}>
												<HeroIcons name='wrench' />
											</Box>
										) : (
											<Box>
												<HeroIcons name='read-record' />
											</Box>
										)}
									</Box>
								</Td>
								<Td p={"4px 8px"}>{item.case_type}</Td>
								<Td p={"4px 8px"} hidden={tabIndex !== 0}>
									{formatTreatedAt(item.treated_at)}
								</Td>
								<Td p={"4px 8px"}>{item.name}</Td>
								<Td p={"4px 8px"} hidden={tabIndex !== 0}>
									{item.id_no}
								</Td>
								<Td p={"4px 8px"}>{item.nhi_type}</Td>
								<Td p={"4px 8px"}>{item.medical_record_number}</Td>
								<Td p={"4px 8px"}>{item.main_diagnosis}</Td>
								<Td p={"4px 8px"} hidden={tabIndex !== 0}>
									{item.dose_days}
								</Td>
								<Td p={"4px 8px"}>{item.card_no}</Td>
								<Td p={"4px 8px"} hidden={tabIndex !== 2}>
									<Text
										onClick={() => handleMessageToggle(item)}
										_hover={{
											cursor: "pointer",
											textDecoration: "underline"
										}}
										whiteSpace={"nowrap"}
										overflow={"hidden"}
										textOverflow={"ellipsis"}
										maxWidth={"250px"}>
										{item.error_msg ?? ""}
									</Text>
								</Td>
							</Tr>
						</Tooltip>
					))}
				</Tbody>
			)}

			{isMessageOpen && (
				<Flex
					position={"fixed"}
					inset={0}
					bg={"rgba(0, 0, 0, 0.5)"}
					zIndex={100}
					justifyContent={"center"}
					alignItems={"center"}
					p={4}
					onClick={() => setIsMessageOpen(false)}>
					<Flex
						p={"20px"}
						bg={"white"}
						rounded={"lg"}
						w={"100%"}
						maxW={"800px"}
						h={"600px"}
						px={4}
						direction={"column"}
						gap={4}
						onClick={e => e.stopPropagation()}>
						<Flex w={"100%"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
							<Text textColor={"rsDanger.500"} fontWeight={"bold"}>
								錯誤訊息
							</Text>
							<Box
								onClick={() => setIsMessageOpen(false)}
								cursor={"pointer"}
								_hover={{
									opacity: 0.7
								}}>
								<HeroIcons name='xmark-sm' />
							</Box>
						</Flex>
						<Box h={"100%"} maxH={"calc(100% - 24px - 40px)"} overflowY={"auto"}>
							<Text>{selectedItem?.error_msg}</Text>
						</Box>
						<Box w={"100%"} display={"flex"} justifyContent={"flex-end"}>
							<Button variant={"outline"} onClick={() => setIsMessageOpen(false)}>
								關閉
							</Button>
						</Box>
					</Flex>
				</Flex>
			)}
		</>
	);
};
