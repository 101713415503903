import { callCustomerService } from './customerService';
import { useUserStore } from '../store';
import { useInstitutionInfoStore } from '../store/DataSetting/useInstitutionInfoStore';
import Swal from 'sweetalert2';

export const useCustomerAPI = () => {
    const { userInfo } = useUserStore();
    const { institutionInfoData,updateInstitutionInfoData } = useInstitutionInfoStore();
    const callCustomerAPI = async () => {
        if (institutionInfoData.customer_uid) {
            return;
        }
        if (userInfo.token) {
            try {
                const result = await callCustomerService(userInfo.token);
                if (result.result === 'success') {
                    updateInstitutionInfoData(result.info);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '取得院所基本資料API失敗',
                    showConfirmButton: false,
                });
            }
        }
    };
    return { callCustomerAPI };
};
