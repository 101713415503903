export const calculateAgeMonthNumber = (zDate: Date, birthDate: Date): { ageYear: number; ageMonth: number } => {
	let totalMonths = (zDate.getFullYear() - birthDate.getFullYear()) * 12 + (zDate.getMonth() - birthDate.getMonth());

	// 如果當前日期的天數小於出生日期的天數，需要減去一個月
	if (zDate.getDate() < birthDate.getDate()) {
		totalMonths -= 1;
	}

	// 處理負數月份的情況
	if (totalMonths < 0) {
		return { ageYear: 0, ageMonth: 0 };
	}

	return {
		ageYear: Math.floor(totalMonths / 12),
		ageMonth: totalMonths % 12
	};
};
