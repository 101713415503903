import { TreatmentInfoProps } from "store/TreatmentManagement/useTreatmentInfoStore";

export const M33M34Logic = (treatmentInfo: TreatmentInfoProps, refill_c: string | null, refill_f: string | null, mt_type: string) => {
	// 一次性領藥的code
	const REFILLABLE_TREATMENT_CODES = ["H8", "HA", "HB", "HC", "HD", "HI"];
	const hasRefillableTreatmentCode = REFILLABLE_TREATMENT_CODES.some(code => treatmentInfo.special_treatment.includes(code));
	const getLastDigit = (cardNo: string) => cardNo.slice(-1);

	let refill_seq = null;
	let c_refill_seq = null;
	if (refill_c && hasRefillableTreatmentCode && treatmentInfo.refillable?.refill_times && mt_type === "04") {
		refill_seq = Array.from({ length: Number(treatmentInfo.refillable.refill_times) })
			.map((_, i) => i + 1)
			.join("");
	} else if (mt_type === "08" && refill_c) {
		refill_seq = getLastDigit(treatmentInfo.card_no);
	}

	if (refill_f && hasRefillableTreatmentCode && treatmentInfo.refillable?.refill_times && mt_type === "04") {
		c_refill_seq = Array.from({ length: Number(treatmentInfo.refillable.refill_times) })
			.map((_, i) => i + 1)
			.join("");
	} else if (mt_type === "08" && refill_f) {
		c_refill_seq = getLastDigit(treatmentInfo.card_no);
	}
	// const refill_seq = (refill_c ?? '') && hasRefillableTreatmentCode && treatmentInfo.refillable?.refill_times && mt_type === '04'
	//     ? Array.from({ length: Number(treatmentInfo.refillable.refill_times) })
	//         .map((_, i) => i + 1)
	//         .join('')
	//     : null;
	// const c_refill_seq = (refill_f ?? '') && hasRefillableTreatmentCode && treatmentInfo.refillable?.refill_times
	//     ? Array.from({ length: Number(treatmentInfo.refillable.refill_times) })
	//         .map((_, i) => i + 1)
	//         .join('')
	//     : null;
	let M33 = refill_seq;
	let M34 = c_refill_seq;

	return { M33, M34 };
};
