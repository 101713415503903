// 診斷模型（MedicalRecordDiagModel）
import { DxProps } from "store/TreatmentManagement";
import { MedicalRecordDiagModelProps } from "store/TreatmentManagement/Print/useMedicalRecordDiagModelStore";

export const medicalRecordDiagLogic = (dxList: DxProps[]): MedicalRecordDiagModelProps[] => {
	let finalData: MedicalRecordDiagModelProps[] = dxList.map((dx, index) => ({
		// IDiagBase
		Diag_Code: dx.code,
		Diag_Name: dx.name_tw || "",
		E_Diag_Name: dx.name_en || "",
		ICD9: dx.icd_code || "",
		ICD10: dx.icd_code || "",
		Chronic: "", // 慢性病碼 這邊可能要補

		// IMedicalRecordDiag
		Seq: index + 1 ?? 0, // 順序
		DS: "", // 左右側（暫時不處理）
		DOC: "" // 醫囑衛教 （暫時不處理）doctor_orders
	}));

	return finalData;
};
