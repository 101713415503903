import Swal from "sweetalert2";

export const ROCStringyyyMMddHHmmssToDateyyyyMMddHHmmss = (rocString: string): string => {
    if (!rocString || rocString.length !== 13) {
        Swal.fire({
            icon: 'error',
            title: '日期格式錯誤',
            showConfirmButton: true,
        });
        return '';
    }

    const rocYear = parseInt(rocString.substring(0, 3), 10);
    const month = rocString.substring(3, 5);
    const day = rocString.substring(5, 7);
    const time = rocString.substring(7);

    const adYear = rocYear + 1911;

    return `${adYear}${month}${day}${time}`;
};
