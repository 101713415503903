import axios from "axios";
import Swal from "sweetalert2";

export type DraiaiCorpGetTokenServiceResponse = {
	accessToken: string;
	asrToken: string | null;
	clinicID: string;
	clinicName: string;
	model: string;
	patientName: string;
	recordID: string;
	roomID: string;
	statusCode: number;
	statusMessage: string;
};

export const draiaiCorpGetTokenService = async (apikey: string, enstring: string): Promise<DraiaiCorpGetTokenServiceResponse> => {
	const data = {
		apikey,
		enstring
	};

	const url = "https://quicknote-tw.draiai.com:5001/Corp/GetToken";

	try {
		const response = await axios.post(url, data, {
			headers: {
				"Content-Type": "application/json"
			}
		});

		return response.data;
	} catch (error) {
		console.error("獲取 Token 時發生錯誤:", error);
		Swal.fire({
			icon: "error",
			title: "查詢過程發生錯誤",
			confirmButtonText: "確定"
		});
		throw error;
	}
};
