import { create } from "zustand";

interface ICCardUpdateShowStatusState {
	showStatus: string;
	updateShowStatus: (status: string) => void;
}

export const useICCardUpdateShowStatusStore = create<ICCardUpdateShowStatusState>(set => ({
	showStatus: "0",
	updateShowStatus: status => set({ showStatus: status })
}));
