import React, { MouseEventHandler, useEffect, useState } from "react";

import { Button, Box, Flex, Text, Input, Tooltip } from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import { useIsFormMaximizeStatus } from "contexts/TreatmentManagement/Prescription";

// Components

import { FormHeader } from "./FormHeader";
import { FormBody } from "./FormBody";
import { FeeDisplay } from "./FeeDisplay";
import { ControlButtons } from "./ControlButtons";

const PrescriptionForm: React.FC = () => {
	const { isFormMaximizeStatus, setIsFormMaximizeStatus } = useIsFormMaximizeStatus();

	return (
		<>
			<Box pos={"relative"} w={"100%"} h={`calc(${window.innerHeight}px - 41px - 8px - 42px - 8px - 332px - 8px - 56px - 8px)`}>
				<Flex
					pos={isFormMaximizeStatus ? "fixed" : "relative"}
					top={isFormMaximizeStatus ? "calc(41px + 8px + 41px + 8px)" : "0"}
					left={isFormMaximizeStatus ? "20px" : "0px"}
					right={0}
					bottom={0}
					zIndex={isFormMaximizeStatus ? 20 : 0}
					direction={"column"}
					bg={"#fff"}
					rounded={"8px"}
					boxShadow={"inset 4px 2px 2px rgba(0,0,0,0.25)"}
					w={"100%"}
					h={"100%"}
					maxH={
						isFormMaximizeStatus
							? `calc(${window.innerHeight}px - 41px - 8px - 42px - 8px - 56px)`
							: `calc(${window.innerHeight}px - 41px - 8px - 42px - 8px - 332px - 8px - 56px)`
					}
					overflow={"auto"}
					maxW={isFormMaximizeStatus ? "calc((100%  - 20px - 8px - 20px ) * 2 / 3)" : "100%"}>
					<Box flex={"1 1 auto"} pos={"relative"}>
						<FormHeader />
						<FormBody />
					</Box>
					<Flex
						gap={"16px"}
						p={"8px 16px"}
						pos={"sticky"}
						bottom={"0"}
						left={"0"}
						bg={"#fff"}
						boxShadow={"inset 4px 0px 2px rgba(0,0,0,0.25)"}
						zIndex={10}>
						<FeeDisplay />
						<ControlButtons />
					</Flex>
				</Flex>
			</Box>
		</>
	);
};

export default PrescriptionForm;
