import apiService from './apiService';

export type DeclareErrorProps = {
    current_page: number; // 目前頁碼
    last_page: number; // 最後一頁
    data: DeclareErrorDataProps[]; // 列表內容
};

export type DeclareErrorDataProps = {
    medical_record_uid: string; // 病歷資料 id
    case_type: string; // 案件分類
    main_diagnosis: string; // 主診斷 icd 10 內碼
    dose_days: number; // 開藥天數
    nhi_type: string; // 掛號身份
    card_no: string; // 就醫序號
    name: string; // 病患姓名
    medical_record_number: string; // 病歷號
    error_msg: string; // 錯誤訊息
};

export type DeclareErrorProcessProps = {
    HospGuid: string; // 醫院 guid
    Month: string; // 月份
    msg: string; // 訊息
    result: string; // 結果
};

export type DeclareErrorReqDataProps = {
    ym?: string;
    page?: number;
};

export type DeclareErrorResProps = {
    lists: DeclareErrorProps;
    result: string;
    process: DeclareErrorProcessProps;
    msg?: string;
};

export const callDeclareErrorService = async (
    token: string,
    data: DeclareErrorReqDataProps
): Promise<DeclareErrorResProps> => {
    return apiService<string, any>({
        method: 'GET',
        path: '/api/declare/error/',
        token,
        data: data,
    });
};
