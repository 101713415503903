import { useState } from "react";
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { WaitingList } from "components/WaitingListTable/WaitingList";
import { VisitedList } from "components/WaitingListTable/VisitedList";
import { AppointmentList } from "components/WaitingListTable/AppointmentList";
import { VideoWaitingList } from "components/WaitingListTable/VideoWaitingList";
import { WaitingListsStatusProvider } from "contexts/Registration/WaitingListsStatusContext";
import { useWaitingTableTabIndex } from "contexts";
export const RegisteredWaitingTable = () => {
	const { waitingTableTabIndex, setWaitingTableTabIndex } = useWaitingTableTabIndex();

	const handleTabChange = (index: number) => {
		console.log(index, "index");
		setWaitingTableTabIndex(index);
	};

	return (
		<>
			<Box>
				<Tabs index={waitingTableTabIndex} onChange={handleTabChange}>
					<TabList bg={"gray.50"}>
						<Tab textColor={"rsSecondary.500"}>候診</Tab>
						<Tab textColor={"rsSecondary.500"}>已診</Tab>
						{/* <Tab textColor={'rsSecondary.500'}>預約</Tab> */}
						{/* <Tab textColor={'rsSecondary.500'}>視訊</Tab> */}
					</TabList>
					<WaitingListsStatusProvider>
						<TabPanels>
							<TabPanel px={0}>{waitingTableTabIndex === 0 && <WaitingList />}</TabPanel>
							<TabPanel px={0}>{waitingTableTabIndex === 1 && <VisitedList />}</TabPanel>
							{/* <TabPanel px={0}>
                                {tabIndex === 2 && <AppointmentList />}
                            </TabPanel> */}
							<TabPanel px={0}>{/* {waitingTableTabIndex === 2 && <VideoWaitingList />} */}</TabPanel>
						</TabPanels>
					</WaitingListsStatusProvider>
				</Tabs>
			</Box>
		</>
	);
};
