import React, { createContext, useContext, useState, ReactNode } from 'react';

interface RoomStatusContextValue {
    roomStatus: string;
    setRoomStatus: React.Dispatch<React.SetStateAction<string>>;
    
}

const RoomStatusContext = createContext<RoomStatusContextValue | undefined>(
    undefined
);

interface RoomStatusProviderProps {
    children: ReactNode;
}

export const RoomStatusProvider: React.FC<RoomStatusProviderProps> = ({
    children,
}) => {
    const [roomStatus, setRoomStatus] = useState('');

    return (
        <RoomStatusContext.Provider value={{ roomStatus, setRoomStatus }}>
            {children}
        </RoomStatusContext.Provider>
    );
};

export const useRoomStatus = (): RoomStatusContextValue => {
    const context = useContext(RoomStatusContext);

    if (!context) {
        throw new Error(
            'useRoomStatus must be used within a RoomStatusProvider'
        );
    }

    return context;
};
