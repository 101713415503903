import axios from "axios";
import axiosInstance from "./axiosInstance";
import apiService from "./apiService";

interface sortListsProps {
	ids: string[];
}

// 院所看診時間排序
export const postClinicRoomSortService = async (token: string, sortLists: sortListsProps) => {
	return apiService<sortListsProps, any>({
		method: "POST",
		path: "/api/clinic_room/sort",
		token,
		data: sortLists
	});
};
// export const postClinicRoomSortService = async (
//     token: string,
//     sortLists: sortListsProps
// ) => {
//     try {
//         const requestBody = JSON.stringify(sortLists);
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };
//         console.log(requestBody, 'requestBody service印的');

//         const response = await axios.post(
//             '/api/clinic_room/sort',
//             requestBody,
//             {
//                 headers,
//             }
//         );

//         const result = response.data;
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
