// CompleteOptions.tsx

// React 相關
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// UI 組件
import { Box, Flex, Button } from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import Swal from "sweetalert2";

// 服務和 API
import { postMedicalRecordService } from "service/medicalRecordService";
import { callCustomerService } from "service/customerService";
import { callMedicalStaffListService, medicalStaffListsProps } from "service/medicalStaffListService";
import { callMedicalStaffSpecService } from "service/medicalStaffSpecService";
import { callAccItemsLevelService, accItemsLevelProps } from "service/accItemsLevelService";
import { postRsPrintPrescriptionService } from "service/RsService/RsPrintPrescription";
import { postRsPrintCompleteClinicService } from "service/RsService/RsPrintCompleteClinic";
import { postIcDataService, IcDataProps } from "service/icDataServices";
import { callDrugFreqListsService } from "service/drugFreqListsService";
import { DrugFreqListsProps } from "store/DataSetting/useDrugFreqListStore";
import { useVirtualCardState } from "contexts/VirtualCardContext";
// 查詢和狀態管理
import { useQuery } from "react-query";
import { useDropDownMenuQuery } from "query/useDropDownMenuQuery";
import { useIsBillingOpenStatus, useIsReferralOpenStatus } from "contexts/TreatmentManagement/BillingProcess";
import { useCalculationCompleteStatus } from "contexts/TreatmentManagement/CalculationCompleteContext";
import { useShowAbnormalButtonStatus } from "contexts/TreatmentManagement/BillingProcess/ShowAbnormalButtonStatusContext";
import { usePrescriptionStore, useDxStore, useCCPEStore, useTreatmentInfoStore } from "store/TreatmentManagement";
import { usePrintPrescriptionStore } from "store/TreatmentManagement/Print/usePrintPrescriptionStore";
import { usePrintCompleteClinicStore } from "store/TreatmentManagement/Print/usePrintCompleteClinicStore";
import { usePrintChronicPrescriptionStore } from "store/TreatmentManagement/Print/usePrintChronicPrescriptionStore";
import { useUserStore } from "store";
import { useCashDataStore, useFeeStore } from "store/TreatmentManagement/Payment";
import { useCurrentVisitInfoStore } from "store/Registration";
import { useInstitutionInfoStore } from "store/DataSetting/useInstitutionInfoStore";
import { usePatientInfoStore } from "store/Registration";
import { ICCardUpdateProps, useICCardUpdateStore } from "store/TreatmentManagement/ICCardUpdate/useICCardUpdateStore";
import { useMedicalRecordStatus } from "contexts/TreatmentManagement/MedicalRecordStatusContext";
import { useOriginPrescriptionStore } from "store/TreatmentManagement";
import { useDeclarePrescriptionStore } from "store/TreatmentManagement/useDeclarePrescriptionStore";
import { useIsChooseAbnormalButtonStatus } from "contexts/TreatmentManagement/BillingProcess/IsChooseAbnormalButtonStatusContext";
import { useIsAuthorizeStatus } from "contexts/TreatmentManagement/BillingProcess/IsAuthorizeStatusContext";
import { useLoadingStatus } from "contexts/LoadingStatusContext";

// 工具函數
import { hisGetCardStatus } from "functions/hisGetCardStatus";
import { hisGetSeqNumber256N1, SeqNumber256N1Props } from "functions/hisGetSeqNumber256N1";
import { hisGetRegisterBasic } from "functions/hisGetRegisterBasic";
import { SummaryICCardUpdateData } from "../SummaryICCardUpdateData";
import { SummaryNoICCardUpdateData } from "../SummaryNoICCardUpdateData";

// 邏輯函數
import { opdModelLogic } from "./CompleteOptionsFunc/opdModelLogic";
import { medicalRecordLogic } from "./CompleteOptionsFunc/medicalRecordLogic";
import { medicalRecordDiagLogic } from "./CompleteOptionsFunc/medicalRecordDiagLogic";
import { medicalRecordDrugLogic } from "./CompleteOptionsFunc/medicalRecordDrugLogic";
import { chargeLogic } from "./CompleteOptionsFunc/chargeLogic";
import { hospitalLogic } from "./CompleteOptionsFunc/hospitalLogic";
import { patientLogic } from "./CompleteOptionsFunc/patientLogic";
import { slowRecLogic } from "./CompleteOptionsFunc/slowRecLogic";

import { handleAbnormalCardNumberComplete } from "./CompleteOptions/utils/handleAbnormalCardNumberComplete";
import { handleMissingCardNumberForCompletedTreatment } from "./CompleteOptions/utils/handleMissingCardNumberForCompletedTreatment";
// 其他
import { finalDataProps } from "service/medicalRecordService";
import { summaryData } from "../SummaryFinalData";
import { handleNavigateAfterComplete } from "./CompleteOptions/utils/handleNavigateAfterComplete";

import { hisGetTreatNumNoICCard } from "functions/hisGetTreatNumNoICCard";
import { handleVirtualCardComplete } from "./CompleteOptions/utils/handleVirtualCardComplete";
import { pBufferProps } from "service/RsService/RshisGetTreatNumNoICCardService";
import { checkCardStatus } from "./CompleteOptions/utils/checkCardStatus";

export type DoctorSpecDataProps = {
	Dr_Code: string;
	Dr_Name: string;
	E_Dr_Name: string;
	Dr_IDNo: string;
	Dr_Certificate: string;
	Dr_Mobile: string;
};

export type drugTypeListProps = {
	data_id: string;
	code: string;
	name: string;
};

import { hisWriteTreatmentCodeLogic } from "./CompleteOptionsFunc/hisWriteTreatmentCodeLogic";
import { hisWritePrescriptionLogic } from "./CompleteOptionsFunc/hisWritePrescriptionLogic";
import { RshisWriteTreatmentCodeService } from "service/RsService/RshisWriteTreatmentCodeService";
import { RshisWriteMultiPrescriptSignService } from "service/RsService/RshisWriteMultiPrescriptSignService";
import { mb1Props } from "store/TreatmentManagement/useTreatmentInfoStore";
import { handleHistoricalDataUploadComplete } from "./CompleteOptions/utils/handleHistoricalDataUploadComplete";
export const CompleteOptions = () => {
	const navigate = useNavigate();
	const [ifFormalDataForAbnormal, setIfFormalDataForAbnormal] = useState<string>("");
	const { isAuthorizeStatus, setIsAuthorizeStatus } = useIsAuthorizeStatus();
	const [caseUidForAbnormal, setCaseUidForAbnormal] = useState<string>("");
	const [isPrintForAbnormal, setIsPrintForAbnormal] = useState<boolean>(false);
	const { declarePrescriptionList, setDeclarePrescriptionList } = useDeclarePrescriptionStore();
	const { calculationComplete, setCalculationComplete } = useCalculationCompleteStatus();
	const [isPrintBag, setIsPrintBag] = useState<boolean>(false);
	const [isPrintReceipt, setIsPrintReceipt] = useState<boolean>(false);
	const [prescriptionStyle, setPrescriptionStyle] = useState<number | null>(null);
	const { showReadVHICStatus, setShowReadVHICStatus } = useVirtualCardState();
	const { originPrescriptionList, resetOriginPrescriptionList } = useOriginPrescriptionStore();
	const { icCardData, updateICCardData } = useICCardUpdateStore();
	const { feeData, resetFeeData } = useFeeStore();
	const { cashDataStore, resetCashDataStore } = useCashDataStore();
	const { isChooseAbnormalButtonStatus, setIsChooseAbnormalButtonStatus } = useIsChooseAbnormalButtonStatus();
	const { setIsBillingOpenStatus } = useIsBillingOpenStatus();
	const { userInfo } = useUserStore();
	const { CCPEData, resetCCPEData } = useCCPEStore();
	const { dxList, resetDx } = useDxStore();
	const { treatmentInfo, updateTreatmentInfo, resetTreatmentInfo } = useTreatmentInfoStore();
	const { prescriptionList, resetPrescriptionList } = usePrescriptionStore();
	const { showAbnormalButtonStatus, setShowAbnormalButtonStatus } = useShowAbnormalButtonStatus();
	const { medicalRecordStatus, setMedicalRecordStatus } = useMedicalRecordStatus();
	const { currentVisitInfoData, resetCurrentVisitInfoData } = useCurrentVisitInfoStore();
	const { patientInfo, resetPatientInfo } = usePatientInfoStore();
	const { loadingStatus, setLoadingStatus } = useLoadingStatus();
	const { PrintPrescriptionData, updatePrintPrescriptionData, resetPrintPrescriptionData } = usePrintPrescriptionStore();
	const { PrintCompleteClinicData, updatePrintCompleteClinicData, resetPrintCompleteClinicData } = usePrintCompleteClinicStore();
	const { PrintChronicPrescriptionData, updatePrintChronicPrescriptionData, resetPrintChronicPrescriptionData } =
		usePrintChronicPrescriptionStore();
	const { institutionInfoData, updateInstitutionInfoData } = useInstitutionInfoStore();
	const [isCompleteButtonStatus, setIsCompleteButtonStatus] = useState(false);

	const [accItemsLevelList, setAccItemSLevelList] = useState<accItemsLevelProps[]>([]);
	const callAccItemsLevelAPI = async () => {
		const result = await callAccItemsLevelService(userInfo.token!!, 3);
		setAccItemSLevelList(result.lists);
	};
	useEffect(() => {
		if (userInfo.token) {
			callAccItemsLevelAPI();
		}
	}, []);
	const { data: drugFreqListsData, isSuccess: isDrugFreqListsSuccess } = useQuery(
		"drugFreqLists",
		() => callDrugFreqListsService(userInfo.token),
		{
			enabled: !!userInfo.token,
			staleTime: Infinity
		}
	);
	const [drugFreqLists, setDrugFreqLists] = useState<DrugFreqListsProps[]>([]);
	useEffect(() => {
		if (isDrugFreqListsSuccess) {
			setDrugFreqLists(drugFreqListsData.lists);
		}
	}, [isDrugFreqListsSuccess]);

	const [isStartClinicComplete, setIsStartClinicComplete] = useState(false);
	const [isStartChronicContinuousPrescription, setIsStartChronicContinuousPrescription] = useState(false);

	const [isChronic, setIsChronic] = useState(false);

	useEffect(() => {
		if (
			// treatmentInfo.case_type === '04' &&
			treatmentInfo.refillable?.refill_times
		) {
			setIsChronic(true);
		}
	}, [treatmentInfo.refillable?.refill_times]);

	const [virtualCardCaseUid, setVirtualCardCaseUid] = useState<string>("");
	const [virtualCardIfFormalData, setVirtualCardIfFormalData] = useState<string>("");
	const [isPrintForVirtualCard, setIsPrintForVirtualCard] = useState<boolean>(false);

	// 組織列印資料
	const organizePrintDataLogic = (
		seqNumber256N1Result?: SeqNumber256N1Props,
		hisGetTreatNumNoICCardResult?: pBufferProps,
		mb1?: mb1Props
	) => {
		const filteredList = prescriptionList.filter(prescription => prescription.name_tw && prescription.name_tw.trim() !== "");

		const filteredChronicList = filteredList.filter(prescription => prescription.if_chronic === "1");

		// 掛號模型
		let opdFinalData = opdModelLogic(
			treatmentInfo,
			currentVisitInfoData,
			patientInfo,
			doctorSpecData,
			feeData,
			cashDataStore,
			seqNumber256N1Result?.pBuffer ?? {
				visitDateTime: "", //就診日期時間
				visitSerialNumber: "", //就醫序號
				medicalInstitutionCode: "", //醫療院所代碼
				securitySignature: "", //安全簽章
				samId: "", //SAM ID
				sameDayVisit: "", //是否同日就診
				visitIdentificationCode: "" //就醫識別碼
			},
			hisGetTreatNumNoICCardResult ?? {
				computerDateTime: "", // 電腦日期時間 (1-13)
				medicalInstitutionCode: "", // 醫療院所代碼 (14-23)
				medicalTreatmentIdentificationCode: "" // 就醫識別碼 (24-43)｀
			},
			mb1 ?? {
				signature: "", // 安全簽章
				treatment_no: "", // 就醫識別碼
				treated_at: "", // 就醫日期時間
				card_number: "", // 卡片號碼
				institution_type: "", // 機構類別
				sam_code: "", // SAM卡代碼
				card_no: "" // 卡片序號
			}
		);
		// 病歷模型
		let medicalRecordFinalData = medicalRecordLogic(
			treatmentInfo,
			CCPEData,
			patientInfo,
			doctorSpecData,
			filteredList,
			drugTypeList,
			drugFreqLists,
			seqNumber256N1Result?.pBuffer ?? {
				visitDateTime: "", //就診日期時間
				visitSerialNumber: "", //就醫序號
				medicalInstitutionCode: "", //醫療院所代碼
				securitySignature: "", //安全簽章
				samId: "", //SAM ID
				sameDayVisit: "", //是否同日就診
				visitIdentificationCode: "" //就醫識別碼
			},
			hisGetTreatNumNoICCardResult ?? {
				computerDateTime: "", // 電腦日期時間 (1-13)
				medicalInstitutionCode: "", // 醫療院所代碼 (14-23)
				medicalTreatmentIdentificationCode: "" // 就醫識別碼 (24-43)
			},
			mb1 ?? {
				signature: "", // 安全簽章
				treatment_no: "", // 就醫識別碼
				treated_at: "", // 就醫日期時間
				card_number: "", // 卡片號碼
				institution_type: "", // 機構類別
				sam_code: "", // SAM卡代碼
				card_no: "" // 卡片序號
			}
		);
		// 診斷模型
		let medicalRecordDiagFinalData = medicalRecordDiagLogic(dxList);
		// 處方模型
		let medicalRecordDrugFinalData = medicalRecordDrugLogic(filteredList, drugTypeList, drugFreqLists);
		// 慢性連續處方模型
		let medicalRecordChronicFinalData = medicalRecordDrugLogic(filteredChronicList, drugTypeList, drugFreqLists);
		// 批價模型
		let chargeFinalData = chargeLogic(treatmentInfo, feeData, cashDataStore, patientInfo);

		// 醫院模型
		let hospitalFinalData = hospitalLogic(institutionInfoData);
		// 病患模型
		let patientFinalData = patientLogic(treatmentInfo, patientInfo);

		// 慢箋模型
		let slowRecFinalData = slowRecLogic(filteredList, treatmentInfo, drugTypeList, drugFreqLists);

		updatePrintPrescriptionData({
			Opd: opdFinalData,
			MedicalRecord: medicalRecordFinalData,
			MedicalRecordDiag: medicalRecordDiagFinalData,
			MedicalRecordDrug: medicalRecordDrugFinalData,
			Charge: chargeFinalData,
			Hospital: hospitalFinalData,
			Patient: patientFinalData,
			SlowRec: slowRecFinalData
		});

		updatePrintCompleteClinicData({
			Opd: opdFinalData,
			MedicalRecord: medicalRecordFinalData,
			MedicalRecordDiag: medicalRecordDiagFinalData,
			MedicalRecordDrug: medicalRecordDrugFinalData,
			Charge: chargeFinalData,
			Hospital: hospitalFinalData,
			Patient: patientFinalData,
			SlowRec: slowRecFinalData
		});

		if (isChronic) {
			let slowRecFinalData = slowRecLogic(filteredChronicList, treatmentInfo, drugTypeList, drugFreqLists);
			updatePrintChronicPrescriptionData({
				Opd: opdFinalData,
				MedicalRecord: medicalRecordFinalData,
				MedicalRecordDiag: medicalRecordDiagFinalData,
				MedicalRecordDrug: medicalRecordChronicFinalData,
				Charge: chargeFinalData,
				Hospital: hospitalFinalData,
				Patient: patientFinalData,
				SlowRec: slowRecFinalData
			});
		}

		console.log("在 organizePrintDataLogic 整理好: ");
		console.log("批次列印 PrintCompleteClinicData: ", PrintCompleteClinicData);
		console.log("單次列印 PrintPrescriptionData: ", PrintPrescriptionData);

		if (isChronic) {
			console.log("慢性連續處方列印 PrintChronicPrescriptionData: ", PrintChronicPrescriptionData);
		}
	};

	const printData = async () => {
		console.log("列印： 進入 printData");
		if (institutionInfoData.hosp_name) {
			let formNames: string[] = [];

			switch (treatmentInfo.prescription_style) {
				case 0: // 醫師自調
				case 2: // 藥師院內調劑
					formNames.push("Prescription", "DrugDetailReceipt");
					console.log("準備院內調劑、醫師自調");
					break;
				case 1: // 交付調劑
					formNames.push("DeliveryPrescription_Say", "Prescription", "DrugDetailReceipt");
					console.log("準備交付調劑");
					break;
				default:
					console.log("列印： 格式錯誤");
					return;
			}

			updatePrintCompleteClinicData({
				FormList: formNames
			});

			console.log("updatePrintCompleteClinicData: ", PrintCompleteClinicData);
			setIsStartClinicComplete(true);
		}

		if (isChronic) {
			updatePrintChronicPrescriptionData({
				FormName: "ChronicContinuousPrescription"
			});

			setIsStartChronicContinuousPrescription(true);
		}

		console.log("列印： 列印結束");
	};

	useEffect(() => {
		const handlePrintCompleteClinic = async () => {
			if (isStartClinicComplete) {
				console.log("批次列印： 送印資料", PrintCompleteClinicData);

				await postRsPrintCompleteClinicAPI();

				setIsStartClinicComplete(false);
			}
		};
		handlePrintCompleteClinic();
	}, [isStartClinicComplete]);

	useEffect(() => {
		const handlePrintChronicContinuousPrescription = async () => {
			if (isStartChronicContinuousPrescription) {
				console.log("慢性連續處方列印： 送印資料", PrintChronicPrescriptionData);

				await postRsPrintChronicContinuousPrescriptionAPI();

				setIsStartChronicContinuousPrescription(false);
			}
		};
		handlePrintChronicContinuousPrescription();
	}, [isStartChronicContinuousPrescription]);

	// 批次印單 postRsPrintCompleteClinicAPI
	const postRsPrintCompleteClinicAPI = async () => {
		console.log("列印： 進入 postRsPrintCompleteClinicAPI");
		console.log("列印： 送印 FormName: ", PrintCompleteClinicData.FormList);
		const result = await postRsPrintCompleteClinicService(PrintCompleteClinicData);
		if (result) {
			const printResult = JSON.parse(result);
			console.log("列印 印單結果", printResult);
		} else {
			console.log("列印 印單失敗:", result);
		}
	};

	// 單次印單 postRsPrintAPI
	const postRsPrintAPI = async () => {
		console.log("列印： 進入 postRsPrintAPI");
		console.log("列印： 送印 FormName: ", PrintPrescriptionData.FormName);

		const result = await postRsPrintPrescriptionService(PrintPrescriptionData);
		const printResult = JSON.parse(result);
		console.log("列印 印單結果", printResult);
		if (printResult.ERRORCODE === "0") {
			console.log("列印 印單成功");
		} else if (printResult.ERRORCODE === "-1") {
			console.log("列印 印單失敗:", printResult.pBuffer);
		}
	};

	const postRsPrintChronicContinuousPrescriptionAPI = async () => {
		console.log("列印：進入 postRsPrintChronicContinuousPrescriptionAPI");
		console.log("列印： 送印 FormName: ", PrintChronicPrescriptionData.FormName);
		const result = await postRsPrintPrescriptionService(PrintChronicPrescriptionData);
		if (result) {
			const printResult = JSON.parse(result);
			console.log("列印慢箋 印單結果", printResult);
		} else {
			console.log("列印慢箋 印單失敗:", result);
		}
	};

	const updateICCardDataAsync = (data: Partial<ICCardUpdateProps>) => {
		return new Promise<void>(resolve => {
			const { updateICCardData } = useICCardUpdateStore.getState();
			updateICCardData(data);
			resolve(); // 在更新完成后，Promise 立即解析
		});
	};
	useEffect(() => {
		const handleAbnormalCompletion = async () => {
			if (isChooseAbnormalButtonStatus) {
				// 當選擇完成後執行後續邏輯
				console.log("異常按鈕選擇完成");

				try {
					setLoadingStatus(false);
					if (isPrintForAbnormal) {
						organizePrintDataLogic(undefined, undefined, treatmentInfo.medical_record?.mb1);
						await printData();
					}
					const ICCardUpdateData = await handleMissingCardNumberForCompletedTreatment(
						treatmentInfo,
						CCPEData,
						dxList,
						prescriptionList,
						cashDataStore,
						accItemsLevelList,
						feeData,
						ifFormalDataForAbnormal,
						caseUidForAbnormal,
						drugTypeList,
						institutionInfoData,
						drugFreqLists,
						userInfo
					);
					if (!ICCardUpdateData) return;
					// let ICDataResult

					resetAllStore();
					setMedicalRecordStatus("0");
					handleNavigateAfterComplete(
						medicalRecordStatus,
						navigate,
						setCalculationComplete,
						setIsCompleteButtonStatus,
						setLoadingStatus
					);
				} catch (error) {
					console.error("處理異常完成時發生錯誤:", error);
				} finally {
					setLoadingStatus(false);
					setIsCompleteButtonStatus(false);
				}
			}
		};

		handleAbnormalCompletion();
	}, [isChooseAbnormalButtonStatus]); // 當選擇完成狀態改變時觸發

	useEffect(() => {
		const handleAuthorizeStatus = async () => {
			if (isAuthorizeStatus) {
				console.log("這邊是isAuthorizeStatus", isAuthorizeStatus);
				setShowReadVHICStatus(false);
				try {
					if (isPrintForVirtualCard) {
						organizePrintDataLogic(undefined, undefined, treatmentInfo.medical_record?.mb1);

						await printData();
					}
					const result = await handleVirtualCardComplete(
						treatmentInfo,
						CCPEData,
						dxList,
						prescriptionList,
						cashDataStore,
						accItemsLevelList,
						feeData,
						virtualCardIfFormalData,
						virtualCardCaseUid,
						drugTypeList,
						institutionInfoData,
						drugFreqLists,
						userInfo,
						medicalRecordStatus,
						originPrescriptionList
					);
					console.log("虛擬卡完成結果:", result);
					// 這裡可以根據 result 做進一步處理

					if (result?.result === "success") {
						resetAllStore();
						setMedicalRecordStatus("0");
						handleNavigateAfterComplete(
							medicalRecordStatus,
							navigate,
							setCalculationComplete,
							setIsCompleteButtonStatus,
							setLoadingStatus
						);
					}
					setIsAuthorizeStatus(false);
				} catch (error) {
					console.error("處理虛擬卡時發生錯誤:", error);
					// 這裡可以添加錯誤處理邏輯
				}
			}
		};

		handleAuthorizeStatus();
	}, [isAuthorizeStatus]);

	const handleError = (title: string, text: string) => {
		setIsCompleteButtonStatus(false);
		setLoadingStatus(false);
		Swal.fire({
			icon: "error",
			title,
			text,
			showConfirmButton: true
		});
		return false; // 改為返回 false 更明確表示錯誤狀態
	};

	const handleComplete = async (if_formal_data: string, is_print: boolean) => {
		// 卡號為IC02, IC03, IC04時，需要檢查卡片狀態, 沒卡不要做
		if (isCompleteButtonStatus) {
			return;
		}
		setLoadingStatus(true);
		setIsCompleteButtonStatus(true);
		// 設置按鈕為禁用狀態

		if (["IC02", "IC03", "IC04"].includes(treatmentInfo.card_no)) {
			let CardType = "2";
			const cardStatus = await checkCardStatus(CardType);
			if (cardStatus.result !== "success") {
				return handleError(`${treatmentInfo.card_no}時需要健保卡`, "請確認卡片狀態");
			}
		}

		//     if(process.env.REACT_APP_ENV === 'development'){
		//         const caseUid = ''
		//         const ICCardUpdateData =
		//         SummaryICCardUpdateData(
		//                 treatmentInfo,
		//                 CCPEData,
		//                 dxList,
		//                 // prescriptionList,
		//                 declarePrescriptionList,
		//                 cashDataStore,
		//                 accItemsLevelList,
		//                 feeData,
		//                 if_formal_data,
		//                 caseUid,
		//                 drugTypeList,
		//                 institutionInfoData,
		//                 drugFreqLists,

		//             );
		//         console.log(
		//             '準備寫入IC卡的資料: ',
		//             ICCardUpdateData
		//         );
		//         if(ICCardUpdateData){
		//             return
		//         }
		//    }

		if (treatmentInfo && CCPEData && dxList && prescriptionList && cashDataStore && accItemsLevelList && feeData) {
			const data = summaryData(
				treatmentInfo,
				CCPEData,
				dxList,
				prescriptionList,
				cashDataStore,
				accItemsLevelList,
				feeData,
				if_formal_data,
				drugTypeList,
				drugFreqLists
			);
			if (data.diagnosis.length === 0) {
				return handleError("診斷不可為空", "請填寫診斷");
			}

			let medicalRecordResult = await postMedicalRecordAPI(data);
			let caseUid = "";
			if (medicalRecordResult.result === "success" && medicalRecordResult.data_id) {
				caseUid = medicalRecordResult.data_id;
			} else {
				return handleError("儲存病歷時發生錯誤", "請檢查網路狀態並重新整理");
			}
			if (treatmentInfo.nhi_type_name === "自費") {
				if (is_print) {
					setIsPrintForVirtualCard(true);
				} else {
					setIsPrintForVirtualCard(false);
				}
				handleNavigateAfterComplete(
					medicalRecordStatus,
					navigate,
					setCalculationComplete,
					setIsCompleteButtonStatus,
					setLoadingStatus,
					500
				);
				return;
			}
			if (treatmentInfo.card_no === "V999") {
				console.log("進V999");
				setShowReadVHICStatus(true);
				setVirtualCardCaseUid(caseUid);
				setVirtualCardIfFormalData(if_formal_data);
				if (is_print) {
					setIsPrintForVirtualCard(true);
				} else {
					setIsPrintForVirtualCard(false);
				}
				return;
			}
			if (treatmentInfo.card_no === "A999") {
				console.log("這邊是A999");
				if (is_print) {
					organizePrintDataLogic();
					printData();
				}
				const result = await handleHistoricalDataUploadComplete(
					treatmentInfo,
					CCPEData,
					dxList,
					// prescriptionList,
					declarePrescriptionList,
					cashDataStore,
					accItemsLevelList,
					feeData,
					if_formal_data,
					caseUid,
					drugTypeList,
					institutionInfoData,
					drugFreqLists,
					userInfo
				);
				if (result === null) return;

				resetAllStore();
				setMedicalRecordStatus("0");
				handleNavigateAfterComplete(
					medicalRecordStatus,
					navigate,
					setCalculationComplete,
					setIsCompleteButtonStatus,
					setLoadingStatus,
					500
				);
				return;
			}
			if (medicalRecordStatus === "0") {
				// 一般進入看診
				// 儲存病歷，取回病歷號碼
				if (if_formal_data === "0") {
					//保留
					console.log("保留，病歷號碼：", caseUid);
					resetAllStore();
					navigate("/OPDPage/");
					Swal.fire({
						icon: "success",
						title: "保留成功",
						showConfirmButton: true
					});
				} else if (if_formal_data === "1") {
					//正式

					// 1: 安全模組檔
					// 2: 健保 IC 卡
					// 3: 醫事人員卡
					let CardType = "2";
					console.log(treatmentInfo.card_no, "0910 see");

					if (["IC02", "IC03", "IC04"].includes(treatmentInfo.card_no) || !treatmentInfo.card_no) {
						console.log("這邊是沒有卡號的或等於IC02, IC03, IC04");
						// 沒有卡號，去讀卡
						let hisGetCardStatusResult;
						try {
							hisGetCardStatusResult = await hisGetCardStatus(CardType);
							console.log("讀卡號結果: ", hisGetCardStatusResult);

							if (
								hisGetCardStatusResult === undefined ||
								(hisGetCardStatusResult && typeof hisGetCardStatusResult !== "string")
							) {
								return handleError("讀卡時發生問題", "請檢查讀卡機和代理人");
							}
						} catch (error) {
							return handleError("卡片狀態異常", "請檢查卡片是否正確插入");
						}

						let slicedResult, slicedResult2;
						if (hisGetCardStatusResult) {
							slicedResult = hisGetCardStatusResult.slice(1, 14);
							slicedResult2 = hisGetCardStatusResult.slice(13, 14);
							console.log("slicedResult: ", slicedResult, "slicedResult2: ", slicedResult2);
						} else {
							console.error("hisGetCardStatusResult is undefined");
							return;
						}

						if (slicedResult2 === "2" || slicedResult2 === "3" || slicedResult2 === "4" || slicedResult2 === "5") {
							let basicResult;
							try {
								basicResult = await hisGetRegisterBasic();
							} catch (error) {
								console.error("獲取基本註冊信息時出錯:", error);
								return handleError("讀取卡片資料失敗", "請檢查卡片是否正確插入或重新嘗試");

								// 可以在這裡添加額外的錯誤處理邏輯，例如顯示錯誤消息給用戶
							}
							console.log("取得 basic: ", basicResult);
							if (basicResult.idOrDocumentNumber === treatmentInfo.id_no) {
								let medicalNumber = treatmentInfo.selected_mt_type || treatmentInfo.mt_type;
								console.log("取得就醫類別: ", medicalNumber);
								let BabyTreat = " ";
								let TreatAfterCheck = treatmentInfo.nhi_type_name === "押單" ? "2" : "1";
								let bufferLen = "316";
								let seqNumber256N1Data = {
									cTreatItem: medicalNumber,
									cBabyTreat: BabyTreat,
									cTreatAfterCheck: TreatAfterCheck,
									iButterLen: bufferLen
								};
								console.log("要傳去得到256N1的資料: ", seqNumber256N1Data);

								let seqNumber256N1Result: SeqNumber256N1Props | null = null;

								try {
									seqNumber256N1Result = await hisGetSeqNumber256N1(seqNumber256N1Data);

									if (seqNumber256N1Result && seqNumber256N1Result.ERRORCODE !== 0) {
										return handleError(`發生錯誤: ${seqNumber256N1Result.ERRORCODE}`, "寫卡時發生問題");
									}
								} catch (error) {
									return handleError("取得就醫識別碼失敗", "請檢查健保卡或讀卡機狀態");
								}

								console.log("解析後的256N1結果: ", seqNumber256N1Result);

								// 讀完卡 得到資料

								if (seqNumber256N1Result === null) {
									return handleError("寫卡時發生問題", "請檢查讀卡機和代理人");
								}

								// 開始列印環節
								if (is_print) {
									console.log("這邊是印單的");
									organizePrintDataLogic(seqNumber256N1Result);
									printData();
								} else {
									console.log("這邊是沒有印單的");
								}

								const ICCardUpdateData = SummaryICCardUpdateData(
									treatmentInfo,
									CCPEData,
									dxList,
									// prescriptionList,
									declarePrescriptionList,
									cashDataStore,
									accItemsLevelList,
									feeData,
									if_formal_data,
									caseUid,
									drugTypeList,
									institutionInfoData,
									drugFreqLists,
									seqNumber256N1Result,
									basicResult
								);
								console.log("準備寫入IC卡的資料: ", ICCardUpdateData);

								const updatedICCardData = {
									...useICCardUpdateStore.getState().icCardData,
									...ICCardUpdateData
								};

								// 使用异步更新方法
								await updateICCardDataAsync(updatedICCardData); // 等待数据更新完成

								console.log("icCardData: ", icCardData, "updatedICCardData: ", updatedICCardData);
								const ICDataResult = await postIcDataService(userInfo.token, updatedICCardData);
								console.log("IC上傳結果：", ICDataResult);

								// 開始寫卡
								if (ICDataResult.result === "success") {
									//1. 先做處方 處方沒過要擋
									let finalResult;
									if (prescriptionList && prescriptionList.length > 0) {
										const hisWritePrescriptionData = await hisWritePrescriptionLogic(
											treatmentInfo,
											ICCardUpdateData.drugs,
											drugFreqLists,
											seqNumber256N1Result.pBuffer!,
											basicResult,
											undefined,
											medicalRecordStatus,
											originPrescriptionList
										);
										const hisWritePrescriptionResult =
											await RshisWriteMultiPrescriptSignService(hisWritePrescriptionData);

										const hisWritePrescriptionResultJSON = JSON.parse(hisWritePrescriptionResult);
										console.log("醫令這個最重要結果原始:", hisWritePrescriptionResult);
										console.log("醫令這個最重要結果", hisWritePrescriptionResultJSON.pBuffer);

										if (hisWritePrescriptionResultJSON && hisWritePrescriptionResultJSON.ERRORCODE === 0) {
											const pBuffer = hisWritePrescriptionResultJSON.pBuffer;
											const splitArray: string[] = [];

											for (let i = 0; i < pBuffer.length; i += 40) {
												splitArray.push(pBuffer.slice(i, i + 40));
											}

											// 获取当前的 ICCard 数据
											const { icCardData, updateICCardData } = useICCardUpdateStore.getState();

											// 更新 drugs 数组中的每个对象的 D11 字段
											const latestICCardData = {
												...useICCardUpdateStore.getState().icCardData,

												drugs: useICCardUpdateStore.getState().icCardData.drugs.map((drug, index) => {
													return {
														...drug,
														signature: splitArray[index] || "" // 如果 splitArray 没有足够的数据，使用空字符串
													};
												}),
												ic_content: {
													...useICCardUpdateStore.getState().icCardData.ic_content,
													drugs: useICCardUpdateStore
														.getState()
														.icCardData.ic_content.drugs.map((drug, index) => {
															return {
																...drug,
																signature: splitArray[index] || "" // 如果 splitArray 没有足够的数据，使用空字符串
															};
														})
												}
											};

											await updateICCardDataAsync(latestICCardData);
											console.log("更新后的 drugs 数据: ", latestICCardData.drugs);

											const ICDataAddDrugSignatureResult = await postIcDataService(
												userInfo.token,
												latestICCardData // 使用最新的更新后数据
											);

											console.log("是否成功更新資料庫的IC上傳2: ", ICDataAddDrugSignatureResult);
											finalResult = ICDataAddDrugSignatureResult;
										} else {
											return handleError("寫卡錯誤", `錯誤代碼: ${hisWritePrescriptionResultJSON.ERRORCODE}`);
										}
									} else {
										finalResult = { result: "success" };
									}

									//2. 做診斷 沒寫成功沒差
									const hisWriteTreatmentCodeData = await hisWriteTreatmentCodeLogic(
										treatmentInfo,
										ICCardUpdateData.ic_content.diagnosis,
										seqNumber256N1Result!,
										basicResult,
										undefined
									);
									// hisWriteTreatmentFeeLogic(treatmentInfo,)
									const hisWriteTreatmentCodeResult = await RshisWriteTreatmentCodeService(hisWriteTreatmentCodeData);
									const parseHisWriteTreatmentCodeResult =
										typeof hisWriteTreatmentCodeResult === "string"
											? JSON.parse(hisWriteTreatmentCodeResult)
											: hisWriteTreatmentCodeResult;

									// updateICCardData({

									// })
									//3. 做診療 沒寫成功沒差
									if (finalResult.result === "success") {
										resetAllStore();
										setMedicalRecordStatus("0");
										// navigate("/OPDPage/");
										handleNavigateAfterComplete(
											medicalRecordStatus,
											navigate,
											setCalculationComplete,
											setIsCompleteButtonStatus,
											setLoadingStatus,
											500
										);
									}
								} else {
									return handleError("寫入 IC 發生問題", "請檢查讀卡機和代理人");
								}
							} else {
								return handleError("IC卡與病歷資料匹配錯誤", "請檢查卡片是否正確");
							}
						} else if (slicedResult2 === "0") {
							return handleError("卡片未置入", "請檢查卡片是否正確");
						} else if (slicedResult2 === "1") {
							return handleError("健保IC卡尚未與安全模組認證", "請檢查卡片是否正確");
						} else if (slicedResult2 === "9") {
							return handleError("所置入非健保IC卡", "請檢查卡片是否正確");
						} else {
							return handleError("讀取卡片狀態異常", "請檢查卡片是否正確");
						}
					} else {
						console.log("這邊是有卡片號碼的");
						// if(isForeignID(treatmentInfo.id_no)){
						// if (handleForeignPatient(treatmentInfo, medicalRecordStatus, setMedicalRecordStatus, navigate)) {

						if (is_print) {
							organizePrintDataLogic();
							printData();
						}
						const ICCardUpdateData = await handleAbnormalCardNumberComplete(
							treatmentInfo,
							CCPEData,
							dxList,
							// prescriptionList,
							declarePrescriptionList,
							cashDataStore,
							accItemsLevelList,
							feeData,
							if_formal_data,
							caseUid,
							drugTypeList,
							institutionInfoData,
							drugFreqLists,
							userInfo,
							handleError
						);

						if (!ICCardUpdateData) return;
						resetAllStore();
						setMedicalRecordStatus("0");
						handleNavigateAfterComplete(
							medicalRecordStatus,
							navigate,
							setCalculationComplete,
							setIsCompleteButtonStatus,
							setLoadingStatus,
							500
						);

						// }
						// return;
						// }

						// //沒有卡片讀卡(異常卡號)
						// let noCardReqData = {
						//     pPatientID: treatmentInfo.id_no,
						//     cTreatHospCode: institutionInfoData.nhid,
						// };

						// console.log('異常卡號讀卡的資料: ', noCardReqData);

						// let hisGetTreatNumNoICCardResult;
						// try {
						//     hisGetTreatNumNoICCardResult =
						//         await hisGetTreatNumNoICCard(noCardReqData);
						//     console.log(
						//         '異常卡號讀卡的結果: ',
						//         hisGetTreatNumNoICCardResult
						//     );

						//     if (hisGetTreatNumNoICCardResult.ERRORCODE !== 0) {
						//         let errorMessage = '';
						//         switch (
						//             hisGetTreatNumNoICCardResult.ERRORCODE
						//         ) {
						//             case 4000:
						//                 errorMessage = '讀卡機超時';
						//                 break;
						//             case 4061:
						//                 errorMessage = '網路連接失敗';
						//                 break;
						//             case 5006:
						//                 errorMessage =
						//                     '讀取安全模組內的「醫療院所代碼」失敗';
						//                 break;
						//             case 5174:
						//                 errorMessage = '取得就醫識別碼失敗';
						//                 break;
						//             case 6018:
						//                 errorMessage =
						//                     '參數錯誤，檢核身分證字號或「醫療院所代號」錯誤';
						//                 break;
						//             default:
						//                 errorMessage = '取得就醫識別碼失敗';
						//         }

						//         Swal.fire({
						//             icon: 'error',
						//             title: `發生錯誤: ${hisGetTreatNumNoICCardResult.ERRORCODE}`,
						//             text: `${errorMessage}`,
						//             showConfirmButton: true,
						//         });
						//         return;
						//     }
						// } catch (error) {
						//     console.error('獲取就醫識別碼時出錯:', error);
						//     Swal.fire({
						//         icon: 'error',
						//         title: '讀卡錯誤',
						//         text: '請檢查網路狀態及代理人程式',
						//         showConfirmButton: true,
						//     });
						//     return;
						// }

						// if (is_print) {
						//     organizePrintDataLogic(
						//         undefined,
						//         hisGetTreatNumNoICCardResult.pBuffer
						//     );
						//     printData();
						// } else {
						//     console.log('這邊是沒有印單的');
						// }

						// let ICCardUpdateData: ICCardUpdateProps;

						// ICCardUpdateData = SummaryNoICCardUpdateData(
						//     treatmentInfo,
						//     CCPEData,
						//     dxList,
						//     prescriptionList,
						//     cashDataStore,
						//     accItemsLevelList,
						//     feeData,
						//     if_formal_data,
						//     caseUid,
						//     drugTypeList,
						//     institutionInfoData,
						//     drugFreqLists,
						//     hisGetTreatNumNoICCardResult.pBuffer || undefined
						// );

						// console.log(
						//     '異常掛號的IC卡更新資料: ',
						//     ICCardUpdateData
						// );

						// await updateICCardDataAsync(ICCardUpdateData); // 等待数据更新完成

						// ICDataResult = await postIcDataService(
						//     userInfo.token,
						//     ICCardUpdateData
						// );
						// console.log(
						//     'postIcDataService 資料結果: ',
						//     ICDataResult
						// );
						// if (
						//     !ICDataResult ||
						//     ICDataResult.result !== 'success'
						// ) {
						//     Swal.fire({
						//         icon: 'error',
						//         title: '寫入 IC 發生問題',
						//         text: '請檢查網路狀態並重新整理',
						//         showConfirmButton: true,
						//     });
						//     return
						// } else {
						//     console.log('寫入 IC 成功');
						// }
					}
				} else {
					console.log("不可預期");
					return;
				}
			} else if (medicalRecordStatus === "1" || medicalRecordStatus === "2" || medicalRecordStatus === "3") {
				// 已診進入看診

				if (if_formal_data === "0") {
					//保留
					console.log("保留，病歷號碼：", caseUid);
					resetAllStore();
					setMedicalRecordStatus("0");
					// navigate("/OPDPage/");
					handleNavigateAfterComplete(
						medicalRecordStatus,
						navigate,
						setCalculationComplete,
						setIsCompleteButtonStatus,
						setLoadingStatus,
						500
					);
					Swal.fire({
						icon: "success",
						title: "保留成功",
						showConfirmButton: true
					});
				} else if (if_formal_data === "1") {
					//正式

					console.log("開始完診流程 caseUid: ", caseUid);

					if (treatmentInfo.card_no) {
						let ICDataResult;
						// 原本就有卡號
						Swal.fire({
							title: "是否要寫卡?",
							text: "請確認是否要進行IC卡寫入",
							icon: "question",
							showCancelButton: true,
							confirmButtonColor: "#3085d6",
							cancelButtonColor: "#d33",
							confirmButtonText: "是",
							cancelButtonText: "否"
						}).then(async result => {
							if (result.isConfirmed) {
								// 如果用戶選擇寫卡，這裡可以放置寫卡的邏輯
								console.log("用戶選擇寫卡");
								if (is_print) {
									if (treatmentInfo?.medical_record?.mb1) {
										organizePrintDataLogic(undefined, undefined, treatmentInfo.medical_record.mb1);
										printData();
									} else {
										console.error("medical_record or mb1 is undefined");
									}
								} else {
									console.log("這邊是沒有印單的");
								}
								let ICCardUpdateData: ICCardUpdateProps;
								ICCardUpdateData = SummaryNoICCardUpdateData(
									treatmentInfo,
									CCPEData,
									dxList,
									// prescriptionList,
									declarePrescriptionList,
									cashDataStore,
									accItemsLevelList,
									feeData,
									if_formal_data,
									caseUid,
									drugTypeList,
									institutionInfoData,
									drugFreqLists,
									undefined
								);
								console.log("異常掛號的IC卡更新資料: ", ICCardUpdateData);
								try {
									await updateICCardDataAsync(ICCardUpdateData); // 等待数据更新完成
									ICDataResult = await postIcDataService(userInfo.token, ICCardUpdateData);
									console.log("postIcDataService 資料結果: ", ICDataResult);
									if (!ICDataResult || ICDataResult.result !== "success") {
										return handleError("寫入 IC 失敗", "請檢查網路狀態並重試");
									} else {
										console.log("寫入 IC 成功");
									}
								} catch (error) {
									return handleError("寫入 IC 發生問題", "請檢查網路狀態並重新整理");
								}
								// 開始寫卡
								if (ICDataResult.result === "success") {
									//1. 先做處方 處方沒過要擋
									let finalResult;
									if (prescriptionList && prescriptionList.length > 0) {
										const hisWritePrescriptionData = await hisWritePrescriptionLogic(
											treatmentInfo,
											ICCardUpdateData.drugs,
											drugFreqLists,
											undefined,
											undefined,
											undefined,
											medicalRecordStatus,
											originPrescriptionList
										);
										const hisWritePrescriptionResult =
											await RshisWriteMultiPrescriptSignService(hisWritePrescriptionData);

										let hisWritePrescriptionResultJSON;
										try {
											hisWritePrescriptionResultJSON = JSON.parse(hisWritePrescriptionResult);
										} catch (error) {
											return handleError("寫入醫令資料時發生錯誤", "請檢查網路狀態並重試");
										}

										if (hisWritePrescriptionResultJSON && hisWritePrescriptionResultJSON.ERRORCODE === 0) {
											const pBuffer = hisWritePrescriptionResultJSON.pBuffer;
											const splitArray: string[] = [];

											for (let i = 0; i < pBuffer.length; i += 40) {
												splitArray.push(pBuffer.slice(i, i + 40));
											}

											// 获取当前的 ICCard 数据
											const { icCardData, updateICCardData } = useICCardUpdateStore.getState();

											// 更新 drugs 数组中的每个对象的 D11 字段
											const latestICCardData = {
												...useICCardUpdateStore.getState().icCardData,

												drugs: useICCardUpdateStore.getState().icCardData.drugs.map((drug, index) => {
													return {
														...drug,
														signature: splitArray[index] || "" // 如果 splitArray 没有足够的数据，使用空字符串
													};
												}),
												ic_content: {
													...useICCardUpdateStore.getState().icCardData.ic_content,
													drugs: useICCardUpdateStore
														.getState()
														.icCardData.ic_content.drugs.map((drug, index) => {
															return {
																...drug,
																signature: splitArray[index] || "" // 如果 splitArray 没有足够的数据，使用空字符串
															};
														})
												}
											};

											await updateICCardDataAsync(latestICCardData);
											console.log("更新后的 drugs 数据: ", latestICCardData.drugs);

											const ICDataAddDrugSignatureResult = await postIcDataService(
												userInfo.token,
												latestICCardData // 使用最新的更新后数据
											);

											console.log("是否成功更新資料庫的IC上傳2: ", ICDataAddDrugSignatureResult);
											finalResult = ICDataAddDrugSignatureResult;
										} else {
											console.error("寫卡失敗");
											return handleError("寫卡失敗", "請檢查卡片狀態並重試");
										}
									} else {
										finalResult = { result: "success" };
									}

									//2. 做診斷 沒寫成功沒差
									const hisWriteTreatmentCodeData = await hisWriteTreatmentCodeLogic(
										treatmentInfo,
										ICCardUpdateData.ic_content.diagnosis,
										undefined,
										undefined,
										undefined
									);
									// hisWriteTreatmentFeeLogic(treatmentInfo,)
									const hisWriteTreatmentCodeResult = await RshisWriteTreatmentCodeService(hisWriteTreatmentCodeData);
									console.log("診斷有沒有寫成功: ", hisWriteTreatmentCodeResult);
									// updateICCardData({

									// })
									//3. 做診療 沒寫成功沒差
									if (finalResult.result === "success") {
										resetAllStore();
										setMedicalRecordStatus("0");
										// navigate("/OPDPage/");
										handleNavigateAfterComplete(
											medicalRecordStatus,
											navigate,
											setCalculationComplete,
											setIsCompleteButtonStatus,
											setLoadingStatus,
											500
										);
									}
								} else {
									return handleError("寫入 IC 發生問題", "請檢查讀卡機和代理人");
								}

								resetAllStore();
								setMedicalRecordStatus("0");
								// if (medicalRecordStatus === "1") {
								// 	setTimeout(() => {
								// 		navigate("/OPDPage");
								// 	}, 0);
								// } else if (medicalRecordStatus === "2") {
								// 	setTimeout(() => {
								// 		navigate("/ICCardUpdatePage");
								// 	}, 0);
								// } else if (medicalRecordStatus === "3") {
								// 	setTimeout(() => {
								// 		navigate("/DeclarationAuditPage");
								// 	}, 0);
								// } else {
								// 	console.log("medicalRecordStatus does not match any condition");
								// }
								handleNavigateAfterComplete(
									medicalRecordStatus,
									navigate,
									setCalculationComplete,
									setIsCompleteButtonStatus,
									setLoadingStatus,
									500
								);
								// TODO: 在這裡添加寫卡的程式碼
							} else {
								// 如果用戶選擇不寫卡

								if (is_print) {
									organizePrintDataLogic(undefined, undefined, treatmentInfo.medical_record?.mb1);
									printData();
								} else {
									console.log("這邊是沒有印單的");
								}

								let ICCardUpdateData: ICCardUpdateProps;

								ICCardUpdateData = SummaryNoICCardUpdateData(
									treatmentInfo,
									CCPEData,
									dxList,
									// prescriptionList,
									declarePrescriptionList,
									cashDataStore,
									accItemsLevelList,
									feeData,
									if_formal_data,
									caseUid,
									drugTypeList,
									institutionInfoData,
									drugFreqLists,
									undefined
								);

								console.log("異常掛號的IC卡更新資料: ", ICCardUpdateData);

								// await updateICCardDataAsync(ICCardUpdateData); // 等待数据更新完成

								ICDataResult = await postIcDataService(userInfo.token, ICCardUpdateData);
								console.log("postIcDataService 資料結果: ", ICDataResult);
								if (!ICDataResult || ICDataResult.result !== "success") {
									return handleError("寫入 IC 發生問題", "請檢查網路狀態並重新整理");
								} else {
									console.log("寫入 IC 成功");
								}
								resetAllStore();
								setMedicalRecordStatus("0");
								// if (medicalRecordStatus === "1") {
								// 	setTimeout(() => {
								// 		navigate("/OPDPage");
								// 	}, 0);
								// } else if (medicalRecordStatus === "2") {
								// 	setTimeout(() => {
								// 		navigate("/ICCardUpdatePage");
								// 	}, 0);
								// } else if (medicalRecordStatus === "3") {
								// 	setTimeout(() => {
								// 		navigate("/DeclarationAuditPage");
								// 	}, 0);
								// } else {
								// 	console.log("medicalRecordStatus does not match any condition");
								// }
								handleNavigateAfterComplete(
									medicalRecordStatus,
									navigate,
									setCalculationComplete,
									setIsCompleteButtonStatus,
									setLoadingStatus,
									500
								);
								console.log("用戶選擇不寫卡");
								// TODO: 在這裡添加不寫卡時的處理邏輯
							}
						});
					} else {
						// 沒卡號流程

						Swal.fire({
							title: "是否要寫卡?",
							text: "請確認是否要進行IC卡寫入",
							icon: "question",
							showCancelButton: true,
							confirmButtonColor: "#3085d6",
							cancelButtonColor: "#d33",
							confirmButtonText: "是",
							cancelButtonText: "否"
						}).then(async result => {
							if (result.isConfirmed) {
								// 1: 安全模組檔
								// 2: 健保 IC 卡
								// 3: 醫事人員卡

								let CardType = "2";
								// 如果用戶選擇寫卡，這裡可以放置寫卡的邏輯
								let hisGetCardStatusResult;
								try {
									hisGetCardStatusResult = await hisGetCardStatus(CardType);
									console.log("讀卡號結果: ", hisGetCardStatusResult);

									if (
										hisGetCardStatusResult === undefined ||
										(hisGetCardStatusResult && typeof hisGetCardStatusResult !== "string")
									) {
										return handleError("讀卡時發生問題", "請檢查讀卡機和代理人");
									}
								} catch (error) {
									return handleError("讀卡發生錯誤", "請檢查讀卡機和代理人");
								}

								let slicedResult, slicedResult2;
								if (hisGetCardStatusResult) {
									slicedResult = hisGetCardStatusResult.slice(1, 14);
									slicedResult2 = hisGetCardStatusResult.slice(13, 14);
								} else {
									console.error("hisGetCardStatusResult is undefined");
									return handleError("讀取卡片異常", "請檢查卡片是否正確插入或讀卡機是否正常運作");
								}

								if (slicedResult2 === "2" || slicedResult2 === "3" || slicedResult2 === "4" || slicedResult2 === "5") {
									let basicResult;
									try {
										basicResult = await hisGetRegisterBasic();
									} catch (error) {
										console.error("獲取基本註冊信息時出錯:", error);
										return handleError("取得卡片基本資料失敗", "請檢查卡片是否正確插入或重新嘗試");
										// 可以在這裡添加額外的錯誤處理邏輯，例如顯示錯誤消息給用戶
									}
									console.log("取得 basic: ", basicResult);
									if (basicResult.idOrDocumentNumber === treatmentInfo.id_no) {
										let medicalNumber = treatmentInfo.selected_mt_type || treatmentInfo.mt_type;
										console.log("取得就醫類別: ", medicalNumber);
										let BabyTreat = " ";
										let TreatAfterCheck = treatmentInfo.nhi_type_name === "押單" ? "2" : "1";
										let bufferLen = "316";
										let seqNumber256N1Data = {
											cTreatItem: medicalNumber,
											cBabyTreat: BabyTreat,
											cTreatAfterCheck: TreatAfterCheck,
											iButterLen: bufferLen
										};
										console.log("要傳去得到256N1的資料: ", seqNumber256N1Data);

										let seqNumber256N1Result: SeqNumber256N1Props | null = null;

										try {
											seqNumber256N1Result = await hisGetSeqNumber256N1(seqNumber256N1Data);

											if (seqNumber256N1Result && seqNumber256N1Result.ERRORCODE !== 0) {
												let errorMessage = "寫卡時發生問題";
												switch (seqNumber256N1Result.ERRORCODE) {
													case 4000:
														errorMessage = "讀卡機timeout";
														break;
													case 4013:
														errorMessage = "未置入健保IC卡";
														break;
													case 4029:
														errorMessage = "IC 卡權限不足";
														break;
													case 4033:
														errorMessage = "所置入非健保IC卡";
														break;
													case 4050:
														errorMessage = "安全模組尚未與 IDC 認證";
														break;
													case 4061:
														errorMessage = "網路不通";
														break;
													case 4071:
														errorMessage = "健保 IC 卡與 IDC 認證失敗";
														break;
													case 5001:
														errorMessage = "就醫可用次數不足";
														break;
													case 5002:
														errorMessage = "卡片已註銷";
														break;
													case 5003:
														errorMessage = "卡片已過有限期限";
														break;
													case 5004:
														errorMessage = "新生兒依附就醫已逾 60 日";
														break;
													case 5005:
														errorMessage = "讀卡機的就診日期時間讀取失敗";
														break;
													case 5006:
														errorMessage = "讀取安全模組內的「醫療院所代碼」失敗";
														break;
													case 5007:
														errorMessage = "寫入一組新的「就醫資料登錄」失敗";
														break;
													case 5008:
														errorMessage = "安全模組簽章失敗";
														break;
													case 5009:
														errorMessage = "投保單位無權限";
														break;
													case 5010:
														errorMessage = "同一天看診兩科(含)以上";
														break;
													case 5012:
														errorMessage = "此人未在保";
														break;
													case 5174:
														errorMessage = "取就醫識別碼失敗";
														break;
													case 5081:
														errorMessage =
															"最近 24 小時內同院所未曾就醫，故不可取消就醫（就醫類別輸入 ZA/ZB 時檢查）";
														break;
													case 9129:
														errorMessage = "持卡人於非所限制的醫療院所就診";
														break;
													case 9093:
														errorMessage =
															"即時查保-投保身分不一致(流程上要請院所替民眾做一次卡片更新，將卡片內的身份類別更新成與伺服端一致後，進行取號作業)";
														break;
													case 9094:
														errorMessage = "即時查保-停保或退保";
														break;
													case 9095:
														errorMessage = "即時查保-欠費";
														break;
													default:
														errorMessage = "寫卡時發生未知錯誤";
												}
												return handleError(`發生錯誤: ${seqNumber256N1Result.ERRORCODE}`, errorMessage);
											}
										} catch (error) {
											console.error("獲取256N1序列號時出錯:", error);
											return handleError("取得就醫識別碼失敗", "請檢查健保卡或讀卡機狀態，並重試。");
											// 可以在這裡添加額外的錯誤處理邏輯，例如顯示錯誤消息給用戶
										}

										console.log("解析後的256N1結果: ", seqNumber256N1Result);

										// 讀完卡 得到資料

										if (seqNumber256N1Result === null) {
											return handleError("寫卡時發生問題", "請檢查讀卡機和代理人");
										}

										// 開始列印環節
										if (is_print) {
											console.log("這邊是印單的");
											organizePrintDataLogic(seqNumber256N1Result);
											printData();
										} else {
											console.log("這邊是沒有印單的");
										}

										const ICCardUpdateData = SummaryICCardUpdateData(
											treatmentInfo,
											CCPEData,
											dxList,
											// prescriptionList,
											declarePrescriptionList,
											cashDataStore,
											accItemsLevelList,
											feeData,
											if_formal_data,
											caseUid,
											drugTypeList,
											institutionInfoData,
											drugFreqLists,
											seqNumber256N1Result,
											basicResult
										);

										const updatedICCardData = {
											...useICCardUpdateStore.getState().icCardData,
											...ICCardUpdateData
										};

										// 使用异步更新方法
										await updateICCardDataAsync(updatedICCardData); // 等待数据更新完成

										const ICDataResult = await postIcDataService(userInfo.token, updatedICCardData);

										// 開始寫卡
										if (ICDataResult.result === "success") {
											//1. 先做處方 處方沒過要擋
											let finalResult;
											if (prescriptionList && prescriptionList.length > 0) {
												const hisWritePrescriptionData = await hisWritePrescriptionLogic(
													treatmentInfo,
													ICCardUpdateData.drugs,
													drugTypeListData.lists,
													seqNumber256N1Result.pBuffer!,
													basicResult,
													undefined,
													medicalRecordStatus,
													originPrescriptionList
												);
												const hisWritePrescriptionResult =
													await RshisWriteMultiPrescriptSignService(hisWritePrescriptionData);

												const hisWritePrescriptionResultJSON = JSON.parse(hisWritePrescriptionResult);

												if (hisWritePrescriptionResultJSON && hisWritePrescriptionResultJSON.pBuffer) {
													const pBuffer = hisWritePrescriptionResultJSON.pBuffer;
													const splitArray: string[] = [];

													for (let i = 0; i < pBuffer.length; i += 40) {
														splitArray.push(pBuffer.slice(i, i + 40));
													}

													// 获取当前的 ICCard 数据
													const { icCardData, updateICCardData } = useICCardUpdateStore.getState();

													// 更新 drugs 数组中的每个对象的 D11 字段
													const latestICCardData = {
														...useICCardUpdateStore.getState().icCardData,

														drugs: useICCardUpdateStore.getState().icCardData.drugs.map((drug, index) => {
															return {
																...drug,
																signature: splitArray[index] || "" // 如果 splitArray 没有足够的数据，使用空字符串
															};
														}),
														ic_content: {
															...useICCardUpdateStore.getState().icCardData.ic_content,
															drugs: useICCardUpdateStore
																.getState()
																.icCardData.ic_content.drugs.map((drug, index) => {
																	return {
																		...drug,
																		signature: splitArray[index] || "" // 如果 splitArray 没有足够的数据，使用空字符串
																	};
																})
														}
													};

													await updateICCardDataAsync(latestICCardData);
													console.log("更新后的 drugs 数据: ", latestICCardData.drugs);

													const ICDataAddDrugSignatureResult = await postIcDataService(
														userInfo.token,
														latestICCardData // 使用最新的更新后数据
													);

													console.log("是否成功更新資料庫的IC上傳2: ", ICDataAddDrugSignatureResult);
													finalResult = ICDataAddDrugSignatureResult;
												}
											} else {
												finalResult = {
													result: "success"
												};
											}

											//2. 做診斷 沒寫成功沒差
											const hisWriteTreatmentCodeData = await hisWriteTreatmentCodeLogic(
												treatmentInfo,
												ICCardUpdateData.ic_content.diagnosis,
												seqNumber256N1Result!,
												basicResult,
												undefined
											);
											// hisWriteTreatmentFeeLogic(treatmentInfo,)
											const hisWriteTreatmentCodeResult =
												await RshisWriteTreatmentCodeService(hisWriteTreatmentCodeData);
											const parseHisWriteTreatmentCodeResult =
												typeof hisWriteTreatmentCodeResult === "string"
													? JSON.parse(hisWriteTreatmentCodeResult)
													: hisWriteTreatmentCodeResult;

											console.log("診斷有沒有寫成功: ", hisWriteTreatmentCodeResult);
											// updateICCardData({

											// })
											//3. 做診療 沒寫成功沒差
											if (finalResult.result === "success") {
												resetAllStore();
												setMedicalRecordStatus("0");
												// navigate("/OPDPage/");
												handleNavigateAfterComplete(
													medicalRecordStatus,
													navigate,
													setCalculationComplete,
													setIsCompleteButtonStatus,
													setLoadingStatus,
													500
												);
											}
										} else {
											return handleError("寫入 IC 發生問題", "請檢查讀卡機和代理人");
										}
									} else {
										return handleError("IC卡與病歷資料匹配錯誤", "請檢查卡片是否正確");
									}
								} else if (slicedResult2 === "0") {
									return handleError("卡片未置入", "請檢查卡片是否正確");
								} else if (slicedResult2 === "1") {
									return handleError("健保IC卡尚未與安全模組認證", "請檢查卡片是否正確");
								} else if (slicedResult2 === "9") {
									return handleError("所置入非健保IC卡", "請檢查卡片是否正確");
								} else {
									return handleError("讀取卡片狀態異常", "請檢查卡片是否正確");
								}
							} else {
								// 如果用戶選擇不寫卡
								console.log("已診沒卡號不寫卡");
								setCaseUidForAbnormal(caseUid);
								setIfFormalDataForAbnormal(if_formal_data);
								setIsPrintForAbnormal(is_print);

								setShowAbnormalButtonStatus(true);

								console.log(result, "救命");

								console.log("有進取就醫識別碼流程");
							}
						});
					}
				}
			}
		} else {
			console.log("treatmentInfo:", treatmentInfo);
			console.log("資料異常 檢查：");
			console.log("CCPEData:", CCPEData);
			console.log("dxList:", dxList);
			console.log("prescriptionList:", prescriptionList);
			console.log("cashDataStore:", cashDataStore);
			console.log("accItemsLevelList:", accItemsLevelList);
			console.log("feeData:", feeData);
			return handleError("資料異常", "請檢查網路狀態並重新整理");
		}
	};

	const resetAllStore = () => {
		resetTreatmentInfo();
		resetCurrentVisitInfoData();
		resetCCPEData();
		resetDx();
		resetPrescriptionList();
		resetCashDataStore();
		resetFeeData();
		resetPatientInfo();
		resetPrintCompleteClinicData();
		resetPrintPrescriptionData();
		resetOriginPrescriptionList();
	};

	const postMedicalRecordAPI = async (finalData: finalDataProps): Promise<{ result: string; data_id: string; msg: string }> => {
		console.log("要送去存的病歷資料", finalData);
		try {
			const response = await postMedicalRecordService(userInfo.token, finalData);
			console.log("檢查 caseUid 結果: ", response);
			return response;
		} catch (error) {
			console.error("上傳病歷時發生錯誤:", error);
			return {
				result: "error",
				data_id: "",
				msg: (error as any).response?.data?.msg || "上傳病歷時發生錯誤"
			};
		}
	};

	const handleChangePrescriptionStyle = () => {
		const updateStyle = (treatmentInfo.prescription_style + 1) % 3;
		const drugTypes = ["O", "L", "W", "I"]; // 加入 'I' 類型
		const drugTypesEQ = ["E", "Q"]; // 加入 'I' 類型
		const drugTypeIds = drugTypes.map(code => drugTypeList.find(item => item.code === code)?.data_id);
		const hasOLWIDrug = prescriptionList.some(item => drugTypeIds.includes(item.drug_type));
		const hasEQDrug = prescriptionList.some(item => drugTypesEQ.includes(item.drug_type));
		const maxDays = hasOLWIDrug
			? Math.max(...prescriptionList.filter(item => drugTypeIds.includes(item.drug_type)).map(item => Number(item.dose_day) || 0))
			: 0;
		const caseTypeSome01Or09 = treatmentInfo.case_type === "01" || treatmentInfo.case_type === "09";
		// const mDrugFee = feeData.mDrugFee

		console.log(
			caseTypeSome01Or09,
			maxDays,
			treatmentInfo.case_type,
			feeData.mDrugFee,
			hasEQDrug,
			"全部要看的東西",
			feeData.mDrugFee <= 22 * maxDays
		);
		if (updateStyle === 0) {
			if (caseTypeSome01Or09 && maxDays <= 3 && feeData.mDrugFee <= 22 * maxDays && !hasEQDrug) {
				console.log("進來了吧 全部");
				updateTreatmentInfo({
					prescription_style: updateStyle,
					pharmacist_uid: "",
					case_type: "01"
				});
			} else {
				updateTreatmentInfo({
					prescription_style: updateStyle,
					pharmacist_uid: "",
					case_type: treatmentInfo.case_type
				});
			}
		} else if (updateStyle === 1) {
			if (caseTypeSome01Or09 && maxDays <= 3 && feeData.mDrugFee <= 66 && !hasEQDrug) {
				updateTreatmentInfo({
					prescription_style: updateStyle,
					pharmacist_uid: "",
					case_type: "09"
				});
			} else {
				updateTreatmentInfo({
					prescription_style: updateStyle,
					pharmacist_uid: "",
					case_type: treatmentInfo.case_type
				});
			}
		} else {
			if (caseTypeSome01Or09 && maxDays <= 3 && feeData.mDrugFee <= 66 && !hasEQDrug) {
				updateTreatmentInfo({
					prescription_style: updateStyle,
					case_type: "01"
				});
			} else {
				updateTreatmentInfo({
					prescription_style: updateStyle,
					case_type: treatmentInfo.case_type
				});
			}
		}
	};

	// 轉診單開關
	const { isReferralOpenStatus, setIsReferralOpenStatus } = useIsReferralOpenStatus();

	const handleOpenReferral = () => {
		setIsReferralOpenStatus(prev => !prev);
	};

	// 取得院所資料 callCustomerAPI
	const callCustomerAPI = async () => {
		if (userInfo.token) {
			try {
				const result = await callCustomerService(userInfo.token);
				console.log("callCustomerService Result:", result);
				if (result.result === "success") {
					const customer_style = result.info.prescription_style;
					updateTreatmentInfo({ prescription_style: customer_style });
					updateInstitutionInfoData({
						address: result.info.address,
						customer_uid: result.info.customer_uid,
						hosp_id: result.info.hosp_id,
						nhid: result.info.nhid,
						branch_uid: result.info.branch_uid,
						hosp_name: result.info.hosp_name,
						institution_type: result.info.institution_type,
						business_license: result.info.business_license,
						uniform_numbers: result.info.uniform_numbers,
						tel_area_code: result.info.tel_area_code,
						tel_number: result.info.tel_number,
						zip: result.info.zip,
						responsible_doctor: result.info.responsible_doctor,
						doctor_idno: result.info.doctor_idno,
						pharmacist: result.info.pharmacist,
						pharmacist_idno: result.info.pharmacist_idno,
						prescription_style: result.info.prescription_style
					});
				}
			} catch {
				return handleError("後端服務錯誤", "請檢查網路狀態並重新整理");
			}
		}
	};
	useEffect(() => {
		callCustomerAPI();
	}, []);

	// 取得醫師清單 useQuery
	const [medicalStaffDoctorLists, setMedicalStaffDoctorLists] = useState<medicalStaffListsProps[]>([]);
	const { data: medicalStaffListData, isSuccess: isMedicalStaffListSuccuss } = useQuery(
		["medicalStaffList", userInfo.token],
		() => callMedicalStaffListService(userInfo.token!, "iden_doctor"),
		{ enabled: !!userInfo.token, staleTime: Infinity }
	);

	useEffect(() => {
		if (isMedicalStaffListSuccuss) {
			setMedicalStaffDoctorLists(medicalStaffListData.lists);
		}
	}, [isMedicalStaffListSuccuss]);

	// 取得藥類清單 useDropDownMenuQuery
	const [drugTypeList, setDrugTypeList] = useState<drugTypeListProps[]>([]);
	const { data: drugTypeListData, isSuccess: isDrugTypeListSuccess } = useDropDownMenuQuery("drugTypeList", userInfo.token!);

	useEffect(() => {
		if (isDrugTypeListSuccess) {
			setDrugTypeList(drugTypeListData.lists);
		}
	}, [isDrugTypeListSuccess]);

	// 取得特定醫師詳細資料
	const [doctorSpecData, setDoctorSpecData] = useState<DoctorSpecDataProps>({
		Dr_Code: "",
		Dr_Name: "",
		E_Dr_Name: "",
		Dr_IDNo: "",
		Dr_Certificate: "",
		Dr_Mobile: ""
	});
	// 取得特定醫師詳細資料 callMedicalStaffSpecAPI
	const callMedicalStaffSpecAPI = async (data_id: string) => {
		if (userInfo.token) {
			const result = await callMedicalStaffSpecService(userInfo.token, treatmentInfo.doctor_uid);

			if (result.result === "success") {
				setDoctorSpecData({
					Dr_Code: "",
					Dr_Name: result.info.name ?? "",
					E_Dr_Name: result.info.name_en ?? "",
					Dr_IDNo: result.info.id_no ?? "",
					Dr_Certificate: result.info.license?.[0]?.license_no ?? "",
					Dr_Mobile: result.info.cellphone ?? ""
				});
			}
		}
	};

	useEffect(() => {
		callMedicalStaffSpecAPI(treatmentInfo.doctor_uid);
		setIsCompleteButtonStatus(false);
	}, []);

	useEffect(() => {
		console.log("isChronic", isChronic);
	}, [isChronic]);

	return (
		<>
			<Flex direction={"column"} justify={"flex-end"} w={"100%"} h={"100%"} p={"8px 16px"} gap={"8px"}>
				<Button
					hidden={true}
					variant={"outline"}
					w={"124px"}
					borderWidth={"2px"}
					alignSelf={"flex-end"}
					fontWeight={"semibold"}
					border={"2px solid #646B48"}
					textColor={"#646B48"}
					onClick={handleOpenReferral}
					disabled>
					轉診單
				</Button>
				<Flex gap={"8px"} justify={"flex-end"} w={"100%"}>
					<Box hidden={true}>
						<Button
							variant={"outline"}
							fontWeight={"semibold"}
							w={"100px"}
							border={isPrintBag ? "2px solid #646B48" : "2px solid #C26326"}
							boxShadow={isPrintBag ? "none" : "2px 2px 3px 0px rgba(0, 0, 0, 0.25) inset;"}
							textColor={isPrintBag ? "#646B48" : "#C26326"}
							onClick={() => {
								setIsPrintBag(v => !v);
							}}>
							{isPrintBag ? "印藥袋" : "不印藥袋"}
						</Button>

						<Button
							variant={"outline"}
							fontWeight={"semibold"}
							w={"100px"}
							border={isPrintReceipt ? "2px solid #646B48" : "2px solid #C26326"}
							boxShadow={isPrintReceipt ? "none" : "2px 2px 3px 0px rgba(0, 0, 0, 0.25) inset;"}
							textColor={isPrintReceipt ? "#646B48" : "#C26326"}
							onClick={() => {
								setIsPrintReceipt(v => !v);
							}}>
							{isPrintReceipt ? "印收據" : "不印收據"}
						</Button>
					</Box>

					<Button
						variant={"outline"}
						fontWeight={"semibold"}
						border={"2px solid #646B48"}
						textColor={"#646B48"}
						bg={"#ECECEC"}
						onClick={handleChangePrescriptionStyle}>
						{treatmentInfo.prescription_style === 0
							? "醫師自調"
							: treatmentInfo.prescription_style === 1
								? "交付調劑"
								: treatmentInfo.prescription_style === 2
									? "藥師院內"
									: "error"}

						<Box as='span'>
							<HeroIcons name='chevron-up-down'></HeroIcons>
						</Box>
					</Button>
				</Flex>

				<Flex justify={"flex-end"} gap={"8px"}>
					{/* <ChronicCreateButton /> */}
					{/* <Button

                        w={'100px'}
                        h={'100px'}
                        colorScheme={'rsPrimary'}
                        boxShadow={
                            '2px -2px 2px 0px rgba(0, 0, 0, 0.50) inset;'
                        }
                        onClick={() => {
                            {
                                setIsBillingOpenStatus(false);
                            }
                        }}
                    >
                        繼續開藥
                    </Button> */}
					{/* <Button
                        w={'100px'}
                        h={'100px'}
                        colorScheme={'rsPrimary'}
                        boxShadow={
                            '2px -2px 2px 0px rgba(0, 0, 0, 0.50) inset;'
                        }
                        onClick={() => handleComplete('0', false)}
                    >
                        保留
                    </Button> */}
					<Button
						w={"100px"}
						h={"100px"}
						colorScheme={"rsPrimary"}
						isDisabled={!calculationComplete || isCompleteButtonStatus}
						boxShadow={"2px -2px 2px 0px rgba(0, 0, 0, 0.50) inset;"}
						onClick={() => handleComplete("1", false)}>
						完成不印
					</Button>
					<Button
						w={"124px"}
						h={"100px"}
						colorScheme={"rsDanger"}
						isDisabled={!calculationComplete || isCompleteButtonStatus}
						boxShadow={"2px -2px 2px 0px rgba(0, 0, 0, 0.50) inset;"}
						onClick={() => handleComplete("1", true)}>
						完成列印
					</Button>
				</Flex>
			</Flex>
		</>
	);
};
