import React, { createContext, useContext, useState, ReactNode } from "react";

interface IsBillingOpenContextValue {
	isBillingOpenStatus: boolean;
	setIsBillingOpenStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsBillingOpenContext = createContext<IsBillingOpenContextValue | undefined>(undefined);

interface IsBillingOpenProviderProps {
	children: ReactNode;
}

export const IsBillingOpenProvider: React.FC<IsBillingOpenProviderProps> = ({ children }) => {
	const [isBillingOpenStatus, setIsBillingOpenStatus] = useState(false);
	return (
		<IsBillingOpenContext.Provider
			value={{
				isBillingOpenStatus,
				setIsBillingOpenStatus
			}}>
			{children}
		</IsBillingOpenContext.Provider>
	);
};

export const useIsBillingOpenStatus = (): IsBillingOpenContextValue => {
	const context = useContext(IsBillingOpenContext);

	if (!context) {
		throw new Error("useIsBillingOpenStatus must be used within a isBillingOpenStatusProvider");
	}

	return context;
};
