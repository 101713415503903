import { create } from "zustand";

// 定義 SlowRecModelProps 接口
export interface SlowRecModelProps {
	OpenDate: string;
	Freq: number;
	Days: number;
}

// 定義 SlowRecModelStore 接口
interface SlowRecModelStore {
	slowRecModel: SlowRecModelProps;
	updateSlowRecModel: (slowRecModel: SlowRecModelProps) => void;
	resetSlowRecModel: () => void;
}

// 初始化 slowRecModel 的值
export const initialSlowRecModel: SlowRecModelProps = {
	OpenDate: new Date().toISOString(),
	Freq: 0,
	Days: 0
};

// 創建 useSlowRecModelStore
export const useSlowRecModelStore = create<SlowRecModelStore>(set => ({
	slowRecModel: initialSlowRecModel,
	updateSlowRecModel: slowRecModel =>
		set(() => ({
			slowRecModel: slowRecModel
		})),
	resetSlowRecModel: () =>
		set(() => ({
			slowRecModel: initialSlowRecModel
		}))
}));

export default useSlowRecModelStore;
