import React, { useEffect, useState } from "react";
import { Box, Center, Flex, Text, Checkbox } from "@chakra-ui/react";
import { useDropDownMenuQuery } from "query/useDropDownMenuQuery";
import { useUserStore } from "store";
import { usePrescriptionStore, PrescriptionProps } from "store/TreatmentManagement";

interface DrugSpecialMarkListsProps {
	code: string;
	describe: string;
}

interface InputValuesType {
	columns: { [key: number]: string };
}

export const Mtable: React.FC = () => {
	const { userInfo } = useUserStore();
	const [isChange, setIsChange] = useState(false);
	const {
		prescriptionList,
		selectedIndex,
		isEditing,
		initialValue,
		inputValues,
		setSelectedIndex,
		setIsEditing,
		setInitialValue,
		updateCellValue,
		updatePrescription,
		setPrescriptionList,
		updateInputValues
	} = usePrescriptionStore();
	const { data: drugSpecialMarkData, isSuccess: isDrugSpecialMarkDataSuccess } = useDropDownMenuQuery("drugSpecialMark", userInfo.token!);
	const [drugSpecialMarkLists, setDrugSpecialMarkLists] = useState<DrugSpecialMarkListsProps[]>([]);
	const [checkedItems, setCheckedItems] = useState<boolean[]>([]);
	const [selectedCodes, setSelectedCodes] = useState<string>("");

	useEffect(() => {
		if (isDrugSpecialMarkDataSuccess) {
			setDrugSpecialMarkLists(drugSpecialMarkData.lists);
		}
	}, [isDrugSpecialMarkDataSuccess, drugSpecialMarkData]);

	const handleCheckboxChange = (index: number) => {
		const newCheckedItems = [...checkedItems];
		newCheckedItems[index] = !newCheckedItems[index];
		setCheckedItems(newCheckedItems);
		setIsChange(true);
	};

	useEffect(() => {
		if (isChange) {
			const selected = drugSpecialMarkLists
				.filter((_, index) => checkedItems[index])
				.map(item => item.code)
				.join("");
			setSelectedCodes(selected);
			if (selectedIndex.row !== null && selectedIndex.col !== null && selectedIndex.col === 8) {
				updateCellValue(selectedIndex.row, selectedIndex.col, selected);
				handleSave(selected);
			}
			setIsChange(false);
		}
	}, [checkedItems]);

	useEffect(() => {
		if (selectedIndex.row !== null && selectedIndex.col !== null && drugSpecialMarkLists.length > 0) {
			if (selectedIndex.col === 8 && drugSpecialMarkData && prescriptionList[selectedIndex.row].special_mark !== "") {
				const selectedValue = prescriptionList[selectedIndex.row].special_mark || "";
				const newCheckedItems = drugSpecialMarkLists && drugSpecialMarkLists.map(item => selectedValue.includes(item.code));
				setCheckedItems(newCheckedItems);
				setSelectedCodes(selectedValue);
			} else {
				setCheckedItems(new Array(drugSpecialMarkLists.length).fill(false));
				setSelectedCodes("");
			}
		}
	}, [selectedIndex, drugSpecialMarkData, drugSpecialMarkLists]);

	const handleSave = (selected: string) => {
		const updatedPrescription: Partial<PrescriptionProps> = {
			special_mark: selected
		};
		updatePrescription(selectedIndex.row, updatedPrescription, inputValues[selectedIndex.row].columns);
	};

	return (
		<>
			<Flex direction={"column"}>
				<Text textColor={"#646B48"} fontWeight={"semibold"} px={"8px"}>
					M 欄資訊選單
				</Text>

				<Box py={"8px"}>
					<Flex borderBottom={"1px solid rgba(100, 107, 72, 0.4)"}>
						<Center p={"4px 8px"} flex={"1 1 20%"}>
							<Text textStyle={"semiTitle"}>勾選</Text>
						</Center>
						<Center p={"4px 8px"} flex={"1 1 20%"}>
							<Text textStyle={"semiTitle"}>代號</Text>
						</Center>
						<Center p={"4px 8px"} flex={"1 1 60%"}>
							<Text textStyle={"semiTitle"}>說明</Text>
						</Center>
					</Flex>

					{isDrugSpecialMarkDataSuccess &&
						drugSpecialMarkLists.map((item, index) => (
							<Flex
								key={item.code}
								borderBottom={index === drugSpecialMarkLists.length - 1 ? "none" : "1px solid #ccc"}
								w='100%'
								_hover={{ bg: "gray.50", cursor: "pointer" }}
								pos={"relative"}>
								<Box pos={"absolute"} inset={"0"} onClick={() => handleCheckboxChange(index)} zIndex={1} opacity={0}></Box>
								<Center p={"4px 8px"} flex={"1 0 20%"}>
									<Checkbox
										size={"lg"}
										colorScheme='rsPrimary'
										isChecked={checkedItems[index]}
										onClick={e => e.stopPropagation()}
									/>
								</Center>
								<Center p={"4px 8px"} flex={"1 0 20%"}>
									<Text>{item.code}</Text>
								</Center>
								<Box p={"4px 8px"} flex={"1 0 60%"}>
									<Text>{item.describe}</Text>
								</Box>
							</Flex>
						))}
				</Box>
			</Flex>
		</>
	);
};
