import { callDeclareProgressService, DeclareProgressReqDataProps } from "service/declareProgressService";
import { useDeclareProgressStore } from "store/DeclarationAudit/useDeclareProgressStore";
import { useDeclareListLoadingStatusStore } from "store/DeclarationAudit/useDeclareListLoadingStatusStore";
import { useUserStore } from "store";
import Swal from "sweetalert2";

export const useDeclareProgressAPI = () => {
	const { userInfo } = useUserStore();
	const { updateDeclareProgress } = useDeclareProgressStore();
	const { setIsLoading } = useDeclareListLoadingStatusStore();

	const callDeclareProgressAPI = async (data?: DeclareProgressReqDataProps) => {
		setIsLoading(true);
		try {
			const response = await callDeclareProgressService(userInfo.token, data as DeclareProgressReqDataProps);
			setIsLoading(false);
			if (response.result === "success") {
				updateDeclareProgress(response);
			} else if (response.result === "failure") {
				updateDeclareProgress(response);
				console.log("declareProgress failure: ", response.msg);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			Swal.fire({
				icon: "error",
				title: "查詢過程發生錯誤",
				confirmButtonText: "確定"
			});
		}
		setIsLoading(false);
	};
	return { callDeclareProgressAPI };
};
