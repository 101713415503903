import { create } from "zustand";

interface DeclareListLoadingStatusState {
	isLoading: boolean;
	setIsLoading: (status: boolean) => void;
	resetIsLoading: () => void;
}

export const useDeclareListLoadingStatusStore = create<DeclareListLoadingStatusState>(set => ({
	isLoading: false,
	setIsLoading: status => set({ isLoading: status }),
	resetIsLoading: () => set({ isLoading: false })
}));
