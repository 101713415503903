import React, { useEffect } from 'react';
import { Button } from '@chakra-ui/react';
import {
    callQueueDeclareService,
    QueueDeclareReqDataProps,
} from 'service/queueDeclareService';
import { useUserStore } from 'store';
import { useDeclareSearchRangeStore } from 'store/DeclarationAudit/useDeclareSearchRangeStore';
import { useDeclareProgressStore } from 'store/DeclarationAudit/useDeclareProgressStore';
import { useDeclareProgressAPI } from 'service/declareProgressServiceHelper';
import { useDeclareTransferLoadingStatusStore } from 'store/DeclarationAudit/useDeclareTransferLoadingStatusStore';
import { getTodayYYYYMM } from 'components/CustomComponents/DateTransfer/getTodayYYYYMM';
import Swal from 'sweetalert2';

export const TransformButton = () => {
    const { userInfo } = useUserStore();
    const { declareSearchRangeData } = useDeclareSearchRangeStore();
    const { callDeclareProgressAPI } = useDeclareProgressAPI();
    const { declareProgress, updateDeclareProgress } =
        useDeclareProgressStore();
    const { setIsTransferLoading } = useDeclareTransferLoadingStatusStore();

    const callQueueDeclareAPI = async (data: QueueDeclareReqDataProps) => {
        setIsTransferLoading(true);
        try {
            const result = await callQueueDeclareService(userInfo.token, data);
            if (result) {
                if (result.result === 'success') {
                    Swal.fire({
                        title: '已將資料轉申報系統',
                        text: '請在5分鐘後回來檢視錯誤清單',
                        icon: 'success',
                    });

                    if (declareSearchRangeData.ym === '') {
                        callDeclareProgressAPI({
                            ym: getTodayYYYYMM(),
                        });
                    } else {
                        callDeclareProgressAPI({
                            ym: declareSearchRangeData.ym,
                        });
                    }
                } else {
                    console.log('發生錯誤，轉檔失敗');
                }
            }
        } catch (error) {
            console.error('轉檔過程中發生錯誤:', error);
            Swal.fire({
                title: '轉檔錯誤',
                text: '處理過程中發生未預期的錯誤',
                icon: 'error',
            });
        } finally {
            setIsTransferLoading(false);
        }
    };

    const handleClickTransform = () => {
        if (declareSearchRangeData.ym === '') {
            callQueueDeclareAPI({
                ym: getTodayYYYYMM(),
            });
        } else {
            callQueueDeclareAPI({
                ym: declareSearchRangeData.ym,
            });
        }
    };

    useEffect(() => {
        console.log('declareProgress', declareProgress);
    }, [declareProgress]);

    return (
        <Button
            size={'sm'}
            colorScheme="rsPrimary"
            onClick={handleClickTransform}
            disabled={
                declareProgress.progress === '此項作業執行中' ||
                declareProgress.progress === '已鎖檔'
            }
        >
            開始轉檔
        </Button>
    );
};
