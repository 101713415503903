import { useQuery } from 'react-query';
import Swal from 'sweetalert2';
import { callVitalSignSettingListsService } from 'service/vitalSignSettingListsService';
import { useUserStore } from 'store';

export type vitalSignSettingListsProps = {
    exam_code: null | string;
    id: string;
    if_show: string;
    name: string;
    seq: string;
    unit: string;
};
export const useVitalSignQuery = () => {
    // const token = localStorage.getItem('token')
    const { userInfo } = useUserStore();
    const { data, error, isLoading } = useQuery(
        'vitalSignSettingLists',
        () => callVitalSignSettingListsService(userInfo.token!),
        {
            onSuccess: result => {},
            onError: error => {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            },
            enabled: !!userInfo.token,
            staleTime: Infinity,
        }
    );
    return {
        lists: data?.lists,
        isLoading,
        error,
    };
};
