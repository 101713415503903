import apiService from './apiService';

export type gTypeProps = {
    code: string;
    name: string;
};
//取得地址類別
export const callGTypeAddressTypeService = async (token: string) => {
    return apiService<undefined, any>({
        method: 'GET',
        path: '/api/g_type/address_type',
        token,
    });
};

//取得四則運算
export const callGTypeArithmeticService = async (token: string) => {
    return apiService<undefined, any>({
        method: 'GET',
        path: '/api/g_type/arithmetic',
        token,
    });
};

export type bloodTypeProps = {
    code: string;
    name: string;
};
//取得血型
export const callGTypeBloodTypeService = async (token: string) => {
    return apiService<bloodTypeProps, any>({
        method: 'GET',
        path: '/api/g_type/blood_type',
        token,
    });
};

export type caseTypeProps = {
    code: string;
    name: string;
};
// 案件分類
export const callGTypeCaseTypeService = async (token: string) => {
    return apiService<caseTypeProps, any>({
        method: 'GET',
        path: '/api/g_type/case_type',
        token,
    });
};

export type benefitTypeProps = {
    code: string;
    name: string;
};
// 給付類別
export const callGTypeBenefitTypeService = async (token: string) => {
    return apiService<benefitTypeProps, any>({
        method: 'GET',
        path: '/api/g_type/benefit_type',
        token,
    });
};

export type routeOfAdministrationProps = {
    code: string;
    name: string;
};

// 給藥途徑/作用部位
export const callGTypeRouteOfAdministrationService = async (token: string) => {
    return apiService<routeOfAdministrationProps, any>({
        method: 'GET',
        path: '/api/g_type/route_of_administration',
        token,
    });
};

// 取得優免身份清單
export const callGTypeFeeTypeService = async (token: string) => {
    return apiService<undefined, any>({
        method: 'GET',
        path: '/api/g_type/fee_type',
        token,
    });
};

export type jobProps = {
    code: string;
    name: string;
};
//取得職業
export const callGTypeJobService = async (token: string) => {
    return apiService<jobProps, any>({
        method: 'GET',
        path: '/api/g_type/job',
        token,
    });
};

//取得就醫類別
export const callGTypeMedicalAffairsTypeService = async (token: string) => {
    return apiService<undefined, any>({
        method: 'GET',
        path: '/api/g_type/medical_affairs_type',
        token,
    });
};

// 取得調劑方式
export const callGTypePrescriptionStyleService = async (token: string) => {
    return apiService<undefined, any>({
        method: 'GET',
        path: '/api/g_type/prescription_style',
        token,
    });
};

export interface reservationTypeProps {
    code: string;
    name: string;
}
//取得預約方式
export const callGTypeReservationTypeService = async (token: string) => {
    return apiService<undefined, any>({
        method: 'GET',
        path: '/api/g_type/reservation_type',
        token,
    });
};

//取得身份
export const callGTypeIdentityService = async (token: string) => {
    console.log('取得身份 is called');
    return apiService<undefined, any>({
        method: 'GET',
        path: '/api/g_type/identity',
        token,
    });
};
export type regFeeIdenProps = {
    code: string;
    name: string;
};
// 健保身份
export const callGTypeRegFeeIdenService = async (token: string) => {
    console.log('取得健保 is called');
    return apiService<regFeeIdenProps, any>({
        method: 'GET',
        path: '/api/g_type/reg_fee_identity',
        token,
    });
};
export type idNoTypeProps = {
    code: string;
    name: string;
};
//  身份證件種類
export const callGTypeIdNoTypeService = async (token: string) => {
    return apiService<idNoTypeProps, any>({
        method: 'GET',
        path: '/api/g_type/id_no_type',
        token,
    });
};

// 檢驗申報方式
export type deliverExamProps = {
    code: string;
    name: string;
};

export const callGTypeDeliverExamService = async (token: string) => {
    return apiService<deliverExamProps, any>({
        method: 'GET',
        path: '/api/g_type/deliver_exam',
        token,
    });
};

export type marriageProps = {
    code: string;
    name: string;
};
//  身份證件種類
export const callGTypeMarriageService = async (token: string) => {
    return apiService<marriageProps, any>({
        method: 'GET',
        path: '/api/g_type/marriage',
        token,
    });
};
export type bornTypeProps = {
    code: string;
    name: string;
};
//  身份證件種類
export const callGTypeBornTypeService = async (token: string) => {
    return apiService<bornTypeProps, any>({
        method: 'GET',
        path: '/api/g_type/born_type',
        token,
    });
};

export type jobHistoryProps = {
    code: string;
    name: string;
};
//  職業史
export const callGTypeJobHistoryService = async (token: string) => {
    return apiService<jobHistoryProps, any>({
        method: 'GET',
        path: '/api/g_type/job_history',
        token,
    });
};

export type seekHistoryProps = {
    // code: string;
    name: string;
};
//  疾病史
export const callGTypeSeekHistoryService = async (token: string) => {
    return apiService<seekHistoryProps, any>({
        method: 'GET',
        path: '/api/g_type/seek_history',
        token,
    });
};

export type familyHistoryProps = {
    code: string;
    name: string;
};
//  家族史
export const callGTypeFamilyHistoryService = async (token: string) => {
    return apiService<familyHistoryProps, any>({
        method: 'GET',
        path: '/api/g_type/family_history',
        token,
    });
};

export type longTermDrugsProps = {
    code: string;
    name: string;
};
//  家族史
export const callGTypeLongTermDrugsService = async (token: string) => {
    return apiService<longTermDrugsProps, any>({
        method: 'GET',
        path: '/api/g_type/long_term_drugs',
        token,
    });
};

export const callLicenseTypeSecondService = async (
    token: string,
    apiEndpoint: string
) => {
    return apiService({
        method: 'GET',
        path: `/api/g_type/${apiEndpoint}`,
        token,
    });
};

export const callLicenseTypeThirdService = async (
    token: string,
    apiEndpoint: string
) => {
    return apiService({
        method: 'GET',
        path: `/api/g_type/${apiEndpoint}`,
        token,
    });
};

export interface medicalTypeProps {
    code: string;
    name: string;
}
//  取得就醫序號
export const callGTypeMedicalTypeService = async (token: string) => {
    return apiService<medicalTypeProps, any>({
        method: 'GET',
        path: '/api/g_type/medical_type',
        token,
    });
};
