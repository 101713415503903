import { create } from 'zustand';

type DeclareProgressProps = {
    HospGuid: string;
    result: string;
    Month: string;
    msg: string;
    code: string;
    progress: string;
};

interface DeclareProgressState {
    declareProgress: DeclareProgressProps;
    updateDeclareProgress: (list: DeclareProgressProps) => void;
    clearDeclareProgress: () => void;
}

export const useDeclareProgressStore = create<DeclareProgressState>(set => ({
    declareProgress: {
        HospGuid: '',
        result: '',
        Month: '',
        msg: '',
        code: '',
        progress: '',
    },
    updateDeclareProgress: list => set({ declareProgress: list }),

    clearDeclareProgress: () =>
        set({
            declareProgress: {
                HospGuid: '',
                result: '',
                Month: '',
                msg: '',
                code: '',
                progress: '',
            },
        }),
}));
