import { create } from 'zustand';
import { DeclareStaticDataProps } from 'service/declareStaticService';
interface DeclareStaticDataState {
    declareStaticData: DeclareStaticDataProps;
    updateDeclareStaticData: (data: DeclareStaticDataProps) => void;
    resetDeclareStaticData: () => void;
}

export const useDeclareStaticDataStore = create<DeclareStaticDataState>(
    set => ({
        declareStaticData: {
            total: 0,
            case_01: 0,
            case_other: 0,
            deposit: 0,
        },
        updateDeclareStaticData: data => set({ declareStaticData: data }),
        resetDeclareStaticData: () =>
            set({
                declareStaticData: {
                    total: 0,
                    case_01: 0,
                    case_other: 0,
                    deposit: 0,
                },
            }),
    })
);
