import apiService from './apiService';

export type DeclareProgressReqDataProps = {
    ym?: string;
};

export type DeclareProgressResProps = {
    result: string;
    msg: string;
    code: string;
    progress: string;
    HospGuid: string;
    Month: string;
};
export const callDeclareProgressService = async (
    token: string,
    data: DeclareProgressReqDataProps
): Promise<DeclareProgressResProps> => {
    return apiService<string, any>({
        method: 'GET',
        path: '/api/declare/progress',
        token,
        data: data,
    });
};
