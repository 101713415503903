import GroupPermissionSettingTable from "components/DataSettings/GroupPermissionSettingComponents/GroupPermissionSettingTable";
import CloseIcon from "components/CustomComponents/CloseIcon";
import { useState } from "react";
const GroupPermissionSettingPage = () => {
	const [bgOpenStatus, setBgOpenStatue] = useState(false);
	const handleOpen = () => {
		setBgOpenStatue(true);
	};
	const handleClose = () => {
		setBgOpenStatue(false);
	};
	return (
		<>
			<GroupPermissionSettingTable />
		</>
	);
};

export default GroupPermissionSettingPage;
