import { create } from "zustand";

export type ICCardUpdateScopeDataProps = {
	start_time?: string;
	end_time?: string;
	start_date?: string;
	end_date?: string;
	not_upload?: string;
};

interface ICCardUpdateScopeState {
	icCardUpdateScopeData: ICCardUpdateScopeDataProps;
	updateICCardUpdateScopeData: (newData: Partial<ICCardUpdateScopeState["icCardUpdateScopeData"]>) => void;
	resetICCardUpdateScopeData: () => void;
}

const initialICCardUpdateScopeData = {
	start_date: "",
	end_date: "",
	not_upload: "0"
};

export const useICCardUpdateScopeStore = create<ICCardUpdateScopeState>(set => ({
	icCardUpdateScopeData: initialICCardUpdateScopeData,
	updateICCardUpdateScopeData: newData =>
		set(state => ({
			icCardUpdateScopeData: {
				...state.icCardUpdateScopeData,
				...newData
			}
		})),
	resetICCardUpdateScopeData: () =>
		set(() => ({
			icCardUpdateScopeData: initialICCardUpdateScopeData
		}))
}));
