import React, { createContext, useContext, useState, ReactNode } from "react";

type NavigationItem = {
	name: string;
	path?: string;
	items?: NavigationItem[];
	key: string;
};

interface NavigationStatusContextValue {
	navigationStatus: NavigationItem[] | undefined; // 允許 undefined
	setNavigationStatus: React.Dispatch<React.SetStateAction<NavigationItem[]>>;
}

const NavigationStatusContext = createContext<NavigationStatusContextValue | undefined>(undefined);

interface NavigationStatusProviderProps {
	children: ReactNode;
}

export const NavigationStatusProvider: React.FC<NavigationStatusProviderProps> = ({ children }) => {
	const [navigationStatus, setNavigationStatus] = useState<NavigationItem[]>([]); // 允許 undefined

	return (
		<NavigationStatusContext.Provider value={{ navigationStatus, setNavigationStatus }}>{children}</NavigationStatusContext.Provider>
	);
};

export const useNavigationStatus = (): NavigationStatusContextValue => {
	const context = useContext(NavigationStatusContext);
	if (context === undefined) {
		throw new Error("useNavigationStatus must be used within a NavigationStatusProvider");
	}
	return context;
};
