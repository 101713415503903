import apiService from "./apiService";

export const callCityService = async (token: string, data?: string) => {
	const queryParams = data ? { data } : undefined;
	console.log(queryParams, "還沒出去到setupproxy");
	return apiService<string, any>({
		method: "GET",
		path: "/api/city",
		token,
		data: queryParams
	});
};
