import { Box, Flex, Text, Button, List } from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { AllergyLists, AddAllergyItem } from "./AllergyMedicationComponents";
import { useTreatmentInfoStore } from "store/TreatmentManagement";
import { postPatientInfoServiceForAllergyMedicationService, PatientInfoServiceForAllergyMedicationProps } from "service/patientInfoService";
import { useUserStore } from "store";

export const AllergyMedication = () => {
	const [isShowAll, setIsShowAll] = useState<boolean>(false);
	const [editingIndex, setEditingIndex] = useState<number>(-1);
	const [checkValue, setCheckValue] = useState<string>("");
	const [newAllergyItem, setNewAllergyItem] = useState<string>("");
	const { userInfo } = useUserStore();

	const { treatmentInfo } = useTreatmentInfoStore();
	const [isLoading, setIsLoading] = useState(true);
	const [allergyMedicationList, setAllergyMedicationList] = useState<string[]>([]);

	const postPatientInfoServiceForAllergyMedicationAPI = async (allergyMedicationListStr: string, action: string) => {
		console.log(allergyMedicationListStr, "先看");
		let finalData: PatientInfoServiceForAllergyMedicationProps = {
			info: {
				data_id: treatmentInfo.patient_uid,
				drug_allergy_describe: allergyMedicationListStr
			}
		};
		try {
			const result = await postPatientInfoServiceForAllergyMedicationService(userInfo.token, finalData);
			if (result.result === "success") {
				Swal.fire({
					icon: "success",
					title: `${action}成功`,
					timer: 1500,
					showConfirmButton: false
				});
			}
		} catch (error) {
			console.error("更新過敏藥物資訊時發生錯誤:", error);
			Swal.fire({
				icon: "error",
				title: "更新失敗",
				text: "更新過敏藥物資訊時發生錯誤，請稍後再試。",
				confirmButtonText: "確定"
			});
		}
	};

	useEffect(() => {
		setAllergyMedicationList([]);
		if (typeof treatmentInfo.drug_allergy_describe === "string" && treatmentInfo.drug_allergy_describe.length > 0) {
			const drugAllergyDescribeArray = treatmentInfo.drug_allergy_describe.split(",");
			console.log(drugAllergyDescribeArray);
			setAllergyMedicationList(drugAllergyDescribeArray);
		}
		setIsLoading(false);
	}, [treatmentInfo]);

	const handleAddItem = async () => {
		if (newAllergyItem !== "") {
			if (allergyMedicationList.includes(newAllergyItem)) {
				Swal.fire({
					icon: "warning",
					title: "重複項目",
					text: "此過敏藥物已存在於列表中。請輸入不同的藥物名稱。",
					confirmButtonText: "確定"
				});
			} else {
				const newList = [...allergyMedicationList, newAllergyItem];
				setAllergyMedicationList(newList);
				const newStr = newList.join(",");
				postPatientInfoServiceForAllergyMedicationAPI(newStr, "新增");
				setNewAllergyItem("");
			}
		}
	};

	const handleDeleteItem = (index: number) => {
		Swal.fire({
			icon: "question",
			title: "是否要刪除此項目？",
			confirmButtonText: "是",
			cancelButtonText: "否",
			showCancelButton: true
		}).then(result => {
			if (result.isConfirmed) {
				const otherList = allergyMedicationList.filter((_, subIndex) => subIndex !== index);
				setAllergyMedicationList(otherList);
				const newStr = otherList.join(",");
				postPatientInfoServiceForAllergyMedicationAPI(newStr, "刪除");
			}
		});
	};

	const handleChangeItem = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const newValue = e.target.value;
		setAllergyMedicationList(prevList => {
			const newList = [...prevList];
			newList[index] = newValue;
			return newList;
		});
	};

	const handleEditItem = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
		setEditingIndex(index);
		const value = (e.target as HTMLDivElement).dataset.value!;
		setCheckValue(value);
	};

	const handleCancelItem = (index: number) => {
		setAllergyMedicationList(prevList => {
			const List = [...prevList];
			List[index] = checkValue;
			return List;
		});
		setEditingIndex(-1);
		setCheckValue("");
	};

	const handleSaveItem = async () => {
		try {
			const newStr = allergyMedicationList.join(",");
			await postPatientInfoServiceForAllergyMedicationAPI(newStr, "修改");
			setEditingIndex(-1);
		} catch (error) {
			console.error("修改過敏藥物時發生錯誤:", error);
			// 這裡可以添加錯誤處理邏輯，例如顯示錯誤消息給用戶
		}
	};

	return (
		<>
			<Flex
				direction='column'
				gap={"4px"}
				zIndex={isShowAll ? 50 : 0}
				pos={isShowAll ? "fixed" : "relative"}
				bg={"#fff"}
				w={"100%"}
				maxW={isShowAll ? "480px" : "100%"}
				maxH={isShowAll ? "auto" : "96px"}
				p={isShowAll ? "8px 16px" : "0"}
				rounded={isShowAll ? "lg" : "none"}
				boxShadow={isShowAll ? "lg" : "none"}
				overflow={"auto"}>
				<Flex justify={"space-between"} pb={isShowAll ? "8px" : "0px"}>
					<Text textStyle={"semiTitle"}>
						過敏藥物 <Box as='span'>({allergyMedicationList && allergyMedicationList.length})</Box>
					</Text>
					{!isShowAll ? (
						<Button variant={"link"} colorScheme='gray' size={"sm"} onClick={() => setIsShowAll(pre => !pre)}>
							編輯 <HeroIcons name='chevron-right-sm' />
						</Button>
					) : (
						<Button
							variant={"link"}
							colorScheme='gray'
							size={"sm"}
							onClick={() => {
								setIsShowAll(pre => !pre);
								setEditingIndex(-1);
							}}>
							<HeroIcons name='chevron-left-sm' /> 完成
						</Button>
					)}
				</Flex>
				<List>
					<AllergyLists
						items={allergyMedicationList}
						isShowAll={isShowAll}
						editingIndex={editingIndex}
						handleDeleteItem={handleDeleteItem}
						handleEditItem={handleEditItem}
						handleChangeItem={handleChangeItem}
						handleCancelItem={handleCancelItem}
						handleSaveItem={handleSaveItem}
						setEditingIndex={setEditingIndex}
						checkValue={checkValue}
						setCheckValue={setCheckValue}
					/>
					<AddAllergyItem
						isShowAll={isShowAll}
						handleAddItem={handleAddItem}
						newAllergyItem={newAllergyItem}
						setNewAllergyItem={setNewAllergyItem}
					/>
				</List>
			</Flex>
			<Box
				pos={"fixed"}
				zIndex={40}
				inset={0}
				bg={"rgba(0,0,0,0.32)"}
				hidden={!isShowAll}
				onClick={() => {
					if (editingIndex !== -1) {
						Swal.fire({
							icon: "question",
							title: "是否要結束編輯？",
							confirmButtonText: "是",
							cancelButtonText: "否",
							showCancelButton: true
						}).then(result => {
							if (result.isConfirmed) {
								setIsShowAll(pre => !pre);
								setEditingIndex(-1);
							}
						});
					} else {
						setIsShowAll(pre => !pre);
					}
				}}
			/>
		</>
	);
};

export default AllergyMedication;
