import {
    Flex,
    Box,
    Text,
    Button,
    Center,
    Checkbox,
    list,
} from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import { useIsICWriteInOpenStatus } from 'contexts/ICUpload/IsICWriteInOpen';
import { icDataListsDataProps } from 'service/icDataListsService';
import { ADStringToROCString } from 'functions/ADStringToROCString';
import { useEffect } from 'react';
import { useCallback } from 'react';

import { useUserStore } from 'store';
import {
    postIcDataChangeStatusService,
    icDataChangeStatusReqDataProps,
} from 'service/icDataChangeStatueService';
import { postIcDataDataSetService } from 'service/icDataDataSetService';
import { useLocation, useNavigate } from 'react-router-dom';

import { useIcDataListsAPI } from 'service/icDataListsServiceHelper';
import { useIcDataVoidAPI } from 'service/icDataVoidServiceHelper';
import { useICCardUpdateListStore } from 'store/ICCardUpdate/useICCardUpdateListStore';
import { useICCardUpdateShowStatusStore } from 'store/ICCardUpdate/useICCardUpdateShowStatusStore';
import { usePrescriptionStore } from 'store/TreatmentManagement';
import { useMedicalRecordStatus } from 'contexts/TreatmentManagement/MedicalRecordStatusContext';
import { useHistoryStore } from 'store/TreatmentManagement';
import {
    useTreatmentInfoStore,
    useCCPEStore,
    useDxStore,
    usePricingStore,
} from 'store/TreatmentManagement';
import { useInstitutionInfoStore } from 'store/DataSetting/useInstitutionInfoStore';

import { ICUpload2API } from 'service/RsService/ICUpload2APIHelper';
import { useCustomerAPI } from 'service/customerServiceHelper';

import Swal from 'sweetalert2';

export type BodyProps = {
    tabIndex?: number;
    listData?: icDataListsDataProps[];
};

export const Body: React.FC<BodyProps> = ({ tabIndex, listData }) => {
    const { userInfo } = useUserStore();
    const { callIcDataListsAPI } = useIcDataListsAPI();
    const { callIcDataVoidAPI } = useIcDataVoidAPI();
    const { updateTreatmentInfo, resetTreatmentInfo } = useTreatmentInfoStore();
    const { resetHistoryData } = useHistoryStore();
    const { updatePrescription, resetPrescriptionList } =
        usePrescriptionStore();
    const { updateDxList, resetDx } = useDxStore();
    const navigate = useNavigate();
    const { resetPricing } = usePricingStore();
    const { updateCCPEData, resetCCPEData } = useCCPEStore();
    const { setMedicalRecordStatus } = useMedicalRecordStatus();
    const { isICWriteInOpenStatus, setIsICWriteInOpenStatus } =
        useIsICWriteInOpenStatus();
    const { icCardUpdateList, updateICCardUpdateList, clearICCardUpdateList } =
        useICCardUpdateListStore();

    const handleICWriteInSwitch = () => {
        setIsICWriteInOpenStatus(prev => !prev);
    };

    const { institutionInfoData, updateInstitutionInfoData } =
        useInstitutionInfoStore();
    const { callCustomerAPI } = useCustomerAPI();

    const { showStatus } = useICCardUpdateShowStatusStore();

    // 取得院所資料 callCustomerAPI

    useEffect(() => {
        callCustomerAPI();
        console.log('院所基本資料:', institutionInfoData);
    }, []);

    useEffect(() => {
        console.log('body 的 listData:', listData);
    }, [listData]);

    const formatDate = (dateStr: string): string => {
        if (!dateStr) return '';
        const year = dateStr.slice(0, 3);
        const month = dateStr.slice(3, 5);
        const day = dateStr.slice(5, 7);
        return `${year}.${month}.${day}`;
    };

    const handleChangeStatus = (
        tabIndex: number,
        medical_record_uid: string
    ) => {
        let data = {
            data_id: medical_record_uid,
            status: tabIndex === 0 ? 'void' : 'formal',
        };

        callIcDataChangeStatusAPI(data);
    };

    const callIcDataChangeStatusAPI = async (
        data: icDataChangeStatusReqDataProps
    ) => {
        try {
            const result = await postIcDataChangeStatusService(
                userInfo.token,
                data
            );

            if (result.result === 'success') {
                if (tabIndex === 0) {
                    if (data.status === 'void') {
                        Swal.fire({
                            icon: 'success',
                            title: '已作廢',
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    }

                    if (data.status === 'cancel') {
                        Swal.fire({
                            icon: 'success',
                            title: '已退掛',
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    }

                    callIcDataListsAPI();
                } else if (tabIndex === 1) {
                    Swal.fire({
                        icon: 'success',
                        title: '已復原',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    callIcDataVoidAPI();
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '更新失敗',
                    text: result.msg,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleGoToConsultingRoom = useCallback(
        (id: string | undefined, treated_at: string) => {
            if (id) {
                console.log('Go to consulting room', id);
                resetTreatmentInfo();
                resetHistoryData();
                resetPrescriptionList();
                resetDx();
                resetCCPEData();
                resetPricing();
                setMedicalRecordStatus('2');
                updateTreatmentInfo({
                    id: id,
                });
                setTimeout(() => {
                    navigate('/OPDPage/ConsultingRoomPage');
                }, 0);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '找不到此病歷',
                    text: '請確認病歷資料是否正確',
                    showConfirmButton: true,
                });
            }
        },
        [
            resetTreatmentInfo,
            resetHistoryData,
            resetPrescriptionList,
            resetDx,
            resetCCPEData,
            resetPricing,
            setMedicalRecordStatus,
            updateTreatmentInfo,
            navigate,
        ]
    );

    const handleChangeReady = (medical_record_uid: string) => {
        updateICCardUpdateList(
            icCardUpdateList.map(item =>
                item.medical_record_uid === medical_record_uid
                    ? { ...item, ready: !item.ready }
                    : item
            )
        );
    };

    const handleCancelReg = (medical_record_uid: string) => {
        Swal.fire({
            title: '確定執行嗎？',
            text: '執行後將無法恢復',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '確定IC退掛',
            cancelButtonText: '取消',
        }).then(result => {
            if (result.isConfirmed) {
                postIcDataCancelReg(medical_record_uid);
            } else {
                return;
            }
        });
    };

    // ic 退掛
    const postIcDataCancelReg = async (medical_record_uid: string) => {
        try {
            const data = {
                ids: [medical_record_uid],
                action: 'cancel_reg',
            };

            const result = await postIcDataDataSetService(userInfo.token, data);
            if (result.result === 'success') {
                const ic_upload2_data = {
                    sHospid: institutionInfoData.nhid,
                    sPrecs: result.drugs_count,
                    sPatData: result.ic_content,
                    sType: 'A1',
                    sMrecs: result.medical_record_count,
                };
                const icUpload2Result = await ICUpload2API(ic_upload2_data);
                if (icUpload2Result) {
                    callIcDataListsAPI();
                    let data = {
                        data_id: medical_record_uid,
                        status: 'cancel',
                    };
                    callIcDataChangeStatusAPI(data);
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '取得要上傳的 IC 資料失敗',
                    text: result.msg,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            Swal.fire({
                icon: 'error',
                title: '發生錯誤',
                text: '請重新整理頁面',
                confirmButtonText: '確定',
            });
        }
    };

    return (
        <>
            <Flex direction={'column'}>
                {listData &&
                    listData
                        .filter(item =>
                            tabIndex === 0
                                ? showStatus === '' ||
                                  item.upload_status.toString() === showStatus
                                : item.upload_status === 5
                        )
                        .map((item, index) => (
                            <Flex
                                borderBottom={'1px solid  #D9D9D9'}
                                align={'center'}
                                py={'4px'}
                                key={index}
                            >
                                <Box flex={'0 0 5%'}>
                                    <Center borderLeft={'1px solid #fff'}>
                                        {item.signature ? (
                                            <Button
                                                colorScheme="rsPrimary"
                                                variant={'ghost'}
                                                size={'sm'}
                                            >
                                                已簽
                                            </Button>
                                        ) : (
                                            <Button
                                                colorScheme="rsDanger"
                                                variant={'outline'}
                                                bg={'#ECECEC'}
                                                size={'sm'}
                                                boxShadow={
                                                    '4px 4px 2px 0px rgba(0, 0, 0, 0.25) inset;'
                                                }
                                            >
                                                未簽
                                            </Button>
                                        )}
                                    </Center>
                                </Box>

                                <Box flex={'0 0 5%'} hidden={tabIndex === 1}>
                                    <Center
                                        px={'4px'}
                                        borderLeft={'1px solid #fff'}
                                    >
                                        {
                                            <Checkbox
                                                colorScheme="rsPrimary"
                                                isChecked={item.ready}
                                                onChange={() => {
                                                    handleChangeReady(
                                                        item.medical_record_uid
                                                    );
                                                }}
                                            ></Checkbox>
                                        }
                                    </Center>
                                </Box>
                                <Box flex={'0 0 5%'}>
                                    <Text textAlign={'center'}>
                                        {item.upload_status === 0
                                            ? '未傳'
                                            : item.upload_status === 1
                                              ? '已傳'
                                              : item.upload_status === 5
                                                ? '作廢'
                                                : ''}
                                    </Text>
                                </Box>
                                <Box flex={'0 0 5%'}>
                                    <Box
                                        px={'4px'}
                                        borderLeft={'1px solid #fff'}
                                        opacity={0.5}
                                        _hover={{
                                            cursor: 'pointer',
                                            opacity: 1,
                                        }}
                                        // onClick={handleICWriteInSwitch} //先放著
                                        onClick={() =>
                                            handleGoToConsultingRoom(
                                                item.opd_uid,
                                                item.treated_at
                                            )
                                        }
                                    >
                                        <HeroIcons name="read-record" />
                                    </Box>
                                </Box>

                                <Box flex={'0 0 6%'}>
                                    <Text
                                        px={'4px'}
                                        borderLeft={'1px solid #fff'}
                                    >
                                        {item.treated_at
                                            ? ADStringToROCString(
                                                  item.treated_at
                                              )
                                            : ''}
                                    </Text>
                                </Box>
                                <Box flex={'0 0 6%'}>
                                    <Text
                                        px={'4px'}
                                        borderLeft={'1px solid #fff'}
                                    >
                                        {item.treated_at
                                            ? item.treated_at.slice(11, 19)
                                            : ''}
                                    </Text>
                                </Box>
                                <Box flex={'0 0 10%'}>
                                    <Text
                                        px={'4px'}
                                        borderLeft={'1px solid #fff'}
                                    >
                                        {item.medical_record_number}
                                    </Text>
                                </Box>
                                <Box flex={'1 0 8%'}>
                                    <Text
                                        px={'4px'}
                                        borderLeft={'1px solid #fff'}
                                    >
                                        {item.name}
                                    </Text>
                                </Box>

                                <Box flex={'0 0 10%'}>
                                    <Text
                                        px={'4px'}
                                        borderLeft={'1px solid #fff'}
                                    >
                                        {item.id_no}
                                    </Text>
                                </Box>
                                <Box flex={'0 0 10%'}>
                                    <Text
                                        px={'4px'}
                                        borderLeft={'1px solid #fff'}
                                    >
                                        {item.birth_date
                                            ? formatDate(item.birth_date)
                                            : ''}
                                    </Text>
                                </Box>
                                <Box flex={'0 0 5%'}>
                                    <Box
                                        px={'4px'}
                                        borderLeft={'1px solid #fff'}
                                    >
                                        {/* {item.return_single && (
                                        <Box textColor={'rsPrimary.500'}>
                                            <HeroIcons name="check-circle-fill" />
                                        </Box>
                                    )} */}
                                    </Box>
                                </Box>
                                <Box flex={'0 0 7%'}>
                                    <Text
                                        px={'4px'}
                                        borderLeft={'1px solid #fff'}
                                    >
                                        {item.mt_type}
                                    </Text>
                                </Box>
                                <Box flex={'0 0 5%'}>
                                    <Text
                                        px={'4px'}
                                        borderLeft={'1px solid #fff'}
                                    >
                                        {item.card_no}
                                    </Text>
                                </Box>

                                <Box flex={'0 0 5%'}>
                                    <Box
                                        px={'4px'}
                                        borderLeft={'1px solid #fff'}
                                    >
                                        <Box
                                            hidden={
                                                tabIndex === 0 &&
                                                (item.upload_status === 1 ||
                                                    item.upload_status === 5)
                                            }
                                            textColor={'rsPrimary.500'}
                                            opacity={0.8}
                                            _hover={{
                                                opacity: 1,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                handleChangeStatus(
                                                    tabIndex!,
                                                    item.medical_record_uid
                                                );
                                            }}
                                        >
                                            {tabIndex === 0 ? (
                                                <HeroIcons name="delete" />
                                            ) : tabIndex === 1 ? (
                                                <HeroIcons name="return" />
                                            ) : (
                                                ''
                                            )}
                                        </Box>
                                    </Box>
                                </Box>

                                <Box flex={'0 0 8%'} hidden={tabIndex === 1}>
                                    <Center
                                        px={'4px'}
                                        borderLeft={'1px solid #fff'}
                                        hidden={
                                            item.upload_status === 0 ||
                                            item.upload_status === 5
                                        }
                                    >
                                        <Button
                                            colorScheme="rsDanger"
                                            size={'sm'}
                                            onClick={() => {
                                                handleCancelReg(
                                                    item.medical_record_uid
                                                );
                                            }}
                                        >
                                            退掛
                                        </Button>
                                    </Center>
                                </Box>
                            </Flex>
                        ))}
            </Flex>
        </>
    );
};
