import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

type ShowCases = {
    title: string;
    num: number;
};
export const ShowCases: React.FC<ShowCases> = ({ title, num }) => {
    return (
        <Flex
            textColor={'#fff'}
            gap={5}
            rounded={'lg'}
            bg={'#646B48'}
            p={'8px 16px'}
            boxShadow={'inset 4px 2px 2px rgba(0,0,0,0.25)'}
        >
            <Text>{title}</Text>
            <Text fontWeight={'semibold'}>{num} </Text>
        </Flex>
    );
};
