import apiService from "./apiService";

export type accItemsLevelProps = {
	id: string;
	code: string;
	name: string;
};

export const callAccItemsLevelService = async (token: string, data: number) => {
	const queryParams = data ? { data } : undefined;
	return apiService<string, any>({
		method: "GET",
		path: "/api/acc_items/level",
		token,
		data: queryParams
	});
};
