import React from "react";
import { Button, Box, Text } from "@chakra-ui/react";
import { useDeclareDownloadAPI } from "service/declareDownloadServiceHelper";
import { useDeclareSearchRangeStore } from "store/DeclarationAudit/useDeclareSearchRangeStore";

export const DownloadTOTFAButton = () => {
	const { callDeclareDownloadAPI } = useDeclareDownloadAPI();
	const { declareSearchRangeData } = useDeclareSearchRangeStore();
	const handleDownload = () => {
		if (declareSearchRangeData.ym) {
			callDeclareDownloadAPI(declareSearchRangeData.ym);
		} else {
			callDeclareDownloadAPI();
		}
	};

	return (
		<>
			<Button size={"sm"} colorScheme='rsPrimary' onClick={handleDownload}>
				下載TOTFA
			</Button>
		</>
	);
};
