/* Hooks */
import React, { useEffect, useState, useRef } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { Input, Select } from "@chakra-ui/react";
import zhTW from "date-fns/locale/zh-TW";
import moment from "moment";

/* decalre type */
interface DatePickerProps extends ReactDatePickerProps {
	selectedDate: Date | null;
	handleChangeDate: (date: Date | null) => void;
}

const ROCDatePickerNew: React.FC<DatePickerProps> = ({
	selectedDate,
	handleChangeDate,

	...props
}) => {
	// useEffect(()=>{
	//     console.log(selectedDate, '一拿到的日期')
	// }, [])
	const [currentMonth, setCurrentMonth] = useState<number>(
		parseInt(moment(selectedDate)?.format("MM")) || parseInt(moment(new Date()).format("MM"))
	);

	const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
	const thisYear = new Date().getFullYear();
	const startYear = thisYear - 100;
	const endYear = thisYear + 20;
	const inputRef = useRef<HTMLInputElement>(null);
	const [ROCDate, setROCDate] = useState<string | undefined>(undefined);
	const [inputValue, setInputValue] = useState("");
	const handleChangeDateByInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		// console.log(value, '入的日期');

		if (value.match(/^\d{3}\.\d{2}\.\d{2}$/)) {
			const [rocYear, monthStr, dayStr] = value.split(".");
			const year = parseInt(rocYear, 10) + 1911; // 將民國年轉換為西元年
			const month = parseInt(monthStr, 10); // 將月份字符串轉換為數字
			const day = parseInt(dayStr, 10); // 將日期字符串轉換為數字

			if (year > 2300) {
				setInputValue("");
				handleChangeDate(null);
				return;
			}

			const formattedDate = `${year}-${month}-${day}`;
			if (moment(formattedDate, "YYYY-MM-DD").isValid()) {
				const parsedDate = new Date(year, month - 1, day);
				handleChangeDate(parsedDate);
				setInputValue(value);
			} else {
				setInputValue("");
				handleChangeDate(null);
			}
		} else {
			setInputValue("");
			handleChangeDate(null);
		}
	};

	/* split the dot */
	const handleBlur = (event: any) => {
		if (event.currentTarget.value.length === 7) {
			const enteredValue = event.currentTarget.value;
			const year = parseInt(enteredValue.substring(0, 3)) + 1911;
			const month = enteredValue.substring(4, 6);
			const day = enteredValue.substring(7, 9);

			const parsedDate = moment(`${year}.${month}.${day}`, "YYYY.MM.DD");
			if (parsedDate.isValid()) {
				handleChangeDate(parsedDate.toDate());
			}
		} else if (event.currentTarget.value.length === 6) {
			const enteredValue = event.currentTarget.value;
			const year = parseInt(`0${enteredValue.substring(0, 2)}`) + 1911;
			const month = enteredValue.substring(2, 4);
			const day = enteredValue.substring(4, 6);
			const parsedDate = moment(`${year}.${month}.${day}`, "YYYY.MM.DD");
			if (parsedDate.isValid()) {
				handleChangeDate(parsedDate.toDate());
			}
		}
	};
	// useEffect(() => {
	//     if (selectedDate) {
	//         const rocYear = selectedDate.getFullYear() - 1911;
	//         const formattedDate = moment(selectedDate).format('MM.DD');
	//         setInputValue(`${rocYear}.${formattedDate}`);
	//     } else {
	//         setInputValue('');
	//     }
	//     console.log('共用組件 1')
	// }, [selectedDate]);

	// useEffect(() => {
	//     if (selectedDate) {
	//         const selectedYear = moment(selectedDate).format('YYYY');
	//         const IntYear = parseInt(selectedYear);
	//         if (IntYear) {
	//             setCurrentYear(IntYear);
	//         }
	//     }
	//     console.log('共用組件 2')
	// }, [selectedDate]);
	// /* change to ROC years */
	// useEffect(() => {
	//     if (selectedDate) {
	//         const rocYear = selectedDate.getFullYear() - 1911;
	//         setROCDate(`${rocYear}.MM.dd`);
	//         // setFinalDate(selectedDate)
	//     } else {
	//         setROCDate(undefined);
	//     }
	//     console.log('共用組件 3')
	// }, [selectedDate]);
	// /* choose the month */
	// useEffect(() => {
	//     if (selectedDate) {
	//         const month = parseInt(moment(selectedDate).format('MM'));
	//         setCurrentMonth(month);
	//     }
	//     console.log('共用組件 4')
	// }, [selectedDate]);
	// useEffect(() => {
	//     if (selectedDate) {
	//         const newDate = new Date(selectedDate);
	//         newDate.setFullYear(currentYear);
	//         handleChangeDate(newDate);
	//     }
	//     console.log('共用組件 5')
	// }, [currentYear]);
	useEffect(() => {
		if (selectedDate) {
			const rocYear = selectedDate.getFullYear() - 1911;
			const formattedDate = moment(selectedDate).format("MM.DD");
			setInputValue(`${rocYear}.${formattedDate}`);

			const selectedYear = moment(selectedDate).year();
			setCurrentYear(selectedYear);

			setROCDate(`${rocYear}.MM.dd`);

			const month = parseInt(moment(selectedDate).format("MM"), 10);
			setCurrentMonth(month);

			// console.log('共用組件逻辑');
		} else {
			setInputValue("");
			setROCDate(undefined);
		}
	}, [selectedDate]);

	// useEffect(() => {
	//     if (selectedDate && typeof currentYear === 'number') {
	//         const newDate = new Date(selectedDate);
	//         newDate.setFullYear(currentYear);
	//         handleChangeDate(newDate);
	//     }
	//     console.log('共用組件年份更改');
	// }, [currentYear, selectedDate, handleChangeDate]);

	return (
		<>
			<DatePicker
				showYearDropdown
				showMonthDropdown
				scrollableYearDropdown
				selected={selectedDate}
				yearDropdownItemNumber={100}
				locale={zhTW}
				todayButton='回到今天'
				yearItemNumber={4}
				dateFormat={ROCDate}
				placeholderText='請選擇日期'
				customInput={
					<Input
						ref={inputRef}
						onChange={handleChangeDateByInput}
						onBlur={handleBlur}
						value={inputValue}
						maxLength={7}
						min='1900'
						max='2099'
					/>
				}
				renderCustomHeader={({ changeYear, changeMonth }) => (
					<div className='flex justify-center items-center gap-2 '>
						<div>
							<Select
								size={"sm"}
								onChange={e => {
									const selectedYear = parseInt(e.target.value);
									setCurrentYear(selectedYear);
									changeYear(selectedYear);
									e.stopPropagation();
								}}
								className='text-base bg-gray-100 cursor-pointer z-50 w-full'
								value={currentYear}>
								{Array.from({ length: endYear - startYear + 1 }, (_, index) => {
									const year = startYear + index;
									return (
										<option key={index} value={year}>
											民國
											{year > 1911 ? year - 1911 : year} 年
										</option>
									);
								})}
							</Select>
						</div>
						<div>
							<Select
								className='text-base bg-gray-100 cursor-pointer z-50'
								size={"sm"}
								onChange={e => {
									const selectedMonth = parseInt(e.target.value);
									setCurrentMonth(selectedMonth);
									changeMonth(selectedMonth - 1);
									e.stopPropagation();
								}}
								value={currentMonth}>
								{Array.from({ length: 12 }, (_, index) => {
									const month = index + 1;
									return (
										<option key={index} value={month}>
											{month}月
										</option>
									);
								})}
							</Select>
						</div>
					</div>
				)}
				{...props}
			/>
		</>
	);
};

export default ROCDatePickerNew;
