import axios from "axios";
import Swal from "sweetalert2";

export interface VHChisGetRegisterBasicJsonResProps {
	cardNo: string; // 健保卡號
	name: string; // 姓名
	ROCID: string; // 身分證字號
	birthDate: string; // 出生日期
	sex: string; // 性別
	cardIssueDate: string; // 卡片發卡日期
	cardStatusNote: string; // 卡片狀態說明
	HICCode: string; // 健保身分代碼
	HICTypeNote: string; // 健保身分說明
	cardExpireDate: string; // 卡片有效期限
	availableClinicTimes: string; // 可用就診次數
	newBornDate: string; // 新生兒出生日期
	newBornNote: string; // 新生兒註記
}

export const postVHChisGetRegisterBasicJsonService = async (): Promise<VHChisGetRegisterBasicJsonResProps | null> => {
	try {
		const response = await axios.post<VHChisGetRegisterBasicJsonResProps>(
			"http://localhost:3033/VHC/VHChisGetRegisterBasicJson",
			{},
			{
				headers: {
					Accept: "*/*",
					"Content-Type": "application/json"
				}
			}
		);

		console.log(response.data, "再次要求授權");
		return response.data;
	} catch (error: unknown) {
		if (error instanceof Error) {
			console.error("Error:", error.message);
		} else {
			console.error("An unknown error occurred");
		}
		Swal.fire({
			icon: "error",
			title: "請檢查代理人及虛擬卡是否成功授權",
			timer: 1500,
			showConfirmButton: false
		});
		return null;
	}
};
