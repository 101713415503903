import {
	Table,
	Thead,
	Tbody,
	Tr,
	Td,
	Th,
	Select,
	Button,
	InputGroup,
	InputLeftElement,
	Input,
	Tabs,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Box,
	Flex,
	Text,
	Checkbox,
	Tooltip
} from "@chakra-ui/react";

import { useState, useEffect, useRef } from "react";
import React from "react";
import Swal from "sweetalert2";

import { useQuery } from "react-query";

// Components
import Loading from "components/CustomComponents/Loading";
import DrugDataMaintenanceBasicInfo from "./DrugDataMaintenanceBasicInfo";
import DrugDataMaintenanceAttachSo from "./DrugDataMaintenanceAttachSo";
import DrugDataMaintenanceMedicalOrder from "./DrugDataMaintenanceMedicalOrder";
import DrugDataMaintenanceDiagnosis from "./DrugDataMaintenanceDiagnosis";
import DrugDataMaintenancePediatricDose from "./DrugDataMaintenancePediatricDose";
import DrugDataMaintenanceRelatedSetting from "./DrugDataMaintenanceRelatedSetting";
import DrugDataMaintenanceMedicationInfo from "./DrugDataMaintenanceMedicationInfo";

import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";

// Status
import { useAutoSavedStatus } from "contexts/AutoSavedStatusContext";
import { useUnsavedChanges } from "contexts/UnsavedChangesContext";

// 藥品維護 API
import { postDrugsOfficialService } from "service/drugsOfficialService";
import { postDrugsInUseService } from "service/drugsInUseService";

// 基本資料
import { callDrugBasicService } from "service/drugBasicService";
import { postDrugBasicService } from "service/drugBasicService";

//小兒劑量
import { callDrugChildrenDosageService } from "service/drugChildrenDosageService";
import { postDrugChildrenDosageService } from "service/drugChildrenDosageService";

//附加S.O
import { callDrugAttachSoService } from "service/drugAttachSoService";
import { postDrugAttachSoService } from "service/drugAttachSoService";

//附加醫令
import { callDrugAttachDrugsService } from "service/drugAttachDrugsService";
import { postDrugAttachDrugsService } from "service/drugAttachDrugsService";

//附加診斷
import { callDrugAttachICDService } from "service/drugAttachICDService";
import { postDrugAttachIcdService } from "service/drugAttachICDService";

// 相關設定
import { callDrugSpecificationsService } from "service/drugSpecificationsService";
import { postDrugSpecificationsService } from "service/drugSpecificationsService";

import { useDropDownMenuQuery } from "query/useDropDownMenuQuery";

import { useUserStore } from "store";

// lists 的資料型態
interface ShowListsProps {
	data_id: string;
	name_tw: string;
	name_en: string;
	short_code: string;
	unit: string;
	drug_type: string;
	drug_type_name?: string;
	dosage_form: string;
	freq: string;
	freq_directions: string;
	drug_no: string;
	drug_price: string;
}

//基本資料的物件
export interface BasicInfoProps {
	data_id?: string;
	short_code: string;
	name_tw: string;
	name_en?: string;
	unit?: string;
	drug_type: string;
	dosage_form?: string;
	freq: string;
	freq_directions: string;
	taking_time?: string;
	drug_no: string;
	drug_price?: string;
	self_price?: string;
	cost?: string;
	rateType?: string;
	childrenBonus?: string;
	ingredientCode?: string;
	DRGCode?: string;
	controlled_level?: string | null;
	alternativeMedicine?: string;
	dosing_days?: string;
	route_of_administration?: string;
	dose?: string;
	max_dose?: string;
	max_dose_day?: string;
	treatmentCode?: string;
	specificPart?: string;
	disabled_date?: string;
	custom_name_tw?: string;
	custom_name_en?: string;
}

interface drugTypeListProps {
	data_id: string;
	code: string;
	name: string;
}

// 小兒劑量 的資料型態
export interface PediatricDoseProps {
	[key: `kg_${number}`]: string | null;
}

// 附加 SO 的物件
export interface soDataProps {
	subjective: string;
	objective: string;
}

// 附加醫令
export interface MedicalOrderListsProps {
	attach_drug_uid?: string;
	data_id?: string;
	name_tw: string;
	name_en?: string;
	short_code: string;
	drug_no: string;
}
// 附加診段
//之後要改喔 這邊先不用一定要id
export interface DiagnosisListsProps {
	attach_icd_uid?: string;
	data_id?: string;
	name_tw: string;
	name_en?: string;
	short_code: string;
	icd_code: string;
}
// 相關藥品物件 暫定待改
export interface relatedSettingDataProps {
	bottle_package: string;
	package_capacity: string;
	package_unit: string;
	package_qty: string;
	administrationRoute: string;
	check_insure_status: string;
	exam_declare: string;
	drg_code: string;
	ingredientCode: string;
	substitute: string;
	specific_part: string;
	treatment_code: string;
	deliver_exam_type: string;
	deliver_exam_lab: string;
	lab_name: string;
}

// 用藥資訊 的物件
export interface MedicationInfoProps {
	medicationWaring: string;
}

const DrugDataMaintenanceTable = () => {
	const [keyword, setKeyword] = useState<string>("");

	const { autoSavedStatus, setAutoSavedStatus } = useAutoSavedStatus();
	const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [isNotChanged, setIsNotChanged] = useState(false);

	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const { userInfo } = useUserStore();

	// 高度
	const [preferentialStatusTableHeight, setDrugDataMaintenanceTableTableHeight] = useState(0);
	const windowHeight = window.innerHeight;
	const [topTableHeight, setTopTableHeight] = useState(0);
	const [bottomTableHeight, setBottomTableHeight] = useState(0);
	const outerDivRef = useRef<HTMLDivElement | null>(null);
	const [showLists, setShowLists] = useState<ShowListsProps[]>([]);

	const [selectedShowListsIndex, setSelectedShowListsIndex] = useState(-1);

	const resultSuccessBottom = () => {
		Swal.fire({
			icon: "success",
			title: "儲存成功",
			showConfirmButton: false,
			timer: 1500
		});
	};

	// 空白基本資料物件
	const initialBasicInfoData: BasicInfoProps = {
		data_id: "",
		short_code: "",
		name_tw: "",
		name_en: "",
		unit: "",
		drug_type: "",
		dosage_form: "",
		freq: "",
		freq_directions: "",
		taking_time: "",
		drug_no: "",
		drug_price: "",
		self_price: "",
		cost: "",
		rateType: "",
		childrenBonus: "",
		ingredientCode: "",
		DRGCode: "",
		controlled_level: "",
		alternativeMedicine: "",
		dosing_days: "",
		route_of_administration: "",
		dose: "",
		max_dose: "",
		max_dose_day: "",
		treatmentCode: "",
		specificPart: "",
		disabled_date: "",
		custom_name_tw: "",
		custom_name_en: ""
	};

	// 空白相關設定
	const initialRelatedSettingData = {
		bottle_package: "",
		package_capacity: "",
		package_unit: "",
		package_qty: "",
		administrationRoute: "",
		check_insure_status: "",
		exam_declare: "",
		drg_code: "",
		ingredientCode: "",
		substitute: "",
		specific_part: "",
		treatment_code: "",
		deliver_exam_type: "",
		deliver_exam_lab: "",
		lab_name: ""
	};

	// 基本資料
	const [basicInfoData, setBasicInfoData] = useState<BasicInfoProps>(initialBasicInfoData);
	const checkBasicInfoData = useRef<BasicInfoProps>(basicInfoData);

	//小兒劑量
	const [pediatricDoseLists, setPediatricDoseLists] = useState<PediatricDoseProps[]>([]);
	const checkPediatricDoseLists = useRef(pediatricDoseLists);

	// 附加 SO
	const [soData, setSoData] = useState<soDataProps>({
		subjective: "",
		objective: ""
	});
	const checkSoData = useRef<soDataProps>({
		...soData
	});

	// 附加醫令
	const [medicalOrderLists, setMedicalOrderLists] = useState<MedicalOrderListsProps[]>([]);
	const checkMedicalOrderLists = useRef(medicalOrderLists);
	const [originMedicalOrderLists, setOriginMedicalOrderLists] = useState<MedicalOrderListsProps[]>([]);

	// 附加診斷
	const [diagnosisLists, setDiagnosisLists] = useState<DiagnosisListsProps[]>([]);
	const checkDiagnosisLists = useRef(diagnosisLists);
	const [originDiagnosisLists, setOriginDiagnosisLists] = useState<DiagnosisListsProps[]>([]);

	const [dataIdFromTable, setDataIdFromTable] = useState("");

	// 刪除lists的時候不用儲存的變數
	const [isFromDelete, setIsFromDelete] = useState<boolean>(false);

	// 相關設定物件

	const [relatedSettingData, setRelatedSettingData] = useState<relatedSettingDataProps>(initialRelatedSettingData);
	const checkRelatedSettingData = useRef(initialRelatedSettingData);

	// 用藥資訊
	const [medicationInfo, setMedicationInfo] = useState<MedicationInfoProps>({
		medicationWaring: ""
	});
	const checkMedicationInfo = useRef<MedicationInfoProps>;

	const handleChangeShowListsIndex = (index: number) => (event: React.MouseEvent<HTMLTableRowElement>) => {
		if (showLists) {
			setDataIdFromTable(showLists[index].data_id);
		}
		if (isAddNew) {
			if (hasUnsavedChanges) {
				setSelectedShowListsIndex(index);
				handleAddNewDrugDataCancel();
				return;
			}
		}
		if (autoSavedStatus) {
			setSelectedShowListsIndex(index);
			if (showLists && showLists[selectedShowListsIndex].data_id && hasUnsavedChanges) {
				switch (selectedTabIndex) {
					case 0:
						if (basicInfoData.drug_type === "") {
							Swal.fire({
								title: "請注意藥類尚未填寫",
								icon: "warning",
								confirmButtonText: "好"
							}).then(() => {
								postDrugBasicAPI();
							});
						} else {
							postDrugBasicAPI();
						}
						break;
					case 1:
						postDrugChildrenDosageAPI();
						break;
					case 2:
						postDrugAttachSoAPI();
						break;
					case 3:
						postDrugAttachDrugsAPI();
						break;
					case 4:
						postDrugAttachIcdAPI();
						break;
					case 5:
						postDrugSpecificationsAPI();
						break;
				}
			}
		} else {
			if (hasUnsavedChanges) {
				Swal.fire({
					title: "變更的資料尚未儲存 \n 是否切換藥品？",
					icon: "question",
					showCancelButton: true,
					confirmButtonText: "是",
					cancelButtonText: "否"
				}).then(result => {
					if (result.isConfirmed) {
						setSelectedShowListsIndex(index);
						setHasUnsavedChanges(false);
						// switch (selectedTabIndex) {
						//     case 0:
						//         postDrugBasicAPI();
						//         break;
						//     case 1:
						//         postDrugChildrenDosageAPI();
						//         break;
						//     case 2:
						//         postDrugAttachSoAPI();
						//         break;
						//     case 3:
						//         postDrugAttachDrugsAPI();
						//         break;
						//     case 4:
						//         postDrugAttachIcdAPI();
						//         break;
						//     case 5:
						//         postDrugSpecificationsAPI();
						//         break;
						// }
					} else {
						return;
					}
				});
			} else {
				setSelectedShowListsIndex(index);
			}
		}
	};

	// 這邊用click是因為change會觸發兩次
	//切換頁籤
	const handleChangeSelectedTab = (index: number) => {
		if (basicInfoData.data_id === "") {
			Swal.fire({
				icon: "error",
				title: "請先輸入藥品代碼並儲存為院內用藥",
				confirmButtonText: "好"
			});
			return;
		}
		if (autoSavedStatus) {
			setSelectedTabIndex(index);
			setHasUnsavedChanges(false);
			if (showLists && showLists[selectedShowListsIndex].data_id && hasUnsavedChanges) {
				switch (selectedTabIndex) {
					case 0:
						postDrugBasicAPI();
						break;
					case 1:
						postDrugChildrenDosageAPI();
						break;
					case 2:
						postDrugAttachSoAPI();
						break;
					case 3:
						postDrugAttachDrugsAPI();
						break;
					case 4:
						postDrugAttachIcdAPI();
						break;
					case 5:
						postDrugSpecificationsAPI();
						break;
				}
			}
		} else {
			if (hasUnsavedChanges) {
				Swal.fire({
					title: "變更的資料尚未儲存 \n 是否前往其他頁籤？",
					icon: "question",
					showCancelButton: true,
					confirmButtonText: "是",
					cancelButtonText: "否"
				}).then(result => {
					if (result.isConfirmed) {
						setIsNotChanged(true);
						setSelectedTabIndex(index);
						setHasUnsavedChanges(false);
						// 0513 改為離開不存
						// switch (selectedTabIndex) {
						//     case 0:
						//         if (
						//             showLists &&
						//             showLists[selectedShowListsIndex].data_id
						//         ) {
						//             postDrugBasicAPI();
						//         }
						//         break;
						//     case 1:
						//         if (
						//             showLists &&
						//             showLists[selectedShowListsIndex].data_id
						//         ) {
						//             postDrugChildrenDosageAPI();
						//         }
						//         break;
						//     case 2:
						//         if (
						//             showLists &&
						//             showLists[selectedShowListsIndex].data_id
						//         ) {
						//             postDrugAttachSoAPI();
						//         }
						//         break;
						//     case 3:
						//         if (
						//             showLists &&
						//             showLists[selectedShowListsIndex].data_id
						//         ) {
						//             postDrugAttachDrugsAPI();
						//         }
						//         break;
						//     case 4:
						//         if (
						//             showLists &&
						//             showLists[selectedShowListsIndex].data_id
						//         ) {
						//             postDrugAttachIcdAPI();
						//         }
						//         break;
						//     case 5:
						//         if (
						//             showLists &&
						//             showLists[selectedShowListsIndex].data_id
						//         ) {
						//             postDrugSpecificationsAPI();
						//         }
						//         break;
						// }
					} else {
						return;
					}
				});
			} else {
				// 目前設定是切換分頁不要關閉編輯
				// setEditing(true);
				setSelectedTabIndex(index);
				if (showLists && showLists[selectedShowListsIndex].data_id) {
					setSelectedTabIndex(index);
				} else {
					setSelectedTabIndex(0);
				}
				//
			}
		}
	};

	useEffect(() => {
		function updateHeight() {
			if (outerDivRef.current) {
				const height = outerDivRef.current.clientHeight;
				setDrugDataMaintenanceTableTableHeight(height);
			}
		}
		updateHeight();
		window.addEventListener("resize", updateHeight);

		return () => {
			window.removeEventListener("resize", updateHeight);
		};
	}, [setDrugDataMaintenanceTableTableHeight]);

	useEffect(() => {
		const area = windowHeight - (96 + 32 + 20 + 40 + 8 + 8 + 20); //上下灰區的可視範圍 = 視窗高度 - 有的沒的
		const bottomHeight = 372; // 下面灰區的必要高度
		const topHeight = area - bottomHeight;
		setTopTableHeight(topHeight);
		setBottomTableHeight(bottomHeight);
	}, [windowHeight]);

	const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		setKeyword(inputElement);
	};

	const [drugTypeList, setDrugTypeList] = useState<drugTypeListProps[]>([]);

	const { data: drugTypeListData, isSuccess: isDrugTypeListSuccess } = useDropDownMenuQuery("drugTypeList", userInfo.token);

	useEffect(() => {
		if (isDrugTypeListSuccess) {
			setDrugTypeList(drugTypeListData.lists);
		}
	}, [isDrugTypeListSuccess]);

	const [updateBasicInfoTrigger, setUpdateBasicInfoTrigger] = useState(false);

	const generateOfficialTable = () => {
		let finalData = {
			keyword: keyword
		};
		let checkShowLists = showLists;

		setShowLists([]);
		setSelectedTabIndex(0);
		setBasicInfoData(initialBasicInfoData);
		checkBasicInfoData.current = initialBasicInfoData;

		const postDragsOfficialAPI = async () => {
			if (userInfo.token && finalData) {
				try {
					const result = await postDrugsOfficialService(userInfo.token, finalData);

					console.log("查詢公告用藥:", result.lists);

					if (result.result === "success") {
						setShowLists(result.lists);
						setSelectedShowListsIndex(0);
						setUpdateBasicInfoTrigger(true);
					} else {
						Swal.fire({
							icon: "warning",
							title: "查無資料",
							showConfirmButton: true,
							confirmButtonText: "重新嘗試"
						});
						setShowLists(checkShowLists);
					}

					return result;
				} catch {
					Swal.fire({
						icon: "error",
						title: "後端服務錯誤",
						timer: 1500
					});
				}
			} else {
				Swal.fire({
					icon: "warning",
					title: "查無資料",
					showConfirmButton: true,
					confirmButtonText: "重新嘗試"
				});
				setShowLists(checkShowLists);
				setHasUnsavedChanges(false);
			}
		};
		postDragsOfficialAPI();
	};

	const generateInUseTable = () => {
		let finalData = {
			keyword: keyword
		};
		setShowLists([]);
		const postDrugsInUseAPI = async () => {
			if (userInfo.token && finalData) {
				try {
					const result = await postDrugsInUseService(userInfo.token, finalData);
					console.log("查詢院內用藥:", result.lists);
					if (result.result === "success") {
						setShowLists(result.lists);
						setSelectedShowListsIndex(0);
						if (showLists && showLists[0].data_id) {
							setUpdateBasicInfoTrigger(true);
							setDataIdFromTable(showLists[0].data_id);
						} else {
							setBasicInfoData(prev => ({
								...prev,
								...showLists![0]
							}));
							setSelectedTabIndex(0);
						}
					} else if (result.result === "failure") {
						Swal.fire({
							icon: "warning",
							title: "查無資料",
							showConfirmButton: true,
							confirmButtonText: "重新嘗試"
						});
					}
					return result;
				} catch {
					Swal.fire({
						icon: "error",
						title: "後端服務錯誤",
						timer: 1500
					});
				}
			} else {
				try {
					const result = await postDrugsInUseService(userInfo.token!);
					if (result.result === "success") {
						setShowLists(result.lists);

						setSelectedShowListsIndex(0);
						if (showLists && showLists[0].data_id) {
							callDrugBasicAPI();
							setDataIdFromTable(showLists[0].data_id);
						} else {
							setBasicInfoData(prev => ({
								...prev,
								...showLists![0]
							}));
							setSelectedTabIndex(0);
						}
					} else if (result.result === "failure") {
						Swal.fire({
							icon: "warning",
							title: "查無資料",
							showConfirmButton: true,
							confirmButtonText: "重新嘗試"
						});
					}
				} catch (error) {
					console.error("發生錯誤:", error);
					Swal.fire({
						icon: "error",
						title: "發生錯誤",
						text: "請稍後再試",
						showConfirmButton: true,
						confirmButtonText: "確定"
					});
				}
			}
		};
		postDrugsInUseAPI();
	};

	const handleSearch = (option: string) => {
		switch (option) {
			case "院內用藥":
				generateInUseTable();
				break;
			case "公告用藥":
				generateOfficialTable();
				break;
			default:
				Swal.fire({
					icon: "warning",
					title: "查無資料",
					showConfirmButton: true,
					confirmButtonText: "重新嘗試"
				});
		}
	};

	const handleEdit = () => {
		setIsEditing(true); // 開編輯
		setIsNotChanged(true);

		checkPediatricDoseLists.current = pediatricDoseLists;

		checkRelatedSettingData.current = relatedSettingData;
	};

	const handleCancel = () => {
		if (hasUnsavedChanges) {
			Swal.fire({
				title: "資料尚未儲存，是否要離開？",
				icon: "question",
				showCancelButton: true,
				confirmButtonText: "是",
				cancelButtonText: "否"
			}).then(result => {
				if (result.isConfirmed) {
					setHasUnsavedChanges(false);
					setBasicInfoData(checkBasicInfoData.current);
					setSoData({ ...checkSoData.current });
					setPediatricDoseLists(checkPediatricDoseLists.current);
					setMedicalOrderLists(checkMedicalOrderLists.current);
					setDiagnosisLists(checkDiagnosisLists.current);
					// setMedicationInfo(checkMedicationInfo.current);
					setIsEditing(false);
				}
			});
		} else {
			setIsEditing(true);
		}
	};

	const handleAddNewDrugDataCancel = () => {
		if (hasUnsavedChanges) {
			Swal.fire({
				title: "資料尚未儲存，是否要離開？",
				icon: "question",
				showCancelButton: true,
				confirmButtonText: "是",
				cancelButtonText: "否"
			}).then(result => {
				if (result.isConfirmed) {
					setHasUnsavedChanges(false);
					setBasicInfoData(checkBasicInfoData.current);
					setSoData({ ...checkSoData.current });
					setPediatricDoseLists(checkPediatricDoseLists.current);
					setMedicalOrderLists(checkMedicalOrderLists.current);
					setDiagnosisLists(checkDiagnosisLists.current);
					setIsEditing(false);
					showLists?.shift();
					setSelectedShowListsIndex(0);
					if (showLists && showLists[0].data_id) {
						callDrugBasicAPI();
						setDataIdFromTable(showLists[0].data_id);
					} else {
						setBasicInfoData(prev => ({
							...prev,
							...showLists![0]
						}));
						setSelectedTabIndex(0);
					}
				} else {
					return;
				}
			});
		} else {
			setIsEditing(false);
			setIsAddNew(false);
			showLists?.shift();
			if (showLists && showLists[0].data_id && showLists[0].data_id !== "") {
				callDrugBasicAPI();
				setDataIdFromTable(showLists[0].data_id);
			} else {
				if (showLists && showLists[0]) {
					setBasicInfoData(() => ({
						...initialBasicInfoData,
						...showLists[0]
					}));

					checkBasicInfoData.current = {
						...initialBasicInfoData,
						...showLists[0]
					};
					setSelectedTabIndex(0);
				}
			}
			setSelectedShowListsIndex(0);
		}
	};

	const callDrugAttachICDAPI = async () => {
		if (userInfo.token && showLists) {
			try {
				const result = await callDrugAttachICDService(userInfo.token, showLists[selectedShowListsIndex].data_id);

				if (result.result === "success") {
					setDiagnosisLists(result.lists);
					setOriginDiagnosisLists(result.lists);
					checkDiagnosisLists.current = result.lists;
				} else if (result.msg === "查無資料") {
					setDiagnosisLists([]);
					setOriginDiagnosisLists([]);
					checkDiagnosisLists.current = [];
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500
				});
			}
		}
	};

	// post 附加 SO

	const postDrugAttachSoAPI = () => {
		if (userInfo.token && soData) {
			const postAttachSo = async () => {
				if (showLists) {
					let finalData = {
						data_id: showLists[selectedShowListsIndex].data_id,
						subjective: soData.subjective,
						objective: soData.objective
					};
					try {
						const result = await postDrugAttachSoService(userInfo.token, finalData);
						if (result.result === "success") {
							Swal.fire({
								icon: "success",
								title: "儲存成功",
								showConfirmButton: false,
								timer: 1500
							});
						}
					} catch {
						Swal.fire({
							icon: "error",
							title: "後端服務錯誤",
							timer: 1500
						});
					}
				}
			};
			postAttachSo();
		}
	};
	const callDrugChildrenDosageAPI = async () => {
		if (userInfo.token && showLists) {
			try {
				const result = await callDrugChildrenDosageService(userInfo.token, showLists[selectedShowListsIndex].data_id);
				if (result.result === "success") {
					const pediatricDoseArray: PediatricDoseProps[] = Object.entries(result.lists).map(([key, value]) => {
						return { [key]: value } as PediatricDoseProps;
					});

					setPediatricDoseLists(pediatricDoseArray);
					checkPediatricDoseLists.current = pediatricDoseArray;
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500
				});
			}
		}
	};

	const callDrugAttachSoAPI = async () => {
		if (userInfo.token && showLists) {
			try {
				const result = await callDrugAttachSoService(userInfo.token, showLists[selectedShowListsIndex].data_id);
				if (result.result === "success") {
					let obj = result.objective ?? "";
					let sub = result.subjective ?? "";
					let finalData = {
						subjective: sub,
						objective: obj
					};
					setSoData(finalData);
					checkSoData.current = finalData;
					//api有成功查無資料要清空
				} else if (result.msg === "查無資料") {
					let obj = "";
					let sub = "";
					let finalData = {
						subjective: sub,
						objective: obj
					};
					setSoData(finalData);
					checkSoData.current = finalData;
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500
				});
			}
		}
	};
	const callDrugSpecificationsAPI = async () => {
		if (userInfo.token && showLists) {
			try {
				const result = await callDrugSpecificationsService(userInfo.token, showLists[selectedShowListsIndex].data_id);
				if (result.result === "success") {
					setRelatedSettingData(result.info);
					checkRelatedSettingData.current = result.info;
				} else if (result.msg === "查無資料") {
					setRelatedSettingData(initialRelatedSettingData);
					checkRelatedSettingData.current = initialRelatedSettingData;
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500
				});
			}
		}
	};

	const [basicInfoUpdateCompleted, setBasicInfoUpdateCompleted] = useState(false);
	const callDrugBasicAPI = async () => {
		if (userInfo.token && showLists && showLists[selectedShowListsIndex].data_id) {
			try {
				const result = await callDrugBasicService(userInfo.token, showLists[selectedShowListsIndex].data_id);
				if (result.result === "success") {
					setBasicInfoData(initialBasicInfoData);
					checkBasicInfoData.current = initialBasicInfoData;
					checkBasicInfoData.current = result.info;
					setBasicInfoData(result.info);
					setHasUnsavedChanges(false);
					setBasicInfoUpdateCompleted(true);
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500
				});
			}
		}
	};
	useEffect(() => {
		updateBasicInfoData();
	}, [selectedTabIndex, selectedShowListsIndex]);

	useEffect(() => {
		if (updateBasicInfoTrigger) {
			updateBasicInfoData();

			setUpdateBasicInfoTrigger(false);
		}
	}, [updateBasicInfoTrigger]);

	const updateBasicInfoData = () => {
		if (showLists) {
			switch (selectedTabIndex) {
				case 0:
					//有id的
					if (showLists[selectedShowListsIndex] && showLists[selectedShowListsIndex].data_id) {
						setBasicInfoData(initialBasicInfoData);
						callDrugBasicAPI();
						setDataIdFromTable(showLists[selectedShowListsIndex].data_id);
					} else {
						setBasicInfoData({
							...initialBasicInfoData,
							...showLists[selectedShowListsIndex]
						});
						checkBasicInfoData.current = {
							...initialBasicInfoData,
							...showLists[selectedShowListsIndex]
						};

						setSelectedTabIndex(0);
					}
					break;
				case 1:
					if (showLists[selectedShowListsIndex].data_id) {
						callDrugChildrenDosageAPI();
					} else {
						// 資料會不一樣 basic
						setSelectedTabIndex(0);
					}
					break;
				case 2:
					callDrugAttachSoAPI();
					break;
				case 3:
					callDrugAttachDrugsAPI();
					break;
				case 4:
					callDrugAttachICDAPI();
					break;
				case 5:
					callDrugSpecificationsAPI();
					break;
			}
		}
	};

	const callDrugAttachDrugsAPI = async () => {
		if (userInfo.token && showLists && showLists[selectedShowListsIndex].data_id) {
			try {
				const result = await callDrugAttachDrugsService(userInfo.token, showLists[selectedShowListsIndex].data_id);
				if (result.result === "success") {
					setMedicalOrderLists(result.lists);
					setOriginMedicalOrderLists(result.lists);
					checkMedicalOrderLists.current = result.lists;
				} else if (result.msg === "查無資料") {
					setMedicalOrderLists([]);
					setOriginMedicalOrderLists([]);
					checkMedicalOrderLists.current = [];
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500
				});
			}
		}
	};

	// post 基本資料
	const postDrugBasicAPI = () => {
		if (userInfo.token && basicInfoData) {
			// 已有 data_id：編輯
			const postDrugBasic = async () => {
				try {
					const result = await postDrugBasicService(userInfo.token, basicInfoData);
					if (result.result === "success") {
						if (!basicInfoData.data_id) {
							setBasicInfoData({
								...basicInfoData,
								data_id: result.data_id
							});
							const addedData = showLists[selectedShowListsIndex];
							addedData.data_id = result.data_id;
							addedData.short_code = basicInfoData.short_code;

							const updatedShowLists = [...showLists];
							updatedShowLists[selectedShowListsIndex] = addedData;

							setShowLists(updatedShowLists);
						}

						setShowLists(prev => {
							if (!prev) return prev;
							if (selectedShowListsIndex >= 0) {
								return prev.map((item, index) => {
									if (index === selectedShowListsIndex) {
										return {
											...item,
											short_code: basicInfoData.short_code ?? item.short_code,
											drug_no: basicInfoData.drug_no ?? item.drug_no,
											drug_type:
												drugTypeList.find(subItem => subItem.data_id === basicInfoData.drug_type)?.name ?? "",
											freq_directions: basicInfoData.freq_directions ?? item.freq_directions,
											name_tw: basicInfoData.custom_name_tw ? basicInfoData.custom_name_tw : basicInfoData.name_tw
										};
									}
									return item;
								});
							}

							return prev;
						});

						Swal.fire({
							icon: "success",
							title: "儲存成功",
							showConfirmButton: false,
							timer: 1500
						});

						// 同時更新 check 的值，避免 post 前後不同導致 check 以為還沒儲存
						checkBasicInfoData.current = {
							...checkBasicInfoData.current,
							data_id: result.data_id,
							custom_name_tw: basicInfoData.custom_name_tw,
							short_code: basicInfoData.short_code,
							drug_no: basicInfoData.drug_no,
							freq_directions: basicInfoData.freq_directions,
							drug_type: basicInfoData.drug_type
						};
					} else if (result.result === "failure" && result.msg === "簡碼不可為空") {
						Swal.fire({
							icon: "error",
							title: "藥品代碼不可空白 \n 請填入藥品代碼",
							confirmButtonText: "好"
						});
						return;
					} else {
						Swal.fire({
							icon: "error",
							title: "發生錯誤，請稍後再試",
							confirmButtonText: "好"
						});
						return;
					}
				} catch {
					Swal.fire({
						icon: "error",
						title: "後端服務錯誤",
						timer: 1500
					});
				}
			};
			postDrugBasic();
		} else {
			console.log(`發生錯誤，無 id 及簡碼 `);
		}
	};

	// 取得小兒劑量
	const postDrugChildrenDosageAPI = async () => {
		if (userInfo.token && showLists) {
			let mergedData = pediatricDoseLists.reduce((acc, item) => {
				const [key, value] = Object.entries(item)[0];
				if (value !== null) {
					acc[key as keyof PediatricDoseProps] = value;
				}
				return acc;
			}, {});

			let finalData = {
				drug_in_use_uid: showLists[selectedShowListsIndex].data_id,
				...mergedData
			};

			try {
				const result = await postDrugChildrenDosageService(userInfo.token, finalData);
				if (result.result === "success") {
					resultSuccessBottom();
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500
				});
			}
		}
	};

	// 取得藥品相關設定
	const postDrugSpecificationsAPI = async () => {
		if (userInfo.token && showLists) {
			let finalData = {
				drug_in_use_uid: showLists[selectedShowListsIndex].data_id,
				...relatedSettingData
			};
			try {
				const result = await postDrugSpecificationsService(userInfo.token, finalData);
				if (result.result === "success") {
					resultSuccessBottom();
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500
				});
			}
		}
	};

	// 設定藥品附加診斷
	const postDrugAttachIcdAPI = async () => {
		if (showLists) {
			const icdArr = diagnosisLists
				.map(item => (item.data_id ? item.data_id : item.attach_icd_uid))
				.filter(id => id !== undefined) as string[];
			const idsArr = diagnosisLists
				.map(item => (item.data_id ? item.data_id : item.attach_icd_uid))
				.filter(id => id !== undefined) as string[];

			let finalData = {
				data_id: showLists[selectedShowListsIndex].data_id,
				icd: icdArr,
				ids: idsArr
			};

			if (userInfo.token) {
				try {
					const result = await postDrugAttachIcdService(userInfo.token, finalData);
					if (result.result === "success") {
						resultSuccessBottom();
						callDrugAttachICDAPI();
					}
				} catch {
					Swal.fire({
						icon: "error",
						title: "後端服務錯誤",
						timer: 1500
					});
				}
			}
		}
	};

	// 取得藥品附加醫令
	const postDrugAttachDrugsAPI = async () => {
		if (showLists) {
			const filteredMedicalOrders = medicalOrderLists.filter(
				medicalOrder =>
					!originMedicalOrderLists.some(
						originOrder => originOrder.data_id === medicalOrder.data_id // 用唯一標識符替換 'id'
					)
			);
			const icdArr = filteredMedicalOrders.map(item => item.attach_drug_uid).filter(id => id !== undefined) as string[];
			const idsArr = medicalOrderLists.map(item => item.attach_drug_uid).filter(id => id !== undefined) as string[];

			let finalData = {
				data_id: showLists[selectedShowListsIndex].data_id,
				drugs: icdArr,
				ids: idsArr
			};
			if (userInfo.token) {
				try {
					const result = await postDrugAttachDrugsService(userInfo.token, finalData);
					if (result.result === "success") {
						resultSuccessBottom();
						setOriginMedicalOrderLists(medicalOrderLists);
						setHasUnsavedChanges(false);
						callDrugAttachDrugsAPI();
					}
				} catch {
					Swal.fire({
						icon: "error",
						title: "後端服務錯誤",
						timer: 1500
					});
				}
			}
		}
	};

	// 大儲存
	const handleSave = () => {
		switch (selectedTabIndex) {
			case 0: // 基本資料
				if (JSON.stringify(basicInfoData) === JSON.stringify(checkBasicInfoData.current)) {
					// 無變更，不需要儲存
				} else {
					if (basicInfoData.short_code.length === 0) {
						Swal.fire({
							icon: "error",
							title: "請填寫藥品代碼",
							confirmButtonText: "好"
						});
						break;
					}

					if (basicInfoData.drug_type === "") {
						Swal.fire({
							icon: "question",
							title: "無填寫藥類，是否繼續存檔？",
							showCancelButton: true,
							confirmButtonText: "是",
							cancelButtonText: "否"
						}).then(result => {
							if (result.isConfirmed) {
								postDrugBasicAPI();
								setIsNotChanged(true);
								setHasUnsavedChanges(false);
							} else {
								return;
							}
						});
					} else {
						postDrugBasicAPI();
						setIsNotChanged(true);
						setHasUnsavedChanges(false);
					}
				}
				break;
			case 1: // 小兒劑量
				if (JSON.stringify(pediatricDoseLists) === JSON.stringify(checkPediatricDoseLists)) {
				} else {
					postDrugChildrenDosageAPI();
					setIsNotChanged(true);
					setHasUnsavedChanges(false);
				}
				break;
			case 2: // 附加SO
				if (JSON.stringify(soData) === JSON.stringify(checkSoData.current)) {
					return;
				} else {
					postDrugAttachSoAPI();
					setIsNotChanged(true);
					setHasUnsavedChanges(false);
				}
				break;
			case 3: // 附加醫令
				if (JSON.stringify(medicalOrderLists) === JSON.stringify(checkMedicalOrderLists)) {
				} else {
					postDrugAttachDrugsAPI();
					setIsNotChanged(true);
					setHasUnsavedChanges(false);
				}
				break;
			case 4: // 附加診斷
				if (JSON.stringify(diagnosisLists) === JSON.stringify(checkDiagnosisLists.current)) {
				} else {
					postDrugAttachIcdAPI();
					setIsNotChanged(true);
					setHasUnsavedChanges(false);
				}
				break;
			case 5: // 相關設定
				if (JSON.stringify(relatedSettingData) === JSON.stringify(checkRelatedSettingData.current)) {
				} else {
					postDrugSpecificationsAPI();
					setIsNotChanged(true);
					setHasUnsavedChanges(false);
				}
				break;
			case 6: // 用藥資訊
				if (JSON.stringify("用藥資訊") === JSON.stringify("用藥資訊")) {
				} else {
					// post用藥資訊API
				}
				break;
		}
	};

	// 監控台

	// useEffect(() => {
	//     console.log('isNotChanged', isNotChanged);
	// }, [isNotChanged]);

	// useEffect(() => {
	//     console.log('hasUnsavedChanges', hasUnsavedChanges);
	// }, [hasUnsavedChanges]);

	// useEffect(() => {
	//     console.log('isEditing', isEditing);
	// }, [isEditing]);
	useEffect(() => {
		console.log("showLists", showLists);
	}, [showLists]);
	useEffect(() => {
		console.log("selectedShowListsIndex", selectedShowListsIndex);
	}, [selectedShowListsIndex]);

	useEffect(() => {
		console.log("basicInfoData", basicInfoData);
	}, [basicInfoData]);

	// 檢核資料是否異動
	// 基本資料
	useEffect(() => {
		if (JSON.stringify(basicInfoData) === JSON.stringify(checkBasicInfoData.current)) {
			setIsNotChanged(true);
			setHasUnsavedChanges(false);
		} else {
			setIsNotChanged(false);
			setHasUnsavedChanges(true);
		}
	}, [basicInfoData]);
	//小兒劑量
	useEffect(() => {
		if (JSON.stringify(pediatricDoseLists) === JSON.stringify(checkPediatricDoseLists.current)) {
			setHasUnsavedChanges(false);
			setIsNotChanged(true);
		} else {
			setHasUnsavedChanges(true);
			setIsNotChanged(false);
		}
	}, [pediatricDoseLists]);
	// 附加s.o
	useEffect(() => {
		if (JSON.stringify(soData) === JSON.stringify(checkSoData.current)) {
			setHasUnsavedChanges(false);
			setIsNotChanged(true);
		} else {
			setHasUnsavedChanges(true);
			setIsNotChanged(false);
		}
	}, [soData]);
	//附加醫令
	useEffect(() => {
		if (JSON.stringify(medicalOrderLists) === JSON.stringify(checkMedicalOrderLists.current)) {
			setIsNotChanged(true);
		} else {
			if (isFromDelete) {
				setIsFromDelete(false);
				return;
			} else {
				setIsNotChanged(false);
			}
		}
	}, [medicalOrderLists]);

	//附加診斷
	useEffect(() => {
		if (JSON.stringify(diagnosisLists) === JSON.stringify(checkDiagnosisLists.current)) {
			setIsNotChanged(true);
		} else {
			if (isFromDelete) {
				setIsFromDelete(false);
				return;
			} else {
				setIsNotChanged(false);
			}
		}
	}, [diagnosisLists]);

	// 相關設定
	useEffect(() => {
		if (JSON.stringify(relatedSettingData) === JSON.stringify(checkRelatedSettingData.current)) {
			setIsNotChanged(true);
		} else {
			setHasUnsavedChanges(true);
			setIsNotChanged(false);
		}
	}, [relatedSettingData]);

	const handleAutoSave = () => {
		setAutoSavedStatus(!autoSavedStatus);
	};
	const handleView = () => {
		setIsEditing(false);
	};

	// 搜尋所有院內用藥
	const postDrugsInUseAPI = async () => {
		if (userInfo.token) {
			try {
				const result = await postDrugsInUseService(userInfo.token);
				if (result.result === "success") {
					setShowLists(result.lists);
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500
				});
			}
		}
	};

	//一開始取得 showLists 的資料 當拿到id後去取基本資料
	useEffect(() => {
		const initialAPIs = async () => {
			try {
				await postDrugsInUseAPI();
				setSelectedShowListsIndex(0);
			} catch (error) {
				console.error("錯誤：無法獲取藥物使用數據", error);
				// 可以在這裡添加錯誤處理邏輯，例如顯示錯誤消息給用戶
			}
			if (showLists && showLists[0] && showLists[0].data_id) {
				try {
					await callDrugBasicAPI();
				} catch (error) {
					console.error("錯誤：無法獲取藥物基本資料", error);
					// 可以在這裡添加錯誤處理邏輯，例如顯示錯誤消息給用戶
				}
			}
		};
		initialAPIs();
	}, []);

	const shortCodeInputRef = useRef<HTMLInputElement>(null);
	const handleFocusShortCode = () => {
		// Focus the input using the ref
		if (shortCodeInputRef.current) {
			shortCodeInputRef.current.focus();
		}
	};
	const [focusNewInput, setFocusNewInput] = useState(false);

	const [isAddNew, setIsAddNew] = useState(false);

	const handleAddNewDrugData = () => {
		// Define an empty record
		const newShowLists: ShowListsProps = {
			data_id: "",
			// code: '',
			name_tw: "",
			name_en: "",
			short_code: "",
			unit: "",
			drug_type: "",
			drug_type_name: "",
			dosage_form: "",
			freq: "",
			freq_directions: "",
			drug_no: "",
			drug_price: ""
		};
		setShowLists(prev => {
			if (!prev) return prev;
			// Check if the first element is already an empty record
			if (prev.length > 0 && Object.values(prev[0]).every(value => value === "")) {
				return prev;
			}
			return [newShowLists, ...prev];
		});

		// 如果有選擇的元素，更新其索引以反映新增元素
		// setSelectedShowListsIndex(prevIndex => prevIndex + 1);
		// Uncomment these if needed:
		// setIsEditing(true);
		setHasUnsavedChanges(false);
		checkBasicInfoData.current = initialBasicInfoData;
		setBasicInfoData(initialBasicInfoData);
		setSelectedShowListsIndex(0);
		setSelectedTabIndex(0);
		setIsEditing(true); // 開編輯
		// setIsNotChanged(false);

		setFocusNewInput(true);
		setIsAddNew(true);
	};

	useEffect(() => {
		if (focusNewInput && shortCodeInputRef.current) {
			shortCodeInputRef.current.focus();
			setFocusNewInput(false);
		}
	}, [focusNewInput]);

	const [isCNtyping, setIsCNtyping] = useState<boolean>(false);

	useEffect(() => {
		if (basicInfoUpdateCompleted) {
			setBasicInfoUpdateCompleted(false);
		}
	}, [basicInfoUpdateCompleted]);

	return (
		<>
			<Flex direction={"column"} gap={"8px"}>
				<Flex gap={"12px"} w={"100%"}>
					<Flex flex={"0 0 200px"} align={"center"} gap={"8px"}>
						<Text textStyle={"semiTitle"} flex={"0 0 auto"}>
							藥類
						</Text>
						<Select defaultValue='全部'>
							<option>全部</option>
						</Select>
					</Flex>
					<Flex flex={"1 0 auto"} gap={"8px"}>
						<InputGroup>
							<InputLeftElement
								pointerEvents='none' // This ensures the icon doesn't block input functionality
							>
								<HeroIcons name='search'></HeroIcons>
							</InputLeftElement>
							<Input
								bgColor='white'
								placeholder='藥碼、簡碼、藥品名稱、健保碼'
								onCompositionStart={() => {
									setIsCNtyping(true);
								}}
								onCompositionEnd={() => {
									setIsCNtyping(false);
								}}
								onChange={handleChangeKeyword}
								onKeyDown={event => {
									if (!isCNtyping && event.key === "Enter") {
										handleSearch("公告用藥");
									}
								}}
							/>
						</InputGroup>
						<Flex flex={"0 0 auto"} gap={"6px"}>
							<Tooltip label={keyword.length < 1 ? "輸入關鍵字至少 1 字" : ""} hasArrow bg={"red.500"}>
								<Button
									isDisabled={keyword.length < 1}
									colorScheme='rsPrimary'
									onClick={() => {
										handleSearch("公告用藥");
									}}>
									查詢公告用藥
								</Button>
							</Tooltip>

							<Button
								colorScheme='rsPrimary'
								onClick={() => {
									handleSearch("院內用藥");
								}}>
								查詢院內用藥
							</Button>
						</Flex>
					</Flex>
					{/* 0514 新增藥品有問題 先隱藏 */}
					<Flex gap={"6px"} hidden>
						<Button colorScheme='rsPrimary' onClick={handleAddNewDrugData}>
							新增藥品
						</Button>
					</Flex>
				</Flex>

				<Box bgColor={"gray.50"} borderBottom={"2px solid #ccc"} rounded={"6px"} position={"relative"} h={topTableHeight}>
					{showLists && showLists?.length > 0 ? (
						<>
							<Box pos={"absolute"} inset={0} overflowY={"scroll"} rounded={"6px"} zIndex={30}>
								<Table size='md' variant={"unstyled"}>
									<Thead
										h={"40px"}
										bg={"#f7f7f7"}
										pos={"sticky"}
										top={0}
										className='before:absolute before:box-content before:bottom-0 before:left-0  before:w-full before:h-[2px] before:bg-[#9b9b7a]'>
										<Tr>
											<Th fontSize={"md"} p={"8px 12px"}>
												<Text textStyle={"semiTitle"}>藥品代碼</Text>
											</Th>
											<Th fontSize={"md"} p={"8px 12px"}>
												<Text textStyle={"semiTitle"}>藥品名稱</Text>
											</Th>
											<Th fontSize={"md"} p={"8px 12px"}>
												<Text textStyle={"semiTitle"}>單位</Text>
											</Th>
											<Th fontSize={"md"} p={"8px 12px"}>
												<Text textStyle={"semiTitle"}>藥類</Text>
											</Th>
											<Th fontSize={"md"} p={"8px 12px"}>
												<Text textStyle={"semiTitle"}>劑型</Text>
											</Th>
											<Th fontSize={"md"} p={"8px 12px"}>
												<Text textStyle={"semiTitle"}>使用頻率</Text>
											</Th>
											<Th fontSize={"md"} p={"8px 12px"}>
												<Text textStyle={"semiTitle"}>健保碼</Text>
											</Th>
											<Th fontSize={"md"} p={"8px 12px"}>
												<Text textStyle={"semiTitle"}>健保價</Text>
											</Th>
										</Tr>
									</Thead>
									<Tbody>
										{showLists &&
											showLists.map((item, index) => (
												<Tr
													key={item.drug_no}
													onClick={handleChangeShowListsIndex(index)}
													style={{
														backgroundColor: selectedShowListsIndex === index ? "#DDDBBA" : ""
													}}
													borderBottom={"1px solid #d6d6d6"}
													cursor={"pointer"}>
													<Td fontSize={"md"} p={"8px 12px"}>
														<Box>{item.short_code ?? ""}</Box>
													</Td>

													<Td fontSize={"md"} p={"8px 12px"}>
														<Tooltip label={item.name_tw !== "" ? item.name_tw : item.name_en} hasArrow>
															<Box
																maxW={"500px"}
																whiteSpace={"nowrap"}
																overflowX={"hidden"}
																textOverflow={"ellipsis"}>
																{item.name_tw !== "" ? item.name_tw : item.name_en}
															</Box>
														</Tooltip>
													</Td>
													<Td fontSize={"md"} p={"8px 12px"}>
														<Box>{item.unit == "" ? "-" : item.unit}</Box>
													</Td>
													<Td fontSize={"md"} p={"8px 12px"}>
														<Box>{item.drug_type}</Box>
													</Td>
													<Td fontSize={"md"} p={"8px 12px"}>
														<Box>
															{/* 這個比較特殊 */}
															{item.dosage_form == null ? "-" : item.dosage_form}
														</Box>
													</Td>
													<Td fontSize={"md"} p={"8px 12px"}>
														<Box>{item.freq_directions == "" ? "-" : item.freq_directions}</Box>
													</Td>
													<Td fontSize={"md"} p={"8px 12px"}>
														<Box>{item.drug_no == "" ? "-" : item.drug_no}</Box>
													</Td>
													<Td fontSize={"md"} p={"8px 12px"}>
														<Box>{item.drug_price == "" ? "-" : item.drug_price}</Box>
													</Td>
												</Tr>
											))}
									</Tbody>
								</Table>
							</Box>{" "}
						</>
					) : (
						<Loading></Loading>
					)}
				</Box>

				{/* 下方欄位資訊 */}
				<Tabs
					position='relative'
					bgColor={"gray.50"}
					rounded={"15px"}
					textColor={"#A4A4A4"}
					index={selectedTabIndex}
					overflow={"hidden"}
					h={bottomTableHeight}
					borderBottom={"2px solid #ccc"}>
					<TabList p={"12px 16px"} textColor={"#A4A4A4"} bgColor={"gray.50"} borderBottom={0}>
						<Flex justify={"space-between"} align={"center"} flexWrap={"wrap"} w={"100%"}>
							<Flex flexWrap='wrap'>
								{[
									"基本資料",
									"小兒劑量",
									"附加S.O.",
									"附加醫令",
									"附加診斷",
									"相關設定"
									// '用藥資訊',  尚無 API 無功能先隱藏
								].map((tab, index) => (
									<Tab
										key={tab}
										data-index={index}
										_selected={{
											bg: "transparent",
											borderBottom: "2px solid #708368"
										}}
										isDisabled={basicInfoData.short_code ? false : true}
										fontWeight={"semibold"}
										textColor={selectedTabIndex === index ? "rsPrimary.500" : "rsSteel.500"}
										onClick={() => handleChangeSelectedTab(index)}>
										{tab}
									</Tab>
								))}
							</Flex>

							{isEditing ? (
								<>
									<Flex gap={"8px"}>
										<Checkbox
											hidden={true}
											colorScheme='teal'
											iconColor='white'
											iconSize='2em'
											borderColor={"#A4AC86C6"}
											bg={"#A4AC86C6"}
											textColor={"#fff"}
											fontWeight={"semibold"}
											p={"8px 16px"}
											rounded={"6px"}
											_checked={{
												bg: "teal.500",
												color: "white",
												borderColor: "teal.500"
											}}
											_focus={{
												boxShadow: "outline"
											}}
											isChecked={autoSavedStatus}
											onChange={handleAutoSave}
											data-index='100'>
											自動儲存
										</Checkbox>
										<Button
											colorScheme={isAddNew ? "rsDanger" : "rsSteel"}
											isDisabled={isAddNew ? undefined : isNotChanged}
											onClick={() => {
												isAddNew ? handleAddNewDrugDataCancel() : handleCancel();
											}}
											data-index='100'>
											取消
										</Button>

										{/* 如果資料有變去判斷顯示回檢視還是儲存 */}
										{isNotChanged ? (
											<>
												<Button colorScheme='rsSecondary' onClick={handleView} data-index='100'>
													回檢視
												</Button>
											</>
										) : (
											<>
												<Button
													colorScheme='rsSecondary'
													onClick={handleSave}
													isDisabled={isNotChanged}
													data-index='100'
													_hover={{
														bg: "#B0BCBB"
													}}>
													儲存
												</Button>
											</>
										)}
									</Flex>
								</>
							) : (
								<>
									<Button
										colorScheme='rsWarning' // 自訂顏色方案名稱
										onClick={handleEdit}
										data-index='100'
										isDisabled={selectedShowListsIndex < 0}>
										編輯
									</Button>
								</>
							)}
						</Flex>
					</TabList>

					<TabPanels>
						<TabPanel>
							<DrugDataMaintenanceBasicInfo
								basicInfoData={basicInfoData}
								setBasicInfoData={setBasicInfoData}
								isEditing={isEditing}
								shortCodeInputRef={shortCodeInputRef}
							/>
						</TabPanel>
						<TabPanel>
							<DrugDataMaintenancePediatricDose
								isEditing={isEditing}
								pediatricDoseLists={pediatricDoseLists}
								setPediatricDoseLists={setPediatricDoseLists}
							/>
						</TabPanel>
						<TabPanel>
							<DrugDataMaintenanceAttachSo soData={soData} setSoData={setSoData} isEditing={isEditing} />
						</TabPanel>
						<TabPanel>
							<DrugDataMaintenanceMedicalOrder
								medicalOrderLists={medicalOrderLists}
								setMedicalOrderLists={setMedicalOrderLists}
								isEditing={isEditing}
								setIsFromDelete={setIsFromDelete}
								dataIdFromTable={dataIdFromTable}
								setOriginMedicalOrderLists={setOriginMedicalOrderLists}
							/>
						</TabPanel>
						<TabPanel>
							<DrugDataMaintenanceDiagnosis
								diagnosisLists={diagnosisLists}
								setDiagnosisLists={setDiagnosisLists}
								isEditing={isEditing}
								setIsFromDelete={setIsFromDelete}
								dataIdFromTable={dataIdFromTable}
								setOriginDiagnosisLists={setOriginDiagnosisLists}
							/>
						</TabPanel>

						<TabPanel>
							<DrugDataMaintenanceRelatedSetting
								relatedSettingData={relatedSettingData}
								setRelatedSettingData={setRelatedSettingData}
								isEditing={isEditing}
							/>
						</TabPanel>
						<TabPanel>
							<DrugDataMaintenanceMedicationInfo
								medicationInfo={medicationInfo}
								setMedicationInfo={setMedicationInfo}
								isEditing={isEditing}
							/>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Flex>
		</>
	);
};

export default DrugDataMaintenanceTable;
