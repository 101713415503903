import React, { createContext, useContext, useState, ReactNode } from "react";

interface RegisteredSearchResultContextValue {
	registeredSearchResultStatus: boolean;
	setRegisteredSearchResultStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const RegisteredSearchResultContext = createContext<RegisteredSearchResultContextValue | undefined>(undefined);

interface RegisteredSearchResultStatusProps {
	children: ReactNode;
}

export const RegisteredSearchResultStatusProvider: React.FC<RegisteredSearchResultStatusProps> = ({ children }) => {
	const [registeredSearchResultStatus, setRegisteredSearchResultStatus] = useState(false);

	return (
		<RegisteredSearchResultContext.Provider
			value={{
				registeredSearchResultStatus,
				setRegisteredSearchResultStatus
			}}>
			{children}
		</RegisteredSearchResultContext.Provider>
	);
};

export const useRegisteredSearchResultStatus = (): RegisteredSearchResultContextValue => {
	const context = useContext(RegisteredSearchResultContext);

	if (!context) {
		throw new Error("useRoomStatus must be used within a RegisteredSearchResultStatus");
	}

	return context;
};
