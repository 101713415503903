import { create } from "zustand";

// 定義 ChargeModelProps 接口
export interface ChargeModelProps {
	// IPatientBase
	Pat_Code: string; // 病歷號
	Pat_Name: string; // 病患姓名
	Pat_IdnoType: number; // 身份證件種類 0.身分證 1.護照 2.居留證號
	Pat_IDNo?: string; // 身分證字號(建議)
	Pat_Gender?: string; // 病患性別
	Pat_Birth?: string; // 病患生日(建議)
	Pat_BirthTime?: string; // 出生時間 Ex : 13:00
	Pat_Tel?: string; // 家用電話
	Pat_Mobile?: string; // 行動電話
	Pat_Addr?: string; // 病患地址
	Pat_Memo?: string; // 病患備註
	Pat_Job?: string; // 病患職業
	Pat_Mark?: string; // 病患註記
	// IChargeBase
	Fee_Date: string; // 入帳日期
	Fee_TDiscount?: number; // 折扣
	Fee_Amount?: number; // 應收
	Fee_Allowance?: number; // 折讓
	Fee_Total?: number; // 實收金額
	Fee_ReceiptNo?: string; // 收據單號
	DataType?: string; // 類別
	Dr_Name?: string; // 看診醫師
	Nhi_Card?: string; // 卡號
	Fee_Memo?: string; // 批價備註
	// ICharge
	Handler?: string; // 經手人
	Shd_CO?: number; // 應收自付額
	Fee_HG?: number; // 掛號費
	Fee_CO?: number; // 自付額
	Fee_HD?: number; // 高藥費
	Fee_EQ?: number; // 高檢費
	Fee_PH?: number; // 診察費
	Fee_LPay?: number; // 押單費
	Fee_Drug?: number; // 藥費
	Fee_O?: number; // 內服藥費
	Fee_L?: number; // 外用藥費
	Fee_I?: number; // 注射藥費
	Fee_T?: number; // 注射技術費
	Fee_E?: number; // 檢查費
	Fee_Q?: number; // 檢驗費
	Fee_X?: number; // X 光費
	Fee_R?: number; // 復健費
	Fee_F?: number; // 處置費
	Fee_P?: number; // 手術費
	Fee_A?: number; // 麻醉費
	Fee_M?: number; // 材料費
	Fee_D?: number; // 病床費
	Fee_S?: number; // 藥事服務費
	Fee_N?: number; // 管灌費
	Fee_U?: number; // 精神費
	Fee_B?: number; // 血液費
	Fee_Y?: number; // 托嬰費
	Fee_J?: number; // 代辦費
	Fee_K?: number; // 其他費用
	Fee_HC?: number; // 高門診
	Nhi_PH?: number; // 診察費 (點數)
	Nhi_Drug?: number; // 藥費 (點數)
	Nhi_O?: number; // 內服藥費 (點數)
	Nhi_L?: number; // 外用藥費 (點數)
	Nhi_I?: number; // 注射藥費 (點數)
	Nhi_T?: number; // 注射技術費 (點數)
	Nhi_E?: number; // 檢查費 (點數)
	Nhi_Q?: number; // 檢驗費 (點數)
	Nhi_X?: number; // X 光費 (點數)
	Nhi_R?: number; // 復健費 (點數)
	Nhi_F?: number; // 處置費 (點數)
	Nhi_P?: number; // 手術費 (點數)
	Nhi_A?: number; // 麻醉費 (點數)
	Nhi_M?: number; // 材料費 (點數)
	Nhi_D?: number; // 病床費 (點數)
	Nhi_S?: number; // 藥服費 (點數)
	Nhi_N?: number; // 管灌費 (點數)
	Nhi_U?: number; // 精神費 (點數)
	Nhi_B?: number; // 血液費 (點數)
	Nhi_Y?: number; // 托嬰費 (點數)
	Nhi_J?: number; // 代辦費 (點數)
	Nhi_K?: number; // 其他費用 (點數)
	TNhi_Points?: number; // 總申請健保點數
	Give_S?: number; // 交付藥費
	Give_HD?: number; // 交付高藥費
	Discount_Code?: string; // 優免代碼
	Discount_Name?: string; // 優免名稱
}

// 定義 ChargeModelStore 接口
interface ChargeModelStore {
	chargeModel: ChargeModelProps;
	updateChargeModel: (chargeModel: ChargeModelProps) => void;
	resetChargeModel: () => void;
}

// 初始化 chargeModel 的值
export const initialChargeModel: ChargeModelProps = {
	// IPatientBase
	Pat_Code: "",
	Pat_Name: "",
	Pat_IdnoType: 0,
	Pat_IDNo: "",
	Pat_Gender: "",
	Pat_Birth: "",
	Pat_BirthTime: "",
	Pat_Tel: "",
	Pat_Mobile: "",
	Pat_Addr: "",
	Pat_Memo: "",
	Pat_Job: "",
	Pat_Mark: "",
	// IChargeBase
	Fee_Date: "",
	Fee_TDiscount: 0,
	Fee_Amount: 0,
	Fee_Allowance: 0,
	Fee_Total: 0,
	Fee_ReceiptNo: "",
	DataType: "",
	Dr_Name: "",
	Nhi_Card: "",
	Fee_Memo: "",
	// ICharge
	Handler: "",
	Shd_CO: 0,
	Fee_HG: 0,
	Fee_CO: 0,
	Fee_HD: 0,
	Fee_EQ: 0,
	Fee_PH: 0,
	Fee_LPay: 0,
	Fee_Drug: 0,
	Fee_O: 0,
	Fee_L: 0,
	Fee_I: 0,
	Fee_T: 0,
	Fee_E: 0,
	Fee_Q: 0,
	Fee_X: 0,
	Fee_R: 0,
	Fee_F: 0,
	Fee_P: 0,
	Fee_A: 0,
	Fee_M: 0,
	Fee_D: 0,
	Fee_S: 0,
	Fee_N: 0,
	Fee_U: 0,
	Fee_B: 0,
	Fee_Y: 0,
	Fee_J: 0,
	Fee_K: 0,
	Fee_HC: 0,
	Nhi_PH: 0,
	Nhi_Drug: 0,
	Nhi_O: 0,
	Nhi_L: 0,
	Nhi_I: 0,
	Nhi_T: 0,
	Nhi_E: 0,
	Nhi_Q: 0,
	Nhi_X: 0,
	Nhi_R: 0,
	Nhi_F: 0,
	Nhi_P: 0,
	Nhi_A: 0,
	Nhi_M: 0,
	Nhi_D: 0,
	Nhi_S: 0,
	Nhi_N: 0,
	Nhi_U: 0,
	Nhi_B: 0,
	Nhi_Y: 0,
	Nhi_J: 0,
	Nhi_K: 0,
	TNhi_Points: 0,
	Give_S: 0,
	Give_HD: 0,
	Discount_Code: "",
	Discount_Name: ""
};

// 創建 useChargeModelStore
export const useChargeModelStore = create<ChargeModelStore>(set => ({
	chargeModel: initialChargeModel,
	updateChargeModel: chargeModel =>
		set(() => ({
			chargeModel: chargeModel
		})),
	resetChargeModel: () =>
		set(() => ({
			chargeModel: initialChargeModel
		}))
}));

export default useChargeModelStore;
