import React, { useEffect, useState } from "react";
import { Input, Alert, AlertIcon, Box, Flex } from "@chakra-ui/react";

interface TimeInputProps {
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}
const TimeInput: React.FC<TimeInputProps> = ({ value, onChange, onKeyUp }) => {
	const [errorMessage, setErrorMessage] = useState("");

	const [formattedValue, setFormattedValue] = useState(value);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;
		const regex = /^\d*$/;
		if (regex.test(inputValue)) {
			setFormattedValue(inputValue);
		}
		if (isNaN(Number(inputValue))) {
			setErrorMessage("格式錯誤");
		} else {
			setErrorMessage("");
		}
	};
	const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		const timePattern = /^\d{4}$/;
		if (inputValue.length < 4 || !timePattern.test(inputValue)) {
			setErrorMessage("格式錯誤，請輸入 4 位數字");
			setFormattedValue("");
		} else {
			const formattedTime = inputValue.substring(0, 2) + ":" + inputValue.substring(2);
			setFormattedValue(formattedTime);

			const hours = parseInt(inputValue.substring(0, 2), 10);
			const minutes = parseInt(inputValue.substring(2), 10);

			if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
				setErrorMessage("");
				onChange(e);
			} else {
				setErrorMessage("時間超過限制");
				setFormattedValue("");
			}
		}
	};

	const handleFocus = () => {
		const inputValueWithoutColon = formattedValue.replace(":", "");
		setFormattedValue(inputValueWithoutColon);
	};

	useEffect(() => {
		setTimeout(() => {
			setErrorMessage("");
		}, 1500);
	}, [errorMessage]);
	// 只有清空時會觸發
	useEffect(() => {
		if (value === "") {
			setFormattedValue(value);
		}
	}, [value]);

	return (
		<div style={{ position: "relative" }}>
			<Input
				placeholder='HHMM （輸入 4 碼 24小時制）'
				type='text'
				value={formattedValue}
				required
				maxLength={4}
				onChange={handleChange}
				onKeyUp={onKeyUp}
				onBlur={handleBlur}
				onFocus={handleFocus}
				bgColor={"#fff"}
			/>
			{errorMessage && (
				<Flex pos={"absolute"} top={"100%"} rounded={"lg"} justify={"space-between"}>
					<Alert status='error'>
						<AlertIcon />
						<Box ml={"4px"}>{errorMessage}</Box>
					</Alert>
				</Flex>
			)}
		</div>
	);
};

export default TimeInput;
