import apiService from './apiService';

export type DeclareDownloadReqDataProps = {
    ym?: string;
};

export const callDeclareDownloadService = async (
    token: string,
    data?: string
): Promise<any> => {
    const queryParams = data
        ? { data }
        : { data: new Date().toISOString().slice(0, 7) };

    return apiService<any, any>({
        method: 'GET',
        path: '/api/declare/download',
        token,
        data: queryParams,
        responseType: 'arraybuffer',
    });
};
