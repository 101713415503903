import Swal from "sweetalert2";
import { AxiosResponse } from "axios";

/**
 * 處理從 API 獲取的數據，並格式化為統一的 JSON 格式
 * @param {AxiosResponse} response - 從 axios 獲取的響應
 * @returns {Promise<Object>} - 格式化後的 JSON 對象，包含 result 和 data
 */
export async function rsAPIResponseFormat(response: AxiosResponse) {
	try {
		if (response && response.status === 200) {
			const { data } = response;
			if (data.RtnCode === "0000") {
				if (data.model) {
					return {
						result: "success",
						data: data.model
					};
				} else {
					return {
						result: "success",
						data: data
					};
				}
			} else {
				return {
					result: "failure",
					data: data
				};
			}
		} else {
			return {
				result: "failure",
				data: response.data || "API 失敗"
			};
		}
	} catch (error) {
		console.error("Error in rsAPIResponseFormat:", error);
		Swal.fire({
			icon: "error",
			title: "API 整理失敗",
			showConfirmButton: true
		});
		return {
			result: "failure",
			data: "API 整理失敗"
		};
	}
}
