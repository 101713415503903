import { create } from "zustand";
import { useOpdModelStore, OpdModelProps } from "./useOpdModelStore";
import { useMedicalRecordModelStore, MedicalRecordModelProps } from "./useMedicalRecordModelStore";
import { useMedicalRecordDiagModelStore, MedicalRecordDiagModelProps } from "./useMedicalRecordDiagModelStore";
import { useMedicalRecordDrugModelStore, MedicalRecordDrugModelProps } from "./useMedicalRecordDrugModelStore";
import { useChargeModelStore, ChargeModelProps } from "./useChargeModelStore";
import { useHospitalModelStore, HospitalModelProps } from "./useHospitalModelStore";
import { usePatientModelStore, PatientModelProps } from "./usePatientModelStore";
import { useSlowRecModelStore, SlowRecModelProps } from "./useSlowRecModelStore";

// 定義 PrintPrescriptionProps 接口
export interface PrintPrescriptionProps {
	FormName: string;
	Opd: OpdModelProps; //
	MedicalRecord: MedicalRecordModelProps; //病歷模型
	MedicalRecordDiag: MedicalRecordDiagModelProps[];
	MedicalRecordDrug: MedicalRecordDrugModelProps[];
	Charge: ChargeModelProps;
	Hospital: HospitalModelProps;
	Patient: PatientModelProps;
	SlowRec: SlowRecModelProps;
}

// 初始化 PrintPrescriptionProps
export const initialPrintPrescriptionData: PrintPrescriptionProps = {
	FormName: "",
	Opd: useOpdModelStore.getState().opdModel, // 從 useOpdModelStore 獲取初始 patient
	MedicalRecord: useMedicalRecordModelStore.getState().medicalRecord,
	MedicalRecordDiag: useMedicalRecordDiagModelStore.getState().diagModels,
	MedicalRecordDrug: useMedicalRecordDrugModelStore.getState().drugModels,
	Charge: useChargeModelStore.getState().chargeModel,
	Hospital: useHospitalModelStore.getState().hospitalModel,
	Patient: usePatientModelStore.getState().patientModel,
	SlowRec: useSlowRecModelStore.getState().slowRecModel
};

// 定義 PrintPrescriptionState 接口
interface PrintPrescriptionState {
	PrintPrescriptionData: PrintPrescriptionProps;
	updatePrintPrescriptionData: (newData: Partial<PrintPrescriptionProps>) => void;
	resetPrintPrescriptionData: () => void;
}

// 創建 usePrintPrescriptionStore
export const usePrintPrescriptionStore = create<PrintPrescriptionState>(set => ({
	PrintPrescriptionData: initialPrintPrescriptionData,
	updatePrintPrescriptionData: newData =>
		set(state => ({
			PrintPrescriptionData: {
				...state.PrintPrescriptionData,
				...newData
			}
		})),
	resetPrintPrescriptionData: () =>
		set(() => ({
			PrintPrescriptionData: initialPrintPrescriptionData
		}))
}));

export default usePrintPrescriptionStore;
