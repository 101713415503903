// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datePickerCustomForWorkingSchedule .react-datepicker {
	border: none;
	background-color: transparent;
	box-shadow: none;
}

.datePickerCustomForWorkingSchedule .react-datepicker__header {
	background-color: transparent;
}

.datePickerCustomForWorkingSchedule .react-datepicker__today-button {
	background-color: #f0f0f0;
	border-top: none;
}
.datePickerCustomForWorkingSchedule .react-datepicker__today-button:hover {
	background-color: #e0e0e0;
}

.datePickerCustomForWorkingSchedule .react-datepicker__day-name {
	font-weight: 700;
	color: #708368;
}
`, "",{"version":3,"sources":["webpack://./src/style/components/datePickerCustomForWorkingSchedule.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,6BAA6B;CAC7B,gBAAgB;AACjB;;AAEA;CACC,6BAA6B;AAC9B;;AAEA;CACC,yBAAyB;CACzB,gBAAgB;AACjB;AACA;CACC,yBAAyB;AAC1B;;AAEA;CACC,gBAAgB;CAChB,cAAc;AACf","sourcesContent":[".datePickerCustomForWorkingSchedule .react-datepicker {\n\tborder: none;\n\tbackground-color: transparent;\n\tbox-shadow: none;\n}\n\n.datePickerCustomForWorkingSchedule .react-datepicker__header {\n\tbackground-color: transparent;\n}\n\n.datePickerCustomForWorkingSchedule .react-datepicker__today-button {\n\tbackground-color: #f0f0f0;\n\tborder-top: none;\n}\n.datePickerCustomForWorkingSchedule .react-datepicker__today-button:hover {\n\tbackground-color: #e0e0e0;\n}\n\n.datePickerCustomForWorkingSchedule .react-datepicker__day-name {\n\tfont-weight: 700;\n\tcolor: #708368;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
