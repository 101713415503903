import React, { useEffect, useState } from "react";
import { Box, Text, Flex, List, ListItem, Input } from "@chakra-ui/react";
import { callOpdRecordService } from "service/opdRecordService";
import { useDropDownMenuQuery } from "query/useDropDownMenuQuery";

// store
import { useFeeStore } from "store/TreatmentManagement/Payment";
import { useCashDataStore } from "store/TreatmentManagement/Payment";
import { useTreatmentInfoStore } from "store/TreatmentManagement";
import { useCurrentVisitInfoStore } from "store/Registration";
import { callAccItemsLevelService } from "service/accItemsLevelService";
import { useQuery } from "react-query";
import { useUserStore } from "store";
type accItemsLevel3Props = {
	id: string;
	code: string;
	name: string;
};
export const CashDetail = () => {
	const { treatmentInfo } = useTreatmentInfoStore();
	const { cashDataStore, updateCashDataStore } = useCashDataStore();
	const { currentVisitInfoData, updateCurrentVisitInfoData } = useCurrentVisitInfoStore();
	const { userInfo } = useUserStore();
	const { feeData } = useFeeStore();
	const [opdRecordData, setOpdRecordData] = useState();
	const [HGFeeUid, setHGFeeUid] = useState("");
	const [COFeeUid, setCOFeeUid] = useState("");
	const [LPAYFeeUid, setLPAYFeeUid] = useState("");
	const { data: accItemsLevel3Data, isSuccess: isAccItemsLevel3DataSuccess } = useQuery(
		"accItemsLevel3",
		() => callAccItemsLevelService(userInfo.token, 3),
		{
			enabled: !!userInfo.token,
			staleTime: Infinity
		}
	);
	const [accItemsLevel3, setAccItemLevel3] = useState<accItemsLevel3Props[]>([]);
	useEffect(() => {
		if (isAccItemsLevel3DataSuccess) {
			setAccItemLevel3(accItemsLevel3Data.lists);
			setHGFeeUid(accItemsLevel3Data.lists.find((item: accItemsLevel3Props) => item.code === "HG").id);
			setCOFeeUid(accItemsLevel3Data.lists.find((item: accItemsLevel3Props) => item.code === "CO").id);
			setLPAYFeeUid(accItemsLevel3Data.lists.find((item: accItemsLevel3Props) => item.code === "LPAY").id);
		}
	}, [isAccItemsLevel3DataSuccess]);
	const callOpdRecordAPI = async () => {
		if (treatmentInfo && treatmentInfo.id) {
			let data_id = treatmentInfo.id;
			if (data_id) {
				try {
					const result = await callOpdRecordService(userInfo.token, data_id);
					updateCurrentVisitInfoData({
						// cashier: result.cashier,
						data_id: result.info.data_id,
						opd_at: result.info.opd_at,
						cashier_uid: result.cashier.main.data_id,
						// cahier_type?????? 這個到底要不要
						total: result.cashier.main.total,
						discount: result.cashier.main.discount,
						final: result.cashier.main.final,
						vital_sign: result.vital_sign,
						cashier: result.cashier.detail
					});
				} catch (error) {
					console.error("調用OpdRecord服務時發生錯誤：", error);
					// 這裡可以添加錯誤處理邏輯，例如顯示錯誤消息給用戶
				}
			}
		}
	};
	useEffect(() => {
		callOpdRecordAPI();
	}, []);
	const { data: feeTypeData, isSuccess: isFeeTypeSuccess } = useDropDownMenuQuery("feeType", userInfo.token!);
	const [feeTypeLists, setFeeTypeLists] = useState([]);

	useEffect(() => {
		if (isFeeTypeSuccess) {
			setFeeTypeLists(feeTypeData.lists);
		}
	}, [isFeeTypeSuccess]);
	// 找到 "掛號費"
	const [receivable, setReceivable] = useState<number>(0);
	const [discount, setDiscount] = useState<number>(0);
	useEffect(() => {
		if (currentVisitInfoData && currentVisitInfoData.cashier && feeData) {
			const LPAYFee = Number(currentVisitInfoData?.cashier?.find(item => item.acc_item_uid === LPAYFeeUid)?.final) || 0;
			const HGFee = Number(currentVisitInfoData?.cashier?.find(item => item.acc_item_uid === HGFeeUid)?.final) || 0;
			const COFee = Number(currentVisitInfoData?.cashier?.find(item => item.acc_item_uid === COFeeUid)?.final) || 0;
			const receivableFee = Math.floor(LPAYFee + HGFee + COFee + feeData.mFeeHD + feeData.mFeeEQ + feeData.xAllFee);
			setReceivable(Math.floor(receivableFee));
			updateCashDataStore({
				receivableFee: receivableFee,
				registrationFee: HGFee,
				deductibleFee: COFee,
				depositFee: LPAYFee,
				discount: discount,
				actualFee: receivableFee - discount
			});
		}
	}, [currentVisitInfoData, feeData, discount]);
	const handleChangeDiscount = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		if (receivable - Number(value) >= 0) {
			setDiscount(Number(value));
		} else {
			setDiscount(Number(receivable));
		}
	};
	return (
		<>
			<Box w={"100%"} h={"100%"} p={"8px 12px"} overflowY={"auto"}>
				<Text mb={"4px"} textStyle={"semiTitle"}>
					現金收入明細/金額
				</Text>

				<Flex flexWrap={"wrap"} pos={"relative"} justify={"space-between"}>
					<Box flex={"0 0 calc(50% - 16px)"}>
						<Flex justify={"space-between"} align={"center"}>
							<Text fontWeight={"semibold"}>掛號</Text>
							<Text>
								{(() => {
									const finalValue =
										Number(currentVisitInfoData?.cashier?.find(item => item.acc_item_uid === HGFeeUid)?.final) || 0;
									return Math.floor(finalValue);
								})()}
							</Text>
							{/* <Text>
                                {currentVisitInfoData?.cashier?.[1]?.final 
                                    ? Math.floor(currentVisitInfoData.cashier[1].final)
                                    : 'N/A'}
                            </Text> */}
						</Flex>
						<Flex justify={"space-between"} align={"center"}>
							<Text fontWeight={"semibold"}>自付額</Text>
							<Text>
								{(() => {
									const finalValue =
										Number(currentVisitInfoData?.cashier?.find(item => item.acc_item_uid === COFeeUid)?.final) || 0;
									return Math.floor(finalValue);
								})()}
							</Text>
						</Flex>
						<Flex justify={"space-between"} align={"center"}>
							<Text fontWeight={"semibold"}>高藥費</Text>
							<Text>{feeData.mFeeHD}</Text>
						</Flex>
						<Flex justify={"space-between"} align={"center"}>
							<Text fontWeight={"semibold"}>高檢費</Text>
							<Text>{feeData.mFeeEQ}</Text>
						</Flex>
					</Box>
					<Box w={"1px"} h={"100%"} bg={"#646B48"} pos={"absolute"} top={0} left={"50%"}></Box>
					<Box flex={"0 0 calc(50% - 16px)"}>
						<Flex justify={"space-between"} align={"center"}>
							<Text fontWeight={"semibold"}>押單費</Text>
							<Text>
								{(() => {
									const finalValue =
										Number(currentVisitInfoData?.cashier?.find(item => item.acc_item_uid === LPAYFeeUid)?.final) || 0;
									return Math.floor(finalValue);
								})()}
							</Text>
						</Flex>{" "}
						<Flex justify={"space-between"} align={"center"}>
							<Text fontWeight={"semibold"}>自費</Text>
							<Text>{feeData.xAllFee}</Text>
						</Flex>
					</Box>
				</Flex>

				<List spacing={"4px"} mt={"4px"} maxW={"300px"} ml={"auto"}>
					<ListItem>
						<Flex
							justify={"space-between"}
							bg={"#646B48"}
							boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;"}
							p={"2px 16px"}
							rounded={"md"}>
							<Text fontWeight={"semibold"} textColor={"#fff"} pr={"20px"}>
								應收
							</Text>
							<Text fontWeight={"semibold"} textColor={"#fff"} flex={"1"} textAlign={"right"} px={"8px"}>
								{receivable}
							</Text>
							<Text fontWeight={"semibold"} textColor={"#fff"}>
								元
							</Text>
						</Flex>
					</ListItem>
					<ListItem>
						<Flex
							justify={"space-between"}
							align={"center"}
							bg={"#646B48"}
							boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;"}
							p={"2px 16px"}
							rounded={"md"}
							gap={"8px"}>
							<Text fontWeight={"semibold"} textColor={"#fff"} pr={"20px"}>
								折讓
							</Text>
							<Input
								fontWeight={"semibold"}
								flex={"1"}
								textAlign={"right"}
								px={"8px"}
								bg={"#fff"}
								boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;"}
								size={"lg"}
								border={"1px solid #45533F"}
								rounded={"md"}
								h={"28px"}
								value={discount}
								onChange={handleChangeDiscount}></Input>
							<Text fontWeight={"semibold"} textColor={"#fff"}>
								元
							</Text>
						</Flex>
					</ListItem>

					<ListItem>
						<Flex
							justify={"space-between"}
							align={"center"}
							bg={"#C5713A"}
							boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;"}
							p={"2px 16px"}
							rounded={"md"}>
							<Text fontWeight={"semibold"} textColor={"#fff"} pr={"20px"}>
								實收
							</Text>
							<Text fontWeight={"semibold"} textColor={"#fff"} flex={"1"} textAlign={"right"} px={"8px"}>
								{receivable - discount}
							</Text>
							<Text fontWeight={"semibold"} textColor={"#fff"}>
								元
							</Text>
						</Flex>
					</ListItem>
				</List>
			</Box>
		</>
	);
};
