import { postV1000hisGetTreatNumNoICCard2Service } from "service/RsService/V1000hisGetTreatNumNoICCard2Service";
import { postIcDataService } from "service/icDataServices";
import { SummaryNoICCardUpdateData } from "../../../SummaryNoICCardUpdateData";
import Swal from "sweetalert2";
import { TreatmentInfoProps } from "store/TreatmentManagement/useTreatmentInfoStore";
import { CCPEProps } from "store/TreatmentManagement";
import { DxProps } from "store/TreatmentManagement";
import { PrescriptionProps } from "store/TreatmentManagement";
import { cashDataStoreProps } from "store/TreatmentManagement/Payment";
import { accItemsLevelProps } from "service/accItemsLevelService";
import { FeeState } from "store/TreatmentManagement/Payment";
import { drugTypeListProps } from "service/drugTypeListService";
import { InstitutionInfoProps } from "store/DataSetting/useInstitutionInfoStore";
import { DrugFreqListsProps } from "store/DataSetting/useDrugFreqListStore";
import { UserInfoProps } from "store/useUserStore";

export async function handleAbnormalCardNumberComplete(
	treatmentInfo: TreatmentInfoProps,
	CCPEData: CCPEProps,
	dxList: DxProps[],
	prescriptionList: PrescriptionProps[],
	cashDataStore: cashDataStoreProps,
	accItemsLevelList: accItemsLevelProps[],
	feeData: FeeState,
	if_formal_data: string,
	caseUid: string,
	drugTypeLists: drugTypeListProps[],
	institutionInfoData: InstitutionInfoProps,
	drugFreqLists: DrugFreqListsProps[],
	userInfo: UserInfoProps,
	handleError: (title: string, text: string) => void
	//   hisGetTreatNumNoICCardResult?: pBufferProps,
	//   mb1?: mb1Props,
	//   treatNumV1000EncodeData?: TreatNumV1000EncodeResultProps,
) {
	let V1000hisGetTreatNumNoICCard2ReqData = {
		sHospid: institutionInfoData.nhid,
		sPatientId: treatmentInfo.id_no
	};

	let V1000hisGetTreatNumNoICCard2Result = await postV1000hisGetTreatNumNoICCard2Service(V1000hisGetTreatNumNoICCard2ReqData);
	console.log("V1000hisGetTreatNumNoICCard2Result", V1000hisGetTreatNumNoICCard2Result);

	if (V1000hisGetTreatNumNoICCard2Result?.result !== "success") {
		return handleError("取得就醫識別碼失敗", "請檢查健保卡或讀卡機狀態，並重試。");
		// 	title: "取得就醫識別碼失敗",
		// 	text: "請檢查健保卡或讀卡機狀態，並重試。",
		// 	showConfirmButton: true
		// });
		// return null;
	}

	const V1000hisGetTreatNumNoICCard2Data = V1000hisGetTreatNumNoICCard2Result.data;
	let ICCardUpdateData = SummaryNoICCardUpdateData(
		treatmentInfo,
		CCPEData,
		dxList,
		prescriptionList,
		cashDataStore,
		accItemsLevelList,
		feeData,
		if_formal_data,
		caseUid,
		drugTypeLists,
		institutionInfoData,
		drugFreqLists,
		undefined,
		undefined,
		V1000hisGetTreatNumNoICCard2Data
	);
	console.log("ICCardUpdateData", ICCardUpdateData);

	const ICDataResult = await postIcDataService(userInfo.token, ICCardUpdateData);

	if (ICDataResult.result !== "success") {
		return handleError("寫IC_DATA資料庫發生問題", "請檢查健保卡或讀卡機狀態，並重試。");
		// Swal.fire({
		// 	icon: "error",
		// 	title: "寫IC_DATA資料庫發生問題",
		// 	showConfirmButton: true
		// });
		// return null;
	}

	return ICCardUpdateData;
}
