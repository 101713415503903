import apiService from "./apiService";

const formatDate = (date: Date): string => {
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const day = date.getDate().toString().padStart(2, "0");
	return `${year}-${month}-${day}`;
};

const removeEmptyValues = (obj: any, depth: number = 0): any => {
	if (obj instanceof Date) {
		return formatDate(obj);
	}

	if (Array.isArray(obj)) {
		return obj.map(item => removeEmptyValues(item, depth + 1));
	} else if (typeof obj === "object" && obj !== null) {
		return Object.entries(obj).reduce<Record<string, any>>((acc, [key, value]) => {
			const cleanValue = removeEmptyValues(value, depth + 1);
			if (
				depth === 0 ||
				(cleanValue !== null &&
					typeof cleanValue !== "undefined" &&
					(cleanValue instanceof Date ||
						(Array.isArray(cleanValue) && cleanValue.length > 0) ||
						(!Array.isArray(cleanValue) && Object.keys(cleanValue).length > 0) ||
						cleanValue === 0 ||
						typeof cleanValue === "boolean"))
			) {
				acc[key] = cleanValue;
			}
			return acc;
		}, {});
	}

	return obj;
};

export type infoProps = {
	data_id?: string | null;
	name: string;
	medical_record_number: string;
	nhi_identity?: string;
	id_no_type: string;
	id_no: string;
	birth_date?: string;
	birth_time?: Date | null;
	sex?: string;
	job?: string;
	marriage?: string;
	first_visit?: Date | null;
	blood_type?: string;
	born_type?: string;
	born_times?: string;
	job_history?: string[];
	seek_history?: "";
	family_history?: string[];
	cancer?: string;
	surgery?: string;
	fracture?: string;
	other_chronic?: string;
	long_term_drugs?: string;
	if_smoke?: string;
	smoke_freq?: string;
	smoke_for_years?: string;
	quit_smoking?: string;
	if_chewing?: string;
	chewing_freq?: string;
	chewing_for_years?: string;
	quit_chewing?: string;
	if_drinking?: string;
	drinking_freq?: string;
	drinking_for_years?: string;
	quit_drinking?: string;
	if_exercise?: string;
	work_hours_per_day?: string;
	sleeping_hours?: string;
	membership?: string;
	// 增加這些
	if_drug_allergy?: string;
	drug_allergy_describe?: string;
	note?: string;

	opd_at: Date | null;
	// 保險身份註記 我自己先加
	insurerCode: string;
};
export type phoneArrProps = {
	data_id?: string;
	type?: string;
	number?: string;
	seq?: string;
};
// export type areaListsProps = {
//     code: string;
//     name: string
// }
export type addressArrProps = {
	data_id?: string;
	type?: string;
	city_uid?: string;
	city_name?: string;
	area_uid?: string;
	area_name?: string;
	content?: string;
	seq?: string;
	area_lists?: Record<string, string>;
};
export type emerArrProps = {
	data_id?: string;
	name?: string;
	relation?: string;
	phone?: string;
	seq?: string;
};
export type emailArrProps = {
	data_id?: string;
	content?: string;
	seq?: string;
};

export interface PatientInfoProps {
	info: infoProps;
	phone?: phoneArrProps[];
	address?: addressArrProps[];
	emer?: emerArrProps[];
	email?: emailArrProps[];
}

// 編輯病患基本資料
export const postPatientInfoService = async (token: string, finalData: PatientInfoProps) => {
	// const finalData2 = removeEmptyValues(finalData);
	// console.log(finalData2, '更新病患資訊 最終送出 這邊');
	console.log(finalData, "更新病患資訊 最終送出 這邊");

	return apiService({
		method: "POST",
		path: "/api/patient/info",
		token,
		data: finalData
	});
};

export type PatientInfoServiceForAllergyMedicationProps = {
	info: {
		data_id: string;
		drug_allergy_describe: string;
	};
};
export const postPatientInfoServiceForAllergyMedicationService = async (
	token: string,
	finalData: PatientInfoServiceForAllergyMedicationProps
) => {
	const finalData2 = removeEmptyValues(finalData);
	console.log(finalData2, "更新過敏藥物 最終送出");

	return apiService({
		method: "POST",
		path: "/api/patient/info",
		token,
		data: finalData2
	});
};
