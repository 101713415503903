// 這個 MedicalRecordStatusContext 代表病歷是哪種狀態

import React, { createContext, useContext, useState, ReactNode } from 'react';

interface MedicalRecordStatusContextType {
    medicalRecordStatus: string;
    setMedicalRecordStatus: (status: string) => void;
}

// 新病歷0, 已診1 從IC上傳來2, 從申報審核來3

const MedicalRecordStatusContext = createContext<
    MedicalRecordStatusContextType | undefined
>(undefined);

export const MedicalRecordStatusProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [medicalRecordStatus, setMedicalRecordStatus] = useState<string>('0');

    return (
        <MedicalRecordStatusContext.Provider
            value={{ medicalRecordStatus, setMedicalRecordStatus }}
        >
            {children}
        </MedicalRecordStatusContext.Provider>
    );
};

export const useMedicalRecordStatus = () => {
    const context = useContext(MedicalRecordStatusContext);
    if (context === undefined) {
        throw new Error(
            'useMedicalRecordStatus must be used within a MedicalRecordStatusProvider'
        );
    }
    return context;
};
