import apiService from "./apiService";

// data1 =  patient_uid
// data2 = data_type
export const callPatientSpecService = async (token: string, data1: string, data2: string) => {
	return apiService({
		method: "GET",
		path: `/api/patient/spec`,
		token,
		data: { data1, data2 }
	})
		.then(response => {
			if ((response.info && response.info.birth_date) || (response.info && response.info.first_visit)) {
				response.info.birth_date = new Date(response.info.birth_date);
			}
			return response;
		})
		.catch(error => {
			console.error("Error:", error);
			throw error;
		});
};
