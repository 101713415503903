import apiService from "./apiService";

// 取得診療科別清單
export const callHospSubjectService = async (token: string) => {
	return apiService<string, any>({
		method: "GET",
		path: "/api/hosp/subject",
		token
	});
};
