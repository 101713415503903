/* Hooks */
import React, { useState, useRef, useEffect, Dispatch, RefObject, createRef } from "react";
import {
	Button,
	Input,
	InputLeftElement,
	Select,
	Flex,
	InputGroup,
	Box,
	Tab,
	Tabs,
	TabList,
	TabPanels,
	TabIndicator,
	Text
} from "@chakra-ui/react";
import Swal from "sweetalert2";
import GroupMemberIcon from "components/CustomComponents/GroupMemberIcon";
import AddIcon from "components/CustomComponents/AddIcon";
// Props
import { RegSeqSettingProps } from "service/regSeqSettingService";
// API
import { callGTypeReservationTypeService } from "service/gTypeService";
import { callRegSeqSettingService } from "service/regSeqSettingService";
// import { callRegSeqSettingService } from 'service/callRegSeqSettingService';
import { postRegSeqSettingService } from "service/regSeqSettingService";
// import { postRegSeqSettingService } from 'service/postRegSeqSettingService';
import { deleteRegSeqSettingService } from "service/regSeqSettingService";
// import { deleteRegSeqSettingService } from 'service/deleteRegSeqSettingService';
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import { useUserStore } from "store";
interface RuleListsProps {
	data_id: string;
	rule_name: string;
}
interface NumberBoxItem {
	num: number;
	state: string;
	color: string;
}
interface ButtonListsProps {
	code: string;
	name: string;
	bgColor: string;
	hoverBgColor: string;
	color: string;
}
interface SettingItemProps {
	rule_type: number;
	seq_list: string;
}
const NumberingRuleTable = () => {
	// const token = localStorage.getItem('token');
	const { userInfo } = useUserStore();
	const [editStatus, setEditStatus] = useState<boolean>(false);
	const createInitialNumberBoxArray = () => {
		return Array.from({ length: 400 }, (_, index) => ({
			num: index + 1,
			state: "1",
			color: ""
		}));
	};
	const [numberBoxArray, setNumberBoxArray] = useState(createInitialNumberBoxArray);
	const [buttonLists, setButtonLists] = useState<ButtonListsProps[]>([]);
	const checkNumberBoxArray = useRef<{ num: number; state: string; color: string }[]>([]);

	const [numberBoxSize, setNumberBoxSize] = useState(80);
	const [numberContainerHeight, setNumberContainerHeight] = useState(0);
	const [selectedColor, setSelectedColor] = useState<string>("");
	const inputRefs = useRef<RefObject<HTMLInputElement>[]>([]);

	useEffect(() => {
		const handleResize = () => {
			const nowWindowWidth = window.innerWidth;
			let result = 0;

			if (nowWindowWidth > 1023) {
				result = (nowWindowWidth - 240 - 80) / 20;
			} else {
				result = (nowWindowWidth - 80) / 10;
			}

			setNumberBoxSize(result);
		};

		handleResize();

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	const mapButtonAttributes = (button: ButtonListsProps) => {
		const baseColor = `numRule.${button.code}00`;
		const hoverBgColor = `numRule.${button.code}50`;
		const color = button.code === "2" || button.code === "3" ? "#6C6351" : "#fff";

		return {
			...button,
			bgColor: baseColor,
			hoverBgColor: hoverBgColor,
			color: color
		};
	};

	const callReservationTypeAPI = async () => {
		if (userInfo.token) {
			try {
				const result = await callGTypeReservationTypeService(userInfo.token);
				if (result.result === "success") {
					const mappedButtonLists = result.lists.map(mapButtonAttributes);
					setButtonLists(mappedButtonLists);
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500,
					showConfirmButton: false
				});
			}
		}
	};
	useEffect(() => {
		callReservationTypeAPI();
	}, []);
	useEffect(() => {
		const handleResize = () => {
			const result = window.innerHeight - (300 + 96 + 32 + 60);

			setNumberContainerHeight(result);
		};

		handleResize();

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	const [selectionStart, setSelectionStart] = useState<number | null>(null);
	const [selectionEnd, setSelectionEnd] = useState<number | null>(null);

	useEffect(() => {}, [numberContainerHeight]);

	const checkRuleLists = useRef<RuleListsProps[]>([]);

	const handleEdit = () => {
		checkRuleLists.current = ruleLists;
		checkNumberBoxArray.current = numberBoxArray;
		setEditStatus(true);
	};

	const postRegSeqSettingAPI = async (finalData: RegSeqSettingProps) => {
		if (userInfo.token) {
			if (finalData.data_id) {
				try {
					const result = await postRegSeqSettingService(userInfo.token, finalData);
					if (result.result === "success") {
						Swal.fire({
							icon: "success",
							title: "儲存成功",
							showConfirmButton: false,
							timer: 1500
						});
					}
				} catch {
					Swal.fire({
						icon: "error",
						title: "後端服務錯誤",
						timer: 1500,
						showConfirmButton: false
					});
				}
			} else {
				try {
					const result = await postRegSeqSettingService(userInfo.token, finalData);
					if (result.result === "success") {
						Swal.fire({
							icon: "success",
							title: "新增成功",
							timer: 1500,
							showConfirmButton: false
						});
					}
					setRuleLists(prev => {
						if (!prev) return prev;

						return prev.map((item, index) => {
							if (index === selectedTabIndex) {
								return { ...item, data_id: result.data_id };
							}
							return item;
						});
					});
				} catch {
					Swal.fire({
						icon: "error",
						title: "後端服務錯誤",
						timer: 1500,
						showConfirmButton: false
					});
				}
			}
		}
	};
	const handleSave = () => {
		const colorArray = numberBoxArray.filter(item => item.state !== "");
		// array 1-7 對應顏色 1不傳
		const array1 = colorArray.filter(item => item.state === "1");
		const array2 = colorArray.filter(item => item.state === "2");
		const array3 = colorArray.filter(item => item.state === "3");
		const array4 = colorArray.filter(item => item.state === "4");
		const array5 = colorArray.filter(item => item.state === "5");
		const array6 = colorArray.filter(item => item.state === "6");
		const array7 = colorArray.filter(item => item.state === "7");

		let str1 = array1.map(item => item.num).join(", ");
		let str2 = array2.map(item => item.num).join(", ");
		let str3 = array3.map(item => item.num).join(", ");
		let str4 = array4.map(item => item.num).join(", ");
		let str5 = array5.map(item => item.num).join(", ");
		let str6 = array6.map(item => item.num).join(", ");
		let str7 = array7.map(item => item.num).join(", ");

		let finalArray = [];

		if (str1) finalArray.push({ rule_type: 1, seq_list: str1 });
		if (str2) finalArray.push({ rule_type: 2, seq_list: str2 });
		if (str3) finalArray.push({ rule_type: 3, seq_list: str3 });
		if (str4) finalArray.push({ rule_type: 4, seq_list: str4 });
		if (str5) finalArray.push({ rule_type: 5, seq_list: str5 });
		if (str6) finalArray.push({ rule_type: 6, seq_list: str6 });
		if (str7) finalArray.push({ rule_type: 7, seq_list: str7 });

		if (ruleLists[selectedTabIndex].rule_name === "") {
			Swal.fire({
				icon: "error",
				title: "儲存失敗，名稱不能空白",
				showConfirmButton: true,
				confirmButtonText: "好"
			});
			return;
		} else if (finalArray.length === 0) {
			Swal.fire({
				icon: "error",
				title: "儲存失敗，必須設定規則",
				showConfirmButton: true,
				confirmButtonText: "好"
			});
			return;
		} else {
			// 如果是原有的 Tab
			if (ruleLists[selectedTabIndex].data_id) {
				const finalData = {
					data_id: ruleLists[selectedTabIndex].data_id,
					rule_name: ruleLists[selectedTabIndex].rule_name,
					lists: finalArray
				};
				postRegSeqSettingAPI(finalData);
			} else {
				const finalData = {
					rule_name: ruleLists[selectedTabIndex].rule_name,
					lists: finalArray
				};
				postRegSeqSettingAPI(finalData);
			}
		}

		setEditStatus(false);
		setSelectedColor("");
	};

	const handleCancel = () => {
		if (ruleLists[selectedTabIndex].data_id) {
			// 如果是原有的 Tab 取消編輯
			setSelectedColor("");
			setNumberBoxArray(checkNumberBoxArray.current);
			setRuleLists(checkRuleLists.current);

			setEditStatus(false);
		} else {
			// 如果是新增的 Tab 取消編輯
			setEditStatus(false);
			setNumberBoxArray(createInitialNumberBoxArray);
			setRuleLists(
				ruleLists.filter((item, index) => index !== selectedTabIndex) // 把陣列中新增的該項目過濾掉，變回原本的陣列
			);
			setSelectedTabIndex(0);
			setSelectedColor("");
		}
	};

	const handleSelectedStart = (event: React.MouseEvent, index: number) => {
		setSelectionStart(index);
	};
	const handleSelectedEnd = (event: React.MouseEvent, index: number) => {
		setSelectionEnd(index);
		updateSelection(index);
	};

	const updateSelection = (endIndex: number, ruleType?: number) => {
		// 如果没有提供 ruleType，可以默认使用 selectedState
		const stateToUpdate = ruleType ? `type_${ruleType}` : selectedState;
		const updatedArray = numberBoxArray.map((item, index) => {
			if (selectionStart !== null) {
				const start = Math.min(selectionStart, endIndex);
				const end = Math.max(selectionStart, endIndex);

				if (index >= start && index <= end) {
					return {
						...item,
						state: stateToUpdate,
						color: selectedColor
					};
				}
			}
			return item;
		});

		setNumberBoxArray(updatedArray);
		setSelectionEnd(null);
		setSelectionStart(null);
	};

	const [selectedState, setSelectedState] = useState("");
	const handleColorChange = (color: string, code: string) => {
		if (selectedColor === color) {
			// 預設的現掛
			setSelectedState("");
			setSelectedColor("");
		} else {
			setSelectedState(code);
			setSelectedColor(color);
		}
	};
	const [ruleLists, setRuleLists] = useState<RuleListsProps[]>([]);
	const callRegSeqSettingAPI = async (data_id?: string) => {
		if (userInfo.token) {
			if (data_id) {
				try {
					const result = await callRegSeqSettingService(userInfo.token, data_id);
					if (result.result === "success") {
						if (result.info && result.info.setting) {
							let updatedArray = [...numberBoxArray];
							Object.entries(result.info.setting).forEach(([_, value]) => {
								const settingItem = value as SettingItemProps;
								updatedArray = applyRuleFromSetting(settingItem.rule_type, settingItem.seq_list, updatedArray);
							});

							setNumberBoxArray(updatedArray);
						}
					}
				} catch {
					Swal.fire({
						icon: "error",
						title: "後端服務錯誤",
						timer: 1500,
						showConfirmButton: false
					});
				}
			} else {
				try {
					const result = await callRegSeqSettingService(userInfo.token);
					if (result.result === "success") {
						setRuleLists(result.rule_lists);
						if (result.rule_setting && result.rule_setting.setting) {
							let updatedArray = [...numberBoxArray];
							Object.entries(result.rule_setting.setting).forEach(([_, value]) => {
								const settingItem = value as SettingItemProps;
								updatedArray = applyRuleFromSetting(settingItem.rule_type, settingItem.seq_list, updatedArray);
							});

							setNumberBoxArray(updatedArray);
						}
					}
				} catch {
					Swal.fire({
						icon: "error",
						title: "後端服務錯誤",
						timer: 1500,
						showConfirmButton: false
					});
				}
			}
		}
	};
	const applyRuleFromSetting = (ruleType: number, seqList: string, array: NumberBoxItem[]): NumberBoxItem[] => {
		const nums = seqList.split(", ").map((num: string) => parseInt(num.trim()));
		return array.map((item: NumberBoxItem) => {
			if (nums.includes(item.num)) {
				return {
					...item,
					state: `${ruleType}`,
					color: `numRule.${ruleType * 100}`
				};
			}
			return item;
		});
	};

	// const getColorForRuleType = (ruleType: number) => {
	//     const baseColor = `numRule.${ruleType + 2}00`;
	//     // 注意：hoverBgColor 可能在这里不需要，除非您也想要根据规则类型设置 hover 效果的颜色
	//     const hoverBgColor = `numRule.${ruleType + 2}50`;
	//     const color = ruleType === 2 || ruleType === 5 ? '#6C6351' : '#fff';

	//     return {
	//         bgColor: baseColor,
	//         hoverBgColor: hoverBgColor,
	//         color: color,
	//     };
	// };
	// const updateSelectionBasedOnSetting = (settingItem: SettingItemProps) => {
	//     const seqListArray = settingItem.seq_list.split(",").map(num => parseInt(num.trim()));
	//     const ruleType = settingItem.rule_type;
	//     const color = getColorForRuleType(ruleType);

	//     const updatedArray = numberBoxArray.map(item => {
	//         if (seqListArray.includes(item.num)) {
	//             // 确保 state 是字符串
	//             return { ...item, state: `type_${ruleType}`, color: color };
	//         }
	//         return item;
	//     });

	//     setNumberBoxArray(updatedArray);
	// };

	useEffect(() => {
		callRegSeqSettingAPI();
	}, []);

	const [ruleName, setRuleName] = useState<string>("");

	const handleChangeTabName = (event: React.ChangeEvent<HTMLInputElement>, data_id?: string, index?: number) => {
		if (data_id) {
			setRuleLists(prev => {
				if (!prev) return prev; // 如果 prev 是 null 或 undefined，則直接返回

				// 使用 map 函數返回一個新陣列
				return prev.map((item, index) => {
					// 如果當前索引匹配 selectedTabIndex，則更新 name 屬性
					if (index === selectedTabIndex) {
						return { ...item, rule_name: event.target.value };
					}

					// 其他情況下返回原物件
					return item;
				});
			});

			// 更新 ruleName 狀態
			setRuleName(event.target.value);
		} else {
			if (index === ruleLists.length - 1 && editStatus) {
				setRuleLists(prev => prev.map((item, idx) => (idx === index ? { ...item, rule_name: event.target.value } : item)));
			}
		}
	};

	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [newRuleName, setNewRuleName] = useState<string>("");

	const handleChangeTab = (index: number, data_id?: string) => {
		// 不會重複呼叫
		if (index === selectedTabIndex) {
			return;
		}

		// 已有的 Tab
		if (data_id) {
			if (editStatus) {
				return;
			} else {
				setNumberBoxArray(createInitialNumberBoxArray());
				setSelectedTabIndex(index);
			}
		}
		// 新的 Tab
		else {
			if (!editStatus) {
				setNumberBoxArray(createInitialNumberBoxArray());
				setEditStatus(true);
				setRuleLists(prev => [...prev, { rule_name: newRuleName, data_id: "" }]);
				setSelectedTabIndex(ruleLists.length);
			}
		}
	};

	useEffect(() => {
		if (selectedTabIndex >= 0) {
			const dataId = ruleLists[selectedTabIndex]?.data_id;
			if (dataId) {
				callRegSeqSettingAPI(dataId);
			}
		}
	}, [selectedTabIndex]);

	useEffect(() => {
		console.log("numberBoxArray:", numberBoxArray);
	}, [selectedTabIndex]);

	// 刪除樣板
	const deleteRegSeqSettingAPI = async () => {
		if (userInfo.token) {
			try {
				const result = await deleteRegSeqSettingService(userInfo.token, ruleLists[selectedTabIndex].data_id);
				if (result.result) {
					setRuleLists(prev => prev.filter(item => item.data_id !== ruleLists[selectedTabIndex].data_id));
					setSelectedTabIndex(0);
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500,
					showConfirmButton: false
				});
			}
		}
	};
	const handleDeactivated = () => {
		if (ruleLists.length === 1) {
			Swal.fire({
				title: "刪除失敗，僅剩一個樣板",
				icon: "error",
				showCancelButton: false,
				confirmButtonText: "好"
			});
			return;
		} else {
			Swal.fire({
				title: "是否要刪除？",
				icon: "question",
				showCancelButton: true,
				confirmButtonText: "是",
				cancelButtonText: "否"
			}).then(result => {
				if (result.isConfirmed) {
					deleteRegSeqSettingAPI();
					Swal.fire({
						title: "刪除成功",
						icon: "success",
						showConfirmButton: false,
						timer: 1500
					});
				}
			});
		}
	};

	useEffect(() => {
		inputRefs.current = ruleLists.map((_, i) => inputRefs.current[i] || createRef());
	}, [ruleLists]);

	useEffect(() => {
		if (editStatus && typeof selectedTabIndex === "number" && inputRefs.current[selectedTabIndex]) {
			const selectedInputRef = inputRefs.current[selectedTabIndex];
			if (selectedInputRef && selectedInputRef.current) {
				selectedInputRef.current.focus();
			}
		}
	}, [selectedTabIndex, editStatus]);

	return (
		<>
			<Box>
				<Tabs variant='unstyled' position='relative' index={selectedTabIndex}>
					<TabList bgColor={"gray.50"} borderRadius={"15px"} py={"8px"} px={"16px"} borderBottom={"2px solid #ccc"}>
						{ruleLists &&
							ruleLists.map((item, index) => (
								<>
									<Tab
										key={index}
										textColor={"#708368"}
										opacity={0.5}
										fontWeight={"semibold"}
										borderBottom={"2px solid transparent"}
										_selected={{
											opacity: 1
										}}
										_hover={{ opacity: 0.8 }}
										onClick={() => handleChangeTab(index, item.data_id)}
										display={"flex"}
										gap={"4px"}>
										<Box textColor={"rsPrimary.500"}>
											<HeroIcons name='users-fill'></HeroIcons>
										</Box>
										<Box>{item.rule_name}</Box>
									</Tab>
								</>
							))}

						{!editStatus && (
							<Tab
								key={ruleLists.length}
								textColor={"#708368"}
								opacity={0.5}
								fontWeight={"bold"}
								_selected={{
									opacity: 1
								}}
								_hover={{ opacity: 1 }}
								onClick={() => handleChangeTab(ruleLists.length)}
								display={"flex"}
								gap={"4px"}>
								<HeroIcons name='add-fill'></HeroIcons>
								<Box>
									{newRuleName}
									{"新增樣板"}
								</Box>
							</Tab>
						)}
					</TabList>
					<TabIndicator mt='-16px' height='2px' bg='#9B9B7A' borderRadius='1px' />
				</Tabs>
			</Box>
			<Flex
				mt={"8px"}
				bgColor={"gray.50"}
				rounded={"15px"}
				p={"16px"}
				flexDirection={"column"}
				justify={"flex-start"}
				gap={"16px"}
				borderBottom={"2px solid #ccc"}>
				<Flex align={"center"} gap={"16px"}>
					{/* 群組名稱 */}
					<Flex gap={"8px"} align={"center"} h={"40px"}>
						<Box textColor={"rsPrimary.500"}>
							<HeroIcons name='users-fill'></HeroIcons>
						</Box>

						{editStatus ? (
							<>
								<Input
									name=''
									flex={1}
									bgColor='#fff'
									value={ruleLists[selectedTabIndex].rule_name}
									onChange={event => handleChangeTabName(event, ruleLists[selectedTabIndex].data_id, selectedTabIndex)}
									ref={inputRefs.current[selectedTabIndex]}></Input>
							</>
						) : (
							<>
								<Flex align={"center"} gap={5}>
									{ruleLists.length && (
										<>
											<Text textStyle={"semiTitle"}>{ruleLists[selectedTabIndex].rule_name ?? ""}</Text>
											<Button size={"sm"} onClick={handleEdit} colorScheme='rsWarning'>
												編輯
											</Button>
										</>
									)}
								</Flex>
							</>
						)}
					</Flex>

					{editStatus ? (
						<>
							<Flex alignItems={"center"} gap={"8px"} bg={"#E9E5DA"} rounded={"lg"} p={"8px 16px"}>
								<label htmlFor='setType'>
									<Text textColor={"rsPrimary.500"} whiteSpace={"nowrap"} size={"sm"} fontWeight={"semibold"}>
										輸入方式
									</Text>
								</label>

								<Select id='setType' bgColor={"#fff"} borderColor={"rsPrimary"} size={"sm"}>
									<option value='0'>顏色拖曳模式</option>
									<option value='1' disabled>
										數字填號模式
									</option>
									<option value='2' disabled>
										單雙數填號模式
									</option>
								</Select>

								<Box className='hints'>
									<Text size={"sm"} textColor='rsPrimary.500' whiteSpace={"nowrap"}>
										＊選擇種類按鈕後，點選或拖曳數字格字
									</Text>
								</Box>
							</Flex>
						</>
					) : (
						<></>
					)}
				</Flex>

				<Box>
					<Flex gap={3} alignItems={"center"} mb={5}>
						{buttonLists &&
							buttonLists.map(item => (
								<Button
									key={item.code}
									onClick={editStatus ? () => handleColorChange(item.bgColor, item.code) : undefined}
									bgColor={item.bgColor}
									_hover={{
										cursor: editStatus ? "pointer" : "default",
										bgColor: item.hoverBgColor
									}}
									color={item.color}
									className={selectedColor === item.bgColor ? "heartbeat" : ""}>
									{item.name}
								</Button>
							))}
					</Flex>

					{/* 數字們 */}
					<Flex wrap={"wrap"} h={`${numberContainerHeight}`} overflowY={"scroll"} alignContent={"flex-start"}>
						{numberBoxArray &&
							numberBoxArray.map((item, index) => (
								<Box
									key={index}
									w={`${numberBoxSize}px`}
									aspectRatio={1}
									lineHeight={`${numberBoxSize}px`}
									borderWidth={"1px"}
									textAlign={"center"}
									textColor={item.color === "numRule.200" || item.color === "numRule.300" ? "#6C6351" : "#fff"}
									fontSize={window.innerWidth < 1440 ? "md" : "2xl"}
									alignSelf={"flex-start"}
									_hover={
										editStatus
											? {
													filter: "brightness(1.1)",
													cursor: "pointer",
													boxShadow: "inset 0 3px 10px #00000066",
													userSelect: "none"
												}
											: undefined
									}
									// borderColor={'#6C6351'}
									bgColor={item.state !== "1" ? item.color : "#868C6E"}
									onMouseDown={event => {
										selectedColor === "" ? "" : handleSelectedStart(event, index);
									}}
									onMouseUp={event => {
										selectedColor === "" ? "" : handleSelectedEnd(event, index);
									}}>
									{item.num}
								</Box>
							))}
					</Flex>
				</Box>

				{/* 控制按鈕群 */}
				<Flex gap={5} justify={"flex-end"}>
					{editStatus ? (
						<>
							<Button colorScheme='rsSteel' onClick={handleCancel}>
								取消
							</Button>
							<Button colorScheme='rsSecondary' onClick={handleSave}>
								儲存
							</Button>
						</>
					) : (
						<>
							<Button colorScheme='rsDanger' onClick={handleDeactivated}>
								刪除
							</Button>
							<Button colorScheme='rsWarning' onClick={handleEdit}>
								編輯
							</Button>
						</>
					)}
				</Flex>
			</Flex>
		</>
	);
};

export default NumberingRuleTable;
