import { callIcDataListsService, icDataListsReqDataProps } from "service/icDataListsService";
import { useICCardUpdateListStore } from "store/ICCardUpdate/useICCardUpdateListStore";
import { useICCardUpdateScopeStore } from "store/ICCardUpdate/useICCardUpdateScopeStore";
import { useICCardUpdateShowStatusStore } from "store/ICCardUpdate/useICCardUpdateShowStatusStore";
import { useUserStore } from "store";
import Swal from "sweetalert2";

export const useIcDataListsAPI = () => {
	const { userInfo } = useUserStore();
	const { clearICCardUpdateList, updateICCardUpdateList } = useICCardUpdateListStore();
	const { icCardUpdateScopeData } = useICCardUpdateScopeStore();
	const { showStatus } = useICCardUpdateShowStatusStore();
	const callIcDataListsAPI = async () => {
		clearICCardUpdateList;
		try {
			const result = await callIcDataListsService(userInfo.token, icCardUpdateScopeData);

			if (result.result === "success") {
				console.log("API 22 回傳結果：", result);
				const allItems = Object.values(result.lists)
					.flat()
					.map(item => ({
						...item,
						ready: item.upload_status === 0 ? true : false
					}));
				console.log("API 22 回傳結果有資料，整理後：", allItems);
				updateICCardUpdateList(allItems);
			} else {
				console.log("API 22 回傳結果無資料");
				updateICCardUpdateList([]);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			Swal.fire({
				icon: "error",
				title: "查詢過程發生錯誤",
				confirmButtonText: "確定"
			});
		}
	};
	return { callIcDataListsAPI };
};
