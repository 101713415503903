import React, { createContext, useContext, useState, ReactNode } from "react";

interface RegisteredTableHeightContextValue {
	registeredTableHeightStatus: number;
	setRegisteredTableHeightStatus: React.Dispatch<React.SetStateAction<number>>;
}

const RegisteredTableHeightContext = createContext<RegisteredTableHeightContextValue | undefined>(undefined);

interface RegisteredTableHeightStatusProps {
	children: ReactNode;
}

export const RegisteredTableHeightStatusProvider: React.FC<RegisteredTableHeightStatusProps> = ({ children }) => {
	const [registeredTableHeightStatus, setRegisteredTableHeightStatus] = useState(0);

	return (
		<RegisteredTableHeightContext.Provider
			value={{
				registeredTableHeightStatus,
				setRegisteredTableHeightStatus
			}}>
			{children}
		</RegisteredTableHeightContext.Provider>
	);
};

export const useRegisteredTableHeightStatus = (): RegisteredTableHeightContextValue => {
	const context = useContext(RegisteredTableHeightContext);

	if (!context) {
		throw new Error("useRegisteredTableHeightStatus must be used within a RegisteredTableHeightStatusProvider");
	}

	return context;
};
