import axios from "axios";
// import RsApiService from './RSApiService'

type finalDataProps = {
	cTreatItem: string;
	cBabyTreat: string;
	cTreatAfterCheck: string;
	iButterLen: string;
};
// Rs取得就醫序號新版-就醫識別碼
export const RshisGetSeqNumber256N1Service = async (finalData: finalDataProps) => {
	try {
		const response = await axios.post("http://localhost:23752/cshis/RshisGetSeqNumber256N1", finalData, {
			headers: {
				Accept: "*/*",
				"Content-Type": "application/json"
			}
		});

		console.log(response.data, "Rs取得就醫序號新版-就醫識別碼");
		return response.data;
	} catch (error: unknown) {
		if (error instanceof Error) {
			console.error("Error:", error.message);
		} else {
			console.error("An unknown error occurred");
		}
	}
};
