import React, { useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";

import { BasicInfo } from "./BasicInfo";
import { DxList } from "./DxList";
import { RxList } from "./RxList";
import { Points } from "./Points";
import { Others } from "./Others";
import { callMedicalRecordHistoryDeclareService } from "service/medicalRecordHistoryDeclareService";
import { useDeclareListSelectedItemStore } from "store/DeclarationAudit/useDeclareListSelectedItemStore";
import { useUserStore } from "store";
import { useDeclareListTabIndexStore } from "store/DeclarationAudit/useDeclareListTabIndexStore";
import { useDeclareListHistoryDataStore } from "store/DeclarationAudit/useDeclareListHistoryDataStore";
import { useDeclareHistoryLoadingStatusStore } from "store/DeclarationAudit/useDeclareHistoryLoadingStatusStore";
import Loading from "components/CustomComponents/Loading";
import Swal from "sweetalert2";
export const Details = () => {
	const { userInfo } = useUserStore();
	const { selectedItem } = useDeclareListSelectedItemStore();
	const { tabIndex } = useDeclareListTabIndexStore();
	const { historyData, updateHistoryData, resetHistoryData } = useDeclareListHistoryDataStore();
	const { isHistoryLoading, setIsHistoryLoading } = useDeclareHistoryLoadingStatusStore();
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true);
	}, []);

	const callMedicalRecordHistoryDeclareAPI = async () => {
		setIsHistoryLoading(true);
		try {
			if (selectedItem !== null) {
				const response = await callMedicalRecordHistoryDeclareService(userInfo.token, selectedItem?.medical_record_uid);
				if (response && response.result === "success") {
					updateHistoryData(response);
				} else if (response && response.result === "failure") {
					resetHistoryData();
					Swal.fire({
						icon: "error",
						title: "發生異常",
						text: response.msg,
						showConfirmButton: true
					});
				}
			}
		} catch (error) {
			console.error("調用醫療記錄歷史服務時發生錯誤:", error);
			Swal.fire({
				icon: "error",
				title: "發生異常",
				text: "無法獲取醫療記錄歷史數據",
				showConfirmButton: true
			});
		} finally {
			setIsHistoryLoading(false);
		}
	};

	useEffect(() => {
		if (isMounted) {
			if (selectedItem === null) {
				resetHistoryData();
			} else {
				callMedicalRecordHistoryDeclareAPI();
			}
		}
	}, [selectedItem]);

	return (
		<Box>
			<BasicInfo />

			<DxList />

			<RxList />

			{tabIndex == 0 && (
				<Flex>
					<Points />
					<Others />
				</Flex>
			)}
		</Box>
	);
};
