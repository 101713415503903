import { draiaiCorpGetTokenService } from "./draiaiCorpGetTokenService";
import { useTreatmentInfoStore } from "store/TreatmentManagement";
import { useInstitutionInfoStore } from "store/DataSetting/useInstitutionInfoStore";
import { useDraiaiLoadingStatus } from "contexts/TreatmentManagement/DraiaiLoadingStatusContext";
import JSEncrypt from "jsencrypt";
import Swal from "sweetalert2";

type EnstringProps = {
	clinicID: string; // 診所編號
	roomID: string; // 診間編號
	recordID: string; // 病歷號碼
	patientName: string; // 病患名稱
};

export const useDraiaiCorpGetTokenService = () => {
	const { treatmentInfo } = useTreatmentInfoStore();
	const { institutionInfoData } = useInstitutionInfoStore();
	const { setIsDraiaiLoading } = useDraiaiLoadingStatus();
	const postDraiaiCorpGetTokenAPI = async () => {
		const apikey = "CMqOt4Ps1zHUbJvsiCi62fr6GzjcHuPUb7y+cNs+ORU5";
		const publicKeyPem = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA8eLSiO007GLrd5/5YE6NhiHZbyIUdect
X3NhC6Iwgp2T3G0JHxF1YcbnhBe9IT63d0cVN3oq9CbZ44Tvt8Lhk6EDyZati/NlByZo2WBhMImK
1MFgRhMbmKr4Al8387NHI9cZk2xceHFPMNaFQAegHWPYRsgw3iBIaN7PKpF7C0FqJQi/FZdukEEK
o/wnCaZN+wL+SvXxZ6FRFsyYFvcjGp5PwbxeKSIsU7F020WDE1nLtZuiJkrJrjChmi2hSpar4uAT
ijTXZpCNzBprDH28E4g9bAoRGc4C4BsAM7xyWpE61ryYKZdKWpgAsJwgbY12IxoFlBgztY83gLXX
LgNXWQIDAQAB
-----END PUBLIC KEY-----

`;

		const data: EnstringProps = {
			// clinicID: institutionInfoData.nhid,
			clinicID: "3501091688", // 測試診所編號
			// roomID: treatmentInfo.clinic_room_uid,
			roomID: "R1", // 測試診間編號
			recordID: treatmentInfo.medical_record_number,
			patientName: treatmentInfo.patient_name
		};

		console.log("data", data);

		const jsonData = JSON.stringify(data);

		// // 創建 JSEncrypt 實例並設置公鑰
		const encryptor = new JSEncrypt();
		encryptor.setPublicKey(publicKeyPem);

		// // 使用 RSA 公鑰加密 JSON 字串
		const enstring = encryptor.encrypt(jsonData);

		if (typeof enstring !== "string") {
			console.error("加密失敗");
			return;
		}

		// // 轉換為 Base64 字串（jsencrypt 自動返回 Base64 字串）
		console.log("加密後的資料:", enstring);

		try {
			setIsDraiaiLoading(true);
			const response = await draiaiCorpGetTokenService(apikey, enstring);
			console.log("response", response);
			if (response.statusCode === 0 || response.statusCode === 200) {
				console.log("Dr AI accessToken", response.accessToken);
				// 開啟新視窗並導向 Dr.AI 網頁
				const drAIUrl = `https://quicknote-tw.draiai.com?Token=${response.accessToken}`;
				window.open(drAIUrl, "_blank", `width=1264,height=${window.innerHeight}`);
			} else {
				Swal.fire({
					icon: "warning",
					title: "發生錯誤",
					text: response.statusMessage,
					confirmButtonText: "確定"
				});
			}
			setIsDraiaiLoading(false);
		} catch (error) {
			setIsDraiaiLoading(false);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Something went wrong!"
			});
			console.error("獲取 Token 時發生錯誤:", error);
		}
	};

	return { postDraiaiCorpGetTokenAPI };
};
