import { useState, useEffect } from "react";
import { Flex, Box, Tag, Tooltip } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

// Tools
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import { ADStringToROCString } from "functions/ADStringToROCString";
import { VitalSignBody } from "./VitalSignBody";
import { SwalToast } from "components/CustomComponents/SwalComponents";
import { useFilterOpdList } from "./utils/useFilterOpdList";
// API
import { useCustomerAPI } from "service/customerServiceHelper";
import { useWaitingTableTabIndex } from "contexts";
import { notifyDoctorService } from "service/VHIC/NotifyDoctorService";
import { useWaitingListQuery } from "./utils/useWaitingListQuery";
import { deleteOpdRecordService, callOpdRecordService } from "service/opdRecordService";
// Store
import { useHistoryStore } from "store/TreatmentManagement";
import { usePrescriptionStore } from "store/TreatmentManagement";
import { useTreatmentInfoStore, useCCPEStore, useDxStore, usePricingStore } from "store/TreatmentManagement";
import { useUserStore } from "store";
import { useCurrentVisitInfoStore, usePatientInfoStore, useSearchPatientStore, useVitalSignsDataStore } from "store/Registration";
import { useInstitutionInfoStore } from "store/DataSetting/useInstitutionInfoStore";
// Status
import { useRegisteredFlowStatus } from "contexts/RegisteredFlowStatusContext";
import { useIsOpenDetailStatus } from "contexts/IsOpenDetailStatusContext";
import { useIsManualInputStatus } from "contexts/isManualInputStatusContext";
import { useIsChronicStatus } from "contexts/Registration/IsChronicContext";

export const BaseWaitingListTable = () => {
	const { userInfo } = useUserStore();
	const { callCustomerAPI } = useCustomerAPI();
	const { isManualInputStatus, setIsManualInputStatus } = useIsManualInputStatus();
	const { isOpenDetailStatus, setIsOpenDetailStatus } = useIsOpenDetailStatus();
	const { resetPrescriptionList } = usePrescriptionStore();
	const { institutionInfoData } = useInstitutionInfoStore();
	const { resetCCPEData } = useCCPEStore();
	const { resetDx } = useDxStore();
	const { resetHistoryData } = useHistoryStore();
	const { resetPricing } = usePricingStore();
	const navigate = useNavigate();
	const { waitingTableTabIndex } = useWaitingTableTabIndex();
	const { isChronicStatus, setIsChronicStatus } = useIsChronicStatus();

	const {
		setRegisteredFlowStatus,
		setShowPatientManagementStructureStatus,
		addNewDataToWaitingTableStatus,
		setAddNewDataToWaitingTableStatus
	} = useRegisteredFlowStatus();
	const { resetSearchPatientData } = useSearchPatientStore();

	const { treatmentInfo, updateTreatmentInfo, resetTreatmentInfo } = useTreatmentInfoStore();

	let location = useLocation();
	const { currentVisitInfoData, updateCurrentVisitInfoData, resetCurrentVisitInfoData } = useCurrentVisitInfoStore();
	const { resetVitalSignsData } = useVitalSignsDataStore();
	const { patientInfo, updatePatientInfo, resetPatientInfo } = usePatientInfoStore();
	const pathName = location.pathname;
	const pathSegments = pathName.split("/");
	const lastSegment = pathSegments.pop();
	const { data: opdData, isLoading, error, refetch } = useWaitingListQuery(waitingTableTabIndex);
	const filteredData = useFilterOpdList(opdData?.lists || {});
	// const [doctor_account, setDoctor_account] = useState('');
	// useEffect(() => {
	//     const fetchMedicalStaffSpec = async () => {
	//         try {
	//             console.log(currentVisitInfoData.doctor_uid, 'currentVisitInfoData.doctor_uid');
	//             const result = await callMedicalStaffSpecService(userInfo.token, treatmentInfo.doctor_uid);
	//             setDoctor_account(result.ot_account || '');
	//             console.log(result, 'result 哪個醫師');
	//         } catch (error) {
	//             console.error('Error fetching medical staff spec:', error);
	//         }
	//     };

	//     fetchMedicalStaffSpec();
	// }, [treatmentInfo, userInfo.token]);

	useEffect(() => {
		if (addNewDataToWaitingTableStatus) {
			refetch().then(() => {
				setAddNewDataToWaitingTableStatus(false);
			});
		}
	}, [addNewDataToWaitingTableStatus, refetch, setAddNewDataToWaitingTableStatus]);

	const resetAllRegistrationStores = () => {
		resetCurrentVisitInfoData();
		resetPatientInfo();
		resetVitalSignsData();
		resetSearchPatientData();
	};

	const handleOpenDetail = async (data_id: string, status: string) => {
		resetAllRegistrationStores();
		try {
			const result = await callOpdRecordService(userInfo.token!, data_id);
			console.log(result, "dddd 我看能怎處理");
			if (result?.result === "success") {
				//基本資料

				updatePatientInfo({
					...patientInfo,
					info: {
						...patientInfo.info,
						data_id: result.info.patient_uid,
						name: result.info.patient_name,
						medical_record_number: result.info.medical_record_number,
						// id_no_type: '1', //沒傳 先給身分證
						id_no: result.info.id_no,
						birth_date: result.info.birth_date ? new Date(result.info.birth_date) : null,
						birth_time: result.info.birth_time ? new Date(result.info.birth_time) : null,
						sex: result.info.sex,
						if_drug_allergy: result.info.drug_allergy_describe ? "1" : "0",
						drug_allergy_describe: result.info.drug_allergy_describe,
						// job
						// marriage
						first_visit: result.info.first_visit ? new Date(result.info.first_visit) : null //但沒傳
						// blood_type
						// born_type
						// born_times
						// job_history
						// seek_history
						// family_history
						// cancer
						// surgery
						// fracture
						// other_chronic
						// long_term_drugs
						// if_smoke: '',
						// smoke_freq: '',
						// smoke_for_years: '',
						// quit_smoking: '',
						// opd_at: result.info.opd_at,
					}
				});

				// result.info.my_type 就醫類別

				//掛號資訊

				updateCurrentVisitInfoData({
					member_uid: result.info.membership,
					cashier: result.cashier.detail,
					data_id: result.info.data_id,
					opd_at: result.info.opd_at,
					cashier_uid: result.cashier.main.data_id,
					nhi_type: result.info.nhi_type,
					// cahier_type?????? 這個到底要不要
					total: result.cashier.main.total,
					discount: result.cashier.main.discount,
					final: result.cashier.main.final,
					vital_sign: result.vital_sign,
					clinic_room_and_doctor: result.info.clinic_room_uid + "_" + result.info.doctor_uid,
					doctor_uid: result.info.doctor_uid,
					clinic_room_uid: result.info.clinic_room_uid,
					shift_uid: result.info.shift_uid,
					...(status === "視訊" ? { card_no: "V999" } : {}),
					...(result.info.card_no ? { card_no: result.info.card_no } : {}),
					mt_type: result.info.mt_type,

					// cashier: [
					//     result.cashier.detail
					// ]
					refillable_uid: result.info?.refillable_uid || "",
					refillable_detail_uid: result.info?.refillable_detail_uid || ""
				});

				setIsOpenDetailStatus(true);
				setRegisteredFlowStatus("second");
				setShowPatientManagementStructureStatus(true);
				setIsManualInputStatus(true);

				if (result.alert && result.alert[0] === "可能為慢性病連續處方箋") {
					setIsChronicStatus(true);
					Swal.fire({
						icon: "warning",
						title: "有慢性病連續處方箋 \n 是否前往連箋領藥？",

						showConfirmButton: true,
						showCancelButton: true,
						confirmButtonText: "是",
						cancelButtonText: "否"
					}).then(result => {
						if (result.isConfirmed) {
							console.log("使用者選擇前往領藥");
							setRegisteredFlowStatus("SequentialPrescription");
						}
					});
				}
			}
		} catch {
			Swal.fire({
				icon: "error",
				title: "後端服務錯誤",
				timer: 1500,
				showConfirmButton: false
			});
		}
	};

	const handleGoToConsultingRoom = async (id: string, status: string) => {
		await callCustomerAPI();
		try {
			// Reset all necessary data
			resetTreatmentInfo();
			resetHistoryData();
			resetPrescriptionList();
			resetDx();
			resetCCPEData();
			resetPricing();

			const account = institutionInfoData.ot_admin_account || "";
			const password = institutionInfoData.ot_admin_password || "";
			if (status === "視訊") {
				const result = await callOpdRecordService(userInfo.token!, id);
				console.log(result, "我想拿到病人id");
				if (result.result === "success") {
					await notifyDoctorService({
						account: account,
						pw: password,
						hisCaseID: result.info.patient_uid || "",
						doctorID: result.info.doctor_uid || ""
					});
				}
			}

			// Update treatment info
			await updateTreatmentInfo({
				id: id
			});

			// Navigate to the consulting room page
			navigate("/OPDPage/ConsultingRoomPage");
		} catch (error) {
			console.error("Error in handleGoToConsultingRoom:", error);
			// Handle the error appropriately, e.g., show an error message to the user
		}
	};
	const handleDeleteRegistration = async (data_id: string) => {
		deleteOpdRecordAPI(data_id);
	};
	const deleteOpdRecordAPI = async (data_id: string) => {
		let finalData = { data_id: data_id };

		try {
			const result = await deleteOpdRecordService(userInfo.token!, finalData);
			if (result.result === "success") {
				// const formattedOpdList = result.lists.map(
				//     (item: OpdArrListsProps) => ({
				//         ...item,
				//         birth_date: ADStringToROCString(item.birth_date),
				//     })
				// );

				SwalToast.fire({
					icon: "success",
					title: "退掛成功"
				});
				refetch();
			}
		} catch {
			Swal.fire({
				icon: "error",
				title: "後端服務錯誤",
				timer: 1500,
				showConfirmButton: false
			});
		}
	};

	return (
		<>
			{filteredData &&
				Object.entries(filteredData).map((item, index) => (
					<Box key={index} w={"100%"}>
						<Flex
							p={"5px"}
							align={"center"}
							borderBottom={"1px solid #868C6E"}
							onClick={
								lastSegment === "RegisteredPage" ? undefined : () => handleGoToConsultingRoom(item[1].id, item[1].status)
							}
							_hover={
								ADStringToROCString(item[1].opd_at) === ADStringToROCString(new Date().toString())
									? {
											bg: "#DDDBBA33",
											cursor: "pointer"
										}
									: {
											bg: "red.100",
											cursor: "pointer"
										}
							}
							bg={ADStringToROCString(item[1].opd_at) === ADStringToROCString(new Date().toString()) ? "" : "red.50"}
							w={"100%"}>
							<Box textColor={"rsPrimary.600"} px={"4px"} flex={"1 0 5%"}>
								{/* {ADStringToROCString(item[1].opd_at) ===
                                  ADStringToROCString(new Date().toString()) ? (
                                    item[1].reg_number
                                ) : (
                                    <Tag
                                        colorScheme="red"
                                        boxShadow={'0 0 1px rgba(0,0,0,0.55)'}
                                    >
                                        {ADStringToROCString(item[1].opd_at)
                                            .split('.')
                                            .slice(1)
                                            .join('.')}
                                    </Tag>
                                )} */}
								{item[1].status !== "候診/報到" ? (
									<Tag colorScheme='green'>{item[1].status}</Tag>
								) : ADStringToROCString(item[1].opd_at) === ADStringToROCString(new Date().toString()) ? (
									item[1].reg_number
								) : (
									<Tag colorScheme='red' boxShadow={"0 0 1px rgba(0,0,0,0.55)"}>
										{ADStringToROCString(item[1].opd_at).split(".").slice(1).join(".")}
									</Tag>
								)}
							</Box>
							<Box textColor={"rsPrimary.600"} px={"4px"} flex={"1 0 5%"}>
								{item[1].shift_name}
							</Box>
							<Box textColor={"rsPrimary.600"} px={"4px"} flex={"1 0 5%"}>
								{item[1].clinic_room_name}
							</Box>
							<Box textColor={"rsPrimary.600"} px={"4px"} flex={"1 0 9%"}>
								{item[1].doctor_name}
							</Box>
							<Box textColor={"rsPrimary.600"} px={"4px"} flex={"1 0 9%"}>
								{item[1].patient_name}
							</Box>
							<Box textColor={"rsPrimary.600"} px={"4px"} flex={"1 0 4%"}>
								{item[1].nhi_type_name}
							</Box>
							<Box textColor={"rsPrimary.600"} px={"4px"} flex={"1 0 7%"}>
								{ADStringToROCString(item[1].birth_date)}
							</Box>
							<Box textColor={"rsPrimary.600"} px={"4px"} flex={"1 0 5%"}>
								{item[1].card_no}
							</Box>
							<Box textColor={"rsPrimary.600"} px={"4px"} flex={"1 0 12%"}>
								{item[1].medical_record_number}
							</Box>
							<VitalSignBody key={index} vital_signs={item[1].vital_sign} id={item[1].id} />

							<Box textColor={"rsPrimary.600"} px={"4px"} flex={"1 0 6%"}>
								<>
									<Tooltip
										hasArrow
										label={item[1].note && item[1].note != "" ? item[1].note : ""}
										bg='red.600'
										placement={"right"}>
										<Box w={"24px"} h={"24px"} mx={"auto"}>
											<HeroIcons name='notice'></HeroIcons>
										</Box>
									</Tooltip>
								</>
							</Box>
							{lastSegment === "RegisteredPage" ? (
								<>
									<Box textColor={"rsPrimary.600"} px={"4px"} flex={"1 0 6%"}>
										<Box
											w={"24px"}
											h={"24px"}
											mx={"auto"}
											onClick={() => handleOpenDetail(item[1].id!, item[1].status)}
											opacity={0.8}
											_hover={{
												cursor: "pointer",
												opacity: 1
											}}>
											<HeroIcons name='openWindow'></HeroIcons>
										</Box>
									</Box>
									<Box textColor={"rsPrimary.600"} px={"4px"} flex={"1 0 4%"}>
										<Box
											w={"24px"}
											h={"24px"}
											mx={"auto"}
											onClick={() => handleDeleteRegistration(item[1].id!)}
											textColor={"rsDanger.500"}
											opacity={0.8}
											_hover={{
												cursor: "pointer",
												opacity: 1
											}}>
											<HeroIcons name='delete-fill'></HeroIcons>
										</Box>
									</Box>
								</>
							) : (
								<></>
							)}
						</Flex>
					</Box>
				))}
		</>
	);
};
