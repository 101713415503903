import { Box, Flex, Text, Button, Input, List } from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import { useEffect, useState } from "react";
import { Sign } from "./VitalSignComponents/Sign";
import { useSignContext } from "contexts";
import { useTreatmentInfoStore, VitalSignProps } from "store/TreatmentManagement";
import { useVitalSignQuery, vitalSignSettingListsProps } from "../../../../components/WaitingListTable/Components/utils/useVitalSignQuery";

export const VitalSigns = () => {
	const { lists, isLoading, error } = useVitalSignQuery();
	const [isShowAll, setIsShowAll] = useState<boolean>(false);

	return (
		<>
			<Box pos={"relative"} h={isShowAll ? "120px" : "auto"}>
				<Flex
					direction={"column"}
					gap={"4px"}
					zIndex={isShowAll ? 50 : 0}
					pos={isShowAll ? "fixed" : "relative"}
					bg={"#fff"}
					w={"100%"}
					maxW={isShowAll ? "480px" : "100%"}
					p={isShowAll ? "8px 16px" : "0"}
					rounded={isShowAll ? "lg" : "none"}
					boxShadow={isShowAll ? "lg" : "none"}>
					<Flex justify={"space-between"}>
						<Flex align={"center"} gap={"8px"} _hover={{ bg: "gray.50" }}>
							<Text textStyle={"semiTitle"}>基礎體徵 </Text>
						</Flex>

						{isShowAll ? (
							<>
								{" "}
								<Button variant={"link"} colorScheme='gray' size={"sm"} onClick={() => setIsShowAll(pre => !pre)}>
									<HeroIcons name='chevron-left-sm'></HeroIcons>
									關閉
								</Button>
							</>
						) : (
							<>
								{" "}
								<Button variant={"link"} colorScheme='gray' size={"sm"} onClick={() => setIsShowAll(pre => !pre)}>
									全部
									<HeroIcons name='chevron-right-sm'></HeroIcons>
								</Button>
							</>
						)}
					</Flex>
					<List spacing={"4px"} h={isShowAll ? "100%" : `calc(28px * 3 + 8px)`} overflow={"hidden"}>
						{lists &&
							lists.map((item: vitalSignSettingListsProps, index: number) => <Sign key={index} item={item} index={index} />)}
					</List>
				</Flex>
				<Box
					pos={"fixed"}
					zIndex={40}
					inset={0}
					bg={"rgba(0,0,0,0.32)"}
					hidden={!isShowAll}
					onClick={() => {
						setIsShowAll(pre => !pre);
					}}></Box>
			</Box>
		</>
	);
};
