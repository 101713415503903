import { Box, Flex, Text, Heading, Input, Button, Tag, VStack, HStack } from "@chakra-ui/react";
import { useEffect, useState, ChangeEvent } from "react";
import { useRegisteredFlowStatus } from "contexts/RegisteredFlowStatusContext";
import { useUserStore } from "store";
import { useTreatmentInfoStore } from "store/TreatmentManagement";
import { callRsGetPeaCareNameService } from "service/RsService/RsGetPeaCarenameService";
import { ADDateToROCString } from "functions/ADDateToROCString";
import { useIsChronicStatus } from "contexts/Registration/IsChronicContext";
import Swal from "sweetalert2";
import { useControlledDrugSearch } from "hooks/useControlledDrugSearch";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";

import { callPatientSpecService } from "service/patientSpecService";

import { usePatientInfoStore } from "store/Registration";
export const AsideMenu = () => {
	const [doctorROCId, setDoctorROCId] = useState("");
	const { userInfo } = useUserStore();
	const { treatmentInfo } = useTreatmentInfoStore();
	const {
		registeredFlowStatus,
		setRegisteredFlowStatus,
		showPatientManagementStructureStatus,
		setShowPatientManagementStructureStatus,
		reLoadMainInformationStatus,
		setReLoadMainInformationStatus
	} = useRegisteredFlowStatus();

	const { patientInfo, updatePatientInfo } = usePatientInfoStore();

	const { isChronicStatus, setIsChronicStatus } = useIsChronicStatus();

	const handleOpenMedCloudWindow = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const target = e.target as HTMLButtonElement;
		const url = target.getAttribute("data-url");
		const windowFeatures = "width=980,height=567,top=0,left=0";

		if (url) {
			const newWindow = window.open(url, "_blank", windowFeatures);
			if (newWindow) {
				newWindow.focus();
			}
		}
	};

	// const handleOpenControlledDrugWindow = async (
	//     e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	// ) => {
	//     try {
	//         await callMedicalStaffSpecAPI();
	//         await callRsGetPeaCarenameAPI();
	//     } catch (error) {
	//         console.error('發生錯誤:', error);
	//         Swal.fire({
	//             icon: 'error',
	//             title: '操作失敗',
	//             text: '請稍後再試',
	//             confirmButtonText: '確定',
	//         });
	//     }
	// };
	// const callMedicalStaffSpecAPI = async () => {
	//     try {
	//         const result = await callMedicalStaffSpecService(
	//             userInfo.token!,
	//             treatmentInfo.doctor_uid
	//         );
	//         if (result.id_no) {
	//             setDoctorROCId(result.id_no);
	//         }
	//     } catch {
	//         alert('請確認代理人有開啟');
	//     }
	// };

	// const callRsGetPeaCarenameAPI = async () => {
	//     if (treatmentInfo.birth_date) {
	//         const formatPatBirth = ADDateToROCString(treatmentInfo.birth_date);
	//         const splitFormatPatBirth = formatPatBirth.replace(/\./g, '').padStart(7, '0');
	//         const url = 'https://medcloud.nhi.gov.tw/iese0000/IESE0200S00.aspx';
	//         console.log('Formatted birth date:', splitFormatPatBirth);
	//         let finalData = {
	//             dr_idno: treatmentInfo.doctor_id_no,
	//             pat_idno: treatmentInfo.id_no,
	//             pat_birth: splitFormatPatBirth,
	//             com_port: '0'
	//         }
	//         console.log(finalData, 'finalData');

	//         // 測試用
	//         // let finalData = {
	//         //     dr_idno: '9999999999',
	//         //     pat_idno: 'Z299999965',
	//         //     pat_birth: '0930416',
	//         //     com_port: '0',
	//         // };
	//         try {
	//             const result = await callRsGetPeaCareNameService(finalData);
	//             if (result) {
	//                 if (result.ERRORCODE == 0) {
	//                     const url = result.pBuffer;
	//                     const windowFeatures =
	//                         'width=980,height=567,top=0,left=0';
	//                     if (url) {
	//                         const newWindow = window.open(
	//                             url,
	//                             '_blank',
	//                             windowFeatures
	//                         );
	//                         if (newWindow) {
	//                             newWindow.focus();
	//                         }
	//                     }
	//                 } else if (result.ERRORCODE && result.ERRORCODE !== 0) {
	//                     Swal.fire({
	//                         icon: 'error',
	//                         title: '錯誤',
	//                         confirmButtonText: '確定',
	//                     });
	//                 }
	//             }
	//         } catch {
	//             Swal.fire({
	//                 icon: 'error',
	//                 title: '錯誤',
	//                 text: '卡機異常',
	//                 confirmButtonText: '確定',
	//             });
	//         }
	//     }
	// };

	const { handleOpenControlledDrugWindow } = useControlledDrugSearch();

	const handleOpenControlledDrug = () => {
		handleOpenControlledDrugWindow(userInfo.token!);
	};

	const AsideButton = {
		bg: "transparent",
		px: "16px",
		py: "0px",
		w: "100%",
		h: "30px",
		justifyContent: "flex-start",
		textColor: "rsPrimary.500",
		boxShadow: "none",
		_hover: {
			boxShadow: "none",
			bg: "#E7D7C1"
		}
	};

	// 有連續處方簽
	const [isSequential, setIsSequential] = useState<boolean>(true);

	const callPatientSpecAPI = async () => {
		try {
			const result = await callPatientSpecService(userInfo.token!, patientInfo.info.data_id!, "basic");

			if (result?.result === "success") {
				console.log(result, "只能來看這了吧");
				let resultInfo = result.info;
				if (result.info.membership === "") {
					const { membership, ...rest } = result.info;
					resultInfo = rest;
				}
				updatePatientInfo({
					info: resultInfo,
					...(result.phone && { phone: result.phone }),
					...(result.address && { address: result.address }),
					...(result.emer_contactor && {
						emer: result.emer_contactor
					}),
					...(result.email && { email: result.email })
				});
			}
		} catch {
			Swal.fire({
				icon: "error",
				title: "取得病患資料時發生錯誤",
				timer: 1500,
				showConfirmButton: false
			});
		}
	};

	const handleGoBasicData = async () => {
		callPatientSpecAPI();
		setRegisteredFlowStatus("first");
	};

	return (
		<>
			<Flex bg={"gray.150"} rounded={"16px"} borderBottom={"2px solid #ccc"}>
				<VStack spacing='8px' align='stretch' p={"12px"}>
					<Box>
						<Button onClick={handleGoBasicData} sx={AsideButton}>
							基本資料
						</Button>
					</Box>
					<Box>
						<Button
							onClick={() => {
								setRegisteredFlowStatus("second");
							}}
							isDisabled={!patientInfo.info.data_id}
							sx={AsideButton}>
							當次掛號
						</Button>
					</Box>
					<Box>
						<Button disabled opacity={"0.4"} sx={AsideButton} isDisabled={!patientInfo.info.data_id}>
							重要記載
						</Button>
					</Box>
					<Box>
						<Button
							onClick={() => {
								setRegisteredFlowStatus("Receivables");
							}}
							isDisabled={!patientInfo.info.data_id}
							sx={AsideButton}>
							欠還款管理
						</Button>
					</Box>
					<Box>
						<Button
							sx={AsideButton}
							isDisabled={!patientInfo.info.data_id}
							onClick={() => {
								setRegisteredFlowStatus("MedicalHistory");
							}}>
							歷史病歷
						</Button>
					</Box>
					<Box>
						<Button disabled opacity={"0.4"} sx={AsideButton} hidden isDisabled={!patientInfo.info.data_id}>
							預約管理
						</Button>
					</Box>
					<Box>
						<Button sx={AsideButton} onClick={handleOpenControlledDrug} isDisabled={!patientInfo.info.data_id}>
							管制藥查詢
						</Button>
					</Box>
					<Box>
						<Button
							sx={AsideButton}
							data-url={"https://medcloud.nhi.gov.tw/imme0008/IMME0008S01.aspx"}
							onClick={e => handleOpenMedCloudWindow(e)}
							isDisabled={!patientInfo.info.data_id}>
							雲端藥歷查詢
						</Button>
					</Box>
					<Box position={"relative"}>
						{isChronicStatus && (
							<Box
								position={"absolute"}
								top={"2px"}
								left={`calc(4rem + 20px)`}
								color={"rsDanger.500"}
								zIndex={"10"}
								className='bell-swing'
								pointerEvents='none'>
								<HeroIcons name={"bell"} />
							</Box>
						)}
						<Button
							sx={AsideButton}
							// disabled={!isSequential}
							isDisabled={!patientInfo.info.data_id}
							opacity={isSequential ? "1" : "0.4"}
							onClick={() => {
								if (!isSequential) return;
								setRegisteredFlowStatus("SequentialPrescription");
							}}>
							<Text textColor={isChronicStatus ? "rsDanger.500" : "rsPrimary.500"}>連箋領藥</Text>
						</Button>
					</Box>
					<Box>
						<Button
							sx={AsideButton}
							isDisabled={!patientInfo.info.data_id}
							onClick={() => {
								setRegisteredFlowStatus("PrintProofAndReceipt");
							}}>
							證明及收據列印
						</Button>
					</Box>
				</VStack>
			</Flex>
		</>
	);
};
