import React, { useEffect, useRef, useState } from "react";
import { Box } from "@chakra-ui/react";
import { usePrescriptionInputRefs } from "contexts/TreatmentManagement/Prescription";
import { callDrugsForTreatmentService, postDrugsForTreatmentService } from "service/drugsForTreatmentService";
import moment from "moment";
import { usePrescriptionStore, PrescriptionProps, initialPrescription } from "store/TreatmentManagement";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AddRowButton } from "./FormComponents/AddRowButton";
import { InsertRowTooltip } from "./FormComponents/InsertRowTooltip";
import { PrescriptionRow } from "./FormComponents/PrescriptionRow";
import { HandleKeyDownAction } from "./FormComponents/HandleKeyDownAction";
import { callDrugFreqListsService } from "service/drugFreqListsService";
import { useQuery } from "react-query";
import { useUserStore } from "store";
import { useIsFormMaximizeStatus } from "contexts/TreatmentManagement/Prescription";

interface Drug {
	data_id: string;
	name_tw: string;
	drug_no: string;
	short_code: string;
}

interface DrugFreqListsProps {
	data_id: string;
	code: string;
	freq_code: string;
	ori_freq_code: string;
	directions: string;
	days: number;
	times: number;
	type: string;
}

export const FormBody: React.FC = () => {
	const { userInfo } = useUserStore();
	const { prescriptionInputRefs } = usePrescriptionInputRefs();
	const isComposingRef = useRef(false);
	const {
		prescriptionList,
		selectedIndex,
		isEditing,
		initialValue,
		inputValues,
		setSelectedIndex,
		setIsEditing,
		setInitialValue,
		updateCellValue,
		updatePrescription,
		setPrescriptionList,
		updateInputValues
	} = usePrescriptionStore();

	const containerRef = useRef<HTMLDivElement>(null);
	const boxRefs = useRef<Array<Array<HTMLDivElement | null>>>([]);

	const { data: drugFreqListsData, isSuccess: isDrugFreqListsSuccess } = useQuery(
		"drugFreqLists",
		() => callDrugFreqListsService(userInfo.token),
		{ enabled: !!userInfo.token, staleTime: Infinity }
	);

	const [drugFreqLists, setDrugFreqLists] = useState<DrugFreqListsProps[]>([]);

	useEffect(() => {
		if (isDrugFreqListsSuccess) {
			setDrugFreqLists(drugFreqListsData.lists);
		}
	}, [isDrugFreqListsSuccess]);

	const moveRow = (dragIndex: number, hoverIndex: number) => {
		const newPrescriptionList = [...prescriptionList];
		const [draggedRow] = newPrescriptionList.splice(dragIndex, 1);
		newPrescriptionList.splice(hoverIndex, 0, draggedRow);

		const newInputValues = [...inputValues];
		const [draggedValues] = newInputValues.splice(dragIndex, 1);
		newInputValues.splice(hoverIndex, 0, draggedValues);

		setPrescriptionList(newPrescriptionList);
		updateInputValues(newInputValues);
	};

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.focus();
		}
		boxRefs.current = prescriptionList.map(() => Array(10).fill(null));
	}, [prescriptionList]);

	const handleInsertPrescription = async (
		index: number,
		currentPrescriptionList: PrescriptionProps[],
		currentInputValues: { columns: string[] }[]
	) => {
		try {
			const newInputValues = { columns: Array(10).fill("") };
			let updatedPrescriptionList = [...currentPrescriptionList];
			updatedPrescriptionList.splice(index, 0, initialPrescription);

			let updatedInputValues = [...currentInputValues];
			updatedInputValues.splice(index, 0, newInputValues);

			setPrescriptionList(updatedPrescriptionList);
			updateInputValues(updatedInputValues);
			prescriptionInputRefs.current.splice(index, 0, Array(10).fill(null));

			return {
				updatedPrescriptionList,
				updatedInputValues
			};
		} catch (error) {
			console.error("Error inserting prescription:", error);
		}
	};

	const handleAddMultipleRows = async (index: number, count: number): Promise<number> => {
		let currentPrescriptionList = [...prescriptionList];
		let currentInputValues = [...inputValues];

		for (let i = 0; i < count; i++) {
			try {
				const result = await handleInsertPrescription(index + i, currentPrescriptionList, currentInputValues);
				if (result) {
					currentPrescriptionList = result.updatedPrescriptionList;
					currentInputValues = result.updatedInputValues;
				}
			} catch (error) {
				console.error("插入處方時發生錯誤:", error);
				// 可以在這裡添加額外的錯誤處理邏輯，例如顯示錯誤消息給用戶
			}
		}

		setPrescriptionList(currentPrescriptionList);
		updateInputValues(currentInputValues);

		return index + count;
	};

	const handleAddRow = async (index: number): Promise<number> => {
		return new Promise(resolve => {
			handleInsertPrescription(index, prescriptionList, inputValues).then(() => {
				resolve(index + 1);
			});
		});
	};

	// 監控 body 位置及高度
	const { isFormMaximizeStatus } = useIsFormMaximizeStatus();
	const [boxMetrics, setBoxMetrics] = useState({
		innerWidth: 0,
		innerHeight: 0,
		top: 0,
		left: 0
	});

	useEffect(() => {
		const updateBoxMetrics = () => {
			if (containerRef.current) {
				const rect = containerRef.current.getBoundingClientRect();
				// console.log('Bounding Rect:', rect);
				// console.log('Scroll Position:', window.scrollY, window.scrollX);
				setBoxMetrics({
					innerWidth: rect.width,
					innerHeight: rect.height,
					top: rect.top + window.scrollY,
					left: rect.left + window.scrollX
				});
			}
		};

		updateBoxMetrics();
		window.addEventListener("resize", updateBoxMetrics);
		window.addEventListener("scroll", updateBoxMetrics);

		return () => {
			window.removeEventListener("resize", updateBoxMetrics);
			window.removeEventListener("scroll", updateBoxMetrics);
		};
	}, [isFormMaximizeStatus]);

	return (
		<DndProvider backend={HTML5Backend}>
			<Box ref={containerRef} p={"4px 12px"}>
				{prescriptionList &&
					prescriptionList.map((item, rowIndex) => (
						<React.Fragment key={rowIndex}>
							<InsertRowTooltip
								index={rowIndex}
								onInsert={index => handleInsertPrescription(index, prescriptionList, inputValues)}
							/>
							<PrescriptionRow
								key={rowIndex}
								rowIndex={rowIndex}
								item={item}
								selectedIndex={selectedIndex}
								setSelectedIndex={setSelectedIndex}
								setIsEditing={setIsEditing}
								updateCellValue={updateCellValue}
								boxRefs={boxRefs}
								isEditing={isEditing}
								handleAddMultipleRows={handleAddMultipleRows}
								updateInputValues={updateInputValues}
								moveRow={moveRow}
								updatePrescription={updatePrescription}
								isComposingRef={isComposingRef}
								onAddRow={() => handleAddRow(prescriptionList.length)}
								setPrescriptionList={setPrescriptionList}
								drugFreqLists={drugFreqLists}
								boxMetrics={boxMetrics}
							/>
						</React.Fragment>
					))}
				<AddRowButton onAdd={() => handleAddRow(prescriptionList.length)} />
			</Box>
		</DndProvider>
	);
};
