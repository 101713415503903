import apiService from "./apiService";
// 寫入病歷及批價資訊
type DiagnosisProps = {
	data_id: string;
	ds?: string;
	doctor_orders?: string;
};

type DrugProps = {
	drug_in_use_uid: string; // 醫令資料id (必填)
	qty: number; // 使用量 (必填, 數字型態，整數5位，小數點2位)
	qty_in_day: number; // 日量 (必填, 數字型態，整數5位，小數點2位)
	freq: string; // 用法 (必填, 給藥頻率的資料 id)
	site?: string; // 部位code
	route?: string; // 途徑code (當健保碼長度為 10 碼時，此欄位為必填)
	dose_days?: number; // 給藥天數 (數字型態)
	total_qty: number; // 總量 (必填, 數字型態，整數7位，小數點2位)
	given_qty: number; // 給藥量 (必填, 數字型態，整數7位，小數點2位)
	add_days?: number; // 加開天數 (數字型態)
	add_given_qty?: number; // 加開給藥量 (數字型態，整數7位，小數點2位)
	memo?: string; // 備註
	status?: number; // 階段 (0：開立、5：已批、6：已執行、9：封存)
	doctor_order?: string; // 醫囑
	icd_code?: string; // 國際病名碼
	started_at?: string; // 開始時間 (西元日期時間型態YYYY-mm-dd HH:ii:ss)
	ended_at?: string; // 結束時間 (西元日期時間型態YYYY-mm-dd HH:ii:ss)
	op_idno?: string; // 執行人員/身分證號
	special_mark?: string[]; // M欄註記

	if_chronic?: string; // 是否慢性病處方，0：否、1：是
};

export type CashierProps = {
	acc_item_uid: string;
	cashier_type: number;
	price: number;
	discount?: number;
	final: number;
};

export type RefillableProps = {
	refill_times: number | null; // 可領藥次數，範圍為2到4
	seq: number | null; // 領藥順序
	ori_medical_record_uid?: string; // 原處方資料id，當seq不為1時必填
};

export type finalDataProps = {
	data_id?: string;
	if_formal_data?: string;
	medical_type?: number; // 病例類型
	treated_at: string; // 就醫日期時間 (必填)
	card_no?: string; // 就醫序號，固定長度四碼
	opd_uid: string; // 掛號資料id (必填)
	shift_uid: string; // 看診時段id，與掛號資料相同 (必填) 0：預約、10：候診/報到、20：保留、30：保留批價、40：保留報到、50：已診、60：已診批價、70：領藥、80：完成
	clinic_room_uid: string; // 診間的id，與掛號資料相同 (必填) 內容為自/g_type/reg_fee_identity取得的code
	patient_uid: string; // 病患資料id (必填)
	doctor_uid: string; // 看診醫師id (必填)

	seq?: string;
	declare_doctor_uid?: string; // 申報醫師id，沒指定資料就會存入看診醫師id，內容為Cloud HIS API 介接規格書 - 系統設定篇-2 | 56. 取得院內人員清單 的 data_id
	pharmacist_uid?: string; // 申報藥師，內容為Cloud HIS API 介接規格書 - 系統設定篇-2 | 56. 取得院內人員清單 的 data_id
	case_type?: string; // 案件分類，以健保身份就醫時，就必須最大長度2碼英數字
	medical_subject?: string; // 就醫科別 (必填)，內容為自/g_type/medical_type取得的code
	dose_days?: number; // 給藥天數，不同給藥途徑請填當次口服藥最大值，若無則填該次處方之最大值
	prescription_style?: number; // 調劑方式，0：自調、1：交付、2：藥師調劑
	cc?: string; // CCPE內容 (必填)
	note?: string; // 備註
	diagnosis: DiagnosisProps[]; // 診斷資料區段，陣列型態，陣列長度至少1，最大6
	drugs: DrugProps[]; // 醫令區段，陣列型態

	total: number; // 總應收
	discount: number; // 總折扣
	final: number; // 總實收
	cashier_status: number; // 收費執行階段
	cashier: CashierProps[]; // 診療相關費用，陣列型態

	ori_mt_type: string | undefined; // 原就醫類別，內容來自10. 取得病患就診基本資料內的mt_type
	mt_type: string; // 批價畫面的就醫類別，有可能被使用者更改過
	copayment: string; // 部分負擔代碼，固定長度3
	referral: string; // 轉診醫事機構代碼，固定長度10
	benefit_type: string; // 給付類別，固定長度1
	special_treatment: string; // 特殊治療項目代碼，固定長度2
	refillable: RefillableProps; // 慢性病連續處方箋區段
};

export const postMedicalRecordService = async (token: string, finalData: finalDataProps) => {
	return apiService<finalDataProps, any>({
		method: "POST",
		path: "/api/medical_record",
		token,
		data: finalData
	});
};
