// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-gradient {
	width: 100vw;
	height: 100vh;
	background: linear-gradient(334deg, #8fd6d2, #ced7aa, #c1e3a6);
	/* background-image: linear-gradient(
            45deg,
            rgba(194, 233, 221, 0.5) 1%,
            rgba(104, 119, 132, 0.5) 100%
        ),
        linear-gradient(-45deg, #494d71 0%, rgba(217, 230, 185, 0.5) 80%); */

	background-size: 500%;
	animation: gradient-animation 5s ease infinite;
}

@keyframes gradient-animation {
	0% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 100% 100%;
	}
	100% {
		background-position: 0% 0%;
	}
}
`, "",{"version":3,"sources":["webpack://./src/style/components/login.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,aAAa;CACb,8DAA8D;CAC9D;;;;;4EAK2E;;CAE3E,qBAAqB;CACrB,8CAA8C;AAC/C;;AAEA;CACC;EACC,0BAA0B;CAC3B;CACA;EACC,8BAA8B;CAC/B;CACA;EACC,0BAA0B;CAC3B;AACD","sourcesContent":[".login-gradient {\n\twidth: 100vw;\n\theight: 100vh;\n\tbackground: linear-gradient(334deg, #8fd6d2, #ced7aa, #c1e3a6);\n\t/* background-image: linear-gradient(\n            45deg,\n            rgba(194, 233, 221, 0.5) 1%,\n            rgba(104, 119, 132, 0.5) 100%\n        ),\n        linear-gradient(-45deg, #494d71 0%, rgba(217, 230, 185, 0.5) 80%); */\n\n\tbackground-size: 500%;\n\tanimation: gradient-animation 5s ease infinite;\n}\n\n@keyframes gradient-animation {\n\t0% {\n\t\tbackground-position: 0% 0%;\n\t}\n\t50% {\n\t\tbackground-position: 100% 100%;\n\t}\n\t100% {\n\t\tbackground-position: 0% 0%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
