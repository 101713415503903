/* Hooks */
import React, { useEffect, useState } from "react";
// import { momentLocalizer } from 'react-big-calendar';
import { Box, Flex } from "@chakra-ui/react";
/* Componetns */
import RegisteredFunctionBar from "../../components/Registration/RegisteredComponents/RegisteredFunctionBar";
import { RegisteredWaitingTable } from "components/Registration/RegisteredComponents/RegisteredWaitingTable";
import ReadVirtualHealthInsuranceCard from "components/Registration/RegisteredComponents/ReadVirtualHealthInsuranceCard";
import PatientManagementStructure from "components/Registration/RegisteredComponents/PatientManagementFloatingLayer/PatientManagement";
import Loading from "../../components/CustomComponents/Loading";
import ICCardRegisteredForm from "components/ICCardRegisteredComponents/ICCardRegisteredForm";
/* Status */
import { useLoadingStatus } from "../../contexts/LoadingStatusContext";
import RegisteredRoomStatus from "../../components/Registration/RegisteredComponents/RegisteredRoomStatus";
import { useShowEditStatus } from "../../contexts/ShowEditDndContext";
import { useRegisteredSearchResultStatus } from "../../contexts/RegisteredSearchResultContext";
import { useSidebarStatus } from "../../contexts/SidebarStatusContext";
// import { useOpenPatientManagementFormStatus } from '../contexts/SidebarHomePageOpenPatientManagementForm';
import { useShowPatientManagementFormStatus } from "contexts/ShowPatientManagementFormStatusContext";
import { useShowRegisteredFormStatus } from "contexts/ShowRegisteredFormStatusContext";
import { useShowICCardRegisteredFormStatus } from "contexts/ShowICCardRegisteredFormStatusContext";
/* Provider */
import { RoomStatusProvider } from "../../contexts/Registration/RoomStatusContext";
import { IsChronicProvider } from "contexts/Registration/IsChronicContext";
import { IsPrintProofOpenProvider } from "contexts/Registration/IsPrintProofOpenContext";
import { CombinedRegistrationProviders } from "contexts/Registration";

// import {VirtualCardAuthorizeComponents} from 'components/VirtualCardAuthorizeComponents';
import SearchPatientMedicalRecord from "components/Registration/RegisteredComponents/SearchPatientMedicalRecord";

const RegisteredPage: React.FC = () => {
	const { registeredSearchResultStatus } = useRegisteredSearchResultStatus();

	const { showRegisteredFormStatus } = useShowRegisteredFormStatus();
	const { showICCardRegisteredFormStatus } = useShowICCardRegisteredFormStatus();
	const { showPatientManagementFormStatus } = useShowPatientManagementFormStatus();
	const { showEditStatus } = useShowEditStatus();

	const [date, setDate] = useState(new Date());
	const [view, setView] = useState("month");
	useEffect(() => {}, []);
	const [openPatientManagementFormLocalStatus, setOpenPatientManagementFormLocalStatus] = useState(false);
	const handleClosePatientManagementForm = () => {
		setOpenPatientManagementFormLocalStatus(false);
	};
	const handleOpenPatientManagementForm = () => {
		setOpenPatientManagementFormLocalStatus(true);
	};

	const { loadingStatus } = useLoadingStatus();

	const [windowHeight, setWindowHeight] = useState(window.innerHeight);

	useEffect(() => {
		const handleResize = () => {
			setWindowHeight(window.innerHeight);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<>
			{loadingStatus && <Loading />}
			{/* <RoomStatusProvider>
                <IsChronicProvider>
                    <IsPrintProofOpenProvider> */}
			<CombinedRegistrationProviders>
				<Flex rowGap={"20px"} direction={"column"}>
					{/* 功能列 */}
					<RegisteredFunctionBar />
					<RegisteredWaitingTable />
				</Flex>
				{/* 候診表 */}

				{/* 虛擬健保卡讀卡浮層 */}
				<ReadVirtualHealthInsuranceCard />
				{/* <VirtualCardAuthorizeComponents /> */}
				{/* 病患資料主要功能浮層 */}
				<PatientManagementStructure />

				<SearchPatientMedicalRecord />
			</CombinedRegistrationProviders>
			{/* </IsPrintProofOpenProvider>
                </IsChronicProvider>
            </RoomStatusProvider> */}
		</>
	);
};

export default RegisteredPage;
