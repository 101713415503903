import axios from "axios";

type finalDataProps = {
	pDateTime: string;
	pPatientID: string;
	pPatientBirthDate: string;
	pDataWrite: string;
};
// Rs就醫診療資料寫入作業
export const RshisWriteTreatmentCodeService = async (finalData: finalDataProps) => {
	try {
		const response = await axios.post("http://localhost:23752/cshis/RshisWriteTreatmentCode", finalData, {
			headers: {
				Accept: "*/*",
				"Content-Type": "application/json"
			}
		});

		console.log(response.data, "Rs就醫診療資料寫入作業");
		return response.data;
	} catch (error: unknown) {
		if (error instanceof Error) {
			console.error("Error:", error.message);
		} else {
			console.error("An unknown error occurred");
		}
	}
};
