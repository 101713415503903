import axios from "axios";
import Swal from "sweetalert2";

export interface VHChisWriteMultiPrescriptSignReqProps {
    pDateTime: string;
    pPatientID: string;
    pPatientBirthDate : string;
    pDataWrite: string;
    iWriteCount: number;
}

export interface VHChisWriteMultiPrescriptSignResProps {
    result: string;
}   

export const postVHChisWriteMultiPrescriptSignService = async (VHChisWriteMultiPrescriptSignReqData: VHChisWriteMultiPrescriptSignReqProps): Promise<VHChisWriteMultiPrescriptSignResProps | null> => {
    try {
        const response = await axios.post('http://localhost:3033/VHC/VHChisWriteMultiPrescriptSign', VHChisWriteMultiPrescriptSignReqData, {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
            },
        });
        console.log(response, '回傳的寫醫令卡片')

        return response.data;
    } catch (error: unknown) {
        console.error('Error:', error instanceof Error ? error.message : 'An unknown error occurred');
        Swal.fire({
            icon: 'error',
            title: '寫IC_DATA資料庫發生問題',
            showConfirmButton: true,
        });
        return null;
    }
};
