import React, { createContext, useContext, useState, ReactNode } from "react";

interface IsSearchListsContextValue {
	isSearchListsStatus: boolean;
	setIsSearchListsStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsSearchListsContext = createContext<IsSearchListsContextValue | undefined>(undefined);

interface IsSearchListsProviderProps {
	children: ReactNode;
}

export const IsSearchListsProvider: React.FC<IsSearchListsProviderProps> = ({ children }) => {
	const [isSearchListsStatus, setIsSearchListsStatus] = useState(false);
	return (
		<IsSearchListsContext.Provider
			value={{
				isSearchListsStatus,
				setIsSearchListsStatus
			}}>
			{children}
		</IsSearchListsContext.Provider>
	);
};

export const useIsSearchListsStatus = (): IsSearchListsContextValue => {
	const context = useContext(IsSearchListsContext);

	if (!context) {
		throw new Error("useIsSearchListsStatus must be used within a isSearchListsStatusProvider");
	}

	return context;
};
