/* Hooks */
import React, { useEffect, useState, useRef } from "react";
import { Input, Button, Box, Flex, Text, FormLabel, FormControl, Checkbox } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
/* Components */
import moment from "moment";
import Swal from "sweetalert2";
import Loading from "../components/CustomComponents/Loading";
import "../style/components/login.css";
import { useUserStore } from "store";
import { useCookies } from "react-cookie";

/* API */
import { callLoginService } from "../service/loginService";

// Context
import { useLoadingStatus } from "../functions/useLoadingStatus";
import { callSystemFunctionTreeService, SystemFunctionTreeResult } from "../service/systemFunctionTreeService";

interface LoginPageProps {
	setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}
const LoginPage: React.FC<LoginPageProps> = ({ setIsLoggedIn }) => {
	const CryptoJS = require("crypto-js");
	const { userInfo, updateUserInfo } = useUserStore();
	const navigate = useNavigate();

	/* status */
	const { loadingStatus, setLoadingStatus } = useLoadingStatus();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [newHash, setNewHash] = useState("");
	const [request_time, setRequest_time] = useState("");
	const passwordRef = useRef<HTMLInputElement>(null);
	const [loginErrorStatus, setLoginErrorStatus] = useState(false);
	const [rememberMe, setRememberMe] = useState<boolean>(() => {
		const storedRememberMe = localStorage.getItem("rememberMe");
		return storedRememberMe ? JSON.parse(storedRememberMe) : false;
	});

	const [cookies, setCookie] = useCookies(["token", "X-Tenant"]);

	useEffect(() => {
		const subdomain = window.location.hostname.split(".")[0].toLowerCase();
		localStorage.setItem("X-Tenant", subdomain);
		setCookie("X-Tenant", subdomain, { path: "/" });
	}, []);

	const callSystemFunctionTreeAPI = async (token: string) => {
		if (token) {
			try {
				const result = await callSystemFunctionTreeService(token);
				if (result && result.result === "success") {
					console.log(result.items, "result.items");
					localStorage.setItem("navigation2", JSON.stringify(result.items));
					return true;
				}
				return false;
			} catch (error) {
				console.error("Error calling system function tree API:", error);
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500,
					showConfirmButton: false
				});
				return false;
			}
		}
		return false;
	};

	useEffect(() => {
		if (username === "") {
			const hisName = localStorage.getItem("username");
			if (hisName) {
				setUsername(hisName);
				if (passwordRef.current) {
					passwordRef.current.focus();
				}
			}
		}
		if (password === "") {
			const hisPassword = localStorage.getItem("password");
			if (hisPassword) {
				setPassword(hisPassword);
			}
		}
	}, []);

	const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		const hasFullWidthCharacters = /[^\x00-\x7F]+/.test(inputElement);

		if (hasFullWidthCharacters) {
			Swal.fire({
				icon: "error",
				title: "請使用半形輸入",
				showConfirmButton: true
			});
		} else {
			setUsername(inputElement);
		}
	};

	const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event.target.value;
		const hasFullWidthCharacters = /[^\x00-\x7F]+/.test(inputElement);

		if (hasFullWidthCharacters) {
			Swal.fire({
				icon: "error",
				title: "請使用半形輸入",
				showConfirmButton: true
			});
		} else {
			setPassword(inputElement);
		}
	};

	useEffect(() => {
		if (username !== "") {
			const keyTime = new Date();
			const momentTime = moment(keyTime).format("YYYY-MM-DD HH:mm:ss");
			const key = moment(momentTime).format("YYYYHHMMmmDDss");

			// setCurrentMomentTime(momentTime);
			setRequest_time(momentTime);

			const Message = username;
			const hash = CryptoJS.HmacSHA256(Message, key);
			const hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
			setNewHash(hashInBase64);
		}
	}, [username]);

	useEffect(() => {
		const currentUrl = window.location.href;

		const queryStartIndex = currentUrl.indexOf("?");

		const queryString = queryStartIndex !== -1 ? currentUrl.substring(queryStartIndex + 1) : "";

		const urlParams = new URLSearchParams(queryString);

		urlParams.forEach((value, key) => {
			localStorage.setItem(key, value);
		});
	}, []);

	const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
		const newKey = "Rs@375@100";
		const sha256Key = CryptoJS.SHA256(newKey);
		const aesKey = CryptoJS.enc.Hex.stringify(sha256Key);
		const md5Hash = CryptoJS.MD5(newKey).toString();
		const key = CryptoJS.enc.Hex.parse(aesKey);
		const iv = CryptoJS.enc.Hex.parse(md5Hash);

		const dataToEncrypt = password;
		const encrypted = CryptoJS.AES.encrypt(dataToEncrypt, key, {
			iv: iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7
		});

		const encryptedData = encrypted.toString();

		const newLogin = {
			code: username,
			request_time: request_time,
			password: encryptedData,
			mac: newHash
		};

		e.preventDefault();
		setLoginErrorStatus(false);
		setLoadingStatus(true);

		setTimeout(async () => {
			console.log("B", newLogin);
			if (username !== "" && password !== "") {
				setTimeout(() => {
					setLoadingStatus(false);
				}, 500);
				try {
					console.log("C");
					const result = await callLoginService(newLogin);
					console.log("callLoginService: ", result);
					if (result.result === "success") {
						console.log("D");
						const decodeToken = jwtDecode(result.token);
						console.log("decodeToken: ", decodeToken);

						setCookie("token", result.token, { path: "/" });
						// setCookie('X-Tenant', result.XTenant, { path: '/' });
						localStorage.setItem("hosp_info", JSON.stringify(result.hosp_info));
						localStorage.setItem("username", username);
						localStorage.setItem("rememberMe", rememberMe.toString());
						if (rememberMe) {
							localStorage.setItem("password", password);
						} else {
							localStorage.removeItem("password");
						}

						updateUserInfo({
							result: result.result,
							user_name: result.user_name,
							user_id: result.user_id,
							token: result.token,
							hosp_info: {
								customer_uid: result.hosp_info.customer_uid,
								hosp_id: result.hosp_info.hosp_id,
								nhid: result.hosp_info.nhid,
								news_online_url: result.hosp_info.news_online_url,
								hosp_name: result.hosp_info.hosp_name
							}
						});

						const treeLoaded = await callSystemFunctionTreeAPI(result.token);

						setIsLoggedIn(true);
						setLoadingStatus(false);

						Swal.fire({
							icon: "success",
							title: "登入成功",
							showConfirmButton: false,
							timer: 1500
						}).then(() => {
							navigate("/HomePage");
							localStorage.setItem("lastPathname", "HomePage");
						});
					} else {
						Swal.fire({
							icon: "error",
							title: "登入失敗(1)",
							text: "請檢查帳號或密碼是否有誤",
							showConfirmButton: true
						});
						return;
					}
				} catch (error) {
					console.error("Login error:", error);
					Swal.fire({
						icon: "error",
						title: "登入失敗(2)",
						text: "發生未知錯誤，請稍後再試",
						showConfirmButton: true
					});
				} finally {
					setLoadingStatus(false);
				}
			} else {
				setLoginErrorStatus(true);
				setLoadingStatus(false);
			}
		}, 200);
	};

	return (
		<Flex justify={"center"} align={"center"} pos={"relative"} px={"16px"} height={"100vh"} className='login-gradient'>
			{loadingStatus && <Loading />}
			<Flex
				direction={"column"}
				justify={"space-between"}
				maxW={{ base: "100%", lg: "800px" }}
				p={{ base: "30px", lg: "80px" }}
				bg={"rgba(255,255,255,0.5)"}
				boxShadow={"0 1px 3px rgba(0,0,0,0.1)"}
				filter={"auto"}
				backdropBlur={"20px"}>
				<Text fontSize={{ base: "xl", lg: "2xl" }} textAlign={"center"} textColor={"rsPrimary.700"}>
					耀聖資訊{" "}
					<Text as={"span"} textColor={"#555"}>
						{" "}
						醫療管理系統
					</Text>
				</Text>

				<Flex align={"center"} py={"16px"} opacity={"0.5"} mb={"32px"}>
					<Box h={"1px"} bg={"rsPrimary.500"} flex={"1 0 auto"}></Box>
					<Text px={"16px"} textColor={"rsPrimary.500"} flex={"0 0 auto"} fontSize={"sm"} textTransform={"uppercase"}>
						REALSUN
					</Text>
					<Box h={"1px"} bg={"rsPrimary.500"} flex={"1 0 auto"}></Box>
				</Flex>

				<form onSubmit={handleLogin}>
					<Flex minW={"325px"} direction={"column"} rowGap={"24px"}>
						<FormControl>
							<FormLabel htmlFor='account'>
								<Text fontSize={"sm"} fontWeight={"light"}>
									帳號 Email
								</Text>
							</FormLabel>
							<Input
								type='text'
								id='account'
								bg={"#fff"}
								value={username}
								onChange={handleChangeUsername}
								placeholder='Enter your Email'
								autoComplete='account'
							/>
						</FormControl>
						<FormControl>
							<FormLabel htmlFor='password'>
								<Text fontSize={"sm"} fontWeight={"light"}>
									{" "}
									密碼 Password
								</Text>
							</FormLabel>
							<Input
								ref={passwordRef}
								autoComplete='current-password'
								type='password'
								id='password'
								bg={"#fff"}
								value={password}
								onChange={handleChangePassword}
								placeholder='Enter your password'
							/>
						</FormControl>
						<Flex align='center'>
							<Checkbox isChecked={rememberMe} onChange={e => setRememberMe(e.target.checked)}>
								記住密碼
							</Checkbox>
						</Flex>
						<Box mt={"16px"}>
							<Button type='submit' className='w-full' colorScheme='rsPrimary'>
								登入 Sign in
							</Button>
						</Box>
					</Flex>
				</form>
			</Flex>
		</Flex>
	);
};

export default LoginPage;
