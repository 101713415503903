export const mainNavItemButton = {
	rounded: "0",
	color: "#f3f5e9",
	bg: "rsPrimary.400",
	_hover: {
		boxShadow: "inset 0px 0px 2px rgba(0, 0, 0, 0.6)",
		bg: "rsPrimary.800",
		color: "#fff"
	},
	borderRight: "1px solid #777"
};
