import { create } from "zustand";

interface Drug {
	id: string;
	name_tw: string;
	drug_no: string;
	controlled_level: string;
	cost: string;
	custom_name_en: string;
	custom_name_tw: string;
	disabled_date: string;
	drug_price: number | null;
	drug_type: string;
	dosage_form: string;
	dose: string;
	dosing_days: string;
	freq: string;
	data_id: string;
	max_dose: string;
	max_dose_day: string;
	route_of_administration: string;
	self_price: string;
	short_code: string;
	taking_time: string;
	unit: string;
	freq_custom_code?: string;
	freq_directions?: string;
}

interface PrescriptionState {
	prescriptionList: PrescriptionProps[];
	selectedIndex: { row: number; col: number };
	isEditing: boolean;
	initialValue: string;
	inputValues: { columns: string[] }[];
	setSelectedIndex: (index: { row: number; col: number }) => void;
	appendPrescription: (prescription: Partial<PrescriptionProps>, inputValues: string[]) => void;
	setIsEditing: (isEditing: boolean) => void;
	setInitialValue: (value: string) => void;
	addPrescription: () => void;
	updatePrescription: (index: number, prescription: Partial<PrescriptionProps>, inputValues: string[]) => void;
	updateInputValues: (values: { columns: string[] }[]) => void;
	updateCellValue: (rowIndex: number, colIndex: number, value: string) => void;
	setPrescriptionList: (list: PrescriptionProps[]) => void;
	resetPrescriptionList: () => void;
}

export type PrescriptionProps = {
	controlled_level: string;
	cost: string;
	data_id: string;
	disabled_date: string;
	dosage_form: string;
	dose: string;
	dose_day?: string;
	dosing_days: string;
	drug_no: string;
	drug_price: number | null;
	drug_type: string;
	freq: string;
	max_dose: string;
	max_dose_day: string;
	name_en: string;
	name_tw: string;
	route_of_administration: string;
	self_price: string;
	short_code: string;
	taking_time: string;
	unit: string;

	qty?: string;
	qty_in_day?: string;
	total_qty?: string;
	given_qty?: string;
	memo?: string;
	site?: string;
	special_mark?: string;
	freq_custom_code?: string;
	freq_directions?: string;

	if_chronic?: string | null; // 0: 非慢性處方簽, 1: 慢性處方簽
	// 用來判斷的
	hasAskedForChronicCase?: boolean;
};

export const initialPrescription: PrescriptionProps = {
	controlled_level: "",
	cost: "",
	data_id: "",
	disabled_date: "",
	dosage_form: "",
	dose: "",
	dose_day: "",
	dosing_days: "",
	drug_no: "",
	drug_price: null,
	drug_type: "",
	freq: "",
	max_dose: "",
	max_dose_day: "",
	name_en: "",
	name_tw: "",
	route_of_administration: "",
	self_price: "",
	short_code: "",
	taking_time: "",
	unit: "",

	qty_in_day: "",
	total_qty: "",
	given_qty: "",
	qty: "",
	memo: "",
	site: "",
	special_mark: "",
	freq_custom_code: "",
	freq_directions: "",
	if_chronic: null,
	hasAskedForChronicCase: false
};

export const usePrescriptionStore = create<PrescriptionState>(set => ({
	prescriptionList: [initialPrescription],
	selectedIndex: { row: 0, col: 0 },
	isEditing: false,
	initialValue: "",
	inputValues: [{ columns: Array(10).fill("") }],
	setSelectedIndex: index => set({ selectedIndex: index }),
	setIsEditing: isEditing => set({ isEditing }),
	setInitialValue: value => set({ initialValue: value }),
	addPrescription: () =>
		set(state => ({
			prescriptionList: [...state.prescriptionList, { ...initialPrescription }],
			inputValues: [...state.inputValues, { columns: Array(10).fill("") }]
		})),
	updatePrescription: (index, prescription, inputVals) =>
		set(state => {
			const newPrescriptionList = [...state.prescriptionList];
			newPrescriptionList[index] = {
				...newPrescriptionList[index],
				...prescription
			};

			const newInputValues = [...state.inputValues];
			newInputValues[index] = { columns: inputVals };

			return {
				prescriptionList: newPrescriptionList,
				inputValues: newInputValues
			};
		}),
	updateInputValues: values => set({ inputValues: values }),
	updateCellValue: (rowIndex, colIndex, value) =>
		set(state => {
			const newInputValues = [...state.inputValues];
			newInputValues[rowIndex].columns[colIndex] = value;
			return { inputValues: newInputValues };
		}),
	appendPrescription: (prescription, inputVals) =>
		set(state => {
			const emptyIndex = state.prescriptionList.findIndex(p => !p.data_id);
			if (emptyIndex !== -1) {
				const newPrescriptionList = [...state.prescriptionList];
				newPrescriptionList[emptyIndex] = {
					...initialPrescription,
					...prescription
				};
				const newInputValues = [...state.inputValues];
				newInputValues[emptyIndex] = { columns: inputVals };
				return {
					prescriptionList: newPrescriptionList,
					inputValues: newInputValues
				};
			} else {
				return {
					prescriptionList: [...state.prescriptionList, { ...initialPrescription, ...prescription }],
					inputValues: [...state.inputValues, { columns: inputVals }]
				};
			}
		}),
	setPrescriptionList: list => set({ prescriptionList: list }),
	resetPrescriptionList: () =>
		set({
			prescriptionList: [initialPrescription],
			selectedIndex: { row: 0, col: 0 },
			isEditing: false,
			initialValue: "",
			inputValues: [{ columns: Array(10).fill("") }]
		})
}));
