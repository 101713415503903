import React, { createContext, useContext, useState, ReactNode } from "react";

interface OPDTableHeightContextValue {
	OPDTableHeightStatus: number;
	setOPDTableHeightStatus: React.Dispatch<React.SetStateAction<number>>;
}

const OPDTableHeightContext = createContext<OPDTableHeightContextValue | undefined>(undefined);

interface OPDTableHeightStatusProps {
	children: ReactNode;
}

export const OPDTableHeightStatusProvider: React.FC<OPDTableHeightStatusProps> = ({ children }) => {
	const [OPDTableHeightStatus, setOPDTableHeightStatus] = useState(0);

	return (
		<OPDTableHeightContext.Provider
			value={{
				OPDTableHeightStatus,
				setOPDTableHeightStatus
			}}>
			{children}
		</OPDTableHeightContext.Provider>
	);
};

export const useOPDTableHeightStatus = (): OPDTableHeightContextValue => {
	const context = useContext(OPDTableHeightContext);

	if (!context) {
		throw new Error("useOPDTableHeightStatus must be used within a OPDTableHeightStatusProvider");
	}

	return context;
};
