import React, { useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';

type ShowCasesOutline = {
    title: string;
    num: number;
};
export const ShowCasesOutline: React.FC<ShowCasesOutline> = ({
    title,
    num,
}) => {
    const [color, setColor] = useState('');

    useEffect(() => {
        if (num === 0) {
            setColor('#646B48');
        } else {
            setColor('#C26326');
        }
    }, [num]);

    return (
        <Flex
            textColor={color}
            gap={5}
            rounded={'lg'}
            bg={'transparent'}
            p={'6px 16px'}
            boxShadow={'inset 4px 2px 2px rgba(0,0,0,0.25)'}
            border={` 2px solid ${color}`}
        >
            <Text>{title}</Text>
            <Text fontWeight={'semibold'}>{num} </Text>
        </Flex>
    );
};
