import { ListItem, Flex, Box, Text, Input } from "@chakra-ui/react";

import {
	useVitalSignQuery,
	vitalSignSettingListsProps
} from "../../../../../components/WaitingListTable/Components/utils/useVitalSignQuery";
import { useEffect, useState } from "react";
import { useTreatmentInfoStore, VitalSignProps } from "store/TreatmentManagement";
import { postVitalSignSetValueService, vitalSignSetValueProps } from "service/vitalSignSetValueService";
import { VitalSignRules, VitalSignRulesType, getBackgroundColor } from "functions/VitalSignRules";
import Swal from "sweetalert2";
import { useUserStore } from "store";
export const Sign = ({ item, index }: { item: vitalSignSettingListsProps; index: number }) => {
	// const token = localStorage.getItem('token');
	const { userInfo } = useUserStore();
	const { lists, isLoading, error } = useVitalSignQuery();
	const { treatmentInfo } = useTreatmentInfoStore();
	const [value, setValue] = useState<string>("");
	const [sign, setSign] = useState<VitalSignProps>();

	const [unit, setUnit] = useState();
	useEffect(() => {
		if (treatmentInfo.vital_sign) {
			const filteredItem = treatmentInfo.vital_sign.find((subItem: VitalSignProps) => subItem.vital_sign_uid === item.id);
			setSign(filteredItem);
			setValue(filteredItem?.value ?? "");
		}
	}, [lists, item]);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value);
	};

	const handleSave = async () => {
		if (sign && sign.data_id && sign.data_id !== "") {
			const finalData: vitalSignSetValueProps = {
				data_id: sign.data_id,
				opd_uid: treatmentInfo.id,
				vital_sign_uid: sign.vital_sign_uid,
				value: value
			};
			postVitalSignSetValueAPI(finalData);
		} else {
			const finalData: vitalSignSetValueProps = {
				opd_uid: treatmentInfo.id,
				vital_sign_uid: item.id,
				value: value
			};
			postVitalSignSetValueAPI(finalData);
		}
	};
	const postVitalSignSetValueAPI = async (finalData: vitalSignSetValueProps) => {
		try {
			const result = await postVitalSignSetValueService(userInfo.token!, finalData);
			if (result) {
				console.log("成功");
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "基礎體徵數據保存失敗",
				text: "請檢查網絡連接或稍後再試",
				confirmButtonText: "確定"
			});
		}
	};
	const handleBlur = () => {
		handleSave();
	};
	const comparisonResult = VitalSignRules(value, item.name);
	return (
		<ListItem key={index}>
			<Flex align={"center"} gap={"8px"} _hover={{ bg: "gray.50" }}>
				<Box data-name='bloodPressure' opacity={0.5} _hover={{ opacity: 1, cursor: "grab" }} fontSize={"16px"}>
					&#x283F;
				</Box>
				<Text flex={"0 0 auto"}>{item.name}</Text>
				<Input
					flex={"0 1 60px"}
					bg={getBackgroundColor(comparisonResult)}
					onBlur={handleBlur}
					h={"28px"}
					px={"4px"}
					textAlign={"right"}
					maxLength={3}
					name='sp'
					onChange={handleChange}
					value={value}></Input>
				<Box>{item.unit}</Box>
			</Flex>
		</ListItem>
	);
};
