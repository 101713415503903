import React, { useEffect, useState } from "react";
import { useSidebarStatus } from "contexts/SidebarStatusContext";
import PreferentialStatusTable from "../../components/DataSettings/PreferentialStatusComponents/PreferentialStatusTable";
import CloseIcon from "components/CustomComponents/CloseIcon";
const PreferentialStatusPage: React.FC = () => {
	const { setSidebarStatus } = useSidebarStatus();

	const [bgOpenStatus, setBgOpenStatue] = useState(false);
	const handleOpen = () => {
		setBgOpenStatue(true);
	};
	const handleClose = () => {
		setBgOpenStatue(false);
	};
	return (
		<>
			<PreferentialStatusTable />
		</>
	);
};

export default PreferentialStatusPage;
