import { useState, useEffect } from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import HomePageNewOnlineIcon from "../../images/icon/HomePageNewsOnlineIcon.png";
import { useUserStore } from "../../store/useUserStore";

const HomeStatusBoard = () => {
	const [newsOnlineUrl, setNewsOnlineUrl] = useState("");
	const { userInfo } = useUserStore();

	useEffect(() => {
		if (userInfo && userInfo.hosp_info) {
			setNewsOnlineUrl(userInfo.hosp_info.news_online_url);
			// console.log('newsOnlineUrl', userInfo.hosp_info.news_online_url);
		} else {
			console.log("hosp_info is not available");
		}
		// console.log('userInfo:', userInfo);
	}, [userInfo]);

	return (
		<Flex>
			<Box w='100%' minH={`calc(${window.innerHeight}px - 130px)`} bg='#fff' rounded='xl' p='8px 16px'>
				<iframe title='realsun' src={newsOnlineUrl} width='100%' height='100%' />
			</Box>
			<Box>
				<Image src={HomePageNewOnlineIcon} w='77px' />
			</Box>
		</Flex>
	);
};

export default HomeStatusBoard;
