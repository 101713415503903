import React, { useState, useEffect } from 'react';
import { Center, Radio, RadioGroup, Text, Flex, Box } from '@chakra-ui/react';
import { useICCardUpdateListTabIndexStore } from '../../../store/ICCardUpdate/useICCardUpdateListTabIndexStore';
import { useICCardUpdateShowStatusStore } from '../../../store/ICCardUpdate/useICCardUpdateShowStatusStore';
export const UploadStatus = () => {
    const { tabIndex } = useICCardUpdateListTabIndexStore();
    const { showStatus, updateShowStatus } = useICCardUpdateShowStatusStore();

    const handleUploadStatusChange = (nextValue: string) => {
        updateShowStatus(nextValue);
    };

    useEffect(() => {
        console.log('上傳狀態:', showStatus);
    }, [showStatus]);

    return (
        <Flex gap={'8px'} align={'center'} ml={'16px'} hidden={tabIndex !== 0}>
            <Text>顯示資料</Text>
            <Flex
                p={'8px 16px'}
                bg={'gray.50'}
                rounded={'lg'}
                flexWrap={'wrap'}
            >
                <RadioGroup
                    onChange={handleUploadStatusChange}
                    value={showStatus}
                >
                    <Center gap={'16px'} w={'100%'}>
                        <Radio value="0">未傳</Radio>
                        <Radio value="1">已上傳</Radio>
                        <Radio value="">全部</Radio>
                    </Center>
                </RadioGroup>
            </Flex>
        </Flex>
    );
};
