import { Icon, Box } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import DragImg from "../../images/icon/dragImg.png";
const DragIcon = ({ onClick, disabled }: any) => {
	return (
		<Box
			display='flex'
			alignItems='center'
			justifyContent='center'
			_hover={!disabled ? { color: "green.500", transform: "translateY(-1px)" } : {}}
			cursor={!disabled ? "pointer" : "not-allowed"}
			onClick={!disabled ? onClick : undefined}>
			<div className=''>
				<svg xmlns='http://www.w3.org/2000/svg' width='20' height='8' viewBox='0 0 29 11' fill='none'>
					<path d='M27.3733 1.68103H2' stroke='#708368' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
					<path d='M27.3733 9.36206H2' stroke='#708368' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
				</svg>
			</div>
		</Box>
	);
};

export default DragIcon;
