import { create } from "zustand";

interface PricingState {
	costPrice: string;
	insurancePrice: string;
	selfFundedPrice: string;
	projectRate: string;
	updateCostPrice: (price: string) => void;
	updateInsurancePrice: (price: string) => void;
	updateSelfFundedPrice: (price: string) => void;
	updateProjectRate: (rate: string) => void;
	resetPricing: () => void;
}

export const usePricingStore = create<PricingState>(set => ({
	costPrice: "",
	insurancePrice: "",
	selfFundedPrice: "",
	projectRate: "",
	updateCostPrice: price => set({ costPrice: price }),
	updateInsurancePrice: price => set({ insurancePrice: price }),
	updateSelfFundedPrice: price => set({ selfFundedPrice: price }),
	updateProjectRate: rate => set({ projectRate: rate }),
	resetPricing: () => set({ costPrice: "", insurancePrice: "", selfFundedPrice: "", projectRate: "" })
}));
