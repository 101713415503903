import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Center, Flex, Image, Spinner, Text, Tooltip } from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import Clock from "components/CustomComponents/Clock";
import { useDraiaiLoadingStatus } from "contexts/TreatmentManagement/DraiaiLoadingStatusContext";
import { ADDateToROCString } from "functions/ADDateToROCString";
import { calculateAge } from "functions/calculateAge";
import { useDraiaiCorpGetTokenService } from "service/draiaiCorpGetTokenServiceHelper";
import { useTreatmentInfoStore } from "store/TreatmentManagement";

const RoomInformation: React.FC = () => {
	// 雲端藥歷及管治藥查詢
	const { treatmentInfo } = useTreatmentInfoStore();
	const navigate = useNavigate();

	const { postDraiaiCorpGetTokenAPI } = useDraiaiCorpGetTokenService();
	const { isDraiaiLoading } = useDraiaiLoadingStatus();

	if (treatmentInfo && treatmentInfo.id === "") {
		navigate("/OPDPage");
	}

	useEffect(() => {
		const age = treatmentInfo.birth_date ? calculateAge(treatmentInfo.birth_date) : "";
		console.log(age);
	}, [treatmentInfo]);

	const handleDoctorAi = async () => {
		postDraiaiCorpGetTokenAPI();
	};

	return (
		<>
			<Flex justify={"space-between"} align={"center"} gap={"8px"} w={"100%"}>
				<Flex align={"center"} gap={"16px"}>
					<Text fontWeight={"semibold"} fontSize={"20px"}>
						{treatmentInfo.medical_record_number}
					</Text>

					<Flex align={"center"} fontWeight={"semibold"} gap={"16px"}>
						<Box
							bg={treatmentInfo.sex === "M" ? "#79A09F" : treatmentInfo.sex === "F" ? "#D28989" : "red.500"}
							h={"40px"}
							px={"8px"}
							rounded={"4px"}>
							<Text fontSize={"30px"} textColor={"#fff"} lineHeight={"40px"}>
								{treatmentInfo.patient_name}
							</Text>
						</Box>

						<Tooltip
							label={treatmentInfo.birth_date ? ADDateToROCString(treatmentInfo.birth_date) : ""}
							bg={"#45533F"}
							hasArrow>
							<Text fontSize={"30px"} lineHeight={"40px"}>
								{treatmentInfo.birth_date ? calculateAge(treatmentInfo.birth_date) : ""}
							</Text>
						</Tooltip>
						<Flex align={"center"} gap={"8px"}>
							<HeroIcons name='key-circle-green'></HeroIcons>
							<Text fontSize={"20px"} fontWeight={"semibold"}>
								{treatmentInfo.id_no}
							</Text>
						</Flex>
						<Flex align={"center"} gap={"8px"}>
							<HeroIcons name='id-card-circle-green'></HeroIcons>
							<Text fontSize={"20px"} fontWeight={"semibold"}>
								{treatmentInfo.nhi_type_name}
							</Text>
						</Flex>
					</Flex>

					<Text
						border={"1px solid #45533F"}
						p={"0px 16px"}
						rounded={"md"}
						lineHeight={"40px"}
						fontSize={"20px"}
						fontWeight={"semibold"}>
						{treatmentInfo.card_no}
					</Text>
					{/* <Button
                        bg={'#45533F'}
                        textColor={'#fff'}
                        boxShadow={
                            ' 3px -3px 2px 0px rgba(0, 0, 0, 0.50) inset'
                        }
                        _hover={{
                            bg: '#2A3327',
                            boxShadow: '-2px 2px 2px #000 inset',
                        }}
                        hidden={true}
                    >
                        IC 讀卡
                    </Button> */}
				</Flex>
				{/* <Box hidden={true}>
                    <Flex
                        align={'center'}
                        gap={'8px'}
                        rounded="10px"
                        bg="#646B48"
                        boxShadow={' 2px 2px 2px 0px rgba(0, 0, 0, 0.25) inset'}
                        p={'6px 16px'}
                    >
                        <Text
                            textColor={'#fff'}
                            fontWeight={'semibold'}
                            flexShrink={0}
                        >
                            候診人數
                        </Text>
                        <Box
                            p={'2px 16px'}
                            bg={'#fff'}
                            textColor={'#000'}
                            fontWeight={'semibold'}
                            rounded={'4px'}
                            flexShrink={0}
                        >
                            23
                        </Box>
                    </Flex>
                </Box> */}
				{/* {process.env.REACT_APP_ENV === 'development' && (
                    <Flex gap={'8px'}>
                        <Text>案件分類{treatmentInfo.case_type}</Text>
                        <Text>就醫類別{treatmentInfo.mt_type}</Text>
                    </Flex>
                )} */}
				<Flex align={"center"} gap={"8px"}>
					<Box textColor={"rsPrimary.500"} rounded={"4px"} p={"4px 16px"} textAlign={"center"}>
						<Clock />
					</Box>

					<Tooltip label='使用 Dr.AI' bg='#45533F' hasArrow>
						<Box
							h={"40px"}
							w={"100px"}
							bg={"blue.50"}
							py={2}
							px={4}
							rounded={"md"}
							boxShadow={"md"}
							_hover={{ opacity: 1, cursor: "pointer" }}
							position={"relative"}
							onClick={isDraiaiLoading ? () => {} : handleDoctorAi}>
							<Box opacity={isDraiaiLoading ? 0.2 : 1}>
								<Image src='https://ccpe.draiai.com/logo.svg'></Image>
							</Box>
							<Center
								position={"absolute"}
								top={"50%"}
								left={"50%"}
								transform={"translate(-50%, -50%)"}
								hidden={!isDraiaiLoading}>
								<Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='lg' />
							</Center>
						</Box>
					</Tooltip>
				</Flex>
			</Flex>
		</>
	);
};

export default RoomInformation;
