import { useEffect } from "react";
import { useLoadingStatus } from "../../contexts/LoadingStatusContext";
import Loading from "../../components/CustomComponents/Loading";
import { ConsultingRoomWaitingListTable } from "components/TreatmentManagement/ConsultingRoomWaitingListTable";
import { IsChronicProvider } from "contexts/Registration/IsChronicContext";

export default function OPDPage() {
	const { loadingStatus } = useLoadingStatus();

	return (
		<>
			<IsChronicProvider>
				{loadingStatus && <Loading></Loading>}
				<ConsultingRoomWaitingListTable />
			</IsChronicProvider>
		</>
	);
}
