import axios from "axios";
import Swal from "sweetalert2";
import { rsAPIResponseFormat } from "utils/rsAPIResponseFormat";

type V1000hisGetTreatNumNoICCard2ServiceProps = {
	sHospid: string; // 機構代碼
	sPatientId: string; // 保險對象身分證號X(10)
};

export type V1000hisGetTreatNumNoICCard2ResultProps = {
	TreatDT: string;
	HospId: string;
	TreatNum: string;
};
// 傳入JSON範例:{
//     "sHospid":"機構代碼",
//     "sPatientId":"保險對象身分證號X(10)",
//     "sTreatDT":"就診日期時間 1110622092407"
// }
// Rs讀取卡片狀態
export const postV1000hisGetTreatNumNoICCard2Service = async (data: V1000hisGetTreatNumNoICCard2ServiceProps) => {
	try {
		const response = await axios.post("http://localhost:23752/cshis/V1000hisGetTreatNumNoICCard2", data, {
			headers: {
				Accept: "*/*",
				"Content-Type": "application/json"
			}
		});

		console.log("Rs外籍取得就醫識別碼API", response);

		const formattedResponse = await rsAPIResponseFormat(response);
		return formattedResponse;
	} catch (error: unknown) {
		if (error instanceof Error) {
			console.error("Error:", error.message);
			Swal.fire({
				icon: "error",
				title: "API 整理失敗",
				showConfirmButton: true
			});
		} else {
			console.error("An unknown error occurred");
		}
	}
};
