import React, { useEffect, useState } from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import zhTW from "date-fns/locale/zh-TW";
import { useWeekRange } from "contexts/WeekRangeContext";
import moment from "moment";

import { Text, Flex, Box } from "@chakra-ui/react";
import "../../../style/components/datePickerCustomForWorkingSchedule.css";

interface DatePickerProps extends ReactDatePickerProps {
	selectedDate: Date | null;
	handleChangeDate: (date: Date | null) => void;
}
interface WeekRangeProps {
	startOfWeek: Date;
	endOfWeek: Date;
}
const WorkingScheduleDatePicker = () =>
	//     {
	//     selectedDate,
	//     handleChangeDate,
	//     ...props
	// }: DatePickerProps
	{
		const { weekRange, setWeekRange, selectedDate, setSelectedDate } = useWeekRange();
		const [currentMonth, setCurrentMonth] = useState<string>(moment(selectedDate)?.format("MM") || moment(new Date()).format("MM"));

		// 檢查是否為這個月
		const isDateInCurrentMonth = (date: Date) => {
			return moment(date).format("MM") === currentMonth;
		};
		const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
		const thisYear = new Date().getFullYear();
		const startYear = thisYear - 100;
		const endYear = thisYear + 20;
		const isDateInSameWeek = (date: Date, selectedDate: Date | null) => {
			if (!selectedDate) {
				return false;
			}

			const startOfWeek = moment(selectedDate).startOf("isoWeek");
			// console.log(startOfWeek, '先看startOf');
			const endOfWeek = moment(selectedDate).endOf("isoWeek");
			return moment(date).isBetween(startOfWeek, endOfWeek, undefined, "[]"); // '[]' 表示包含起始和結束日期
		};
		/* picker '<' and '>' change date */
		const handleChangeDateByPicker = (date: Date | null) => {
			handleChangeDate(date);
		};
		const handleChangeDate = (date: Date | null) => {
			if (date !== null) {
				setSelectedDate(date); // 确保 date 不为 null
			}
			// 如果需要，您可以在这里处理 date 为 null 的情况
		};

		const handleChangeYear = (e: React.ChangeEvent<HTMLSelectElement>) => {
			const selectedYear = e.target.value;
			setCurrentYear(parseInt(selectedYear));
			// console.log(selectedYear, 'selectYear');
		};
		/* when currentYear change update selectedDate */
		useEffect(() => {
			if (selectedDate) {
				const newDate = new Date(selectedDate);
				newDate.setFullYear(currentYear);
				handleChangeDate(newDate);
			}
		}, [currentYear]);
		/* when currentMonth change update selectedDate */
		useEffect(() => {
			const selectedYear = moment(selectedDate).format("YYYY");
			const IntYear = parseInt(selectedYear);
			if (IntYear) {
				setCurrentYear(IntYear);
			}
			// console.log('selectDate', selectedDate, selectedYear);
		}, [selectedDate]);
		/* view the month  */
		useEffect(() => {
			setCurrentMonth(moment(selectedDate)?.format("MM") || moment(new Date()).format("MM"));
		}, [selectedDate]);
		const getWeekStartAndEnd = (date: Date): WeekRangeProps[] => {
			const startOfWeek = new Date(date);
			startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Set to Sunday
			const endOfWeek = new Date(startOfWeek);
			endOfWeek.setDate(endOfWeek.getDate() + 6); // Set to Saturday

			// Now return an array with a single WeekRangeProps object
			return [{ startOfWeek, endOfWeek }];
		};
		useEffect(() => {
			if (selectedDate !== null) {
				setWeekRange(getWeekStartAndEnd(selectedDate));
			}
			// You may want to handle the case when `selectedDate` is null
		}, [selectedDate]);

		return (
			<div className='datePickerCustomForWorkingSchedule'>
				<ReactDatePicker
					selected={selectedDate}
					onChange={handleChangeDate}
					inline
					// showWeekPicker
					locale={zhTW}
					// showWeekPicker
					// 一開始為週日
					calendarStartDay={0}
					todayButton='回到今天'
					renderCustomHeader={({ decreaseMonth, increaseMonth }) => {
						return (
							<Flex align={"center"} justify={"space-between"} px={"8px"} pb={"8px"}>
								{/* 選擇年份 */}
								<select
									onChange={handleChangeYear}
									className='text-[#708368] bg-[#00000000] text-[18px] font-semibold'
									value={currentYear}>
									{Array.from({ length: endYear - startYear + 1 }, (_, index) => {
										const year = startYear + index;
										return (
											<option key={index} value={year}>
												民國 {year > 1911 ? year - 1911 : year} 年
											</option>
										);
									})}
								</select>

								{/* 切換月份 */}
								<Flex align={"center"} gap={3}>
									{/* 左箭頭 */}
									<Box
										onClick={() => {
											if (selectedDate) {
												const newDate = new Date(selectedDate);
												newDate.setMonth(newDate.getMonth() - 1);
												handleChangeDateByPicker(newDate);
												decreaseMonth();
											}
										}}
										_hover={{ cursor: "pointer" }}>
										<svg xmlns='http://www.w3.org/2000/svg' width='8' height='14' viewBox='0 0 8 14' fill='none'>
											<path
												d='M7 1L1 7L7 13'
												stroke='#708368'
												strokeWidth='2'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</Box>

									{/* 顯示月份 */}
									<Text fontWeight={"semibold"} textColor={"rsPrimary.700"} fontSize={"16px"}>
										{currentMonth}月
									</Text>

									{/* 右箭頭 */}
									<Box
										onClick={() => {
											if (selectedDate) {
												const newDate = new Date(selectedDate);
												newDate.setMonth(newDate.getMonth() + 1);
												handleChangeDateByPicker(newDate);
												increaseMonth();
											}
										}}
										_hover={{ cursor: "pointer" }}>
										<svg xmlns='http://www.w3.org/2000/svg' width='8' height='14' viewBox='0 0 8 14' fill='none'>
											<path
												d='M1 13L7 7L1 1'
												stroke='#708368'
												strokeWidth='2'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</Box>
								</Flex>
							</Flex>
						);
					}}
					dayClassName={(date: Date) => {
						// 如果日期在選中日期的同一週，則返回一個用於變色的類名
						if (selectedDate && moment(date).isSame(selectedDate, "week")) {
							return "bg-[#E9E5DA] rounded-full";
						}
						// 如果日期不屬於當前月，則返回透明度變化的類名
						if (!isDateInCurrentMonth(date)) {
							return "opacity-40";
						}
						return "";
					}}
					// {...props}
				/>
			</div>
		);
	};

export default WorkingScheduleDatePicker;
