import React, { createContext, useContext, useState, ReactNode } from 'react';

interface IsAuthorizeStatusContextValue {
    isAuthorizeStatus: boolean;
    setIsAuthorizeStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsAuthorizeStatusContext = createContext<
    IsAuthorizeStatusContextValue | undefined
>(undefined);

interface IsAuthorizeStatusProviderProps {
    children: ReactNode;
}

export const IsAuthorizeStatusProvider: React.FC<IsAuthorizeStatusProviderProps> = ({
    children,
}) => {
    const [isAuthorizeStatus, setIsAuthorizeStatus] = useState(false);
    return (
        <IsAuthorizeStatusContext.Provider
            value={{
                isAuthorizeStatus,
                setIsAuthorizeStatus,
            }}
        >
            {children}
        </IsAuthorizeStatusContext.Provider>
    );
};

export const useIsAuthorizeStatus = (): IsAuthorizeStatusContextValue => {
    const context = useContext(IsAuthorizeStatusContext);

    if (!context) {
        throw new Error(
            'useIsAuthorizeStatus must be used within a IsAuthorizeStatusProvider'
        );
    }

    return context;
};
