import { ADDateToROCStringyyyMMddHHmmssNoDot } from 'functions/ADDateToROCStringyyyMMddHHmmssNoDot';
import { ADDateToROCStringNoDot } from 'functions/ADDateToROCStringNoDot';
import { TreatmentInfoProps } from 'store/TreatmentManagement/useTreatmentInfoStore';
import { ICCardUpdateDiagnosisProps } from 'store/TreatmentManagement/ICCardUpdate/useICCardUpdateStore';
import { SeqNumber256N1Props } from 'functions/hisGetSeqNumber256N1';
import { RegisterBasicProps } from 'functions/hisGetRegisterBasic';
import { hisGetTreatNumNoICCardResProps } from 'functions/hisGetTreatNumNoICCard';
import { VHChisGetSeqNumber256N1ResultProps } from 'service/VHIC/VHChisGetSeqNumber256N1Format';
import { VHChisGetRegisterBasicJsonResProps } from 'service/VHIC/VHChisGetRegisterBasicJsonService';
type ICCardWritingProps = {
    pDateTime: string; //8-3.就診日期時間 yyyMMddHHmmss
    pPatientID: string; //傳入之民眾「身分證號」(HC基3)，長度11bytes(含 null char)
    pPatientBirthDate: string; //傳入之民眾「出生日期」(HC 基 4)，長度 8 bytes(含 null char)。
    pDataWrite: string;
};
export const hisWriteTreatmentCodeLogic = (
    treatmentInfo: TreatmentInfoProps,
    dxList: ICCardUpdateDiagnosisProps[],
    seqNumber256N1Result?: SeqNumber256N1Props | undefined,
    basicResult?: RegisterBasicProps | undefined,
    hisGetTreatNumNoICCardResult?: hisGetTreatNumNoICCardResProps | undefined,
    VHChisGetSeqNumber256N1Result?: VHChisGetSeqNumber256N1ResultProps | undefined,
    VHChisGetRegisterBasicJsonResult?: VHChisGetRegisterBasicJsonResProps | undefined
): Promise<ICCardWritingProps> => {
    let NotesOnReplenishingCards = '1';
    const pPatientBirthDate = VHChisGetRegisterBasicJsonResult?.birthDate || 
                            basicResult?.birthDate || 
                            (treatmentInfo.birth_date ? ADDateToROCStringNoDot(new Date(treatmentInfo.birth_date)) : 
                            ''); // 出生日期
    const pDateTime =  VHChisGetSeqNumber256N1Result?.visitDateTime || treatmentInfo.medical_record?.mb1.treated_at || seqNumber256N1Result?.pBuffer?.visitDateTime || hisGetTreatNumNoICCardResult?.pBuffer?.computerDateTime || '';
    const pPatientID = VHChisGetRegisterBasicJsonResult?.ROCID || treatmentInfo.id_no || basicResult?.idOrDocumentNumber || hisGetTreatNumNoICCardResult?.pBuffer?.medicalTreatmentIdentificationCode || '';
    const replenishingCardNote = NotesOnReplenishingCards.padEnd(1, ' ');

    const dxFormatted = [
        dxList[0]
            ? `[${dxList[0].icd_code}]`.padEnd(7, ' ')
            : '[ ]'.padEnd(7, ' '), // 主要診斷碼 (3-9)
        dxList[1]
            ? `[${dxList[1].icd_code}]`.padEnd(7, ' ')
            : '[ ]'.padEnd(7, ' '), // 次要診斷碼 第1組 (12-18)
        dxList[2]
            ? `[${dxList[2].icd_code}]`.padEnd(7, ' ')
            : '[ ]'.padEnd(7, ' '), // 次要診斷碼 第2組 (21-27)
        dxList[3]
            ? `[${dxList[3].icd_code}]`.padEnd(7, ' ')
            : '[ ]'.padEnd(7, ' '), // 次要診斷碼 第3組 (30-36)
        dxList[4]
            ? `[${dxList[4].icd_code}]`.padEnd(7, ' ')
            : '[ ]'.padEnd(7, ' '), // 次要診斷碼 第4組 (39-45)
        dxList[5]
            ? `[${dxList[5].icd_code}]`.padEnd(7, ' ')
            : '[ ]'.padEnd(7, ' '), // 次要診斷碼 第5組 (48-54)
    ].join('');

    const pDataWrite = replenishingCardNote + dxFormatted;

    console.log(
        JSON.stringify(pDataWrite),
        '先看一下pDataWrite長什麼樣子',
        pDataWrite
    );
    console.log('pDataWrite length:', pDataWrite.length); // 確認長度

    let finalData: ICCardWritingProps = {
        pDateTime: pDateTime.padEnd(14, ' '),
        pPatientID: pPatientID.padEnd(11, ' '),
        pPatientBirthDate: pPatientBirthDate.padEnd(8, ' '),
        pDataWrite: pDataWrite,
    };

    console.log(finalData, 'finalData');

    return Promise.resolve(finalData);
};
