import axios from "axios";
import axiosInstance from "./axiosInstance";
import apiService from "./apiService";

// 16. 取得或模糊搜尋院內自用的 ICD-10 資訊
export const callICD10PersonalUseService = async (token: string, data?: { [key: string]: string | number } | string) => {
	let path = "/api/icd10/in_use";

	if (typeof data === "string") {
		const newqueryParams = data ? { data } : undefined;
		console.log(newqueryParams, "先看啦");
		return apiService<any, any>({
			method: "GET",
			path,
			token,
			data: newqueryParams
		});
	} else if (typeof data === "object") {
		const queryString =
			data &&
			Object.keys(data)
				.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
				.join("&");
		if (queryString) {
			path += `/?${queryString}`;
		}
		return apiService<any, any>({
			method: "GET",
			path,
			token,
			data: null
		});
	}
};

// 17. 取得院內自用的特定 ICD-10 資訊
export const callICD10PersonalUseSpecService = async (token: string, data: string) => {
	const newqueryParams = data ? { data } : undefined;
	return apiService<updatedDataProps, any>({
		method: "GET",
		path: "/api/icd10/in_use",
		token,
		data: newqueryParams
	});
};

interface updatedDataProps {
	data_id?: string;
	short_code: string;
	custom_name_tw: string;
	custom_name_en: string;
	icd_code: string;
	subjective: string;
	objective: string;
	health_education: string;
}
// 14. 增修院內自用 ICD-10 資訊
export const postICD10PersonalUseService = async (token: string, updatedData: updatedDataProps) => {
	return apiService<updatedDataProps, any>({
		method: "POST",
		path: "/api/icd10/in_use",
		token,
		data: updatedData
	});
};

export const deleteICD10PersonalUseService = async (token: string, data_id: string) => {
	return apiService<undefined, any>({
		method: "DELETE",
		path: `/api/icd10/in_use?id=${data_id}`,
		token
	});
};
