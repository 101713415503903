import { callDeclareErrorService } from "service/declareErrorService";
import { useDeclareListsStore, DeclareListsItemProps } from "store/DeclarationAudit/useDeclareListsStore";
import { DeclareSearchRangeDataProps } from "store/DeclarationAudit/useDeclareSearchRangeStore";
import { useDeclarePageControlStore } from "store/DeclarationAudit/useDeclarePageControlStore";
import { useDeclareListLoadingStatusStore } from "store/DeclarationAudit/useDeclareListLoadingStatusStore";
import { useUserStore } from "store";
import Swal from "sweetalert2";

export const useDeclareErrorAPI = () => {
	const { userInfo } = useUserStore();
	const { updateDeclareLists, clearDeclareLists } = useDeclareListsStore();
	const { updateCurrentPage, updateLastPages, resetPageControl } = useDeclarePageControlStore();
	const { setIsLoading } = useDeclareListLoadingStatusStore();
	const callDeclareErrorAPI = async (data?: DeclareSearchRangeDataProps) => {
		clearDeclareLists();
		resetPageControl();
		setIsLoading(true);
		try {
			const response = await callDeclareErrorService(userInfo.token, data as DeclareSearchRangeDataProps);
			setIsLoading(false);
			if (response.result === "success") {
				updateDeclareLists(response.lists.data as DeclareListsItemProps[]);
				updateCurrentPage(response.lists.current_page);
				updateLastPages(response.lists.last_page);
				console.log("Success:", response);

				if (response.msg == "檢核無誤") {
					Swal.fire({
						icon: "success",
						title: "檢核無誤",
						showConfirmButton: false,
						timer: 1500
					});
					return;
				}
			} else if (response.result === "failure") {
				console.log("Failure:", response);
				if (response.msg === "尚無檢核錯誤清單") {
					Swal.fire({
						icon: "info",
						title: "無檢核錯誤清單",
						showConfirmButton: false,
						timer: 1500
					});
					clearDeclareLists();
					resetPageControl();
				} else {
					Swal.fire({
						icon: "error",
						title: "查詢過程發生錯誤",
						text: response.process.msg,
						confirmButtonText: "確定"
					});
				}
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			Swal.fire({
				icon: "error",
				title: "查詢過程發生錯誤",
				confirmButtonText: "確定"
			});
		}
	};
	return { callDeclareErrorAPI };
};
