import React from "react";
import { Tooltip, Flex, Box, Input } from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";

interface AddRowButtonProps {
	onAdd: () => void;
}

export const AddRowButton: React.FC<AddRowButtonProps> = ({ onAdd }) => (
	<Tooltip label={"新增一行"} hasArrow bg='#646B48'>
		<Flex
			pt={"8px"}
			gap={"4px"}
			pb={"4px"}
			align={"center"}
			onClick={onAdd}
			opacity={0.5}
			_hover={{
				opacity: 1,
				cursor: "pointer"
			}}>
			<Box textAlign={"center"} flex={"0 0 32px"} mx={"auto"} textColor={"#646B48"}>
				<HeroIcons name='add-fill'></HeroIcons>
			</Box>
			{Array(10)
				.fill("")
				.map((_, index) => (
					<Input
						key={index}
						pointerEvents={"none"}
						flex={index === 0 ? "1 1 30%" : index === 9 ? "0 0 calc(6% + 30px)" : "0 0 6%"}
						size={"md"}
						fontSize={"20px"}
						h={"35px"}
						px={"4px"}
						textAlign={"right"}
						border={index === 4 ? "none" : "1px solid #E2E8F0"}
					/>
				))}
			{/* <Box flex={'0 0 30px'}>
                 <HeroIcons name="ellipsis-horizontal"></HeroIcons> 
            </Box> */}
		</Flex>
	</Tooltip>
);
