import { Button, Box } from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import { mainNavItemButton } from "style/chakraStyles";
import { useControlledDrugSearch } from "hooks/useControlledDrugSearch";
import { useUserStore } from "store";

export const ControlledDrugSearch = () => {
	const { userInfo } = useUserStore();

	const { handleOpenControlledDrugWindow } = useControlledDrugSearch();

	const handleOpenControlledDrug = () => {
		handleOpenControlledDrugWindow(userInfo.token!);
	};

	return (
		<>
			<Button
				variant='ghost'
				size={"sm"}
				sx={mainNavItemButton}
				boxShadow={"inset 0px 2px 2px #00000099"}
				onClick={handleOpenControlledDrug}>
				<HeroIcons name='controlled-medication-query'></HeroIcons>
				<Box as='span' ml={"4px"}>
					管制藥查詢
				</Box>
			</Button>
		</>
	);
};
