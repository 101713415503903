import { create } from "zustand";

interface DeclarePrescriptionState {
	declarePrescriptionList: DeclarePrescriptionProps[];
	updateDeclarePrescription: (index: number, prescription: Partial<DeclarePrescriptionProps>) => void;
	setDeclarePrescriptionList: (list: DeclarePrescriptionProps[]) => void;
	resetDeclarePrescriptionList: () => void;
}

export type DeclarePrescriptionProps = {
	controlled_level: string;
	cost: string;
	data_id: string;
	disabled_date: string;
	dosage_form: string;
	dose: string;
	dose_day?: string;
	dosing_days: string;
	drug_no: string;
	drug_price: number | null;
	drug_type: string;
	freq: string;
	max_dose: string;
	max_dose_day: string;
	name_en: string;
	name_tw: string;
	route_of_administration: string;
	self_price: string;
	short_code: string;
	taking_time: string;
	unit: string;
	qty?: string;
	qty_in_day?: string;
	total_qty?: string;
	given_qty?: string;
	memo?: string;
	site?: string;
	special_mark?: string;
	freq_custom_code?: string;
	freq_directions?: string;
};

export const initialDeclarePrescription: DeclarePrescriptionProps = {
	controlled_level: "",
	cost: "",
	data_id: "",
	disabled_date: "",
	dosage_form: "",
	dose: "",
	dose_day: "",
	dosing_days: "",
	drug_no: "",
	drug_price: null,
	drug_type: "",
	freq: "",
	max_dose: "",
	max_dose_day: "",
	name_en: "",
	name_tw: "",
	route_of_administration: "",
	self_price: "",
	short_code: "",
	taking_time: "",
	unit: "",
	qty_in_day: "",
	total_qty: "",
	given_qty: "",
	qty: "",
	memo: "",
	site: "",
	special_mark: "",
	freq_custom_code: "",
	freq_directions: ""
};

export const useDeclarePrescriptionStore = create<DeclarePrescriptionState>(set => ({
	declarePrescriptionList: [initialDeclarePrescription],
	updateDeclarePrescription: (index, prescription) =>
		set(state => {
			const newDeclarePrescriptionList = [...state.declarePrescriptionList];
			newDeclarePrescriptionList[index] = {
				...newDeclarePrescriptionList[index],
				...prescription
			};
			return { declarePrescriptionList: newDeclarePrescriptionList };
		}),
	setDeclarePrescriptionList: list => set({ declarePrescriptionList: list }),
	resetDeclarePrescriptionList: () =>
		set({
			declarePrescriptionList: [initialDeclarePrescription]
		})
}));
