import { useState } from "react";
import { Table, TableContainer } from "@chakra-ui/react";
import { Box, Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import { ListHeader } from "./ListHeader";
import { ListBody } from "./ListBody";
import { ListFooter } from "./ListFooter";
import { useDeclareListsStore } from "store/DeclarationAudit/useDeclareListsStore";
import { useDeclareListTabIndexStore } from "store/DeclarationAudit/useDeclareListTabIndexStore";

export const List = () => {
	const { declareLists } = useDeclareListsStore();
	const { tabIndex } = useDeclareListTabIndexStore();
	return (
		<>
			{/* <ConditionalSearch /> */}

			<TableContainer h={`calc(${window.innerHeight}px - 316px)`} overflowY={"auto"} pos={"relative"}>
				<Table variant='simple'>
					<ListHeader />
					<ListBody data={declareLists} />
				</Table>
			</TableContainer>
			<ListFooter />
		</>
	);
};
