import React, { createContext, useState, useContext } from 'react';

interface WaitingTableTabContextType {
    waitingTableTabIndex: number;
    setWaitingTableTabIndex: (index: number) => void;
}

const WaitingTableTabIndexContext = createContext<WaitingTableTabContextType | undefined>(undefined);

export const WaitingTableTabIndexProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [waitingTableTabIndex, setWaitingTableTabIndex] = useState(0);

    return (
        <WaitingTableTabIndexContext.Provider value={{ waitingTableTabIndex, setWaitingTableTabIndex }}>
            {children}
        </WaitingTableTabIndexContext.Provider>
    );
};

export const useWaitingTableTabIndex = () => {
    const context = useContext(WaitingTableTabIndexContext);
    if (context === undefined) {
        throw new Error('useWaitingTableTab must be used within a WaitingTableTabProvider');
    }
    return context;
};