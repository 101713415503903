import React, { createContext, useContext, useState, ReactNode } from "react";

interface IsSetXValueOpenContextValue {
	isSetXValueOpenStatus: boolean;
	setIsSetXValueOpenStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsSetXValueOpenContext = createContext<IsSetXValueOpenContextValue | undefined>(undefined);

interface IsSetXValueOpenProviderProps {
	children: ReactNode;
}

export const IsSetXValueOpenProvider: React.FC<IsSetXValueOpenProviderProps> = ({ children }) => {
	const [isSetXValueOpenStatus, setIsSetXValueOpenStatus] = useState(false);
	return (
		<IsSetXValueOpenContext.Provider
			value={{
				isSetXValueOpenStatus,
				setIsSetXValueOpenStatus
			}}>
			{children}
		</IsSetXValueOpenContext.Provider>
	);
};

export const useIsSetXValueOpenStatus = (): IsSetXValueOpenContextValue => {
	const context = useContext(IsSetXValueOpenContext);

	if (!context) {
		throw new Error("useIsSetXValueOpenStatus must be used within a isSetXValueOpenStatusProvider");
	}

	return context;
};
