import React, { createContext, useContext, ReactNode, useState } from "react";

interface ShowMainFunctionDeleteIconContextValue {
	showMainFunctionDeleteIconStatus: string;
	setShowMainFunctionDeleteIconStatus: React.Dispatch<React.SetStateAction<string>>;
}
const ShowMainFunctionDeleteIconStatusContext = createContext<ShowMainFunctionDeleteIconContextValue | undefined>(undefined);

interface ShowMainFunctionDeleteIconProviderProps {
	children: ReactNode;
}

export const ShowMainFunctionDeleteIconProvider: React.FC<ShowMainFunctionDeleteIconProviderProps> = ({ children }) => {
	const [showMainFunctionDeleteIconStatus, setShowMainFunctionDeleteIconStatus] = useState("");

	return (
		<ShowMainFunctionDeleteIconStatusContext.Provider
			value={{
				showMainFunctionDeleteIconStatus,
				setShowMainFunctionDeleteIconStatus
			}}>
			{children}
		</ShowMainFunctionDeleteIconStatusContext.Provider>
	);
};

export const useShowMainFunctionDeleteIconStatus = (): ShowMainFunctionDeleteIconContextValue => {
	const context = useContext(ShowMainFunctionDeleteIconStatusContext);

	if (!context) {
		throw new Error("useShowMainFunctionDeleteIconStatus must be used within a ShowMainFunctionDeleteIconProvider");
	}
	return context;
};
