import { useEffect, useState } from "react";
import React from "react";
import { Sign } from "./VitalSignBodyComponents/Sign";
import { useSignContext } from "contexts";
import { useVitalSignQuery, vitalSignSettingListsProps } from "./utils/useVitalSignQuery";
import { Box, Flex } from "@chakra-ui/react";

type VitalSignProps = {
	data_id?: string;
	setting_uid?: string;
	name?: string;
	value?: string;
	unit?: string;
};

type VitalSignBodyProps = {
	vital_signs?: VitalSignProps[];
	id: string;
};

export const VitalSignBody: React.FC<VitalSignBodyProps> = ({ vital_signs = [], id }) => {
	const [displayedLists, setDisplayedLists] = useState<vitalSignSettingListsProps[]>([]);

	const { lists, isLoading, error } = useVitalSignQuery();
	const { state } = useSignContext();
	useEffect(() => {
		if (lists) {
			const filteredLists = lists.filter((item: vitalSignSettingListsProps) => state.selectedSigns.includes(item.id));
			setDisplayedLists(filteredLists);
		}
	}, [lists, state.selectedSigns]);

	return (
		<>
			{displayedLists &&
				displayedLists.map((item: vitalSignSettingListsProps, index: number) => (
					<Box key={index} px={"4px"} flex={"0 0 10%"} textAlign={"right"}>
						<Sign
							index={index}
							vital_sign_uid={item.id}
							vital_signs={vital_signs}
							opd_id={id}
							data_id={vital_signs.find(vs => vs.setting_uid === item.id)?.data_id ?? ""}
						/>
					</Box>
				))}
		</>
	);
};
