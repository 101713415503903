import { create } from "zustand";

// Define the interface for a single vital sign data item.
export interface VitalSignsDataProps {
	data_id?: string | null;
	opd_uid: string;
	vital_sign_uid: string;
	value?: string | null;
}

// Initialize the data as an empty array.
const initialVitalSignsData: Record<string, VitalSignsDataProps> = {
	systolic: {
		data_id: null,
		opd_uid: "",
		vital_sign_uid: "",
		value: null
	},
	diastolic: {
		data_id: null,
		opd_uid: "",
		vital_sign_uid: "",
		value: null
	},
	height: {
		data_id: null,
		opd_uid: "",
		vital_sign_uid: "",
		value: null
	},
	weight: {
		data_id: null,
		opd_uid: "",
		vital_sign_uid: "",
		value: null
	},
	temperature: {
		data_id: null,
		opd_uid: "",
		vital_sign_uid: "",
		value: null
	}
};

// Define the state interface for the store.
interface VitalSignsState {
	vitalSignsData: typeof initialVitalSignsData;
	updateVitalSignsData: (type: string, newData: VitalSignsDataProps) => void;
	resetVitalSignsData: () => void;
}

// Create the store using Zustand.
export const useVitalSignsDataStore = create<VitalSignsState>(set => ({
	vitalSignsData: initialVitalSignsData,
	updateVitalSignsData: (type, newData) =>
		set(state => ({
			vitalSignsData: {
				...state.vitalSignsData,
				[type]: { ...state.vitalSignsData[type], ...newData }
			}
		})),
	resetVitalSignsData: () =>
		set(() => ({
			vitalSignsData: initialVitalSignsData
		}))
}));
