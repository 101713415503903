import React, { useState, useEffect } from "react";
import FeeSettingTable from "../../components/DataSettings/FeeSettingComponents/FeeSettingTable";
import CloseIcon from "components/CustomComponents/CloseIcon";

const FeeSettingPage = () => {
	const [windowHeight, setWindowHeight] = useState(window.innerHeight);
	const [finalHeight, setFinalHeight] = useState(0);
	useEffect(() => {
		const handleResize = () => {
			setWindowHeight(window.innerHeight);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	const [bgOpenStatus, setBgOpenStatue] = useState(false);
	const handleOpen = () => {
		setBgOpenStatue(true);
	};
	const handleClose = () => {
		setBgOpenStatue(false);
	};
	useEffect(() => {
		const finalHeight = windowHeight - 120;
		setFinalHeight(finalHeight);
	}, [windowHeight]);
	return (
		<>
			<div>
				<FeeSettingTable></FeeSettingTable>
			</div>
		</>
	);
};

export default FeeSettingPage;
