import apiService from "./apiService";
import axiosInstance from "./axiosInstance";
//取得費用設定
export const callRegFeeSettingService = async (token: string) => {
	return apiService<undefined, any>({
		method: "GET",
		path: "/api/reg_fee/setting",
		token
	});
};

// export const callRegFeeSettingService = async (
//     token: string,
//     date?: string
// ) => {
//     try {
//         const headers = {
//             'Api-Token': `${token}`,
//         };
//         const response = await axiosInstance.get(`/api/reg_fee/setting/`, { headers });

//         const result = response.data;

//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };

// export const callRegFeeSettingChosenService = async (token: string, date?: string) => {
//     console.log(date, '中間那層')
//     return apiService<string, any>({
//         method: 'GET',
//         path: '/api/reg_fee/setting',
//         token,
//         data: date,
//     });
// };

export const callRegFeeSettingChosenService = async (token: string, date: string) => {
	try {
		const queryParams = { date };
		const headers = {
			"Api-Token": `${token}`,
			"Content-Type": "application/json"
		};

		console.log("到底", queryParams);
		const response = await axiosInstance.get(`/api/reg_fee/setting`, {
			headers,
			params: queryParams // 将查询字符串参数添加到请求中
		});

		const result = response.data;

		return result;
	} catch (error: unknown) {
		if (error instanceof Error) {
			console.error("Error fetching data:", error.message);
		} else {
			console.error("An unknown error occurred");
		}
	}
};

export const postRegFeeSettingService = async (token: string, date: object) => {
	return apiService<object, any>({
		method: "POST",
		path: "/api/reg_fee/setting",
		token,
		data: date
	});
};
// export const postRegFeeSettingService = async (token, date) => {
//     try {
//         const requestBody = JSON.stringify(date);
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         const response = await axios.post(
//             `/api/reg_fee/setting/`,
//             requestBody,
//             {
//                 headers,
//             }
//         );
//         const result = response.data;

//         return result;
//     } catch (error) {
//         console.error('Error fetching data:', error.message);
//     }
// };

export const deleteRegFeeSettingService = async (token: string, dataId: string) => {
	// 使用apiService调用DELETE方法
	return apiService<undefined, any>({
		method: "DELETE",
		path: `/api/reg_fee/setting?start_date=${dataId}`,
		token
	});
};
// 刪除看診時段
// export const deleteRegFeeSettingService = async (
//     token: string,
//     dataId: string
// ) => {
//     try {
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };
//         const url = `/api/reg_fee/setting/?start_time=${dataId}`;

//         const response = await axios.delete(url, {
//             headers,
//         });

//         const result = response.data;

//         console.log(result, 'API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
