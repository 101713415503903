import apiService from "./apiService";

type callOpdRecordResponseProps = {
	result: string;
	info: {
		data_id: string;
		opd_at: string;
		reg_number: number;
		patient_uid: string;
		card_no: string;
		nhi_type: string;
		doctor_uid: string;
		shift_uid: string;
		clinic_room_uid: string;
		mt_type: string;
		note: string | null;
		membership_uid: string;
		patient_name: string;
		medical_record_number: string;
		birth_date: string;
		sex: string;
		id_no: string;
		if_drug_allergy: number;
		drug_allergy_describe: string | null;
		seek_history: string | null;
		doctor_room: string;
	};
	cashier: {
		main: {
			data_id: string;
			cashier_type: number;
			total: string;
			discount: string;
			final: string;
		};
		detail: Array<{
			data_id: string;
			price: string;
			discount: string;
			final: string;
			acc_item_uid: string;
			acc_name: string;
		}>;
	};
	vital_sign: Array<{
		data_id: string;
		value: string;
		vital_sign_uid: string;
		name: string;
	}>;
};

// 取得特定病患的掛號資訊
export const callOpdRecordService = async (token: string, data: string) => {
	const queryParams = data ? { data } : undefined;
	return apiService<any, any>({
		method: "GET",
		path: "/api/opd/record",
		token,
		data: queryParams
	});
};

type vitalSignDataProps = {
	data_id?: string;
	vital_sign_uid: string;
	value?: string;
};
type cashierListsProps = {
	acc_item_uid: string;
	cashier_type: string;
	// 1：點數、2：交付、3：金額
	price: number;
	discount?: number;
	final: number;
};
export interface opdRecordProps {
	// 資料ID，如果未定義，則表示是新增的資料
	data_id?: string;
	// 病患的資料id，必填
	patient_uid: string;
	// 時段資料id，必填
	shift_uid: string;
	// 診間資料id，必填
	clinic_room_uid: string;
	// 醫師資料id，必填
	doctor_uid: string;
	// 掛號身份，必填，內容為從某個來源取得的code
	nhi_type: string;
	// reg_type: string;
	// 優免身份資料uid，可選
	member_uid?: string;
	// 卡號，最大長度4碼英數字，可選
	card_no?: string;
	// 就醫類別，可選
	mt_type?: string;
	// 告知診間的附加訊息，可選
	note?: string;
	cashier_status: string;
	// 掛號費，可能涉及金額計算，可選
	total?: number | null;
	discount?: number | null;
	final?: number | null;
	cashier?: cashierListsProps[];
	opd_status: string;
	vital_sign?: vitalSignDataProps[];
	opd_at?: string;
	refillable_uid?: string | null;
	refillable_detail_uid?: string | null;
}

export const postOpdRecordService = async (token: string, data: opdRecordProps) => {
	return apiService<string, any>({
		method: "POST",
		path: "/api/opd/record",
		token,
		data: data
	});
};

export const deleteOpdRecordService = async (token: string, data: object) => {
	return apiService<undefined, any>({
		method: "DELETE",
		path: `/api/opd/record`,
		token,
		data: data
	});
};
// export const deleteClinicScheduleService = async (
//     token: string,
//     data: object
// ) => {
//     return apiService<undefined, any>({
//         method: 'DELETE',
//         path: `/api/clinic_schedule`,
//         token,
//         data: data
//     })
// }
