import React, { createContext, useState, useContext, ReactNode } from 'react';

interface IsManualInputStatusContextType {
    isManualInputStatus: boolean;
    setIsManualInputStatus: (value: boolean) => void;
}

const IsManualInputStatusContext = createContext<IsManualInputStatusContextType | undefined>(undefined);

export const IsManualInputStatusProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isManualInputStatus, setIsManualInputStatus] = useState<boolean>(false);

    return (
        <IsManualInputStatusContext.Provider value={{ isManualInputStatus, setIsManualInputStatus }}>
            {children}
        </IsManualInputStatusContext.Provider>
    );
};

export const useIsManualInputStatus = (): IsManualInputStatusContextType => {
    const context = useContext(IsManualInputStatusContext);
    if (context === undefined) {
        throw new Error('useIsManualInputStatus must be used within an IsManualInputStatusProvider');
    }
    return context;
};


