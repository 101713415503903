import { Box, Flex, Heading, Input, Button, Select } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useVirtualCardState } from 'contexts/VirtualCardContext';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import {
    callPatientSearchService,
    patientSearchListsProps,
} from 'service/patientSearchService';
import { useRegisteredFlowStatus } from 'contexts/RegisteredFlowStatusContext';
import { callPatientSpecService } from 'service/patientSpecService';
import { useEscapeToClose } from 'functions/useEscapeToClose';
import { useSearchPatientStore } from 'store/Registration';
import { toROCDateString } from 'functions/toROCDateString';
import Swal from 'sweetalert2';
import { ADDateTransToROCStr } from 'functions/ADDateTransToROCStr';
import { useIsChronicStatus } from 'contexts/Registration/IsChronicContext';
//store
import { usePatientInfoStore } from 'store/Registration';
import { useVitalSignsDataStore } from 'store/Registration';
import { useCurrentVisitInfoStore } from 'store/Registration';
import { useUserStore } from 'store';
function SearchPatientMedicalRecord() {
    // const token = localStorage.getItem('token');
    const { userInfo } = useUserStore();
    const { searchPatientData } = useSearchPatientStore();
    const { patientInfo, updatePatientInfo } = usePatientInfoStore();
    const { resetCurrentVisitInfoData } = useCurrentVisitInfoStore();
    const { resetVitalSignsData } = useVitalSignsDataStore();
    const { setRegisteredFlowStatus, setShowPatientManagementStructureStatus } =
        useRegisteredFlowStatus();
    const [searchResult, setSearchResult] = useState<patientSearchListsProps[]>(
        []
    );
    const [searchWay, setSearchWay] = useState<string>('fuzzy');
    const [keywords, setKeywords] = useState<string>('');

    const { isChronicStatus, setIsChronicStatus } = useIsChronicStatus();

    const {
        showSearchPatientMedicalRecord,
        setShowSearchPatientMedicalRecord,
    } = useVirtualCardState();

    const handleChangeSearchWay = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        setSearchWay(value);
    };

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.toLocaleUpperCase();

        setKeywords(value);
    };

    useEffect(() => {
        setKeywords(searchPatientData.keywords);
        setSearchResult(searchPatientData.searchLists || []);
    }, [showSearchPatientMedicalRecord]);

    useEffect(() => {
        if (searchPatientData) {
            setKeywords(searchPatientData.keywords);
            setSearchResult(searchPatientData.searchLists || []);
        }
    }, [searchPatientData]);

    const handleSearchPatient = async () => {
        if (keywords.length < 3) {
            Swal.fire({
                icon: 'info',
                title: '請輸入至少 3 個字',
                timer: 1500,
            });
            return;
        }
        try {
            const result = await callPatientSearchService(
                userInfo.token!,
                searchWay,
                keywords
            );
            console.log('呼叫搜尋病患結果: ', result);
            if (result && result.result === 'success') {
                if (result.lists && result.lists.length > 0) {
                    console.log('多筆搜尋結果: ', result);
                    let transDate = result.lists.map(
                        (item: patientSearchListsProps) => {
                            return {
                                ...item,
                                birth_date: item.birth_date
                                    ? toROCDateString(item.birth_date)
                                    : null,
                            };
                        }
                    );
                    setSearchResult(transDate);
                } else if (result.info) {
                    console.log('單筆搜尋結果: ', result);
                    setShowSearchPatientMedicalRecord(false);
                    setShowPatientManagementStructureStatus(true);
                    setRegisteredFlowStatus('second');
                    // setIsChronicStatus(true);
                    updatePatientInfo({
                        ...patientInfo,
                        info: result.info,
                        email: result.email,
                        emer: result.emer_contactor,
                        address: result.address,
                        phone: result.phone,
                    });
                } else {
                    console.log('例外狀況');
                    Swal.fire({
                        icon: 'error',
                        title: '發生錯誤',
                        text: '請檢察網路連線並重新整理',
                    });
                }
            } else if (result.message === 'Internal Server Error') {
                Swal.fire({
                    icon: 'error',
                    title: '發生錯誤',
                    text: '請檢察網路連線並重新整理',
                });
                return;
            } else if (result.result === 'failure') {
                if (result.msg === '查無符合資料') {
                    Swal.fire({
                        icon: 'info',
                        title: '查無病患資料',
                        timer: 1500,
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: '例外狀況',
                        timer: 1500,
                    });
                }
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: '後端服務錯誤',
                timer: 1500,
                showConfirmButton: false,
            });
        }
    };
    const handleKeyDownInput = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key === 'Enter' && !event.ctrlKey) {
            handleSearchPatient();
            return;
        }

        if (event.ctrlKey) {
            switch (event.key) {
                case '1':
                    setSearchWay('all');
                    break;
                case '2':
                    setSearchWay('birth_date');
                    break;
                case '3':
                    setSearchWay('id_no');
                    break;
                case '4':
                    setSearchWay('medical_record_number');
                    break;
                case '5':
                    setSearchWay('name');
                    break;
                default:
                    break;
            }
        }
    };

    const handleGoRegistration = async (
        event: React.MouseEvent<HTMLButtonElement>,
        data_id: string
    ) => {
        console.log(data_id, '先看這id');
        resetVitalSignsData();
        try {
            const result = await callPatientSpecService(
                userInfo.token!,
                data_id,
                'basic'
            );
            if (result?.result === 'success') {
                console.log(result.info, '這邊要確定欸');
                updatePatientInfo({
                    info: result.info,
                    phone: result.phone,
                    address: result.address,
                    emer: result.emer_contactor,
                    email: result.email,
                });

                // updateCurrent
                setShowSearchPatientMedicalRecord(false);
                setShowPatientManagementStructureStatus(true);
                setRegisteredFlowStatus('second');
                setIsChronicStatus(true);
            }
        } catch {
            Swal.fire({
                icon: 'error',
                title: '後端服務錯誤',
                timer: 1500,
                showConfirmButton: false,
            });
        }
    };

    // const [birthStr, setBirthStr] = useState('')

    useEffect(() => {
        if (patientInfo.info.birth_date) {
            const str = ADDateTransToROCStr(patientInfo.info.birth_date);
            console.log(str, '轉換結果');
            // setBirthStr(str)
        }
    }, [patientInfo.info.birth_date]);

    useEscapeToClose(setShowSearchPatientMedicalRecord);
    return (
        <>
            <Flex
                hidden={showSearchPatientMedicalRecord ? false : true}
                pos={'fixed'}
                top={0}
                left={0}
                bg={'rgba(0,0,0,0.4)'}
                justify={'center'}
                w={'100%'}
                h={'100%'}
                zIndex={60}
            >
                <Flex
                    pos={'relative'}
                    top={'15px'}
                    left={'0px'}
                    bg={'#fff'}
                    p={'24px'}
                    rounded={'15px'}
                    alignSelf={'flex-start'}
                    w={'1200px'}
                    direction={'column'}
                    gap={'16px'}
                >
                    <Flex justify={'space-between'} align={'center'}>
                        <Heading
                            fontSize={'20px'}
                            textColor={'rsPrimary.500'}
                            fontWeight={'normal'}
                        >
                            查詢病患
                        </Heading>
                        <Box
                            cursor={'pointer'}
                            _hover={{
                                filter: 'brightness(1.5)',
                                cursor: 'pointer',
                            }}
                            textColor={'rsPrimary.500'}
                            onClick={() => {
                                setKeywords('');
                                setSearchResult([]);
                                resetCurrentVisitInfoData();
                                setShowSearchPatientMedicalRecord(false);
                            }}
                        >
                            <HeroIcons name="xmark"></HeroIcons>
                        </Box>
                    </Flex>
                    <hr />
                    <Flex
                        align={'center'}
                        gap={'16px'}
                        justify={'space-between'}
                        p={'16px'}
                        bg={'gray.50'}
                        rounded={'15px'}
                    >
                        <Box textColor={'rsPrimary.500'}>
                            <HeroIcons name="search"></HeroIcons>
                        </Box>
                        <Select
                            bg={'#fff'}
                            flex={'0 0 200px'}
                            onChange={handleChangeSearchWay}
                            value={searchWay}
                        >
                            <option value="fuzzy">全部</option>
                            <option value="birth_date">生日</option>
                            <option value="id_no">身分證字號</option>
                            <option value="medical_record_number">
                                病歷號
                            </option>
                            <option value="name">姓名</option>
                        </Select>
                        <Input
                            bg={'#fff'}
                            placeholder={
                                searchWay === 'birthday'
                                    ? '請輸入生日 ex: 0790304'
                                    : searchWay === 'name'
                                      ? '請輸入姓名'
                                      : searchWay === 'rocId'
                                        ? '請輸入身分證字號'
                                        : searchWay === 'medicalRecord'
                                          ? '請輸入病歷號'
                                          : '請輸入關鍵字'
                            }
                            onChange={handleChangeInput}
                            value={keywords}
                            onKeyUp={e => {
                                if (e.key === 'Enter') {
                                    handleSearchPatient();
                                }
                            }}
                            autoFocus
                        ></Input>

                        <Button
                            colorScheme="rsDanger"
                            onClick={handleSearchPatient}
                        >
                            查詢
                        </Button>
                    </Flex>

                    {
                        <Flex direction={'column'}>
                            {/* 表頭 */}
                            <Flex
                                p={'5px'}
                                bg={'gray.150'}
                                borderBottom={'1px solid #868C6E'}
                            >
                                <Flex p={'5px'} align={'center'} w={'100%'}>
                                    <Box
                                        textColor={'rsPrimary.500'}
                                        px={'8px'}
                                        flex={'1 0 120px'}
                                    >
                                        病歷號
                                    </Box>
                                    <Box
                                        textColor={'rsPrimary.500'}
                                        px={'8px'}
                                        flex={'1 0 80px'}
                                    >
                                        姓名
                                    </Box>

                                    <Box
                                        textColor={'rsPrimary.500'}
                                        px={'8px'}
                                        flex={'1 0 80px'}
                                    >
                                        生日
                                    </Box>

                                    <Box
                                        textColor={'rsPrimary.500'}
                                        px={'8px'}
                                        flex={'1 0 105px'}
                                    >
                                        身份證號
                                    </Box>
                                    <Box
                                        textColor={'rsPrimary.500'}
                                        px={'8px'}
                                        flex={'1 0 100px'}
                                    >
                                        電話
                                    </Box>
                                    <Box
                                        textColor={'rsPrimary.500'}
                                        px={'8px'}
                                        flex={'1 0 64px'}
                                    >
                                        資訊
                                    </Box>
                                </Flex>
                            </Flex>
                            <Flex
                                direction={'column'}
                                maxH={`${window.innerHeight - 524}px`}
                                overflowY={'scroll'}
                            >
                                {/* 表身 */}
                                {searchResult &&
                                    searchResult?.map((item, index) => (
                                        <Flex p={'5px'} key={index}>
                                            <Flex
                                                p={'5px'}
                                                align={'center'}
                                                borderBottom={
                                                    '1px solid #868C6E'
                                                }
                                                _hover={{ bg: '#DDDBBA33' }}
                                                w={'100%'}
                                            >
                                                <Box
                                                    textColor={'rsPrimary.500'}
                                                    px={'8px'}
                                                    flex={'1 0 120px'}
                                                >
                                                    {item.medical_record_number}
                                                </Box>
                                                <Box
                                                    textColor={'rsPrimary.500'}
                                                    px={'8px'}
                                                    flex={'1 0 80px'}
                                                >
                                                    {item.name}
                                                </Box>
                                                <Box
                                                    textColor={'rsPrimary.500'}
                                                    px={'8px'}
                                                    flex={'1 0 80px'}
                                                >
                                                    {item.birth_date}
                                                </Box>
                                                <Box
                                                    textColor={'rsPrimary.500'}
                                                    px={'8px'}
                                                    flex={'1 0 105px'}
                                                >
                                                    {item.id_no}
                                                </Box>
                                                <Box
                                                    textColor={'rsPrimary.500'}
                                                    px={'8px'}
                                                    flex={'1 0 100px'}
                                                >
                                                    {item.phone}
                                                </Box>
                                                <Box flex={'1 0 64px'}>
                                                    <Button
                                                        colorScheme="gray"
                                                        variant="link"
                                                        size={'sm'}
                                                        boxShadow={'none'}
                                                        _hover={{
                                                            boxShadow: 'none',
                                                        }}
                                                        onClick={event =>
                                                            handleGoRegistration(
                                                                event,
                                                                item.id
                                                            )
                                                        }
                                                    >
                                                        <HeroIcons name="openWindow"></HeroIcons>
                                                    </Button>
                                                </Box>
                                            </Flex>
                                        </Flex>
                                    ))}
                            </Flex>
                        </Flex>
                    }
                </Flex>
            </Flex>
        </>
    );
}

export default SearchPatientMedicalRecord;
