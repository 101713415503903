import apiService from "./apiService";

export type customerProps = {
	customer_uid: string | null;
	hosp_id: string | null;
	nhid: string | null;
	branch_uid: string | null;
	hosp_name: string | null;
	institution_type: string | null;
	business_license: string | null;
	uniform_numbers: string | null;
	tel_area_code: string | null;
	tel_number: string | null;
	address: string | null;
	zip: string | null;
	prescription_style: string | null;
	responsible_doctor: string | null;
	doctor_idno: string | null;
	pharmacist: string | null;
	pharmacist_idno: string;
	deliver_exam_type: string | null;
	deliver_exam_lab: string | null;
	lab_name: string | null;
	ot_admin_account: string | null;
	ot_admin_password: string | null;
};
//取得院所基本資料
export const callCustomerService = async (token: string) => {
	return apiService<undefined, any>({
		method: "GET",
		path: "/api/customer",
		token
	});
};

// export const callCustomerService = async (token: string) => {
//     try {
//         const headers = {
//             'Api-Token': `${token}`,
//         };
//         const response = await axios.get('/api/customer', { headers });
//         return response.data;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };

interface updatedDataProps {
	address: string | null;
	branch_uid: string | null;
	business_license: string | null;
	customer_uid: string | null;
	doctor_idno: string | null;
	hosp_id: string | null;
	hosp_name: string | null;
	institution_type: string | null;
	nhid: string | null;
	pharmacist: string | null;
	pharmacist_idno: string | null;
	prescription_style: string | null;
	responsible_doctor: string | null;
	tel_area_code: string | null;
	tel_number: string | null;
	uniform_numbers: string | null;
	zip: string | null;
	deliver_exam_type: string | null;
	deliver_exam_lab: string | null;
	lab_name: string | null;
	ot_admin_account: string | null;
	ot_admin_password: string | null;
}
//增修院所資本資料設定
export const postCustomerService = async (token: string, updatedData: updatedDataProps) => {
	return apiService<updatedDataProps, any>({
		method: "POST",
		path: "/api/customer",
		token,
		data: updatedData
	});
};

// export const postCustomerService = async (
//     token: string,
//     updatedData: updatedDataProps
// ) => {
//     try {
//         const requestBody = JSON.stringify(updatedData);
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         const response = await axios.post('/api/customer', requestBody, {
//             headers,
//         });
//         const result = response.data;

//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
