import React, { createContext, useContext, useState, ReactNode } from 'react';

interface VirtualCardStateContextValue {
    // 打開虛擬卡QRCode讀卡畫面
    showReadVHICStatus: boolean;
    setShowReadVHICStatus: React.Dispatch<React.SetStateAction<boolean>>;
    // 顯示搜尋病患醫療記錄
    showSearchPatientMedicalRecord: boolean;
    setShowSearchPatientMedicalRecord: React.Dispatch<React.SetStateAction<boolean>>;
    // 虛擬卡步驟
    virtualCardStepStatus: number;
    setVirtualCardStepStatus: React.Dispatch<React.SetStateAction<number>>;
}

const VirtualCardStateContext = createContext<VirtualCardStateContextValue | undefined>(undefined);

interface VirtualCardStateProviderProps {
    children: ReactNode;
}

export const VirtualCardStateProvider: React.FC<VirtualCardStateProviderProps> = ({ children }) => {
    const [showReadVHICStatus, setShowReadVHICStatus] = useState(false);
    const [showSearchPatientMedicalRecord, setShowSearchPatientMedicalRecord] = useState(false);
    const [virtualCardStepStatus, setVirtualCardStepStatus] = useState(0);

    return (
        <VirtualCardStateContext.Provider
            value={{
                showReadVHICStatus,
                setShowReadVHICStatus,
                showSearchPatientMedicalRecord,
                setShowSearchPatientMedicalRecord,
                virtualCardStepStatus,
                setVirtualCardStepStatus,
            }}
        >
            {children}
        </VirtualCardStateContext.Provider>
    );
};

export const useVirtualCardState = (): VirtualCardStateContextValue => {
    const context = useContext(VirtualCardStateContext);

    if (!context) {
        throw new Error('useVirtualCardState must be used within a VirtualCardStateProvider');
    }
    return context;
};
