export const ADDateToROCStringyyyMMddHHmmssNoDot = (date: Date) => {
	let rocYear = (date.getFullYear() - 1911).toString().padStart(3, "0");
	const month = date.getMonth() + 1;
	const day = date.getDate();
	const hours = date.getHours();
	const minutes = date.getMinutes();
	const seconds = date.getSeconds();

	const formattedMonth = month < 10 ? `0${month}` : month;
	const formattedDay = day < 10 ? `0${day}` : day;
	const formattedHours = hours < 10 ? `0${hours}` : hours;
	const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
	const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

	return `${rocYear}${formattedMonth}${formattedDay}${formattedHours}${formattedMinutes}${formattedSeconds}`;
};
