import { Button } from "@chakra-ui/react";
import { useEffect , useState} from "react";
interface VideoRegistrationButtonProps {
    opdRecordAPI: (param: string) => Promise<void>;  // 更新接口定義
    doctorUid: string;
}
import { postRegisterCovidPatientInfoService, RegisterCovidPatientInfoReqProps, RegisterCovidPatientInfoResProps } from "service/VHIC/RegisterCovidPatientInfoService";
import { usePatientInfoStore } from "store/Registration/usePatientInfoStore";
import { useCurrentVisitInfoStore } from "store/Registration/useCurrentVisitInfoStore";
import { useUserStore } from "store";
import { useDropDownMenuQuery } from "query/useDropDownMenuQuery";
import { shiftProps} from "service/shiftService"

import moment from "moment";
import { useCustomerAPI } from "service/customerServiceHelper";
import { useInstitutionInfoStore } from "store/DataSetting/useInstitutionInfoStore";
import { callMedicalStaffSpecService } from "service/medicalStaffSpecService";
import Swal from "sweetalert2";

export const VideoRegistrationButton:React.FC<VideoRegistrationButtonProps> = ({opdRecordAPI, doctorUid}) => {
    const { userInfo } = useUserStore();
    const { patientInfo } = usePatientInfoStore();
    const { currentVisitInfoData } = useCurrentVisitInfoStore();
    const { data: shiftData } = useDropDownMenuQuery('shift', userInfo.token);
    const { institutionInfoData } = useInstitutionInfoStore();
    const [doctor_account, setDoctor_account] = useState('');
    const { callCustomerAPI } = useCustomerAPI();
    const [customerApiCalled, setCustomerApiCalled] = useState(false);
    const [doctorAccountFetched, setDoctorAccountFetched] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (!customerApiCalled) {
                await callCustomerAPI();
                setCustomerApiCalled(true);
            }
            
            if (doctorUid && !doctorAccountFetched) {
                try {
                    const result = await callMedicalStaffSpecService(userInfo.token, doctorUid);
                    setDoctor_account(result.info.ot_account);
                    setDoctorAccountFetched(true);
                } catch (error) {
                  
                    console.log('取得醫師診療帳號失敗')
                }
            }
        };
        
        fetchData();
    }, [doctorUid, userInfo.token]);

    // Remove console.log statements
    const handleVideoRegistration = async() => {
        console.log(institutionInfoData, 'institutionInfoData');
        console.log(currentVisitInfoData, 'currentVisitInfoData');
        console.log(patientInfo, 'patientInfo');
        console.log(shiftData, 'shiftData');

      
        if (!doctor_account) {
            Swal.fire({
                title: '取得醫師診療帳號失敗',
                text: '請確認人員設定裡醫師診療帳號是否填入',
                icon: 'error',
            });
            return;
        }
        const birthday = patientInfo.info.birth_date ? moment(patientInfo.info.birth_date).format('YYYYMMDD') : '';
        const name = patientInfo.info.name || '';
        const localNumber = patientInfo.info.medical_record_number || '';
        const cellPhone = patientInfo.phone && patientInfo.phone.length > 0 && patientInfo.phone[0].number
            ? patientInfo.phone[0].number
            : '';
        const user_id = patientInfo.info.id_no ? patientInfo.info.id_no : '';
        const gender = patientInfo.info.sex === 'F' ? 'F' : 'M';
        const veterans = patientInfo.info.nhi_identity === '榮民' ? true : false;
        const disability = false; // 身心障礙先給false
        const injury = false; // 重大傷病先給false
      
        
        

        const bloodtype = patientInfo.info.blood_type ? (
            patientInfo.info.blood_type === 'A' || 
            patientInfo.info.blood_type === 'B' || 
            patientInfo.info.blood_type === 'AB' || 
            patientInfo.info.blood_type === 'O' ? 
            patientInfo.info.blood_type : 'N/A'
        ) : 'N/A';

        // const bloodrhtype = false; //血型 Rh 先給false
       

       
        const getCurrentTime = (): string => {
            const now = new Date();
            const hours = now.getHours().toString().padStart(2, '0');
            const minutes = now.getMinutes().toString().padStart(2, '0');
            return `${hours}:${minutes}`;
          };
          
          // 根據當前時間找到對應的時段
          const findCurrentShift = () => {
            if (!Array.isArray(shiftData?.lists)) {
                console.error('shiftData.lists is not an array:', shiftData);
                return { start_time: '', end_time: '' };
            }
            const currentTime = getCurrentTime();
            const currentShift = shiftData.lists.find((shift: shiftProps) => 
                currentTime >= shift.start_time && currentTime < shift.end_time
            );
            return currentShift 
                ? { start_time: currentShift.start_time, end_time: currentShift.end_time }
                : { start_time: '', end_time: '' };
          };
        const hisCaseID = (patientInfo.info.data_id || '') + moment().format('YYYYMMDDHHmmss');

        // 找到對應的開始與結束時間
        const consultationShift = findCurrentShift();
        // const consultationStartTime = moment().format('YYYY/MM/DD') + ' ' + (consultationShift?.start_time || '');
        // const consultationEndTime = moment().format('YYYY/MM/DD') + ' ' + (consultationShift?.end_time || '');
        const consultationStartTime = moment(new Date()).format('YYYY/MM/DD HH:mm');
        const consultationEndTime = moment(new Date()).add(10, 'minutes').format('YYYY/MM/DD HH:mm');
        const ot_admin_account = institutionInfoData?.ot_admin_account || '';
        const ot_admin_password = institutionInfoData?.ot_admin_password || '';
        const videoRegistrationData: RegisterCovidPatientInfoReqProps = {
            account: ot_admin_account,
            pw: ot_admin_password,
            patient: {
                name: name,
                localNumber: localNumber,
                cellPhone: cellPhone,
                user_id: user_id,
                user_birthday: birthday,
                country: 'HC',
                gender: gender,
                veterans: veterans,
                disability: disability,
                injury: injury,

                bloodtype: bloodtype as "A" | "B" | "AB" | "O" | "N/A",
                // bloodrhtype: bloodrhtype,

            },
            consultation: {
                doctorID: doctor_account,
                consultationStartTime: consultationStartTime,
                consultationEndTime: consultationEndTime,
                hisCaseID: hisCaseID,
            }
    };

        try {
            const result: RegisterCovidPatientInfoResProps = await postRegisterCovidPatientInfoService(videoRegistrationData);
            
            // 檢查 postRegisterCovidPatientInfoService 的結果
            if (result.ExectResult) {  // 假設 API 返回一個包含 success 字段的對象
                // 只有在 postRegisterCovidPatientInfoService 成功時才執行 opdRecordAPI
                alert(result.PatientMessage)
                try {
                    await opdRecordAPI('12');
                    console.log('視訊掛號和 opdRecordAPI 都成功執行');
                } catch (error) {
                    console.error('opdRecordAPI 執行失敗:', error);
                    // 這裡可以添加 opdRecordAPI 失敗的處理邏輯
                }
            } else {
                console.error('視訊掛號失敗:', result);
                // 這裡可以添加視訊掛號失敗的處理邏輯，比如顯示錯誤消息給用戶
            }
        } catch (error) {
            console.error('視訊掛號過程中發生錯誤:', error);
            // 這裡可以添加錯誤處理邏輯
        }
        
    }
    return (
        <>
            <Button
                variant={'outline'}
                colorScheme="rsDanger"
                borderWidth={'2px'}
                fontWeight={'semibold'}
                onClick={handleVideoRegistration}
            >
                視訊掛號
            </Button>
        </>
    )
};
