import { create } from "zustand";

// 定義 PatientModelProps 接口
export interface PatientModelProps {
	Pat_Code: string; // 病歷號
	Pat_Name: string; // 病患姓名
	Pat_IdnoType: number; // 身份證件種類
	Pat_IDNo?: string; // 身分證字號(建議)
	Pat_Gender?: string; // 病患性別
	Pat_Birth?: string; // 病患生日(建議)
	Pat_BirthTime?: string; // 出生時間 Ex : 13:00
	Pat_Tel?: string; // 家用電話
	Pat_Mobile?: string; // 行動電話
	Pat_Addr?: string; // 病患地址
	Pat_Memo?: string; // 病患備註
	Pat_Job?: string; // 病患職業
	Pat_Mark?: string; // 病患註記
	//Patient
	Pat_OriCode?: string; // Patient 原號
	Pat_ShortCode?: string; // 簡碼
	Pat_Allergy?: string; // 過敏
	Req?: number; // 就診序
	FirstDate?: string; // 初診日期
	LastDate?: string; // 最後回診日
}

// 定義 PatientModelStore 接口
interface PatientModelStore {
	patientModel: PatientModelProps;
	updatePatientModel: (patientModel: PatientModelProps) => void;
	resetPatientModel: () => void;
}

// 初始化 patientModel 的值
export const initialPatientModel: PatientModelProps = {
	Pat_Code: "",
	Pat_Name: "",
	Pat_IdnoType: 0,
	Pat_IDNo: "",
	Pat_Gender: "",
	Pat_Birth: "",
	Pat_BirthTime: "",
	Pat_Tel: "",
	Pat_Mobile: "",
	Pat_Addr: "",
	Pat_Memo: "",
	Pat_Job: "",
	Pat_Mark: "",
	//Patient
	Pat_OriCode: "",
	Pat_ShortCode: "",
	Pat_Allergy: "",
	Req: 0,
	FirstDate: "",
	LastDate: ""
};

// 創建 usePatientModelStore
export const usePatientModelStore = create<PatientModelStore>(set => ({
	patientModel: initialPatientModel,
	updatePatientModel: patientModel =>
		set(() => ({
			patientModel: patientModel
		})),
	resetPatientModel: () =>
		set(() => ({
			patientModel: initialPatientModel
		}))
}));

export default usePatientModelStore;
