import React, { useState, useEffect } from "react";
import { Box, Text, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useUserStore } from "store";
import { usePatientInfoStore, useCurrentVisitInfoStore } from "store/Registration";
import { useRegistrationRefillableStore } from "store/Registration/useRegistrationRefillableStore";
import { InsetShadowBox } from "style/chakraStyles";
import Swal from "sweetalert2";

import { PatientInfo } from "../PatientInfo";
import { Record } from "./Record";
// import { GotHistoryList } from './GotHistoryList';

import { postRefillableListsService, RefillableListsReqProps } from "service/refillableListsService";

import { callRefillableSpecService, RefillableSpecReqProps } from "service/refillableSpecService";

export const SequentialPrescription = () => {
	const { userInfo } = useUserStore();
	const { patientInfo } = usePatientInfoStore();
	const { currentVisitInfoData, updateCurrentVisitInfoData } = useCurrentVisitInfoStore();
	const { refillableLists, updateRefillableLists, refillableDetailInfo, updateRefillableDetailInfo } = useRegistrationRefillableStore();

	// const today = new Date().toISOString().split("T")[0];
	// const currentOpdAt = new Date(currentVisitInfoData.opd_at).toISOString().split("T")[0];
	// const visitDate = currentOpdAt ? currentOpdAt : today;
	const today = new Date().toISOString().split("T")[0];
	const currentOpdAt = currentVisitInfoData?.opd_at ? new Date(currentVisitInfoData.opd_at).toISOString().split("T")[0] : null;
	const visitDate = currentOpdAt ?? today;

	const postRefillableListsAPI = async () => {
		if (patientInfo.info.data_id && visitDate) {
			const reqData: RefillableListsReqProps = {
				patient_uid: patientInfo.info.data_id,
				medical_date: visitDate,
				opd_uid: currentVisitInfoData.data_id ?? ""
			};
			const result = await postRefillableListsService(userInfo.token, reqData);
			console.log("日式綠茶 list result", result);

			if (result.result === "success") {
				updateRefillableLists({ lists: result.lists });
				updateRefillableDetailInfo({
					refillable_uid: result.detail_info?.refillable_uid,
					refillable_detail_uid: result.detail_info?.refillable_detail_uid,
					card_no: result.detail_info?.card_no,
					first_date: result.detail_info?.first_date,
					refill_times: result.detail_info?.refill_times,
					get_times: result.detail_info?.get_times,
					lists: result.detail_info?.lists
				});
			}
		} else {
			Swal.fire({
				icon: "error",
				title: "無此病患資料"
			});
		}
	};

	useEffect(() => {
		postRefillableListsAPI();
	}, []);

	const callRefillableSpecAPI = async (data_id: string) => {
		console.log("data_id", data_id);
		if (patientInfo.info.data_id) {
			const reqData: RefillableSpecReqProps = {
				patient_uid: patientInfo.info.data_id,
				refillable_uid: data_id,
				medical_date: visitDate,
				opd_uid: currentVisitInfoData.data_id ?? ""
			};

			const result = await callRefillableSpecService(userInfo.token, reqData);

			console.log("日式綠茶 spec result", result);

			if (result.result === "success") {
				updateRefillableDetailInfo({
					refillable_uid: data_id,
					refillable_detail_uid: result.detail_info?.refillable_detail_uid,
					card_no: result.detail_info?.card_no,
					first_date: result.detail_info?.first_date,
					refill_times: result.detail_info?.refill_times,
					get_times: result.detail_info?.get_times,
					lists: result.detail_info?.lists
						? result.detail_info?.lists.map(item => ({
								...item,
								prescription_date: item.prescription_date || null
							}))
						: []
				});
			}
		} else {
			Swal.fire({
				icon: "error",
				title: "無此病患資料"
			});
		}
	};

	const handleChangeDetailInfo = (data_id: string) => {
		callRefillableSpecAPI(data_id);
	};

	return (
		<>
			<Box w={"100%"} sx={InsetShadowBox} pos={"relative"}>
				<PatientInfo />
				<Box w={"100%"} my={"12px"} p={"12px"} rounded={"lg"} bg={"#F5F5F5"}>
					<Tabs minH={"279px"}>
						<TabList>
							{refillableLists && refillableLists.lists.length > 0 ? (
								refillableLists.lists.map(item => (
									<Tab
										key={item.data_id}
										onClick={() => {
											handleChangeDetailInfo(item.data_id);
										}}>
										<Text textStyle={"semiTitle"}>{item.diagnosis_name}</Text>
									</Tab>
								))
							) : (
								<Box pos={"absolute"} top={"50%"} left={"50%"} transform={"translate(-50%, -50%)"}>
									<Text textStyle={"semiTitle"} fontSize={"xl"} textColor={"#ccc"}>
										無資料
									</Text>
								</Box>
							)}
						</TabList>
						<TabPanels>
							{refillableLists && refillableLists.lists.length > 0 ? (
								refillableLists.lists.map((item, index) => (
									<TabPanel key={index}>
										<Record refillable_uid={item.data_id} />
									</TabPanel>
								))
							) : (
								<TabPanel>
									<Text textStyle={"semiTitle"}>無資料</Text>
								</TabPanel>
							)}
						</TabPanels>
					</Tabs>
				</Box>
			</Box>
		</>
	);
};
