import { useEffect, useState } from "react";
import { Input, Checkbox, Flex, Text, Select } from "@chakra-ui/react";
import { relatedSettingDataProps } from "./DrugDataMaintenanceTable";
import { useUnsavedChanges } from "contexts/UnsavedChangesContext";
import { useHospNameAPI } from "service/hospNameServiceHelper";
import { useUserStore } from "store";
import { useDropDownMenuQuery } from "query/useDropDownMenuQuery";
import { deliverExamProps } from "service/gTypeService";
import Swal from "sweetalert2";

interface DrugDataMaintenanceRelatedSetting {
	relatedSettingData: relatedSettingDataProps;
	setRelatedSettingData: React.Dispatch<React.SetStateAction<relatedSettingDataProps>>;
	isEditing: boolean;
}

const DrugDataMaintenanceRelatedSetting = ({ relatedSettingData, setRelatedSettingData, isEditing }: DrugDataMaintenanceRelatedSetting) => {
	const { userInfo } = useUserStore();
	const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();
	const { callHospNameAPI } = useHospNameAPI();

	const [deliverExamLists, setDeliverExamLists] = useState<deliverExamProps[]>([]);

	const { data: deliverExamListsData, isSuccess: isDeliverExamListsSuccess } = useDropDownMenuQuery("deliverExam", userInfo.token!);

	useEffect(() => {
		if (isDeliverExamListsSuccess) {
			setDeliverExamLists(deliverExamListsData.lists);
		}
	}, [isDeliverExamListsSuccess]);

	const handleChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = event.target;
		setHasUnsavedChanges(true);

		setRelatedSettingData((pre: relatedSettingDataProps) => ({
			...pre,
			[name]: value
		}));
	};

	const handleChangeInput = (
		event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> & {
			target: HTMLInputElement;
		}
	) => {
		const { name, value } = event.target;
		if (name === "originPackaging" || name === "reminderForInsuredPartiesToInquire") {
			const { name, checked } = event.target;
			setRelatedSettingData((pre: relatedSettingDataProps) => ({
				...pre,
				[name]: checked ? "1" : "0"
			}));
			setHasUnsavedChanges(true);
		} else if (name === "package_capacity" || name === "package_qty") {
			const regex = /^(?:\d{1,7}(?:\.\d{0,2})?|\.\d{1,2})$/;
			if (regex.test(value))
				setRelatedSettingData((pre: relatedSettingDataProps) => ({
					...pre,
					[name]: value
				}));
			setHasUnsavedChanges(true);
		} else {
			setRelatedSettingData((pre: relatedSettingDataProps) => ({
				...pre,
				[name]: value
			}));
			setHasUnsavedChanges(true);
		}
	};

	const ItemStyle = {
		alignItems: "center",
		flex: window.innerWidth > 1440 ? "0 1 25%" : "0 1 50%",
		gap: "8px",
		pr: "24px"
	};

	const [triggerSearchHospName, setTriggerSearchHospName] = useState(false);

	const handleSearchHospName = async () => {
		// 如果醫事機構代碼是空字串，則不進行查詢

		try {
			const { deliver_exam_lab } = relatedSettingData;

			if (!deliver_exam_lab || deliver_exam_lab.length === 0) {
				setRelatedSettingData(pre => ({
					...pre,
					lab_name: ""
				}));
				return;
			}

			if (deliver_exam_lab.length !== 10) {
				Swal.fire({
					icon: "error",
					title: "醫事機構代碼字數不足",
					text: "請輸入 10 碼數字",
					timer: 1500,
					showConfirmButton: false
				});
				setRelatedSettingData(pre => ({
					...pre,
					lab_name: ""
				}));
				return;
			}

			if (!/^\d+$/.test(deliver_exam_lab)) {
				Swal.fire({
					icon: "error",
					title: "醫事機構代碼內容有誤",
					text: "請輸入數字",
					timer: 1500,
					showConfirmButton: false
				});
				setRelatedSettingData(pre => ({
					...pre,
					lab_name: ""
				}));
				return;
			}
			const result = await callHospNameAPI({ nhid: deliver_exam_lab });
			console.log("callHospNameAPI result", result);
			setRelatedSettingData(pre => ({
				...pre,
				lab_name: result?.hosp_name ?? ""
			}));
		} catch (error) {
			console.error("Error fetching hospital name:", error);
		} finally {
			setTriggerSearchHospName(false);
		}
	};

	useEffect(() => {
		console.log("triggerSearchHospName", triggerSearchHospName);
		if (triggerSearchHospName) {
			handleSearchHospName();
		}
	}, [triggerSearchHospName]);

	return (
		<>
			<Flex rowGap={"24px"} flexWrap={"wrap"}>
				<Flex sx={ItemStyle}>
					<Checkbox
						colorScheme='rsPrimary'
						name='bottle_package'
						id='bottle_package'
						onChange={handleChangeInput}
						isDisabled={!isEditing}
						defaultValue={"0"}
						value={relatedSettingData.bottle_package ?? ""}></Checkbox>
					<Text textStyle={"semiTitle"} flex={"0 0 auto"}>
						<label htmlFor='bottle_package'>原瓶包裝</label>
					</Text>
				</Flex>

				<Flex sx={ItemStyle}>
					<Text color={"rsPrimary.500"} fontWeight={"semibold"} flex={"0 0 auto"}>
						包裝量
					</Text>
					<Input
						name='package_capacity'
						textColor={"#000"}
						disabled={!isEditing}
						value={relatedSettingData.package_capacity ?? ""}
						onChange={handleChangeInput}
						bg={isEditing ? "#fff" : "transparent"}></Input>
				</Flex>

				<Flex sx={ItemStyle}>
					<Text color={"rsPrimary.500"} fontWeight={"semibold"} flex={"0 0 64px"}>
						包裝單位
					</Text>
					<Input
						name='package_unit'
						textColor={"#000"}
						disabled={!isEditing}
						value={relatedSettingData.package_unit ?? ""}
						maxLength={10}
						onChange={handleChangeInput}
						bg={isEditing ? "#fff" : "transparent"}></Input>
				</Flex>

				<Flex sx={ItemStyle}>
					<Text textStyle={"semiTitle"} flex={"0 0 auto"}>
						包裝個數
					</Text>
					<Input
						name='package_qty'
						textColor={"#000"}
						disabled={!isEditing}
						value={relatedSettingData.package_qty ?? ""}
						onChange={handleChangeInput}
						bg={isEditing ? "#fff" : "transparent"}></Input>
				</Flex>

				<Flex sx={ItemStyle}>
					<Checkbox
						name='check_insure_status'
						id='check_insure_status'
						onChange={handleChangeInput}
						isDisabled={!isEditing}
						defaultValue={"0"}
						value={relatedSettingData.check_insure_status ?? ""}></Checkbox>
					<Text textStyle={"semiTitle"} _hover={{ cursor: "pointer" }}>
						<label htmlFor='check_insure_status'>提醒應保險對象查詢</label>
					</Text>
				</Flex>

				<Flex sx={ItemStyle}>
					<Text color={"rsPrimary.500"} fontWeight={"semibold"} flex={"0 0 64px"}>
						DRG碼
					</Text>
					<Input
						name='drg_code'
						textColor={"#000"}
						disabled={!isEditing}
						maxLength={2}
						value={relatedSettingData.drg_code ?? ""}
						onChange={handleChangeInput}
						bg={isEditing ? "#fff" : "transparent"}></Input>
				</Flex>

				<Flex sx={ItemStyle}>
					<Text color={"rsPrimary.500"} fontWeight={"semibold"} flex={"0 0 64px"}>
						成分碼
					</Text>
					<Input
						name='ingredientCode'
						textColor={"#000"}
						disabled={!isEditing}
						value={relatedSettingData.ingredientCode ?? ""}
						onChange={handleChangeInput}
						bg={isEditing ? "#fff" : "transparent"}></Input>
				</Flex>

				<Flex sx={ItemStyle}>
					<Text color={"rsDanger.500"} fontWeight={"semibold"} flex={"0 0 64px"}>
						替代藥品
					</Text>
					<Input
						name='substitute'
						textColor={"#000"}
						bg={isEditing ? "rsDanger.600" : "rsDanger.500"}
						disabled={!isEditing}
						value={relatedSettingData.substitute ?? ""}
						onChange={handleChangeInput}></Input>
				</Flex>

				<Flex sx={{ ...ItemStyle, flex: "0 0 33%" }}>
					<Text color={"rsPrimary.500"} fontWeight={"semibold"} flex={"0 0 80px"}>
						檢驗所代號
					</Text>
					<Input
						textColor={"#000"}
						disabled={!isEditing}
						maxLength={10}
						placeholder='請輸入 10 碼數字'
						name='deliver_exam_lab'
						value={relatedSettingData.deliver_exam_lab ?? ""}
						onChange={handleChangeInput}
						onBlur={() => setTriggerSearchHospName(true)}
						onKeyUp={event => {
							if (event.key === "Enter") {
								setTriggerSearchHospName(true);
							}
						}}
						bg={isEditing ? "#fff" : "transparent"}></Input>
				</Flex>

				<Flex sx={{ ...ItemStyle, flex: "0 0 33%" }}>
					<Text color={"rsPrimary.500"} fontWeight={"semibold"} flex={"0 0 64px"}>
						檢驗所
					</Text>

					<Input textColor={"#000"} readOnly name='lab_name' value={relatedSettingData.lab_name ?? ""} bg={"#fff"} />
				</Flex>

				<Flex sx={{ ...ItemStyle, flex: "0 0 33%" }}>
					<Text color={"rsPrimary.500"} fontWeight={"semibold"} flex={"0 0 64px"}>
						申報方式
					</Text>

					<Select
						textColor={"#000"}
						disabled={!isEditing}
						name='deliver_exam_type'
						value={relatedSettingData.deliver_exam_type ?? ""}
						onChange={handleChangeSelect}
						placeholder='請選擇'
						bg={"#fff"}>
						{deliverExamLists.map(item => (
							<option key={item.code} value={item.code}>
								{item.name}
							</option>
						))}
					</Select>
				</Flex>

				<Flex sx={ItemStyle}>
					<Text color={"rsPrimary.500"} fontWeight={"semibold"} flex={"0 0 64px"}>
						特定部位
					</Text>

					<Select
						textColor={"#000"}
						disabled={!isEditing}
						name='specific_part'
						value={relatedSettingData.specific_part ?? ""}
						onChange={handleChangeSelect}
						placeholder='請選擇'
						bg={"#fff"}>
						<option value='item1'>item1*</option>
						<option value='item2'>item2*</option>
						<option value='item3'>item3*</option>
						<option value='item4'>item4*</option>
					</Select>
				</Flex>

				<Flex sx={ItemStyle}>
					<Text color={"rsPrimary.500"} fontWeight={"semibold"} flex={"0 0 64px"}>
						治療代號
					</Text>
					<Input
						textColor={"#000"}
						disabled={!isEditing}
						name='treatment_code'
						value={relatedSettingData.treatment_code ?? ""}
						onChange={handleChangeInput}
						bg={isEditing ? "#fff" : "transparent"}
						maxLength={2}></Input>
				</Flex>
			</Flex>
		</>
	);
};

export default DrugDataMaintenanceRelatedSetting;
