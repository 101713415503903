import axios from 'axios';
import Swal from 'sweetalert2';

export interface VHCHisWriteTreatmentCodeReqProps {
    pDateTime: string;
    pPatientID: string;
    pPatientBirthDate : string;
    pDataWrite: string;
} 
export interface VHCHisWriteTreatmentCodeResProps {
    ERRORCODE: number;
    pBufferDocID: string;
}
export const postVHChisWriteTreatmentCode10Service = async (data: VHCHisWriteTreatmentCodeReqProps): Promise<VHCHisWriteTreatmentCodeResProps | null> => {
    console.log(data, 'service這邊看看')
    try {
        const finalData = JSON.stringify(data)
        const response = await axios.post('http://localhost:3033/VHC/VHChisWriteTreatmentCode10', finalData, {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
            },
        });
        
        console.log(response.data, '再次要求授權');
        return response.data;
    } catch (error: unknown) {
        if (error instanceof Error) {
            console.error('Error:', error.message);
        } else {
            console.error('An unknown error occurred');
        }
        Swal.fire({
            icon: 'error',  
            text: '虛擬健保卡寫入診斷失敗',
        });
        return null;
    }
};
