import { Button, Textarea } from "@chakra-ui/react";
import { RsGetStokenService } from "service/RsService/RsGetStokenService";
import { useState } from "react";

export const UpdateVirtualCardToken = () => {
    const [sToken, setSToken] = useState<string>('');
    const handleUpdateVirtualCardToken = async () => {
        const result = await RsGetStokenService();
        if (result) {
            setSToken(result.pBuffer);
        }
    }
    return (<>
        <Textarea value={sToken} onChange={(e) => setSToken(e.target.value)} />
        <Button
            onClick={handleUpdateVirtualCardToken}
        >
            更新虛擬卡號
        </Button>
    </>);
};   
