import React from "react";
import { Box, Flex, Text, Input } from "@chakra-ui/react";
export const BeforeTreatment = () => {
	return (
		<>
			<Flex direction={"column"} gap={"4px"}>
				<Flex gap={"4px"} align={"center"}>
					<Text textStyle={"semiTitle"} flexShrink={0}>
						事前審查受理編號
					</Text>
					<Input size={"sm"}></Input>
				</Flex>
				<Flex gap={"4px"} align={"center"}>
					<Text textStyle={"semiTitle"} flexShrink={0}>
						治療項目
					</Text>
					<Input size={"sm"}></Input>
					<Input size={"sm"}></Input>
					<Input size={"sm"}></Input>
					<Input size={"sm"}></Input>
				</Flex>
				<Flex gap={"4px"} align={"center"}>
					<Text textStyle={"semiTitle"} flexShrink={0}>
						OPICD
					</Text>
					<Input size={"sm"}></Input>
				</Flex>
			</Flex>
		</>
	);
};
