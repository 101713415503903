import { create } from "zustand";

interface ICCardUpdateListTabIndexState {
	tabIndex: number;
	setTabIndex: (index: number) => void;
}

export const useICCardUpdateListTabIndexStore = create<ICCardUpdateListTabIndexState>(set => ({
	tabIndex: 0,
	setTabIndex: index => set({ tabIndex: index })
}));
