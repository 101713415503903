import React, { useEffect, useState, useRef } from "react";
import { Box, Flex, Text, Heading, Button } from "@chakra-ui/react";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import "../style/components/animation.css";
import moment from "moment";
import { HeroIcons } from "./CustomComponents/CustomHeroIcons";

import { useLogoutStatus } from "contexts/LogoutStatusContext";
import { useUnsavedChanges } from "contexts/UnsavedChangesContext";
import { useAutoSavedStatus } from "contexts/AutoSavedStatusContext";

import { useNavigationStatus } from "contexts/NavigationStatusContext";
import { useUserStore } from "store";
import NavRegistrationIcon from "./CustomComponents/NavRegistrationIcon";
import NavConsultingRoomIcon from "./CustomComponents/NavConsultingRoomIcon";
import NavICuploadIcon from "./CustomComponents/NavICuploadIcon";
import NavReportIcon from "./CustomComponents/NavReportIcon";
import NavDataSettingIcon from "./CustomComponents/NavDataSettingIcon";
import NavManagementIcon from "./CustomComponents/NavManagementIcon";
import { mainNavItemButton } from "style/chakraStyles";
import { Cookies } from "react-cookie";
import { useCookies } from "react-cookie";
type NavigationItem = {
	navName: string;
	subitem?: string[] | NavigationItem[];
};
type NavigationItem2 = {
	name: string;
	path?: string;
	items?: NavigationItem2[];
	key: string;
};

type HeaderProps = {
	setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
	navigation2?: NavigationItem2[];
};
//這邊很重要 你中文打錯會找不到網頁
// const navigationMapping: { [key: string]: string } = {
//     首頁: 'HomePage',
//     掛號作業: 'RegisteredPage',
//     統計報表: 'StatisticalReportPage',
//     預約明細表: 'AppointmentSchedulePage',
//     預約管理: 'SchedulePage',
//     健康風險管理: 'HealthRiskManagementPage',
//     療程項目設定: 'CourseItemPage',
//     追蹤項目維護: 'TrackProjectMaintenancePage',
//     系統設定: 'SystemPage',
//     療程管理: 'TreatmentRoomComponents',
//     診療作業: 'OPDPage',
//     健檢掛號作業: 'HealthCheckRegistrationPage',
//     檢驗值輸入: 'CheckValueInputPage',
//     健檢報告製作: 'HealthCheckReportProductionPage',
//     健檢套餐設定: 'HealthPackageMaintenancePage',
//     密碼權限設定: 'PasswordPermissionDataPage',
//     會員中心: 'ProfileSettingPage',
//     X光室輸入: 'XRayRoomInputPage',
//     X光室輸入細項: 'XRayRoomDetailPage',
//     醫評維護設定: 'PhysicianAssessmentPage',
//     醫評維護內容設定: 'PhysicianAssessmentFormPage',
//     醫評片語維護設定: 'PhysicianAssessmentPhraseSettingPage',
//     醫評項目維護設定: 'PhysicianAssessmentItemSettingPage',
//     會員註冊: 'MemberPage',
//     院所資料設定: 'MedicalInstitutionDataSettingPage',
//     病名資料維護: 'DiseaseNameDataMaintenancePage',
//     費用設定: 'FeeSettingPage',
//     優免身份設定: 'PreferentialStatusPage',
//     藥品資料維護: 'DrugDataMaintenancePage',
//     IC作業: 'ICCardUpdatePage',
//     人員資料設定: 'PersonnelProfileSettingPage',
//     群組權限設定: 'GroupPermissionSettingPage',
// };

const Header: React.FC<HeaderProps> = ({ setIsLoggedIn, navigation2 }) => {
	const cookies = new Cookies();
	const [setCookie, removeCookie] = useCookies(["token"]);
	const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();
	const { navigationStatus, setNavigationStatus } = useNavigationStatus();
	const { logoutStatus, setLogoutStatus } = useLogoutStatus();
	const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
	const [memberDropdownState, setMemberDropdownState] = useState(false);
	const [hosp_name, setHosp_name] = useState<string>("");
	const [hosp_id, setHosp_id] = useState<string>("");
	const [selectedNavItems, setSelectedNavItems] = useState<Set<string>>(new Set());
	const { autoSavedStatus, setAutoSavedStatus } = useAutoSavedStatus();
	useEffect(() => {
		const hosp_info = localStorage.getItem("hosp_info");
		if (hosp_info) {
			const parsedHospInfo = JSON.parse(hosp_info);
			setHosp_name(parsedHospInfo.hosp_name);
			setHosp_id(parsedHospInfo.hosp_id);
		} else {
		}
	}, []);

	const { resetUserInfo } = useUserStore();

	const navigate = useNavigate();
	const handleToggleMobileMenu = () => {
		setIsMobileMenuVisible(true);
	};

	const handleToggleSubMenu = (navName: string) => {
		setSelectedNavItems(prevSelectedNavItems => {
			const newSelectedNavItems = new Set(prevSelectedNavItems);

			if (newSelectedNavItems.has(navName)) {
				newSelectedNavItems.delete(navName);
			} else {
				newSelectedNavItems.clear();
				newSelectedNavItems.add(navName);
			}

			setIsMobileMenuVisible(newSelectedNavItems.size > 0);

			return newSelectedNavItems;
		});
	};

	const handleToggleSubMenuClose = () => {
		setSelectedNavItems(new Set());
		setIsMobileMenuVisible(false);
	};
	const logout = async () => {
		setMemberDropdownState(false);
		localStorage.removeItem("isLoggedIn");
		localStorage.removeItem("token");
		localStorage.removeItem("lastPathname");
		cookies.remove("token");
		// removeCookie('token', { path: '/' });
		resetUserInfo();
		Swal.fire({
			icon: "success",
			title: "登出成功",
			showConfirmButton: false,
			timer: 1500
		});
		setTimeout(() => {
			setIsLoggedIn(false);
			navigate("/");
		}, 1500);
		setHasUnsavedChanges(false);
	};
	const handleLogout = () => {
		if (hasUnsavedChanges) {
			Swal.fire({
				title: "您有尚未儲存的資料！",
				text: "如果離開，您的更改將會遺失。",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "離開",
				cancelButtonText: "取消"
			}).then(result => {
				if (result.isConfirmed) {
					logout();
				}
			});
		} else {
			logout();
		}
	};
	const location = useLocation();

	const memberDropdownRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		const handleClickOutside3 = (event: MouseEvent) => {
			if (memberDropdownRef.current && !memberDropdownRef.current.contains(event.target as Node)) {
				setMemberDropdownState(false);
			}

			if (event.target instanceof Element && !event.target.closest(".menu-item")) {
				setSelectedNavItems(new Set());
			}
		};

		// 使用 'click' 而不是 'mousedown'
		document.addEventListener("click", handleClickOutside3);

		return () => {
			document.removeEventListener("click", handleClickOutside3);
		};
	}, []);

	const [newNavigation2, setNewNavigation2] = useState<NavigationItem2[]>();
	useEffect(() => {
		if (navigation2) {
			const transformData = () => {
				return Object.entries(navigation2).map(([key, item]) => {
					if (!item.key) {
						if (item.items) {
							item.items = Object.entries(item.items).map(([subKey, subItem]) => {
								if (!subItem.key) {
									return { ...subItem, key: subKey };
								}
								return subItem;
							});
						}
						return { ...item, key };
					}
					return item;
				});
			};
			setNewNavigation2(transformData);
			setNavigationStatus(transformData);
		}
	}, [navigation2]);

	const handleGoToAccount = () => {
		if (hasUnsavedChanges) {
			Swal.fire({
				title: "您有尚未儲存的資料！",
				text: "如果離開，您的更改將會遺失。",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "離開",
				cancelButtonText: "取消"
			}).then(result => {
				if (result.isConfirmed) {
					navigate("/ProfileSettingPage");
					setMemberStatus(false);
					setHasUnsavedChanges(false);
				}
			});
		} else {
			setMemberStatus(false);
			navigate("/ProfileSettingPage");
		}

		setMemberDropdownState(false);
	};
	const handleGoToHome = () => {
		if (hasUnsavedChanges) {
			Swal.fire({
				title: "您有尚未儲存的資料！",
				text: "如果離開，您的更改將會遺失。",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "離開",
				cancelButtonText: "取消"
			}).then(result => {
				if (result.isConfirmed) {
					setMemberStatus(false);
					navigate("/HomePage");
				}
			});
		} else {
			setMemberStatus(false);
			navigate("/HomePage");
		}
	};
	const handleLinkClick2 = (path: string, event: React.MouseEvent) => {
		event.preventDefault();
		const currentPath = location.pathname.replace(/\//g, "");

		const targetPage = path.replace(/\//g, "");
		navigate(path);
		setOpenSubItems(false);
		setOpenSubitemIndex(-1);
	};
	// 判斷是否可以直接去下個頁面還是要阻擋問問題
	const handleLinkClick = (path: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault();
		const currentPath = location.pathname.replace(/\//g, "");

		const targetPage = path.replace(/\//g, "");
		navigate(path);
		setOpenSubItems(false);
		setOpenSubitemIndex(-1);
	};
	const [openSubItems, setOpenSubItems] = useState<boolean>(false);
	const [openSubitemIndex, setOpenSubitemIndex] = useState<number>(-1);
	const headerRef = useRef<HTMLDivElement>(null);

	const handleOpenSubItems = (index: number) => () => {
		if (openSubitemIndex === index) {
			setOpenSubitemIndex(-1);
			// setOpenSubitemIndex(index);
			setOpenSubItems(true);
		} else {
			// 否则设置为当前索引
			setOpenSubitemIndex(index);
			setOpenSubItems(true);
		}
	};
	const handleClickOutside2 = (event: MouseEvent) => {
		if (headerRef.current && !headerRef.current.contains(event.target as Node)) {
			setOpenSubitemIndex(-1);
		}
	};
	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside2);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside2);
		};
	}, []);

	const [memberStatus, setMemberStatus] = useState<boolean>(false);
	const memberStatusRef = useRef<HTMLDivElement>(null);
	const handleOpenMember = (event: React.MouseEvent) => {
		event.stopPropagation();
		setMemberStatus(true);
	};

	useEffect(() => {
		const handleClickOutside3 = (event: MouseEvent) => {
			if (memberStatusRef.current && !memberStatusRef.current.contains(event.target as Node)) {
				setMemberStatus(false);
			}

			if (event.target instanceof Element && !event.target.closest(".menu-item")) {
				setSelectedNavItems(new Set());
			}
		};

		// 使用 'click' 而不是 'mousedown'
		document.addEventListener("click", handleClickOutside3);

		return () => {
			document.removeEventListener("click", handleClickOutside3);
		};
	}, [memberStatus]);

	const [currentRocDate, setCurrentRocDate] = useState("000 年 0月 00日");
	const [currentWeek, setCurrentWeek] = useState("星期Ｘ");
	useEffect(() => {
		const interval = setInterval(() => {
			const now = new Date();
			const time = moment(now).format("h:mm:ss");
			const ampm = moment(now).format("a");
			const year = moment(now).format("YYYY");
			const month = moment(now).format("M");
			const day = moment(now).format("D");
			const weekday = moment(now).format("dddd");

			const RocYear = parseInt(year) - 1911;
			const RocDate = `${RocYear} 年 ${month} 月 ${day} 日  `;

			setCurrentRocDate(RocDate);
			setCurrentWeek(weekday);
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<>
			<Box
				id='main-nav'
				pos={"fixed"}
				top={0}
				left={0}
				w={"100%"}
				zIndex={"50"}
				bg={"rsPrimary.900"}
				px={{ base: "20px", lg: "40px" }}>
				<Flex justify={"space-between"} align={"center"} flexWrap={"wrap"}>
					<Flex align={"center"} gap={"24px"}>
						{hosp_name && hosp_id && (
							<>
								<Heading
									_hover={{ cursor: "pointer" }}
									onClick={handleGoToHome}
									textColor={"#fff"}
									size={"sm"}
									py={"10px"}
									fontSize={"18px"}
									lineHeight={"20px"}
									fontWeight={"normal"}>
									<Text as={"span"}>{hosp_name}</Text>
									<Text as={"small"} mx={"12px"}>
										{hosp_id}
									</Text>
								</Heading>
							</>
						)}
					</Flex>
					<Flex align={"center"} justifyContent={"flex-end"} gap={"16px"}>
						{newNavigation2 && (
							<>
								<Flex
									ref={headerRef}
									align={"center"}
									display={{ base: "none", lg: "flex" }}
									className={`${isMobileMenuVisible ? "hidden" : ""}`}
									rounded={"4px"}>
									{newNavigation2.map((item, index) => (
										<React.Fragment key={index}>
											<Box pos='relative' h={"32px"}>
												{item.items ? (
													<>
														{/* 有第二層的項目 */}
														<Button
															sx={mainNavItemButton}
															variant={"ghost"}
															size={"sm"}
															boxShadow={
																index === 0
																	? "inset 4px 2px 2px rgba(0, 0, 0, 0.6)"
																	: "inset 0px 2px 2px rgba(0, 0, 0, 0.6)"
															}
															onClick={item.items ? handleOpenSubItems(index) : undefined}>
															{item.name === "掛號管理" && <NavRegistrationIcon />}
															{item.name === "診療管理" && <NavConsultingRoomIcon />}
															{item.name === "IC上傳" && <NavICuploadIcon />}
															{item.name === "統計報表" && <NavReportIcon />}
															{item.name === "資料設定" && <NavDataSettingIcon />}
															{item.name === "行政管理" && <NavManagementIcon />}
															<Box as='span' ml={"4px"}>
																{item.name}
															</Box>
															{item.name === "掛號管理" ||
															item.name === "診療管理" ||
															item.name === "資料設定" ? (
																<Box ml='4px' fontWeight={"semibold"}>
																	<HeroIcons name='chevron-down-sm'></HeroIcons>
																</Box>
															) : (
																""
															)}
														</Button>

														{/* 渲染第二層 */}
														<Box
															pos={"absolute"}
															top={"100%"}
															left={"50%"}
															w={"200px"}
															textAlign={"center"}
															transform={"translate(-50%, 1px)"}
															bgColor={"rsPrimary.900"}
															boxShadow={"2xl"}>
															{openSubitemIndex === index &&
																openSubItems &&
																item.items.map((subItem, subIndex) => (
																	<a
																		href={`${subItem.path}`}
																		key={subIndex}
																		onClick={e => handleLinkClick(`${subItem.path}`, e)}>
																		<Text
																			py={"12px"}
																			color={"#fff"}
																			_hover={{
																				bgColor: "#ffffff11",
																				boxShadow: "inset 0px -5px 10px   #D2D2B355"
																			}}>
																			{subItem.name}
																		</Text>
																	</a>
																))}
														</Box>
													</>
												) : (
													<>
														{/* 渲染第一層的項目 */}
														<Button
															sx={mainNavItemButton}
															variant={"ghost"}
															size={"sm"}
															boxShadow={
																index === 0
																	? "inset 4px 2px 2px rgba(0, 0, 0, 0.6)"
																	: "inset 0px 2px 2px rgba(0, 0, 0, 0.6)"
															}
															key={item.key}
															onClick={e => handleLinkClick2(`${item.path}`, e)}>
															{item.name === "掛號管理" && <NavRegistrationIcon />}
															{item.name === "診療管理" && <NavConsultingRoomIcon />}
															{item.name === "IC上傳" && <NavICuploadIcon />}
															{item.name === "統計報表" && <NavReportIcon />}
															{item.name === "資料設定" && <NavDataSettingIcon />}
															{item.name === "行政管理" && <NavManagementIcon />}

															<Box as='span' ml={"4px"}>
																{item.name}
															</Box>
														</Button>
													</>
												)}
											</Box>
										</React.Fragment>
									))}
								</Flex>
							</>
						)}

						<Text textColor={"#fff"} w={"191px"} textAlign={"center"}>
							{currentRocDate} {currentWeek}
						</Text>

						{/* User */}
						<Flex
							justify={"center"}
							align={"center"}
							pos={"relative"}
							onClick={(event: React.MouseEvent) => handleOpenMember(event)}
							_hover={{ cursor: "pointer" }}
							textColor={"#fff"}>
							<Box opacity={0.8} _hover={{ cursor: "pointer", opacity: 1 }}>
								<HeroIcons name='user-circle'></HeroIcons>
							</Box>
							{memberStatus && (
								<>
									<Box
										textColor={"#fff"}
										pos={"absolute"}
										top={"100%"}
										left={"50%"}
										transform={"translate(-50%, 1px)"}
										ref={memberStatusRef}
										bgColor={"rsPrimary.900"}
										w={"200px"}
										textAlign={"center"}>
										<Text
											py={"12px"}
											color={"#fff"}
											_hover={{
												bgColor: "#ffffff11",
												boxShadow: "inset 0px -5px 10px   #D2D2B355"
											}}
											onClick={handleGoToAccount}>
											編輯個人資料
										</Text>

										<Text
											py={"12px"}
											color={"#fff"}
											_hover={{
												bgColor: "#ffffff11",
												boxShadow: "inset 0px -5px 10px   #D2D2B355"
											}}
											onClick={handleLogout}>
											登出
										</Text>
									</Box>
								</>
							)}
						</Flex>
					</Flex>
				</Flex>

				{/* {isMobileMenuVisible && (
                    <div
                        className="mobile-menu lg:hidden absolute w-full bg-[#A4AC86] shadow-lg top-full left-0"
                        onClick={handleToggleMobileMenu}
                    >
                        <div className="flex flex-col items-start p-5">
                            {navigation?.map((navItem, index) => (
                                <React.Fragment key={index}>
                                    {navItem.subitem ? (
                                        <div
                                            className={` text-lg cursor-pointer relative text-white hover:text-[#DDDBBA] mb-4 ${
                                                selectedNavItems.has(
                                                    navItem.navName
                                                )
                                                    ? 'menu-item'
                                                    : ''
                                            }`}
                                            onClick={() =>
                                                handleToggleSubMenu(
                                                    navItem.navName
                                                )
                                            }
                                        >
                                            {navItem.navName}

                                            {selectedNavItems.has(
                                                navItem.navName
                                            ) && (
                                                <div className="absolute top-0 left-full translate-x-3 w-48 bg-[#949c79] rounded-md shadow-lg  p-2 text-center flex flex-col z-50">
                                                    {navItem.subitem.map(
                                                        (subitem, index) => (
                                                            <React.Fragment
                                                                key={index}
                                                            >
                                                                {typeof subitem ===
                                                                'string' ? (
                                                                    <Link
                                                                        to={`/${navigationMapping[subitem]}`}
                                                                        className="mobile-menu-link"
                                                                    >
                                                                        <div className="text-lg cursor-pointer flex items-center justify-center text-white">
                                                                            {
                                                                                subitem
                                                                            }
                                                                        </div>
                                                                    </Link>
                                                                ) : (
                                                                    <Link
                                                                        to={`/${
                                                                            navigationMapping[
                                                                                subitem
                                                                                    .navName
                                                                            ]
                                                                        }`}
                                                                        className="mobile-menu-link"
                                                                    >
                                                                        <div className="text-lg cursor-pointer flex items-center justify-center hover:text-[#DDDBBA] text-[white]">
                                                                            {
                                                                                subitem.navName
                                                                            }
                                                                        </div>
                                                                    </Link>
                                                                )}
                                                            </React.Fragment>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <Link
                                            to={`/${
                                                navigationMapping[
                                                    navItem.navName
                                                ]
                                            }`}
                                            className="mobile-menu-link"
                                        >
                                            <div className="text-lg cursor-pointer  hover:text-[#DDDBBA] text-white mb-4">
                                                {navItem.navName}
                                            </div>
                                        </Link>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                )} */}
			</Box>
		</>
	);
};

export default Header;
