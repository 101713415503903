import apiService from "./apiService";

type dataProps = {
	ids: string[];
};
// 24. 更新 IC 資料為已上傳
export const postIcDataUploadResultService = async (token: string, data: dataProps) => {
	console.log("準備上車的 ids: ", data);
	return apiService<dataProps, any>({
		method: "POST",
		path: "/api/ic_data/upload_result",
		token,
		data: data
	});
};
