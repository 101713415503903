/* Hooks */
import React, { useState, useRef, useEffect, Dispatch } from "react";
import { Button, Input, Select, Radio, RadioGroup, Stack, Flex, InputGroup, Text, Box, Grid } from "@chakra-ui/react";
import CloseIcon from "../../CustomComponents/CloseIcon";
import Swal from "sweetalert2";
import { callCustomerService } from "service/customerService";
import { postCustomerService } from "service/customerService";
import { callGTypePrescriptionStyleService } from "service/gTypeService";
import { callGTypeMedicalAffairsTypeService } from "service/gTypeService";
import { useUnsavedChanges } from "contexts/UnsavedChangesContext";
import { useUserStore } from "store";
import { useDropDownMenuQuery } from "query/useDropDownMenuQuery";
import { deliverExamProps } from "service/gTypeService";

import { useHospNameAPI } from "service/hospNameServiceHelper";

interface medicalAffairsTypeListsProps {
	name: string;
	code: string;
}

interface prescriptionStyleListsProps {
	name: string;
	code: string;
}
interface basicInputDataProps {
	customer_uid: string | null;
	hosp_id: string | null;
	nhid: string | null;
	branch_uid: string | null;
	hosp_name: string | null;
	institution_type: string | null;
	business_license: string | null;
	uniform_numbers: string | null;
	tel_area_code: string | null;
	tel_number: string | null;
	address: string | null;
	zip: string | null;
	prescription_style: string | null;
	responsible_doctor: string | null;
	doctor_idno: string | null;
	pharmacist: string | null;
	pharmacist_idno: string | null;
	deliver_exam_type: string | null;
	deliver_exam_lab: string | null;
	lab_name: string | null;
	ot_admin_account: string | null;
	ot_admin_password: string | null;
}
interface BasicSettingEditStatusProps {
	basicSettingEditStatus: boolean;
	setBasicSettingEditStatus: React.Dispatch<React.SetStateAction<boolean>>;
}
const MedicalInstitutionBasicSetting = ({ basicSettingEditStatus, setBasicSettingEditStatus }: BasicSettingEditStatusProps) => {
	const { userInfo } = useUserStore();

	const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

	const outerDivRef = useRef<HTMLDivElement | null>(null);

	const [deliverExamLists, setDeliverExamLists] = useState<deliverExamProps[]>([]);

	const { data: deliverExamListsData, isSuccess: isDeliverExamListsSuccess } = useDropDownMenuQuery("deliverExam", userInfo.token!);

	useEffect(() => {
		if (isDeliverExamListsSuccess) {
			setDeliverExamLists(deliverExamListsData.lists);
		}
	}, [isDeliverExamListsSuccess]);

	const { callHospNameAPI } = useHospNameAPI();

	const [basicInputData, setBasicInputData] = useState<basicInputDataProps>({
		customer_uid: null, // 資料UUID
		hosp_id: null, // 客戶編號
		nhid: null, // 醫事機構代碼
		business_license: null, // 開業執照
		branch_uid: null, // 服務地區
		hosp_name: null, // 診所名字
		institution_type: null, // 院所層級
		uniform_numbers: null, // 統一編號
		tel_area_code: null, // 電話區碼
		tel_number: null, //聯絡電話
		address: null, // 通訊地址
		zip: null, // 郵遞區號
		prescription_style: null, // 調劑方式
		responsible_doctor: null, // 負責醫師
		doctor_idno: null, // 負責醫師身分證
		pharmacist: null, // 調劑藥師
		pharmacist_idno: null, //調劑藥師身分證
		deliver_exam_type: null, // 檢驗申報方式
		deliver_exam_lab: null, // 檢驗所醫事機構代碼
		lab_name: null, // 檢驗所名稱
		ot_admin_account: null,
		ot_admin_password: null
	});

	const fetchData = async () => {
		if (userInfo.token) {
			try {
				const result = await callCustomerService(userInfo.token);
				if (result.result === "success") {
					setBasicInputData({
						...result.info,
						prescription_style: result.info.prescription_style?.toString() || null,
						deliver_exam_type: result.info.deliver_exam_type?.toString() || null
					});
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500,
					showConfirmButton: false
				});
			}
		}
	};

	// 複製一份 inputData
	const prevData = useRef<basicInputDataProps>({
		...basicInputData
	});

	// 調劑方式
	const handleChangePrescriptionStyle: (nextValue: string) => void = nextValue => {
		const value = nextValue;
		setBasicInputData(prevData => ({
			...prevData,
			prescription_style: value
		}));
		setHasUnsavedChanges(true);
	};

	const [prescriptionStyleLists, setPrescriptionStyleLists] = useState<prescriptionStyleListsProps[]>([]);

	const fetchPrescriptionStyle = async () => {
		if (userInfo.token) {
			try {
				const result = await callGTypePrescriptionStyleService(userInfo.token);
				if (result.result === "success") {
					setPrescriptionStyleLists(result.lists);
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500,
					showConfirmButton: false
				});
			}
		} else {
		}
	};

	// 機構列表
	const [medicalAffairsTypeLists, setMedicalAffairsTypeLists] = useState<medicalAffairsTypeListsProps[]>([]);

	// 取得機構型態陣列
	const fetchInstitutionType = async () => {
		if (userInfo.token) {
			try {
				const result = await callGTypeMedicalAffairsTypeService(userInfo.token);
				if (result.result === "success") {
					setMedicalAffairsTypeLists(result.lists);
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500,
					showConfirmButton: false
				});
			}
		}
	};

	// 起始呼叫取得資料
	useEffect(() => {
		fetchData();
		fetchInstitutionType();
		fetchPrescriptionStyle();
	}, []);

	// 編輯按鈕 //
	const handleEdit = () => {
		setBasicSettingEditStatus(false);
		prevData.current = { ...basicInputData };
	};

	const handleCancel = () => {
		if (hasUnsavedChanges) {
			Swal.fire({
				title: "資料尚未儲存，是否要離開？",
				icon: "question",
				showCancelButton: true,
				confirmButtonText: "是",
				cancelButtonText: "否"
			}).then(result => {
				if (result.isConfirmed) {
					setBasicInputData({ ...prevData.current });
					setBasicSettingEditStatus(true);
					setHasUnsavedChanges(false);
				}
				return;
			});
		} else {
			setBasicSettingEditStatus(true);
		}
	};

	// 比較兩個物件
	function deepEqual(obj1: any, obj2: any) {
		if (obj1 === obj2) {
			return true;
		}

		if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
			return false;
		}

		const keys1 = Object.keys(obj1);
		const keys2 = Object.keys(obj2);

		if (keys1.length !== keys2.length) {
			return false;
		}

		for (const key of keys1) {
			if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
				return false;
			}
		}

		return true;
	}

	// 用法示例
	const handleSave = () => {
		if (deepEqual(prevData.current, basicInputData)) {
			setBasicSettingEditStatus(true);
			setHasUnsavedChanges(false);
			return;
		} else {
			if (basicInputData.nhid && basicInputData.nhid?.length < 10) {
				Swal.fire({
					icon: "error",
					title: "儲存失敗，醫事機構代���字數有誤",
					confirmButtonText: "好"
				});

				return;
			}
			const postData = async () => {
				if (userInfo.token && basicInputData) {
					try {
						const result = await postCustomerService(userInfo.token, basicInputData);
						if (result.result === "success") {
							Swal.fire({
								icon: "success",
								title: "儲存成功",
								showConfirmButton: false,
								timer: 1500
							});
							fetchData();
							setHasUnsavedChanges(false);
							setBasicSettingEditStatus(true);
						} else {
							Swal.fire({
								icon: "error",
								title: " 儲存失敗",
								showConfirmButton: false, // 隱藏確認按鈕
								timer: 1500 // 設定自動關閉的時間 (1.5 秒)
							});
						}
					} catch {
						Swal.fire({
							icon: "error",
							title: "後端服務錯誤",
							timer: 1500,
							showConfirmButton: false
						});
					}
				}
			};

			postData();
		}
	};

	const [triggerSearchHospName, setTriggerSearchHospName] = useState(false);

	const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = event.target;

		if (name === "deliver_exam_lab") {
			if (value.length === 10) {
				setTriggerSearchHospName(true);
			} else {
				setTriggerSearchHospName(false);
			}
		}

		if (name === "uniform_numbers" || name === "tel_area_code" || name === "tel_number" || name === "zip") {
			if (/^[0-9]*$/.test(value)) {
				setBasicInputData(pre => ({
					...pre,
					[name]: value
				}));
			}
		} else {
			setBasicInputData(pre => ({
				...pre,
				[name]: value
			}));
		}

		setHasUnsavedChanges(true);
	};

	const handleSearchHospName = async () => {
		// 如果醫事機構代碼是空字串，則不進行查詢

		try {
			const { deliver_exam_lab } = basicInputData;

			if (!deliver_exam_lab || deliver_exam_lab.length === 0) {
				setBasicInputData(pre => ({
					...pre,
					lab_name: ""
				}));
				return;
			}

			if (deliver_exam_lab.length !== 10) {
				Swal.fire({
					icon: "error",
					title: "醫事機構代碼字數不足",
					text: "請輸入 10 碼數字",
					timer: 1500,
					showConfirmButton: false
				});
				setBasicInputData(pre => ({
					...pre,
					lab_name: ""
				}));
				return;
			}

			if (!/^\d+$/.test(deliver_exam_lab)) {
				Swal.fire({
					icon: "error",
					title: "醫事機構代碼內容有誤",
					text: "請輸入數字",
					timer: 1500,
					showConfirmButton: false
				});
				setBasicInputData(pre => ({
					...pre,
					lab_name: ""
				}));
				return;
			}
			const result = await callHospNameAPI({ nhid: deliver_exam_lab });
			console.log("callHospNameAPI result", result);
			setBasicInputData(pre => ({
				...pre,
				lab_name: result?.hosp_name ?? ""
			}));
		} catch (error) {
			console.error("Error fetching hospital name:", error);
		} finally {
			setTriggerSearchHospName(false);
		}
	};

	useEffect(() => {
		console.log("triggerSearchHospName", triggerSearchHospName);
		if (triggerSearchHospName) {
			handleSearchHospName();
		}
	}, [triggerSearchHospName]);

	useEffect(() => {
		console.log("調劑方式清單 ", prescriptionStyleLists);
	}, [prescriptionStyleLists]);

	useEffect(() => {
		console.log("調劑方式：", basicInputData.prescription_style, typeof basicInputData.prescription_style);
	}, [basicInputData.prescription_style]);

	return (
		<>
			<Flex direction={"column"} gap={2}>
				<Flex align={"center"} gap={5}>
					<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
						客戶編號
					</Text>
					<Text textColor={"rsPrimary.500"}>{basicInputData.hosp_id ?? ""}</Text>
					<Button colorScheme='rsWarning' onClick={handleEdit} size={"sm"} isDisabled={!basicSettingEditStatus}>
						編輯
					</Button>
				</Flex>

				<Grid templateColumns='repeat(2, 1fr)' rowGap={3} columnGap={5}>
					<Flex align={"center"} gap={5}>
						<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
							院所名稱
						</Text>
						<Input
							name='hosp_name'
							maxLength={10}
							value={basicInputData.hosp_name ?? ""}
							onChange={basicSettingEditStatus ? undefined : handleChangeInput}
							bgColor={basicSettingEditStatus ? "transparent" : "#fff"}
							readOnly={basicSettingEditStatus}
						/>
					</Flex>
					<Flex align={"center"} gap={5}>
						<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
							醫事機構代碼
						</Text>
						<Input
							// maxLength={10}
							name='nhid'
							value={basicInputData.nhid ?? ""}
							onChange={basicSettingEditStatus ? undefined : handleChangeInput}
							bgColor={basicSettingEditStatus ? "transparent" : "#fff"}
							readOnly={basicSettingEditStatus}
						/>
					</Flex>

					<Flex align={"center"} gap={5}>
						<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
							開業執照
						</Text>
						<Input
							name='business_license'
							maxLength={30}
							value={basicInputData.business_license ?? ""}
							onChange={basicSettingEditStatus ? undefined : handleChangeInput}
							bgColor={basicSettingEditStatus ? "transparent" : "#fff"}
							readOnly={basicSettingEditStatus}
						/>
					</Flex>

					<Flex align={"center"} gap={5}>
						<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
							院所層級
						</Text>
						<Select
							value={basicInputData.institution_type || ""}
							name='institution_type'
							onChange={basicSettingEditStatus ? undefined : handleChangeInput}
							bgColor={basicSettingEditStatus ? "transparent" : "#fff"}>
							<option value='' disabled>
								請選擇
							</option>

							{medicalAffairsTypeLists &&
								medicalAffairsTypeLists.map(item => (
									<option key={item.code} value={item.code}>
										{item.name}
									</option>
								))}
						</Select>
					</Flex>
					<Flex align={"center"} gap={5}>
						<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
							統一編號
						</Text>

						<Input
							maxLength={8}
							name='uniform_numbers'
							value={basicInputData.uniform_numbers ?? ""}
							onChange={basicSettingEditStatus ? undefined : handleChangeInput}
							bgColor={basicSettingEditStatus ? "transparent" : "#fff"}
							readOnly={basicSettingEditStatus}
						/>
					</Flex>

					<Flex align={"center"} gap={5}>
						<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
							聯絡電話
						</Text>
						<InputGroup>
							<Input
								w={"120px"}
								value={basicInputData.tel_area_code ?? ""}
								placeholder='電話區碼'
								maxLength={3}
								borderRadius={"6px 0px 0px 6px"}
								name='tel_area_code'
								borderRight={"none"}
								onChange={basicSettingEditStatus ? undefined : handleChangeInput}
								bgColor={basicSettingEditStatus ? "transparent" : "#fff"}
								readOnly={basicSettingEditStatus}></Input>
							<Input
								maxLength={8}
								value={basicInputData.tel_number ?? ""}
								name='tel_number'
								borderRadius={"0px 6px 6px 0px"}
								placeholder={"填寫電話"}
								onChange={basicSettingEditStatus ? undefined : handleChangeInput}
								bgColor={basicSettingEditStatus ? "transparent" : "#fff"}
								readOnly={basicSettingEditStatus}
							/>
						</InputGroup>
					</Flex>

					<Flex align={"center"} gap={5} className='lg:col-span-2'>
						<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
							通訊地址
						</Text>
						<InputGroup>
							<Input
								w={"120px"}
								borderRight={"none"}
								maxLength={6}
								value={basicInputData.zip ?? ""}
								name='zip'
								placeholder={"郵遞區號"}
								borderRadius={"6px 0px 0px 6px"}
								onChange={basicSettingEditStatus ? undefined : handleChangeInput}
								bgColor={basicSettingEditStatus ? "transparent" : "#fff"}
								readOnly={basicSettingEditStatus}></Input>

							<Input
								maxLength={100}
								value={basicInputData.address ?? ""}
								name='address'
								borderRadius={"0px 6px 6px 0px"}
								placeholder='填寫地址'
								onChange={basicSettingEditStatus ? undefined : handleChangeInput}
								bgColor={basicSettingEditStatus ? "transparent" : "#fff"}
								readOnly={basicSettingEditStatus}
							/>
						</InputGroup>
					</Flex>

					<Flex align={"center"} gap={5}>
						<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
							負責醫師
						</Text>
						<Input
							maxLength={50}
							value={basicInputData.responsible_doctor ?? ""}
							name='responsible_doctor'
							onChange={basicSettingEditStatus ? undefined : handleChangeInput}
							bgColor={basicSettingEditStatus ? "transparent" : "#fff"}
							readOnly={basicSettingEditStatus}
						/>
					</Flex>
					<Flex align={"center"} gap={5}>
						<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
							負責醫師身分證
						</Text>
						<Input
							maxLength={10}
							value={basicInputData.doctor_idno ?? ""}
							name='doctor_idno'
							onChange={basicSettingEditStatus ? undefined : handleChangeInput}
							bgColor={basicSettingEditStatus ? "transparent" : "#fff"}
							readOnly={basicSettingEditStatus}
						/>
					</Flex>

					<Flex align={"center"} gap={5} className='lg:col-span-2'>
						<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
							調劑方式
						</Text>

						<RadioGroup
							value={
								basicInputData.prescription_style
									? prescriptionStyleLists.find(item => item.code === basicInputData.prescription_style)?.code || ""
									: ""
							}
							display={"flex"}
							gap={"32px"}
							w={"100%"}
							name='prescription_style'
							onChange={basicSettingEditStatus ? undefined : handleChangePrescriptionStyle}>
							{prescriptionStyleLists &&
								Array.isArray(prescriptionStyleLists) &&
								prescriptionStyleLists.map(item => {
									return (
										<Stack flex={"0 1 auto"} key={item.code} direction='row' h={"40px"}>
											<Radio value={item.code} key={item.code} isDisabled={basicSettingEditStatus}>
												{item.name}
											</Radio>
										</Stack>
									);
								})}
						</RadioGroup>
					</Flex>

					<Flex align={"center"} gap={5} opacity={basicInputData.prescription_style?.toString() !== "2" ? "0.5" : "1"}>
						<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
							調劑藥師
						</Text>
						<Box pos={"relative"}>
							<Input
								disabled={basicInputData.prescription_style !== "2"}
								maxLength={50}
								value={basicInputData.pharmacist ?? ""}
								name='pharmacist'
								onChange={basicSettingEditStatus ? undefined : handleChangeInput}
								bgColor={basicSettingEditStatus ? "transparent" : "#fff"}
								readOnly={basicSettingEditStatus}
							/>
							{/* Demo */}
							<Text as={"small"} textColor={"rsWarning.500"} pos={"absolute"} left={"0"} top={"100%"}>
								{/* 異動日期：{'113.3.7'} */}
							</Text>
						</Box>
					</Flex>
					<Flex align={"center"} gap={5} opacity={basicInputData.prescription_style?.toString() !== "2" ? "0.5" : "1"}>
						<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
							調劑藥師身分證
						</Text>
						<Box>
							<Input
								disabled={basicInputData.prescription_style !== "2"}
								maxLength={10}
								value={basicInputData.pharmacist_idno ?? ""}
								name='pharmacist_idno'
								onChange={basicSettingEditStatus ? undefined : handleChangeInput}
								bgColor={basicSettingEditStatus ? "transparent" : "#fff"}
								readOnly={basicSettingEditStatus}
							/>
						</Box>
					</Flex>

					<Flex align={"center"} gap={5} className='lg:col-span-2'>
						<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
							檢驗申報
						</Text>
						<RadioGroup
							display={"flex"}
							gap={"32px"}
							w={"100%"}
							h={"40px"}
							name='deliver_exam_type'
							onChange={value => {
								if (!basicSettingEditStatus) {
									setBasicInputData(prev => ({
										...prev,
										deliver_exam_type: value
									}));
									setHasUnsavedChanges(true);
								}
							}}
							value={basicInputData.deliver_exam_type || ""}>
							{deliverExamLists &&
								deliverExamLists.map(item => (
									<Radio key={item.code} value={item.code}>
										{item.name}
									</Radio>
								))}
						</RadioGroup>
					</Flex>

					<Flex align={"center"} gap={5}>
						<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
							檢驗所代號
						</Text>
						<Input
							maxLength={10}
							placeholder='請輸入 10 碼數字'
							value={basicInputData.deliver_exam_lab ?? ""}
							name='deliver_exam_lab'
							onChange={basicSettingEditStatus ? undefined : handleChangeInput}
							bgColor={basicSettingEditStatus ? "transparent" : "#fff"}
							readOnly={basicSettingEditStatus}
							onBlur={() => setTriggerSearchHospName(true)}
							onKeyUp={event => {
								if (event.key === "Enter") {
									setTriggerSearchHospName(true);
								}
							}}
						/>
					</Flex>

					<Flex align={"center"} gap={5}>
						<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
							檢驗所
						</Text>
						<Input
							value={basicInputData.lab_name ?? ""}
							name='lab_name'
							bgColor={basicSettingEditStatus ? "transparent" : "#fff"}
							readOnly={true}
						/>
					</Flex>

					<Flex align={"center"} gap={5}>
						<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
							視訊診療管理者帳號
						</Text>
						<Input
							// maxLength={10}
							value={basicInputData.ot_admin_account ?? ""}
							name='ot_admin_account'
							onChange={basicSettingEditStatus ? undefined : handleChangeInput}
							bgColor={basicSettingEditStatus ? "transparent" : "#fff"}
							readOnly={basicSettingEditStatus}
						/>
					</Flex>
					<Flex align={"center"} gap={5}>
						<Text textStyle={"semiTitle"} whiteSpace={"nowrap"}>
							視訊診療管理者密碼
						</Text>
						<Input
							// maxLength={10}
							value={basicInputData.ot_admin_password ?? ""}
							name='ot_admin_password'
							onChange={basicSettingEditStatus ? undefined : handleChangeInput}
							bgColor={basicSettingEditStatus ? "transparent" : "#fff"}
							readOnly={basicSettingEditStatus}
							type='password'
						/>
					</Flex>
				</Grid>

				<Flex justifyContent={"flex-end"} p={4}>
					{basicSettingEditStatus ? (
						<></>
					) : (
						<>
							<Flex gap={4}>
								<Button colorScheme='rsSteel' onClick={handleCancel}>
									取消
								</Button>
								<Button colorScheme='rsSecondary' onClick={handleSave}>
									儲存
								</Button>
							</Flex>
						</>
					)}
				</Flex>
			</Flex>
		</>
	);
};

export default MedicalInstitutionBasicSetting;
