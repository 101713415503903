/* Hooks */
import React, { useState } from 'react';
import { useRef, useEffect } from 'react';
import {
    Button,
    Input,
    Flex,
    Box,
    Text,
    Tooltip,
    Center,
    Table,
    Thead,
    Tbody,
    Th,
    Tr,
    Td,
} from '@chakra-ui/react';
import Swal from 'sweetalert2';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import TimeInput from 'components/CustomComponents/TimeInput';

/* Components */

/*Service */
import { callShiftService } from 'service/shiftService';
import { postShiftService } from 'service/shiftService';
import { deleteShiftService } from 'service/shiftService';
// import { postShiftSortService } from 'service/postShiftSortService';

import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import { useUserStore } from 'store';

// 時段陣列 && 檢查陣列
interface showListsProps {
    data_id: string;
    name: string;
    start_time: string;
    end_time: string;
    seq?: number;
    apn?: string;
    isEditing: boolean;
    isDisabled: boolean;
}

export const MedicalInstitutionPeriodSetting = () => {
    const [newStartTime, setNewStartTime] = useState<string>('');
    const [newEndTime, setNewEndTime] = useState<string>('');
    /* declare */

    /* auto height */
    const [
        medicalInstitutionBasicSettingHeight,
        setMedicalInstitutionBasicSettingHeight,
    ] = useState<number>(0);
    const [tableHeight, setTableHeight] = useState<number>(0);
    const windowHeight = window.innerHeight;
    const outerDivRef = useRef<HTMLDivElement | null>(null);
    // show
    const [showLists, setShowLists] = useState<showListsProps[]>([]);
    const [checkShowLists, setCheckShowLists] = useState<showListsProps[]>([]);
    const handleKeyUpStartTime = () => {};
    const handleKeyUpNewEndTime = () => {};
    const handleKeyUpEndTime = () => {};
    const [endTime, setEndTime] = useState<string>('');
    const [newName, setNewName] = useState<string>('');
    const [seq, setSeq] = useState<string>('');
    const [checkStartTime, setCheckStartTime] = useState<string>('');
    const [checkEndTime, setCheckEndTime] = useState<string>('');
    const [checkName, setCheckName] = useState<string>('');
    const [checkItemId, setCheckItemId] = useState<string>('');
    // const token = localStorage.getItem('token');
    const { userInfo } = useUserStore();
    const fetchData = async () => {
        if (userInfo.token) {
            try {
                const fetchedShiftLists = await callShiftService(
                    userInfo.token
                );

                if (fetchedShiftLists && fetchedShiftLists.lists) {
                    const initialShiftLists: showListsProps[] =
                        fetchedShiftLists.lists.map(
                            (item: showListsProps, index: number) => ({
                                ...item,
                                apn:
                                    index === 0
                                        ? 'A'
                                        : index === 1
                                          ? 'P'
                                          : index === 2
                                            ? 'N'
                                            : '',
                                isEditing: true,
                            })
                        );
                    setShowLists(initialShiftLists);
                    setSeq(fetchedShiftLists.lists.length);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        } else {
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    //高度
    useEffect(() => {
        function updateHeight() {
            if (outerDivRef.current) {
                const height = outerDivRef.current.clientHeight;
                setMedicalInstitutionBasicSettingHeight(height);
            }
        }
        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [setMedicalInstitutionBasicSettingHeight]);
    useEffect(() => {
        const finalHeight =
            windowHeight - medicalInstitutionBasicSettingHeight - 215;
        setTableHeight(finalHeight);
    }, [windowHeight, medicalInstitutionBasicSettingHeight]);

    /* blur to add the dot for ROC date */

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        } else {
            const newData = [...showLists];

            const [movedItem] = newData.splice(result.source.index, 1);
            newData.splice(result.destination.index, 0, movedItem);

            setShowLists(newData);
        }
    };

    const handleChangeNewName = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const inputElement = event.target.value;
        setNewName(inputElement);
    };
    // 新增看診時段

    const handleChangeNewStartTime = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const inputElement = event.target.value;
        setNewStartTime(inputElement);
    };
    const handleKeyUpNewStartTime = (event: any) => {};
    const handleChangeNewEndTime = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const inputElement = event.target.value;
        setNewEndTime(inputElement);
    };

    const handleNewShift = () => {
        if (newStartTime < newEndTime) {
            const formattedStart =
                newStartTime.substring(0, 2) + ':' + newStartTime.substring(2);
            const formattedEnd =
                newEndTime.substring(0, 2) + ':' + newEndTime.substring(2);

            if (newName !== '' && newStartTime && newEndTime) {
                let data = {
                    name: newName,
                    start_time: formattedStart,
                    end_time: formattedEnd,
                    seq: seq,
                    apn:
                        showLists.length === 0
                            ? 'A'
                            : showLists.length === 1
                              ? 'P'
                              : showLists.length === 2
                                ? 'N'
                                : '',
                };
                const postData = async () => {
                    if (userInfo.token) {
                        try {
                            const fetchedShiftLists = await postShiftService(
                                userInfo.token,
                                data
                            );

                            if (fetchedShiftLists.result === 'success') {
                                setNewName('');
                                setNewStartTime('');
                                setNewEndTime('');
                                let newItem = {
                                    data_id: fetchedShiftLists.data_id,
                                    name: newName,
                                    start_time: formattedStart, // 或 formattedStart，取决于你的逻辑
                                    end_time: formattedEnd, // 或 formattedEnd，取决于你的逻辑
                                    isEditing: true,
                                    isDisabled: false,
                                };
                                setShowLists(prevLists => {
                                    const updatedLists = [
                                        ...prevLists,
                                        newItem,
                                    ];
                                    return updatedLists;
                                });
                                Swal.fire({
                                    icon: 'success',
                                    title: '新增成功',
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: ' 新增失敗',
                                    showConfirmButton: false, // 隱藏確認按鈕
                                    timer: 1500, // 設定自動關閉的時間 (1.5 秒)
                                });
                            }
                        } catch {
                            Swal.fire({
                                icon: 'error',
                                title: '後端服務錯誤',
                                timer: 1500,
                                showConfirmButton: false,
                            });
                        }
                    } else {
                    }
                };
                postData();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '資料格式有誤',
                    showConfirmButton: true,
                    confirmButtonText: '好',
                });
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: '開始時間不可晚於結束時間',
                showConfirmButton: true,
            });
        }
    };

    const handleDeleteShift = (itemId: string) => {
        if (itemId && userInfo.token) {
            Swal.fire({
                title: '是否要删除此時段?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: '是',
                cancelButtonText: '否',
            }).then(result => {
                if (result.isConfirmed) {
                    const deleteData = async () => {
                        try {
                            const fetchedShiftLists = await deleteShiftService(
                                userInfo.token,
                                itemId
                            );

                            if (fetchedShiftLists.result === 'success') {
                                Swal.fire({
                                    icon: 'success',
                                    title: '刪除成功',
                                    showConfirmButton: false,
                                    timer: 1500,
                                });

                                fetchData();
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: '刪除失败',
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                            }
                        } catch {
                            Swal.fire({
                                icon: 'error',
                                title: '後端服務錯誤',
                                timer: 1500,
                                showConfirmButton: false,
                            });
                        }
                    };
                    deleteData();
                } else {
                    return;
                }
            });
        }
    };

    const handleEdit = (
        itemId: string | null,
        startTime: string,
        name: string,
        endTime: string
    ) => {
        if (itemId) {
            setCheckItemId(itemId);
            setCheckStartTime(startTime);
            setCheckEndTime(endTime);
            setCheckName(name);
        }
        setShowLists(prevShiftLists => {
            return prevShiftLists.map(item => {
                if (item.data_id === itemId) {
                    return {
                        ...item,
                        isEditing: false,
                        isDisabled: false,
                    };
                }
                return {
                    ...item,
                    isEditing: true,
                    isDisabled: true,
                };
            });
        });
    };

    const handleSaveShift = (
        itemId: string | null,
        startTime: string,
        name: string,
        endTime: string,
        apn: string
    ) => {
        if (itemId && startTime && name && endTime) {
            //判斷是否改變資料
            if (
                itemId === checkItemId &&
                startTime === checkStartTime &&
                endTime === checkEndTime &&
                name === checkName
            ) {
                // 未改變資料
                setShowLists(prevShiftLists => {
                    return prevShiftLists.map(item => {
                        if (item.data_id === itemId) {
                            return {
                                ...item,
                                isEditing: true,
                                isDisabled: false,
                            };
                        }
                        return {
                            ...item,
                            isEditing: true,
                            isDisabled: false,
                        };
                    });
                });
                return;
            } else {
                // 有改變資料
                if (startTime < endTime) {
                    const index = showLists.findIndex(
                        item => item.data_id === itemId
                    );

                    if (index !== -1) {
                        // 找到了具有特定 itemId 的元素，index 就是它在数组中的索引位置
                    } else {
                        // 没有找到具有特定 itemId 的元素
                    }
                    let formattedStart = '';
                    if (startTime.length === 4) {
                        formattedStart =
                            startTime.substring(0, 2) +
                            ':' +
                            startTime.substring(2);
                    } else {
                        formattedStart = startTime;
                    }
                    let formattedEnd = '';
                    if (endTime.length === 4) {
                        formattedEnd =
                            endTime.substring(0, 2) +
                            ':' +
                            endTime.substring(2);
                    } else {
                        formattedEnd = endTime;
                    }

                    let data = {
                        data_id: itemId,
                        name: name,
                        start_time: formattedStart,
                        end_time: formattedEnd,
                        seq: index.toString(),
                        apn: apn,
                    };
                    const postData = async () => {
                        if (userInfo.token) {
                            try {
                                const fetchedShiftLists =
                                    await postShiftService(
                                        userInfo.token,
                                        data
                                    );

                                if (fetchedShiftLists.result === 'success') {
                                    setNewName('');
                                    setNewStartTime('');
                                    setNewEndTime('');
                                    fetchData();
                                    Swal.fire({
                                        icon: 'success',
                                        title: ' 修改成功',
                                        showConfirmButton: false, // 隱藏確認按鈕
                                        timer: 1500, // 設定自動關閉的時間 (1.5 秒)
                                    });
                                } else {
                                    Swal.fire({
                                        icon: 'error',
                                        title: ' 修改失敗',
                                        showConfirmButton: false, // 隱藏確認按鈕
                                        timer: 1500, // 設定自動關閉的時間 (1.5 秒)
                                    });
                                }
                            } catch {
                                Swal.fire({
                                    icon: 'error',
                                    title: '後端服務錯誤',
                                    timer: 1500,
                                    showConfirmButton: false,
                                });
                            }
                        }
                    };
                    postData();
                    setShowLists(prevShiftLists => {
                        return prevShiftLists.map(item => {
                            if (item.data_id === itemId) {
                                return {
                                    ...item,
                                    isEditing: true,
                                    isDisabled: false,
                                };
                            }
                            return {
                                ...item,
                                isEditing: true,
                                isDisabled: false,
                            };
                        });
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: '開始時間不可晚於結束時間',
                        showConfirmButton: true,
                        confirmButtonText: '好',
                    });
                }
            }
        }
    };

    const handleCancel = (
        itemId: string | null,
        startTime: string,
        name: string,
        endTime: string
    ) => {
        setShowLists(prevShiftLists => {
            return prevShiftLists.map(item => {
                if (item.data_id === itemId) {
                    return {
                        ...item,
                        start_time: checkStartTime,
                        name: checkName,
                        end_time: checkEndTime,
                        isEditing: true,
                        isDisabled: false,
                    };
                }
                return {
                    ...item,
                    isEditing: true,
                    isDisabled: false,
                };
            });
        });
    };

    const handleChangeName = (
        event: React.ChangeEvent<HTMLInputElement>,
        itemId: string | null
    ) => {
        const updatedShiftLists = showLists.map(item => {
            if (item.data_id === itemId) {
                // 如果找到与传递的 itemId 匹配的项，更新其名称值
                return { ...item, name: event.target.value };
            }
            return item;
        });

        setShowLists(updatedShiftLists);
    };

    // 表格內的開始時間修改
    const handleChangeStartTime = (
        event: React.ChangeEvent<HTMLInputElement>,
        itemId: string | null
    ) => {
        const inputElement = event.target.value;

        const formattedStart =
            inputElement.substring(0, 2) + ':' + inputElement.substring(2);

        // setStartTime(inputElement);
        setShowLists(prevShiftLists => {
            return prevShiftLists.map(item => {
                if (item.data_id === itemId) {
                    return {
                        ...item,
                        start_time: formattedStart,
                    };
                }
                return item;
            });
        });
    };

    const handleChangeEndTime = (
        event: React.ChangeEvent<HTMLInputElement>,
        itemId: string | null
    ) => {
        const inputElement = event.target.value;

        const formattedStart =
            inputElement.substring(0, 2) + ':' + inputElement.substring(2);

        // setStartTime(inputElement);
        setShowLists(prevShiftLists => {
            return prevShiftLists.map(item => {
                if (item.data_id === itemId) {
                    return {
                        ...item,
                        end_time: formattedStart,
                    };
                }
                return item;
            });
        });
    };

    return (
        <>
            <Flex direction={'column'} gap={'16px'}>
                {/* <Flex
                    hidden
                    flexWrap={'wrap'}
                    gap={'24px'}
                    bg={'#E9E5DA'}
                    rounded={'4px'}
                    p={'8px 16px'}
                    w={'fit-content'}
                >
                    <Flex align={'center'} gap={'16px'}>
                        <Text textStyle="semiTitle" flex={'0 0 auto'}>
                            時段名稱
                        </Text>
                        <Input
                            borderColor={'#9B9B7A'}
                            maxLength={10}
                            name="name"
                            onChange={handleChangeNewName}
                            value={newName}
                            bgColor={'#fff'}
                        />
                    </Flex>
                    <Flex align={'center'} gap={'16px'}>
                        <Text textStyle="semiTitle" flex={'0 0 auto'}>
                            開始時間
                        </Text>
                        <TimeInput
                            value={newStartTime}
                            onChange={handleChangeNewStartTime}
                            onKeyUp={handleKeyUpNewStartTime}
                        />
                    </Flex>
                    <Flex align={'center'} gap={'16px'}>
                        <Text textStyle="semiTitle" flex={'0 0 auto'}>
                            結束時間
                        </Text>
                        <TimeInput
                            value={newEndTime}
                            onChange={handleChangeNewEndTime}
                            onKeyUp={handleKeyUpNewEndTime}
                        />
                    </Flex>
                    <Button colorScheme="rsPrimary" onClick={handleNewShift}>
                        新增
                    </Button>
                </Flex> */}

                <DragDropContext onDragEnd={handleDragEnd}>
                    <Table variant="simple">
                        <Thead
                            borderBottom={'2px solid #9B9B7A'}
                            textColor={'#9B9B7A'}
                            fontWeight={'semibold'}
                        >
                            <Tr>
                                <Td w={'120px'} hidden>
                                    <Box textAlign={'center'}>排序</Box>
                                </Td>
                                <Td w={'200px'}>
                                    <Box>時段名稱</Box>
                                </Td>
                                <Td w={'200px'}>
                                    <Box>開始時間</Box>
                                </Td>
                                <Td w={'200px'}>
                                    <Box>結束時間</Box>
                                </Td>
                                <Td w={'136px'}>
                                    <Box className=" text-right">操作區</Box>
                                </Td>
                            </Tr>
                        </Thead>
                        <Droppable droppableId="root">
                            {provided => (
                                <Tbody
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {showLists &&
                                        showLists.map((item, index) => (
                                            <Draggable
                                                key={item.data_id}
                                                draggableId={item.data_id}
                                                index={index}
                                            >
                                                {provided => (
                                                    <Tr
                                                        {...provided.draggableProps}
                                                        ref={provided.innerRef}
                                                        key={item.data_id}
                                                        borderBottom={
                                                            '1px solid #9B9B7A'
                                                        }
                                                        _last={{
                                                            borderBottom:
                                                                '0px solid #9B9B7A',
                                                        }}
                                                    >
                                                        {item.isEditing ? (
                                                            // 沒被選的id
                                                            <>
                                                                <>
                                                                    <Td
                                                                        fontSize={
                                                                            'md'
                                                                        }
                                                                        {...provided.dragHandleProps}
                                                                        key={
                                                                            item.data_id
                                                                        }
                                                                        hidden
                                                                    >
                                                                        <Center>
                                                                            &#x283F;
                                                                        </Center>
                                                                    </Td>
                                                                    <Td>
                                                                        <Box
                                                                            h={
                                                                                '40px'
                                                                            }
                                                                            p={
                                                                                '10px 16px'
                                                                            }
                                                                        >
                                                                            {item.name ||
                                                                                ''}
                                                                        </Box>
                                                                    </Td>
                                                                    <Td>
                                                                        <Box
                                                                            h={
                                                                                '40px'
                                                                            }
                                                                            p={
                                                                                '10px 16px'
                                                                            }
                                                                        >
                                                                            {
                                                                                item.start_time
                                                                            }
                                                                        </Box>
                                                                    </Td>
                                                                    <Td>
                                                                        <Box
                                                                            h={
                                                                                '40px'
                                                                            }
                                                                            p={
                                                                                '10px 16px'
                                                                            }
                                                                        >
                                                                            {
                                                                                item.end_time
                                                                            }
                                                                        </Box>
                                                                    </Td>

                                                                    <Td>
                                                                        <Flex
                                                                            gap={
                                                                                '12px'
                                                                            }
                                                                            justify={
                                                                                'flex-end'
                                                                            }
                                                                        >
                                                                            <Tooltip
                                                                                label="編輯"
                                                                                hasArrow
                                                                                bg={
                                                                                    'rsPrimary.500'
                                                                                }
                                                                                openDelay={
                                                                                    1000
                                                                                }
                                                                            >
                                                                                <Box
                                                                                    textColor={
                                                                                        'rsPrimary.500'
                                                                                    }
                                                                                    onClick={() =>
                                                                                        handleEdit(
                                                                                            item.data_id,
                                                                                            item.start_time,
                                                                                            item.name,
                                                                                            item.end_time
                                                                                        )
                                                                                    }
                                                                                    _hover={{
                                                                                        cursor: 'pointer',
                                                                                        opacity:
                                                                                            '1',
                                                                                    }}
                                                                                    opacity={
                                                                                        item.isDisabled
                                                                                            ? '0.5'
                                                                                            : '0.8'
                                                                                    }
                                                                                    pointerEvents={
                                                                                        item.isDisabled
                                                                                            ? 'none'
                                                                                            : 'auto'
                                                                                    }
                                                                                >
                                                                                    <HeroIcons name="edit-fill" />
                                                                                </Box>
                                                                            </Tooltip>
                                                                            <Tooltip
                                                                                label="刪除"
                                                                                hasArrow
                                                                                bg={
                                                                                    'rsPrimary.500'
                                                                                }
                                                                                openDelay={
                                                                                    1000
                                                                                }
                                                                            >
                                                                                <Box
                                                                                    hidden
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            item.data_id
                                                                                        ) {
                                                                                            handleDeleteShift(
                                                                                                item.data_id
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                    textColor={
                                                                                        'rsPrimary.500'
                                                                                    }
                                                                                    _hover={{
                                                                                        cursor: 'pointer',
                                                                                        opacity:
                                                                                            '1',
                                                                                    }}
                                                                                    opacity={
                                                                                        item.isDisabled
                                                                                            ? '0.5'
                                                                                            : '0.8'
                                                                                    }
                                                                                    pointerEvents={
                                                                                        item.isDisabled
                                                                                            ? 'none'
                                                                                            : 'auto'
                                                                                    }
                                                                                >
                                                                                    <HeroIcons name="delete-fill"></HeroIcons>
                                                                                </Box>
                                                                            </Tooltip>
                                                                        </Flex>
                                                                    </Td>
                                                                </>
                                                            </>
                                                        ) : (
                                                            // 被選的id
                                                            <>
                                                                <Td
                                                                    {...provided.dragHandleProps}
                                                                    hidden
                                                                >
                                                                    <Center>
                                                                        {' '}
                                                                        &#x283F;
                                                                    </Center>
                                                                </Td>

                                                                <Td
                                                                    fontSize={
                                                                        'md'
                                                                    }
                                                                >
                                                                    <Box
                                                                        h={
                                                                            '40px'
                                                                        }
                                                                        py={
                                                                            '10px'
                                                                        }
                                                                        px={
                                                                            '16px'
                                                                        }
                                                                    >
                                                                        {item.name ||
                                                                            ''}
                                                                    </Box>
                                                                </Td>
                                                                <Td>
                                                                    <TimeInput
                                                                        onChange={event =>
                                                                            handleChangeStartTime(
                                                                                event,
                                                                                item.data_id
                                                                            )
                                                                        }
                                                                        onKeyUp={
                                                                            handleKeyUpStartTime
                                                                        }
                                                                        value={
                                                                            item.start_time
                                                                                ? item.start_time
                                                                                : ''
                                                                        }
                                                                    />
                                                                </Td>
                                                                <Td>
                                                                    <TimeInput
                                                                        onChange={event =>
                                                                            handleChangeEndTime(
                                                                                event,
                                                                                item.data_id
                                                                            )
                                                                        }
                                                                        onKeyUp={event => {
                                                                            if (
                                                                                event.key ===
                                                                                'Enter'
                                                                            ) {
                                                                                handleSaveShift(
                                                                                    item.data_id,
                                                                                    item.start_time,
                                                                                    item.name,
                                                                                    item.end_time,
                                                                                    item.apn ??
                                                                                        ''
                                                                                );
                                                                            }
                                                                        }}
                                                                        value={
                                                                            item.end_time
                                                                                ? item.end_time
                                                                                : ''
                                                                        }
                                                                    />
                                                                </Td>
                                                                <Td>
                                                                    <Flex
                                                                        gap={
                                                                            '12px'
                                                                        }
                                                                        justify={
                                                                            'flex-end'
                                                                        }
                                                                    >
                                                                        {item.isEditing ? (
                                                                            <Box
                                                                                onClick={() =>
                                                                                    handleEdit(
                                                                                        item.data_id,
                                                                                        item.start_time,
                                                                                        item.name,
                                                                                        item.end_time
                                                                                    )
                                                                                }
                                                                                _hover={{
                                                                                    cursor: 'pointer',
                                                                                    opacity:
                                                                                        '1',
                                                                                }}
                                                                                opacity={
                                                                                    item.isDisabled
                                                                                        ? '0.5'
                                                                                        : '0.8'
                                                                                }
                                                                                pointerEvents={
                                                                                    item.isDisabled
                                                                                        ? 'none'
                                                                                        : 'auto'
                                                                                }
                                                                            >
                                                                                <HeroIcons name="edit" />
                                                                            </Box>
                                                                        ) : (
                                                                            <Flex
                                                                                gap={
                                                                                    '12px'
                                                                                }
                                                                                textColor={
                                                                                    'rsPrimary.500'
                                                                                }
                                                                            >
                                                                                <Tooltip
                                                                                    label="取消"
                                                                                    hasArrow
                                                                                    bg={
                                                                                        'rsPrimary.500'
                                                                                    }
                                                                                    openDelay={
                                                                                        1000
                                                                                    }
                                                                                >
                                                                                    <Box
                                                                                        onClick={() =>
                                                                                            handleCancel(
                                                                                                item.data_id,
                                                                                                item.start_time,
                                                                                                item.name,
                                                                                                item.end_time
                                                                                            )
                                                                                        }
                                                                                        opacity={
                                                                                            '0.8'
                                                                                        }
                                                                                        _hover={{
                                                                                            cursor: 'pointer',
                                                                                            opacity:
                                                                                                '1',
                                                                                        }}
                                                                                    >
                                                                                        <HeroIcons name="return"></HeroIcons>
                                                                                    </Box>
                                                                                </Tooltip>

                                                                                <Tooltip
                                                                                    label="儲存"
                                                                                    hasArrow
                                                                                    bg={
                                                                                        'rsPrimary.500'
                                                                                    }
                                                                                    openDelay={
                                                                                        1000
                                                                                    }
                                                                                >
                                                                                    <Box
                                                                                        onClick={() =>
                                                                                            handleSaveShift(
                                                                                                item.data_id,
                                                                                                item.start_time,
                                                                                                item.name,
                                                                                                item.end_time,
                                                                                                item.apn ??
                                                                                                    ''
                                                                                            )
                                                                                        }
                                                                                        _hover={{
                                                                                            cursor: 'pointer',
                                                                                            opacity:
                                                                                                '1',
                                                                                        }}
                                                                                        opacity={
                                                                                            item.isDisabled
                                                                                                ? '0.5'
                                                                                                : '0.8'
                                                                                        }
                                                                                        pointerEvents={
                                                                                            item.isDisabled
                                                                                                ? 'none'
                                                                                                : 'auto'
                                                                                        }
                                                                                    >
                                                                                        <HeroIcons name="check"></HeroIcons>
                                                                                    </Box>
                                                                                </Tooltip>
                                                                            </Flex>
                                                                        )}

                                                                        <Box
                                                                            hidden
                                                                            onClick={() => {
                                                                                if (
                                                                                    item.data_id
                                                                                ) {
                                                                                    handleDeleteShift(
                                                                                        item.data_id
                                                                                    );
                                                                                }
                                                                            }}
                                                                            textColor={
                                                                                'rsPrimary.500'
                                                                            }
                                                                            _hover={{
                                                                                cursor: 'pointer',
                                                                                opacity:
                                                                                    '1',
                                                                            }}
                                                                            opacity={
                                                                                item.isDisabled
                                                                                    ? '0.5'
                                                                                    : '0.8'
                                                                            }
                                                                            pointerEvents={
                                                                                item.isDisabled
                                                                                    ? 'none'
                                                                                    : 'auto'
                                                                            }
                                                                        >
                                                                            <HeroIcons name="delete-fill"></HeroIcons>
                                                                        </Box>
                                                                    </Flex>
                                                                </Td>
                                                            </>
                                                        )}
                                                    </Tr>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                </Tbody>
                            )}
                        </Droppable>
                    </Table>
                </DragDropContext>
            </Flex>
        </>
    );
};
