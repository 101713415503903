import React from 'react';
import { Box } from '@chakra-ui/react';
import { DownloadTOTFAButton } from './DownloadTOTFAButton';
export const Download = () => {
    return (
        <Box>
            <DownloadTOTFAButton />
        </Box>
    );
};
