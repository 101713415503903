import { callDeclareDownloadService } from 'service/declareDownloadService';
import { useUserStore } from 'store';
import Swal from 'sweetalert2';
export const useDeclareDownloadAPI = () => {
    const { userInfo } = useUserStore();

    const callDeclareDownloadAPI = async (data?: string) => {
        try {
            const response = await callDeclareDownloadService(
                userInfo.token,
                data
            );

            if (response) {
                const blob = new Blob([response], {
                    type: 'application/octet-stream',
                });

                if (blob instanceof Blob) {
                    console.log('成功創建 Blob 對象');
                    console.log('blob size:', blob.size);

                    const urlObject = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = urlObject;
                    a.download = 'TOTFA.zip';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(urlObject);
                } else {
                    console.log('response is null');
                    Swal.fire({
                        icon: 'info',
                        title: '無資料',
                        confirmButtonText: '確定',
                    });
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            Swal.fire({
                icon: 'info',
                title: '該月無下載資料',
                confirmButtonText: '確定',
            });
        }
    };
    return { callDeclareDownloadAPI };
};
