import axios from "axios";

// 請求病患授權
export interface VHChisGetSeqNumber256N1ReqProps {
	cTreatItem: string; // 就醫類別 (HC 健 8-1), 3 bytes
	cBabyTreat: string; // 新生兒就醫註記 (HC 健 8-2), 2 bytes
	cTreatAfterCheck: "1" | "2"; // 補卡註記, 1 byte: '1' for 正常, '2' for 補卡
}
export interface VHChisGetSeqNumber256N1ResProps {
	ERRORCODE: number;
	pBuffer?: string;
}
export const postVHChisGetSeqNumber256N1Service = async (
	VHChisGetSeqNumber256N1ReqData: VHChisGetSeqNumber256N1ReqProps
): Promise<VHChisGetSeqNumber256N1ResProps> => {
	try {
		const response = await axios.post("http://localhost:3033/VHC/VHChisGetSeqNumber256N1", VHChisGetSeqNumber256N1ReqData, {
			headers: {
				Accept: "*/*",
				"Content-Type": "application/json"
			}
		});

		return response.data;
	} catch (error: unknown) {
		console.error("Error:", error instanceof Error ? error.message : "An unknown error occurred");
		throw error; // 重新拋出錯誤，讓調用者處理
	}
};
