import { Button } from "@chakra-ui/react";
import Swal from "sweetalert2";
interface VideoRegistrationButtonProps {
    opdRecordAPI: (param: string) => Promise<void>;  // 更新接口定義
}


export const RegistrationButton:React.FC<VideoRegistrationButtonProps> = ({opdRecordAPI}) => {
    
    const handleRegistration = async() => {
        try {
            await opdRecordAPI('10');
        } catch (error) {
            Swal.fire({
                title: '掛號失敗',
                text: '請重新掛號',
                icon: 'error',
                confirmButtonText: '確定'
            })
        }
        
    }
    return (
        <>
            <Button
                variant={'outline'}
                colorScheme="rsPrimary"
                borderWidth={'2px'}
                fontWeight={'semibold'}
                onClick={handleRegistration}
            >
                掛號
            </Button>
        </>
    )
};
