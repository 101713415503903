import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { callOpdListsService } from "service/opdListsService";
import { OpdListsProps } from "service/opdListsService";
import Swal from "sweetalert2";
import { useUserStore } from "store";
import { useWaitingTableTabIndex } from "contexts";
export const useBaseWaitingListQuery = () => {
	// const [opdLists, setOpdList] = useState<OpdListsProps>({});
	const { userInfo } = useUserStore();
	const { waitingTableTabIndex } = useWaitingTableTabIndex(); // 從 context 中獲取 waitingTabIndex

	const query = useQuery("opdLists", () => callOpdListsService(userInfo.token!), {
		onSuccess: result => {
			// console.log(result, '整排的lists');
		},
		onError: error => {
			Swal.fire({
				icon: "error",
				title: "後端服務錯誤",
				timer: 1500,
				showConfirmButton: false
			});
		},
		enabled: !!userInfo.token && waitingTableTabIndex === 0,
		staleTime: Infinity,
		refetchInterval: 30000
	});

	return {
		...query,
		opdLists: query.data?.lists ?? {}
	};
};
