import React, { useEffect, useState, useRef } from "react";
import { Box, Text, Flex, Center, Tooltip, List, ListItem, Button, useDisclosure, Select, Input, Divider } from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import Swal from "sweetalert2";

import { useUserStore } from "store";

export const CasesRatio = () => {
	const { userInfo } = useUserStore();

	const cases = [
		{
			project: "專案",
			amount: 0,
			percentage: "0%"
		},
		{
			project: "連簽",
			amount: 0,
			percentage: "0%"
		},
		{
			project: "慢性",
			amount: 0,
			percentage: "0%"
		},
		{
			project: "保健",
			amount: 0,
			percentage: "0%"
		}
	];

	return (
		<>
			<Box w={"100%"} h={"100%"} p={"8px 12px"} overflowY={"auto"}>
				<Text mb={"4px"} textStyle={"semiTitle"}>
					案件件數 / 比例
				</Text>
				<Flex direction={"column"} gap={"4px"}>
					<Text fontSize={"xl"} fontWeight={"semibold"}>
						總案件數 {0} 件
					</Text>
					<Box w={"100%"} h={"1px"} bg={"#646B48"}></Box>
					<Box h={"calc(28px * 5)"} overflowY={"auto"}>
						{cases &&
							cases.map((item, index) => (
								<Flex align={"center"} justify={"space-between"} _hover={{ bg: "gray.50" }} p={"2px 8px"} key={index}>
									<Text fontWeight={"semibold"} flex={"0 0 33%"}>
										{item.project}
									</Text>
									<Text flex={"0 0 33%"} textAlign={"right"}>
										{item.amount} 件
									</Text>
									<Text flex={"0 0 33%"} textAlign={"right"}>
										{item.percentage}
									</Text>
								</Flex>
							))}
					</Box>
				</Flex>
			</Box>
		</>
	);
};
