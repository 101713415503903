import React, { createContext, useContext, useState, ReactNode } from "react";

interface ComputeFeeContextValue {
	computeFeeStatus: boolean;
	setComputeFeeStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const ComputeFeeContext = createContext<ComputeFeeContextValue | undefined>(undefined);

interface ComputeFeeProviderProps {
	children: ReactNode;
}

export const ComputeFeeProvider: React.FC<ComputeFeeProviderProps> = ({ children }) => {
	const [computeFeeStatus, setComputeFeeStatus] = useState(false);
	return (
		<ComputeFeeContext.Provider
			value={{
				computeFeeStatus,
				setComputeFeeStatus
			}}>
			{children}
		</ComputeFeeContext.Provider>
	);
};

export const useComputeFeeStatus = (): ComputeFeeContextValue => {
	const context = useContext(ComputeFeeContext);

	if (!context) {
		throw new Error("useComputeFeeStatus must be used within a computeFeeStatusProvider");
	}

	return context;
};
