import { useState, useEffect, ChangeEventHandler, useRef } from "react";
import {
	Box,
	Flex,
	Text,
	Heading,
	Input,
	InputGroup,
	InputRightElement,
	Button,
	UnorderedList,
	OrderedList,
	ListItem,
	PinInput,
	PinInputField
} from "@chakra-ui/react";
import moment from "moment";
import "../style/components/login.css";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";

// API
// import { postVerifyCheckCodeService } from 'service/postVerifyCheckCodeService';
import { postVerifyCheckCodeService } from "service/verifyCheckCodeService";
// import { postVerifySetPasswordService } from 'service/postVerifySetPasswordService';
import { postVerifySetPasswordService } from "service/verifySetPasswordService";

import { Cookies } from "react-cookie";

import { useNavigate } from "react-router-dom";
const AuthenticationPasswordPage = () => {
	// const token = localStorage.getItem('token')
	const cookies = new Cookies();

	const navigate = useNavigate();
	const [stepCounter, setStepCounter] = useState(1);
	const [code, setCode] = useState("");

	const [otpCode, setOtpCode] = useState<string>("");

	const [showPassword, SetShowPassword] = useState<boolean>();
	const handleShowPasswordToggle = () => SetShowPassword(!showPassword);

	const [finalData, setFinalData] = useState<number>();

	const [otpStatusMessage, setOtpStatusMessage] = useState<string>();

	const [password, setPassword] = useState<string>();
	const [passwordAgain, setPasswordAgain] = useState<string>();

	const [checkPassword, setCheckPassword] = useState<boolean>();
	const [passwordStatusMessage, setPasswordStatusMessage] = useState<string>();

	// 取子網域
	const [subdomain, setSubdomain] = useState("");
	useEffect(() => {
		const fullURL = window.location.href;
		console.log(fullURL, "取得url");
		const url = new URL(fullURL);
		const hostname = url.hostname;
		const parts = hostname.split(".");
		const subdomain = parts[0].toLowerCase();
		console.log(subdomain, "子域名");
		localStorage.setItem("X-Tenant", subdomain);
		cookies.set("X-Tenant", subdomain, { path: "/" });
		setSubdomain(subdomain);
	}, []);

	const stepContent = [
		{
			title: "輸入驗證碼",
			text: "請檢查您的 email 是否有來自 RealSun 的信件，使用其中的驗證碼。"
		},
		{
			title: "設定密碼",
			text: "請根據複雜度規則，設計一個密碼"
		},
		{
			title: "重新登入",
			text: "恭喜完成設定，請至耀聖醫療系統網站登入"
		}
	];

	const handleOtpChange = (value: string) => {
		setOtpCode(value);
	};

	const verifyCode = 556677;

	// 這邊要調整
	const handleOtpCodeSubmit = () => {
		const numberValue: number = parseInt(otpCode, 10);
		const first = document.getElementById("first");
		console.log(first, "第一個");
		setFinalData(numberValue);

		if (otpCode.length === 6) {
			postVerifyCheckCodeAPI();
		} else if (otpCode.length < 6) {
			setOtpStatusMessage("驗證碼長度不完整");
		}
	};

	const handleKeyPressForOptSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			handleOtpCodeSubmit();
		}
	};

	const handleKeyPressForPasswordSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			handlePasswordSubmit();
		}
	};

	const handleInputPassword: ChangeEventHandler<HTMLInputElement> = event => {
		setPassword(event.target.value);
	};
	const handleCheckPassword = () => {
		if (password && /[\uFF01-\uFF5E]/.test(password)) {
			setPasswordStatusMessage("密碼中不能包含全形字符");
		} else if (password && password.length < 8) {
			setPasswordStatusMessage("密碼長度必須至少為 8 個字符");
		} else if (password && !/[A-Za-z]/.test(password)) {
			setPasswordStatusMessage("密碼需包含至少一個英文字母");
		} else if (password && !/\d/.test(password)) {
			setPasswordStatusMessage("密碼需包含至少一個數字");
		} else if (password && !/[~!@#$%^&*()_+`\-={}[\]:;"'<>,.?\\/]/.test(password)) {
			setPasswordStatusMessage("密碼需包含至少一個特殊符號");
		} else if (password && !/[a-z]/.test(password)) {
			setPasswordStatusMessage("密碼需包含至少一個小寫英文字母");
		} else if (password && !/[A-Z]/.test(password)) {
			setPasswordStatusMessage("密碼需包含至少一個大寫英文字母");
		} else {
			setCheckPassword(true);
		}
	};

	const handleInputPwDoubleCheck: ChangeEventHandler<HTMLInputElement> = event => {
		setPasswordAgain(event.target.value);
	};

	const postVerifySetPasswordAPI = async () => {
		if (password) {
			// 用url取得user
			const CryptoJS = require("crypto-js");

			const newKey = "Rs@375@100";

			const sha256Key = CryptoJS.SHA256(newKey);
			const aesKey = CryptoJS.enc.Hex.stringify(sha256Key);

			const md5Hash = CryptoJS.MD5(newKey).toString();

			const key = CryptoJS.enc.Hex.parse(aesKey);
			const iv = CryptoJS.enc.Hex.parse(md5Hash);

			const dataToEncrypt = password; // 替换为实际的要加密的数据
			const encrypted = CryptoJS.AES.encrypt(dataToEncrypt, key, {
				iv: iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7
			});

			const encryptedData = encrypted.toString();

			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			const user = urlParams.get("user") || "";
			// 現在時間
			const keyTime = new Date();
			// 給request_time用的
			const momentTime = moment(keyTime).format("YYYY-MM-DD HH:mm:ss");
			console.log("時間");
			// 當加密的鑰匙
			const binaryKey = moment(momentTime).format("YYYYHHMMmmDDss");
			console.log(binaryKey, "加密鑰匙");

			const hash = CryptoJS.HmacSHA256(code, binaryKey);
			// 加密結果
			const macInBase64 = CryptoJS.enc.Base64.stringify(hash);
			let data = {
				user: user,
				password: encryptedData,
				code: code,
				mac: macInBase64,
				request_time: momentTime
			};
			console.log("data, ", data);
			try {
				const result = await postVerifySetPasswordService(data);
				if (result.result === "success") {
					setPasswordStatusMessage("OK!");
					setStepCounter(prev => prev + 1);
					Swal.fire({
						icon: "success",
						title: "註冊成功",
						showConfirmButton: false,
						timer: 1500
					});
				} else {
					console.log("失敗");
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500,
					showConfirmButton: false
				});
			}
		}
	};
	const handlePasswordSubmit = () => {
		if (password && /[\uFF01-\uFF5E]/.test(password)) {
			setPasswordStatusMessage("密碼中不能包含全形字符");
		} else if (password && password.length < 8) {
			setPasswordStatusMessage("密碼長度必須至少為 8 個字符");
		} else if (password && !/[A-Za-z]/.test(password)) {
			setPasswordStatusMessage("密碼需包含至少一個英文字母");
		} else if (password && !/\d/.test(password)) {
			setPasswordStatusMessage("密碼需包含至少一個數字");
		} else if (password && !/[~!@#$%^&*()_+`\-={}[\]:;"'<>,.?\\/]/.test(password)) {
			setPasswordStatusMessage("密碼需包含至少一個特殊符號");
		} else if (password && !/[a-z]/.test(password)) {
			setPasswordStatusMessage("密碼需包含至少一個小寫英文字母");
		} else if (password && !/[A-Z]/.test(password)) {
			setPasswordStatusMessage("密碼需包含至少一個大寫英文字母");
		} else if (password !== passwordAgain) {
			setCheckPassword(false);
			setPasswordStatusMessage("兩次密碼不符");
		} else {
			setCheckPassword(true);
			postVerifySetPasswordAPI();
		}
	};
	const handleGoLogin = () => {
		navigate("/");
	};

	const postVerifyCheckCodeAPI = async () => {
		// 用url取得user
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const user = urlParams.get("user") || "";
		// 現在時間
		const keyTime = new Date();
		// 給request_time用的
		const momentTime = moment(keyTime).format("YYYY-MM-DD HH:mm:ss");
		// 當加密的鑰匙
		const binaryKey = moment(momentTime).format("YYYYHHMMmmDDss");

		const hash = CryptoJS.HmacSHA256(otpCode, binaryKey);
		// 加密結果
		const macInBase64 = CryptoJS.enc.Base64.stringify(hash);

		let data = {
			user: user,
			code: otpCode,
			mac: macInBase64,
			request_time: momentTime
		};
		try {
			const result = await postVerifyCheckCodeService(data);
			console.log(result, "api結果");
			if (result.result === "success") {
				console.log("驗證成功");
				setCode(result.code);
				setOtpStatusMessage("驗證通過");
				setStepCounter(prev => prev + 1);
			} else if (result.result === "failure") {
				console.log("驗證失败");
				if (result.msg === "驗證失敗") {
					console.log("msg成功");
					setOtpStatusMessage("驗證失敗，請再試一次");
					setOtpCode("");

					const firstPin = document.getElementsByClassName("firstPin")[0] as HTMLElement;
					if (firstPin) {
						firstPin.focus();
					}
				}
			} else {
				console.log("不確定的狀況");
			}
		} catch {
			Swal.fire({
				icon: "error",
				title: "後端服務錯誤",
				timer: 1500,
				showConfirmButton: false
			});
		}
	};
	// 測試用 先不用刪
	// useEffect(()=>{
	//     const CryptoJS = require('crypto-js');
	//     const str = 'D8178'; //加密的東西
	//     const binaryKey = '20230906382819'; // 加密的鑰匙

	//     const hash = CryptoJS.HmacSHA256(str, binaryKey);
	//     const macInBase64 = CryptoJS.enc.Base64.stringify(hash);  把sha-256轉成 base64

	//     console.log(macInBase64, '原本的');
	//     const str2 = '116655';
	//     const binaryKey2 = '20241802562529';

	//     const hash2 = CryptoJS.HmacSHA256(str2, binaryKey2);
	//     const macInBase642 = CryptoJS.enc.Base64.stringify(hash2);

	//     console.log(macInBase642, '要測試的');
	// })

	return (
		<>
			<Flex
				className='login-gradient'
				w={"100%"}
				h={"100vh"}
				pos={"fixed"}
				inset={0}
				zIndex={100}
				align={"center"}
				justify={"center"}>
				<Flex bg={"gray.50"} rounded={"15px"} overflow={"hidden"} minH={"calc(100vh - 200px)"}>
					{/* The Left */}
					<Flex direction={"column"} justify={"space-between"} py={"100px"} px={"40px"} bg={"rsPrimary.800"}>
						{/* Top content */}
						<Flex direction={"column"}>
							<Heading textColor={"#fff"} fontSize={"40px"} fontWeight={"semibold"} mb={"40px"}>
								開通帳號
							</Heading>
							<Box>
								<Text fontSize={"md"} textColor={"#fff"} mb={"5px"}>
									歡迎來到耀聖資訊系統
								</Text>
								<Text fontSize={"20px"} fontWeight={"semibold"} textColor={"#fff"}></Text>
							</Box>
						</Flex>

						{/* Bottom Content */}
						<Flex>
							<OrderedList ml={0} spacing={"30px"} maxW={"300px"}>
								{stepContent &&
									stepContent.map((step, index) => (
										<ListItem key={index} className='flex gap-[10px]' opacity={stepCounter === index + 1 ? "1" : "0.5"}>
											<Box
												w={"24px"}
												h={"24px"}
												flexShrink={0}
												bg={"rsPrimary.500"}
												color={"#fff"}
												textAlign={"center"}
												lineHeight={"24px"}
												rounded={"full"}>
												{index + 1}
											</Box>
											<Box>
												<Text textColor={"#fff"} fontSize={"18px"} lineHeight={"24px"} mb={"10px"}>
													{step.title}
												</Text>
												<Text textColor={"#fff"} fontSize={"14px"}>
													{step.text}
												</Text>
											</Box>
										</ListItem>
									))}
							</OrderedList>
						</Flex>
					</Flex>

					{/* The Right */}
					<Flex direction={"column"} justify={"space-between"} py={"100px"} px={"40px"} bg={"#f8f8f8"}>
						{/* Top content */}
						<Flex direction={"column"} gap={"40px"} minW={"338px"}>
							<Text
								as={"span"}
								bg={"rsPrimary.800"}
								px={"16px"}
								py={"4px"}
								textColor={"#fff"}
								fontSize={"20px"}
								textTransform={"uppercase"}
								alignSelf={"flex-start"}>
								Step {stepCounter}
							</Text>

							{/* 以後要拉出去的 */}

							{/* 標題 */}
							<Box>
								<Heading fontSize={"20px"} textColor={"rsPrimary.500"}>
									{stepCounter === 1 ? (
										<>請輸入驗證碼</>
									) : stepCounter === 2 ? (
										<> 設定您的密碼</>
									) : stepCounter === 3 ? (
										<>開通帳號完成</>
									) : (
										<>''</>
									)}
								</Heading>
								<Text fontSize={"14px"} fontWeight={"normal"} textColor={"rsPrimary.500"}>
									{stepCounter === 1 ? (
										<>Enter Verification Code</>
									) : stepCounter === 2 ? (
										<> Enter New Password</>
									) : stepCounter === 3 ? (
										<>Congratulation</>
									) : (
										<>''</>
									)}
								</Text>
							</Box>

							{stepCounter === 1 ? (
								<>
									{/* 輸入驗證碼 */}
									<Box>
										<Flex gap={"10px"} mb={"20px"}>
											<PinInput
												size={"lg"}
												placeholder=''
												autoFocus
												onChange={handleOtpChange}
												otp
												value={otpCode}
												manageFocus
												errorBorderColor={"rsDanger"}>
												{/* 回頭再來做輸入值後改變 bg */}
												<PinInputField className='firstPin' />
												<PinInputField />
												<PinInputField />
												<PinInputField />
												<PinInputField />
												<PinInputField onKeyDown={handleKeyPressForOptSubmit} />
											</PinInput>
										</Flex>

										<Button colorScheme='rsPrimary' w={"100%"} onClick={handleOtpCodeSubmit}>
											送出 Submit
										</Button>

										{/* Error Message  */}
										<Text textColor={"rsDanger.500"} textAlign={"center"} my={"15px"}>
											{otpStatusMessage}
										</Text>
									</Box>
								</>
							) : stepCounter === 2 ? (
								<>
									{/* 設定密碼 */}
									<Flex direction={"column"} gap={"10px"}>
										<InputGroup size='lg'>
											<Input
												pr='4.5rem'
												type={showPassword ? "text" : "password"}
												placeholder='請輸入您的密碼'
												value={password}
												onChange={handleInputPassword}
												onKeyDown={handleKeyPressForPasswordSubmit}
												onBlur={handleCheckPassword}
											/>
											<InputRightElement width='4.5rem'>
												<Button size='sm' onClick={handleShowPasswordToggle} colorScheme='rsSecondary'>
													{showPassword ? "Hide" : "Show"}
												</Button>
											</InputRightElement>
										</InputGroup>

										<InputGroup size='lg'>
											<Input
												pr='4.5rem'
												type={showPassword ? "text" : "password"}
												placeholder='再次輸入相同的密碼'
												value={passwordAgain}
												onChange={handleInputPwDoubleCheck}
												onKeyDown={handleKeyPressForPasswordSubmit}
											/>
											<InputRightElement width='4.5rem'>
												<Button size='sm' onClick={handleShowPasswordToggle} colorScheme='rsSecondary'>
													{showPassword ? "Hide" : "Show"}
												</Button>
											</InputRightElement>
										</InputGroup>

										<Button colorScheme='rsPrimary' onClick={handlePasswordSubmit}>
											送出 Submit
										</Button>

										<Text textAlign={"center"} textColor={checkPassword ? "rsPrimary.500" : "rsDanger.500"}>
											{passwordStatusMessage}
										</Text>

										<Box mt={"16px"} bg={"gray.150"} rounded={"4px"} p={"16px"} opacity={"0.8"}>
											<UnorderedList>
												<ListItem>不能包含全形字符</ListItem> <ListItem>長度必須至少為 8 個字符</ListItem>{" "}
												<ListItem>需包含至少 1 個英文字母</ListItem> <ListItem>需包含至少 1 個數字</ListItem>{" "}
												<ListItem>需包含至少 1 個特殊符號</ListItem>{" "}
												<ListItem>需包含至少 1 個小寫英文字母</ListItem>{" "}
												<ListItem>需包含至少 1 個大寫英文字母</ListItem>{" "}
											</UnorderedList>
										</Box>
									</Flex>
								</>
							) : stepCounter === 3 ? (
								<>
									{/* 完成設定 */}
									<Flex direction={"column"} gap={"5px"}>
										<Text textColor={"rsPrimary.500"}>恭喜您已完成設定！</Text>
										<Text textColor={"rsPrimary.500"}> 接下來您可以登入並使用系統了</Text>
										<Text textColor={"rsPrimary.500"}>＊請使用您的 email 作為帳號</Text>
									</Flex>
									<Button colorScheme={"rsPrimary"} w={"100%"} onClick={handleGoLogin}>
										前往系統網站
									</Button>
								</>
							) : (
								""
							)}
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};

export default AuthenticationPasswordPage;
