import { PrescriptionProps } from "store/TreatmentManagement";

export const D14Logic = (drug: PrescriptionProps, drugTypeCode: string) => {
	console.log(drugTypeCode, "這個藥的藥種碼");
	console.log(drug.drug_no.charAt(7), "這個藥健保碼第8馬");
	let D14 = "";
	if (drugTypeCode === "L") {
		D14 = "EXT";
	} else if ((drugTypeCode === "O" || drugTypeCode === "W") && (drug.drug_no.charAt(7) === "3" || drug.drug_no.charAt(7) === "4")) {
		D14 = "EXT";
	} else if (drugTypeCode === "O" || drugTypeCode === "W") {
		D14 = "PO";
	} else {
		D14 = "";
	}
	return D14;
};
