import React, { createContext, useContext, useState, ReactNode } from "react";

interface MainFunctionDndStatus {
	draggedItemId: string;
	draggedItemTitle: string;
	draggedItemUrl: string;
}

interface MainFunctionDndStatusContextValue {
	mainFunctionDndStatus: MainFunctionDndStatus;
	setMainFunctionDndStatus: React.Dispatch<React.SetStateAction<MainFunctionDndStatus>>;
}

const MainFunctionDndStatusContext = createContext<MainFunctionDndStatusContextValue | undefined>(undefined);

interface MainFunctionDndStatusProviderProps {
	children: ReactNode;
}

export const MainFunctionDndStatusProvider: React.FC<MainFunctionDndStatusProviderProps> = ({ children }) => {
	const [mainFunctionDndStatus, setMainFunctionDndStatus] = useState<MainFunctionDndStatus>({
		draggedItemId: "",
		draggedItemTitle: "",
		draggedItemUrl: ""
	});

	return (
		<MainFunctionDndStatusContext.Provider value={{ mainFunctionDndStatus, setMainFunctionDndStatus }}>
			{children}
		</MainFunctionDndStatusContext.Provider>
	);
};

export const useMainFunctionDndStatus = (): MainFunctionDndStatusContextValue => {
	const context = useContext(MainFunctionDndStatusContext);

	if (!context) {
		throw new Error("useMainFunctionDndStatus must be used within a MainFunctionDndStatusProvider");
	}

	return context;
};
