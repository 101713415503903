export const checkNhiDrug = (drug_no: string, special_mark: string): boolean => {
	let checkNhiDrug = false;
	if ((drug_no && drug_no.trim() === "") || drug_no === "XXXXXXXXXX" || ["X", "贈", "O"].some(char => special_mark.includes(char))) {
		checkNhiDrug = false;
	} else {
		checkNhiDrug = true;
	}

	return checkNhiDrug;
};
