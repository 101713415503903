import { useContext, useMemo } from 'react';
import { useWaitingListsStatus } from 'contexts/Registration/WaitingListsStatusContext';
import { OpdListsProps, OpdArrListsProps } from 'service/opdListsService';

export const useFilterOpdList = (opdLists: OpdListsProps) => {
    const {
        filterByDoctorStatus,
        filterByPeriodStatus,
        filterByPatientDataStatus,
    } = useWaitingListsStatus();

    return useMemo(() => {
        const regex = new RegExp(filterByPatientDataStatus, 'i');
        const filteredData: OpdListsProps = {};

        Object.entries(opdLists).forEach(([key, item]) => {
            if (
                (filterByPeriodStatus !== '' &&
                    item.shift_name !== filterByPeriodStatus) ||
                (filterByDoctorStatus !== '' &&
                    item.doctor_name !== filterByDoctorStatus)
            ) {
                return;
            }
            if (
                regex.test(item.patient_name) ||
                regex.test(item.birth_date) ||
                regex.test(item.medical_record_number)
            ) {
                filteredData[key] = item;
            }
        });
        return filteredData;
    }, [
        opdLists,
        filterByPeriodStatus,
        filterByDoctorStatus,
        filterByPatientDataStatus,
    ]);
};
