import React, { useEffect } from "react";
import { Box, Text, Flex, ListItem, List } from "@chakra-ui/react";
import { TransformButton } from "./TransformButton";
import Loading from "../../CustomComponents/Loading";
import { useDeclareProgressStore } from "store/DeclarationAudit/useDeclareProgressStore";
import { useDeclareListLoadingStatusStore } from "store/DeclarationAudit/useDeclareListLoadingStatusStore";
import { useDeclareTransferLoadingStatusStore } from "store/DeclarationAudit/useDeclareTransferLoadingStatusStore";

export const Transfer = () => {
	const { declareProgress } = useDeclareProgressStore();
	const { isLoading } = useDeclareListLoadingStatusStore();
	const { isTransferLoading } = useDeclareTransferLoadingStatusStore();

	return (
		<>
			{isLoading ? (
				<Box pos='relative' w='100%' h='300px'>
					<Loading />
				</Box>
			) : isTransferLoading ? (
				<Box pos='relative' w='100%' h='300px'>
					<Loading />
				</Box>
			) : (
				<>
					<Box>
						<TransformButton />
					</Box>

					<Box py={4} px={2}>
						<List spacing={1}>
							<ListItem>
								<Text as={"span"} bg={"linear-gradient(to top, #D2D2B3 0%, transparent 50%)"} px={1}>
									月份
								</Text>
								<Text fontWeight={"bold"} textColor={"rsPrimary.500"} pl={2}>
									{declareProgress.Month || "-"}
								</Text>
							</ListItem>
							<ListItem>
								<Text as={"span"} bg={"linear-gradient(to top, #D2D2B3 0%, transparent 50%)"} px={1}>
									狀態
								</Text>
								<Text fontWeight={"bold"} textColor={"rsPrimary.500"} pl={2}>
									{declareProgress.progress !== "" ? declareProgress.progress : "-"}
								</Text>
							</ListItem>
						</List>
					</Box>
				</>
			)}
		</>
	);
};
