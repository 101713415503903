import Swal from "sweetalert2";
import { RsGetStokenService } from "./RsGetStokenService";
import { useVirtualCardAuthorizeTokenStore } from "store";

export const useRsGetStokenHelper = () => {
    const { virtualCardAuthorizeToken, updateVirtualCardAuthorizeToken } = useVirtualCardAuthorizeTokenStore();

    const checkAndFetchToken = async (): Promise<string | null> => {
        console.log(virtualCardAuthorizeToken, '看一下virtualCardAuthorizeToken');
        
        // 如果 store 中没有 token，则去调用 API 获取
        if (!virtualCardAuthorizeToken.token) {
            try {
                const RsGetStokenResult = await RsGetStokenService();
                console.log(RsGetStokenResult, '看一下RsGetStokenResult');

                // 更新 store
                const token = RsGetStokenResult?.pBuffer ?? '';
                updateVirtualCardAuthorizeToken(token);
                console.log('有去取api');
                return token; // 返回新的 token
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: '請求虛擬卡授權憑證失敗',
                    text: '請稍後再試',
                    confirmButtonText: '確定',
                });
                console.log('没有去取api 也沒有');
                return null;
            }
        } else {
            // 如果 store 中已经有 token，直接返回
            console.log('直接有');
            return virtualCardAuthorizeToken.token;
        }
    };

    return { checkAndFetchToken };

};
