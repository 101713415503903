import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import HomeStatusBoard from "components/HomeComponents/HomeStatusBoard";
import HomeFunctionSide from "components/HomeComponents/HomeFunctionSide";
import HomeBottomSide from "components/HomeComponents/HomeBottomSide";

const HomePage = () => {
	const [device, setDevice] = useState<string>("");

	useEffect(() => {
		const width = window.innerWidth;
		if (width > 1200) {
			setDevice("pc");
		} else {
			setDevice("mobile");
		}
	});
	return (
		<>
			<Flex bg={"#F6EDDA"} pt={"24px"} px={"16px"} minH={"calc(100vh - 40px)"} direction={"column"} justify={"space-between"}>
				<Flex gap={"16px"} direction={device === "pc" ? "row" : "column"}>
					<Box flex={"1 1 auto"} px={"16px"}>
						<HomeStatusBoard />
					</Box>
					<Box>
						<HomeFunctionSide />
					</Box>
				</Flex>
				<HomeBottomSide />
			</Flex>
		</>
	);
};

export default HomePage;
