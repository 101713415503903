import React from "react";
import { Flex, Box } from "@chakra-ui/react";
import { PrescriptionInfo } from "./PrescriptionInfo";
import { GetMedTable } from "./GetMedTable";
export const Record = ({ refillable_uid }: { refillable_uid: string }) => {
	return (
		<Flex justify={"space-between"} w={"100%"}>
			<Box flex={"1 0 0%"} w={"50%"} overflow={"hidden"}>
				<PrescriptionInfo refillable_uid={refillable_uid} />
			</Box>
			<Box flex={"1 0 0%"} width={"50%"}>
				<GetMedTable refillable_uid={refillable_uid} />
			</Box>
		</Flex>
	);
};
