import { create } from 'zustand';

interface DeclarePageControlState {
    current_page: number;
    last_page: number;
    updateCurrentPage: (page: number) => void;
    updateLastPages: (total: number) => void;
    resetPageControl: () => void;
}

export const useDeclarePageControlStore =
    create<DeclarePageControlState>(set => ({
        current_page: 1,
        last_page: 1,
        updateCurrentPage: page => set({ current_page: page }),
        updateLastPages: total => set({ last_page: total }),
        resetPageControl: () => set({ current_page: 1, last_page: 1 }),
    }));
