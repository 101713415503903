import { ADDateToROCStringyyyMMddHHmmssNoDot } from "functions/ADDateToROCStringyyyMMddHHmmssNoDot";
import { ADDateToROCStringNoDot } from "functions/ADDateToROCStringNoDot";
import { mb1Props, TreatmentInfoProps } from "store/TreatmentManagement/useTreatmentInfoStore";
import { ICCardUpdateDrugProps } from "store/TreatmentManagement/ICCardUpdate/useICCardUpdateStore";
import { drugTypeListProps } from "service/drugTypeListService";
import { RegisterBasicProps } from "functions/hisGetRegisterBasic";
import { SeqNumber256N1DataProps } from "functions/hisGetSeqNumber256N1";
import { DrugFreqListsProps } from "store/DataSetting/useDrugFreqListStore";
import { hisGetTreatNumNoICCardResProps } from "functions/hisGetTreatNumNoICCard";
import { OriginPrescriptionProps } from "store/TreatmentManagement";
import { VHChisGetRegisterBasicJsonResProps } from "service/VHIC/VHChisGetRegisterBasicJsonService";
import { VHChisGetSeqNumber256N1ResultProps } from "service/VHIC/VHChisGetSeqNumber256N1Format";

type hisWritePrescriptionProps = {
	pDateTime: string;
	pPatientID: string;
	pPatientBirthDate: string;
	pDataWrite: string;
	iWriteCount: number;
};

export const hisWritePrescriptionLogic = (
	treatmentInfo: TreatmentInfoProps,
	ic_data_drugs: ICCardUpdateDrugProps[],
	drugFreqLists: DrugFreqListsProps[],
	seqNumber256N1Result?: SeqNumber256N1DataProps,
	basicResult?: RegisterBasicProps,
	hisGetTreatNumNoICCardResult?: hisGetTreatNumNoICCardResProps,
	medicalRecordStatus?: string,
	originPrescription?: OriginPrescriptionProps[],
	VHChisGetSeqNumber256N1Result?: VHChisGetSeqNumber256N1ResultProps,
	VHChisGetRegisterBasicJsonResult?: VHChisGetRegisterBasicJsonResProps
): Promise<hisWritePrescriptionProps> => {
	let originPrescriptionList;
	if (medicalRecordStatus === "1" || medicalRecordStatus === "2") {
		originPrescriptionList = originPrescription;
	}
	const pDateTime =
		VHChisGetSeqNumber256N1Result?.visitDateTime ||
		treatmentInfo.medical_record?.mb1.treated_at ||
		seqNumber256N1Result?.visitDateTime ||
		hisGetTreatNumNoICCardResult?.pBuffer.computerDateTime ||
		""; // 就診日期時間
	const pPatientBirthDate =
		VHChisGetRegisterBasicJsonResult?.birthDate ||
		basicResult?.birthDate ||
		(treatmentInfo.birth_date ? ADDateToROCStringNoDot(new Date(treatmentInfo.birth_date)) : "") ||
		"";
	const pPatientID =
		basicResult?.idOrDocumentNumber ||
		treatmentInfo.id_no ||
		hisGetTreatNumNoICCardResult?.pBuffer.medicalTreatmentIdentificationCode ||
		""; // 身分證號碼
	// let pDataWriteFake = `${pDateTime}1AC31912100        QID               3 3.00   01`;

	type PrescriptionItem = ICCardUpdateDrugProps | OriginPrescriptionProps;

	const processPrescriptionData = (
		pDateTime: string,
		drugFreqLists: DrugFreqListsProps[],
		prescriptionItem: PrescriptionItem,
		isOriginPrescription: boolean
	) => {
		let drugFreq = drugFreqLists.find((item: DrugFreqListsProps) => item.data_id === prescriptionItem.freq)?.code;

		const doseDays = "dose_days" in prescriptionItem ? prescriptionItem.dose_days : prescriptionItem.dose_day;
		const totalQty =
			isOriginPrescription && (medicalRecordStatus === "1" || medicalRecordStatus === "2")
				? (-Math.abs(Number(prescriptionItem.total_qty) || 0)).toString()
				: prescriptionItem.total_qty?.toString();

		return [
			pDateTime.padEnd(13, " "),
			"1".padEnd(1, " "),
			prescriptionItem.drug_no?.padEnd(12, " ") ?? "".padEnd(12, " "),
			prescriptionItem.site?.padEnd(6, " ") ?? "".padEnd(6, " "),
			drugFreq?.padEnd(18, " ") ?? "".padEnd(18, " "),
			doseDays?.toString().padEnd(2, " ") ?? "".padEnd(2, " "),
			totalQty?.toString().padEnd(7, " ") ?? "".padEnd(7, " "),
			prescriptionItem.memo?.padEnd(2, " ") ?? "".padEnd(2, " ")
		].join("");
	};
	const pDataWriteMinus = originPrescriptionList
		? originPrescriptionList.map(item => processPrescriptionData(pDateTime, drugFreqLists, item, true)).join("")
		: "";

	const pDataWrite = ic_data_drugs
		? ic_data_drugs.map(item => processPrescriptionData(pDateTime, drugFreqLists, item, false)).join("")
		: "";

	console.log(pDataWrite, "先看一下怎麼跑吧哎", ic_data_drugs);
	let finalpDataWrite;
	if (medicalRecordStatus === "1" || medicalRecordStatus === "2") {
		finalpDataWrite = pDataWriteMinus + pDataWrite;
	} else {
		finalpDataWrite = pDataWrite; // 添加一個 null 字符作為結尾
	}
	const finaliwriteCount =
		medicalRecordStatus === "1" || medicalRecordStatus === "2"
			? (originPrescription?.length ?? 0) + ic_data_drugs.length
			: ic_data_drugs.length;

	// 建立最終數據對象
	let finalData: hisWritePrescriptionProps = {
		pDateTime: pDateTime.toString().padEnd(14, " "), // 就診日期時間 1131008153719
		pPatientID: pPatientID.toString().padEnd(11, " "), // 身分證號碼 F131130735
		pPatientBirthDate: pPatientBirthDate.toString().padEnd(8, " "), // 出生日期 0900803
		pDataWrite: finalpDataWrite, // 拼接後的字符串數據 11310081537191AB203331G0        BID               3 18.0     \u0000
		iWriteCount: finaliwriteCount
	};

	console.log(finalData, "寫卡處方數據");
	return Promise.resolve(finalData);
};
