import React, { createContext, useContext, useState, ReactNode } from "react";

interface IsCopyDrugFromHistoryContextValue {
	isCopyDrugFromHistoryStatus: boolean;
	setIsCopyDrugFromHistoryStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsCopyDrugFromHistoryContext = createContext<IsCopyDrugFromHistoryContextValue | undefined>(undefined);

interface IsCopyDrugFromHistoryProviderProps {
	children: ReactNode;
}

export const IsCopyDrugFromHistoryProvider: React.FC<IsCopyDrugFromHistoryProviderProps> = ({ children }) => {
	const [isCopyDrugFromHistoryStatus, setIsCopyDrugFromHistoryStatus] = useState(false);

	return (
		<IsCopyDrugFromHistoryContext.Provider
			value={{
				isCopyDrugFromHistoryStatus,
				setIsCopyDrugFromHistoryStatus
			}}>
			{children}
		</IsCopyDrugFromHistoryContext.Provider>
	);
};

export const useIsCopyDrugFromHistoryStatus = (): IsCopyDrugFromHistoryContextValue => {
	const context = useContext(IsCopyDrugFromHistoryContext);

	if (!context) {
		throw new Error("useIsCopyDrugFromHistoryStatus must be used within a IsCopyDrugFromHistoryProvider");
	}

	return context;
};
