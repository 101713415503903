/* Hooks */
import { useEffect, useState } from 'react';
/* Status */
import { useLoadingStatus } from 'contexts/LoadingStatusContext';
import { Box, Flex, Button, Text, Heading } from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';

/* Components */
import Loading from 'components/CustomComponents/Loading';
import CCPEDxForm from 'components/TreatmentManagement/ConsultingRoomComponents/CCPEDxComponents';
import VitalSignsForm from 'components/TreatmentManagement/ConsultingRoomComponents/VitalSignsFormComponents';
import PrescriptionForm from 'components/TreatmentManagement/ConsultingRoomComponents/PrescriptionFormComponents';
import { BillingProcess } from './BillingProcess';
import { CreateReferral } from './BillingProcess/CreateReferral';
import Header from 'components/TreatmentManagement/ConsultingRoomComponents/Header';
import RoomInformation from 'components/TreatmentManagement/ConsultingRoomComponents/RoomInformation';
import { CompleteAction } from 'components/TreatmentManagement/ConsultingRoomComponents/CompleteActionComponents';
import { RightSideOptionSelector } from 'components/TreatmentManagement/ConsultingRoomComponents/RightSideOptionSelectorComponents/RightSideOptionSelector';
import { useIsSearchListsStatus } from 'contexts/TreatmentManagement/Prescription';
import {
    useIsBillingOpenStatus,
    useIsReferralOpenStatus,
} from 'contexts/TreatmentManagement/BillingProcess';

export const ConsultingRoom = () => {
    const { isBillingOpenStatus } = useIsBillingOpenStatus();
    const { isReferralOpenStatus } = useIsReferralOpenStatus();
    const { loadingStatus } = useLoadingStatus();
    const { isSearchListsStatus } = useIsSearchListsStatus();
    return (
        <>
            <Header />
            <Box
                py={'8px'}
                px={'20px'}
                bg={'#f5f5f5'}
                minH={'calc(100dvh - 40px)'}
            >
                {loadingStatus && <Loading />}
                <Box mb={'8px'} data-section="top">
                    <RoomInformation />
                </Box>
                <Flex gap={'8px'}>
                    <Box flex={'1 1 66.66%'} data-section="left">
                        <Flex gap={'8px'} mb={'8px'} minW={'500px'}>
                            <VitalSignsForm />

                            <Flex direction={'column'} gap={'8px'}>
                                <CCPEDxForm />
                            </Flex>
                        </Flex>

                        <PrescriptionForm />
                        <CompleteAction />
                    </Box>
                    <Box flex={'1 1 33.33%'} data-section="right">
                        <Box
                            py={'8px'}
                            px={'12px'}
                            bg={'#fff'}
                            boxShadow={'inset 4px 2px 2px rgba(0,0,0,0.25)'}
                            rounded={'8px'}
                            h={`calc(${window.innerHeight}px - 41px - 8px - 8px - 44px - 8px)`}
                            overflow={'auto'}
                        >
                            <RightSideOptionSelector />
                        </Box>
                    </Box>
                </Flex>
            </Box>
            {isBillingOpenStatus && <BillingProcess />}
            {/* {isReferralOpenStatus && <CreateReferral />} */}
        </>
    );
};
