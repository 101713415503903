import apiService from "./apiService";

export interface medicalStaffListsProps {
	name: string;
	data_id: string;
	identity: string;
	color: string;
}
// 取得院內人員清單
export const callMedicalStaffListService = async (token: string, data?: string) => {
	const queryParams = data ? { data } : undefined;
	return apiService<string, any>({
		method: "GET",
		path: "/api/medical_staff/list",
		token,
		data: queryParams
	});
};

// export const callMedicalStaffListService = async (
//     token: string,
//     identity?: string
// ) => {
//     try {
//         const queryParams = { identity };
//         const headers = {
//             'Api-Token': `${token}`,
//         };
//         const response = await axiosInstance.get('/api/medical_staff/list', {
//             headers,
//             params: queryParams,
//         });
//         const result = response.data;
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
