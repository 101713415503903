import { Center, Box } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import ROCDatePicker from "../../CustomComponents/ROCDatePicker";
import moment from "moment";
import { useICCardUpdateScopeStore } from "../../../store/ICCardUpdate/useICCardUpdateScopeStore";
import Swal from "sweetalert2";

export const Date2DatePick = () => {
	const { icCardUpdateScopeData, updateICCardUpdateScopeData } = useICCardUpdateScopeStore();

	// 設置起始日期的狀態。如果 icCardUpdateScopeData 中有起始日期，則使用該日期；
	// 否則，默認設置為昨天的日期。這樣可以確保始終有一個有效的起始日期。
	const [selectedStartDate, setSelectedStartDate] = useState<Date>(
		icCardUpdateScopeData.start_date ? new Date(icCardUpdateScopeData.start_date) : new Date()
	);
	const [selectedEndDate, setSelectedEndDate] = useState<Date>(
		icCardUpdateScopeData.end_date ? new Date(icCardUpdateScopeData.end_date) : new Date()
	);

	const [preSelectedStartDate, setPreSelectedStartDate] = useState<Date>(new Date());
	const [preSelectedEndDate, setPreSelectedEndDate] = useState<Date>(new Date());

	const handleChangeStartDate = (date: Date | null) => {
		if (date && moment(date).isSameOrBefore(moment(), "day")) {
			if (date > selectedEndDate) {
				// 如果選擇的起始日期晚於結束日期，將結束日期設為起始日期
				setSelectedEndDate(date);
			}
			setSelectedStartDate(date);
		} else {
			setSelectedStartDate(new Date());
		}
	};

	const handleChangeEndDate = (date: Date | null) => {
		if (date && moment(date).isSameOrBefore(moment(), "day")) {
			if (date < selectedStartDate) {
				Swal.fire({
					icon: "warning",
					title: "日期錯誤",
					text: "迄日不能低於起日"
				});
				setSelectedEndDate(preSelectedEndDate);
			} else {
				setSelectedEndDate(date);
			}
		} else {
			setSelectedEndDate(new Date());
		}
	};

	const handleFocusStartDate = () => {
		setPreSelectedStartDate(selectedStartDate);
		// 移除 setSelectedStartDate(new Date());
	};

	const handleFocusEndDate = () => {
		setPreSelectedEndDate(selectedEndDate);
		// 移除 setSelectedEndDate(new Date());
	};

	const handleBlurStartDate = (event: React.FocusEvent<HTMLInputElement>) => {
		const enteredValue = event.currentTarget.value.replace(/\./g, "");

		if (enteredValue.length < 6) {
			setSelectedStartDate(preSelectedStartDate);
			return;
		}

		const yearOffset = enteredValue.length === 7 ? 0 : 1;
		const year = parseInt(enteredValue.substring(0, 3 - yearOffset)) + 1911;
		const month = enteredValue.substring(3 - yearOffset, 5 - yearOffset);
		const day = enteredValue.substring(5 - yearOffset);

		const formattedDate = `${year}-${month}-${day}`;
		const enteredDate = moment(formattedDate, "YYYY-MM-DD");

		if (!enteredDate.isValid() || enteredDate.isAfter(moment(), "day")) {
			setSelectedStartDate(preSelectedStartDate);
		} else if (enteredDate.toDate() !== selectedStartDate) {
			handleChangeStartDate(enteredDate.toDate());
		}
	};

	const handleBlurEndDate = (event: React.FocusEvent<HTMLInputElement>) => {
		const enteredValue = event.currentTarget.value.replace(/\./g, "");

		if (enteredValue.length < 6) {
			setSelectedEndDate(preSelectedEndDate);
			return;
		}

		const yearOffset = enteredValue.length === 7 ? 0 : 1;
		const year = parseInt(enteredValue.substring(0, 3 - yearOffset)) + 1911;
		const month = enteredValue.substring(3 - yearOffset, 5 - yearOffset);
		const day = enteredValue.substring(5 - yearOffset);

		const formattedDate = `${year}-${month}-${day}`;
		const enteredDate = moment(formattedDate, "YYYY-MM-DD");

		if (!enteredDate.isValid() || enteredDate.isAfter(moment(), "day")) {
			setSelectedEndDate(preSelectedEndDate);
		} else if (enteredDate.toDate() !== selectedEndDate) {
			handleChangeEndDate(enteredDate.toDate());
		}
	};

	useEffect(() => {
		if (!icCardUpdateScopeData.start_date) {
			const yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");
			updateICCardUpdateScopeData({
				...icCardUpdateScopeData,
				start_date: yesterday
			});
		}
		if (!icCardUpdateScopeData.end_date) {
			const today = moment().format("YYYY-MM-DD");
			updateICCardUpdateScopeData({
				...icCardUpdateScopeData,
				end_date: today
			});
		}
	}, []);

	useEffect(() => {
		const formattedStartDate = moment(selectedStartDate).format("YYYY-MM-DD");
		const formattedEndDate = moment(selectedEndDate).format("YYYY-MM-DD");
		updateICCardUpdateScopeData({
			...icCardUpdateScopeData,
			start_date: formattedStartDate,
			end_date: formattedEndDate
		});
	}, [selectedStartDate, selectedEndDate]);

	return (
		<Center gap={"4px"} zIndex={20}>
			<ROCDatePicker
				onChange={handleChangeStartDate}
				selectedDate={selectedStartDate}
				handleChangeDate={handleChangeStartDate}
				onFocus={handleFocusStartDate}
				onBlur={handleBlurStartDate}
				maxDate={new Date()}
			/>
			<Box>-</Box>
			<ROCDatePicker
				onChange={handleChangeEndDate}
				selectedDate={selectedEndDate}
				handleChangeDate={handleChangeEndDate}
				onFocus={handleFocusEndDate}
				onBlur={handleBlurEndDate}
				maxDate={new Date()}
			/>
		</Center>
	);
};
