import axios, { AxiosResponse, Method } from "axios";

import axiosInstance from "./axiosInstance";

interface ApiServiceParams<T> {
	method: Method;
	path: string;
	token?: string;
	data?: T | Record<string, any>;
	responseType?: "arraybuffer" | "blob" | "document" | "json" | "text" | "stream";
}

async function apiService<T, R = any>({ method, path, token, data, responseType }: ApiServiceParams<T>): Promise<R> {
	let url = path;
	console.log(url, "apiService url: ");
	if (data && method === "GET") {
		const queryString = new URLSearchParams(data as any).toString();
		url += `?${queryString}`;
	}

	try {
		const headers = {
			"Api-Token": token,
			"Content-Type": "application/json"
		};

		let response: AxiosResponse<R>;
		if (method === "GET" && data) {
			response = await axiosInstance.request<R>({
				method: "get",
				url: path,
				headers: headers,
				params: data,
				responseType: responseType
			});
		} else if (method === "DELETE" && data) {
			response = await axiosInstance.request<R>({
				method: "delete",
				url: path,
				headers: headers,
				data: data
			});
		} else {
			switch (method) {
				case "GET":
					response = await axiosInstance.get<R>(path, { headers });

					break;
				case "POST":
					// console.log('Post api service', data);
					response = await axiosInstance.post<R>(path, data, {
						headers
					});
					break;
				case "DELETE":
					response = await axiosInstance.delete<R>(path, { headers });
					break;
				default:
					throw new Error(`Unsupported method: ${method}`);
			}
		}

		// console.log('API response:', response.data, token, url);
		return response.data;
	} catch (error: any) {
		if (axios.isAxiosError(error)) {
			// console.error('Axios error:', error.message, error);
			const customError = {
				message: error.response?.data.message || "後端服務錯誤,稍後再試",
				status: error.response?.status || 500
			};
			throw customError;
		} else {
			console.error("An unexpected error occurred:", error.message);
			throw new Error("An unexpected error occurred");
		}
	}
}
export default apiService;
