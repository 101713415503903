import apiService from "./apiService";

// type MedicalRecord = {
//     treated_at: string; // 就醫日期時間, 西元年月日YYYY-mm-dd
//     data_id: string; // 病歷資料id
//     case_type: string; // 案件分類
//     card_no: string; // 卡號
//     doctor_name: string; // 該次看診醫師姓名
//     icd_code: string; // 主診斷國際病名碼
//     diagnosis_name: string; // 診斷名稱
// };

// 17. 取得特定病患的就醫日期清單（歷史病歷用）
export const callMedicalRecordHistoryDataListsService = async (token: string, data?: string) => {
	const queryParams = data ? { data } : undefined;
	return apiService<string, any>({
		method: "GET",
		path: "/api/medical_record/history/date_lists",
		token,
		data: queryParams
	});
};
