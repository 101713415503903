import { useTreatmentInfoStore } from 'store/TreatmentManagement';
import { callMedicalStaffSpecService } from 'service/medicalStaffSpecService';
import { callRsGetPeaCareNameService } from 'service/RsService/RsGetPeaCarenameService';
import { ADDateToROCString } from 'functions/ADDateToROCString';
import Swal from 'sweetalert2';

export const useControlledDrugSearch = () => {
    const { treatmentInfo } = useTreatmentInfoStore();

    const callMedicalStaffSpecAPI = async (token: string) => {
        try {
            const result = await callMedicalStaffSpecService(
                token,
                treatmentInfo.doctor_uid
            );
            if (!result) {
                Swal.fire({
                    icon: 'error',
                    title: '調用醫療人員規格API時出錯',
                    text: '請稍後再試',
                    confirmButtonText: '確定',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: '調用醫療人員規格API時出錯',
                text: '請稍後再試',
                confirmButtonText: '確定',
            });
        }
    };

    const callRsGetPeaCarenameAPI = async () => {
        if (treatmentInfo.birth_date) {
            const formatPatBirth = ADDateToROCString(treatmentInfo.birth_date);
            const splitFormatPatBirth = formatPatBirth.replace(/\./g, '').padStart(7, '0');
            let finalData = {
                dr_idno: treatmentInfo.doctor_id_no,
                pat_idno: treatmentInfo.id_no,
                pat_birth: splitFormatPatBirth,
                com_port: '0'
            };

            try {
                const result = await callRsGetPeaCareNameService(finalData);
                if (result) {
                    if (result.ERRORCODE == 0) {
                        const url = result.pBuffer;
                        const windowFeatures = 'width=980,height=567,top=0,left=0';
                        if (url) {
                            const newWindow = window.open(url, '_blank', windowFeatures);
                            if (newWindow) {
                                newWindow.focus();
                            }
                        }
                    } else if (result.ERRORCODE && result.ERRORCODE !== 0) {
                        Swal.fire({
                            icon: 'error',
                            title: '開啟藥品查詢視窗失敗',
                            text: '請稍後再試',
                            confirmButtonText: '確定',
                        });
                    }
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: '開啟藥品查詢視窗失敗',
                    text: '請稍後再試',
                    confirmButtonText: '確定',
                });
            }
        }
    };

    const handleOpenControlledDrugWindow = async (token: string) => {
        try {
            await callMedicalStaffSpecAPI(token);
            await callRsGetPeaCarenameAPI();
        } catch (error) {
            console.error('發生錯誤:', error);
            Swal.fire({
                icon: 'error',
                title: '操作失敗',
                text: '請稍後再試',
                confirmButtonText: '確定',
            });
        }
    };

    return { handleOpenControlledDrugWindow };
};