import { create } from "zustand";

//一開始給歷史詳細的資料

type InfoProps = {
	data_id: string;
	cc: string;
	treated_at: string;
	case_type: string;
	medical_subject: string;
	doctor_name: string;
	pharmacist_name: string;
};
type DiagnosisProps = {
	icd_10_in_use_uid: string;
	short_code: string;
	icd_code: string;
	ds: string;
	name_tw: string;
	name_en: string;
};
export type DrugProps = {
	controlled_level: string | null;
	cost: string | null;
	disabled_date: string | null;
	dosage_form: string;
	dose_days: string | null;
	drug_no: string;
	drug_price: string;
	drug_type: string;
	drug_type_code: string;
	drugs_in_use_uid: string;
	freq: string;
	freq_code: string;
	freq_custom_code: string;
	freq_directions: string;
	given_qty: string;
	ing_qty_unit: string;
	max_dose: string | null;
	max_dose_day: string | null;
	name_en: string;
	name_tw: string;
	qty: string;
	qty_in_day: string;
	route_of_administration: string | null;
	self_price: string | null;
	short_code: string;
	site: string | null;
	special_mark: string | null;
	total_qty: string;
};
export interface historyProps {
	info?: InfoProps;
	diagnosis?: DiagnosisProps[];
	drugs: DrugProps[];
}
const initialHistoryData: historyProps = {
	info: {
		data_id: "",
		cc: "",
		treated_at: "",
		case_type: "",
		medical_subject: "",
		doctor_name: "",
		pharmacist_name: ""
	},
	diagnosis: [],
	drugs: []
};
interface historyState {
	historyData: historyProps;
	updateHistoryData: (newData: Partial<historyProps>) => void;
	resetHistoryData: () => void;
}

export const useDeclareListHistoryDataStore = create<historyState>(set => ({
	historyData: initialHistoryData,
	updateHistoryData: newData =>
		set(state => ({
			historyData: { ...state.historyData, ...newData }
		})),
	resetHistoryData: () =>
		set(() => ({
			historyData: initialHistoryData
		}))
}));
