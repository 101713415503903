import { RshisGetCardStatusService } from "service/RsService/RshisGetCardStatusService";

type cardType = {
	cardType: string;
};

type hisGetCardStatusResultType = {
	ERRORCODE: number;
	pBuffer: string;
};

export const hisGetCardStatus2 = async (data: string): Promise<string> => {
	const finalData: cardType = {
		cardType: data
	};
	try {
		const result = await RshisGetCardStatusService(finalData);
		console.log("RshisGetCardStatusService API 返回結果", result);
		const hisGetCardStatusResultJSON = JSON.parse(result);

		return hisGetCardStatusResultJSON.ERRORCODE.toString();
	} catch (error) {
		console.error("調用 RshisGetCardStatusService 時發生錯誤:", error);
		return "-1";
	}
};
