import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Text, Box } from "@chakra-ui/react";

import { useDeclareListHistoryDataStore } from "store/DeclarationAudit/useDeclareListHistoryDataStore";
import { useDeclareHistoryLoadingStatusStore } from "store/DeclarationAudit/useDeclareHistoryLoadingStatusStore";
import Loading from "components/CustomComponents/Loading";
export const DxList = () => {
	const { historyData } = useDeclareListHistoryDataStore();
	const { isHistoryLoading } = useDeclareHistoryLoadingStatusStore();

	return (
		<TableContainer my={"12px"} h={"120px"} overflowY={"scroll"}>
			<Table variant={"striped"} size={"sm"}>
				<Thead bg={"#D9D9D9"} pos={"sticky"} top={0} left={0} zIndex={"10"}>
					<Tr>
						<Th p={"4px 8px"}>
							<Text fontSize={"md"}>排序</Text>
						</Th>
						<Th p={"4px 8px"}>
							<Text fontSize={"md"}>代碼</Text>
						</Th>
						<Th p={"4px 8px"}>
							<Text fontSize={"md"}>ICD10</Text>
						</Th>
						<Th p={"4px 8px"}>
							<Text fontSize={"md"}>中文病名</Text>
						</Th>
						<Th p={"4px 8px"}>
							<Text fontSize={"md"}>部位</Text>
						</Th>
					</Tr>
				</Thead>
				<Tbody pos={"relative"}>
					{isHistoryLoading ? (
						<Td pos={"relative"} w={"100%"} h={"80px"} colSpan={5}>
							<Loading />
						</Td>
					) : (
						<>
							{historyData.diagnosis &&
								historyData.diagnosis.map((item, index) => (
									<Tr key={index}>
										<Td p={"4px 8px"}>{index + 1}</Td>
										<Td p={"4px 8px"}>{item.short_code}</Td>
										<Td p={"4px 8px"}>{item.icd_code}</Td>
										<Td p={"4px 8px"}>{item.name_tw}</Td>
										<Td p={"4px 8px"}>{item.ds}</Td>
									</Tr>
								))}

							{historyData.diagnosis && historyData.diagnosis.length === 0 && (
								<Tr>
									<Td p={"4px 8px"} colSpan={5}>
										<Text fontSize={"md"}>無資料</Text>
									</Td>
								</Tr>
							)}
						</>
					)}
				</Tbody>
			</Table>
		</TableContainer>
	);
};
