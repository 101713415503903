import React, { createContext, useContext, useState, ReactNode } from "react";

interface CalculationCompleteContextValue {
	calculationComplete: boolean;
	setCalculationComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

const CalculationCompleteContext = createContext<CalculationCompleteContextValue | undefined>(undefined);

interface CalculationCompleteProviderProps {
	children: ReactNode;
}

export const CalculationCompleteProvider: React.FC<CalculationCompleteProviderProps> = ({ children }) => {
	const [calculationComplete, setCalculationComplete] = useState(false);
	return (
		<CalculationCompleteContext.Provider
			value={{
				calculationComplete,
				setCalculationComplete
			}}>
			{children}
		</CalculationCompleteContext.Provider>
	);
};

export const useCalculationCompleteStatus = (): CalculationCompleteContextValue => {
	const context = useContext(CalculationCompleteContext);

	if (!context) {
		throw new Error("useCalculationCompleteStatus must be used within a CalculationCompleteProvider");
	}

	return context;
};
