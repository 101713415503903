import React, { useState, useEffect } from "react";
import { Tabs, Tab, TabList, TabPanel, TabPanels, TabIndicator } from "@chakra-ui/react";
import MedicalInstitutionBasicSetting from "../../components/DataSettings/MedicalInstitutionDataSettingComponents/MedicalInstitutionBasicSetting";
import MedicalInstitutionClinicSetting from "../../components/DataSettings/MedicalInstitutionDataSettingComponents/MedicalInstitutionClinicSetting";
import { MedicalInstitutionPeriodSetting } from "../../components/DataSettings/MedicalInstitutionDataSettingComponents/MedicalInstitutionPeriodSetting";
import { useSidebarStatus } from "contexts/SidebarStatusContext";
import { useUnsavedChanges } from "contexts/UnsavedChangesContext";
import { MedicalInstitutionVirtualCardSetting } from "../../components/DataSettings/MedicalInstitutionDataSettingComponents/MedicalInstitutionVirtualCardSetting";

const MedicalInstitutionDataSettingPage = () => {
	const { setSidebarStatus } = useSidebarStatus();
	const [windowHeight, setWindowHeight] = useState(window.innerHeight);
	const [finalHeight, setFinalHeight] = useState(0);
	const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();
	const [basicSettingEditStatus, setBasicSettingEditStatus] = useState<boolean>(true);

	useEffect(() => {
		const handleResize = () => {
			setWindowHeight(window.innerHeight);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		const finalHeight = windowHeight - 120;
		setFinalHeight(finalHeight);
	}, [windowHeight]);

	const [tabIndex, setTabIndex] = useState<number>(0);

	const handleChangeTabIndex = (event: any) => {
		setBasicSettingEditStatus(true);
	};
	const [bgOpenStatus, setBgOpenStatue] = useState(false);
	const handleOpen = () => {
		setBgOpenStatue(true);
	};
	const handleClose = () => {
		setBgOpenStatue(false);
	};

	const tabItemStyle = {
		textColor: "rsPrimary.500",
		fontWeight: "bold",
		opacity: 0.5,
		_selected: {
			opacity: 1
		}
	};

	return (
		<>
			<Tabs>
				<TabList borderRadius={"lg"} bgColor={"gray.50"} borderBottom={"2px solid #ccc"} px={"16px"} py={"8px"}>
					<Tab
						sx={tabItemStyle}
						isDisabled={tabIndex == 0 ? false : hasUnsavedChanges ? true : false}
						onClick={handleChangeTabIndex}>
						基本資料
					</Tab>
					<Tab
						sx={tabItemStyle}
						isDisabled={tabIndex == 1 ? false : hasUnsavedChanges ? true : false}
						onClick={handleChangeTabIndex}>
						時段設定
					</Tab>
					<Tab
						sx={tabItemStyle}
						isDisabled={tabIndex == 3 ? false : hasUnsavedChanges ? true : false}
						onClick={handleChangeTabIndex}>
						診間設定
					</Tab>
					<Tab
						textColor={"rsPrimary.500"}
						fontWeight={"bold"}
						opacity={0.5}
						_selected={{
							opacity: 1
						}}
						isDisabled={tabIndex == 3 ? false : hasUnsavedChanges ? true : false}
						onClick={handleChangeTabIndex}>
						虛擬卡設定
					</Tab>
				</TabList>
				<TabIndicator mt='-16px' height='2px' bg='#9B9B7A' borderRadius='1px' />

				<TabPanels
					pos={"relative"}
					// bgColor={'gray.50'}
					// borderRadius={'15px'}
					// borderBottom={'2px solid #ccc'}
					// mt={'8px'}
				>
					<TabPanel>
						<MedicalInstitutionBasicSetting
							basicSettingEditStatus={basicSettingEditStatus}
							setBasicSettingEditStatus={setBasicSettingEditStatus}
						/>
					</TabPanel>
					<TabPanel>
						<MedicalInstitutionPeriodSetting />
					</TabPanel>
					<TabPanel>
						<MedicalInstitutionClinicSetting />
					</TabPanel>
					<TabPanel>
						<MedicalInstitutionVirtualCardSetting />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</>
	);
};

export default MedicalInstitutionDataSettingPage;
