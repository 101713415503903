import apiService from './apiService';

export const callMedicalRecordHistoryDeclareService = async (
    token: string,
    data?: string
) => {
    const queryParams = data ? { data } : undefined;
    const response = await apiService<string, any>({
        method: 'GET',
        path: '/api/medical_record/history/declare',
        token,
        data: queryParams,
    });

    if (response.result === 'success') {
        // 將 drugs 中的 special_mark 由陣列轉成字串
        if (response.drugs && Array.isArray(response.drugs)) {
            response.drugs = response.drugs.map((drug: any) => ({
                ...drug,
                special_mark: Array.isArray(drug.special_mark)
                    ? drug.special_mark.join(',')
                    : drug.special_mark,
            }));
        }

        return {
            result: 'success',
            info: response.info,
            diagnosis: response.diagnosis,
            drugs: response.drugs,
        };
    } else {
        return {
            result: 'failure',
            msg: response.msg || '獲取特定病患就醫資訊失敗',
        };
    }

    return;
};
