import apiService from './apiService';

export type QueueDeclareReqDataProps = {
    ym?: string;
};

export type QueueDeclareResProps = {
    result: string;
    msg: string;
};

export const callQueueDeclareService = async (
    token: string,
    data: QueueDeclareReqDataProps
): Promise<QueueDeclareResProps> => {
    return apiService<string, any>({
        method: 'GET',
        path: '/api/queue/declare',
        token,
        data: data,
    });
};
