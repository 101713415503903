/* Hooks */
import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

import "./index.css";
import "moment/locale/zh-tw";

import { ChakraProvider, Flex, Box, Tooltip } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import Swal from "sweetalert2";

/* import components */
import Header from "./components/Header";
import Breadcrumb from "./components/Breadcrumbs";
import Sidebar from "./components/Sidebar";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";

/* import pages */
import LoginPage from "./pages/LoginPage";
import SchedulePage from "./pages/Registration/RegisteredPage";
import OPDPage from "./pages/TreatmentManagement/ConsultingWaitingListPage";
import RegisteredPage from "./pages/Registration/RegisteredPage";
import HomePage from "./pages/HomePage";
import MedicalInstitutionDataSettingPage from "./pages/DataSetting/MedicalInstitutionDataSettingPage";
import DiseaseNameDataMaintenancePage from "./pages/DataSetting/DiseaseNameDataMaintenancePage";
import PreferentialStatusPage from "pages/DataSetting/PreferentialStatusPage";
import FeeSettingPage from "pages/DataSetting/FeeSettingPage";
import DrugDataMaintenancePage from "pages/DataSetting/DrugDataMaintenancePage";
import ICCardUpdatePage from "pages/ICCardUpdatePage";
import DeclarationAuditPage from "pages/DeclarationAuditPage";
import PersonnelProfileSettingPage from "pages/DataSetting/PersonnelProfileSettingPage";
import GroupPermissionSettingPage from "pages/DataSetting/GroupPermissionSettingPage";
import NumberingRulePage from "pages/DataSetting/NumberingRulePage";
import WorkingSchedulePage from "pages/DataSetting/WorkingSchedulePage";
import DrugFrequenceSettingPage from "pages/DataSetting/DrugFrequenceSettingPage";
import AuthenticationPasswordPage from "pages/AuthenticationPasswordPage";
import { ConsultingRoomPage } from "pages/TreatmentManagement/ConsultingRoomPage";

/* contexts */
import { SidebarStatusProvider } from "./contexts/SidebarStatusContext";
import { ShowEditStatusProvider } from "./contexts/ShowEditDndContext";
import { ShowMainFunctionDeleteIconProvider } from "./contexts/ShowMainFunctionDeleteIconContext";
import { RegisteredSearchResultStatusProvider } from "./contexts/RegisteredSearchResultContext";
import { LoadingStatusProvider } from "./contexts/LoadingStatusContext";
import { LoginAPIStatusProvider } from "./contexts/LoginAPIStatusContext";
import { RegisteredTableHeightStatusProvider } from "./contexts/RegisteredTableHeightContext";
import { OPDTableHeightStatusProvider } from "./contexts/OPDTableHeightContext";
import { HealthCheckRegisteredTableHeightStatusProvider } from "./contexts/HealthCheckRegisteredTableHeightContext";
import { ShowRegisteredFormStatusProvider } from "contexts/ShowRegisteredFormStatusContext";
import { ShowPatientManagementFormStatusProvider } from "contexts/ShowPatientManagementFormStatusContext";
import { ShowICCardRegisteredFormStatusProvider } from "contexts/ShowICCardRegisteredFormStatusContext";
import { AutoSavedStatusProvider } from "contexts/AutoSavedStatusContext";
import { RegisteredFlowStatusProvider } from "contexts/RegisteredFlowStatusContext";
import { SignProvider } from "contexts";
import { IsICWriteInOpenProvider } from "contexts/ICUpload/IsICWriteInOpen";
import { IsVisitedWaitingListFeeDetailOpenProvider } from "contexts/IsVisitedWaitingListFeeDetailOpenContext";
import { MedicalRecordStatusProvider } from "contexts/TreatmentManagement/MedicalRecordStatusContext";
import { WaitingTableTabIndexProvider } from "contexts";
import { VirtualCardStateProvider } from "contexts/VirtualCardContext";
import { IsAuthorizeStatusProvider } from "contexts/TreatmentManagement/BillingProcess/IsAuthorizeStatusContext";
import { IsOpenDetailStatusProvider } from "contexts/IsOpenDetailStatusContext";
import { IsManualInputStatusProvider } from "contexts/isManualInputStatusContext";
/* services */
import { setupAxiosInterceptors } from "./service/setupAxiosInterceptors";
import { callSystemFunctionTreeService, SystemFunctionTreeResult } from "./service/systemFunctionTreeService";

/* store */
import { useUserStore } from "store";
import { useCookies, CookiesProvider } from "react-cookie";
import jwtDecode from "jwt-decode";

/* Status  */
import { useMainFunctionDndStatus } from "./contexts/MainFunctionDndStatusContext";
import { useUnsavedChanges } from "contexts/UnsavedChangesContext";
import { useNavigationStatus } from "contexts/NavigationStatusContext";
import { useLogoutStatus } from "contexts/LogoutStatusContext";

import theme from "./theme";
import { HospInfoProps } from "./store/useUserStore";

type NavigationItem2 = {
	name: string;
	path?: string;
	items?: NavigationItem2[];
	key: string;
};

function App() {
	const [cookies, setCookie, removeCookie] = useCookies(["token"]);

	const navigation2FromLocalStorage = localStorage.getItem("navigation2");
	setupAxiosInterceptors();
	const { logoutStatus, setLogoutStatus } = useLogoutStatus();
	const [isChecked, setIsChecked] = useState(false);
	/* components */
	// const { navigationStatus, setNavigationStatus } = useNavigationStatus();
	// const { hasUnsavedChanges } = useUnsavedChanges();
	// const { setMainFunctionDndStatus } = useMainFunctionDndStatus();
	// const navigate = useNavigate();
	/* declare */
	const queryClient = new QueryClient();
	const hasAlertedRef = useRef(false); // 超時登出判斷alert觸發沒
	const [isVisible, setIsVisible] = useState(true);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const { userInfo, updateUserInfo } = useUserStore();
	// const [scrollLocked, setScrollLocked] = useState(false);
	const [sidebarHidden, setSidebarHidden] = useState(false);
	const [sideBarOpen, setSidBarOpen] = useState(false);
	const navigate = useNavigate();
	const sidebarOpenRef = useRef();
	const location = useLocation();
	const handleShowSidebarOpen = () => setSidBarOpen(!sideBarOpen);
	const [navigation2, setNavigation2] = useState<NavigationItem2[]>([]);
	window.addEventListener("error", event => {
		console.error("Global Error caught:", event.error);
		Swal.fire({
			icon: "error",
			title: "發生錯誤",
			text: "應用程式發生異常，請稍後再試。"
		});
	});

	window.addEventListener("unhandledrejection", event => {
		console.error("Unhandled promise rejection:", event.reason);
		Swal.fire({
			icon: "error",
			title: "發生錯誤",
			text: "應用程式發生異常，請稍後再試。"
		});
	});

	const callSystemFunctionTreeAPI = async () => {
		if (cookies.token) {
			try {
				const result = await callSystemFunctionTreeService(cookies.token);
				if (result) {
					if (result.result === "success") {
						setNavigation2(result.items);
					}
				} else {
					return;
				}
			} catch {
				Swal.fire({
					icon: "error",
					title: "後端服務錯誤",
					timer: 1500,
					showConfirmButton: false
				});
			}
		}
	};
	useEffect(() => {
		const navigation2FromLocalStorage = localStorage.getItem("navigation2");
		if (isLoggedIn && isChecked && (!navigation2FromLocalStorage || navigation2FromLocalStorage === "[]")) {
			callSystemFunctionTreeAPI();
		} else if (navigation2FromLocalStorage && navigation2FromLocalStorage !== "[]") {
			try {
				// console.log('進來解析');
				const parsedNavigation = JSON.parse(navigation2FromLocalStorage);
				setNavigation2(Array.isArray(parsedNavigation) ? parsedNavigation : Object.values(parsedNavigation));
			} catch (error) {
				console.error("Error parsing navigation2 from localStorage:", error);
				callSystemFunctionTreeAPI();
			}
		}
	}, [isLoggedIn, isChecked, navigation2FromLocalStorage]);

	/* get the expirationTime if overTime log out */
	// useEffect(() => {
	//     const expirationTime = localStorage.getItem('expirationTime');
	//     if (expirationTime) {
	//         const currentTime = new Date().getTime();
	//         if (currentTime > parseInt(expirationTime)) {
	//             localStorage.removeItem('isLoggedIn');
	//             localStorage.removeItem('expirationTime');
	//             setIsLoggedIn(false);
	//             navigate('/');
	//         } else {
	//             setIsLoggedIn(true);
	//         }
	//     }
	// }, []);

	/* 在頁面載入時綁定虛擬鍵盤事件 */
	window.addEventListener("resize", adjustPageHeightOnVirtualKeyboard);

	function adjustPageHeightOnVirtualKeyboard() {
		const windowHeight = window.innerHeight;
		const outerHeight = window.outerHeight;

		// 設定一個閾值，當兩個高度相差超過閾值時視為虛擬鍵盤開啟
		const threshold = 200; // 可自行調整

		// 判斷虛擬鍵盤是否開啟
		const isVirtualKeyboardOpen = windowHeight + threshold < outerHeight;

		// 調整頁面高度，以避免頁面位置變動
		if (isVirtualKeyboardOpen) {
			// 虛擬鍵盤開啟，調整頁面高度
			document.body.style.height = `${outerHeight}px`;
		} else {
			// 虛擬鍵盤關閉，恢復原來的頁面高度
			document.body.style.height = "100vh";
		}
	}

	window.removeEventListener("resize", adjustPageHeightOnVirtualKeyboard);

	/* about the dnd  */
	// const handleDragEnd = (result: DropResult) => {
	//     if (!result.destination) return;

	//     const { source, destination } = result;

	//     // Store the dragged item's id in the MainFunctionDndStatusContext
	//     const draggedItemId = items[source.index].id;
	//     const draggedItemTitle = items[source.index].label;
	//     const draggedItemUrl = items[source.index].url;

	//     setMainFunctionDndStatus({
	//         draggedItemId: draggedItemId,
	//         draggedItemTitle: draggedItemTitle,
	//         draggedItemUrl: draggedItemUrl,
	//     });
	// };
	// const [isHomePageTop, setIsHomePageTop] = useState<number>(140);
	// const [isHomePageLeft, setIsHomePageLeft] = useState<number>(12);
	// const [isHomePageX, setIsHomePageX] = useState<number>(4);
	// useEffect(() => {
	//     const currentUrl = window.location.href;
	//     const queryStartIndex = currentUrl.indexOf('HomePage');
	//     if (queryStartIndex > 0) {
	//         setIsHomePageTop(96);
	//         setIsHomePageLeft(0);
	//         setIsHomePageX(0);
	//     } else {
	//         setIsHomePageTop(140);
	//         setIsHomePageLeft(12);
	//         setIsHomePageX(4);
	//     }
	// });

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const [isConsultingRoom, setIsConsultingRoom] = useState<boolean>(false);

	// useEffect(() => {
	//     const path = window.location.pathname;
	//     const fullURL = window.location.href;

	//     // 更新 sidebarHidden 的值
	//     if (
	//         path.startsWith('/HomePage') ||
	//         path.startsWith('/OPDPage') ||
	//         path.startsWith('/ICCardUpdatePage')
	//     ) {
	//         setSidebarHidden(true);
	//         setSidBarOpen(false);
	//     } else {
	//         setSidebarHidden(false);
	//     }

	//     if (path.startsWith('/OPDPage/ConsultingRoomPage')) {
	//         setIsConsultingRoom(true);
	//     } else {
	//         setIsConsultingRoom(false);
	//     }
	// }, [location.pathname]);

	// 在組件首次加載時從 localStorage 加載數據
	useEffect(() => {
		const navigation2FromLocalStorage = localStorage.getItem("navigation2");
		const isLoggedInFromLocalStorage = localStorage.getItem("isLoggedIn");

		if (navigation2FromLocalStorage) {
			setNavigation2(JSON.parse(navigation2FromLocalStorage));
		}

		if (isLoggedInFromLocalStorage) {
			setIsLoggedIn(JSON.parse(isLoggedInFromLocalStorage));
		}
	}, []);

	// 在導航變化時保存數據到 localStorage
	useEffect(() => {
		if (navigation2) {
			localStorage.setItem("navigation2", JSON.stringify(navigation2));
		}
	}, [navigation2]);

	// 在組件首次加載時恢復路徑
	const savedPath = localStorage.getItem("lastPathname");
	useEffect(() => {
		if (savedPath && location.pathname === "/" && isLoggedIn && navigation2) {
			if (isLoggedIn && savedPath === "/") {
				navigate("/HomePage");
				// console.log('進入首頁')
			} else {
				navigate(savedPath);
				// console.log('進入其他頁面')
			}
		}
	}, [navigate, location.pathname, isLoggedIn]);

	const expirationTimeRef = useRef(Number(localStorage.getItem("expirationTime")));

	// 超時登出功能
	// useEffect(() => {
	//     if (isLoggedIn) {
	//         console.log(expirationTimeRef.current, '初始化的過期時間');
	//         console.log(Date.now(), '現在時間');

	//         const checkExpiration = () => {
	//             const nowTime = Date.now();
	//             console.log(nowTime, '現在時間');
	//             console.log(expirationTimeRef.current, '過期時間');
	//             if (expirationTimeRef.current <= nowTime) {
	//                 localStorage.removeItem('lastPathname');
	//                 localStorage.removeItem('navigation2');
	//                 localStorage.removeItem('token');
	//                 localStorage.removeItem('isLoggedIn');
	//                 localStorage.removeItem('expirationTime'); // 清除過期時間
	//                 setIsLoggedIn(false);
	//                 if (!hasAlertedRef.current) {
	//                     hasAlertedRef.current = true;
	//                     alert('已超過時間請重新登入');
	//                     localStorage.removeItem('expirationTime')
	//                     navigate('/');
	//                 }
	//             }
	//         };

	//         const intervalId = setInterval(checkExpiration, 1000); // 每秒检查一次

	//         const events = ['click', 'keydown', 'mousemove'];
	//         events.forEach(event => window.addEventListener(event, updateExpirationTime));

	//         return () => {
	//             clearInterval(intervalId);
	//             events.forEach(event => window.removeEventListener(event, updateExpirationTime));
	//             hasAlertedRef.current = false; // 重置 ref
	//         };
	//     }
	// }, [isLoggedIn, navigate]);

	useEffect(() => {
		updateUserInfo({
			token: cookies.token ?? "",
			hosp_info: JSON.parse(localStorage.getItem("hosp_info") ?? "{}") as HospInfoProps
		});
	}, []);

	// 在路徑變化時保存當前路徑
	useEffect(() => {
		if (logoutStatus) {
			localStorage.remove("lastPathname");
		} else if (location.pathname !== "/AuthenticationPasswordPage") {
			localStorage.setItem("lastPathname", location.pathname);
			if (userInfo) {
				if (userInfo.token) {
					return;
				}
				updateUserInfo({
					token: cookies.token ?? "",
					hosp_info: JSON.parse(localStorage.getItem("hosp_info") ?? "{}") as HospInfoProps
				});
			}
		}
	}, [location.pathname]);

	useEffect(() => {
		console.log(userInfo, "userInfo");
	}, [userInfo]);

	// 更新 sidebarHidden 和 isConsultingRoom 狀態
	useEffect(() => {
		const path = location.pathname;

		setSidebarHidden(
			path.startsWith("/HomePage") ||
				path.startsWith("/OPDPage") ||
				path.startsWith("/ICCardUpdatePage") ||
				path.startsWith("/DeclarationAuditPage")
		);

		setIsConsultingRoom(path.startsWith("/OPDPage/ConsultingRoomPage"));
	}, [location.pathname]);

	const logoutUser = async () => {
		localStorage.removeItem("token");
		localStorage.removeItem("isLoggedIn");
		removeCookie("token");

		setIsLoggedIn(false);
		navigate("/");
	};

	const checkToken = async () => {
		if (cookies.token) {
			setIsLoggedIn(true);
			setIsChecked(true);
		} else {
			await logoutUser();
		}
	};

	useEffect(() => {
		if (location.pathname !== "/AuthenticationPasswordPage") {
			checkToken();
		}
	}, []);

	return (
		<ChakraProvider theme={theme}>
			<QueryClientProvider client={queryClient}>
				<ShowEditStatusProvider>
					<SignProvider>
						<ShowMainFunctionDeleteIconProvider>
							<RegisteredSearchResultStatusProvider>
								{/* <DragDropContext onDragEnd={handleDragEnd}> */}
								<LoadingStatusProvider>
									<LoginAPIStatusProvider>
										<SidebarStatusProvider>
											<RegisteredTableHeightStatusProvider>
												<ShowPatientManagementFormStatusProvider>
													<ShowICCardRegisteredFormStatusProvider>
														<OPDTableHeightStatusProvider>
															<HealthCheckRegisteredTableHeightStatusProvider>
																<ShowRegisteredFormStatusProvider>
																	<AutoSavedStatusProvider>
																		<RegisteredFlowStatusProvider>
																			<IsICWriteInOpenProvider>
																				<IsVisitedWaitingListFeeDetailOpenProvider>
																					<MedicalRecordStatusProvider>
																						<WaitingTableTabIndexProvider>
																							<VirtualCardStateProvider>
																								<IsAuthorizeStatusProvider>
																									<IsOpenDetailStatusProvider>
																										<IsManualInputStatusProvider>
																											<div>
																												{isLoggedIn &&
																												navigation2 ? (
																													<>
																														<Box
																															hidden={
																																isConsultingRoom // 如果是診療室 就隱藏 Header
																															}>
																															<Header
																																setIsLoggedIn={
																																	setIsLoggedIn
																																}
																																navigation2={
																																	navigation2
																																}
																															/>
																														</Box>
																														<Flex pt={"40px"}>
																															<Flex
																																as='aside'
																																hidden={
																																	sidebarHidden
																																}
																																alignSelf={
																																	"stretch"
																																}
																																bg={
																																	"rsPrimary.500"
																																}
																																pos={
																																	"fixed"
																																}
																																w={"240px"}
																																h={"100%"}
																																top={"40px"}
																																left={
																																	sideBarOpen
																																		? "-220px"
																																		: "0px"
																																}
																																_hover={{
																																	left: "0px"
																																}}
																																zIndex={40}
																																transition={
																																	sideBarOpen
																																		? "left 0.2s"
																																		: "0s"
																																}>
																																<Box
																																	w={
																																		"100%"
																																	}>
																																	<Sidebar></Sidebar>
																																</Box>
																																{/* 按鈕 */}
																																<Tooltip
																																	label={
																																		sideBarOpen
																																			? "展開"
																																			: "收合"
																																	}
																																	hasArrow
																																	bg={
																																		"rsSecondary.500"
																																	}
																																	placement='right'>
																																	<Box
																																		className='translate-x-[-50%]'
																																		style={
																																			sideBarOpen
																																				? undefined
																																				: {
																																						rotate: "180deg",
																																						transformOrigin:
																																							"left"
																																					}
																																		}
																																		pos={
																																			"absolute"
																																		}
																																		top={
																																			"36px"
																																		}
																																		left={
																																			"100%"
																																		}
																																		w={
																																			"30px"
																																		}
																																		h={
																																			"30px"
																																		}
																																		cursor={
																																			"pointer"
																																		}
																																		onClick={
																																			handleShowSidebarOpen
																																		}>
																																		<HeroIcons name='asideArrow'></HeroIcons>
																																	</Box>
																																</Tooltip>
																															</Flex>

																															<Box
																																as='section'
																																w={"100%"}>
																																<Box
																																	position={
																																		"fixed"
																																	}
																																	top={
																																		"40px"
																																	}
																																	left={
																																		sidebarHidden
																																			? "0px"
																																			: sideBarOpen
																																				? "20px"
																																				: "240px"
																																	}
																																	w={
																																		"100%"
																																	}
																																	zIndex={
																																		20
																																	}>
																																	<Box
																																		hidden={
																																			isConsultingRoom // 如果是診療室 就隱藏 Header
																																		}>
																																		<Breadcrumb
																																			isVisible={
																																				isVisible
																																			}
																																		/>
																																	</Box>
																																</Box>
																																<Box
																																	pl={
																																		sidebarHidden
																																			? "0px"
																																			: sideBarOpen
																																				? "40px"
																																				: "260px"
																																	}
																																	pr={
																																		sidebarHidden
																																			? "0px"
																																			: "20px"
																																	}
																																	pt={
																																		sidebarHidden
																																			? "0px"
																																			: "56px"
																																	}>
																																	<Routes>
																																		<Route
																																			path='/'
																																			element={
																																				<Navigate to='/HomePage' />
																																			}
																																		/>
																																		<Route
																																			path='/HomePage'
																																			element={
																																				<HomePage />
																																			}
																																		/>
																																		<Route
																																			path='/RegisteredPage'
																																			element={
																																				<RegisteredPage />
																																			}
																																		/>

																																		<Route
																																			path='/MedicalInstitutionDataSettingPage'
																																			element={
																																				<MedicalInstitutionDataSettingPage />
																																			}
																																		/>
																																		<Route
																																			path='OPDPage/ConsultingRoomPage'
																																			element={
																																				<ConsultingRoomPage />
																																			}
																																		/>
																																		<Route
																																			path='DiseaseNameDataMaintenancePage'
																																			element={
																																				<DiseaseNameDataMaintenancePage />
																																			}
																																		/>
																																		<Route
																																			path='/OPDPage'
																																			element={
																																				<OPDPage />
																																			}
																																		/>
																																		<Route
																																			path='/PreferentialStatusPage'
																																			element={
																																				<PreferentialStatusPage />
																																			}
																																		/>

																																		{/* 資料設定 */}
																																		<Route
																																			path='/FeeSettingPage'
																																			element={
																																				<FeeSettingPage />
																																			}
																																		/>
																																		<Route
																																			path='/DrugDataMaintenancePage'
																																			element={
																																				<DrugDataMaintenancePage />
																																			}
																																		/>
																																		<Route
																																			path='/PersonnelProfileSettingPage'
																																			element={
																																				<PersonnelProfileSettingPage />
																																			}
																																		/>
																																		<Route
																																			path='/GroupPermissionSettingPage'
																																			element={
																																				<GroupPermissionSettingPage />
																																			}
																																		/>
																																		<Route
																																			path='/NumberingRulePage'
																																			element={
																																				<NumberingRulePage />
																																			}
																																		/>
																																		<Route
																																			path='/WorkingSchedulePage'
																																			element={
																																				<WorkingSchedulePage
																																					sidebarOpenRef={
																																						sidebarOpenRef
																																					}
																																				/>
																																			}
																																		/>
																																		<Route
																																			path='/DrugFrequenceSettingPage'
																																			element={
																																				<DrugFrequenceSettingPage />
																																			}
																																		/>

																																		{/* 開通帳號驗證 */}
																																		<Route
																																			path='/AuthenticationPasswordPage'
																																			element={
																																				<AuthenticationPasswordPage />
																																			}
																																		/>

																																		{/* IC 上傳 */}
																																		<Route
																																			path='/ICCardUpdatePage'
																																			element={
																																				<ICCardUpdatePage />
																																			}
																																		/>

																																		{/* 申報查核 */}
																																		<Route
																																			path='/DeclarationAuditPage'
																																			element={
																																				<DeclarationAuditPage />
																																			}
																																		/>
																																	</Routes>
																																</Box>
																															</Box>
																														</Flex>
																													</>
																												) : (
																													<>
																														<Routes>
																															<Route
																																path='/'
																																element={
																																	<LoginPage
																																		setIsLoggedIn={
																																			setIsLoggedIn
																																		}
																																	/>
																																}
																															/>
																															<Route
																																path='/AuthenticationPasswordPage'
																																element={
																																	<AuthenticationPasswordPage />
																																}
																															/>
																															<Route
																																path='*'
																																element={
																																	<Navigate
																																		to={
																																			isLoggedIn
																																				? "/HomePage"
																																				: "/"
																																		}
																																	/>
																																}
																															/>
																														</Routes>
																													</>
																												)}
																											</div>
																										</IsManualInputStatusProvider>
																									</IsOpenDetailStatusProvider>
																								</IsAuthorizeStatusProvider>
																							</VirtualCardStateProvider>
																						</WaitingTableTabIndexProvider>
																					</MedicalRecordStatusProvider>
																				</IsVisitedWaitingListFeeDetailOpenProvider>
																			</IsICWriteInOpenProvider>
																		</RegisteredFlowStatusProvider>
																	</AutoSavedStatusProvider>
																</ShowRegisteredFormStatusProvider>
															</HealthCheckRegisteredTableHeightStatusProvider>
														</OPDTableHeightStatusProvider>
													</ShowICCardRegisteredFormStatusProvider>
												</ShowPatientManagementFormStatusProvider>
											</RegisteredTableHeightStatusProvider>
										</SidebarStatusProvider>
									</LoginAPIStatusProvider>
								</LoadingStatusProvider>
								{/* </DragDropContext> */}
							</RegisteredSearchResultStatusProvider>
						</ShowMainFunctionDeleteIconProvider>
					</SignProvider>
				</ShowEditStatusProvider>
			</QueryClientProvider>
		</ChakraProvider>
	);
}

export default App;
