import React, { createContext, useContext, useState, ReactNode } from "react";

interface IsOpenDetailStatusContextValue {
	isOpenDetailStatus: boolean;
	setIsOpenDetailStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsOpenDetailStatusContext = createContext<IsOpenDetailStatusContextValue | undefined>(undefined);

interface IsOpenDetailStatusProviderProps {
	children: ReactNode;
}

export const IsOpenDetailStatusProvider: React.FC<IsOpenDetailStatusProviderProps> = ({ children }) => {
	const [isOpenDetailStatus, setIsOpenDetailStatus] = useState(false);

	return (
		<IsOpenDetailStatusContext.Provider value={{ isOpenDetailStatus, setIsOpenDetailStatus }}>
			{children}
		</IsOpenDetailStatusContext.Provider>
	);
};

export const useIsOpenDetailStatus = (): IsOpenDetailStatusContextValue => {
	const context = useContext(IsOpenDetailStatusContext);

	if (!context) {
		throw new Error("useIsOpenDetailStatus must be used within an IsOpenDetailStatusProvider");
	}

	return context;
};
