import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Text, RadioGroup, Stack, Radio, Select, Flex, Center } from "@chakra-ui/react";
import { ADDateToROCString } from "functions/ADDateToROCString";
import { useTreatmentInfoStore } from "store/TreatmentManagement";

import { specificTreatmentListsProps } from "service/specificTreatmentListsService";
import { useDropDownMenuQuery } from "query/useDropDownMenuQuery";
import { useUserStore } from "store";

interface LeftContentProps {
	selectedTimes: number | null;
	setSelectedTimes: Dispatch<SetStateAction<number | null>>;
	selectedReasons: string;
	setSelectedReasons: Dispatch<SetStateAction<string>>;
}

export const LeftContent: React.FC<LeftContentProps> = ({ selectedTimes, setSelectedTimes, selectedReasons, setSelectedReasons }) => {
	const { treatmentInfo } = useTreatmentInfoStore();
	const { userInfo } = useUserStore();

	// 特殊原因清單
	const [specificTreatmentLists, setSpecificTreatmentLists] = useState<specificTreatmentListsProps[]>([]);
	const { data: specificTreatmentListsData, isSuccess: isSpecificTreatmentListsSuccess } = useDropDownMenuQuery(
		"specificTreatmentLists",
		userInfo.token!
	);
	useEffect(() => {
		if (isSpecificTreatmentListsSuccess) {
			setSpecificTreatmentLists(specificTreatmentListsData.lists);
		}
	}, [isSpecificTreatmentListsSuccess]);

	const handleChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedReasons(event.target.value);
	};

	const titleStyle = {
		textStyle: "semiTitle",
		w: "120px",
		flexShrink: 0
	};
	return (
		<Flex direction={"column"} gap={"12px"} p={"12px 16px"} bg={"#F5F5F5"} rounded={"lg"} w={"680px"}>
			<Flex gap={"12px"} align={"center"}>
				<Text sx={titleStyle}>原處方日期</Text>
				<Text>
					{ADDateToROCString(
						treatmentInfo.refillable.refill_times !== null && treatmentInfo.treated_at
							? new Date(treatmentInfo.treated_at!)
							: new Date()
					)}
				</Text>
			</Flex>

			<Flex gap={"12px"} align={"center"}>
				<Text sx={titleStyle}>開立醫師</Text>
				<Text>{treatmentInfo.doctor_name}</Text>
			</Flex>
			<Flex gap={"12px"} align={"center"}>
				<Text sx={titleStyle}>可調劑次數</Text>
				<Flex gap={"8px"}>
					<RadioGroup
						value={selectedTimes?.toString() ?? ""}
						onChange={value => {
							setSelectedTimes(parseInt(value) || null);
						}}>
						<Stack direction='row'>
							{[2, 3, 4].map(num => (
								<Radio key={num} value={num.toString()} colorScheme='rsPrimary' bg={"#fff"}>
									{num}
								</Radio>
							))}
						</Stack>
					</RadioGroup>
				</Flex>
			</Flex>
			<Flex gap={"12px"} align={"center"}>
				<Text sx={titleStyle}>本次調劑序</Text>
				<Text>1</Text>
			</Flex>
			<Flex gap={"12px"} align={"center"}>
				<Text sx={titleStyle}>連簽特殊原因</Text>
				<Select onChange={handleChangeSelect} value={selectedReasons} bg={"#fff"}>
					<option value=''>請選擇</option>
					{specificTreatmentLists &&
						specificTreatmentLists.map((item, index) => (
							<option value={item.code} key={index}>
								{item.code} {item.name}
							</option>
						))}
				</Select>
			</Flex>
		</Flex>
	);
};
