import React, { createContext, useContext, useState, ReactNode } from "react";

interface IsPrintProofOpenContextValue {
	isPrintProofOpenStatus: boolean;
	setIsPrintProofOpenStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsPrintProofOpenContext = createContext<IsPrintProofOpenContextValue | undefined>(undefined);

interface IsPrintProofOpenProviderProps {
	children: ReactNode;
}

export const IsPrintProofOpenProvider: React.FC<IsPrintProofOpenProviderProps> = ({ children }) => {
	const [isPrintProofOpenStatus, setIsPrintProofOpenStatus] = useState(false);
	return (
		<IsPrintProofOpenContext.Provider
			value={{
				isPrintProofOpenStatus,
				setIsPrintProofOpenStatus
			}}>
			{children}
		</IsPrintProofOpenContext.Provider>
	);
};

export const useIsPrintProofOpenStatus = (): IsPrintProofOpenContextValue => {
	const context = useContext(IsPrintProofOpenContext);

	if (!context) {
		throw new Error("useIsPrintProofOpenContextStatus must be used within a isPrintProofOpenStatusProvider");
	}

	return context;
};
