import { Box, Flex } from "@chakra-ui/react";
import { Selections } from "./Selections";
import { CasesCounter } from "./CasesCounter";
import { Lists } from "./Lists";
import { RightSide } from "./RightSide";
import { InsetShadowBox } from "style/chakraStyles";

export const DeclarationAudit = () => {
	return (
		<>
			<Flex h={"calc( 100vh - 40px)"} bg={"gray.550"} p={"48px 20px 20px"} direction={"column"}>
				<Box mb={3}>
					<Selections />
				</Box>
				<Flex>
					<Box flex={"1 1 auto"}>
						<Box mr={"4px"}>
							<Lists />
							<CasesCounter />
						</Box>
					</Box>
					<Box flex={"1 1 auto"}>
						<Box ml={"4px"}>
							<RightSide />
						</Box>
					</Box>
				</Flex>
			</Flex>
		</>
	);
};
