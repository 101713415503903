// src/stores/registeredPageStore.ts
import { create } from "zustand";

export type infoProps = {
	data_id?: string | null;
	name: string;
	medical_record_number: string;
	membership?: string;
	nhi_identity?: string;
	id_no_type: string;
	id_no: string;
	birth_date?: Date | null;
	birth_time?: Date | null;
	sex?: string;
	job?: string;
	marriage?: string;
	first_visit?: Date | null;
	blood_type?: string;
	born_type?: string;
	born_times?: string;
	job_history?: string[];
	seek_history?: "";
	family_history?: string[];
	cancer?: string;
	surgery?: string;
	fracture?: string;
	other_chronic?: string;
	long_term_drugs?: string;
	if_smoke?: string;
	smoke_freq?: string;
	smoke_for_years?: string;
	quit_smoking?: string;
	if_chewing?: string;
	chewing_freq?: string;
	chewing_for_years?: string;
	quit_chewing?: string;
	if_drinking?: string;
	drinking_freq?: string;
	drinking_for_years?: string;
	quit_drinking?: string;
	if_exercise?: string;
	work_hours_per_day?: string;
	sleeping_hours?: string;
	// 增加這些
	if_drug_allergy?: string;
	drug_allergy_describe?: string;
	note?: string;

	opd_at: Date | null;
	// 保險身份註記 我自己先加
	insurerCode: string;
};
export type phoneArrProps = {
	data_id?: string;
	type?: string;
	number?: string;
	seq?: string;
};
// export type areaListsProps = {
//     code: string;
//     name: string
// }
export type addressArrProps = {
	data_id?: string;
	type?: string;
	city_uid?: string;
	city_name?: string;
	area_uid?: string;
	area_name?: string;
	content?: string;
	seq?: string;
	area_lists?: Record<string, string>;
};
export type emerArrProps = {
	data_id?: string;
	name?: string;
	relation?: string;
	phone?: string;
	seq?: string;
};
export type emailArrProps = {
	data_id?: string;
	content?: string;
	seq?: string;
};

export interface PatientInfoProps {
	info: infoProps;
	phone?: phoneArrProps[];
	address?: addressArrProps[];
	emer?: emerArrProps[];
	email?: emailArrProps[];
}

const initialPatientInfo: PatientInfoProps = {
	info: {
		name: "",
		medical_record_number: "",
		nhi_identity: "hi",
		// membership: '',
		id_no_type: "1",
		id_no: "",
		// birth_date: null,
		// birth_time: null,
		// sex: '',
		// job: '',
		// marriage: '',
		// first_visit: null,
		// blood_type: '',
		// born_type: '',
		// born_times: '',
		// job_history: [],
		// seek_history: '',
		// family_history: [],
		// cancer: '',
		// surgery: '',
		// fracture: '',
		// other_chronic: '',
		// long_term_drugs: '',

		// //抽菸
		// if_smoke: '',
		// smoke_freq: '',
		// smoke_for_years: '',
		// quit_smoking: '',

		// // 檳榔
		// if_chewing: '',
		// chewing_freq: '',
		// chewing_for_years: '',
		// quit_chewing: '',

		// // 喝酒
		// if_drinking: '',
		// drinking_freq: '',
		// drinking_for_years: '',
		// quit_drinking: '',

		// if_exercise: '',
		// work_hours_per_day: '',
		// sleeping_hours: '',

		// if_drug_allergy: '',
		// drug_allergy_describe: '',
		// note: '',

		opd_at: null,
		// // identity_code: '',
		// // 保險身份註記 我自己先加的
		insurerCode: ""
	}
	// phone: [],
	// address: [],
	// emer: [],
	// email: [],
};

interface PatientInfoState {
	patientInfo: PatientInfoProps;
	updatePatientInfo: (newData: Partial<PatientInfoProps>) => void;
	resetPatientInfo: () => void;
}

export const usePatientInfoStore = create<PatientInfoState>(set => ({
	patientInfo: initialPatientInfo,
	updatePatientInfo: newData =>
		set(state => ({
			...state,
			patientInfo: { ...state.patientInfo, ...newData }
		})),
	resetPatientInfo: () =>
		set(state => ({
			...state,
			patientInfo: initialPatientInfo
		}))
}));
