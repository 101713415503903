import React, { createContext, useContext, useState, ReactNode } from "react";

interface LoadingStatusContextValue {
	loadingStatus: boolean;
	setLoadingStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoadingStatusContext = createContext<LoadingStatusContextValue | undefined>(undefined); // Set the initial value as undefined

interface LoadingStatusProviderProps {
	children: ReactNode;
}

export const LoadingStatusProvider: React.FC<LoadingStatusProviderProps> = ({ children }) => {
	const [loadingStatus, setLoadingStatus] = useState(false);

	return (
		<LoadingStatusContext.Provider
			value={{
				loadingStatus,
				setLoadingStatus
			}}>
			{children} {/* Fix: Remove double curly braces around children */}
		</LoadingStatusContext.Provider>
	);
};

export const useLoadingStatus = (): LoadingStatusContextValue => {
	const context = useContext(LoadingStatusContext); // Fix: Remove the typo 'con'

	if (!context) {
		throw new Error("useLoadingStatus must be used within a LoadingStatus");
	}
	return context;
};
