import { create } from 'zustand';

interface OriginPrescriptionState {
    originPrescriptionList: OriginPrescriptionProps[];
    updateOriginPrescription: (index: number, prescription: Partial<OriginPrescriptionProps>) => void;
    setOriginPrescriptionList: (list: OriginPrescriptionProps[]) => void;
    resetOriginPrescriptionList: () => void;
}

export type OriginPrescriptionProps = {
    controlled_level: string;
    cost: string;
    data_id: string;
    disabled_date: string;
    dosage_form: string;
    dose: string;
    dose_day?: string;
    dosing_days: string;
    drug_no: string;
    drug_price: number | null;
    drug_type: string;
    freq: string;
    max_dose: string;
    max_dose_day: string;
    name_en: string;
    name_tw: string;
    route_of_administration: string;
    self_price: string;
    short_code: string;
    taking_time: string;
    unit: string;
    qty?: string;
    qty_in_day?: string;
    total_qty?: string;
    given_qty?: string;
    memo?: string;
    site?: string;
    special_mark?: string;
    freq_custom_code?: string;
    freq_directions?: string;
};

export const initialOriginPrescription: OriginPrescriptionProps = {
    controlled_level: "",
    cost: "",
    data_id: "",
    disabled_date: "",
    dosage_form: "",
    dose: "",
    dose_day: "",
    dosing_days: "",
    drug_no: "",
    drug_price: null,
    drug_type: "",
    freq: "",
    max_dose: "",
    max_dose_day: "",
    name_en: "",
    name_tw: "",
    route_of_administration: "",
    self_price: "",
    short_code: "",
    taking_time: "",
    unit: "",
    qty_in_day: "",
    total_qty: "",
    given_qty: "",
    qty: "",
    memo: "",
    site: "",
    special_mark: "",
    freq_custom_code: "",
    freq_directions: ""
};

export const useOriginPrescriptionStore = create<OriginPrescriptionState>((set) => ({
    originPrescriptionList: [initialOriginPrescription],
    updateOriginPrescription: (index, prescription) =>
        set((state) => {
            const newOriginPrescriptionList = [...state.originPrescriptionList];
            newOriginPrescriptionList[index] = {
                ...newOriginPrescriptionList[index],
                ...prescription,
            };
            return { originPrescriptionList: newOriginPrescriptionList };
        }),
    setOriginPrescriptionList: (list) => set({ originPrescriptionList: list }),
    resetOriginPrescriptionList: () => set({
        originPrescriptionList: [initialOriginPrescription]
    })
}));
