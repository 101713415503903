import React, { createContext, useContext, useState, ReactNode } from "react";

interface LogoutStatusContextValue {
	logoutStatus: boolean;
	setLogoutStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const LogoutStatusContext = createContext<LogoutStatusContextValue | undefined>(undefined);

interface LogoutStatusProviderProps {
	children: ReactNode;
}

export const LogoutStatusProvider: React.FC<LogoutStatusProviderProps> = ({ children }) => {
	const [logoutStatus, setLogoutStatus] = useState<boolean>(false);

	return (
		<LogoutStatusContext.Provider
			value={{
				logoutStatus,
				setLogoutStatus
			}}>
			{children}
		</LogoutStatusContext.Provider>
	);
};

export const useLogoutStatus = (): LogoutStatusContextValue => {
	const context = useContext(LogoutStatusContext);

	if (!context) {
		throw new Error("uselogoutStatus must be used within a logoutStatus");
	}
	return context;
};
