// import axios from 'axios';
import apiService from "./apiService";
interface dataProps {
	keyword: string;
}
// 院內藥品
export const postDrugsInUseService = async (token: string, finalData?: dataProps) => {
	return apiService<dataProps, any>({
		method: "POST",
		path: "/api/drugs/in_use",
		token,
		data: finalData
	});
};
// export const postDrugsInUseService = async (
//     token: string,
//     finalData?: dataProps
// ) => {
//     try {
//         const requestBody = JSON.stringify(finalData);
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         const response = await axios.post('/api/drugs/in_use', requestBody, {
//             headers,
//         });
//         const result = response.data;

//         console.log('完整：', response);
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
