import { Checkbox, Button, Flex } from "@chakra-ui/react";
import { useVitalSignQuery, vitalSignSettingListsProps } from "./utils/useVitalSignQuery";
import { useSignContext } from "contexts";
import { useEffect, useState } from "react";

export const SelectedSign = () => {
	const { lists, isLoading, error } = useVitalSignQuery();
	const { state, dispatch } = useSignContext();
	const [showSelectedSign, setShowSelectedSign] = useState(false);

	const handleCheckboxChange = (id: string) => {
		dispatch({ type: "TOGGLE_SIGN", payload: id });
	};

	const handleOpenSelectedSign = () => {
		setShowSelectedSign(!showSelectedSign);
	};

	if (isLoading) return <span>Loading...</span>;

	return (
		<>
			<Button onClick={handleOpenSelectedSign} hidden>
				控制器
			</Button>
			{showSelectedSign && (
				<Flex wrap='wrap'>
					{lists &&
						lists?.map((sign: vitalSignSettingListsProps) => (
							<Checkbox
								key={sign.id}
								isChecked={state.selectedSigns.includes(sign.id)}
								onChange={() => handleCheckboxChange(sign.id)}>
								{sign.name}
							</Checkbox>
						))}
				</Flex>
			)}
		</>
	);
};
