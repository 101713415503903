import React from "react";
import { Thead, Tr, Th, Text } from "@chakra-ui/react";
import { useDeclareListTabIndexStore } from "store/DeclarationAudit/useDeclareListTabIndexStore";

export const ListHeader = () => {
	const { tabIndex } = useDeclareListTabIndexStore();
	return (
		<Thead pos={"sticky"} top={0} left={0} w={"100%"} zIndex={10} bg={"#D9D9D9"}>
			<Tr>
				<Th p={"4px 8px"}>
					<Text fontSize={"md"} textAlign={"center"}>
						病歷
					</Text>
				</Th>
				<Th p={"4px 8px"}>
					<Text fontSize={"md"}>案件</Text>
				</Th>

				<Th p={"4px 8px"} hidden={tabIndex !== 0}>
					<Text fontSize={"md"}>日期</Text>
				</Th>
				<Th p={"4px 8px"}>
					<Text fontSize={"md"}>姓名</Text>
				</Th>
				<Th p={"4px 8px"} hidden={tabIndex !== 0}>
					<Text fontSize={"md"}>身分證字號</Text>
				</Th>
				<Th p={"4px 8px"}>
					<Text fontSize={"md"}>身份</Text>
				</Th>
				<Th p={"4px 8px"}>
					<Text fontSize={"md"}>病歷號</Text>
				</Th>
				<Th p={"4px 8px"}>
					<Text fontSize={"md"}>主診斷</Text>
				</Th>
				<Th p={"4px 8px"} hidden={tabIndex !== 0}>
					<Text fontSize={"md"}>天數</Text>
				</Th>
				<Th p={"4px 8px"}>
					<Text fontSize={"md"}>卡號</Text>
				</Th>

				<Th p={"4px 8px"} hidden={tabIndex !== 2}>
					<Text fontSize={"md"} textColor={"rsDanger.500"}>
						訊息
					</Text>
				</Th>
			</Tr>
		</Thead>
	);
};
