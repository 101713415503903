import apiService from "./apiService";

export type SystemFunctionTreeResult = {
	result: string;
	items: {
		[key: string]: {
			name: string;
			path?: string;
			items?: {
				[key: string]: {
					name: string;
					path: string;
				};
			};
		};
	};
};

export const callSystemFunctionTreeService = async (token: string) => {
	return apiService<string, any>({
		method: "GET",
		path: "/api/system_function/tree",
		token
	});
};
