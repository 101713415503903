import apiService from "./apiService";

function convertDateStringToDate(obj: any): any {
	if (typeof obj === "string") {
		// 尝试转换ISO 8601日期字符串
		const date = Date.parse(obj);
		if (!isNaN(date)) {
			return new Date(obj);
		}
		return obj;
	} else if (Array.isArray(obj)) {
		return obj.map(item => convertDateStringToDate(item));
	} else if (typeof obj === "object" && obj !== null) {
		const newObj: { [key: string]: any } = {};
		Object.keys(obj).forEach(key => {
			newObj[key] = convertDateStringToDate(obj[key]);
		});
		return newObj;
	}
	return obj;
}
export interface patientSearchListsProps {
	id: string;
	birth_date: string;
	id_no: string;
	id_no_type?: string;
	medical_record_number: string;
	name: string;
	phone: string;
	phone_type?: string;
}
// data1 = search_type
// data2 = keyword
// export const callPatientSearchService = async(token: string, data1: string, data2: string) => {
//     return apiService({
//         method: 'GET',
//         path: `/api/patient/search`,
//         token,
//         data: { data1, data2 },
//     })
// }

const hasSpecialChars = (data: string) =>
	/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?！＠＃＄％＾＆＊（）＿＋＝［］｛｝；'："＼｜，．＜＞／？]+/.test(data);

export const callPatientSearchService = async (token: string, data1: string, data2: string) => {
	console.log("patientSearchService data2: ", data2);
	const decodedData2 = decodeURIComponent(data2);
	console.log("patientSearchService decodedData2: ", decodedData2);
	const trimData2 = decodedData2.trim();

	console.log("進來搜尋 trimData2: ", trimData2);

	if (trimData2 === "") {
		console.log("空字串: ", trimData2);

		return {
			result: "failure",
			msg: "空字串"
		};
	}

	// 新增檢查控制字符的條件
	if (/[\x00-\x1F\x7F]/.test(trimData2)) {
		console.log("包含控制字符: ", trimData2);

		return {
			result: "failure",
			msg: "包含控制字符"
		};
	}

	if (trimData2 && hasSpecialChars(trimData2)) {
		console.log("有特殊符號: ", trimData2);

		return {
			result: "failure",
			msg: "有特殊符號"
		};
	}

	const isChinese = /[\u4e00-\u9fa5]/.test(trimData2);
	if ((isChinese && trimData2.length < 2) || (!isChinese && trimData2.length < 3)) {
		console.log("字數錯誤: ", trimData2);

		return {
			result: "failure",
			msg: "字數不足"
		};
	}

	try {
		const response = await apiService({
			method: "GET",
			path: `/api/patient/search`,
			token,
			data: { data1, data2: trimData2 }
		});

		if (response && response.info && typeof response.info.birth_date === "string") {
			response.info.birth_date = new Date(response.info.birth_date);
		}

		return response;
	} catch (error) {
		console.error("搜尋病患資料失敗:", error);

		return null;
	}
};
