import { Center } from "@chakra-ui/react";

const ConsultingRoomIdCardIcon = ({ onClick }: any) => {
	return (
		<Center>
			<svg xmlns='http://www.w3.org/2000/svg' width='34' height='34' viewBox='0 0 34 34' fill='none'>
				<g filter='url(#filter0_i_70_6181)'>
					<circle cx='17' cy='17' r='17' fill='#45533F' />
				</g>
				<path
					d='M8 15H26M12 20H13M17 20H18M11 24H23C23.7956 24 24.5587 23.6839 25.1213 23.1213C25.6839 22.5587 26 21.7956 26 21V13C26 12.2044 25.6839 11.4413 25.1213 10.8787C24.5587 10.3161 23.7956 10 23 10H11C10.2044 10 9.44129 10.3161 8.87868 10.8787C8.31607 11.4413 8 12.2044 8 13V21C8 21.7956 8.31607 22.5587 8.87868 23.1213C9.44129 23.6839 10.2044 24 11 24Z'
					stroke='white'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<defs>
					<filter
						id='filter0_i_70_6181'
						x='0'
						y='-1'
						width='35'
						height='35'
						filterUnits='userSpaceOnUse'
						colorInterpolationFilters='sRGB'>
						<feFlood floodOpacity='0' result='BackgroundImageFix' />
						<feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
						<feColorMatrix
							in='SourceAlpha'
							type='matrix'
							values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
							result='hardAlpha'
						/>
						<feOffset dx='1' dy='-1' />
						<feGaussianBlur stdDeviation='1' />
						<feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
						<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0' />
						<feBlend mode='normal' in2='shape' result='effect1_innerShadow_70_6181' />
					</filter>
				</defs>
			</svg>
		</Center>
	);
};

export default ConsultingRoomIdCardIcon;
