import { Box, Flex, Text, Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { InsetShadowBox } from "style/chakraStyles";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import { useDeclareListTabIndexStore } from "store/DeclarationAudit/useDeclareListTabIndexStore";
import { useDeclareSearchRangeStore } from "store/DeclarationAudit/useDeclareSearchRangeStore";
import { useDeclareListsStore } from "store/DeclarationAudit/useDeclareListsStore";

import { useDeclareListsAPI } from "service/declareListsServiceHelper";
import { useDeclareStaticAPI } from "service/declareStaticServiceHelper";
import { useDeclareErrorAPI } from "service/declareErrorServiceHelper";
import { useDeclareProgressAPI } from "service/declareProgressServiceHelper";
import { useDeclareProgressStore } from "store/DeclarationAudit/useDeclareProgressStore";
import { useDeclareListSelectedItemStore } from "store/DeclarationAudit/useDeclareListSelectedItemStore";
import { useDeclareListHistoryDataStore } from "store/DeclarationAudit/useDeclareListHistoryDataStore";
import { List } from "./List"; // 清單
import { Transfer } from "./Transfer"; // 轉檔
import { Download } from "./Download"; // 下載
import { getTodayYYYYMM } from "components/CustomComponents/DateTransfer/getTodayYYYYMM";
export const Lists = () => {
	const { declareProgress } = useDeclareProgressStore();
	const { tabIndex, setTabIndex, resetTabIndex } = useDeclareListTabIndexStore();
	const { declareLists, clearDeclareLists } = useDeclareListsStore();
	const { declareSearchRangeData, updateDeclareSearchRangeData } = useDeclareSearchRangeStore();
	const { callDeclareListsAPI } = useDeclareListsAPI();
	const { callDeclareStaticAPI } = useDeclareStaticAPI();
	const { callDeclareErrorAPI } = useDeclareErrorAPI();
	const { callDeclareProgressAPI } = useDeclareProgressAPI();
	const { selectedItem, resetSelectedItem } = useDeclareListSelectedItemStore();
	const [isMounted, setIsMounted] = useState(false);
	const { resetHistoryData } = useDeclareListHistoryDataStore();

	useEffect(() => {
		resetTabIndex();
		resetSelectedItem();
		resetHistoryData();
		clearDeclareLists();

		if (declareSearchRangeData.ym === "") {
			console.log("啟動刷新列表：空", declareSearchRangeData);
			updateDeclareSearchRangeData({
				...declareSearchRangeData,
				ym: getTodayYYYYMM(),
				page: 1
			});
		} else {
			console.log("啟動刷新列表：有值", declareSearchRangeData);
		}

		callDeclareStaticAPI();

		setIsMounted(true);
	}, []);

	useEffect(() => {
		if (isMounted) {
			if (tabIndex === 0) {
				callDeclareListsAPI({
					...declareSearchRangeData,
					page: 1
				});
			} else if (tabIndex === 1) {
				if (declareSearchRangeData.ym === "") {
					callDeclareProgressAPI({ ym: getTodayYYYYMM() });
				} else {
					callDeclareProgressAPI({ ym: declareSearchRangeData.ym });
				}
			} else if (tabIndex === 2) {
				callDeclareErrorAPI({
					...declareSearchRangeData,
					page: 1
				});
			}
		}
	}, [tabIndex, isMounted]);

	useEffect(() => {
		if (tabIndex === 1) {
			const intervalId = setInterval(() => {
				if (declareProgress.progress === "此項作業執行中" || declareProgress.progress === "已鎖檔") {
					if (declareSearchRangeData.ym === "") {
						callDeclareProgressAPI({ ym: getTodayYYYYMM() });
					} else {
						callDeclareProgressAPI({
							ym: declareSearchRangeData.ym
						});
					}
				} else {
					clearInterval(intervalId);
				}
			}, 6000);
			return () => clearInterval(intervalId);
		}
	}, [declareProgress.progress, declareSearchRangeData.ym, tabIndex]);

	const TabStyle = {
		display: "flex",
		gap: "4px",
		textColor: "rsPrimary.500",
		opacity: 0.5,
		_selected: {
			opacity: 1,
			bg: "linear-gradient(to top, rgba(69,83,63,0.2), rgba(85,107,79,0.2) 20%, rgba(102,127,95,0.2) 50%, transparent)"
		}
	};

	return (
		<Box sx={InsetShadowBox} h={`calc(${window.innerHeight}px - 200px)`}>
			<Tabs position='relative' variant='enclosed' onChange={index => setTabIndex(index)} h={"100%"} index={tabIndex}>
				<TabList>
					<Tab sx={TabStyle}>
						<HeroIcons name='clipboard-document-list' />
						<Text>清單</Text>
					</Tab>

					<Tab sx={TabStyle}>
						<HeroIcons name='arrow-path' />
						<Text>轉檔</Text>
					</Tab>

					<Tab
						sx={TabStyle}
						// isDisabled={declareProgress.progress !== '無任何錯誤'}
					>
						<HeroIcons name='xmark-circle' />
						<Text>錯誤清單</Text>
					</Tab>

					<Tab
						sx={TabStyle}
						// isDisabled={declareProgress.progress !== '無任何錯誤'}
					>
						<HeroIcons name='folder-arrow-down' />
						<Text>下載</Text>
					</Tab>
				</TabList>

				<TabIndicator mt='-1.5px' height='2px' bg='rsPrimary.500' borderRadius='1px' />

				<TabPanels h={"100%"}>
					<TabPanel px={0} pb={0}>
						<List />
					</TabPanel>
					<TabPanel px={0} pb={0}>
						<Transfer />
					</TabPanel>
					<TabPanel px={0} pb={0}>
						<List />
					</TabPanel>
					<TabPanel px={0} pb={0}>
						<Download />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Box>
	);
};
