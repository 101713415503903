import apiService from './apiService';

// 回傳的值
type vitalSignProps = {
    data_id :  string ,
    setting_uid :  string ,
    name :  string ,
    value :  string ,
    unit :  string 
}
export type OpdArrListsProps = {
    // 掛號資料ID
    id: string;
    // 看診序號
    reg_number: string;
    // 掛號身份
    nhi_type: string;
    // 掛號身份
    nhi_type_name: string;
    // 告知診間的附加訊息
    note: string;
    // 卡號，最大長度4碼英數字
    card_no: string;
    // 時段名稱
    shift_name: string;
    // 診間名稱
    clinic_room_name: string;
    // 醫師姓名
    doctor_name: string;
    //病患姓名
    patient_name: string;
    //病患生日
    birth_date: string;
    //病歷號
    medical_record_number: string;
    //看診狀態
    status: string;
    
    opd_at: string
    vital_sign: vitalSignProps[]
}

export interface OpdListsProps {
    [key: string]: OpdArrListsProps;
}
//6-1. 掛號室未診名單
export const callOpdListsService = async (token: string) => {
     return apiService<OpdListsProps, any>({
        method: 'GET',
        path: '/api/opd/lists',
        token,
    });
};


export interface RegistrationListProps {
    birth_date: string;
    card_no: string;
    clinic_room_name: string;
    doctor_name: string;
    data_id: string;
    medical_record_number: string;
    nhi_type: string;
    nhi_type_name: string;
    note: string | null;
    opd_at: string;
    patient_name: string;
    reg_number: number;
    shift_name: string;
    status: string;
    treated_at: string;
    vital_sign?: any; // Add this if it's used in VitalSignBody
    fee: {
        1: string;
        CO: string;
        HD: string;
        LPAY: string;
        SE: string;
    };
}

export interface RegistrationOpdListProps {
    [key: string]: RegistrationListProps;
}

//6-2-1. 視訊診療名單
export const callVideoOpdListService = async (token: string, date?: string) => {
    const data: { type?: string; status?: string; date?: string } = {
        type: 'registration',
        status: '12',
    };

    if (date) {
        data.date = date;
    }

    return apiService<OpdListsProps, any>({
        method: 'GET',
        path: '/api/opd/lists',
        token,
        data,
    });
};
//6-2. 掛號室已診名單
export const callRegistrationOpdListsService = async (token: string, date?: string) => {
    const data: { type?: string; status?: string; date?: string } = {
        type: 'registration',
        status: '50',
    };

    if (date) {
        data.date = date;
    }

    return apiService<OpdListsProps, any>({
        method: 'GET',
        path: '/api/opd/lists',
        token,
        data,
    });
};


export interface ClinicListProps {
    birth_date: string;
    card_no: string;
    clinic_room_name: string;
    doctor_name: string;
    data_id: string;
    medical_record_number: string;
    nhi_type: string;
    nhi_type_name: string;
    note: string | null;
    opd_at: string;
    patient_name: string;
    reg_number: number;
    shift_name: string;
    status: string;
    treated_at: string;
    vital_sign?: any; // Add this if it's used in VitalSignBody
    
}
//6-3  診間已診名單
export const callClinicOpdListsService = async (token: string, date?: string) => {
    const data: { type: string; status: string; date?: string } = {
        type: 'clinic',
        status: '50',
    };

    if (date) {
        data.date = date;
    }

    return apiService<OpdListsProps, any>({
        method: 'GET',
        path: '/api/opd/lists',
        token,
        data,
    });
};
