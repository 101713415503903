import { create } from "zustand";
type cashierProps = {
	acc_item_uid: string;
	acc_name?: string;
	cashier_type: string;
	price: number;
	discount?: number;
	final: number;
};
export type vitalSignDataProps = {
	data_id?: string;
	vital_sign_uid: string;
	value?: string;
};
export interface currentVisitInfoDataProps {
	// 時間/診間
	// date: string;
	data_id?: string;
	cashier_uid?: string;
	patient_uid: string;
	shift_uid: string;
	clinic_room_uid: string;
	doctor_uid: string;

	// 身份
	// reg_type?: string;
	nhi_type: string;
	member_uid?: string;
	// another_identity?: string;

	// 看診資訊
	card_no?: string;
	mt_type?: string;
	note?: string;

	// treatmentSeq?: string;

	// 費用
	// registration_fee: string; // 掛號費
	// copayment_amount: string; // 自付額
	// deposit_fee: string; // 押單費
	cashier_status: string;
	total?: number;
	discount?: number;
	final?: number;
	cashier?: cashierProps[];
	opd_status: string;
	clinic_room_and_doctor: string;
	vital_sign?: vitalSignDataProps[];
	opd_at: string;
	reg_number: string;
	refillable_uid?: string;
	refillable_detail_uid?: string;
}
const initialCurrentVisitInfoData: currentVisitInfoDataProps = {
	// date: '',
	data_id: "",
	// cashier_uid: '',
	patient_uid: "",
	shift_uid: "",
	clinic_room_uid: "",
	doctor_uid: "",
	clinic_room_and_doctor: "",

	// reg_type: "",
	nhi_type: "",
	member_uid: "",

	card_no: "",
	mt_type: "01",
	// another_identity: '',
	note: "",

	// treatmentSeq: '',
	// registration_fee: '', // 掛號費:
	// copayment_amount: '', // 自付額
	// deposit_fee: '', // 押單費
	opd_at: "",
	total: 0,
	discount: 0,
	final: 0,
	cashier_status: "5",
	opd_status: "10",
	cashier: [
		// 掛號費
		{
			acc_item_uid: "",
			acc_name: "掛號費",
			cashier_type: "3",
			price: 0,
			discount: 0,
			final: 0
		},
		// 自付額
		{
			acc_item_uid: "",
			acc_name: "自付額",
			cashier_type: "3",
			price: 0,
			discount: 0,
			final: 0
		},
		{
			acc_item_uid: "",
			acc_name: "押單費",
			cashier_type: "3",
			price: 0,
			discount: 0,
			final: 0
		}
	],
	vital_sign: [],
	reg_number: "",
	// 就醫類別 看診序號 掛號費 自付額 呀擔費 沒做

	refillable_uid: "",
	refillable_detail_uid: ""
};

interface CurrentRegisteredState {
	currentVisitInfoData: currentVisitInfoDataProps;
	updateCurrentVisitInfoData: (newData: Partial<currentVisitInfoDataProps>) => void;
	resetCurrentVisitInfoData: () => void;
}
export const useCurrentVisitInfoStore = create<CurrentRegisteredState>(set => ({
	currentVisitInfoData: initialCurrentVisitInfoData,
	updateCurrentVisitInfoData: newData =>
		set(state => ({
			currentVisitInfoData: {
				...state.currentVisitInfoData,
				...newData,
				cashier: newData.cashier ? newData.cashier : state.currentVisitInfoData.cashier
			}
		})),
	resetCurrentVisitInfoData: () =>
		set(() => ({
			currentVisitInfoData: initialCurrentVisitInfoData
		}))
}));
