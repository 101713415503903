import axios from "axios";
import Swal from "sweetalert2";
// 請求病患授權
export interface VHCHisReadCSVAndGetLastTokenProps {
	HospID: string;
	sToken: string;
	ROCID: string;
}
export const postGetVHCTeleMedicineReqCodeService = async (QRCodeString: VHCHisReadCSVAndGetLastTokenProps): Promise<any> => {
	console.log(QRCodeString, "service這邊看看");
	try {
		console.log(QRCodeString, "service看一下");
		const finalData = JSON.stringify(QRCodeString);
		const response = await axios.post("https://vhcapp.nhi.gov.tw/vhc/cloud/tlm/GetVHCTeleMedicineReqCode", finalData, {
			headers: {
				Accept: "*/*",
				"Content-Type": "application/json"
			}
		});

		if (response.data.ERRORCODE && response.data.ERRORCODE !== 0) {
			Swal.fire({
				icon: "error",
				text: response.data.message,
				title: "錯誤"
			});
			return null;
		}
		console.log(response.data, "再次要求授權");
		return response.data;
	} catch (error: unknown) {
		Swal.fire({
			icon: "error",
			title: "虛擬健保卡取得授權發生問題",
			text: "請檢查是否有開啟虛擬健保卡代理人及更新虛擬卡健保卡授權"
		});

		if (error instanceof Error) {
			console.error("Error:", error.message);
		} else {
			console.error("An unknown error occurred");
		}
	}
};
