import React from "react";
import { Box, Flex, Center, Text, Button } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer } from "@chakra-ui/react";

import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";

import { useIsPrintProofOpenStatus } from "contexts/Registration/IsPrintProofOpenContext";
import { DxProof } from "./DxProof";

interface listProps {
	date: string;
	name: string;
	doctor: string;
	id: string;
}

export const MedicalHistoryList = () => {
	const fakeList: listProps[] = [
		{
			date: "113.07.08",
			name: "急性支氣管炎",
			doctor: "鍾娃娃",
			id: "83d87209jf82"
		},
		{
			date: "113.05.20",
			name: "上呼吸道感染",
			doctor: "鍾娃娃",
			id: "92f8a50fke92"
		},
		{
			date: "113.02.22",
			name: "皮膚感染",
			doctor: "鍾娃娃",
			id: "74c7b12gjk63"
		},
		{
			date: "113.01.23",
			name: "上呼吸道感染",
			doctor: "鍾娃娃",
			id: "59d6c34hlm84"
		},
		{
			date: "112.11.11",
			name: "急性腸炎",
			doctor: "鍾娃娃",
			id: "20e5d78jnp95"
		},
		{
			date: "112.08.09",
			name: "功能性消化不良",
			doctor: "鍾娃娃",
			id: "31f4e90jqr06"
		},
		{
			date: "112.06.16",
			name: "花粉所致過敏性鼻炎",
			doctor: "鍾娃娃",
			id: "42g3f01kst17"
		},
		{
			date: "112.01.03",
			name: "激躁性腸症候群未伴有腹瀉",
			doctor: "鍾娃娃",
			id: "53h2g12lur28"
		}
	];

	const list: listProps[] = [{ date: "-", name: "-", doctor: "-", id: "-" }];

	const { setIsPrintProofOpenStatus } = useIsPrintProofOpenStatus();

	return (
		<>
			<Box bg={"#F5F5F5"} p={"8px 16px"} rounded={"lg"}>
				<Text textStyle={"semiTitle"} mb={"8px"}>
					歷史病歷清單
				</Text>
				<TableContainer minH={"500px"} maxH={"500px"} overflowY={"scroll"}>
					<Table>
						<Thead bg={"#D9D9D9"} pos={"sticky"} top={0} left={0} zIndex={10}>
							<Tr>
								<Th>
									<Text fontSize={"md"} textColor={"rsPrimary.500"}>
										看診日期
									</Text>
								</Th>
								<Th>
									<Text fontSize={"md"} textColor={"rsPrimary.500"}>
										中文病名
									</Text>
								</Th>
								<Th>
									<Text fontSize={"md"} textColor={"rsPrimary.500"}>
										看診醫師
									</Text>
								</Th>
								<Th>
									<Text textAlign={"center"} fontSize={"md"} textColor={"rsPrimary.500"}>
										開立
									</Text>
								</Th>
								<Th>
									<Text textAlign={"center"} fontSize={"md"} textColor={"rsPrimary.500"}>
										紀錄
									</Text>
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{list &&
								list.map((item, index) => (
									<Tr key={index} borderBottom={"1px solid #646B48"} _hover={{ bg: "#fff" }}>
										<Td>{item.date}</Td>
										<Td>{item.name}</Td>
										<Td>{item.doctor}</Td>
										<Td>
											<Center
												_hover={{
													cursor: "pointer",
													opacity: 1
												}}
												opacity={0.8}
												onClick={() => {
													setIsPrintProofOpenStatus(prev => !prev);
												}}>
												<HeroIcons name='openWindow' />
											</Center>
										</Td>
										<Td>
											<Center
												_hover={{
													cursor: "pointer",
													opacity: 1
												}}
												opacity={0.8}>
												<HeroIcons name='clipboard' />
											</Center>
										</Td>
									</Tr>
								))}
						</Tbody>
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};
