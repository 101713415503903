// 掛號模型 OpdModel

import { currentVisitInfoDataProps } from "store/Registration";
import { OpdModelProps } from "store/TreatmentManagement/Print/useOpdModelStore";
import { mb1Props, TreatmentInfoProps } from "store/TreatmentManagement/useTreatmentInfoStore";
import { PatientInfoProps } from "store/Registration";
import { DoctorSpecDataProps } from "../CompleteOptions";
import { SeqNumber256N1DataProps } from "functions/hisGetSeqNumber256N1";
import { pBufferProps } from "service/RsService/RshisGetTreatNumNoICCardService";
import { FeeState } from "store/TreatmentManagement/Payment";
import { cashDataStoreProps } from "store/TreatmentManagement/Payment";

export const opdModelLogic = (
	treatmentInfo: TreatmentInfoProps,
	currentVisitInfoData: currentVisitInfoDataProps,
	patientInfo: PatientInfoProps,
	doctorSpecData: DoctorSpecDataProps,
	feeData: FeeState,
	cashData: cashDataStoreProps,
	seqNumber256N1Result?: SeqNumber256N1DataProps,
	hisGetTreatNumNoICCardResult?: pBufferProps,
	mb1?: mb1Props
) => {
	let finalData: OpdModelProps = {
		// IPatientBase
		Pat_Code: treatmentInfo.medical_record_number,
		Pat_Name: treatmentInfo.patient_name,
		Pat_IdnoType: (() => {
			switch (patientInfo.info.id_no_type) {
				case "1": // 身分證
					return 0;
				case "2": // 居留證號
					return 2;
				case "3": // 護照
					return 1;
				default:
					return 0;
			}
		})(), //雲端邏輯 1 身分證 2 居留證號 3 護照 ； 列印邏輯 0 身分證 1 護照 2 居留證號
		Pat_IDNo: treatmentInfo.id_no,
		Pat_Gender: treatmentInfo.sex === "F" ? "女" : treatmentInfo.sex === "M" ? "男" : "",
		Pat_Birth: treatmentInfo.birth_date?.toISOString(),
		Pat_BirthTime: "", // 目前應該沒
		Pat_Tel: patientInfo.phone?.find(phone => phone.type === "2")?.number || "",
		Pat_Mobile: patientInfo.phone?.find(phone => phone.type === "1")?.number || "",
		Pat_Addr: patientInfo.address?.[0]?.area_name
			? `${patientInfo.address[0].area_name}` + `${patientInfo.address[0].city_name}` + `${patientInfo.address[0].content}`
			: "",
		Pat_Memo: "", // 病患備註 #20 押單收200塊, % 都不收, $20 掛號收200 等...規則
		Pat_Job: patientInfo.info.job,
		Pat_Mark: patientInfo.info.note, // 病患註記

		// IDoctorBase
		Dr_Code: treatmentInfo.doctor_uid || "", // 醫師代碼 目前沒有
		Dr_Name: treatmentInfo.doctor_name || "",
		E_Dr_Name: doctorSpecData.E_Dr_Name || "", // 英文醫師名稱
		Dr_IDNo: doctorSpecData.Dr_IDNo || "", // 醫師身分證字號
		Dr_Certificate: doctorSpecData.Dr_Certificate || "", // 醫師證號
		Dr_Mobile: doctorSpecData.Dr_Mobile || "", // 醫師手機

		// IShiftBase
		Shift_Code: "", // 時段代碼
		Shift_Name: treatmentInfo.shift_name, // 時段名稱

		// IRoomBase
		RoomSeq: 1, // 第 N 診
		RoomName: treatmentInfo.clinic_room_name, // 診間名稱
		Dept_Code: treatmentInfo.subject_code,
		Dept_Name: treatmentInfo.subject_name,

		// Opd
		OpdDateTime: treatmentInfo.opd_at ? treatmentInfo.opd_at?.toISOString() : "",
		OpdStatus: "候診", //先寫死
		Req: 1, //先寫死
		RegNumber: currentVisitInfoData.reg_number, // 掛號序號
		Nhi_Type: treatmentInfo.nhi_type_name, // 健保身份
		Nhi_Card: treatmentInfo.card_no || (seqNumber256N1Result ? seqNumber256N1Result.visitSerialNumber : ""), // 健保卡號
		TreatNumber:
			mb1?.treatment_no ||
			(hisGetTreatNumNoICCardResult?.medicalTreatmentIdentificationCode ?? "") ||
			(seqNumber256N1Result?.visitIdentificationCode ?? ""), // 就醫識別碼
		Fee_HG: cashData.registrationFee, // 掛號費
		Fee_CO: cashData.deductibleFee, // 自付額
		Fee_LPay: cashData.depositFee, // 押單費
		Handler: "" // 經手人
	};
	return finalData;
};
