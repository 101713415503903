import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { ADDateTransToROCStr } from "functions/ADDateTransToROCStr";

import { useRegistrationRefillableStore } from "store/Registration/useRegistrationRefillableStore";
export const PrescriptionInfo = ({ refillable_uid }: { refillable_uid: string }) => {
	const { refillableDetailInfo, refillableLists } = useRegistrationRefillableStore();
	const Items = {
		p: "4px 8px",
		gap: "24px",
		align: "center"
	};
	return (
		<>
			<Flex sx={Items}>
				<Text textStyle={"semiTitle"} w={"120px"}>
					就醫日期
				</Text>
				<Text flex={1}>{ADDateTransToROCStr(refillableDetailInfo.first_date)}</Text>
			</Flex>
			<Flex sx={Items}>
				<Text textStyle={"semiTitle"} w={"120px"}>
					中文病名
				</Text>
				<Text flex={1} whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>
					{refillableLists.lists.find(item => item.data_id === refillable_uid)?.diagnosis_name}
				</Text>
			</Flex>

			<Flex sx={Items}>
				<Text textStyle={"semiTitle"} w={"120px"}>
					調劑次數
				</Text>
				<Text flex={1}>{refillableDetailInfo.refill_times} 次</Text>
			</Flex>
			<Flex sx={Items}>
				<Text textStyle={"semiTitle"} w={"120px"}>
					原卡號
				</Text>
				<Text flex={1}>{refillableDetailInfo.card_no ?? "-"}</Text>
			</Flex>
			<Flex sx={Items}>
				<Text textStyle={"semiTitle"} w={"120px"}>
					領藥次數
				</Text>
				<Text flex={1}>{refillableDetailInfo.get_times} 次</Text>
			</Flex>
		</>
	);
};
