import moment from "moment";
// Hook

import { Flex, Text, Button, Tag } from "@chakra-ui/react";
import Swal from "sweetalert2";
import { useEffect, useState, ChangeEvent } from "react";
import { useQuery } from "react-query";
// Store
import { useCurrentVisitInfoStore } from "store/Registration";
import { useRegistrationRefillableStore } from "store/Registration/useRegistrationRefillableStore";
import { useVitalSignsDataStore } from "store/Registration";
import { usePatientInfoStore } from "store/Registration";
import { useUserStore } from "store";
import { useSearchPatientStore } from "store/Registration";
// Status
import { useRegisteredFlowStatus } from "contexts/RegisteredFlowStatusContext";
import { useIsOpenDetailStatus } from "contexts/IsOpenDetailStatusContext";
import { useIsManualInputStatus } from "contexts/isManualInputStatusContext";
// Service
import { postOpdRecordService } from "service/opdRecordService";
import { callAccItemsLevelService, accItemsLevelProps } from "service/accItemsLevelService";
import { medicalStaffListsProps, callMedicalStaffListService } from "service/medicalStaffListService";
import { callScheduleDoctorRoomService } from "service/scheduleDoctorRoomService";

import { regFeeIdenProps, callGTypeRegFeeIdenService } from "service/gTypeService";
import { callShiftService } from "service/shiftService";
import { membershipProps, callMembershipService } from "service/memberShipService";
import { callClinicRoomService } from "service/clinicRoomService";
import { opdRecordProps } from "service/opdRecordService";
import { shiftProps } from "service/shiftService";
import { clinicRoomProps } from "service/clinicRoomService";
import { scheduleDoctorRoomObjProps } from "service/scheduleDoctorRoomService";
import { HoldAndReturnButton } from "./HoldAndReturnButton";
import { useBaseWaitingListQuery } from "components/WaitingListTable/Components/utils/useBaseWaitingListQuery";

// Tools
import { calculateAge } from "functions/calculateAge";
import { RegistrationButton } from "./EssentialPatientInformation/components/RegistrationButton";
import { VideoRegistrationButton } from "./EssentialPatientInformation/components/VideoRegistrationButton";

interface scheduleDoctorRoomProps {
	data_id: string;
	name: string;
}

export const EssentialPatientInformation = () => {
	const { userInfo } = useUserStore();
	const { vitalSignsData, resetVitalSignsData } = useVitalSignsDataStore();
	const [HGFeeUid, setHGFeeUid] = useState("");
	const [COFeeUid, setCOFeeUid] = useState("");
	const [LPAYFeeUid, setLPAYFeeUid] = useState("");
	const { setIsManualInputStatus } = useIsManualInputStatus();
	const { addNewDataToWaitingTableStatus, setAddNewDataToWaitingTableStatus, setShowPatientManagementStructureStatus } =
		useRegisteredFlowStatus();
	const { currentVisitInfoData, resetCurrentVisitInfoData } = useCurrentVisitInfoStore();
	const { patientInfo, resetPatientInfo } = usePatientInfoStore();
	const { resetSearchPatientData } = useSearchPatientStore();
	const { resetRefillableLists, resetRefillableDetailInfo } = useRegistrationRefillableStore();
	const { data: opdData, isLoading, error, refetch } = useBaseWaitingListQuery();
	const { isOpenDetailStatus, setIsOpenDetailStatus } = useIsOpenDetailStatus();

	const [birthDateString, setBirthDateString] = useState("");
	useEffect(() => {
		const birthDate = patientInfo.info.birth_date ? moment(patientInfo.info.birth_date) : null;
		let rocDate = "";

		if (birthDate && birthDate.isValid()) {
			const rocYear = birthDate.year() - 1911;
			rocDate = birthDate.format(`${rocYear}.MM.DD`);
		}

		setBirthDateString(rocDate);
	}, [patientInfo.info.birth_date]);

	const [regFeeIdenLists, setRegFeeIdenLists] = useState<regFeeIdenProps[]>([]);
	const [membershipLists, setMembershipLists] = useState<membershipProps[]>([]);
	const [shiftLists, setShiftLists] = useState<shiftProps[]>([]);
	const [clinicRoomLists, setClinicRoomLists] = useState<clinicRoomProps[]>([]);

	const { data: membershipData, isSuccess: isMembershipSuccess } = useQuery("membership", () => callMembershipService(userInfo.token!), {
		enabled: !!userInfo.token,
		staleTime: Infinity
	});
	const { data: regFeeIdenData, isSuccess: isRegFeeIdenSuccess } = useQuery(
		"regFeeIden",
		() => callGTypeRegFeeIdenService(userInfo.token!),
		{
			enabled: !!userInfo.token,
			staleTime: Infinity
		}
	);
	const { data: shiftData, isSuccess: isShiftSuccess } = useQuery("shift", () => callShiftService(userInfo.token!), {
		enabled: !!userInfo.token,
		staleTime: Infinity
	});
	const { data: clinicRoomData, isSuccess: isClinicRoomSuccess } = useQuery("clinicRoom", () => callClinicRoomService(userInfo.token!), {
		enabled: !!userInfo.token,
		staleTime: Infinity
	});

	const { data: medicalStaffListData, isSuccess: isMedicalStaffListSuccess } = useQuery(
		"medicalStaffDoctorList",
		() => callMedicalStaffListService(userInfo.token!, "iden_doctor"),
		{
			enabled: !!userInfo.token,
			staleTime: Infinity
		}
	);

	useEffect(() => {
		if (isRegFeeIdenSuccess) {
			setRegFeeIdenLists(regFeeIdenData.lists);
		}
		if (isMembershipSuccess) {
			setMembershipLists(membershipData.lists);
		}
		if (isShiftSuccess) {
			setShiftLists(shiftData.lists);
		}
		if (isClinicRoomSuccess) {
			setClinicRoomLists(clinicRoomData.lists);
		}
	}, [isRegFeeIdenSuccess, isMembershipSuccess, isShiftSuccess, isClinicRoomSuccess]);

	const { data: accItemsLevel3Data, isSuccess: isAccItemsLevel3DataSuccess } = useQuery(
		"accItemsLevel3",
		() => callAccItemsLevelService(userInfo.token, 3),
		{
			enabled: !!userInfo.token,
			staleTime: Infinity
		}
	);
	const [accItemsLevel3, setAccItemLevel3] = useState<accItemsLevelProps[]>([]);
	useEffect(() => {
		if (isAccItemsLevel3DataSuccess) {
			setAccItemLevel3(accItemsLevel3Data.lists);
			setHGFeeUid(accItemsLevel3Data.lists.find((item: accItemsLevelProps) => item.code === "HG").id);
			setCOFeeUid(accItemsLevel3Data.lists.find((item: accItemsLevelProps) => item.code === "CO").id);
			setLPAYFeeUid(accItemsLevel3Data.lists.find((item: accItemsLevelProps) => item.code === "LPAY").id);
		}
	}, [isAccItemsLevel3DataSuccess]);
	const handleSave = () => {
		opdRecordAPI("10");
	};
	const [scheduleDoctorRoomLists, setScheduleDoctorRoomLists] = useState<scheduleDoctorRoomProps[]>([]);
	const [scheduleDoctorRoomObj, setScheduleDoctorRoomObj] = useState<scheduleDoctorRoomObjProps[]>([]);
	const [todayDate, setTodayDate] = useState("");
	const [todayDateWithDash, setTodayDateWithDash] = useState(moment(new Date()).format("YYYY-MM-DD"));

	useEffect(() => {
		const rocYear = (parseInt(moment().format("YYYY")) - 1911).toString();
		const mm = moment().format("MM");
		const dd = moment().format("DD");

		const rocTodayDate = `${rocYear} 年 ${mm} 月 ${dd} 日`;
		// const rocTodayDateWithDash = `${rocYear}-${mm}-${dd}`;
		setTodayDate(rocTodayDate);
		// setTodayDateWithDash(rocTodayDateWithDash);
	}, []);

	const { data: scheduleDoctorRoomData, isSuccess: isScheduleDoctorRoomSuccess } = useQuery(
		["scheduleDoctorRoom", todayDateWithDash],
		() => callScheduleDoctorRoomService(userInfo.token!, todayDateWithDash),
		{
			enabled: !!userInfo.token && !!todayDateWithDash,
			staleTime: Infinity
		}
	);
	useEffect(() => {
		if (isScheduleDoctorRoomSuccess) {
			setScheduleDoctorRoomObj(scheduleDoctorRoomData.lists);
		}
	}, [isScheduleDoctorRoomSuccess]);

	// 判斷今日時段診間 醫師lists
	useEffect(() => {
		let arr: scheduleDoctorRoomProps[] = [];
		scheduleDoctorRoomObj &&
			Object.entries(scheduleDoctorRoomObj).forEach(([key, value]) => {
				// console.log(`${key}:`);

				if (Array.isArray(value) && key === currentVisitInfoData.shift_uid) {
					value.forEach((item, index) => {
						Object.entries(item).forEach(([subKey, subValue]) => {
							let finalList = {
								data_id: subKey,
								name: subValue as string
							};
							arr.push(finalList);
						});
					});
				}
			});
		setScheduleDoctorRoomLists(arr);
	}, [scheduleDoctorRoomObj, currentVisitInfoData.shift_uid]);

	// };

	// 儲存基礎體徵
	const opdRecordAPI = async (status: string) => {
		let cashierDeme = [
			{
				acc_item_uid: accItemsLevel3.find(item => item.code === "HG")?.id ?? "",
				cashier_type: "3",
				price: Math.floor(currentVisitInfoData?.cashier?.find(item => item.acc_name === "掛號費")?.price ?? 0),
				final: Math.floor(currentVisitInfoData?.cashier?.find(item => item.acc_name === "掛號費")?.price ?? 0)
			},
			{
				acc_item_uid: accItemsLevel3.find(item => item.code === "CO")?.id ?? "",
				cashier_type: "3",
				price: Math.floor(currentVisitInfoData?.cashier?.find(item => item.acc_name === "自付額")?.price ?? 0),
				final: Math.floor(currentVisitInfoData?.cashier?.find(item => item.acc_name === "自付額")?.price ?? 0)
			},
			{
				acc_item_uid: accItemsLevel3.find(item => item.code === "LPAY")?.id ?? "",
				cashier_type: "3",
				price: Math.floor(currentVisitInfoData?.cashier?.find(item => item.acc_name === "押單費")?.price ?? 0),
				final: Math.floor(currentVisitInfoData?.cashier?.find(item => item.acc_name === "押單費")?.price ?? 0)
			}
		];

		if (!patientInfo?.info?.data_id) {
			console.error("Patient info is missing");
			Swal.fire({
				icon: "error",
				title: "錯誤",
				text: "病患資訊不完整，無法進行操作"
			});
			return;
		}
		const today = moment().format("YYYY-MM-DD");
		const cardNo = moment(currentVisitInfoData.opd_at).format("YYYY-MM-DD") === today ? null : "A999";

		let finalData: opdRecordProps = {
			...(currentVisitInfoData?.data_id && {
				data_id: currentVisitInfoData.data_id
			}),
			...(currentVisitInfoData?.opd_at && {
				opd_at: currentVisitInfoData.opd_at
			}),
			...(currentVisitInfoData?.cashier_uid && {
				cashier_uid: currentVisitInfoData.cashier_uid
			}),
			patient_uid: patientInfo.info.data_id,
			shift_uid: currentVisitInfoData?.shift_uid || (shiftLists[0]?.data_id ?? ""),
			clinic_room_uid: currentVisitInfoData?.clinic_room_uid || (scheduleDoctorRoomLists[0]?.data_id?.split("_")[0] ?? ""),
			doctor_uid: currentVisitInfoData?.doctor_uid || (scheduleDoctorRoomLists[0]?.data_id?.split("_")[1] ?? ""),
			nhi_type: currentVisitInfoData?.nhi_type || (regFeeIdenLists[0]?.code ?? ""),
			member_uid: currentVisitInfoData?.member_uid ?? "",
			card_no: currentVisitInfoData?.card_no ?? "",
			mt_type: currentVisitInfoData?.mt_type ?? "",
			note: currentVisitInfoData?.note ?? "",
			cashier_status: currentVisitInfoData?.cashier_status ?? "",
			total: cashierDeme.reduce((acc, item) => acc + Number(item.price), 0),
			discount: null,
			final: cashierDeme.reduce((acc, item) => acc + Number(item.price), 0),
			cashier: cashierDeme,
			...(currentVisitInfoData.opd_at ? { opd_at: currentVisitInfoData.opd_at } : {}),
			opd_status: status,
			vital_sign: currentVisitInfoData.vital_sign,
			...(currentVisitInfoData.opd_at ? { opd_at: currentVisitInfoData.opd_at } : { opd_at: todayDateWithDash }),
			...(cardNo ? { card_no: cardNo } : {}),

			refillable_uid: currentVisitInfoData.refillable_uid,
			refillable_detail_uid: currentVisitInfoData.refillable_detail_uid
		};

		try {
			if (!userInfo?.token) {
				throw new Error("User token is missing");
			}
			console.log("送出前確認 finalData", finalData);
			const result = await postOpdRecordService(userInfo.token, finalData);
			if (result.result === "success") {
				setShowPatientManagementStructureStatus(false);
				setAddNewDataToWaitingTableStatus(true);
				refetch();
				resetAllRegistrationStores();
				setIsOpenDetailStatus(false);
				setIsManualInputStatus(false);
			} else if (result.result === "failure") {
				Swal.fire({
					icon: "warning",
					title: "API回傳代碼",
					text: result.msg
				});
			}
		} catch (error) {
			console.error("Error in opdRecordAPI:", error);
			Swal.fire({
				icon: "error",
				title: "後端服務錯誤",
				text: "操作失敗，請稍後再試",
				timer: 1500,
				showConfirmButton: false
			});
		}
	};

	const resetAllRegistrationStores = () => {
		resetCurrentVisitInfoData();
		resetPatientInfo();
		resetVitalSignsData();
		resetSearchPatientData();
		resetRefillableLists();
		resetRefillableDetailInfo();
	};
	const handleVideoRegistration = () => {
		console.log("handleVideoRegistration");
	};

	// 還單狀態
	const [isReturn, setIsReturn] = useState<boolean>(false);
	useEffect(() => {
		console.log(currentVisitInfoData, "currentVisitInfoData kkkkkkkk");
	}, [currentVisitInfoData]);

	const getDoctorUid = () => {
		return currentVisitInfoData?.doctor_uid || (scheduleDoctorRoomLists[0]?.data_id?.split("_")[1] ?? "");
	};
	return (
		<>
			<Flex w={"100%"} direction={"column"} bg={"gray.50"} p={"16px"} rounded={"15px"} borderBottom={"2px solid #ccc"}>
				<Flex gap={"8px"} align={"center"}>
					{currentVisitInfoData.data_id && (
						<Button colorScheme='rsWarning' onClick={handleSave}>
							儲存
						</Button>
					)}

					{!currentVisitInfoData.data_id && (
						<>
							{/* <Button
                                colorScheme="rsPrimary"
                                onClick={handleSave}
                            >
                                掛號
                            </Button> */}
							<RegistrationButton opdRecordAPI={opdRecordAPI} />
							{isReturn && <HoldAndReturnButton />}
						</>
					)}

					<Button variant={"outline"} colorScheme='rsPrimary' borderWidth={"2px"} fontWeight={"semibold"}>
						預約
					</Button>
					<Button variant={"outline"} colorScheme='rsDanger' borderWidth={"2px"} fontWeight={"semibold"}>
						報到
					</Button>
					{!currentVisitInfoData.data_id && <VideoRegistrationButton opdRecordAPI={opdRecordAPI} doctorUid={getDoctorUid()} />}
				</Flex>

				<Flex direction={"column"} mt={"16px"} gap={"8px"}>
					<Flex gap={"30px"}>
						<Text py={"8px"} textStyle={"semiTitle"} flex={"0 0 120px"}>
							個人檔案
						</Text>
						<Flex flexWrap={"wrap"} columnGap={"50px"}>
							<Flex py={"8px"} gap={"16px"} flex={"0 1 240px"}>
								<Text textStyle={"semiTitle"}>姓名</Text>
								<Text>{patientInfo.info.name}</Text>
							</Flex>
							<Flex py={"8px"} gap={"16px"} flex={"0 1 240px"}>
								<Text textStyle={"semiTitle"}>病歷號</Text>
								<Text>{patientInfo.info.medical_record_number}</Text>
							</Flex>
							<Flex py={"8px"} gap={"16px"} flex={"0 1 240px"}>
								<Text textStyle={"semiTitle"}>生日</Text>
								<Text>{birthDateString}</Text>
								<Tag bg='rsDanger.800' textColor={"#fff"}>
									{patientInfo.info.birth_date ? calculateAge(patientInfo.info.birth_date) : ""}
								</Tag>
							</Flex>

							<Flex py={"8px"} gap={"16px"} flex={"0 1 240px"}>
								<Text textStyle={"semiTitle"}>生理性別</Text>
								<Text>{patientInfo.info.sex === "M" ? "男" : "女"}</Text>
							</Flex>
							<Flex py={"8px"} gap={"16px"} flex={"0 1 240px"}>
								<Text textStyle={"semiTitle"}>身分證號</Text>
								<Text>{patientInfo.info.id_no}</Text>
							</Flex>
							<Flex py={"8px"} gap={"16px"} flex={"0 1 240px"}>
								<Text textStyle={"semiTitle"}>藥物過敏</Text>
								<Text>
									{patientInfo.info.if_drug_allergy && patientInfo.info.if_drug_allergy.toString() === "1"
										? patientInfo.info.drug_allergy_describe
											? patientInfo.info.drug_allergy_describe
											: "有"
										: "無"}
								</Text>
							</Flex>
						</Flex>
					</Flex>

					<hr />

					<Flex gap={"30px"}>
						<Text py={"8px"} textStyle={"semiTitle"} flex={"0 0 120px"}>
							相關身份
						</Text>
						<Flex flexWrap={"wrap"} w={"100%"} columnGap={"50px"}>
							<Flex py={"8px"} gap={"16px"} flex={"0 1 240px"}>
								<Text textStyle={"semiTitle"}>身份</Text>
								<Text>
									{regFeeIdenLists &&
										(regFeeIdenLists.find(item => item.code === patientInfo.info.nhi_identity)?.name || "")}
								</Text>
							</Flex>
							<Flex py={"8px"} gap={"16px"} flex={"0 1 240px"}>
								<Text textStyle={"semiTitle"}>優免身份</Text>
								<Text>
									{membershipLists &&
										(membershipLists?.find(item => item.data_id === patientInfo.info.membership)?.name ?? "")}
								</Text>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};
