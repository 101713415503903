import React from "react";
import { Flex, Box, Text, Input, InputGroup, InputRightAddon, Center, Button } from "@chakra-ui/react";
import { TableContainer, Table, Thead, Tbody, Th, Tr, Td } from "@chakra-ui/react";

export const Repayment = () => {
	return (
		<>
			<Flex
				textColor={"#fff"}
				bg={"#646B48"}
				p={"8px 16px"}
				justify={"space-between"}
				rounded={"lg"}
				boxShadow={"inset 4px 2px 2px rgba(0,0,0,0.25)"}
				mb={"8px"}>
				<Text>本次已還款金額</Text>

				<Text fontSize={"xl"}>0 元</Text>
			</Flex>

			<Box bg={"#F5F5F5"} rounded={"lg"} p={"8px 16px"} mb={"8px"}>
				<Text textStyle={"semiTitle"}>本次還款</Text>
				<Flex align={"center"} gap={"8px"} pl={"16px"} mb={"8px"}>
					<Text fontWeight={"semibold"} flexShrink={0}>
						還款金額
					</Text>
					<InputGroup>
						<Input defaultValue={"0"} bg={"#fff"} textAlign={"right"}></Input>
						<InputRightAddon>元</InputRightAddon>
					</InputGroup>
				</Flex>
				<Flex align={"center"} gap={"8px"} pl={"16px"} mb={"8px"}>
					<Text fontWeight={"semibold"} flexShrink={0}>
						實收金額
					</Text>
					<InputGroup>
						<Input defaultValue={"0"} bg={"#fff"} textAlign={"right"}></Input>
						<InputRightAddon>元</InputRightAddon>
					</InputGroup>
				</Flex>
				<Flex align={"center"} justify={"space-between"} gap={"8px"} pl={"16px"} mb={"8px"}>
					<Text fontWeight={"semibold"} flexShrink={0}>
						待找金額
					</Text>
					<Flex align={"center"} gap={"8px"}>
						<Text fontWeight={"semibold"} fontSize={"xl"} textColor={"rsDanger.500"} px={"8px"}>
							0
						</Text>
						<Center h={"40px"} px={"16px"}>
							元
						</Center>
					</Flex>
				</Flex>
				<Flex justify={"flex-end"}>
					<Button colorScheme='rsPrimary'>還款</Button>
				</Flex>
			</Box>

			<TableContainer h={"300px"} overflowY={"scroll"}>
				<Table>
					<Thead bg={"#D9D9D9"} pos={"sticky"} top={0} left={0} zIndex={10}>
						<Tr>
							<Th>
								<Text fontSize={"md"}>欠款日期</Text>
							</Th>
							<Th>
								<Text fontSize={"md"}>時間</Text>
							</Th>
							<Th>
								<Text fontSize={"md"} textAlign={"right"}>
									還款金額
								</Text>
							</Th>
							<Th>
								<Text fontSize={"md"}>經手人</Text>
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						<Tr>
							{/* <Td>113.07.10</Td>
                            <Td>10:30:55</Td>
                            <Td textAlign={'right'}>200</Td>
                            <Td>李阿信</Td> */}
							<Td></Td>
							<Td></Td>
							<Td></Td>
							<Td></Td>
						</Tr>
					</Tbody>
				</Table>
			</TableContainer>
		</>
	);
};
