import { useCallback } from 'react';
import { useTreatmentInfoStore } from 'store/TreatmentManagement';
import { useNavigate } from 'react-router-dom';
import { useHistoryStore } from 'store/TreatmentManagement';
import { usePrescriptionStore } from 'store/TreatmentManagement';
import { useDxStore } from 'store/TreatmentManagement';
import { useCCPEStore } from 'store/TreatmentManagement';
import { usePricingStore } from 'store/TreatmentManagement';
import { useMedicalRecordStatus } from 'contexts/TreatmentManagement/MedicalRecordStatusContext';
import Swal from 'sweetalert2';

export const useGoToConsultingRoom = () => {
    const { updateTreatmentInfo, resetTreatmentInfo } = useTreatmentInfoStore();
    const { resetHistoryData } = useHistoryStore();
    const { resetPrescriptionList } = usePrescriptionStore();
    const { resetDx } = useDxStore();
    const { resetCCPEData } = useCCPEStore();
    const { resetPricing } = usePricingStore();
    const { setMedicalRecordStatus } = useMedicalRecordStatus();
    const navigate = useNavigate();

    const handleGoToConsultingRoom = useCallback(
        (id: string | undefined, from: string) => {
            if (id) {
                console.log('Go to consulting room', id);
                resetTreatmentInfo();
                resetHistoryData();
                resetPrescriptionList();
                resetDx();
                resetCCPEData();
                resetPricing();
                setMedicalRecordStatus(from);
                updateTreatmentInfo({
                    id: id,
                });
                setTimeout(() => {
                    navigate('/OPDPage/ConsultingRoomPage');
                }, 0);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '找不到此病歷',
                    text: '請確認病歷資料是否正確',
                    showConfirmButton: true,
                });
            }
        },
        [
            resetTreatmentInfo,
            resetHistoryData,
            resetPrescriptionList,
            resetDx,
            resetCCPEData,
            resetPricing,
            setMedicalRecordStatus,
            updateTreatmentInfo,
            navigate,
        ]
    );

    return { handleGoToConsultingRoom };
};
