import axios from "axios";
import axiosInstance from "./axiosInstance";
import apiService from "./apiService";

// 取得特定人員的詳細資料
export const callMedicalStaffSpecService = async (token: string, data: string) => {
	console.log("url", `/api/medical_staff/spec/${data}`);
	const queryParams = data ? { data } : undefined;
	return apiService<string, any>({
		method: "GET",
		path: `/api/medical_staff/spec`,
		token,
		data: queryParams
	});
};
// export const callMedicalStaffSpecService = async (
//     token: string,
//     data_id: string
// ) => {
//     try {
//         const queryParams = { data_id };
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         const response = await axiosInstance.get('/api/medical_staff/spec', {
//             headers,
//             params: queryParams,
//         });
//         const result = response.data;
//         console.log(result, 'API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };

export interface updatedDataProps {
	data_id?: string;
	info: {
		name: string;
		name_en?: string | null;
		birth_date?: string | null;
		id_no?: string | null;
		cellphone?: string | null;
		email?: string | null;
		color: string;
		date_in?: string | null;
		date_out?: string | null;
		subject_code?: string | null;
		address?: string | null;
		emergence_contactor?: string | null;
		emergence_relationship?: string | null;
		emergence_phone?: string | null;
		identity?: string | null;
		personal_auth?: {
			[key: string]: string;
		};
	};
	license?: FinalDataProps[];
	group_auth?: string[];
}
interface FinalDataProps {
	license_type?: string;
	license_no?: string;
	start_date?: string | null;
	end_date?: string | null;
}

// 增修特定人員的詳細資料
export const postMedicalStaffSpecService = async (token: string, updatedData: updatedDataProps) => {
	return apiService<updatedDataProps, any>({
		method: "POST",
		path: "/api/medical_staff/spec",
		token,
		data: updatedData
	});
};
// export const postMedicalStaffSpecService = async (
//     token: string,
//     updatedData: updatedDataProps
// ) => {
//     console.log('有進來要存特定人員');
//     try {
//         const requestBody = JSON.stringify(updatedData);
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         const response = await axiosInstance.post(
//             '/api/medical_staff/spec',
//             requestBody,
//             {
//                 headers,
//             }
//         );
//         const result = response.data;
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
