import { Flex, Box, Select, Input, Button } from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import moment from "moment";
import { medicalStaffListsProps, callMedicalStaffListService } from "service/medicalStaffListService";
import { useWaitingListsStatus } from "contexts/Registration/WaitingListsStatusContext";
import { useDropDownMenuQuery } from "query/useDropDownMenuQuery";
import { useUserStore } from "store";
import { usePatientInfoStore } from "store/Registration";
import { shiftProps } from "service/shiftService";
import Clock from "components/CustomComponents/Clock";
import { useLocation } from "react-router-dom";
import ROCDatePicker from "components/CustomComponents/ROCDatePicker";
import { useRegistrationListStatus } from "contexts/Registration/RegistrationListStatusContext";

export const RegistrationLIstFilterBar = () => {
	const { userInfo } = useUserStore();
	const { patientInfo, updatePatientInfo } = usePatientInfoStore();

	const {
		filterByDoctorStatus,
		setFilterByDoctorStatus,
		filterByPeriodStatus,
		setFilterByPeriodStatus,
		filterByPatientDataStatus,
		setFilterByPatientDataStatus,
		filterByDateStatus,
		setFilterByDateStatus
	} = useRegistrationListStatus();

	// 取得醫師名單
	const [medicalStaffLists, setMedicalStaffLists] = useState<medicalStaffListsProps[]>([]);
	const { data: medicalStaffListData, isSuccess: isMedicalStaffListSuccuss } = useQuery(
		"medicalStaffList",
		() => callMedicalStaffListService(userInfo.token!, "iden_doctor"),
		{ enabled: !!userInfo.token, staleTime: Infinity }
	);

	// 取得時段
	const [shiftLists, setShiftLists] = useState<shiftProps[]>([]);

	const { data: shiftListData, isSuccess: isShiftListSuccess } = useDropDownMenuQuery("shift", userInfo.token!);

	useEffect(() => {
		if (isMedicalStaffListSuccuss) {
			setMedicalStaffLists(medicalStaffListData.lists);
		}
		if (isShiftListSuccess) {
			setShiftLists(shiftListData.lists);
		}
	}, [isMedicalStaffListSuccuss, isShiftListSuccess]);

	let location = useLocation();
	const pathName = location.pathname;
	const pathSegments = pathName.split("/");
	const lastSegment = pathSegments.pop();

	const [selectedDate, setSelectedDate] = useState<Date | null>(null);

	useEffect(() => {
		if (patientInfo?.info.birth_date) {
			setSelectedDate(new Date(patientInfo.info.birth_date));
		}
	}, [patientInfo?.info.birth_date]);

	const handleChangeDate = (newSelectedDate: Date | null) => {
		if (newSelectedDate !== selectedDate) {
			setSelectedDate(newSelectedDate);
			setFilterByDateStatus(newSelectedDate ? newSelectedDate.toISOString() : "");
			console.log(newSelectedDate, "多少");
		}
	};

	const handleFocusDate = (_: React.FocusEvent<HTMLInputElement>) => {
		// No need to set preSelectedDate here
	};

	const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		const enteredValue = event.currentTarget.value.replace(/\./g, "");

		if (enteredValue.length < 6) {
			return;
		}

		const yearOffset = enteredValue.length === 7 ? 0 : 1;
		const year = parseInt(enteredValue.substring(0, 3 - yearOffset)) + 1911;
		const month = enteredValue.substring(3 - yearOffset, 5 - yearOffset);
		const day = enteredValue.substring(5 - yearOffset);

		const formattedDate = `${year}-${month}-${day}`;
		const enteredDate = moment(formattedDate, "YYYY-MM-DD");

		// 檢查日期
		if (!enteredDate.isValid()) {
			setSelectedDate(null);
			setFilterByDateStatus("");
		} else {
			handleChangeDate(enteredDate.toDate());
		}
	};

	return (
		<>
			<Flex align={"center"} justify={"space-between"}>
				<Flex align={"center"} mb={"8px"} columnGap={"16px"}>
					<Flex zIndex={20} gap={"8px"} align={"center"}>
						<Box textColor={"rsPrimary.500"}>
							<HeroIcons name='calendar-days'></HeroIcons>
						</Box>
						<ROCDatePicker
							onChange={handleChangeDate}
							selectedDate={selectedDate}
							handleChangeDate={handleChangeDate}
							onFocus={handleFocusDate}
							onBlur={handleBlur}
							maxDate={new Date()} // Set maximum date to today
						></ROCDatePicker>
					</Flex>
					<Flex gap={"8px"} align={"center"}>
						<Box textColor={"rsPrimary.500"}>
							<HeroIcons name='clock'></HeroIcons>
						</Box>
						<Select
							bg={"#fff"}
							w={"200px"}
							value={filterByPeriodStatus || ""}
							onChange={e => {
								setFilterByPeriodStatus(e.target.value);
							}}>
							<option value=''>全部</option>
							{shiftLists &&
								shiftLists.map((item, index) => (
									<option key={index} value={item.name}>
										{item.name}
									</option>
								))}
						</Select>
					</Flex>
					<Flex gap={"8px"} align={"center"}>
						<Box textColor={"rsPrimary.500"}>
							<HeroIcons name='user'></HeroIcons>
						</Box>
						<Select
							bg={"#fff"}
							w={"200px"}
							value={filterByDoctorStatus || ""}
							onChange={e => {
								setFilterByDoctorStatus(e.target.value);
							}}>
							<option value=''>全部醫師</option>
							{medicalStaffLists &&
								medicalStaffLists.map((item: any, index: number) => (
									<option key={index} value={item.name}>
										{item.name}
									</option>
								))}
						</Select>
					</Flex>

					<Flex columnGap={"8px"} align={"center"}>
						<Box textColor={"rsPrimary.500"}>
							<HeroIcons name='search'></HeroIcons>
						</Box>
						<Input
							bg={"#fff"}
							w={"200px"}
							placeholder='姓名 / 病歷號 '
							value={filterByPatientDataStatus || ""}
							onChange={e => {
								setFilterByPatientDataStatus(e.target.value);
							}}></Input>
					</Flex>
					<Button
						colorScheme='rsSecondary'
						variant={"link"}
						onClick={() => {
							setFilterByDoctorStatus("");
							setFilterByPeriodStatus("");
							setFilterByPatientDataStatus("");
							setFilterByDateStatus("");
						}}>
						<HeroIcons name='recycle-sm'></HeroIcons>{" "}
						<Box as='span' ml={"4px"}>
							清除篩選條件
						</Box>
					</Button>
				</Flex>
				<Box hidden={lastSegment === "RegisteredPage"} p={"8px 16px"} textColor={"rsPrimary.500"}>
					<Clock />
				</Box>
			</Flex>
		</>
	);
};
