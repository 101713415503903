export const handleFrequencyMatching = (
	days: number,
	times: number,
	transfer: number,
	x: number,
	quantit: number
): { total: number; dailyAmount: number } => {
	// if (transfer > 0) {
	//     transfer = 1 / transfer;
	// } else if (transfer < 0) {
	//     transfer = Math.abs(transfer);
	// }

	// console.log(days, 'days');
	// console.log(transfer, 'transfer');
	// console.log(x, 'x');
	// console.log(times, 'times');
	// console.log(quantit, 'quantit');

	// const total = days * transfer * (1 / x) * times * quantit;
	times = times || 1;
	const total = days * times * quantit;
	const dailyAmount = times * quantit;
	console.log(total, "total");

	return { total, dailyAmount };
};
