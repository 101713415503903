import { callRshisGetRegisterBasicService } from 'service/RsService/RshisGetRegisterBasicService';

export type RegisterBasicProps = {
    cardNumber: string;            // 卡片號碼 (1-12)
    name: string;                  // 姓名 (13-32)
    idOrDocumentNumber: string;    // 身分證號或身分證明文件號碼 (33-42)
    birthDate: string;             // 出生日期 (43-49)
    gender: string;                // 性別 (50)
    issueDate: string;             // 發卡日期 (51-57)
    cancellationMark: string;      // 卡片註銷註記 (58)
    insurerCode: string;           // 保險人代碼 (59-60)
    insureIdentity: string;        // 保險對象身份註記 (61)
    cardValidityPeriod: string;    // 卡片有效期限 (62-68)
    medicalTreatmentNumber: string;// 就醫可用次數 (69-70)
    newBornAttachmentDate: string; // 新生兒依附註記之新生兒出生日期 (71-77)
    newBornTwinsNote: string;      // 新生兒依附註記之新生兒胞胎註記 (78)
    PatCardNumber?: string;    // 卡片號碼(1-12)
    PatName?: string;          // 姓名(13-32)
    PatIDNo?: string;          // 身分證號或身分證明文件號碼(33-42)
    PatBirth?: string;         // 出生日期(43-49)
    PatGender?: string;        // 性別(50)
    PatCardDate?: string;      // 發卡日期(51-57)
    PatCancelMark?: string;    // 卡片註銷註記(58)
    PatInsurerCode?: string;   // 保險人代碼(59-60)
    PatInsurerType?: string;   // 保險對象身份註記(61)
    PatCardExpDate?: string;   // 卡片有效期限(62-68)
    PatAvaliableCount?: string;// 就醫可用次數(69-70)
    PatNewBornBirth?: string;  // 新生兒依附註記之新生兒出生日期(71-77)
    PatNewBornMark?: string;   // 新生兒依附註記之新生兒胞胎註記(78)
    pBuffer?: string;
};

export const hisGetRegisterBasic = async (): Promise<RegisterBasicProps> => {
    try {
        const response = await callRshisGetRegisterBasicService();
        console.log(response, 'hisGetRegisterBasic origin data');
        
        let parsedData: RegisterBasicProps;

        if (response.PatCardNumber) {
            // 新版本，直接使用回傳的資料
            console.log('new version')
            parsedData = {
                cardNumber: response.PatCardNumber,
                name: response.PatName || '',
                idOrDocumentNumber: response.PatIDNo || '',
                birthDate: response.PatBirth || '',
                gender: response.PatGender || '',
                issueDate: response.PatCardDate || '',
                cancellationMark: response.PatCancelMark || '',
                insurerCode: response.PatInsurerCode || '',
                insureIdentity: response.PatInsurerType || '',
                cardValidityPeriod: response.PatCardExpDate || '',
                medicalTreatmentNumber: response.PatAvaliableCount || '',
                newBornAttachmentDate: response.PatNewBornBirth || '',
                newBornTwinsNote: response.PatNewBornMark || '',
                ...response
            };
        } else if (response.pBuffer) {
            console.log('old version')
            // 舊版本，需要解析 pBuffer
            const secPBuffer = response.pBuffer
            console.log(secPBuffer, '我還沒切Buffer的資料')
            parsedData = parsePBuffer(secPBuffer);
            console.log('old version data', parsedData)
        } else {
            console.error('Invalid response format');
            throw new Error('Invalid response format');
        }

        console.log(parsedData, 'Parsed data from getregisterbasic service');
        return parsedData;
    } catch (error) {
        console.error('取得掛號基本資訊時發生錯誤:', error);
        throw error;
    }
};

const parsePBuffer = (pBuffer: string): RegisterBasicProps => {
    const parsedData = {
        cardNumber: pBuffer.substring(0, 12).trim(),
        name: pBuffer.substring(12, 28).trim(),
        idOrDocumentNumber: pBuffer.substring(28, 39).trim(),
        birthDate: pBuffer.substring(39, 46).trim(),
        gender: pBuffer.substring(46, 47).trim(),
        issueDate: pBuffer.substring(47, 54).trim(),
        cancellationMark: pBuffer.substring(54, 55).trim(),
        insurerCode: pBuffer.substring(55, 57).trim(),
        insureIdentity: pBuffer.substring(57, 58).trim(),
        cardValidityPeriod: pBuffer.substring(58, 65).trim(),
        medicalTreatmentNumber: pBuffer.substring(65, 67).trim(),
        newBornAttachmentDate: pBuffer.substring(67, 74).trim(),
        newBornTwinsNote: pBuffer.substring(74, 81).trim(),
    };

    console.log('Parsed pBuffer values:');
    for (const [key, value] of Object.entries(parsedData)) {
        console.log(`${key}: "${value}"`);
    }

    return parsedData;
};
