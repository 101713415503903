import { create } from "zustand";

export interface HistoryCCPEProps {
	cc?: string;
	pe?: string;
}
const initialHistoryCCPEData: HistoryCCPEProps = {
	cc: "",
	pe: ""
};
interface HistoryCCPEState {
	historyCCPEData: HistoryCCPEProps;
	updateHistoryCCPEData: (newData: Partial<HistoryCCPEProps>) => void;
	resetHistoryCCPEData: () => void;
}

export const useHistoryCCPEStore = create<HistoryCCPEState>(set => ({
	historyCCPEData: initialHistoryCCPEData,
	updateHistoryCCPEData: newData =>
		set(state => ({
			historyCCPEData: { ...state.historyCCPEData, ...newData }
		})),
	resetHistoryCCPEData: () =>
		set(() => ({
			historyCCPEData: initialHistoryCCPEData
		}))
}));
