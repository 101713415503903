import React, { useEffect, useState } from "react";

const Clock: React.FC = () => {
	const [currentTime, setCurrentTime] = useState<string>("");

	useEffect(() => {
		const updateTime = () => {
			const now = new Date();
			const hours = now.getHours().toString().padStart(2, "0");
			const minutes = now.getMinutes().toString().padStart(2, "0");
			const seconds = now.getSeconds().toString().padStart(2, "0");
			setCurrentTime(`${hours}:${minutes}:${seconds}`);
		};

		updateTime();
		const intervalId = setInterval(updateTime, 1000);

		return () => clearInterval(intervalId);
	}, []);

	return <div>{currentTime}</div>;
};

export default Clock;
