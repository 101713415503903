import apiService from "./apiService";

export type IcDataDlXMLReqDataProps = {
	start_date?: string;
	end_date?: string;
};
export const callIcDataDlXMLService = async (token: string, data?: IcDataDlXMLReqDataProps): Promise<any> => {
	return apiService<any, any>({
		method: "GET",
		path: "/api/ic_data/dlXML",
		token,
		data,
		responseType: "arraybuffer"
	});
};
