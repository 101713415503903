import React, { useEffect } from "react";
import { Text, Flex, Button } from "@chakra-ui/react";
import Swal from "sweetalert2";

import { Date2DatePick } from "./Date2DatePick";
import { UploadStatus } from "./UploadStatus";

import { useUserStore } from "../../../store";

import { useICCardUpdateScopeStore } from "store/ICCardUpdate/useICCardUpdateScopeStore";
import { useICCardUpdateListTabIndexStore } from "../../../store/ICCardUpdate/useICCardUpdateListTabIndexStore";

import { useIcDataListsAPI } from "../../../service/icDataListsServiceHelper";
import { useIcDataVoidAPI } from "../../../service/icDataVoidServiceHelper";

export const Search = () => {
	const { userInfo } = useUserStore();
	const { icCardUpdateScopeData } = useICCardUpdateScopeStore();
	const { tabIndex } = useICCardUpdateListTabIndexStore();
	const { callIcDataVoidAPI } = useIcDataVoidAPI();
	const { callIcDataListsAPI } = useIcDataListsAPI();

	const handleSearch = () => {
		if (tabIndex === 0) {
			callIcDataListsAPI();
		} else if (tabIndex === 1) {
			callIcDataVoidAPI();
		}
	};

	useEffect(() => {
		console.log("tabIndex: ", tabIndex);
	}, [tabIndex]);

	useEffect(() => {
		console.log("icCardUpdateScopeData: ", icCardUpdateScopeData);
	}, [icCardUpdateScopeData]);

	return (
		<>
			<Flex gap={"8px"} align={"center"}>
				<Text textStyle={"semiTitle"}>查詢日期區間資料</Text>
				<Date2DatePick />
				<Button colorScheme={"rsPrimary"} onClick={handleSearch}>
					搜尋
				</Button>

				<UploadStatus />
				{/* <ICReader /> */}
			</Flex>
		</>
	);
};
