import React, { Component, ReactNode } from "react";
import Swal from "sweetalert2";

interface ErrorBoundaryProps {
	children: ReactNode;
}

interface ErrorBoundaryState {
	hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(_: Error): ErrorBoundaryState {
		// 更新 state 以便下一次 render 顯示 fallback UI
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		// 在 console 中記錄錯誤信息
		console.error("Error caught by Error Boundary:", error, errorInfo);

		// 使用 Swal 顯示錯誤信息
		Swal.fire({
			icon: "error",
			title: "發生錯誤",
			text: "應用程式發生異常，請稍後再試。"
		});
	}

	render() {
		if (this.state.hasError) {
			// 防止頁面渲染錯誤，只顯示空白內容
			return null;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
