import React from "react";
import { Flex, Button } from "@chakra-ui/react";
import { ShowCases } from "./ShowCases";
import { ShowCasesOutline } from "./ShowCasesOutline";
import { ExportAndPrint } from "./ExportAndPrint";
import { useDeclareStaticDataStore } from "store/DeclarationAudit/useDeclareStaticDataStore";

export const CasesCounter = () => {
	const { declareStaticData } = useDeclareStaticDataStore();

	return (
		<>
			<Flex gap={"8px"} mt={"8px"}>
				<ShowCases title={"總件數"} num={declareStaticData.total} />
				<ShowCases title={"一般案件"} num={declareStaticData.case_01} />
				<ShowCases title={"其他案件"} num={declareStaticData.case_other} />
				<ShowCasesOutline title={"押單"} num={declareStaticData.deposit} />
				<ShowCasesOutline title={"刪除"} num={0} />
				{/* <ExportAndPrint /> */}
			</Flex>
		</>
	);
};
