import moment from 'moment';
import Swal from 'sweetalert2';
export const ADStringToROCStringyyyMMddHHmmssNoDot = (adString: string): string => {
    if (!adString || adString.length !== 19) {
        Swal.fire({
            icon: 'error',
            title: '日期格式錯誤',
            showConfirmButton: true,
        });
        return '';
    }

    const adDate = moment(adString, 'YYYY-MM-DD HH:mm:ss');
    if (!adDate.isValid()) {
        console.error('Invalid date');
        return '';
    }

    const rocYear = adDate.year() - 1911;
    const month = adDate.format('MM');
    const day = adDate.format('DD');
    const time = adDate.format('HHmmss');

    return `${rocYear}${month}${day}${time}`;
};
