import axios from "axios";
import { PrintCompleteClinicProps } from "store/TreatmentManagement/Print/usePrintCompleteClinicStore";
// import RsApiService from './RSApiService'

// Rs讀取卡片狀態
export const postRsPrintCompleteClinicService = async (data: PrintCompleteClinicProps) => {
	try {
		const response = await axios.post("http://localhost:23752/Print/CompleteClinic", data, {
			headers: {
				Accept: "*/*",
				"Content-Type": "application/json"
			}
		});

		console.log("批次列印結果", response.data);
		return response.data;
	} catch (error: unknown) {
		if (error instanceof Error) {
			console.error("Error:", error.message);
		} else if (axios.isAxiosError(error)) {
			console.error("Axios error:", error.response?.data || "No response data");
		} else {
			console.error("An unknown error occurred");
		}
	}
};
