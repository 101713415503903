import { create } from 'zustand';

export interface ICCardUpdateListItem {
    signature: string; // 簽章
    medical_record_uid: string; // 病歷號 uid
    medical_record_number: string; // 病歷號
    treated_at: string; // 看診日期
    name: string; // 姓名
    id_no: string; // 身分證字號
    birth_date: string; // 出生日期
    mt_type: string; // 就醫類別
    card_no: string | null; // 健保卡號
    upload_status: number; // 上傳狀態
    opd_uid: string; // 門診 uid

    ready: boolean; // 是否準備上傳
}

interface ICCardUpdateListState {
    icCardUpdateList: ICCardUpdateListItem[];
    updateICCardUpdateList: (list: ICCardUpdateListItem[]) => void;
    addICCardUpdateItem: (item: ICCardUpdateListItem) => void;
    removeICCardUpdateItem: (medical_record_uid: string) => void;
    clearICCardUpdateList: () => void;
}

export const useICCardUpdateListStore = create<ICCardUpdateListState>(set => ({
    icCardUpdateList: [],
    updateICCardUpdateList: list => set({ icCardUpdateList: list }),
    addICCardUpdateItem: item =>
        set(state => ({
            icCardUpdateList: [...state.icCardUpdateList, item],
        })),
    removeICCardUpdateItem: medical_record_uid =>
        set(state => ({
            icCardUpdateList: state.icCardUpdateList.filter(
                item => item.medical_record_uid !== medical_record_uid
            ),
        })),
    clearICCardUpdateList: () => set({ icCardUpdateList: [] }),
}));
