import * as HIconsSolid from '@heroicons/react/24/solid';
import * as HIcons from '@heroicons/react/24/outline';

import AddIcon from './AddIcon';
import PaletteIcon from './PaletteIcon';
import AsideArrowIcon from './AsideArrowIcon';
import ClinicImage from '../../images/icon/clinicIcon.png';
import ConsultingRoomMedicalHistoryIcon from './ConsultingRoomMedicalHistoryIcon';
import ConsultingRoomControlledMedicationQueryIcon from './ConsultingRoomControlledMedicationQueryIcon';

import ConsultingRoomImportantNoteIcon from './ConsultingRoomImportantNoteIcon';

import ConsultingRoomMedicalTestReportIcon from './ConsultingRoomMedicalTestReportIcon';
import ConsultingRoomCloudMedicationHistoryIcon from './ConsultingRoomCloudMedicationHistoryIcon';
import ConsultingRoomKeyIcon from './ConsultingRoomKeyIcon';
import ConsultingRoomIdCardIcon from './ConsultingRoomIdCardIcon';
import ConsultingRoomVideoTreatmentIcon from './ConsultingRoomVideoTreatmentIcon';

import NavRegistrationIcon from './NavRegistrationIcon';
import NavConsultingRoomIcon from './NavConsultingRoomIcon';
import NavICuploadIcon from './NavICuploadIcon';
import NavDataSettingIcon from './NavDataSettingIcon';
import NavReportIcon from './NavReportIcon';
import NavManagementIcon from './NavManagementIcon';
import UploadListIcon from './UploadListIcon';
import ReadRecordIcon from './ReadRecordIcon';

interface HeroIconsProps {
    name: string;
}

export const HeroIcons = ({ name }: HeroIconsProps) => {
    const unicodeDictionary = {
        url: 'https://www.htmlsymbols.xyz/braille-patterns',
        BraillePatterns123456: '&#x283F;',
    };

    switch (name) {
        case 'edit':
            return <HIcons.PencilSquareIcon className="w-6 " />;
        case 'edit-fill':
            return <HIconsSolid.PencilSquareIcon className="w-6 " />;

        case 'add':
            return <HIcons.PlusCircleIcon className="w-6 " />;
        case 'add-fill':
            return <HIconsSolid.PlusCircleIcon className="w-6 " />;

        case 'delete':
            return <HIcons.TrashIcon className="w-6 " />;
        case 'delete-fill':
            return <HIconsSolid.TrashIcon className="w-6 " />;

        case 'check':
            return <HIcons.CheckIcon className="w-6 " />;
        case 'check-fill':
            return <HIconsSolid.CheckIcon className="w-6 " />;

        case 'check-circle':
            return <HIcons.CheckCircleIcon className="w-6 " />;
        case 'check-circle-fill':
            return <HIconsSolid.CheckCircleIcon className="w-6 " />;

        case 'clock':
            return <HIcons.ClockIcon className="w-6 " />;
        case 'clock-fill':
            return <HIconsSolid.ClockIcon className="w-6 " />;

        case 'user':
            return <HIcons.UserIcon className="w-6 " />;
        case 'user-fill':
            return <HIconsSolid.UserIcon className="w-6 " />;

        case 'search':
            return <HIcons.MagnifyingGlassIcon className="w-6 " />;
        case 'search-fill':
            return <HIconsSolid.MagnifyingGlassIcon className="w-6 " />;

        case 'notice':
            return <HIcons.ExclamationCircleIcon className="w-6 " />;
        case 'notice-fill':
            return <HIconsSolid.ExclamationCircleIcon className="w-6 " />;

        case 'return':
            return <HIcons.ArrowUturnLeftIcon className="w-6 " />;
        case 'return-w':
            return <HIcons.ArrowUturnLeftIcon className="w-6 text-[#fff]" />;

        case 'bars-arrow-up':
            return <HIcons.BarsArrowUpIcon className="w-6 " />;
        case 'bars-arrow-down':
            return <HIcons.BarsArrowDownIcon className="w-6 " />;

        case 'ellipsis':
            return <HIcons.EllipsisVerticalIcon className="w-6 " />;
        case 'ellipsis-fill':
            return <HIconsSolid.EllipsisVerticalIcon className="w-6 " />;
        case 'ellipsis-horizontal':
            return <HIcons.EllipsisHorizontalIcon className="w-4 " />;
        case 'ellipsis-horizontal-fill':
            return <HIconsSolid.EllipsisHorizontalIcon className="w-4 " />;

        case 'openWindow':
            return <HIcons.ArrowTopRightOnSquareIcon className="w-6 " />;
        case 'openWindow-fill':
            return <HIconsSolid.ArrowTopRightOnSquareIcon className="w-6 " />;

        case 'recycle-sm':
            return <HIcons.ArrowPathIcon className="w-4 " />;
        case 'recycle':
            return <HIcons.ArrowPathIcon className="w-6 " />;

        case 'qrcode':
            return <HIcons.QrCodeIcon className="w-6 " />;
        case 'qrcode-fill':
            return <HIconsSolid.QrCodeIcon className="w-6 " />;

        case 'xmark-xs':
            return <HIcons.XMarkIcon className="w-4 " />;

        case 'xmark-sm':
            return <HIcons.XMarkIcon className="w-6 " />;
        case 'xmark':
            return <HIcons.XMarkIcon className="w-8 " />;

        case 'xmark-circle':
            return <HIcons.XCircleIcon className="w-6 " />;
        case 'xmark-circle-fill':
            return <HIconsSolid.XCircleIcon className="w-6 " />;

        case 'bars2':
            return <HIcons.Bars2Icon className="w-6 " />;

        case 'plus-circle':
            return <HIcons.PlusCircleIcon className="w-6 " />;
        case 'plus-circle-fill':
            return <HIconsSolid.PlusCircleIcon className="w-6 " />;

        case 'arrow-right':
            return <HIcons.ArrowRightIcon className="w-6 " />;
        case 'arrow-right-fill':
            return <HIconsSolid.ArrowRightIcon className="w-6 " />;
        case 'arrow-left':
            return <HIcons.ArrowLeftIcon className="w-6 " />;
        case 'arrow-left-fill':
            return <HIconsSolid.ArrowLeftIcon className="w-6 " />;
        case 'arrow-up':
            return <HIcons.ArrowUpIcon className="w-6 " />;
        case 'arrow-up-fill':
            return <HIconsSolid.ArrowUpIcon className="w-6 " />;
        case 'arrow-down':
            return <HIcons.ArrowDownIcon className="w-6 " />;
        case 'arrow-down-fill':
            return <HIconsSolid.ArrowDownIcon className="w-6 " />;

        case 'arrow-up-down':
            return <HIcons.ArrowsUpDownIcon className="w-6 " />;

        case 'arrow-pointing-out':
            return <HIcons.ArrowsPointingOutIcon className="w-6" />;

        case 'arrow-pointing-in':
            return <HIcons.ArrowsPointingInIcon className="w-6" />;

        case 'cursor-arrow':
            return <HIcons.CursorArrowRaysIcon className="w-6 " />;
        case 'cursor-arrow-fill':
            return <HIconsSolid.CursorArrowRaysIcon className="w-6 " />;

        case 'cursor-arrow-ripple':
            return <HIcons.CursorArrowRippleIcon className="w-6 " />;
        case 'cursor-arrow-ripple-fill':
            return <HIconsSolid.CursorArrowRippleIcon className="w-6 " />;

        case 'user':
            return <HIcons.UserIcon className="w-6 " />;
        case 'user-fill':
            return <HIconsSolid.UserIcon className="w-6 " />;

        case 'users':
            return <HIcons.UsersIcon className="w-6 " />;
        case 'users-fill':
            return <HIconsSolid.UsersIcon className="w-6 " />;

        case 'user-group':
            return <HIcons.UserGroupIcon className="w-6 " />;
        case 'user-group-fill':
            return <HIconsSolid.UserGroupIcon className="w-6 " />;

        case 'user-plus':
            return <HIcons.UserPlusIcon className="w-6 " />;
        case 'user-plus-fill':
            return <HIconsSolid.UserPlusIcon className="w-6 " />;

        case 'user-circle':
            return <HIcons.UserCircleIcon className="w-6 " />;
        case 'user-circle-fill':
            return <HIconsSolid.UserCircleIcon className="w-6 " />;

        case 'user-circle':
            return <HIcons.UserCircleIcon className="w-6 " />;
        case 'user-circle-fill':
            return <HIconsSolid.UserCircleIcon className="w-6 " />;

        case 'calendar-days':
            return <HIcons.CalendarDaysIcon className="w-6 " />;
        case 'dollar':
            return <HIcons.CurrencyDollarIcon className="w-6 " />;
        case 'dollar-fill':
            return <HIconsSolid.CurrencyDollarIcon className="w-6 " />;

        case 'chevron-right-sm':
            return <HIcons.ChevronRightIcon className="w-4" />;
        case 'chevron-right':
            return <HIcons.ChevronRightIcon className="w-6" />;
        case 'chevron-left-sm':
            return <HIcons.ChevronLeftIcon className="w-4" />;
        case 'chevron-left':
            return <HIcons.ChevronLeftIcon className="w-6" />;
        case 'chevron-down-sm':
            return <HIcons.ChevronDownIcon className="w-4" />;
        case 'chevron-down':
            return <HIcons.ChevronDownIcon className="w-6" />;
        case 'chevron-up-sm':
            return <HIcons.ChevronUpIcon className="w-4" />;
        case 'chevron-up':
            return <HIcons.ChevronUpIcon className="w-6" />;

        case 'chevron-up-down':
            return <HIcons.ChevronUpDownIcon className="w-6" />;

        case 'identification-sm':
            return <HIcons.IdentificationIcon className="w-4" />;
        case 'identification':
            return <HIcons.IdentificationIcon className="w-6" />;

        case 'cake-sm':
            return <HIcons.CakeIcon className="w-4" />;
        case 'cake':
            return <HIcons.CakeIcon className="w-6" />;

        case 'clipboard-document-list':
            return <HIcons.ClipboardDocumentListIcon className="w-6" />;
        case 'clipboard':
            return <HIcons.ClipboardIcon className="w-6" />;

        case 'book-open':
            return <HIcons.BookOpenIcon className="w-6" />;

        case 'x-circle':
            return <HIcons.XCircleIcon className="w-6" />;

        case 'light-bulb':
            return <HIcons.LightBulbIcon className="w-6" />;

        case 'printer':
            return <HIcons.PrinterIcon className="w-6" />;

        case 'book-mark':
            return <HIcons.BookmarkIcon className="w-6" />;

        case 'phone-sm':
            return <HIcons.PhoneIcon className="w-4" />;
        case 'phone':
            return <HIcons.PhoneIcon className="w-6" />;

        case 'information-circle':
            return <HIcons.InformationCircleIcon className="w-6" />;
        case 'information-circle-sm':
            return <HIcons.InformationCircleIcon className="w-4" />;

        case 'computer-desktop':
            return <HIcons.ComputerDesktopIcon className="w-6" />;
        case 'computer-desktop-sm':
            return <HIcons.ComputerDesktopIcon className="w-4" />;

        case 'wrench':
            return <HIconsSolid.WrenchIcon className="w-5" />;

        case 'folder-arrow-down':
            return <HIcons.FolderArrowDownIcon className="w-6" />;

        case 'arrow-path':
            return <HIcons.ArrowPathIcon className="w-6" />;
        // DIY ------------------------------------------------------------------------------------
        case 'palette':
            return <PaletteIcon></PaletteIcon>;

        case 'asideArrow':
            return <AsideArrowIcon></AsideArrowIcon>;

        case 'clinic-class':
            return <img src={ClinicImage} alt="" />;

        case 'medical-history':
            return <ConsultingRoomMedicalHistoryIcon />;
        case 'controlled-medication-query':
            return <ConsultingRoomControlledMedicationQueryIcon />;
        case 'important-note':
            return <ConsultingRoomImportantNoteIcon />;
        case 'medical-test-report':
            return <ConsultingRoomMedicalTestReportIcon />;
        case 'cloud-medication-history':
            return <ConsultingRoomCloudMedicationHistoryIcon />;
        case 'video-treatment':
            return <ConsultingRoomVideoTreatmentIcon />;
        case 'key-circle-green':
            return <ConsultingRoomKeyIcon />;
        case 'id-card-circle-green':
            return <ConsultingRoomIdCardIcon />;

        case 'nav-consulting-room':
            return <NavConsultingRoomIcon />;

        case 'nav-registration-room':
            return <NavRegistrationIcon />;

        case 'nav-ic-upload-room':
            return <NavICuploadIcon />;

        case 'nav-management-room':
            return <NavManagementIcon />;

        case 'nav-data-setting-room':
            return <NavDataSettingIcon />;

        case 'nav-report-room':
            return <NavReportIcon />;

        case 'upload-list':
            return <UploadListIcon />;

        case 'read-record':
            return <ReadRecordIcon />;
        default:
            return null;
    }
};
