import React, { createContext, useContext, useState, ReactNode } from "react";

interface ShowDrawDetailContextValue {
	showDrawDetailStatus: boolean;
	setShowDrawDetailStatus: React.Dispatch<React.SetStateAction<boolean>>;
	editStatus: boolean;
	setEditStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShowDrawDetailContext = createContext<ShowDrawDetailContextValue | undefined>(undefined);

interface ShowDrawDetailStatusProps {
	children: ReactNode;
}

export const PersonnelProfileSettingStatusProvider: React.FC<ShowDrawDetailStatusProps> = ({ children }) => {
	const [showDrawDetailStatus, setShowDrawDetailStatus] = useState(false);
	const [editStatus, setEditStatus] = useState(false);

	return (
		<ShowDrawDetailContext.Provider
			value={{
				showDrawDetailStatus,
				setShowDrawDetailStatus,
				editStatus,
				setEditStatus
			}}>
			{children}
		</ShowDrawDetailContext.Provider>
	);
};

export const usePersonnelProfileDataSettingStatus = (): ShowDrawDetailContextValue => {
	const context = useContext(ShowDrawDetailContext);

	if (!context) {
		throw new Error("useShowDrawDetailStatus must be used within a ShowDrawDetailStatusProvider");
	}

	return context;
};
