import { create } from "zustand";

export interface InstitutionInfoProps {
	customer_uid: string; // 資料UUID
	hosp_id: string; // 客戶編號, 最大長度10
	nhid: string; // 醫事機構代碼, 最大長度10
	branch_uid: number; // 服務地區, 最大長度2
	hosp_name: string; // 院所名稱, 最大長度50
	institution_type: number; // 機構型態（1：診所、2：醫院、3：藥局）
	business_license: string; // 開業執照, 最大長度30
	uniform_numbers: string; // 統一編號, 最大長度8
	tel_area_code: string; // 電話區碼, 最大長度3
	tel_number: string; // 電話號碼, 最大長度8
	address: string; // 地址, 最大長度100
	zip: string; // 郵遞區號, 最大長度6
	responsible_doctor: string; // 負責醫師, 最大長度50
	doctor_idno: string; // 負責醫師身分證號, 最大長度10
	pharmacist: string; // 調劑藥師, 最大長度50
	pharmacist_idno: string; // 藥師身分證號, 最大長度10
	prescription_style: number; // 調劑方式（1：自行調劑、2：交付調劑     //0：自調、1：交付、2：藥師調劑）
	deliver_exam_type: string; // 檢驗申報方式  // 1: 交檢  2: 自行  3: 委自  4: 委交
	deliver_exam_lab: string; // 檢驗所醫事機構代碼
	lab_name: string; // 檢驗所名稱
	ot_admin_account: string; // 帳號, 最大長度20
	ot_admin_password: string; // 密碼, 最大長度20
}

const initialInstitutionInfo: InstitutionInfoProps = {
	customer_uid: "",
	hosp_id: "",
	nhid: "",
	branch_uid: 0,
	hosp_name: "",
	institution_type: 0,
	business_license: "",
	uniform_numbers: "",
	tel_area_code: "",
	tel_number: "",
	address: "",
	zip: "",
	responsible_doctor: "",
	doctor_idno: "",
	pharmacist: "",
	pharmacist_idno: "",
	prescription_style: 0,
	deliver_exam_type: "",
	deliver_exam_lab: "",
	lab_name: "",
	ot_admin_account: "",
	ot_admin_password: ""
};

interface InstitutionInfoState {
	institutionInfoData: InstitutionInfoProps;
	updateInstitutionInfoData: (newData: Partial<InstitutionInfoProps>) => void;
	resetInstitutionInfoData: () => void;
}

export const useInstitutionInfoStore = create<InstitutionInfoState>(set => ({
	institutionInfoData: initialInstitutionInfo,
	updateInstitutionInfoData: newData =>
		set(state => ({
			institutionInfoData: { ...state.institutionInfoData, ...newData }
		})),
	resetInstitutionInfoData: () =>
		set(() => ({
			institutionInfoData: initialInstitutionInfo
		}))
}));
