

import { useQuery } from "react-query";
import { callVideoOpdListService } from "service/opdListsService";
import Swal from "sweetalert2";
import { useUserStore } from "store";
import { useWaitingTableTabIndex } from "contexts";
export const useVideoWaitingListQuery = () => {
    // const [opdLists, setOpdList] = useState<OpdListsProps>({});
    const {userInfo} = useUserStore()
    const { waitingTableTabIndex } = useWaitingTableTabIndex(); // 從 context 中獲取 waitingTabIndex
    const query = useQuery('opdVideoList', () => callVideoOpdListService(userInfo.token!), {
        onSuccess: (result) => {
            // console.log(result, '整排的lists');
        },
        onError: (error) => {
            Swal.fire({
                icon: 'error',
                title: '後端服務錯誤',
                timer: 1500,
                showConfirmButton: false,
            });
        },
        enabled: !!userInfo.token && waitingTableTabIndex === 2,
        staleTime: Infinity,
        refetchInterval: 30000,
    });

    return {
        ...query,
        opdLists: query.data?.lists ?? {},
    };
};
