import React, { useEffect, useState } from "react";

import { Button, Box, Flex, Text, Heading } from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import moment from "moment";
import { DxProps, useTreatmentInfoStore } from "store/TreatmentManagement";
import { TreatmentInfoProps } from "store/TreatmentManagement/useTreatmentInfoStore";
import { ControlledDrugSearch } from "./RoomInformationComponents/ControlledDrugSearch";
import { callOpdBaseInfoService, DrugProps } from "service/opdBaseInfoService";
import { callOpdRecordService } from "service/opdRecordService";
import { useUserStore } from "store";
import { useCCPEStore, useDxStore, usePrescriptionStore, PrescriptionProps } from "store/TreatmentManagement";
import { mainNavItemButton } from "style/chakraStyles";
import { useIsCopyDrugFromHistoryStatus } from "contexts/TreatmentManagement/IsCopyDrugFromHistoryContext";
import { useInstitutionInfoStore } from "store/DataSetting/useInstitutionInfoStore";
import { callCustomerService } from "service/customerService";
import { useOriginPrescriptionStore } from "store/TreatmentManagement/useOriginPrescriptionStore";

const Header: React.FC = () => {
	const { userInfo } = useUserStore();
	const { setIsCopyDrugFromHistoryStatus } = useIsCopyDrugFromHistoryStatus();
	const { setOriginPrescriptionList } = useOriginPrescriptionStore();

	const { treatmentInfo, updateTreatmentInfo } = useTreatmentInfoStore();
	const { updateCCPEData } = useCCPEStore();
	const { dxList, updateDxList } = useDxStore();
	const { institutionInfoData, updateInstitutionInfoData } = useInstitutionInfoStore();
	const { prescriptionList, updatePrescription, setPrescriptionList, appendPrescription, updateInputValues } = usePrescriptionStore();
	const [hosp_name, setHosp_name] = useState<string>("");
	const [hosp_id, setHosp_id] = useState<string>("");
	useEffect(() => {
		const hosp_info = localStorage.getItem("hosp_info");
		if (hosp_info) {
			const parsedHospInfo = JSON.parse(hosp_info);
			setHosp_name(parsedHospInfo.hosp_name);
			setHosp_id(parsedHospInfo.hosp_id);
		} else {
		}
	}, []);

	const [currentRocDate, setCurrentRocDate] = useState("000 年 0月 0日");
	const [currentWeek, setCurrentWeek] = useState("星期");

	const [institutionInfo, setInstitutionInfo] = useState<any>(null);

	const callCustomerInfoAPI = async () => {
		const result = await callCustomerService(userInfo.token!);
		updateInstitutionInfoData(result.info);
		setInstitutionInfo(result.info);
	};

	useEffect(() => {
		callCustomerInfoAPI();
	}, []);
	useEffect(() => {
		const interval = setInterval(() => {
			const now = new Date();
			const time = moment(now).format("h:mm:ss");
			const ampm = moment(now).format("a");
			const year = moment(now).format("YYYY");
			const month = moment(now).format("M");
			const day = moment(now).format("D");
			const weekday = moment(now).format("dddd");

			const RocYear = parseInt(year) - 1911;
			const RocDate = `${RocYear} 年 ${month} 月 ${day} 日  `;

			setCurrentRocDate(RocDate);
			setCurrentWeek(weekday);
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	const callOpdBaseInfoAPI = async () => {
		if (treatmentInfo?.id && institutionInfo) {
			try {
				const result = await callOpdBaseInfoService(userInfo.token!, treatmentInfo.id);
				if (result?.result === "success") {
					console.log("opdBaseInfo result: ", result);
					let birthDate = result.info.birth_date ? new Date(result.info.birth_date) : null;
					let opdDate = result.info.opd_at ? new Date(result.info.opd_at) : null;
					updateTreatmentInfo({
						...result.info,
						birth_date: result.info.birth_date ? new Date(result.info.birth_date) : null,
						opd_at: result.info.opd_at ? new Date(result.info.opd_at) : null,
						vital_sign: result.vital_sign,
						case_type:
							result.medical_record?.info?.case_type ||
							treatmentInfo?.case_type ||
							(institutionInfoData.prescription_style === 1 ? "09" : "01"),
						card_no: result.info?.card_no || ""
					});
				}
				if (result.medical_record && Object.keys(result.medical_record).length > 0) {
					updateTreatmentInfo({
						data_id: result.medical_record?.info.data_id,
						treated_at: result.medical_record?.info.treated_at ? new Date(result.medical_record.info.treated_at) : null,
						card_no: result.info.card_no,
						medical_record: {
							info: result.medical_record.info,
							mb1: result.medical_record.mb1
						},
						case_type: result.medical_record.info.case_type,
						special_treatment: result.medical_record?.refillable.specific_treatment || "",
						refillable: {
							refill_times: Number(result.medical_record?.refillable.refill_times) || null,
							seq: null, // 領藥順序
							ori_medical_record_uid: "" // 原處方
						}
					});
					updateCCPEData({
						cc: result.medical_record.info.cc ?? ""
					});

					const newDxList: DxProps[] = result.medical_record.diagnosis.map(dx => ({
						data_id: dx.icd_10_in_use_uid ?? "",
						short_code: dx.short_code ?? "",
						icd_code: dx.icd_code ?? "",
						code: dx.icd_code ?? "",
						name_en: dx.name_en ?? "",
						name_tw: dx.name_tw ?? "",
						original_name: "",
						attach_ccpe: "",
						if_chronic: dx.if_chronic ?? ""
					}));

					// 確保總是有六個診斷，如果不足則用空物件填充
					const filledDxList = [
						...newDxList,
						...Array(Math.max(0, 6 - newDxList.length)).fill({
							data_id: "",
							short_code: "",
							icd_code: "",
							code: "",
							name_en: "",
							name_tw: "",
							original_name: "",
							attach_ccpe: ""
						})
					].slice(0, 6);

					updateDxList(filledDxList);
					// 將藥品資料轉換為處方列表格式

					const newPrescriptions: PrescriptionProps[] = [];

					for (const drug of result.medical_record.drugs) {
						const prescription: PrescriptionProps = {
							controlled_level: drug.controlled_level || "",
							cost: drug.cost || "",
							data_id: drug.drugs_in_use_uid || "",
							disabled_date: drug.disabled_date || "",
							dosage_form: drug.dosage_form || "",
							dose: drug.dose || "",
							dose_day: drug.dose_days || "",
							dosing_days: drug.dosing_days || "",
							drug_no: drug.drug_no || "",
							drug_price: drug.drug_price || null,
							drug_type: drug.drug_type || "",
							freq: drug.freq || "",
							max_dose: drug.max_dose || "",
							max_dose_day: drug.max_dose_day || "",
							name_en: drug.name_en || "",
							name_tw: drug.name_tw || "",
							route_of_administration: drug.route_of_administration || "",
							self_price: drug.self_price || "",
							short_code: drug.short_code || "",
							taking_time: drug.taking_time || "",
							unit: drug.unit || "",
							qty: drug.qty || "",
							qty_in_day: drug.qty_in_day || "",
							total_qty: drug.total_qty || "",
							given_qty: drug.given_qty || "",
							memo: drug.memo || "",
							site: drug.site || "",
							special_mark: Array.isArray(drug.special_mark) ? drug.special_mark.join(",") : drug.special_mark || "",
							freq_custom_code: drug.freq_custom_code || "",
							freq_directions: drug.freq_directions || "",
							if_chronic: drug.if_chronic?.toString() || null,
							hasAskedForChronicCase: true
						};

						newPrescriptions.push(prescription);
					}

					// 更新處方列表
					const updatedPrescriptionList = [...prescriptionList];
					newPrescriptions.forEach((prescription, index) => {
						if (index < updatedPrescriptionList.length) {
							updatedPrescriptionList[index] = prescription;
						} else {
							updatedPrescriptionList.push(prescription);
						}
					});

					// 更新處方列表狀態
					setPrescriptionList(updatedPrescriptionList);
					setOriginPrescriptionList(updatedPrescriptionList);

					// 更新輸入值
					const newInputValues = updatedPrescriptionList.map(prescription => ({
						columns: [
							prescription.drug_no || "",
							prescription.name_tw || "",
							prescription.dose || "",
							prescription.unit || "",
							prescription.freq || "",
							prescription.route_of_administration || "",
							prescription.dosing_days || "",
							prescription.total_qty || "",
							prescription.memo || "",
							prescription.special_mark || ""
						]
					}));
					updateInputValues(newInputValues);
					setIsCopyDrugFromHistoryStatus(true);
				}
			} catch (error) {
				console.error("Error calling API:", error);
			}
		}
	};

	useEffect(() => {
		callOpdBaseInfoAPI();
	}, [institutionInfo]);

	const [medCloudWindow, setMedCloudWindow] = useState<Window | null>(null);

	const handleOpenMedCloudWindow = (e: React.MouseEvent<HTMLButtonElement>) => {
		const url = "https://medcloud.nhi.gov.tw/imme0008/IMME0008S01.aspx";
		const windowFeatures = "width=980,height=567,top=0,left=0";

		if (medCloudWindow && !medCloudWindow.closed) {
			medCloudWindow.focus();
		} else {
			const newWindow = window.open(url, "_blank", windowFeatures);
			if (newWindow) {
				setMedCloudWindow(newWindow);
				newWindow.focus();
			}
		}
	};

	const handleVideoTreatment = () => {
		console.log("開啟視訊診療");
	};

	return (
		<Box pos={"fixed"} top={0} left={0} zIndex={10} w={"100%"} bg={"rsPrimary.900"} px={{ base: "20px", lg: "40px" }}>
			<Flex justify={"space-between"} align={"center"} flexWrap={"wrap"}>
				{hosp_name && hosp_id && (
					<Heading textColor={"#fff"} size={"sm"} py={"10px"} fontSize={"18px"} lineHeight={"20px"} fontWeight={"normal"}>
						<Text as={"span"}>{hosp_name}</Text>
						<Text as={"small"} mx={"12px"}>
							{hosp_id}
						</Text>
					</Heading>
				)}

				<Flex gap={"16px"} align={"center"}>
					<Flex overflow={"hidden"} rounded={"4px"} h={"32px"}>
						<Button variant='ghost' size={"sm"} sx={mainNavItemButton} boxShadow={"inset 4px 2px 2px #00000099"}>
							<HeroIcons name='medical-history'></HeroIcons>
							<Box as='span' ml={"4px"}>
								歷史病歷
							</Box>
						</Button>
						<Button variant='ghost' size={"sm"} sx={mainNavItemButton} boxShadow={"inset 0px 2px 2px #00000099"}>
							<HeroIcons name='medical-test-report'></HeroIcons>
							<Box as='span' ml={"4px"}>
								檢驗報告
							</Box>
						</Button>
						<Button variant='ghost' size={"sm"} sx={mainNavItemButton} boxShadow={"inset 0px 2px 2px #00000099"}>
							<HeroIcons name='important-note'></HeroIcons>
							<Box as='span' ml={"4px"}>
								重要記載
							</Box>
						</Button>

						<Button
							variant='ghost'
							size={"sm"}
							sx={mainNavItemButton}
							boxShadow={"inset 0px 2px 2px #00000099"}
							onClick={handleVideoTreatment}>
							<HeroIcons name='video-treatment'></HeroIcons>
							<Box as='span' ml={"4px"}>
								視訊診療
							</Box>
						</Button>

						<Button
							variant='ghost'
							size={"sm"}
							sx={mainNavItemButton}
							boxShadow={"inset 0px 2px 2px #00000099"}
							onClick={handleOpenMedCloudWindow}>
							<HeroIcons name='cloud-medication-history'></HeroIcons>
							<Box as='span' ml={"4px"}>
								雲端藥歷
							</Box>
						</Button>
						<ControlledDrugSearch />
					</Flex>
					<Text textColor={"#fff"}>
						{currentRocDate} {currentWeek}
					</Text>
					<Flex align={"center"} textColor={"#fff"} gap={"8px"}>
						<Box w={"24px"} h={"24px"}>
							<HeroIcons name='clinic-class'></HeroIcons>
						</Box>
						<Text>
							{treatmentInfo.subject_code}
							{treatmentInfo.subject_name}
						</Text>
					</Flex>
					<Flex align={"center"} textColor={"#fff"} gap={"8px"}>
						<HeroIcons name='user-circle'></HeroIcons>
						<Text>{treatmentInfo.doctor_name}</Text>
					</Flex>
				</Flex>
			</Flex>
		</Box>
	);
};

export default Header;
