import { useState } from "react";

import { Box, Flex, Text, Button, Center, Checkbox } from "@chakra-ui/react";

import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import { InsetShadowBox } from "style/chakraStyles";

import { useIsICWriteInOpenStatus } from "contexts/ICUpload/IsICWriteInOpen";

import { PatientInfo } from "./PatientInfo";
import { DxInfo } from "./DxInfo";
import { DoctorInfo } from "./DoctorInfo";
import { PrescriptionsInfo } from "./PrescriptionsInfo";
import { Submit } from "./Submit";

export const ICWriteIn = () => {
	const { isICWriteInOpenStatus, setIsICWriteInOpenStatus } = useIsICWriteInOpenStatus();

	const handleICWriteInSwitch = () => {
		setIsICWriteInOpenStatus(prev => !prev);
	};
	return (
		<>
			<Box pos={"fixed"} zIndex={50} inset={0} bg={"rgba(0,0,0,0.3)"}>
				<Box
					pos={"relative"}
					maxW={"1080px"}
					w={"calc( 100% - 32px)"}
					h={"calc(100% - 100px)"}
					top={"50%"}
					left={"50%"}
					transform={"translate(-50%,-50%)"}
					bg={"#ECECEC"}
					rounded={"lg"}
					border={"2px solid #646B48"}
					boxShadow={"4px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;"}>
					<Flex p={"8px 16px "} justify={"space-between"} align={"center"}>
						<Text textStyle={"semiTitle"}>IC卡寫入</Text>
						<Box opacity={0.8} _hover={{ opacity: 1, cursor: "pointer" }} onClick={handleICWriteInSwitch}>
							<HeroIcons name='xmark-sm' />
						</Box>
					</Flex>
					<Box p={"8px 16px"}>
						<Flex gap={"8px"}>
							<Box flex={"0 0 300px"} sx={InsetShadowBox} minH={"168px"}>
								<PatientInfo />
							</Box>
							<Box flex={"1 1 auto"} sx={InsetShadowBox} minH={"168px"}>
								<DxInfo />
							</Box>
						</Flex>
						<Flex my={"8px"} gap={"8px"} align={"center"} sx={InsetShadowBox}>
							<DoctorInfo />
						</Flex>

						<Box my={"8px"} sx={InsetShadowBox}>
							<PrescriptionsInfo />
						</Box>
						<Box my={"8px"}>
							<Submit />
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};
