import Swal from "sweetalert2";
import { ICUpload2Service } from "./ICUpload2Service";

export const ICUpload2API = async (ic_upload2_data: any) => {
	console.log("呼叫代理人 API");
	const response = await ICUpload2Service(ic_upload2_data);
	if (!response) {
		Swal.fire({
			title: "上傳 IC 資料異常",
			text: "請檢查代理人是否正常啟用",
			icon: "error",
			confirmButtonText: "確定"
		});
		return false;
	} else {
		const responseObj = JSON.parse(response);
		console.log("代理人 API 回傳:");
		console.log("RtnCode:", responseObj.RtnCode);
		console.log("Opcode:", responseObj.Opcode);
		if (responseObj.RtnCode === "0000") {
			return true;
		} else {
			const errorMessages: { [key: string]: string } = {
				"5002": "下載檔案尚未就緒，請稍後再試",
				"5003": "作業類別錯誤",
				"5004": "安全模組卡無效或與上傳時不符",
				"8201": "服務異常，請稍後再試",
				"8202": "服務異常，請稍後再試",
				"8203": "簽章已逾有效期限，請重新取簽章",
				"8205": "安全模組卡已註銷，請聯繫所屬分區業務組",
				"9002": "驗簽失敗，未完成驗證作業",
				"9005": "醫療院所代號不存在"
			};

			const errorMessage = errorMessages[responseObj.RtnCode] || responseObj.ERRORCODE;
			const icon = ["5002", "8203"].includes(responseObj.RtnCode) ? "warning" : "error";

			Swal.fire({
				title: "上傳 IC 資料失敗",
				text: errorMessage,
				icon: icon,
				confirmButtonText: "確定"
			});
			return false;
		}
	}
};
