import React, { useState, useEffect } from "react";
import { Button, Text, Flex, Box, Stack } from "@chakra-ui/react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	useToast
} from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";

import Swal from "sweetalert2";
import { useTreatmentInfoStore } from "store/TreatmentManagement";
import { usePrescriptionStore, PrescriptionProps } from "store/TreatmentManagement";
import { LeftContent } from "./LeftContent";
import { RightContent } from "./RightContent";
import { useIsCopyDrugFromHistoryForChronicStatus } from "contexts/TreatmentManagement/IsCopyDrugFromHistoryForChronicContext";

export type ChronicPrescriptionItem = {
	data_id: string;
	if_chronic: string;
	name_tw: string;
	qty_in_day: string;
	qty: string;
	dose_day: string;
	freq_custom_code: string;
	total_qty: string;
	given_qty: string;
	special_mark: string;
};

export const ChronicSetting = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const { treatmentInfo, updateTreatmentInfo } = useTreatmentInfoStore();
	const { prescriptionList, setPrescriptionList } = usePrescriptionStore();
	const [selectedTimes, setSelectedTimes] = useState<number | null>(null);
	const [selectedReasons, setSelectedReasons] = useState<string>("");
	const [chronicPrescriptionList, setChronicPrescriptionList] = useState<ChronicPrescriptionItem[]>([]);
	const { isCopyDrugFromHistoryForChronicStatus, setIsCopyDrugFromHistoryForChronicStatus } = useIsCopyDrugFromHistoryForChronicStatus();

	const handleSave = () => {
		if (selectedTimes === null) {
			toast({
				title: "請選擇可調劑次數",
				status: "error",
				duration: 2000,
				isClosable: true
			});
			return;
		}

		if (chronicPrescriptionList.every(item => !item.if_chronic)) {
			toast({
				title: "請至少選擇一項慢性處方",
				status: "warning",
				duration: 2000,
				isClosable: true
			});
			return;
		}

		setPrescriptionList(
			prescriptionList.map(prescription => {
				// 找到對應的慢性處方項目
				const chronicItem = chronicPrescriptionList.find(item => item.data_id === prescription.data_id);

				// 更新 if_chronic 屬性
				return {
					...prescription,
					if_chronic: chronicItem?.if_chronic || null
				};
			})
		);

		updateTreatmentInfo({
			special_treatment: selectedReasons,
			refillable: {
				refill_times: selectedTimes,
				seq: 1
			}
		});

		Swal.fire({
			icon: "success",
			title: "慢性處方簽已設立",
			timer: 1500,
			showConfirmButton: false
		});
		onClose();
	};

	useEffect(() => {
		setSelectedReasons(treatmentInfo.special_treatment);
		setSelectedTimes(treatmentInfo.refillable.refill_times);
	}, [isOpen]);

	const handleDelete = () => {
		Swal.fire({
			icon: "warning",
			title: "確定要作廢此連續處方簽嗎？",
			showCancelButton: true,
			confirmButtonText: "確定",
			cancelButtonText: "取消",
			customClass: {
				popup: "swal-popup" // 添加自定義類
			}
		}).then(result => {
			if (result.isConfirmed) {
				updateTreatmentInfo({
					special_treatment: "",
					refillable: { refill_times: null, seq: 1 }
				});
				setPrescriptionList(
					prescriptionList.map(prescription => ({
						...prescription,
						if_chronic: null
					}))
				);
				onClose();
			}
		});
	};

	useEffect(() => {
		// 檢查符合慢性處方條件的處方
		const longTermPrescriptions = prescriptionList.filter(prescription => {
			const daysOfMedication = Number(prescription.dose_day || "0") || 0;
			const controllerLevel = Number(prescription.controlled_level || "0");
			// const hasAskedForChronicCase = prescription.hasAskedForChronicCase;
			console.log(daysOfMedication, controllerLevel, "救命");

			return daysOfMedication >= 14 && controllerLevel !== 1 && controllerLevel !== 2;
		});
		console.log(longTermPrescriptions, "救命符合的有幾個陣列");
		console.log(chronicPrescriptionList, "救命已選擇的慢性處方");
		console.log(treatmentInfo.refillable.refill_times, "救命");
		// 只有在有慢性處方設定時才進行檢查
		if (treatmentInfo.refillable.refill_times !== null && longTermPrescriptions.length !== chronicPrescriptionList.length) {
			// 檢查是否所有應該是慢性處方的項目都有被設定
			// const hasInvalidChronicPrescriptions = chronicPrescriptionList.some(chronicItem =>
			//     !longTermPrescriptions.find(prescription => prescription.data_id === chronicItem.data_id && Number(prescription.dose_day) ！== Number(chronicItem.dose_day))
			// );

			// if (hasInvalidChronicPrescriptions) {
			updateTreatmentInfo({
				special_treatment: "",
				refillable: { refill_times: null, seq: 1 }
			});
			setPrescriptionList(
				prescriptionList.map(prescription => ({
					...prescription,
					if_chronic: null
				}))
			);
			// }
		}
	}, [prescriptionList]);

	useEffect(() => {
		if (isCopyDrugFromHistoryForChronicStatus) {
			console.log("要清");
			updateTreatmentInfo({
				special_treatment: "",
				refillable: { refill_times: null, seq: 1 }
			});
			setPrescriptionList(
				prescriptionList.map(prescription => ({
					...prescription,
					if_chronic: null
				}))
			);
			setIsCopyDrugFromHistoryForChronicStatus(false);
		}
	}, [isCopyDrugFromHistoryForChronicStatus]);

	return (
		<Box position={"relative"}>
			<Button
				colorScheme={treatmentInfo.refillable.refill_times !== null ? "rsDanger" : "rsPrimary"}
				textColor={"#fff"}
				boxShadow={"2px -2px 2px 0px rgba(0, 0, 0, 0.50) inset;"}
				onClick={onOpen}>
				{treatmentInfo.refillable.refill_times !== null ? `連續 ${treatmentInfo.refillable.refill_times}` : "慢性"}
			</Button>

			{treatmentInfo.refillable.refill_times !== null && (
				<Box
					position={"absolute"}
					top={"-10px"}
					right={"-16px"}
					cursor={"pointer"}
					onClick={handleDelete}
					borderRadius={"50%"}
					bg={"#fff"}
					p={"4px"}
					textColor={"rsDanger.500"}
					border={"2px solid"}
					borderColor={"rsDanger.500"}
					transform={"scale(0.6)"}
					_hover={{
						bg: "rsDanger.600",
						textColor: "#fff"
					}}>
					<HeroIcons name={"xmark-sm"} />
				</Box>
			)}

			<Modal isOpen={isOpen} onClose={onClose} size={"full"} closeOnOverlayClick={false} isCentered motionPreset={"slideInTop"}>
				<ModalOverlay backdropFilter='blur(3px) ' />
				<ModalContent maxW={`calc(100vw - 40px)`} minH={`calc(100vh - 40px)`}>
					<ModalHeader>
						<Text textStyle={"semiTitle"}>開立慢性連續處方簽</Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Flex gap={"12px"}>
							<LeftContent
								selectedTimes={selectedTimes}
								setSelectedTimes={setSelectedTimes}
								selectedReasons={selectedReasons}
								setSelectedReasons={setSelectedReasons}
							/>
							<RightContent
								isModalOpen={isOpen}
								chronicPrescriptionList={chronicPrescriptionList}
								setChronicPrescriptionList={setChronicPrescriptionList}
							/>
						</Flex>
					</ModalBody>

					<ModalFooter>
						<Stack direction={"row"} spacing={"4px"}>
							{treatmentInfo.refillable.refill_times !== null && (
								<Button colorScheme='rsDanger' onClick={handleDelete}>
									作廢
								</Button>
							)}
							<Button variant={"ghost"} onClick={onClose}>
								取消
							</Button>
							<Button colorScheme='rsPrimary' onClick={handleSave}>
								確認
							</Button>
						</Stack>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Box>
	);
};
