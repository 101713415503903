import apiService from "./apiService";

// 70. 取得院內用藥頻率設定清單
export const callDrugFreqListsService = async (token: string) => {
	return apiService<string, any>({
		method: "GET",
		path: "/api/drug/freq/lists",
		token
	});
};
