import React, { useEffect, useState, useRef } from "react";
import { Box, Flex, Center, Text, Button } from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import Clock from "components/CustomComponents/Clock";
// Context
import { useIsBillingOpenStatus } from "contexts/TreatmentManagement/BillingProcess";

// Components
import { PrescriptionsList } from "./PrescriptionsList";
import { TestItemsList } from "./TestItemsList";
import { PatientInfo } from "./PatientInfo";
import { CashDetail } from "./CashDetail";
import { ReportingMember } from "./ReportingMember";
import { CasesRatio } from "./CasesRatio";
import { DoctorOrders } from "./DoctorOrders";
import { BillingDetail } from "./BillingDetail";
import { DxHistory } from "./DxHistory";
import { Category } from "./Category";
import { Options } from "./Options";
import { CompleteOptions } from "./CompleteOptions";
import { useVirtualCardState } from "contexts/VirtualCardContext";
import { usePrescriptionStore } from "store/TreatmentManagement";
import { useUserStore } from "store";
import { useDropDownMenuQuery } from "query/useDropDownMenuQuery";

import { useFeeStore } from "store/TreatmentManagement/Payment";
import { useTreatmentInfoStore } from "store/TreatmentManagement";
import { useDxStore } from "store/TreatmentManagement/useDxStore";
import { drugTypeListProps } from "service/drugTypeListService";
import { callCustomerService, customerProps } from "service/customerService";
// import { VirtualCardAuthorizeComponents } from 'components/VirtualCardAuthorizeComponents';
import { VirtualCardAuthorizeComponents } from "components/VirtualCardAuthorizeComponents";
import { useCalculationCompleteStatus } from "contexts/TreatmentManagement/CalculationCompleteContext";

import { useShowAbnormalButtonStatus } from "contexts/TreatmentManagement/BillingProcess/ShowAbnormalButtonStatusContext";

// myself
// import { CaseClassificationLogic } from 'components/TreatmentManagement/PaymentComponents/CaseClassificationLogic';
import { computeFeeAndClassifyCase } from "components/TreatmentManagement/PaymentComponents/computeFeeAndClassifyCase";
import { useComputeFeeStatus } from "contexts/TreatmentManagement/ComputeFeeContext";
import { AbnormalButton } from "./CompleteOptions/components/abnormalButton";
import Loading from "components/CustomComponents/Loading";
import { useLoadingStatus } from "contexts/LoadingStatusContext";

export const BillingProcess = () => {
	const { userInfo } = useUserStore();
	const { treatmentInfo, updateTreatmentInfo } = useTreatmentInfoStore();
	const { dxList } = useDxStore();
	const { prescriptionList } = usePrescriptionStore();
	const { feeData, setFeeData, resetFeeData, initialFeeState } = useFeeStore();
	const { loadingStatus, setLoadingStatus } = useLoadingStatus();
	const { computeFeeStatus, setComputeFeeStatus } = useComputeFeeStatus();
	const { setCalculationComplete } = useCalculationCompleteStatus();
	const { showAbnormalButtonStatus } = useShowAbnormalButtonStatus();
	const { showReadVHICStatus } = useVirtualCardState();

	const { setIsBillingOpenStatus } = useIsBillingOpenStatus();
	const [customData, setCustomData] = useState<customerProps>();
	const hasRunRef = useRef(false);
	const callCustomerAPI = async () => {
		if (userInfo) {
			try {
				const result = await callCustomerService(userInfo.token!);
				if (result.result === "success") {
					console.log(result, "裡面 院內基本資料");
					setCustomData(result.info);
				}
			} catch (error) {
				console.error("Error fetching customer service data:", error);
			}
		}
	};
	useEffect(() => {
		callCustomerAPI();
	}, []);
	// 取得藥類清單
	const [drugTypeList, setDrugTypeList] = useState<drugTypeListProps[]>([]);
	const { data: drugTypeListData, isSuccess: isDrugTypeListSuccess } = useDropDownMenuQuery("drugTypeList", userInfo.token!);

	useEffect(() => {
		if (isDrugTypeListSuccess) {
			setDrugTypeList(drugTypeListData.lists);
		}
	}, [isDrugTypeListSuccess]);

	useEffect(() => {
		if (drugTypeListData && drugTypeListData.lists.length > 0 && treatmentInfo && dxList && customData) {
			console.log("Recalculating fees and case classification");
			resetFeeData();
			const caseType = computeFeeAndClassifyCase(
				drugTypeListData.lists,
				treatmentInfo,
				prescriptionList,
				dxList,
				customData,
				initialFeeState,
				feeData,
				setFeeData,
				resetFeeData
			);
			// updateTreatmentInfo({
			//     case_type: caseType
			// })
			setCalculationComplete(true);
		}
	}, [drugTypeListData, treatmentInfo.case_type, treatmentInfo.prescription_style, prescriptionList, dxList, customData]);

	return (
		<>
			{loadingStatus && <Loading />}
			<Center zIndex={60} bg={"#000000aa"} pos={"fixed"} inset={0}>
				<Box
					pos={"absolute"}
					top={"8px"}
					left={"8px"}
					right={"8px"}
					w={"calc(100vw - 16px)"}
					minH={"calc(100vh - 16px)"}
					h={"auto"}
					bg={"#ECECEC"}
					rounded={"lg"}
					border={"2px solid #646B48"}>
					<Flex justify={"space-between"} align={"center"} p={"4px 16px"}>
						<Text textStyle={"semiTitle"}>批價作業</Text>
						<Flex align={"center"} gap={"8px"}>
							<Box textColor={"rsPrimary.500"} fontSize={"sm"}>
								<Clock />
							</Box>
							<Box
								_hover={{ cursor: "pointer" }}
								onClick={() => {
									setIsBillingOpenStatus(prev => !prev);
								}}>
								<HeroIcons name='xmark'></HeroIcons>
							</Box>
						</Flex>
					</Flex>

					<Flex px={"16px"} h={`calc(${window.innerHeight}px - 16px - 16px - 40px)`} gap={"8px"}>
						<Flex flex={"0 1 calc(66.66% - 4px)"} overflow-x={"hidden"} gap={"8px"} direction={"column"} overflowX={"hidden"}>
							<Center
								w={"100%"}
								flex={"0 0 250px"}
								bg={"#fff"}
								rounded={"lg"}
								boxShadow={"4px 2px 2px 0px rgba(0, 0, 0, 0.25) inset"}>
								<PrescriptionsList />
							</Center>
							<Center
								w={"100%"}
								flex={"0 0 150px"}
								bg={"#fff"}
								rounded={"lg"}
								boxShadow={"4px 2px 2px 0px rgba(0, 0, 0, 0.25) inset"}>
								<TestItemsList />
							</Center>

							<Flex gap={"8px"} flex={"1 0 auto"}>
								<Flex direction={"column"} flex={"0 1 calc( 33.33% - 4px)"} gap={"8px"}>
									<Center
										bg={"#fff"}
										rounded={"lg"}
										boxShadow={"4px 2px 2px 0px rgba(0, 0, 0, 0.25) inset"}
										border={"2px solid #646B48"}>
										<PatientInfo />
									</Center>
									<Center
										bg={"#fff"}
										rounded={"lg"}
										boxShadow={"4px 2px 2px 0px rgba(0, 0, 0, 0.25) inset"}
										border={"2px solid #646B48"}
										overflow={"auto"}
										flex={1}>
										<CashDetail />
									</Center>
								</Flex>
								<Flex direction={"column"} flex={"0 1 calc( 33.33% - 4px)"} overflow={"hidden"} gap={"8px"}>
									<Center
										w={"100%"}
										flex={"1"}
										bg={"#fff"}
										rounded={"lg"}
										boxShadow={"4px 2px 2px 0px rgba(0, 0, 0, 0.25) inset"}>
										<BillingDetail />
									</Center>
									<Center
										w={"100%"}
										bg={"#fff"}
										rounded={"lg"}
										boxShadow={"4px 2px 2px 0px rgba(0, 0, 0, 0.25) inset"}
										flex={1}>
										<DoctorOrders />
									</Center>
								</Flex>
								<Flex direction={"column"} flex={"0 1 calc( 33.33% - 4px)"} overflow={"hidden"} gap={"8px"}>
									<Center
										flexGrow={1}
										w={"100%"}
										bg={"#fff"}
										rounded={"lg"}
										boxShadow={"4px 2px 2px 0px rgba(0, 0, 0, 0.25) inset"}>
										<CasesRatio />
									</Center>
									<Center
										flexGrow={"1"}
										w={"100%"}
										bg={"#fff"}
										rounded={"lg"}
										boxShadow={"4px 2px 2px 0px rgba(0, 0, 0, 0.25) inset"}>
										<ReportingMember />
									</Center>
								</Flex>
							</Flex>
						</Flex>
						<Flex flex={"0 1 calc(33.33% - 4px)"} gap={"8px"} direction={"column"} overflowX={"hidden"}>
							<Center
								w={"100%"}
								bg={"#fff"}
								rounded={"lg"}
								boxShadow={"4px 2px 2px 0px rgba(0, 0, 0, 0.25) inset"}
								overflowY={"auto"}>
								<DxHistory />
							</Center>
							<Center w={"100%"} bg={"#fff"} rounded={"lg"} boxShadow={"4px 2px 2px 0px rgba(0, 0, 0, 0.25) inset"}>
								<Category />
							</Center>
							<Center
								w={"100%"}
								flex={"1"}
								bg={"#fff"}
								rounded={"lg"}
								boxShadow={"4px 2px 2px 0px rgba(0, 0, 0, 0.25) inset"}>
								<Options />
							</Center>

							<Center w={"100%"} flex={"1"} rounded={"lg"}>
								<CompleteOptions />
							</Center>
						</Flex>
					</Flex>
				</Box>{" "}
				{showAbnormalButtonStatus && (
					<Box position='absolute' zIndex={9999} top={"50%"} left={"50%"} transform={"translate(-50%, -50%)"}>
						<AbnormalButton />
					</Box>
				)}
				{showReadVHICStatus && (
					<Box position='absolute' zIndex={9999} top={"0%"} left={"50%"} transform={"translate(-50%, -50%)"}>
						<VirtualCardAuthorizeComponents />
					</Box>
				)}
			</Center>
		</>
	);
};
