import { Heading, Button, filter } from '@chakra-ui/react';
import {
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    TabIndicator,
    Input,
    InputGroup,
    InputLeftElement,
    Grid,
    GridItem,
} from '@chakra-ui/react';
import { Box, FormLabel, Flex, Text } from '@chakra-ui/react';
import React, {
    useState,
    useEffect,
    useRef,
    RefObject,
    createRef,
    MouseEvent,
} from 'react';

import { RadioGroup, Radio } from '@chakra-ui/react';
import GroupViewIcon from '../../CustomComponents/GroupViewIcon';
import GroupEditIcon from 'components/CustomComponents/GroupEditIcon';
import GroupDenyIcon from 'components/CustomComponents/GroupDenyIcon';
import GroupMemberIcon from 'components/CustomComponents/GroupMemberIcon';
import AddIcon from 'components/CustomComponents/AddIcon';

import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import Swal from 'sweetalert2';

import '../../../style/components/animation.css';

// API
// 取得群組權限
// import { callGroupAuthListService } from 'service/callGroupAuthListService';
import { callGroupAuthListService } from 'service/groupAuthListService';
// 取得系統功能表
import { callSystemFunctionTreeService } from 'service/systemFunctionTreeService';
// import { callSystemFunctionTreeService } from 'service/callSystemFunctionTreeService';
import { postGroupAuthService } from 'service/groupAuthListService';
// import { postGroupAuthService } from 'service/postGroupAuthService';
import { deleteGroupAuthService } from 'service/groupAuthListService';
// import { deleteGroupAuthService } from 'service/deleteGroupAuthService';

import { useUserStore } from 'store';

interface NavigationProps {
    name: string;
    path?: string;
    items?: NavigationProps[];
    key: string;
    status: string;
}

interface ItemWithStatus {
    key: string;
    status: string;
}
interface FuncAuth {
    [key: string]: string;
}
interface GroupListsProps {
    data_id?: string;
    name: string;
    func_auth: { [key: string]: string };
}

const ButtonStates = {
    VIEW: '可檢視',
    EDIT: '可編輯',
    DISABLED: '禁止使用',
    QQ: '衝突',
};

const GroupPermissionSettingTable = () => {
    // const token = localStorage.getItem('token');
    const { userInfo } = useUserStore();

    const [groupLists, setGroupLists] = useState<GroupListsProps[]>([]);
    const [navigation, setNavigation] = useState<NavigationProps[]>([]);
    const [newNavigation, setNewNavigation] = useState<NavigationProps[]>();

    const [editStatus, setEditStatus] = useState<boolean>(false);
    const [changedStatus, setChangedStatus] = useState<string>();
    const [radioStatus, setRadioStatus] = useState<string | null>(null);
    const newTabInputRef = useRef<HTMLInputElement>(null);
    const inputRefs = useRef<RefObject<HTMLInputElement>[]>([]);
    const checkNavigation = useRef<NavigationProps[]>([]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const callSystemFunctionTreeAPI = async () => {
        if (userInfo.token) {
            try {
                const result = await callSystemFunctionTreeService(
                    userInfo.token
                );
                if (result.result === 'success') {
                    setNavigation(result.items);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };

    const callGroupAuthListAPI = async () => {
        if (userInfo.token) {
            try {
                const result = await callGroupAuthListService(userInfo.token);
                if (result.result === 'success') {
                    setGroupLists(result.lists);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };

    useEffect(() => {
        callGroupAuthListAPI();
    }, []);
    useEffect(() => {
        callSystemFunctionTreeAPI();
    }, []);

    useEffect(() => {
        if (navigation) {
            //改造結構 加上status, 這邊只有改結構沒有把status加進去
            const transformData = () => {
                return Object.entries(navigation).map(([key, item]) => {
                    const newItem = {
                        ...item,
                        key: item.key || key,
                        status: '',
                    };

                    if (newItem.items) {
                        newItem.items = Object.entries(newItem.items).map(
                            ([subKey, subItem]) => {
                                return {
                                    ...subItem,
                                    key: subItem.key || subKey,
                                    status: '',
                                };
                            }
                        );
                    }
                    return newItem;
                });
            };
            setNewNavigation(transformData());
        }
    }, [navigation]);

    const getNextState = (currentState: string) => {
        switch (currentState) {
            case ButtonStates.VIEW:
                return ButtonStates.EDIT;
            case ButtonStates.EDIT:
                return ButtonStates.DISABLED;
            case ButtonStates.DISABLED:
                return ButtonStates.VIEW;
            case ButtonStates.QQ:
                return ButtonStates.QQ;
            default:
                return ButtonStates.VIEW;
        }
    };

    const handleClickStatus = (event: MouseEvent<HTMLButtonElement>) => {
        const value = event.currentTarget.value;
        if (value) {
            if (radioStatus === value) {
                setRadioStatus(null);
            } else {
                if (radioStatus === '') {
                    setRadioStatus('');
                }
                setRadioStatus(value);
            }
        }
    };

    // 編輯時不可切分頁
    const handleEdit = () => {
        if (groupLists && groupLists[selectedTabIndex].data_id) {
            setEditStatus(true);
            if (inputRefs.current && typeof selectedTabIndex === 'number') {
                const selectedInputRef = inputRefs.current[selectedTabIndex];
                if (selectedInputRef && selectedInputRef.current) {
                    selectedInputRef.current.focus();
                }
            }
        } else {
            setEditStatus(true);
            newTabInputRef.current?.focus();
        }
    };

    const handleSave = () => {
        if (newNavigation && userInfo.token) {
            let itemsWithStatus: ItemWithStatus[] = [];

            newNavigation.forEach(item => {
                if (item.status) {
                    itemsWithStatus.push({
                        key: item.key,
                        status: item.status,
                    });
                }

                if (item.items) {
                    item.items.forEach(subItem => {
                        if (subItem.status) {
                            itemsWithStatus.push({
                                key: subItem.key,
                                status: subItem.status,
                            });
                        }
                    });
                }
            });

            const funcAuth = itemsWithStatus.reduce(
                (acc: FuncAuth, currentItem) => {
                    acc[currentItem.key] = currentItem.status;
                    return acc;
                },
                {} as FuncAuth
            );

            // ryan say stop

            if (Object.entries(funcAuth).length === 0) {
                Swal.fire({
                    icon: 'error',
                    title: '新增失敗，無設定任何權限項目',
                    confirmButtonText: '好',
                });
                return;
            }

            if (groupLists[selectedTabIndex].name === '') {
                Swal.fire({
                    icon: 'error',
                    title: '請輸入群組名稱',
                    confirmButtonText: '好',
                });
                return;
            }
            if (
                groupLists &&
                selectedTabIndex < groupLists.length &&
                groupLists[selectedTabIndex].data_id
            ) {
                const updateAuthData = {
                    data_id: groupLists[selectedTabIndex].data_id,
                    name: groupLists[selectedTabIndex].name,
                    func_auth: funcAuth,
                };
                postGroupAuthAPI(updateAuthData);
            } else {
                const newGroupData = {
                    name: groupLists[selectedTabIndex].name,
                    func_auth: funcAuth,
                };
                postGroupAuthAPI(newGroupData);
            }
        }
        setEditStatus(false);

        setRadioStatus('');
    };

    const postGroupAuthAPI = async (finalData: GroupListsProps) => {
        if (userInfo.token && newNavigation) {
            try {
                const result = await postGroupAuthService(
                    userInfo.token,
                    finalData
                );
                if (result.result) {
                    Swal.fire({
                        icon: 'success',
                        title: '儲存成功',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    setGroupLists(prev => {
                        if (!prev) return prev;

                        return prev.map((item, index) => {
                            if (index === selectedTabIndex) {
                                return { ...item, data_id: result.data_id };
                            }
                            return item;
                        });
                    });
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }

        callGroupAuthListAPI();
    };

    const handleCancel = () => {
        // 這是新增的邏輯
        if (selectedTabIndex == groupLists.length - 1) {
            setEditStatus(false);
            setSelectedTabIndex(0);
            setGroupLists(
                groupLists.filter((item, index) => selectedTabIndex !== index)
            );
        } else {
            setNewNavigation(checkNavigation.current);
            setEditStatus(false);
        }
        setRadioStatus('');
    };

    const getColorScheme = (buttonState: string) => {
        switch (buttonState) {
            case ButtonStates.VIEW:
                return 'rsPrimary';
            case ButtonStates.EDIT:
                return 'rsWarning';
            case ButtonStates.DISABLED:
                return 'rsDanger';
            case ButtonStates.QQ:
                return 'white';
            default:
                return 'white';
        }
    };
    const getButtonColor = (status: string) => {
        switch (status) {
            case '':
                return `${'rsPrimary'}`;
            case 'D':
                return `${'rsDanger'}`;
            case 'E':
                return `${'rsWarning'}`;
        }
    };
    const getTextColor = (status: string) => {
        switch (status) {
            case 'E':
                return `${'rsPrimary.500'}`;
            default:
                return '';
        }
    };
    const handleClickBtn = (itemName: string, key: string) => {
        if (radioStatus) {
            setNewNavigation(prevNavigation => {
                if (!prevNavigation) return prevNavigation;
                return prevNavigation.map(item => {
                    if (item.key === key) {
                        const newStatus =
                            radioStatus === 'V' ? '' : radioStatus;

                        const updatedItem = { ...item, status: newStatus };

                        if (updatedItem.items) {
                            updatedItem.items = updatedItem.items.map(
                                subItem => {
                                    return { ...subItem, status: newStatus };
                                }
                            );
                        }

                        return updatedItem;
                    }
                    return item;
                });
            });
        } else {
            setNewNavigation(prevNavigation => {
                if (!prevNavigation) return prevNavigation;
                return prevNavigation.map(item => {
                    if (item.key === key) {
                        let newStatus;
                        switch (item.status) {
                            case '':
                                newStatus = 'E';
                                break;
                            case 'E':
                                newStatus = 'D';
                                break;
                            case 'D':
                                newStatus = '';
                                break;
                            default:
                                newStatus = '';
                        }

                        return { ...item, status: newStatus };
                    }
                    return item;
                });
                return prevNavigation;
            });
        }
    };
    const handleClickChildBtn = (itemName: string, key: string) => {
        setNewNavigation(prevNavigation => {
            if (!prevNavigation) return prevNavigation;

            return prevNavigation.map(item => {
                let allStatusSame = true;
                let firstStatus: string | null = null;

                if (item.items) {
                    item.items = item.items.map(subItem => {
                        if (subItem.key === key) {
                            let newStatus;

                            if (radioStatus) {
                                newStatus =
                                    radioStatus === 'V' ? '' : radioStatus;
                            } else {
                                switch (subItem.status) {
                                    case '':
                                        newStatus = 'E';
                                        break;
                                    case 'E':
                                        newStatus = 'D';
                                        break;
                                    case 'D':
                                        newStatus = '';
                                        break;
                                    default:
                                        newStatus = '';
                                }
                            }
                            return { ...subItem, status: newStatus };
                        }
                        return subItem;
                    });

                    firstStatus = item.items[0]?.status;
                    allStatusSame = item.items.every(
                        subItem => subItem.status === firstStatus
                    );
                }

                return {
                    ...item,
                    status:
                        allStatusSame && firstStatus !== null
                            ? firstStatus
                            : 'N',
                };
            });
        });
    };

    useEffect(() => {
        if (selectedTabIndex === groupLists.length) {
            newTabInputRef.current?.focus();
        }
    }, [selectedTabIndex]);

    // useEffect(() => {
    //     if (
    //         groupLists[selectedTabIndex] &&
    //         selectedTabIndex !== undefined &&
    //         newNavigation
    //     ) {
    //         const updatedNavigation = newNavigation.map(navItem => {
    //             if (navItem.items && Array.isArray(navItem.items)) {
    //                 const updatedItems = navItem.items.map(item => {
    //                     if (
    //                         groupLists[
    //                             selectedTabIndex
    //                         ]?.func_auth?.hasOwnProperty(item.key)
    //                     ) {
    //                         return {
    //                             ...item,
    //                             status: groupLists[selectedTabIndex].func_auth[
    //                                 item.key
    //                             ],
    //                         };
    //                     }
    //                     return item;
    //                 });
    //                 return { ...navItem, items: updatedItems };
    //             }
    //             return navItem;
    //         });

    //     }
    // }, [groupLists, selectedTabIndex]);
    const updateNavigation = () => {
        if (
            groupLists[selectedTabIndex] &&
            selectedTabIndex !== undefined &&
            newNavigation
        ) {
            const updatedNavigation = newNavigation.map(navItem => {
                const navItemFuncAuthStatus =
                    groupLists[selectedTabIndex]?.func_auth?.[navItem.key];

                const updatedNavItem = {
                    ...navItem,
                    status: navItemFuncAuthStatus
                        ? navItemFuncAuthStatus
                        : navItem.status,
                };

                if (navItem.items && Array.isArray(navItem.items)) {
                    const updatedItems = navItem.items.map(item => {
                        const itemFuncAuthStatus =
                            groupLists[selectedTabIndex]?.func_auth?.[item.key];
                        return {
                            ...item,
                            status: itemFuncAuthStatus
                                ? itemFuncAuthStatus
                                : '',
                        };
                    });
                    updatedNavItem.items = updatedItems;
                }

                return updatedNavItem;
            });
            setNewNavigation(updatedNavigation);
            checkNavigation.current = updatedNavigation;
        }
    };

    useEffect(() => {
        updateNavigation();
    }, [selectedTabIndex, groupLists]);

    const handleChangeTab = (index: number, data_id?: string) => {
        if (data_id) {
            if (editStatus) {
                inputRefs.current[index].current?.focus();
                return;
            } else {
                setSelectedTabIndex(index);
            }
            // 新增 沒data_id
        } else {
            if (!editStatus) {
                setEditStatus(true);
                setGroupLists(prev => [
                    ...prev,
                    { name: newTabName, func_auth: {} },
                ]);
                setSelectedTabIndex(groupLists.length);
            }
        }
    };

    const [newTabName, setNewTabName] = useState<string>('');
    const handleChangeTabName = (
        event: React.ChangeEvent<HTMLInputElement>,
        data_id?: string,
        index?: number
    ) => {
        // 已經有的資料
        if (data_id) {
            // 編輯時
            if (editStatus && selectedTabIndex >= 0) {
                // 更新名字
                setGroupLists(prev => {
                    return prev.map(item =>
                        item.data_id === data_id
                            ? { ...item, name: event.target.value }
                            : item
                    );
                });

                const selectedInputRef = inputRefs.current[selectedTabIndex];
                if (selectedInputRef && selectedInputRef.current) {
                    selectedInputRef.current.focus();
                }
            }
        } else {
            if (index === groupLists.length - 1 && editStatus) {
                setGroupLists(prev =>
                    prev.map((item, idx) =>
                        idx === index
                            ? { ...item, name: event.target.value }
                            : item
                    )
                );
            }
        }
    };

    useEffect(() => {
        inputRefs.current = groupLists.map(
            (_, i) => inputRefs.current[i] || createRef()
        );
    }, [groupLists]);

    useEffect(() => {
        if (
            editStatus &&
            typeof selectedTabIndex === 'number' &&
            inputRefs.current[selectedTabIndex]
        ) {
            const selectedInputRef = inputRefs.current[selectedTabIndex];
            if (selectedInputRef && selectedInputRef.current) {
                selectedInputRef.current.focus();
            }
        }
    }, [selectedTabIndex, editStatus]);

    // 這個要啟用,但使用newNavigation會造成無限迴圈
    useEffect(() => {
        if (
            JSON.stringify(newNavigation) ===
            JSON.stringify(checkNavigation.current)
        ) {
            return;
        } else {
            setNewNavigation(prevNavigation => {
                if (!prevNavigation) return prevNavigation;

                return prevNavigation.map(item => {
                    // 对于没有items的项目，直接返回，不做status的统一设定
                    if (!item.items || item.items.length === 0) {
                        return {
                            ...item,
                            // 保持原有的status，如果原本没有定义，则默认为空字符串
                            status:
                                item.status !== undefined ? item.status : '',
                        };
                    }

                    // 对于有items的项目，判断所有子项目的status是否相同
                    let firstStatus = item.items[0].status;
                    let allStatusSame = item.items.every(
                        subItem => subItem.status === firstStatus
                    );

                    // 如果所有子项目的status相同，则设置该status给父项目，否则设为'N'
                    return {
                        ...item,
                        status: allStatusSame ? firstStatus : 'N',
                    };
                });
            });
        }
        return;
    }, [selectedTabIndex, groupLists]);

    const deleteGroupAuthAPI = async (data_id: string) => {
        if (userInfo.token) {
            try {
                const result = await deleteGroupAuthService(
                    userInfo.token,
                    data_id
                );
                if (result.result === 'success') {
                    Swal.fire({
                        icon: 'success',
                        title: '刪除成功',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    setGroupLists(prev => {
                        if (!prev) return prev;
                        return prev.filter(item => item.data_id !== data_id);
                    });
                    setSelectedTabIndex(0);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };
    const handleDeactivated = () => {
        if (groupLists && groupLists[selectedTabIndex]?.data_id) {
            const groupId = groupLists[selectedTabIndex].data_id;
            if (groupId) {
                // 确保 groupId 不是 undefined

                Swal.fire({
                    title: '是否要刪除此群組？',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                }).then(result => {
                    if (result.isConfirmed) {
                        deleteGroupAuthAPI(groupId);
                    } else {
                        return;
                    }
                });
            }
        }
    };

    return (
        <>
            <Tabs variant="unstyled" index={selectedTabIndex}>
                <TabList
                    overflowX={'auto'}
                    p={'8px 16px'}
                    bg={'gray.50'}
                    rounded={'15px'}
                    borderBottom={'2px solid #ccc'}
                >
                    <>
                        {groupLists &&
                            groupLists.map((item, index) => (
                                <Tab
                                    key={index}
                                    textColor={'rsPrimary.500'}
                                    opacity={0.5}
                                    fontWeight={'semibold'}
                                    _selected={{
                                        opacity: 1,
                                    }}
                                    onClick={() =>
                                        handleChangeTab(index, item.data_id)
                                    }
                                >
                                    <Flex gap={'4px'}>
                                        <Box w={'24px'}>
                                            <HeroIcons name="users-fill"></HeroIcons>
                                        </Box>
                                        <Box whiteSpace={'nowrap'}>
                                            {item.name}
                                        </Box>
                                    </Flex>
                                </Tab>
                            ))}
                        {!editStatus && (
                            <>
                                <Tab
                                    key={groupLists.length}
                                    textColor={'rsPrimary.500'}
                                    opacity={0.5}
                                    fontWeight={'semibold'}
                                    _selected={{
                                        opacity: 1,
                                    }}
                                    isDisabled={editStatus}
                                    onClick={() =>
                                        handleChangeTab(groupLists.length)
                                    }
                                >
                                    <Flex gap={'4px'}>
                                        <Box w={'24px'}>
                                            <HeroIcons name="add-fill"></HeroIcons>
                                        </Box>

                                        <Box
                                            whiteSpace={'nowrap'}
                                            // ref={newTabInputRef}
                                        >
                                            {newTabName}
                                            新增群組
                                        </Box>
                                    </Flex>
                                </Tab>
                            </>
                        )}
                    </>
                </TabList>
                <TabIndicator
                    mt="-16px"
                    height="2px"
                    bg="#9B9B7A"
                    borderRadius="1px"
                />
            </Tabs>

            <Box
                mt={'8px'}
                p={'16px'}
                bg={'gray.50'}
                rounded={'15px'}
                pos={'relative'}
                borderBottom={'2px solid #ccc'}
            >
                <Flex px={'8px'} gap={'16px'} align={'center'}>
                    {/* 名稱 */}
                    <Flex gap={'4px'} align={'center'} pos={'relative'}>
                        <Box w={'24px'} textColor={'rsPrimary.500'}>
                            <HeroIcons name="users-fill"></HeroIcons>
                        </Box>

                        {editStatus ? (
                            <>
                                <Input
                                    flex={1}
                                    bg="#fff"
                                    ref={inputRefs.current[selectedTabIndex]}
                                    value={groupLists[selectedTabIndex].name}
                                    onChange={event =>
                                        handleChangeTabName(
                                            event,
                                            groupLists[selectedTabIndex]
                                                .data_id,
                                            selectedTabIndex
                                        )
                                    }
                                ></Input>
                            </>
                        ) : (
                            <>
                                {groupLists.length > 1 && (
                                    <>
                                        <Box
                                            textStyle={'semiTitle'}
                                            pos={'relative'}
                                            py={'8px'}
                                        >
                                            {groupLists[selectedTabIndex]
                                                .name ?? ''}
                                            <Button
                                                pos={'absolute'}
                                                left={'calc(100% + 20px)'}
                                                top={'50%'}
                                                className=" translate-y-[-50%]"
                                                size={'sm'}
                                                onClick={handleEdit}
                                                colorScheme="rsWarning"
                                                isDisabled={
                                                    editStatus ? true : false
                                                }
                                            >
                                                編輯群組
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </>
                        )}
                    </Flex>

                    {/* 功能區 */}

                    {editStatus ? (
                        <>
                            {/* 一鍵套用區 */}
                            <Box
                                w={'fit-content'}
                                rounded={'4px'}
                                bg={'#E9E5DA'}
                                px={'16px'}
                                py={'4px'}
                            >
                                <Flex gap={'16px'} alignItems={'center'}>
                                    <Flex gap={'4px'} align={'center'}>
                                        <Box
                                            className={
                                                editStatus &&
                                                radioStatus == null
                                                    ? 'heartbeat'
                                                    : ''
                                            }
                                            textColor={'rsPrimary.500'}
                                        >
                                            {/* 手手 icon */}
                                            <HeroIcons name="cursor-arrow-ripple"></HeroIcons>
                                        </Box>
                                        <Heading
                                            textColor={'rsPrimary.500'}
                                            size={'sm'}
                                            fontWeight={'semibold'}
                                            whiteSpace={'nowrap'}
                                        >
                                            一鍵套用
                                        </Heading>
                                    </Flex>

                                    <Button
                                        size={'sm'}
                                        value={'V'}
                                        onClick={
                                            editStatus
                                                ? handleClickStatus
                                                : undefined
                                        }
                                        colorScheme="rsPrimary"
                                        className={
                                            radioStatus === 'V' && editStatus
                                                ? 'heartbeat'
                                                : ''
                                        }
                                    >
                                        <Text className="flex items-center gap-2">
                                            <GroupViewIcon />
                                            <span className=" font-semibold">
                                                僅檢視
                                            </span>
                                        </Text>
                                    </Button>

                                    <Button
                                        size={'sm'}
                                        value={'E'}
                                        onClick={
                                            editStatus
                                                ? handleClickStatus
                                                : undefined
                                        }
                                        colorScheme="rsWarning"
                                        className={
                                            radioStatus === 'E' && editStatus
                                                ? 'heartbeat'
                                                : ''
                                        }
                                        textColor={'rsPrimary.500'}
                                    >
                                        <Text className="flex items-center gap-2">
                                            <GroupEditIcon />
                                            <span className=" font-semibold">
                                                可編輯
                                            </span>
                                        </Text>
                                    </Button>

                                    <Button
                                        size={'sm'}
                                        value={'D'}
                                        onClick={
                                            editStatus
                                                ? handleClickStatus
                                                : undefined
                                        }
                                        colorScheme="rsDanger"
                                        className={
                                            radioStatus === 'D' && editStatus
                                                ? 'heartbeat'
                                                : ''
                                        }
                                    >
                                        <Text className="flex items-center gap-2">
                                            <GroupDenyIcon />
                                            <span className=" font-semibold">
                                                禁止使用
                                            </span>
                                        </Text>
                                    </Button>
                                </Flex>
                            </Box>
                        </>
                    ) : (
                        <></>
                    )}
                </Flex>

                {/* Heading */}
                <Flex
                    mt={'16px'}
                    mb={'16px'}
                    borderBottom={'2px solid #868C6E'}
                    opacity={editStatus ? '1' : '0.8'}
                    py={'8px'}
                >
                    <Heading
                        size={'sm'}
                        textColor={'rsPrimary.500'}
                        fontWeight={'semibold'}
                        flex={'0 1 160px'}
                    >
                        權限項目
                    </Heading>
                    <Heading
                        size={'sm'}
                        textColor={'rsPrimary.500'}
                        fontWeight={'semibold'}
                        flex={'1 1 auto'}
                    >
                        權限內容
                    </Heading>
                </Flex>

                {newNavigation &&
                    newNavigation.map((item, index) => (
                        <>
                            <Flex
                                pos="relative"
                                className="text-black"
                                key={index}
                            >
                                {item.items ? (
                                    <>
                                        {/* 渲染第一層 */}
                                        <Flex
                                            flex={'0 1 160px'}
                                            mb={'16px'}
                                            gap={'8px'}
                                            h={'40px'}
                                            align={'center'}
                                        >
                                            <Button
                                                w={7}
                                                h={7}
                                                border={'1px solid #aaa'}
                                                onClick={() =>
                                                    editStatus &&
                                                    radioStatus !== null
                                                        ? handleClickBtn(
                                                              item.name,
                                                              item.key
                                                          )
                                                        : undefined
                                                }
                                                colorScheme={getButtonColor(
                                                    item.status
                                                )}
                                            ></Button>
                                            <Text textStyle={'semiTitle'}>
                                                {item.name}
                                            </Text>
                                        </Flex>

                                        {/* 渲染第二層 */}
                                        <Flex
                                            flex={1}
                                            wrap={'wrap'}
                                            gap={'16px'}
                                        >
                                            {item.items &&
                                                item.items.map(
                                                    (subItem, subIndex) => (
                                                        <>
                                                            <Button
                                                                key={subIndex}
                                                                onClick={() =>
                                                                    editStatus
                                                                        ? handleClickChildBtn(
                                                                              subItem.name,
                                                                              subItem.key
                                                                          )
                                                                        : undefined
                                                                }
                                                                colorScheme={getButtonColor(
                                                                    subItem.status
                                                                )}
                                                            >
                                                                <Flex
                                                                    align={
                                                                        'center'
                                                                    }
                                                                    gap={2}
                                                                    textColor={getTextColor(
                                                                        subItem.status
                                                                    )}
                                                                >
                                                                    <span>
                                                                        {subItem.status ===
                                                                        '' ? (
                                                                            <>
                                                                                <GroupViewIcon></GroupViewIcon>
                                                                            </>
                                                                        ) : (
                                                                            <>

                                                                            </>
                                                                        )}
                                                                        {subItem.status ===
                                                                        'D' ? (
                                                                            <>
                                                                                <GroupDenyIcon></GroupDenyIcon>
                                                                            </>
                                                                        ) : (
                                                                            <>

                                                                            </>
                                                                        )}
                                                                        {subItem.status ===
                                                                        'E' ? (
                                                                            <>
                                                                                <GroupEditIcon></GroupEditIcon>
                                                                            </>
                                                                        ) : (
                                                                            <>

                                                                            </>
                                                                        )}
                                                                    </span>
                                                                    <span>
                                                                        {
                                                                            subItem.name
                                                                        }
                                                                    </span>
                                                                </Flex>
                                                            </Button>
                                                        </>
                                                    )
                                                )}
                                        </Flex>
                                    </>
                                ) : (
                                    <>
                                        {/* 渲染無子層的第一層 */}
                                        <Flex
                                            align={'center'}
                                            gap={'8px'}
                                            mb={'16px'}
                                            h={'40px'}
                                        >
                                            <Button
                                                w={7}
                                                h={7}
                                                onClick={() =>
                                                    editStatus && radioStatus
                                                        ? handleClickBtn(
                                                              item.name,
                                                              item.key
                                                          )
                                                        : undefined
                                                }
                                                colorScheme={getButtonColor(
                                                    item.status
                                                )}
                                            ></Button>

                                            <Text textStyle={'semiTitle'}>
                                                {item.name}
                                            </Text>
                                        </Flex>
                                    </>
                                )}
                            </Flex>
                        </>
                    ))}

                <Flex pos={'relative'} justifyContent={'flex-end'}>
                    {editStatus ? (
                        <>
                            <Flex gap={'8px'}>
                                <Button
                                    onClick={handleCancel}
                                    colorScheme="rsSteel"
                                >
                                    取消
                                </Button>
                                <Button
                                    onClick={handleSave}
                                    colorScheme="rsSecondary"
                                >
                                    儲存
                                </Button>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Button
                                colorScheme="rsDanger"
                                onClick={handleDeactivated}
                            >
                                刪除群組
                            </Button>
                        </>
                    )}
                </Flex>
            </Box>
        </>
    );
};

export default GroupPermissionSettingTable;
