import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { Cookies } from "react-cookie";
import Swal from "sweetalert2";

const cookies = new Cookies();

const axiosInstance = axios.create();
interface DecodedToken {
	exp?: number;
	iat?: number;
	[key: string]: any;
}

axiosInstance.interceptors.request.use(
	config => {
		// const XTenant = localStorage.getItem('X-Tenant');
		const XTenant = cookies.get("X-Tenant");
		if (XTenant) {
			config.headers["X-Tenant"] = XTenant;
		}

		// const token = localStorage.getItem('token');
		const token = cookies.get("token");
		if (token) {
			console.log("token 存在");
			// 解碼 JWT token
			const decodedToken: DecodedToken = jwtDecode(token);
			// 獲取登入時間，如果 iat 存在則使用它，否則使用當前時間
			const loginTime = decodedToken.iat ? decodedToken.iat * 1000 : Date.now();
			// 計算 4 小時後的過期時間
			const expTime_fourHourLater = loginTime + 4 * 60 * 60 * 1000;
			// const expTime_fourHourLater = loginTime + 5 * 1000;

			if (Date.now() < expTime_fourHourLater) {
				// config.headers['Authorization'] = token;
			} else {
				console.log("token 已過期");
				// Swal.fire({
				//     title: '操作時間逾時',
				//     text: '請重新登入',
				//     icon: 'warning',
				//     confirmButtonText: '確認',
				//     showConfirmButton: true,
				// }).then(result => {
				//     if (result.isConfirmed) {
				//         cookies.remove('token');
				//         window.location.href = '/loginPage';
				//     }
				// });
				cookies.remove("token");
				window.location.href = "/loginPage";
			}
		} else {
			console.log("token 不存在");
			if (location.pathname !== "/") {
				window.location.href = "/";
			}
		}
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

export default axiosInstance;
