import axios from "axios";

// 通知醫生的請求參數
export interface NotifyDoctorRequestProps {
	account: string; // 雲端帳號
	pw: string; // 雲端密碼
	hisCaseID: string; // 病例ID
	doctorID: string; // 醫師ID
}

export interface NotifyDoctorResponseProps {
	statusCode: number; // HTTP 狀態碼
}

export const notifyDoctorService = async (requestData: NotifyDoctorRequestProps): Promise<NotifyDoctorResponseProps | undefined> => {
	console.log("Notifying doctor with data:", requestData);
	try {
		const response = await axios.post("http://localhost:23752/VDAT/RsNotifyDoctor", requestData, {
			headers: {
				Accept: "*/*",
				"Content-Type": "application/json"
			}
		});

		console.log("Notification response:", response.data);
		return response.data;
	} catch (error) {
		if (error instanceof Error) {
			console.error("Error notifying doctor:", error.message);
		} else {
			console.error("An unknown error occurred while notifying doctor");
		}
		return undefined;
	}
};
