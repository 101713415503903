import { useState, useEffect } from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { InsetShadowBox } from 'style/chakraStyles';
import Swal from 'sweetalert2';

import { useIsICWriteInOpenStatus } from 'contexts/ICUpload/IsICWriteInOpen';

import { Lists } from './Lists';
import { ICWriteIn } from './ICWriteIn';
import { Search } from './Search';
import { Statistics } from './Statistics';
import { useUserStore } from 'store';
import { useICCardUpdateListStore } from 'store/ICCardUpdate/useICCardUpdateListStore';
import { postIcDataDataSetService } from 'service/icDataDataSetService';
import { postIcDataUploadResultService } from 'service/icDataUploadResultService';

import { useIcDataListsAPI } from 'service/icDataListsServiceHelper';

import { useICCardUpdateListTabIndexStore } from 'store/ICCardUpdate/useICCardUpdateListTabIndexStore';
import { useICCardUpdateShowStatusStore } from 'store/ICCardUpdate/useICCardUpdateShowStatusStore';

import { useInstitutionInfoStore } from 'store/DataSetting/useInstitutionInfoStore';
import { ICUpload2API } from 'service/RsService/ICUpload2APIHelper';
import { useCustomerAPI } from 'service/customerServiceHelper';

import { DlXMLButton } from './DlXMLButton';

export const ICCardUpdate = () => {
    const { userInfo } = useUserStore();
    const { isICWriteInOpenStatus, setIsICWriteInOpenStatus } =
        useIsICWriteInOpenStatus();
    const { institutionInfoData, updateInstitutionInfoData } =
        useInstitutionInfoStore();

    const { icCardUpdateList } = useICCardUpdateListStore();

    const { callIcDataListsAPI } = useIcDataListsAPI();

    const { tabIndex } = useICCardUpdateListTabIndexStore();
    const { showStatus } = useICCardUpdateShowStatusStore();

    const { callCustomerAPI } = useCustomerAPI();
    // 取得院所資料 callCustomerAPI

    useEffect(() => {
        callCustomerAPI();
    }, []);

    const handleUpload = () => {
        console.log('名單:', icCardUpdateList);
        const ids = icCardUpdateList
            .filter(
                item =>
                    item.ready &&
                    (showStatus === '' ||
                        item.upload_status.toString() === showStatus)
            )
            .map(item => item.medical_record_uid);
        // 此段程式碼的功能如下：
        // - 從 icCardUpdateList 中篩選出符合條件的項目
        // - 篩選條件包括：
        //   1. item.ready 為 true
        //   2. showStatus 為空字串，或者 item.upload_status 與 showStatus 相符
        // - 將篩選後的項目轉換為一個只包含 medical_record_uid 的陣列
        // - 最終得到的 ids 陣列包含所有符合條件的病歷 UID

        console.log('ready 的 ids:', ids);

        if (ids.length === 0) {
            Swal.fire({
                title: '名單中無資料',
                text: '請先勾取上傳資料',
                icon: 'warning',
                confirmButtonText: '確定',
            });
        } else {
            postIcDataListsAPI(ids);
        }
    };

    const postIcDataListsAPI = async (ids: string[]) => {
        try {
            const data = {
                ids: ids,
            };
            const result = await postIcDataDataSetService(userInfo.token, data);
            if (result.result === 'success') {
                console.log('回傳 API24 結果:', result);
                // 接到 23 回傳的資料後，整理成一格陣列，呼叫小張哥 API

                const ic_upload2_data = {
                    sHospid: institutionInfoData.nhid,
                    sPrecs: result.drugs_count,
                    sPatData: result.ic_content,
                    sType: 'A1',
                    sMrecs: result.medical_record_count,
                };

                console.log(
                    '準備給代理人的 ic_upload2_data: ',
                    ic_upload2_data
                );

                const ICupload2Result = await ICUpload2API(ic_upload2_data);
                if (ICupload2Result) {
                    postIcDataUploadResultAPI(ids);
                } else {
                    return;
                }
            }
        } catch (error) {
            console.error('上傳 IC 資料失敗:', error);
        }
    };

    const postIcDataUploadResultAPI = async (ids: string[]) => {
        const data = {
            ids: ids,
        };
        try {
            const result = await postIcDataUploadResultService(
                userInfo.token,
                data
            );
            if (result.result === 'success') {
                console.log('API24 回傳成功，呼叫 API22 刷新頁面');
                callIcDataListsAPI();
                Swal.fire({
                    icon: 'success',
                    title: '上傳成功',
                    confirmButtonText: '確定',
                });
            } else {
                console.log('API24 回傳失敗');
                Swal.fire({
                    icon: 'error',
                    title: '上傳失敗',
                    text: '請聯繫管理員',
                    confirmButtonText: '確定',
                });
            }
        } catch (error) {
            console.error('測試IC資料失敗:', error);
        }
    };

    return (
        <>
            <Box
                h={'calc( 100vh - 40px)'}
                bg={'gray.550'}
                p={'48px 20px 20px'}
                gap={'16px'}
            >
                <Box>
                    <Flex direction={'column'} gap={'16px'} h={'100%'}>
                        <Search />

                        <Box sx={InsetShadowBox}>
                            <Lists />
                        </Box>
                        <Flex gap={'8px'} height={'40px'} flexShrink={0}>
                            <DlXMLButton />
                            <Button
                                variant={'outline'}
                                colorScheme="rsPrimary"
                                borderWidth={'2px'}
                                hidden={true}
                            >
                                讀取 IC 卡相關資料
                            </Button>
                            <Button
                                hidden={true}
                                boxShadow={
                                    '2px -2px 2px 0px rgba(0, 0, 0, 0.50) inset;'
                                }
                                colorScheme="rsPrimary"
                            >
                                補正上傳
                            </Button>
                            <Button
                                hidden={tabIndex !== 0}
                                boxShadow={
                                    '2px -2px 2px 0px rgba(0, 0, 0, 0.50) inset;'
                                }
                                colorScheme="rsDanger"
                                onClick={handleUpload}
                            >
                                上傳
                            </Button>
                        </Flex>
                    </Flex>
                </Box>
            </Box>

            {isICWriteInOpenStatus && <ICWriteIn />}
        </>
    );
};
