import { TreatmentInfoProps } from "store/TreatmentManagement/useTreatmentInfoStore";
import { CCPEProps } from "store/TreatmentManagement/useCCPEStore";
import { DxProps } from "store/TreatmentManagement/useDxStore";
import { PrescriptionProps } from "store/TreatmentManagement/usePrescriptionStore";
import { cashDataStoreProps } from "store/TreatmentManagement/Payment/useCashDataStore";
import { accItemsLevelProps } from "service/accItemsLevelService";
import { FeeState } from "store/TreatmentManagement/Payment";
import { drugTypeListProps } from "service/drugTypeListService";
import { InstitutionInfoProps } from "store/DataSetting/useInstitutionInfoStore";
import { DrugFreqListsProps } from "store/DataSetting/useDrugFreqListStore";
import { UserInfoProps } from "store/useUserStore";
import { hisWritePrescriptionLogic } from "components/TreatmentManagement/ConsultingRoomComponents/BillingProcess/CompleteOptionsFunc/hisWritePrescriptionLogic";
import { postVHChisWriteMultiPrescriptSignService } from "service/VHIC/VHChisWriteMultiPrescriptSignService";

import { SummaryICCardUpdateData } from "../../../SummaryICCardUpdateData";
import { postIcDataService } from "service/icDataServices";
import Swal from "sweetalert2";
import {
	VHChisGetSeqNumber256N1ReqProps,
	postVHChisGetSeqNumber256N1Service,
	VHChisGetSeqNumber256N1ResProps
} from "service/VHIC/VHChisGetSeqNumber256N1Service";
import { VHChisGetSeqNumber256N1Format } from "service/VHIC/VHChisGetSeqNumber256N1Format";
import { RshisWriteMultiPrescriptSignService } from "service/RsService/RshisWriteMultiPrescriptSignService";
import { OriginPrescriptionProps } from "store/TreatmentManagement";
import { useICCardUpdateStore, ICCardUpdateProps } from "store/TreatmentManagement/ICCardUpdate/useICCardUpdateStore";
import { hisWriteTreatmentCodeLogic } from "../../CompleteOptionsFunc/hisWriteTreatmentCodeLogic";
import { RshisWriteTreatmentCodeService } from "service/RsService/RshisWriteTreatmentCodeService";
import { postVHChisGetRegisterBasicJsonService, VHChisGetRegisterBasicJsonResProps } from "service/VHIC/VHChisGetRegisterBasicJsonService";
import { postVHChisWriteTreatmentCode10Service } from "service/VHIC/VHChisWriteTreatmentCode10Service";
import { getVHChisGetCardStatusService } from "service/VHIC/VHChisGetCardStatusService";
export const handleVirtualCardComplete = async (
	treatmentInfo: TreatmentInfoProps,
	CCPEData: CCPEProps,
	dxList: DxProps[],
	prescriptionList: PrescriptionProps[],
	cashDataStore: cashDataStoreProps,
	accItemsLevelList: accItemsLevelProps[],
	feeData: FeeState,
	if_formal_data: string,
	caseUid: string,
	drugTypeList: drugTypeListProps[],
	institutionInfoData: InstitutionInfoProps,
	drugFreqLists: DrugFreqListsProps[],
	userInfo: UserInfoProps,
	medicalRecordStatus: string,
	originPrescriptionList: OriginPrescriptionProps[]
) => {
	const VHChisGetRegisterBasicJsonAPI = async () => {
		try {
			const result = await postVHChisGetRegisterBasicJsonService();

			if (result && result.ROCID) {
				if (result.ROCID === treatmentInfo.id_no) {
					return result;
				} else {
					Swal.fire({
						icon: "warning",
						title: "身分證字號不符",
						text: "請重新掃描 QRCode",
						timer: 1500
					});
				}
			}
		} catch {
			Swal.fire({
				icon: "error",
				title: "取得虛擬健保卡基本資料失敗",
				timer: 1500,
				showConfirmButton: false
			});
		}
	};
	const VHChisGetRegisterBasicJsonResult: VHChisGetRegisterBasicJsonResProps | undefined = await VHChisGetRegisterBasicJsonAPI();

	const updateICCardDataAsync = (data: Partial<ICCardUpdateProps>) => {
		return new Promise<void>(resolve => {
			const { updateICCardData } = useICCardUpdateStore.getState();
			updateICCardData(data);
			resolve(); // 在更新完成后，Promise 立即解析
		});
	};
	try {
		let VHChisGetSeqNumber256N1ReqData: VHChisGetSeqNumber256N1ReqProps = {
			cTreatItem: (treatmentInfo.mt_type.padEnd(2, " ") + " ").slice(0, 3),
			cBabyTreat: " ".padEnd(1, " ") + " ".slice(0, 2),
			cTreatAfterCheck: "1"
		};

		let VHChisGetSeqNumber256N1ResData: VHChisGetSeqNumber256N1ResProps =
			await postVHChisGetSeqNumber256N1Service(VHChisGetSeqNumber256N1ReqData);
		console.log("VHChisGetSeqNumber256N1Result", VHChisGetSeqNumber256N1ResData);

		if (VHChisGetSeqNumber256N1ResData.ERRORCODE !== 0) {
			throw new Error("請檢查虛擬卡是否認證或代理人是否正常運作");
		}

		if (!VHChisGetSeqNumber256N1ResData.pBuffer) {
			throw new Error("未收到有效的 pBuffer 資料");
		}

		const VHChisGetSeqNumber256N1Result = VHChisGetSeqNumber256N1Format(VHChisGetSeqNumber256N1ResData.pBuffer);
		console.log("parsedPBuffer", VHChisGetSeqNumber256N1ResData);

		// TODO: Add further processing with structuredPBuffer data

		let ICCardUpdateData = SummaryICCardUpdateData(
			treatmentInfo,
			CCPEData,
			dxList,
			prescriptionList,
			cashDataStore,
			accItemsLevelList,
			feeData,
			if_formal_data,
			caseUid,
			drugTypeList,
			institutionInfoData,
			drugFreqLists,
			undefined,
			undefined,
			VHChisGetSeqNumber256N1Result,
			VHChisGetRegisterBasicJsonResult
		);
		console.log(ICCardUpdateData, "先看看吧能怎辦呢");
		const ICDataResult = await postIcDataService(userInfo.token, ICCardUpdateData);

		if (ICDataResult.result !== "success") {
			Swal.fire({
				icon: "error",
				title: "寫IC_DATA資料庫發生問題",
				showConfirmButton: true
			});
			return null;
		}

		if (ICDataResult.result === "success") {
			// if(true){
			// 開始寫卡
			let finalResult;
			if (prescriptionList && prescriptionList.length > 0) {
				const hisWritePrescriptionData = await hisWritePrescriptionLogic(
					treatmentInfo,
					ICCardUpdateData.drugs,
					drugFreqLists,
					undefined,
					undefined,
					undefined,
					medicalRecordStatus,
					originPrescriptionList,
					VHChisGetSeqNumber256N1Result,
					VHChisGetRegisterBasicJsonResult
				);
				const hisWritePrescriptionResult = await postVHChisWriteMultiPrescriptSignService(hisWritePrescriptionData);

				// const hisWritePrescriptionResultJSON =
				//     JSON.parse(hisWritePrescriptionResult);

				let hisWritePrescriptionResultJSON;
				if (typeof hisWritePrescriptionResult === "string") {
					try {
						hisWritePrescriptionResultJSON = JSON.parse(hisWritePrescriptionResult);
					} catch (error) {
						console.error("Failed to parse hisWritePrescriptionResult:", error);
						hisWritePrescriptionResultJSON = hisWritePrescriptionResult;
					}
				} else {
					hisWritePrescriptionResultJSON = hisWritePrescriptionResult;
				}
				console.log(hisWritePrescriptionResultJSON, "hisWritePrescriptionResultJSON有拿到什麼");

				if (hisWritePrescriptionResultJSON && hisWritePrescriptionResultJSON.pBuffer) {
					const pBuffer = hisWritePrescriptionResultJSON.pBuffer;
					const splitArray: string[] = [];

					for (let i = 0; i < pBuffer.length; i += 40) {
						splitArray.push(pBuffer.slice(i, i + 40));
					}

					// 获取当前的 ICCard 数据

					// 更新 drugs 数组中的每个对象的 D11 字段
					ICCardUpdateData = {
						...ICCardUpdateData,
						drugs: ICCardUpdateData.drugs.map((drug, index) => ({
							...drug,
							signature: splitArray[index] || ""
						})),
						ic_content: {
							...ICCardUpdateData.ic_content,
							drugs: ICCardUpdateData.ic_content.drugs.map((drug, index) => ({
								...drug,
								signature: splitArray[index] || ""
							}))
						}
					};

					console.log(ICCardUpdateData, "更新後的ICCardUpdateData");

					// Update the store with the new ICCardUpdateData
					await updateICCardDataAsync(ICCardUpdateData);

					// Post the updated ICCardUpdateData to the service
					const ICDataAddDrugSignatureResult = await postIcDataService(userInfo.token, ICCardUpdateData);

					console.log("是否成功更新資料庫的IC上傳2: ", ICDataAddDrugSignatureResult);
					finalResult = ICDataAddDrugSignatureResult;
					return finalResult;
				}
			} else {
				finalResult = { result: "success" };
			}

			//2. 做診斷 沒寫成功沒差
			const hisWriteTreatmentCodeData = await hisWriteTreatmentCodeLogic(
				treatmentInfo,
				ICCardUpdateData.ic_content.diagnosis,
				undefined,
				undefined,
				undefined,
				VHChisGetSeqNumber256N1Result,
				VHChisGetRegisterBasicJsonResult
			);
			// hisWriteTreatmentFeeLogic(treatmentInfo,)
			const hisWriteTreatmentCodeResult = await postVHChisWriteTreatmentCode10Service(hisWriteTreatmentCodeData);
			const parseHisWriteTreatmentCodeResult =
				typeof hisWriteTreatmentCodeResult === "string" ? JSON.parse(hisWriteTreatmentCodeResult) : hisWriteTreatmentCodeResult;

			console.log("診斷有沒有寫成功: ", hisWriteTreatmentCodeResult);
			// updateICCardData({

			// })
			//3. 做診療 沒寫成功沒差
		} else {
			console.log("這邊是沒有寫成功診斷的");
			// Swal.fire({
			//     icon: 'error',
			//     title: '寫入 IC 發生問題',
			//     text: '請檢查讀卡機和代理人',
			//     showConfirmButton: true,
			// });
			// return
		}
		return { result: "success" };
	} catch (error) {
		console.error("虛擬卡流程出錯:", error);
		Swal.fire({
			icon: "error",
			title: "取得虛擬卡就醫識別碼失敗",
			text: error instanceof Error ? error.message : "未知錯誤",
			showConfirmButton: true
		});
		return null;
	}
};
