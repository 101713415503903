import React, { createContext, useContext, useState, ReactNode } from "react";

interface IsVisitedWaitingListFeeDetailOpenContextValue {
	isVisitedWaitingListFeeDetailOpenStatus: boolean;
	setIsVisitedWaitingListFeeDetailOpenStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsVisitedWaitingListFeeDetailOpenContext = createContext<IsVisitedWaitingListFeeDetailOpenContextValue | undefined>(undefined);

interface IsVisitedWaitingListFeeDetailOpenProviderProps {
	children: ReactNode;
}

export const IsVisitedWaitingListFeeDetailOpenProvider: React.FC<IsVisitedWaitingListFeeDetailOpenProviderProps> = ({ children }) => {
	const [isVisitedWaitingListFeeDetailOpenStatus, setIsVisitedWaitingListFeeDetailOpenStatus] = useState(false);
	return (
		<IsVisitedWaitingListFeeDetailOpenContext.Provider
			value={{
				isVisitedWaitingListFeeDetailOpenStatus,
				setIsVisitedWaitingListFeeDetailOpenStatus
			}}>
			{children}
		</IsVisitedWaitingListFeeDetailOpenContext.Provider>
	);
};

export const useIsVisitedWaitingListFeeDetailOpenStatus = (): IsVisitedWaitingListFeeDetailOpenContextValue => {
	const context = useContext(IsVisitedWaitingListFeeDetailOpenContext);

	if (!context) {
		throw new Error("useIsVisitedWaitingListFeeDetailOpenStatus must be used within a isVisitedWaitingListFeeDetailOpenStatusProvider");
	}

	return context;
};
