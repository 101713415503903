import { PrescriptionProps } from "store/TreatmentManagement";
import { TreatmentInfoProps } from "store/TreatmentManagement";

export const D04Logic = (item: PrescriptionProps, orderType: string, treatmentInfo: TreatmentInfoProps) => {
	const isControlledDrug = item.controlled_level ? true : false;
	// const mt_type = treatmentInfo.selected_mt_type || treatmentInfo.mt_type
	let d04 = "";
	// d02 是否1, m才填

	if (orderType === "1" || orderType === "M") {
		if (treatmentInfo.case_type === "08") {
			d04 = isControlledDrug ? "F" : "C";
		} else if (
			item.dose_day &&
			Number(item.dose_day) > 7 &&
			treatmentInfo.refillable?.refill_times &&
			treatmentInfo.refillable?.refill_times > 1 &&
			item.controlled_level.toString() !== "1" &&
			item.controlled_level.toString() !== "2"
		) {
			// 如果是AE連續處方的就醫類別
			d04 = isControlledDrug ? "F" : "C";
		} else if (item && Number(item.dose_day) > 7) {
			// 只要有藥7 - 14天
			d04 = isControlledDrug ? "E" : "B";
		} else {
			// 1 - 7天(一般)
			d04 = isControlledDrug ? "D" : "A";
		}
		return d04;
	}
	return d04;
};
