import { create } from 'zustand';

interface DeclareHistoryLoadingStatusState {
    isHistoryLoading: boolean;
    setIsHistoryLoading: (status: boolean) => void;
    resetIsHistoryLoading: () => void;
}

export const useDeclareHistoryLoadingStatusStore =
    create<DeclareHistoryLoadingStatusState>(set => ({
        isHistoryLoading: false,
        setIsHistoryLoading: status => set({ isHistoryLoading: status }),
        resetIsHistoryLoading: () => set({ isHistoryLoading: false }),
    }));
