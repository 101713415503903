import React, { useEffect, useState } from "react";
import { Box, Flex, Button } from "@chakra-ui/react";
import { useDeclarePageControlStore } from "store/DeclarationAudit/useDeclarePageControlStore";
import { useDeclareSearchRangeStore } from "store/DeclarationAudit/useDeclareSearchRangeStore";
import { useUserStore } from "store";
import { useDeclareListsAPI } from "service/declareListsServiceHelper";
import { useDeclareErrorAPI } from "service/declareErrorServiceHelper";
import { useDeclareListTabIndexStore } from "store/DeclarationAudit/useDeclareListTabIndexStore";
import { useDeclareListSelectedItemStore } from "store/DeclarationAudit/useDeclareListSelectedItemStore";
import { useDeclareListHistoryDataStore } from "store/DeclarationAudit/useDeclareListHistoryDataStore";

export const ListFooter = () => {
	const { userInfo } = useUserStore();
	const { current_page, last_page, updateCurrentPage, updateLastPages } = useDeclarePageControlStore();
	const { updateDeclareSearchRangeData, declareSearchRangeData } = useDeclareSearchRangeStore();
	const { callDeclareListsAPI } = useDeclareListsAPI();
	const { callDeclareErrorAPI } = useDeclareErrorAPI();
	const { tabIndex } = useDeclareListTabIndexStore();
	const [isPageChange, setIsPageChange] = useState(false);
	const { resetHistoryData } = useDeclareListHistoryDataStore();
	const { resetSelectedItem } = useDeclareListSelectedItemStore();

	const handlePageClick = (pageNumber: number) => {
		console.log("點擊變更頁數:", pageNumber);
		setIsPageChange(true);
		updateDeclareSearchRangeData({
			...declareSearchRangeData,
			page: pageNumber
		});
		resetSelectedItem();
		resetHistoryData();
	};

	useEffect(() => {
		console.log("isPageChange", isPageChange);
		if (isPageChange) {
			if (tabIndex === 0) {
				console.log("切換頁數 打一次清單 ListFooter");
				callDeclareListsAPI(declareSearchRangeData);
			} else if (tabIndex === 1) {
				callDeclareErrorAPI(declareSearchRangeData);
			}
			setIsPageChange(false);
		}
	}, [isPageChange]);

	return (
		<Box mt={2} pos={"sticky"} bottom={0}>
			<Flex justifyContent={"center"} w={"100%"}>
				{last_page > 1 && (
					<>
						{[...Array(last_page)].map((_, index) => {
							const pageNumber = index + 1;
							const isCurrentPage = current_page === pageNumber;
							const isNearCurrentPage = Math.abs(current_page - pageNumber) <= 3;
							const isFirstOrLastPage = pageNumber === 1 || pageNumber === last_page;

							if (last_page > 10) {
								if (isFirstOrLastPage || isNearCurrentPage) {
									return (
										<Button
											size={"sm"}
											key={index}
											onClick={() => {
												handlePageClick(pageNumber);
											}}
											mx={1}
											colorScheme={isCurrentPage ? "rsPrimary" : "gray"}>
											{pageNumber}
										</Button>
									);
								} else if (pageNumber === 2 || pageNumber === last_page - 1) {
									return (
										<Button size={"sm"} key={index} mx={1} isDisabled>
											...
										</Button>
									);
								} else {
									return null;
								}
							}

							return (
								<Button
									size={"sm"}
									key={index}
									onClick={() => {
										handlePageClick(pageNumber);
									}}
									mx={1}
									colorScheme={isCurrentPage ? "rsPrimary" : "gray"}>
									{pageNumber}
								</Button>
							);
						})}
					</>
				)}
			</Flex>
		</Box>
	);
};
