import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import {
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    TabIndicator,
} from '@chakra-ui/react';
import { InsetShadowBox } from 'style/chakraStyles';
import { Details } from './Details';

export const RightSide = () => {
    return (
        <>
            <Box sx={InsetShadowBox}>
                <Box h={`calc(${window.innerHeight}px - 168px)`}>
                    {/* <Tabs position="relative" variant="unstyled">
                        <TabList>
                            <Tab>明細及醫令</Tab>
                            <Tab>XML整理</Tab>
                            <Tab>網路申報</Tab>
                            <Tab>健保公告</Tab>
                            <Tab>公告藥價</Tab>
                        </TabList>

                        <TabIndicator
                            mt="-1.5px"
                            height="2px"
                            bg="rsPrimary.500"
                            borderRadius="1px"
                        />
                        <TabPanels>
                            <TabPanel p={0}>
                                <Details />
                            </TabPanel>
                        </TabPanels>
                    </Tabs> */}
                    <Details />
                </Box>
            </Box>
        </>
    );
};
