import React, { useEffect, useState } from "react";

import { Button, Box, Flex, Text, Input, Tooltip, Radio, RadioGroup, Textarea } from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";
import Swal from "sweetalert2";
import { SwalToast } from "components/CustomComponents/SwalComponents";

// Context
import { useIsPartCopyStatus } from "contexts/TreatmentManagement/MedicalRecord";
import { useCCPEStore } from "store/TreatmentManagement";
import { useDxStore } from "store/TreatmentManagement/useDxStore";
import { usePrescriptionStore } from "store/TreatmentManagement";
import { useHistoryCCPEStore } from "store/TreatmentManagement/HistoryRecord/useHistoryCCPEStore";
import { useHistoryDxListStore } from "store/TreatmentManagement/HistoryRecord";
import { useHistoryPrescriptionStore } from "store/TreatmentManagement/HistoryRecord";
import { useIsCopyDrugFromHistoryStatus } from "contexts/TreatmentManagement/IsCopyDrugFromHistoryContext";
import { useIsHistoryLoadingStatus } from "contexts/TreatmentManagement/MedicalRecord/IsHistoryLoadingContext";
import { useIsCopyDrugFromHistoryForChronicStatus } from "contexts/TreatmentManagement/IsCopyDrugFromHistoryForChronicContext";

const CopyButtons: React.FC = () => {
	const { isPartCopyStatus, setIsPartCopyStatus, copyWayStatus, setCopyWayStatus } = useIsPartCopyStatus();
	const { CCPEData, updateCCPEData } = useCCPEStore();
	const { dxList, updateDx, updateDxList, appendDx } = useDxStore();
	const { prescriptionList, updatePrescription, setPrescriptionList, appendPrescription } = usePrescriptionStore();
	const { historyPrescriptionList } = useHistoryPrescriptionStore();
	const { historyCCPEData } = useHistoryCCPEStore();
	const { histroyDxList } = useHistoryDxListStore();
	const { isHistoryLoadingStatus, setIsHistoryLoadingStatus } = useIsHistoryLoadingStatus();

	const { setIsCopyDrugFromHistoryStatus } = useIsCopyDrugFromHistoryStatus();
	const { setIsCopyDrugFromHistoryForChronicStatus } = useIsCopyDrugFromHistoryForChronicStatus();
	useEffect(() => {
		console.log(isHistoryLoadingStatus, "查完新資訊了嗎");
	}, [isHistoryLoadingStatus]);
	const handleDitto = async () => {
		// 1覆蓋 2接上
		if (copyWayStatus === "1" && !isHistoryLoadingStatus) {
			//問問題
			updateCCPEData({
				cc: historyCCPEData ? historyCCPEData.cc : ""
			});
			console.log(histroyDxList, "先看一下");
			updateDxList(histroyDxList);
			console.log(historyPrescriptionList, "先看看吧歷史要");
			setPrescriptionList(historyPrescriptionList);
			setIsCopyDrugFromHistoryStatus(true);
			setIsCopyDrugFromHistoryForChronicStatus(true);
		} else if (copyWayStatus === "0" && !isHistoryLoadingStatus) {
			updateCCPEData({
				cc: historyCCPEData.cc ? CCPEData.cc + "," + historyCCPEData.cc : ""
			});
			const findFirstEmptyIndex = (list: { data_id?: string }[]): number | null => {
				for (let i = 0; i < list.length; i++) {
					if (!list[i].data_id) {
						return i;
					}
				}
				return null;
			};

			const isDuplicateDataId = (list: { data_id: string }[], data_id: string): boolean => {
				return list.some(item => item.data_id === data_id);
			};

			const showWarning = (name_tw: string) => {
				return Swal.fire({
					icon: "warning",
					title: "重複輸入",
					text: `${name_tw}`,
					showConfirmButton: false,
					timer: 1500
				});
			};

			// Append DxList
			let hasDuplicateDx = false;
			console.log(histroyDxList, "先看一下怎麼filter");
			const filterHistoryDxList = histroyDxList.filter(item => item.data_id);
			console.log(filterHistoryDxList, "filter後history診斷");
			try {
				for (const dx of filterHistoryDxList) {
					if (isDuplicateDataId(dxList, dx.data_id)) {
						hasDuplicateDx = true;
						const result = await showWarning(dx.name_tw);
						if (result.isConfirmed) {
							const emptyIndex = findFirstEmptyIndex(dxList);
							console.log(emptyIndex, "空的index是多少");
							if (emptyIndex !== null) {
								dxList[emptyIndex] = dx; // 更新 dxList 以避免覆蓋
								updateDx(emptyIndex, dx);
							} else {
								appendDx(dx);
							}
						} else {
							continue; // Skip to the next dx
						}
					} else {
						const emptyIndex = findFirstEmptyIndex(dxList);
						console.log(emptyIndex, "空的index是多少");
						if (emptyIndex !== null) {
							dxList[emptyIndex] = dx; // 更新 dxList 以避免覆蓋
							updateDx(emptyIndex, dx);
						} else {
							appendDx(dx);
						}
					}
				}
			} catch (error) {
				console.error("處理診斷列表時發生錯誤：", error);
				// 可以在這裡添加更多錯誤處理邏輯，例如顯示錯誤消息給用戶
			}
			const confirmDuplicate = async (name_tw: string) => {
				return Swal.fire({
					icon: "warning",
					title: "重複輸入",
					text: `${name_tw} 已存在，是否要繼續添加？`,
					showCancelButton: true,
					confirmButtonText: "添加",
					cancelButtonText: "跳過"
				});
			};

			// Append PrescriptionList
			let hasDuplicatePrescription = false;
			try {
				const existingDrugIds = new Set(prescriptionList.map(p => p.data_id));
				for (const prescription of historyPrescriptionList) {
					if (existingDrugIds.has(prescription.data_id)) {
						hasDuplicatePrescription = true;
						const result = await confirmDuplicate(prescription.name_tw);
						if (result.isConfirmed) {
							const emptyIndex = findFirstEmptyIndex(prescriptionList);
							if (emptyIndex !== null) {
								prescriptionList[emptyIndex] = prescription;
								updatePrescription(emptyIndex, prescription, []);
							} else {
								appendPrescription(prescription, []);
								prescriptionList.push(prescription);
							}
						}
					} else {
						const emptyIndex = findFirstEmptyIndex(prescriptionList);
						if (emptyIndex !== null) {
							prescriptionList[emptyIndex] = prescription;
							updatePrescription(emptyIndex, prescription, []);
						} else {
							appendPrescription(prescription, []);
							prescriptionList.push(prescription);
						}
					}
				}
				setIsCopyDrugFromHistoryStatus(true);
			} catch (error) {
				console.error("處理處方列表時發生錯誤：", error);
				// 可以在這裡添加更多錯誤處理邏輯，例如顯示錯誤消息給用戶
			}

			if (!hasDuplicateDx && !hasDuplicatePrescription) {
				SwalToast.fire({
					icon: "success",
					title: "DITTO 成功"
				});
			}

			if (!hasDuplicateDx && !hasDuplicatePrescription) {
				SwalToast.fire({
					icon: "success",
					title: "DITTO 成功"
				});
			}
		} else {
			SwalToast.fire({
				icon: "warning",
				title: "資料讀取請稍候"
			});
		}

		SwalToast.fire({
			icon: "success",
			title: "DITTO 成功"
		});
	};
	return (
		<>
			{/* 按鈕群 */}
			<Box my={"8px"}>
				<Flex gap={"8px"} align={"center"}>
					<RadioGroup
						size={"lg"}
						onChange={setCopyWayStatus}
						value={copyWayStatus}
						colorScheme='rsPrimary'
						boxShadow={"4px 2px 2px 0px rgba(0, 0, 0, 0.25) inset"}
						rounded={"md"}
						overflow={"hidden"}
						border={"1px solid #646B48;"}
						defaultValue='2'>
						<Flex rounded={"4px"} gap={"8px"} p={"6px 12px"} border={"1px solid #ccc"} textColor={"#7A7A7A"}>
							<Radio value='1'>覆蓋</Radio>
							<Radio value='2'>接上</Radio>
						</Flex>
					</RadioGroup>
					<Button
						boxShadow={"2px -2px 2px 0px rgba(0, 0, 0, 0.50) inset"}
						bgColor={"#45533F"}
						rounded={"md"}
						textColor={"#fff"}
						_hover={{ bg: "#2A3327" }}
						onClick={handleDitto}>
						DITTO
					</Button>
					<Button
						boxShadow={"2px -2px 2px 0px rgba(0, 0, 0, 0.50) inset"}
						bgColor={isPartCopyStatus ? "cyan.600" : "#45533F"}
						_hover={isPartCopyStatus ? { bg: "cyan.800" } : { bg: "#2A3327" }}
						rounded={"md"}
						textColor={"#fff"}
						onClick={() => {
							setIsPartCopyStatus(prev => !prev);
						}}>
						{" "}
						區CO
					</Button>
				</Flex>
			</Box>
		</>
	);
};

export default CopyButtons;
