import React, { useEffect, useState } from "react";
import { HistoryList } from "./HistoryList";
import { chakra } from "@chakra-ui/react";
import HistoryCopyButtons from "./DittoCopy";
import { CopyData } from "./CopyDataComponents";

import { Button, Box, Flex, Text, Input, Tooltip, Radio, RadioGroup, Textarea } from "@chakra-ui/react";
import { HeroIcons } from "components/CustomComponents/CustomHeroIcons";

export const MedicalRecord: React.FC = () => {
	return (
		<>
			<Flex direction={"column"} gap={"4px"}>
				<HistoryList />
				<HistoryCopyButtons />
				<CopyData />
			</Flex>
		</>
	);
};
