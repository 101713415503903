import { Flex, Center, Box, Text, Checkbox } from '@chakra-ui/react';
import { useICCardUpdateListStore } from 'store/ICCardUpdate/useICCardUpdateListStore';
type HeaderProps = {
    tabIndex: number;
};
export const Header: React.FC<HeaderProps> = ({ tabIndex }) => {
    const { icCardUpdateList, updateICCardUpdateList } =
        useICCardUpdateListStore();

    const allChecked = icCardUpdateList.every(item => item.ready);
    const isIndeterminate =
        icCardUpdateList.some(item => item.ready) && !allChecked;

    const handleChange = () => {
        updateICCardUpdateList(
            icCardUpdateList.map(item => ({ ...item, ready: !allChecked }))
        );
    };
    return (
        <>
            <Flex
                bg={'#D9D9D9'}
                pos={'sticky'}
                top={0}
                left={0}
                w={'100%'}
                zIndex={10}
            >
                <Box flex={'0 0 5%'}>
                    <Text
                        px={'4px'}
                        borderLeft={'1px solid #fff'}
                        textAlign={'center'}
                    >
                        簽章
                    </Text>
                </Box>

                <Box flex={'0 0 5%'} hidden={tabIndex === 1}>
                    <Center
                        textAlign={'center'}
                        px={'4px'}
                        borderLeft={'1px solid #fff'}
                        gap={2}
                    >
                        <Checkbox
                            size="md"
                            colorScheme="rsPrimary"
                            isChecked={allChecked}
                            isIndeterminate={isIndeterminate}
                            onChange={handleChange}
                        />
                        上傳
                    </Center>
                </Box>
                <Box flex={'0 0 5%'}>
                    <Text
                        px={'4px'}
                        borderLeft={'1px solid #fff'}
                        textAlign={'center'}
                    >
                        狀態
                    </Text>
                </Box>
                <Box flex={'0 0 5%'}>
                    <Text
                        px={'4px'}
                        borderLeft={'1px solid #fff'}
                        textAlign={'center'}
                    >
                        病歷
                    </Text>
                </Box>

                <Box flex={'0 0 6%'}>
                    <Text px={'4px'} borderLeft={'1px solid #fff'}>
                        日期
                    </Text>
                </Box>
                <Box flex={'0 0 6%'}>
                    <Text px={'4px'} borderLeft={'1px solid #fff'}>
                        時間
                    </Text>
                </Box>
                <Box flex={'0 0 10%'}>
                    <Text px={'4px'} borderLeft={'1px solid #fff'}>
                        病歷號
                    </Text>
                </Box>
                <Box flex={'1 0 8%'}>
                    <Text px={'4px'} borderLeft={'1px solid #fff'}>
                        姓名
                    </Text>
                </Box>

                <Box flex={'0 0 10%'}>
                    <Text px={'4px'} borderLeft={'1px solid #fff'}>
                        身分證號
                    </Text>
                </Box>
                <Box flex={'0 0 10%'}>
                    <Text px={'4px'} borderLeft={'1px solid #fff'}>
                        生日
                    </Text>
                </Box>
                <Box flex={'0 0 5%'}>
                    <Text
                        textAlign={'center'}
                        px={'4px'}
                        borderLeft={'1px solid #fff'}
                    >
                        還單
                    </Text>
                </Box>
                <Box flex={'0 0 7%'}>
                    <Text px={'4px'} borderLeft={'1px solid #fff'}>
                        類別
                    </Text>
                </Box>
                <Box flex={'0 0 5%'}>
                    <Text px={'4px'} borderLeft={'1px solid #fff'}>
                        序號
                    </Text>
                </Box>
                <Box flex={'0 0 5%'}>
                    <Text
                        px={'4px'}
                        borderLeft={'1px solid #fff'}
                        textAlign={'center'}
                    >
                        {tabIndex === 0 ? '作廢' : tabIndex === 1 ? '復原' : ''}
                    </Text>
                </Box>
                <Box flex={'0 0 8%'} hidden={tabIndex === 1}>
                    <Text
                        px={'4px'}
                        borderLeft={'1px solid #fff'}
                        textAlign={'center'}
                    >
                        IC退掛
                    </Text>
                </Box>
            </Flex>
        </>
    );
};
