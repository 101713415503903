import apiService from "./apiService";

export type RefillableListsReqProps = {
	patient_uid: string; // 病患的uuid
	medical_date: string; // 就醫日期（掛號日期）YYYY-mm-dd
	opd_uid: string; // 掛號資料 id
};

export type RefillableListsResProps = {
	result: string; // 執行結果
	msg?: string; // 錯誤訊息，如果 result 不為 success 時會明文說明錯誤原因
	lists: RefillableListsDataProps[]; // 有開立的慢性病連續處方箋列表
	detail_info?: {
		refillable_uid: string; // 慢性病資料 id，對應 lists[0].data_id
		refillable_detail_uid: string | null;
		card_no: string | null;
		first_date: string;
		refill_times: number;
		get_times: number;
		lists: DetailListProps[]; // 連續處方箋列表
	};
};

export type RefillableListsDataProps = {
	data_id: string; // 慢性病的資料 id
	diagnosis_name: string; // 慢性病中文病名
};

export type DetailListProps = {
	data_id?: string; // 連續處方箋資料 id，當有領藥日期或已過服藥結束日期時不會回應
	seq: number; // 領藥順序
	prescription_date: string; // 領藥日期
	start_date: string; // 服藥的開始日期
	end_date: string; // 服藥的結束日期
};

export const postRefillableListsService = async (token: string, data: RefillableListsReqProps): Promise<RefillableListsResProps> => {
	return apiService<any, RefillableListsResProps>({
		method: "POST",
		path: "/api/refillable/lists",
		token,
		data: data
	});
};
