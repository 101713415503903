import React from "react";
import { Box, Input, Text, Flex, Select } from "@chakra-ui/react";

export const RxInfo = () => {
	return (
		<Flex direction={"column"} gap={"4px"}>
			<Flex align={"center"} gap={"4px"}>
				<Text textStyle={"semiTitle"} flexShrink={0}>
					開始日時
				</Text>
				<Input size={"sm"}></Input>-<Input size={"sm"}></Input>
			</Flex>
			<Flex align={"center"} gap={"4px"}>
				<Text textStyle={"semiTitle"} flexShrink={0}>
					影像來源
				</Text>
				<Select size={"sm"}>
					<option value=''>請選擇</option>
				</Select>
			</Flex>
			<Flex align={"center"} gap={"4px"}>
				<Text textStyle={"semiTitle"} flexShrink={0}>
					連箋/同療註記
				</Text>
				<Select size={"sm"}>
					<option value=''>請選擇</option>
				</Select>
			</Flex>
			<Flex align={"center"} gap={"4px"}>
				<Flex align={"center"} gap={"4px"}>
					<Text textStyle={"semiTitle"} flexShrink={0}>
						健保單價
					</Text>
					<Input size={"sm"}></Input>
				</Flex>
				<Flex align={"center"} gap={"4px"}>
					<Text textStyle={"semiTitle"} flexShrink={0}>
						科別
					</Text>
					<Input size={"sm"}></Input>
				</Flex>
			</Flex>

			<Flex align={"center"} gap={"4px"}>
				<Text textStyle={"semiTitle"} flexShrink={0}>
					藥品批號
				</Text>
				<Input size={"sm"}></Input>
			</Flex>
			<Flex align={"center"} gap={"4px"}>
				<Text textStyle={"semiTitle"} flexShrink={0}>
					轉（代）檢院代
				</Text>
				<Input size={"sm"}></Input>
			</Flex>
		</Flex>
	);
};
