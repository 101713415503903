import React, { useEffect, useState, useRef } from "react";
import { Box, Text, Flex, Select } from "@chakra-ui/react";
import { useUserStore } from "store";
import { useTreatmentInfoStore } from "store/TreatmentManagement";

import { callMedicalStaffListService } from "service/medicalStaffListService";

type identityProps = {
	data_id: string;
	name: string;
	identity: string;
	color: string;
};

export const ReportingMember = () => {
	const { userInfo } = useUserStore();
	const { treatmentInfo, updateTreatmentInfo } = useTreatmentInfoStore();

	const [pharmacistList, setPharmacistList] = useState<identityProps[]>([]);
	const [doctorList, setDoctorList] = useState<identityProps[]>([]);

	const [selectedDoctor, setSelectedDoctor] = useState("");

	useEffect(() => {
		const doc = doctorList.find(doc => doc.data_id === treatmentInfo.doctor_uid);
		if (doc) {
			setSelectedDoctor(doc.data_id);
		}
	}, [treatmentInfo.doctor_uid, doctorList]);

	const callMedicalStaffListAPI = async (iden: string) => {
		try {
			const result = await callMedicalStaffListService(userInfo.token, iden);

			if (result.result === "success") {
				return { iden, lists: result.lists };
			}
		} catch {
			console.log("callMedicalStaffListAPI error");
		}
		return { iden, lists: [] }; // Ensure a default return value
	};

	useEffect(() => {
		const fetchLists = async () => {
			try {
				const [pharmacistResult, doctorResult] = await Promise.all([
					callMedicalStaffListAPI("iden_pharmacist"),
					callMedicalStaffListAPI("iden_doctor")
				]);

				if (pharmacistResult.iden === "iden_pharmacist") {
					setPharmacistList(pharmacistResult.lists);
				}

				if (doctorResult.iden === "iden_doctor") {
					setDoctorList(doctorResult.lists);
				}
			} catch (error) {
				console.error("獲取醫療人員列表時發生錯誤:", error);
				// 可以在這裡添加錯誤處理邏輯，例如顯示錯誤消息給用戶
			}
		};

		fetchLists();
	}, []);

	// 申報醫師
	const handleChangeDeclareDoctor = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = event.target;

		updateTreatmentInfo({
			[name]: value
		});
		setSelectedDoctor(value);
	};

	// 申報藥師
	const handleChangeDeclarePharmacist = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = event.target;

		updateTreatmentInfo({
			[name]: value
		});
	};

	return (
		<>
			<Box w={"100%"} h={"100%"} p={"8px 12px"} overflowY={"auto"}>
				<Text mb={"4px"} textStyle={"semiTitle"}>
					申報醫師 / 藥師
				</Text>
				<Flex direction={"column"} gap={"4px"}>
					<Flex align={"center"}>
						<Text fontWeight={"semibold"} flex={"0 0 100px"}>
							醫師
						</Text>
						<Select
							bg={"#fff"}
							boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;"}
							border={"1px solid #45533F"}
							rounded={"lg"}
							value={selectedDoctor ?? ""}
							name='declare_doctor_uid'
							onChange={handleChangeDeclareDoctor}>
							{doctorList &&
								doctorList.map((item, index) => (
									<option value={item.data_id} key={index}>
										{item.name}
									</option>
								))}
						</Select>
					</Flex>
					<Flex align={"center"} justify={"space-between"}>
						<Text fontWeight={"semibold"} flex={"0 0 100px"}>
							看診件數
						</Text>
						<Text>{`${0}`} 件</Text>
					</Flex>
				</Flex>

				<Box w={"100%"} h={"1px"} my={"12px"} bg={"#646B48"} />
				<Flex direction={"column"} gap={"4px"}>
					<Flex align={"center"}>
						<Text fontWeight={"semibold"} flex={"0 0 100px"}>
							藥師
						</Text>
						<Select
							bg={"#fff"}
							boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;"}
							border={"1px solid #45533F"}
							rounded={"lg"}
							value={treatmentInfo.pharmacist_uid || ""}
							disabled={treatmentInfo.prescription_style === 0 || treatmentInfo.prescription_style === 1}
							onChange={handleChangeDeclarePharmacist}
							name='pharmacist_uid'>
							<option value=''>請選擇</option>
							{pharmacistList &&
								pharmacistList.map((item, index) => (
									<option value={item.data_id} key={index}>
										{item.name}
									</option>
								))}
						</Select>
					</Flex>
					<Flex align={"center"} justify={"space-between"}>
						<Text fontWeight={"semibold"} flex={"0 0 100px"}>
							調劑件數
						</Text>
						<Text>{`${0}`} 件</Text>
					</Flex>
				</Flex>
			</Box>
		</>
	);
};
