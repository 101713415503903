import { useBaseWaitingListQuery } from './useBaseWaitingListQuery';
import { useVideoWaitingListQuery } from './useVideoWaitingListQuery';
import Swal from 'sweetalert2';
export const useWaitingListQuery = (tabIndex: number) => {
  const baseQuery = useBaseWaitingListQuery();
  const videoQuery = useVideoWaitingListQuery();

  switch (tabIndex) {
    case 0:
      return baseQuery;
    case 1:
      return videoQuery;
    default:
        return baseQuery;
  }
};