import React from "react";
import { Flex, Box, Text, Input, InputGroup, InputRightAddon, Textarea, Button } from "@chakra-ui/react";
import { InsetShadow, InsetShadowBox } from "style/chakraStyles";
import { Debt } from "./Debt";
import { Repayment } from "./Repayment";
import { usePatientInfoStore } from "store/Registration";

export const Receivables = () => {
	const { patientInfo } = usePatientInfoStore();
	return (
		<>
			<Flex direction={"column"} gap={"8px"} sx={InsetShadowBox} w={"100%"}>
				<Flex gap={"32px"}>
					<Flex gap={"8px"}>
						<Text fontWeight={"semibold"} w={"54px"} flexShrink={0}>
							病歷號
						</Text>
						<Text fontWeight={"normal"} w={"120px"}>
							{patientInfo.info.medical_record_number}
						</Text>
					</Flex>
					<Flex gap={"8px"}>
						<Text fontWeight={"semibold"} w={"54px"} flexShrink={0}>
							姓名
						</Text>
						<Text fontWeight={"normal"} w={"120px"}>
							{patientInfo.info.name}
						</Text>
					</Flex>
				</Flex>

				<Flex gap={"16px"}>
					<Flex flexBasis={"calc(50% - 8px)"} direction={"column"}>
						<Debt />
					</Flex>
					<Flex flexBasis={"calc(50% - 8px)"} direction={"column"}>
						<Repayment />
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};
