import axios from "axios";
import Swal from "sweetalert2";
import { rsAPIResponseFormat } from "utils/rsAPIResponseFormat";

type TreatNumV1000EncodeServiceProps = {
	sHospid: string; // 機構代碼
	sPatientId: string; // 保險對象身分證號X(10)
	sTreatDT: string; // 就診日期時間 1110622092407
};

export type TreatNumV1000EncodeResultProps = {
	TreatDT: string;
	HospId: string;
	TreatNum: string;
};
// 傳入JSON範例:{
//     "sHospid":"機構代碼",
//     "sPatientId":"保險對象身分證號X(10)",
//     "sTreatDT":"就診日期時間 1110622092407"
// }
// Rs取得就醫識別碼
export const postTreatNumV1000EncodeService = async (data: TreatNumV1000EncodeServiceProps) => {
	try {
		const response = await axios.post("http://localhost:23752/cshis/TreatNumV1000Encode", data, {
			headers: {
				Accept: "*/*",
				"Content-Type": "application/json"
			}
		});

		console.log("Rs取得encode就醫識別碼 API", response);

		const formattedResponse = await rsAPIResponseFormat(response);
		return formattedResponse;
	} catch (error: unknown) {
		if (error instanceof Error) {
			console.error("Error:", error.message);
			Swal.fire({
				icon: "error",
				title: "API 整理失敗",
				showConfirmButton: true
			});
		} else {
			console.error("An unknown error occurred");
		}
	}
};
