import { callDeclareListsService } from "service/declareListsService";
import { useDeclareListsStore } from "store/DeclarationAudit/useDeclareListsStore";
import { DeclareSearchRangeDataProps, useDeclareSearchRangeStore } from "store/DeclarationAudit/useDeclareSearchRangeStore";
import { useDeclarePageControlStore } from "store/DeclarationAudit/useDeclarePageControlStore";
import { useDeclareListLoadingStatusStore } from "store/DeclarationAudit/useDeclareListLoadingStatusStore";
import { useUserStore } from "store";
import Swal from "sweetalert2";

export const useDeclareListsAPI = () => {
	const { userInfo } = useUserStore();
	const { updateDeclareLists, clearDeclareLists } = useDeclareListsStore();
	const { updateCurrentPage, updateLastPages, resetPageControl } = useDeclarePageControlStore();
	const { declareSearchRangeData } = useDeclareSearchRangeStore();
	const { setIsLoading } = useDeclareListLoadingStatusStore();
	const callDeclareListsAPI = async (data?: DeclareSearchRangeDataProps) => {
		clearDeclareLists();
		resetPageControl();
		setIsLoading(true);
		try {
			const response = await callDeclareListsService(userInfo.token, data as DeclareSearchRangeDataProps);
			setIsLoading(false);
			if (response.result === "success") {
				updateDeclareLists(response.lists.data);
				updateCurrentPage(response.lists.current_page);
				updateLastPages(response.lists.last_page);
			} else if (response.result === "failure") {
				if (declareSearchRangeData.ym === "") {
					return;
				}
				if (response.msg === "查無資料") {
					Swal.fire({
						icon: "info",
						title: "查無資料",
						showConfirmButton: false,
						timer: 1500
					});
				} else {
					Swal.fire({
						icon: "error",
						title: "查詢過程發生錯誤",
						text: response.msg,
						confirmButtonText: "確定"
					});
				}
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			Swal.fire({
				icon: "error",
				title: "查詢過程發生錯誤",
				confirmButtonText: "確定"
			});
		}
	};
	return { callDeclareListsAPI };
};
