import React, { createContext, useContext, useState, ReactNode } from "react";

interface RegistrationListStatusContextValue {
	filterByPeriodStatus: string;
	setFilterByPeriodStatus: React.Dispatch<React.SetStateAction<string>>;
	filterByDoctorStatus: string;
	setFilterByDoctorStatus: React.Dispatch<React.SetStateAction<string>>;
	filterByPatientDataStatus: string;
	setFilterByPatientDataStatus: React.Dispatch<React.SetStateAction<string>>;
	filterByDateStatus: string;
	setFilterByDateStatus: React.Dispatch<React.SetStateAction<string>>;
}

const RegistrationListStatusContext = createContext<RegistrationListStatusContextValue | undefined>(undefined);

interface RegistrationListStatusProps {
	children: ReactNode;
}

export const RegistrationListStatusProvider: React.FC<RegistrationListStatusProps> = ({ children }) => {
	const [filterByPeriodStatus, setFilterByPeriodStatus] = useState("");
	const [filterByDoctorStatus, setFilterByDoctorStatus] = useState("");
	const [filterByPatientDataStatus, setFilterByPatientDataStatus] = useState("");
	const [filterByDateStatus, setFilterByDateStatus] = useState("");

	return (
		<RegistrationListStatusContext.Provider
			value={{
				filterByDoctorStatus,
				setFilterByDoctorStatus,
				filterByPeriodStatus,
				setFilterByPeriodStatus,
				filterByPatientDataStatus,
				setFilterByPatientDataStatus,
				filterByDateStatus,
				setFilterByDateStatus
			}}>
			{children}
		</RegistrationListStatusContext.Provider>
	);
};

export const useRegistrationListStatus = (): RegistrationListStatusContextValue => {
	const context = useContext(RegistrationListStatusContext);

	if (!context) {
		throw new Error("useRegistrationListStatus must be used within a RegistrationListStatusProvider");
	}

	return context;
};
