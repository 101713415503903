import apiService from "./apiService";
import axiosInstance from "./axiosInstance";
//取特定日期的診間醫師清單

type ScheduleItem = {
	[key: string]: string; // 例如: "d491bf95af0f4eb08544c1a8adbc02c3_bbf8fca46d6049c0a2071926ba3b39b6": "一診 - 陳武當"
};

export interface scheduleDoctorRoomObjProps {
	[timeSlotId: string]: ScheduleItem[]; // 时段的 ID 对应的是一个数组，每个数组包含一或多个 ScheduleItem
}
export const callScheduleDoctorRoomService = async (token: string, data?: string) => {
	const queryParams = data ? { data } : undefined;
	return apiService<undefined, any>({
		method: "GET",
		path: "/api/schedule/doctor_room",
		token,
		data: queryParams
	});
};
