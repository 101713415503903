import React, { createContext, useContext, useState, ReactNode } from "react";

interface LoginAPIStatusContextValue {
	loginAPIStatus: string;
	setLoginAPIStatus: React.Dispatch<React.SetStateAction<string>>;
}

const LoginAPIStatusContext = createContext<LoginAPIStatusContextValue | undefined>(undefined); // Set the initial value as undefined

interface LoginAPIStatusProviderProps {
	children: ReactNode;
}

export const LoginAPIStatusProvider: React.FC<LoginAPIStatusProviderProps> = ({ children }) => {
	const [loginAPIStatus, setLoginAPIStatus] = useState<string>("");

	return (
		<LoginAPIStatusContext.Provider
			value={{
				loginAPIStatus,
				setLoginAPIStatus
			}}>
			{children} {/* Fix: Remove double curly braces around children */}
		</LoginAPIStatusContext.Provider>
	);
};

export const useLoginAPIStatus = (): LoginAPIStatusContextValue => {
	const context = useContext(LoginAPIStatusContext); // Fix: Remove the typo 'con'

	if (!context) {
		throw new Error("useloginAPIStatus must be used within a loginAPIStatus");
	}
	return context;
};
