import React, { useCallback } from "react";
import {
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerHeader,
	DrawerBody,
	DrawerCloseButton,
	DrawerFooter,
	Flex,
	Box,
	Button
} from "@chakra-ui/react";
import { useTreatmentInfoStore } from "store/TreatmentManagement";
import { SwalToast } from "components/CustomComponents/SwalComponents";
import { useShowAbnormalButtonStatus } from "contexts/TreatmentManagement/BillingProcess/ShowAbnormalButtonStatusContext";
import { useIsChooseAbnormalButtonStatus } from "contexts/TreatmentManagement/BillingProcess/IsChooseAbnormalButtonStatusContext";

export const AbnormalButton = () => {
	const { showAbnormalButtonStatus, setShowAbnormalButtonStatus } = useShowAbnormalButtonStatus();
	const { setIsChooseAbnormalButtonStatus } = useIsChooseAbnormalButtonStatus();
	const { updateTreatmentInfo } = useTreatmentInfoStore();
	const exceptionStateListBtnAPI = [
		{ code: "A000", desc: "讀卡設備故障" },
		{ code: "A010", desc: "讀卡機故障" },
		{ code: "A020", desc: "網路故障造成讀卡機無法使用" },
		{ code: "A030", desc: "安全模組故障造成讀卡機無法使用" },
		{ code: "B000", desc: "卡片不良(表面正常，晶片異常)" },
		{ code: "C000", desc: "停電" },
		{ code: "C001", desc: "例外就醫" },
		{ code: "D000", desc: "醫療資訊系統(HIS)當機" },
		{ code: "D010", desc: "醫療院所電腦故障" },
		{ code: "E000", desc: "健保署資訊系統當機" },
		{ code: "E001", desc: "急診無法更新者" },
		{ code: "F000", desc: "院所赴偏遠地區無法上網" },
		{ code: "Z000", desc: "其他" },
		{ code: "Z00A", desc: "異常就醫序號" },
		{ code: "G000", desc: "網路未建置" },
		{ code: "MSPT", desc: "代謝症候群防治計畫" },
		{ code: "ICND", desc: "未具健保身份生產案件" },
		{ code: "ICC4", desc: "癌症治療品質計畫之追蹤及診斷品質管理" }
	];

	const handleChangeException = (code: string, desc: string) => {
		updateTreatmentInfo({
			card_no: code ?? ""
		});
		setIsChooseAbnormalButtonStatus(true);

		SwalToast.fire({
			icon: "success",
			title: "更新成功",
			timer: 1500
		});

		setShowAbnormalButtonStatus(false);
	};

	return (
		<Drawer
			isOpen={showAbnormalButtonStatus}
			placement='top'
			onClose={() => {
				setShowAbnormalButtonStatus(false);
			}}
			size={"md"}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader textColor={"rsPrimary.500"}>請選擇異常卡號</DrawerHeader>
				<DrawerBody>
					<Flex rowGap={"8px"} flexWrap={"wrap"} gap={"8px"} direction={"column"}>
						{exceptionStateListBtnAPI &&
							exceptionStateListBtnAPI.map((item, index) => (
								<Button
									p={0}
									border={0}
									outline={0}
									boxShadow={0}
									bg={"transparent"}
									h={"auto"}
									display={"flex"}
									key={index}
									name='exception_card_number'
									onClick={() => handleChangeException(item.code, item.desc)}
									_hover={{
										filter: "hue-rotate(45deg)"
									}}
									overflow={"hidden"}>
									<Box
										px={"8px"}
										py={"12px"}
										bg={"rsPrimary.500"}
										textColor={"#fff"}
										flex={"0 0 80px"}
										borderRadius={"4px 0 0 4px"}>
										{item.code}
									</Box>
									<Box px={"16px"} py={"12px"} bg={"gray.250"} flex={"1 0 auto"} textAlign={"left"}>
										{item.desc}
									</Box>
								</Button>
							))}
					</Flex>
				</DrawerBody>

				{/* <DrawerFooter>
                <Button colorScheme="rsSteel" mr={3} onClick={() => {
                    console.log('關閉');
                    setShowAbnormalButtonStatus(false);
                }}>
                    Cancel
                </Button>
            </DrawerFooter> */}
			</DrawerContent>
		</Drawer>
	);
};
