import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";

import { Flex, Text, Select, Input, Radio, RadioGroup, Stack } from "@chakra-ui/react";

import { benefitTypeProps, callGTypeBenefitTypeService } from "service/gTypeService";

import { copaymentExemptionsProps, callCopaymentExemptionsService } from "service/copaymentExemptionsService";
import { specificTreatmentListsProps, callSpecificTreatmentListsService } from "service/specificTreatmentListsService";

import { useUserStore } from "store";
import { useTreatmentInfoStore } from "store/TreatmentManagement";

export const Options = () => {
	const [value, setValue] = React.useState("0");
	const { userInfo } = useUserStore();
	const { treatmentInfo, updateTreatmentInfo } = useTreatmentInfoStore();

	const [benefitTypeLists, setBenefitTypeLists] = useState<benefitTypeProps[]>([]);

	const { data: benefitTypeData, isSuccess: isBenefitTypeSuccess } = useQuery(
		"benefitType",
		() => callGTypeBenefitTypeService(userInfo.token!),
		{
			enabled: !!userInfo.token,
			staleTime: Infinity
		}
	);
	useEffect(() => {
		if (isBenefitTypeSuccess) {
			setBenefitTypeLists(benefitTypeData.lists);
		}
	}, [isBenefitTypeSuccess]);

	// 部分負擔清單
	const [copaymentExemptionsLists, setCopaymentExemptionsLists] = useState<copaymentExemptionsProps>();

	const { data: copaymentExemptionsData, isSuccess: isCopaymentExemptionsSuccess } = useQuery(
		"copaymentExemptions",
		() => callCopaymentExemptionsService(userInfo.token!),
		{
			enabled: !!userInfo.token,
			staleTime: Infinity
		}
	);
	useEffect(() => {
		if (isCopaymentExemptionsSuccess) {
			setCopaymentExemptionsLists(copaymentExemptionsData.lists);
		}
	}, [isCopaymentExemptionsSuccess]);

	// 特殊原因清單
	const [specificTreatmentLists, setSpecificTreatmentLists] = useState<specificTreatmentListsProps[]>([]);

	const { data: specificTreatmentListsData, isSuccess: isSpecificTreatmentListsSuccess } = useQuery(
		"specificTreatmentLists",
		() => callSpecificTreatmentListsService(userInfo.token!),
		{
			enabled: !!userInfo.token,
			staleTime: Infinity
		}
	);
	useEffect(() => {
		if (isSpecificTreatmentListsSuccess) {
			setSpecificTreatmentLists(specificTreatmentListsData.lists);
		}
	}, [isSpecificTreatmentListsSuccess]);

	const handleChange = (event: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		updateTreatmentInfo({
			[name]: value
		});
	};
	const [test, setTest] = useState("");
	const handleTest = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const { value } = event.target;
		setTest(value);
	};
	return (
		<>
			<Flex w={"100%"} h={"100%"} p={"8px 16px"} direction={"column"} gap={"4px"}>
				<Flex>
					<Text flex={"0 0 100px"} p={"8px 0px"} fontWeight={"semibold"}>
						部分負擔
					</Text>

					<Select bg={"#fff"} onChange={handleChange} name='copayment' value={treatmentInfo.copayment ?? ""}>
						{copaymentExemptionsLists &&
							Object.entries(copaymentExemptionsLists).map(([code, description]) => (
								<option value={code} key={code}>
									{description}
								</option>
							))}
					</Select>
				</Flex>
				<Flex>
					<Text flex={"0 0 100px"} p={"8px 0px"} fontWeight={"semibold"}>
						給付類別
					</Text>

					<Select
						bg={"#fff"}
						onChange={handleChange}
						name='benefit_type'
						// value={treatmentInfo.benefit_type}
						value={treatmentInfo.benefit_type ?? ""}>
						{benefitTypeLists &&
							benefitTypeLists.map((item, index) => (
								<option value={item.code} key={index}>
									{item.code} {item.name}
								</option>
							))}
					</Select>
				</Flex>
				<Flex>
					<Text flex={"0 0 100px"} p={"8px 0px"} fontWeight={"semibold"}>
						轉檢代號
					</Text>

					<Input name='referral' placeholder='請輸入 10 碼代號' maxLength={10} onChange={handleChange}></Input>
				</Flex>
				<Flex>
					<Text flex={"0 0 100px"} p={"8px 0px"} fontWeight={"semibold"}>
						連續
					</Text>

					<RadioGroup value={treatmentInfo.refillable.refill_times?.toString() ?? ""}>
						<Stack direction='row' py={"8px"}>
							<Radio value='2' isDisabled>
								2
							</Radio>
							<Radio value='3' isDisabled>
								3
							</Radio>
							<Radio value='4' isDisabled>
								4
							</Radio>
						</Stack>
					</RadioGroup>
				</Flex>
				<Flex>
					<Text flex={"0 0 100px"} p={"8px 0px"} fontWeight={"semibold"}>
						連簽特殊原因
					</Text>

					<Text py={2} pl={2}>
						{specificTreatmentLists.find(item => item.code === treatmentInfo.special_treatment)?.code || ""}{" "}
						{specificTreatmentLists.find(item => item.code === treatmentInfo.special_treatment)?.name || ""}
					</Text>

					{/* <Select
                        bg={'#fff'}
                        onChange={handleChange}
                        name="special_treatment"
                        value={treatmentInfo.special_treatment ?? ''} 
                    >
                        <option value="">請選擇</option>
                        {specificTreatmentLists &&
                            specificTreatmentLists.map((item, index) => (
                                <option value={item.code} key={index}>
                                    {item.code} {item.name}
                                </option>
                            ))}
                    </Select> */}
				</Flex>
				<Flex>
					<Text flex={"0 0 100px"} p={"8px 0px"} fontWeight={"semibold"}>
						計畫註記
					</Text>

					<Select bg={"#fff"} value={test} onChange={handleTest}>
						<option value=''>請選擇</option>
						<option value='P'>P高齡醫學或失智症</option>
					</Select>
				</Flex>
			</Flex>
		</>
	);
};
