import { callHospNameService, HospNameReqDataProps } from "service/hospNameService";
import { useUserStore } from "store";
import Swal from "sweetalert2";
export const useHospNameAPI = () => {
	const { userInfo } = useUserStore();

	const callHospNameAPI = async (data: HospNameReqDataProps) => {
		try {
			const response = await callHospNameService(userInfo.token, data);

			if (response.result === "success") {
				return response.info;
			} else {
				Swal.fire({
					icon: "error",
					title: "查詢過程發生錯誤",
					text: response.msg,
					confirmButtonText: "確定"
				});
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	return { callHospNameAPI };
};
